import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { get, selectionDialogActions } from '../../../../store/utils/dialog/selection';
import SelectionDialog from '../../../../components/molecules/SelectionDialog/SelectionDialog';

function mapStateToProps(state: AppState) {
  return {
    data: get.data(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClose: selectionDialogActions.close.dispatcher(dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
type TProps = TStateProps & TDispatchProps;

const SelectionDialogContainer: React.FC<TProps> = (props: TProps) => {
  const { data } = props;
  const onClose = () => {
    props.onClose({});
  };
  return <SelectionDialog {...data} onClose={onClose} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionDialogContainer);
