import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TRequiredCheckDialogProps } from '../../../containers/components/dialogs/RequiredCheckDialog/RequiredCheckDialog';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import Button from '../../atoms/Button/Button';
import { langResource } from '../../../i18n/_new/resources';
import styles from './RequiredCheckDialog.module.scss';

type TProps = TRequiredCheckDialogProps & RouteComponentProps;

// TODO: このエラーダイアログを汎用的に利用するために、リストの上下の文字列を外から渡せるように修正
const RequiredCheckDialog: React.FC<TProps> = (props: TProps) => {
  const { hasOpen, texts, onClick, onClose } = props;

  const renderContent = () => {
    return (
      <div>
        <div>生地選択画面から</div>
        {texts.map(text => (
          <p key={text}>{`・${text}`}</p>
        ))}
        <div>OKボタンを押すと生地選択画面に戻ります。</div>
      </div>
    );
  };

  const clickAction = () => {
    if (onClick) {
      onClick();
    }
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={hasOpen} className="dialog">
        <DialogTitle>
          <I18TextContainer textObj={langResource.dialog.requiredCheck.title} />
        </DialogTitle>
        <DialogContent>
          <div className={styles.text}>{renderContent()}</div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button textObj={langResource.dialog.requiredCheck.okBtn} onClick={clickAction} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withRouter(RequiredCheckDialog);
