import { actionObjectCreatorFactory } from '../../../../lib/action-creator-factory';
import { ISaveTmpOrderDialog } from '../../../../types/dialog';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

// action
const ac = actionObjectCreatorFactory();

const actionTitle = 'Save TmpOrder Dialog';

export const actions = {
  show: ac<void>(`[${actionTitle}] show`),
  close: ac<void>(`[${actionTitle}] close`),
};

export const initialState: ISaveTmpOrderDialog = {
  hasOpen: false,
  hasError: false,
  isLoading: false,
  errorMessage: '',
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.show._action, (state, payload) => ({ ...state, hasOpen: true }))
  .case(actions.close._action, state => ({ ...initialState }));
