import React, { useEffect } from 'react';

import styles from './PurchaseHistoryDialog.module.scss';

import { TPurchaseHistoryDialogProps } from '../../../containers/components/dialogs/PurchaseHistoryDialog';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import { langResource } from '../../../i18n/_new/resources';
import Button from '../../atoms/Button/Button';
import { IOrderDigests } from '../../../types/orders/digest';

export const PurchaseHistoryDialog: React.FC<TPurchaseHistoryDialogProps> = (props: TPurchaseHistoryDialogProps) => {
  const { hasOpen, purchaseHistory, onLoadPurchaseHistory, onSetPartsSizeHistory } = props;

  useEffect(() => {
    if (hasOpen) {
      onLoadPurchaseHistory();
    }
  }, [hasOpen, onLoadPurchaseHistory]);

  const onClose = () => props.onClose();

  const setHistory = (data: IOrderDigests) => () => {
    onSetPartsSizeHistory({ requestSerial: data.requestSerial });
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={hasOpen} onClose={onClose} maxWidth={false}>
        <div>
          <DialogTitle>
            <I18TextContainer textObj={langResource.dialog.purchaseHistory.title} />
          </DialogTitle>
          <div>
            <DialogContent>
              <div className={styles.purchaseHistoryContents}>
                <div className={styles.thead}>
                  <div className={styles.row}>
                    <div className={styles.col1}>
                      <p>製造NO</p>
                    </div>
                    <div className={styles.col2}>
                      <p>注文日</p>
                    </div>
                    <div className={styles.col3}>
                      <p>アイテム</p>
                    </div>
                    <div className={styles.col4}>
                      <p>ブランド</p>
                    </div>
                    <div className={styles.col5}>
                      <p>期別</p>
                    </div>
                    <div className={styles.col6}>
                      <p>品番</p>
                    </div>
                    <div className={styles.col7}>
                      <p>店舗名</p>
                    </div>
                    <div className={styles.col8}>
                      <p>読込</p>
                    </div>
                  </div>
                </div>
                <div className={styles.tbody}>
                  {purchaseHistory.map((list: IOrderDigests) => (
                    <div className={styles.row} key={list.serialNumber}>
                      <div className={styles.col1}>
                        <p>{list.serialNumber}</p>
                      </div>
                      <div className={styles.col2}>
                        <p>{list.orderDate}</p>
                      </div>
                      <div className={styles.col3}>
                        <p>{list.item.itemCode}</p>
                      </div>
                      <div className={styles.col4}>
                        <p>{list.brandCode}</p>
                      </div>
                      <div className={styles.col5}>
                        <p>{list.seasonCode}</p>
                      </div>
                      <div className={styles.col6}>
                        <p>{list.productNumber}</p>
                      </div>
                      <div className={styles.col7}>
                        <p>{list.tempoName}</p>
                      </div>
                      <div className={styles.col8}>
                        <Button
                          styles={['block', 'black', 'size-xs']}
                          textObj={langResource.dialog.purchaseHistory.loadBtn}
                          onClick={setHistory(list)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
