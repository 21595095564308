import React from 'react';
import NumberFormat from 'react-number-format';

interface IProps {
  price: number;
  prefix?: string;
}

const PriceTextPlane: React.FC<IProps> = (props: IProps) => {
  const { price, prefix } = props;
  return <NumberFormat value={price} displayType="text" thousandSeparator={true} prefix={prefix} />;
};
export default PriceTextPlane;
