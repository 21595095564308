import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from '../helpers/common/history';

import { ERouterPath } from '../types/index';
import { resolvePath, resolvePaths } from '../helpers/common/path';

import MainPageContainer from '../containers/MainPage/MainPage';
import OrderDigestsSearchPageContainer from '../containers/pages/OrderDigestsSearchPage';
import OrderDetailPageContainer from '../containers/pages/OrderDetailPage';

import HomePageContainer from '../containers/pages/HomePage';
import ItemContentPageContainer from '../containers/pages/ItemContentPage';
import OrderContentPageContainer from '../containers/pages/OrderContentPage';
import InventorySearchPageContainer from '../containers/pages/InventorySearchPage';

import SettlementPageContainer from '../containers/pages/SettlementPage';
import SettlementEndPageContainer from '../containers/pages/SettlementEndPage';
import OrderConfirmationPageContainer from '../containers/pages/OrderConfirmationPage';
import config from '../configuration/config';
import SpecialAdjustPageContainer from '../containers/pages/SpecialAdjustPage';
import NoOrderGuard from '../containers/NoOrderGuard/NoOrderGuard';

class AppRouter extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <NoOrderGuard>
            <Route
              exact={true}
              path={resolvePath(ERouterPath.ordersSearch)}
              component={OrderDigestsSearchPageContainer}
            />
            <Route exact={false} path={resolvePath(ERouterPath.orderDetail)} component={OrderDetailPageContainer} />
            <Route exact={true} path={resolvePath(ERouterPath.home)} component={HomePageContainer} />
            <Route exact={true} path={resolvePath(ERouterPath.settlement)} component={SettlementPageContainer} />
            <Route exact={true} path={resolvePath(ERouterPath.settlementEnd)} component={SettlementEndPageContainer} />
            <Route
              exact={true}
              path={resolvePath(ERouterPath.orderConfirmation)}
              component={OrderConfirmationPageContainer}
            />
            <Route
              exact={true}
              path={resolvePath(ERouterPath.inventorySearch)}
              component={InventorySearchPageContainer}
            />
            <Route exact={true} path={resolvePath(ERouterPath.specialAdjust)} component={SpecialAdjustPageContainer} />
            <Route
              exact={true}
              path={resolvePaths([ERouterPath.itemConfirmation, ERouterPath.address, ERouterPath.amount])}
              component={OrderContentPageContainer}
            />
            <Route
              exact={true}
              path={resolvePaths([ERouterPath.clothSelection, ERouterPath.designSelection, ERouterPath.sizeCorrection])}
              component={ItemContentPageContainer}
            />
            {this.renderSamplePage()}
            <Redirect from="/" to={resolvePath(ERouterPath.home)} />
          </NoOrderGuard>
        </Switch>
      </ConnectedRouter>
    );
  }

  renderSamplePage() {
    if (!config.isDev) {
      return null;
    }
    return <Route exact={true} path={resolvePath(ERouterPath.samples)} component={MainPageContainer} />;
  }
}

export default AppRouter;
