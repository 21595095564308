import React from 'react';

import styles from './PartsTab.module.scss';

import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall/';
import { IPartsTabItem } from '../../../types/new-store/components';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';

interface IProps {
  selectingParts: TPartsNumber;
  partsTabs: IPartsTabItem[];
  onChangeParts: typeof SizeCorrectionPageEvents.onChangeParts;
}

const PartsTab: React.FC<IProps> = (props: IProps) => {
  const { partsTabs, selectingParts, onChangeParts } = props;

  const getClassName = (partsNumber: TPartsNumber) => {
    const baseClasses = [styles.tab];
    if (selectingParts === partsNumber) {
      baseClasses.push(styles.tabActive);
    }
    return baseClasses.join(' ');
  };

  const onChange = (partsNumber: TPartsNumber) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onChangeParts({ partsNumber });
  };

  return (
    <div id={styles.container}>
      {partsTabs.map((v, i) => {
        const { partsNumber, partsName } = v;
        return (
          <div key={i} className={getClassName(partsNumber)} onClick={onChange(partsNumber)}>
            <div className={styles.parts}>{partsName}</div>
          </div>
        );
      })}
    </div>
  );
};

export default PartsTab;
