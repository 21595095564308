import equals from 'ramda/es/equals';

/**
 * 文字列の配列を直接引数に指定することで、配列の各要素のの値をキーとバリューに指定したオブジェクトを生成
 * 利用例：
 * const Hoge = rawStrArr2EnumLikeObj('H', 'O', 'G', 'E');
 * type THoge = keyof typeof Hoge; // 'H' | 'O' | 'G' | 'E' というUnion型が取得できる
 */
export const rawStrArr2EnumLikeObj = <T extends string>(o: T[]): { [K in T]: K } => {
  return o.reduce((acc, curr) => {
    acc[curr] = curr;
    return acc;
  }, Object.create(null));
};

const matchElse = Symbol();
/**
 * パターンマッチング用関数
 * @param conditions [equqls(値, target) or 関数(target) or match._(elseとして利用), 前の要素がtrueのときに返却する 値 or 関数(target)][]
 * @param target 対象となる値、比較対象 or 関数の引数として利用される
 */
export const match = <T, U>(
  conditions: Array<
    [
      U extends Function ? ((_target: U) => boolean) | symbol : ((_target: U) => boolean) | U | symbol,
      T extends Function ? (_target: U) => T : ((_target: U) => T) | T,
    ]
  >,
  target: U,
): T | undefined => {
  const matched = conditions.find(
    ([cond, _]) =>
      (typeof cond !== 'function' && (equals(cond as U, target) || cond === matchElse)) ||
      (typeof cond === 'function' && cond(target)),
  ) || [undefined, undefined];

  return typeof matched[1] === 'function' ? matched[1](target) : matched[1];
};
match._ = matchElse;
