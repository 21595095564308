import { confirmationPageActions } from '../../store/pages/confirmation';
import { selectionDialogActions } from '../../store/utils/dialog/selection';
import { ISelectionDialog, IOKCancelDialog } from '../../types/dialog';

export function generateDeleteOrderBtnFunc(
  orderNumber: string,
  deleteOrder: typeof confirmationPageActions.deleteOrder.tp,
  showSelectionDialog: typeof selectionDialogActions.show.type,
): () => void {
  return () => {
    const onYes = () => {
      deleteOrder({ orderNumber });
    };

    const dialog: ISelectionDialog = {
      hasOpen: true,
      title: '',
      contents: 'オーダーを削除しますか？', // FIXME: 多言語化対応する必要あり
      onYes,
    };
    showSelectionDialog(dialog);
  };
}

export function generateOkCancelDialogData(
  contents: string,
  title?: string,
  isTextKey?: boolean,
  onOK?: () => void,
  onCancel?: () => void,
  labelOk?: string,
  labelCancel?: string,
): IOKCancelDialog {
  return {
    hasOpen: true,
    contents,
    title: title ? title : null,
    isTextKey: isTextKey ? isTextKey : false,
    onOK,
    onCancel,
    labelOk,
    labelCancel,
  };
}

export function generateSelectionDialogData(
  contents: string,
  title?: string,
  isTextKey?: boolean,
  onYes?: () => void,
  onNo?: () => void,
  labelYes?: string,
  labelNo?: string,
): ISelectionDialog {
  return {
    hasOpen: true,
    contents,
    title: title ? title : null,
    isTextKey: isTextKey ? isTextKey : false,
    onYes,
    onNo,
    labelYes,
    labelNo,
  };
}
