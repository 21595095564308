import { createSelector } from 'reselect';
import { AppState } from '../../..';
import { IInformationDialog } from '../../../../types/dialog';

export { infoDialogReducer, infoDialogActions } from './action-reducer';

const featureSelector = (state: AppState): IInformationDialog => state.utils.dialog.info;

export const getInformationDialog = {
  data: createSelector(featureSelector, state => state),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
};
