import { getDefaultState } from './helper';
export interface ILocalState {
  progressKey: string;
  progressName: string;
  progressType: string;
  progressList: TProgressList;
}

export interface IProgressListItem {
  progressId: string;
  progressName: string;
  progressType: string;
  progressData: string;
  createDate: string;
  updateDate: string;
}

export type TProgressList = IProgressListItem[];

export const inicialLocalState: ILocalState = { ...getDefaultState() };
