import React from 'react';
import partial from 'ramda/es/partial';

import styles from './OrderDestination.module.scss';
import Title from '../../atoms/Title/Title';
import InputText from '../../../containers/components/InputText';
import Label from '../../atoms/Label/Label';

import { IShipping } from '../../../store/_type/order';
import { addressPageActions } from '../../../store/pages/address';
import { langResource } from '../../../i18n/_new/resources';
import Button from '../../atoms/Button/Button';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import NumberFormat from 'react-number-format';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import {
  isKana,
  isValidPostalCode,
  isValidPhoneNumber,
  isValidMailAddress,
  isValidLengthPhoneNumber,
  isValidLengthShippingStreet,
  isValidLengthCustomerNameKanji,
  isValidLengthCustomerNameKana,
  isValidLotNumber,
  isValidMemberscardNumberInput,
  isValidCutterNameKana,
} from '../../../helpers/common/validate';
import { getCssClassErrorInvalidData } from '../../../helpers/html-css';
import { canEditItem } from '../../../helpers/orders/digest';
import { match } from '../../../lib/utils';
import { AddressItem, TOrderStatus } from '../../../lookups/item-thisisforreplaceall/order-status';

interface IProps {
  isEdit: boolean;
  index: number;
  shipping: IShipping;
  orderNumber: string;
  orderStatus: TOrderStatus;
  mailAddresses: string[];
  onTogleSameOrderOne: typeof addressPageActions.togleSameOrderOne.tp;
  onChangeValue: typeof addressPageActions.changeValue.tp;
  onChangeInputTwoValue: typeof addressPageActions.changeInputTwoValue.tp;
  isManager: boolean;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const OrderDestination: React.FC<TProps> = (props: TProps) => {
  const {
    index,
    orderNumber,
    orderStatus,
    onTogleSameOrderOne,
    onChangeValue,
    onChangeInputTwoValue,
    mailAddresses,
    isEdit,
    isManager,
  } = props;
  const {
    shippingPostalCode,
    shippingState,
    shippingCity,
    shippingStreet,
    customerFamilyNameKana,
    customerGivenNameKana,
    customerFamilyNameKanji,
    customerGivenNameKanji,
    shippingPhoneNumber,
    customerMailAddress,
    isSameOrderOne,
    lotNumber,
    cutterNameKana,
    memberscardNumber,
  } = props.shipping;

  const onHandleChange = (key: keyof Omit<IShipping, 'customerMailAddress'>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    onChangeValue({ key, orderNumber, value });
  };

  const onHandleChangeWithIndex = (key: keyof Pick<IShipping, 'customerMailAddress'>, i: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    const preValue = props.shipping[key];
    onChangeInputTwoValue({ key, index: i, orderNumber, value, preValue });
  };

  const onHandleTogleSame = () => {
    onTogleSameOrderOne({ orderNumber, hasState: !isSameOrderOne });
  };

  const connectNumericKeyboard = (key: keyof Pick<IShipping, 'shippingPhoneNumber' | 'shippingPostalCode'>) => () => {
    if (key === 'shippingPhoneNumber') {
      props.connectNumericKeyboard(shippingPhoneNumber, value => {
        onChangeValue({ key, orderNumber, value });
      });
    }
    if (key === 'shippingPostalCode') {
      props.connectNumericKeyboard(shippingPostalCode, value => {
        if (value.length <= 7) {
          onChangeValue({ key, orderNumber, value });
        } else {
          return value.substring(0, 7);
        }
      });
    }
  };

  const renderButton = () => {
    if (index === 0) {
      return null;
    }

    const labelClassName = isSameOrderOne ? styles.label : styles.labelHidden;
    const buttonTextObj = isSameOrderOne
      ? langResource.addressPage.orderOtherPlace
      : langResource.addressPage.orderOneSamePlace;

    return (
      <div className={styles.btnWrapper}>
        <div className={labelClassName}>
          <Label textObj={langResource.addressPage.orderOneSamePlace} />
        </div>
        <Button textObj={buttonTextObj} onClick={onHandleTogleSame} />
      </div>
    );
  };

  const contentClassName = index > 0 && isSameOrderOne ? styles.contentNone : styles.content;

  const isFilledKanaNameInput = !!customerFamilyNameKana && !!customerGivenNameKana;
  const isValidFormatKanaNameInput = isKana(customerFamilyNameKana) && isKana(customerGivenNameKana);
  const isValidLengthKanaNameInput =
    isValidLengthCustomerNameKana(customerFamilyNameKana) && isValidLengthCustomerNameKana(customerGivenNameKana);
  const kanaNameInputClass = getCssClassErrorInvalidData(
    isValidFormatKanaNameInput && isFilledKanaNameInput && isValidLengthKanaNameInput,
  );
  const kanaNameInputErrorMsg =
    match(
      [
        [!isValidFormatKanaNameInput, 'フリガナはカタカナで入力して下さい'],
        [!isValidLengthKanaNameInput, 'お名前（フリガナ）は各10文字以内で入力してください'],
      ],
      true,
    ) || '';

  const canEditAddressItem = partial(canEditItem, [orderStatus, isManager]);
  const canEditName = !isEdit || canEditAddressItem(AddressItem.customerName);

  const isValidPostalCodeInput = isValidPostalCode(shippingPostalCode);
  const postalCodeInputClass = getCssClassErrorInvalidData(isValidPostalCodeInput);

  const isValidShippingStreetInput = !!shippingStreet;
  const isValidLengthShippingStreetInput = isValidLengthShippingStreet(shippingStreet);
  const shippingStreetInputClass = getCssClassErrorInvalidData(
    isValidShippingStreetInput && isValidLengthShippingStreetInput,
  );
  const shippingStreetInputErrorMag = isValidLengthShippingStreetInput
    ? ''
    : '番地・マンション名は50byte以内で入力してください(全角が2byte、半角が1byte換算)';

  const isCustomerNameKanji = !!customerFamilyNameKanji && !!customerGivenNameKanji;
  const isValidCustomerNameKanji =
    isValidLengthCustomerNameKanji(customerFamilyNameKanji) && isValidLengthCustomerNameKanji(customerGivenNameKanji);
  const customerNameKanjiInputClass = getCssClassErrorInvalidData(isCustomerNameKanji && isValidCustomerNameKanji);
  const customerNameKanjiErrorMsg = isValidCustomerNameKanji ? '' : 'お名前は各10文字以内で入力してください';

  const isValidShippingPhoneNumber = isValidPhoneNumber(shippingPhoneNumber);
  const phoneNumberInputClass = getCssClassErrorInvalidData(isValidShippingPhoneNumber);
  const phoneNumberInputErrorMsg = isValidLengthPhoneNumber(shippingPhoneNumber)
    ? ''
    : '電話番号は13桁未満で入力して下さい';

  const isValidEmailAddress = isValidMailAddress(customerMailAddress) || !customerMailAddress;
  const emailInputClass = getCssClassErrorInvalidData(isValidEmailAddress);

  const isValidShippingState = !!shippingState;
  const isValidShippingCity = !!shippingCity;
  const shippingStateInputClass = getCssClassErrorInvalidData(isValidShippingState);
  const shippingCityInputClass = getCssClassErrorInvalidData(isValidShippingCity);
  const lotNumberInputErrorMsg = isValidLotNumber(lotNumber) ? '' : '照合番号は30桁以下で入力してください';
  const memberscardNumberInputErrorMsg = isValidMemberscardNumberInput(memberscardNumber)
    ? ''
    : '会員番号は30桁以下で入力してください';
  const cutterNamaKanaInputErrorMsg = isValidCutterNameKana(cutterNameKana)
    ? ''
    : '担当者番号は30桁以下で入力してください';

  return (
    <div className={styles.orderDestination}>
      <div className={styles.title}>
        <Title styles={['lineLightBrown']} textObj={langResource.addressPage.deliveryPlace} />
        {renderButton()}
      </div>
      <div className={contentClassName}>
        <div className={styles.left}>
          <div className={styles.subTitle}>
            <Title styles={['gray', 'medium']} textObj={langResource.addressPage.address} />
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize2}>
              <Label textObj={langResource.addressPage.zipCode} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <NumberFormat
                    readOnly={true}
                    value={shippingPostalCode}
                    allowNegative={false}
                    onFocus={connectNumericKeyboard('shippingPostalCode')}
                    data-input-type={EKeyboardInputType.numberText}
                    className={postalCodeInputClass}
                    format="### - ####"
                    mask="_"
                    disabled={isEdit && !canEditAddressItem(AddressItem.postalCode)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize2}>
              <Label textObj={langResource.addressPage.states} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <InputText
                    onValueChanged={onHandleChange('shippingState')}
                    value={shippingState}
                    className={shippingStateInputClass}
                    disabled={isEdit && !canEditAddressItem(AddressItem.state)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.columnSize3}>
              <Label textObj={langResource.addressPage.city} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <InputText
                    onValueChanged={onHandleChange('shippingCity')}
                    value={shippingCity}
                    className={shippingCityInputClass}
                    disabled={isEdit && !canEditAddressItem(AddressItem.city)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize1}>
              <Label textObj={langResource.addressPage.street} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <InputText
                    onValueChanged={onHandleChange('shippingStreet')}
                    value={shippingStreet}
                    className={shippingStreetInputClass}
                    disabled={isEdit && !canEditAddressItem(AddressItem.street)}
                  />
                  <div className="error-tooltip">{shippingStreetInputErrorMag}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize1}>
              <Label textObj={langResource.addressPage.custermerNameKana} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem4}>
                  <InputText
                    onValueChanged={onHandleChange('customerFamilyNameKana')}
                    value={customerFamilyNameKana}
                    className={kanaNameInputClass}
                    disabled={!canEditName}
                  />
                  <div className="error-tooltip">{kanaNameInputErrorMsg}</div>
                </div>
                <div className={styles.inputItem5}>
                  <InputText
                    onValueChanged={onHandleChange('customerGivenNameKana')}
                    value={customerGivenNameKana}
                    className={kanaNameInputClass}
                    disabled={!canEditName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize1}>
              <Label textObj={langResource.addressPage.custermerName} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem4}>
                  <InputText
                    onValueChanged={onHandleChange('customerFamilyNameKanji')}
                    value={customerFamilyNameKanji}
                    className={customerNameKanjiInputClass}
                    disabled={!canEditName}
                  />
                  <div className="error-tooltip">{customerNameKanjiErrorMsg}</div>
                </div>
                <div className={styles.inputItem5}>
                  <InputText
                    onValueChanged={onHandleChange('customerGivenNameKanji')}
                    value={customerGivenNameKanji}
                    className={customerNameKanjiInputClass}
                    disabled={!canEditName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.subTitle}>
            <Title styles={['gray', 'medium']} textObj={langResource.addressPage.contactInfo} />
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize4}>
              <Label textObj={langResource.addressPage.phoneNumber} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <NumberFormat
                    readOnly={true}
                    value={shippingPhoneNumber}
                    allowNegative={false}
                    onFocus={connectNumericKeyboard('shippingPhoneNumber')}
                    data-input-type={EKeyboardInputType.numberText}
                    className={phoneNumberInputClass}
                    disabled={isEdit && !canEditAddressItem(AddressItem.phoneNumber)}
                  />
                  <div className="error-tooltip">{phoneNumberInputErrorMsg}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize4}>
              <Label textObj={langResource.addressPage.email} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem6}>
                  <InputText
                    onValueChanged={onHandleChangeWithIndex('customerMailAddress', 0)}
                    value={mailAddresses[0]}
                    className={emailInputClass}
                    disabled={isEdit && !canEditAddressItem(AddressItem.mailAddress)}
                  />
                </div>
                <div className={styles.inputItemMail}>@</div>
                <div className={styles.inputItem6}>
                  <InputText
                    onValueChanged={onHandleChangeWithIndex('customerMailAddress', 1)}
                    value={mailAddresses[1]}
                    className={emailInputClass}
                    disabled={isEdit && !canEditAddressItem(AddressItem.mailAddress)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize4}>
              <Label textObj={langResource.addressPage.memberscardNumber} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <InputText
                    onValueChanged={onHandleChange('memberscardNumber')}
                    value={memberscardNumber}
                    disabled={false}
                  />
                  <div className="error-tooltip">{memberscardNumberInputErrorMsg}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize4}>
              <Label textObj={langResource.addressPage.lotNumber} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <InputText onValueChanged={onHandleChange('lotNumber')} value={lotNumber} disabled={false} />
                  <div className="error-tooltip">{lotNumberInputErrorMsg}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnSize4}>
              <Label textObj={langResource.addressPage.cutterNameKana} />
              <div className={styles.inputWrap}>
                <div className={styles.inputItem3}>
                  <InputText
                    onValueChanged={onHandleChange('cutterNameKana')}
                    value={cutterNameKana}
                    disabled={false}
                  />
                  <div className="error-tooltip">{cutterNamaKanaInputErrorMsg}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDestination;
