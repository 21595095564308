import { combineReducers } from 'redux';
import { IProductState, reducer as productReducer } from './product/action-reducer';
import { IAvailableOptionState, availableOptionReducer } from './available-option/action-reducer';
import { ISizeMeasurementState, sizeMeasurementReducer } from './size-measurement/action-reducer';
import { IRecommendGaugeState, recommendedGaugeReducer } from './recommend-gauge/action-reducer';
import { adjustOptionReducer, IAdjustOptionState } from './adjust-option/action-reducer';

export * from './product';
export * from './available-option';
export * from './size-measurement';
export * from './recommend-gauge';
export * from './adjust-option';

export interface ILookupFeature {
  product: IProductState;
  availableOption: IAvailableOptionState;
  sizeMeasurement: ISizeMeasurementState;
  recommendGauge: IRecommendGaugeState;
  adjustOption: IAdjustOptionState;
}

export const reducers = combineReducers({
  product: productReducer,
  availableOption: availableOptionReducer,
  sizeMeasurement: sizeMeasurementReducer,
  recommendGauge: recommendedGaugeReducer,
  adjustOption: adjustOptionReducer,
});
