import { TRequestConfig, TRequestParams } from '../..';
// import config from '../../../configuration/config';
export { AvailableOptionRes } from './available-option.class';

export const availableOptionResRequestConfig: TRequestConfig = {
  endpoint: '/items/options/{brand_code}/{item_code}/{season_code}',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.pathParam : '';
    return {
      ok: true,
      body: JSON.stringify('{}'),
    };
  },
  params2request: (params): TRequestParams => {
    return {
      query: params ? params.query : undefined,
      pathParams: {
        brand_code: params ? params.pathParams.brandCode : '',
        item_code: params ? params.pathParams.itemCode : '',
        season_code: params ? params.pathParams.seasonCode : '',
      },
    };
  },
};
