import partial from 'ramda/es/partial';

import { AvailableOptionRes } from '../../../api/items/available-option';
import { IAvailableOption, IOptionPattern, IOptionClass } from '../../../store/_type/lookups';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { IAvailableOptionReq } from '../../../store/order/design/actions';
import { getAutoSettingOptionClassNumber } from '../../item-thisisforreplaceall';
import { AUTO_SETTING_OPTIONS } from '../../../lookups/item-thisisforreplaceall';

const getAutoSetOptionClassNumber = partial(getAutoSettingOptionClassNumber, [AUTO_SETTING_OPTIONS]);

export function canRequest(req: IAvailableOptionReq): boolean {
  const { brandCode, itemCode, seasonCode, optionPatterns } = req;
  const values = [brandCode, itemCode, seasonCode];
  if (values.some(v => v === '')) {
    return false;
  }
  if (optionPatterns.length < 1) {
    return false;
  }
  return true;
}

export function toRequestParam(req: IAvailableOptionReq) {
  const { brandCode, itemCode, seasonCode, optionPatterns } = req;
  const optionPattern = optionPatterns.join(',');
  return {
    pathParams: {
      brandCode,
      itemCode,
      seasonCode,
    },
    query: {
      optionPattern,
    },
  };
}

export function toAviliableOptions(
  brandCode: string,
  optionPatterns: string[],
  res: AvailableOptionRes,
): IAvailableOption[] {
  const expression = 'availableOptions';
  const resData = res.query(expression);
  const data = Array.from({ length: resData.length }).map((v, i) =>
    toOption(res, i, expression, brandCode, optionPatterns),
  );
  return data;
}

function toOption(
  res: AvailableOptionRes,
  index: number,
  parentExpression: string,
  brandCode: string,
  models: string[],
) {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const partsNumber = getValue<TPartsNumber>('partsNumber');
  const partsName = getValue<string>('partsName');
  const optionPatterns = getValue<any>('optionPatterns');
  const childrenExpression = getExpression('optionPatterns');
  const patterns = Array.from({ length: optionPatterns.length }).map((v, i) =>
    toPattern(res, i, childrenExpression, brandCode, models),
  );
  const data: IAvailableOption = {
    partsNumber,
    partsName,
    optionPatterns: patterns,
  };
  return data;
}

function toPattern(
  res: AvailableOptionRes,
  index: number,
  parentExpression: string,
  brandCode: string,
  models: string[],
) {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  const optionPattern = getValue<string>('optionPattern');
  const optionPatternName = getValue<string>('optionPatternName');
  const optionNumber = getValue<string>('optionNumber');
  const optionName = getValue<string>('optionName');
  const isRequired =
    getValue<string>('optionNumber') !== '9003' &&
    (getValue<string>('required') === '1' || getValue<string>('required') === '__required__')
      ? true
      : false;
  const isSpecial = getValue<string>('specialOptionFlag') === '0' ? true : false;
  const isTakeoverability = getValue<string>('takeoverability') === '1' ? true : false;
  const optionClasses = getValue<any>('optionClasses');
  const childrenExpression = getExpression('optionClasses');
  const classes = optionClasses
    ? Array.from({ length: optionClasses?.length || 0 }).map((v, i) =>
        toOptionClasses(res, i, childrenExpression, optionClasses?.length || 0),
      )
    : [];
  const autoSettingClassNumber = getAutoSetOptionClassNumber(optionNumber, brandCode, models, classes);

  const data: IOptionPattern = {
    optionPattern,
    optionPatternName,
    optionNumber,
    optionName,
    isRequired,
    isSpecial,
    isTakeoverability,
    optionClasses: classes,
    autoSettingClassNumber,
  };

  return data;
}

function toOptionClasses(res: AvailableOptionRes, index: number, parentExpression: string, length: number) {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  const optionClassNumber =
    getValue<string>('optionClassNumber') !== '' ? getValue<string>('optionClassNumber') : '999';
  const optionClassName = getValue<string>('optionClassName');
  // 選択肢が1つしかない場合、selector側で反映されるので、デフォルトフラグを設定しない
  const defaultFlag = getValue<string>('defaultFlag') === '1' && length > 1;
  const retailPrice = getValue<number>('retailPrice');
  const retailPriceTaxin = getValue<number>('retailPriceTaxin');
  const imagePath = getValue<string>('imagePath');
  const data: IOptionClass = {
    optionClassNumber,
    optionClassName,
    isDefault: defaultFlag,
    retailPrice,
    retailPriceTaxin,
    imagePath,
  };
  return data;
}
