import { History } from 'history';

import { resolvePath, getOrderDetailPath } from '../common/path';
import { ERouterPath } from '../../types';
import { commonPageActions } from '../../store/pages/common';
import { okCancelDialogActions } from '../../store/utils/dialog/ok-cancel';
import { IOKCancelDialog } from '../../types/dialog';
import { saveTmpOrderActions } from '../../store/utils/dialog/save-tmpOrder';
import { CommonPageEvents } from '../../views/events/CommonPage';

export function generateBackHomeBtnFunc(
  history: History,
  orderNumber: string,
  deleteOrder: typeof commonPageActions.deleteOrder.tp,
  showOkCancelDialog: typeof okCancelDialogActions.show.type,
): () => void {
  return () => {
    const onOK = () => {
      history.push(resolvePath(ERouterPath.home));
      // 下記の処理をすぐに実行するとselectorでerrorが発生するため
      setTimeout(() => {
        deleteOrder({ orderNumber });
      }, 50);
    };

    const dialog: IOKCancelDialog = {
      hasOpen: true,
      title: 'Confirm',
      contents: '現在の注文をキャンセルしてホーム画面に移動しますか？',
      onOK,
    };
    showOkCancelDialog(dialog);
  };
}

export function generateSaveTempBtnFunc(saveTmpOrderDialog: typeof saveTmpOrderActions.show.type): () => void {
  return () => {
    saveTmpOrderDialog();
  };
}

export function generateNextItemConfirmBtnFunc(
  history: History,
  orderNumber: string,
  deleteOrder: typeof commonPageActions.deleteOrder.tp,
  showOkCancelDialog: typeof okCancelDialogActions.show.type,
): () => void {
  return () => {
    const onOK = () => {
      history.push(resolvePath(ERouterPath.itemConfirmation));
      // 下記の処理をすぐに実行するとselectorでerrorが発生するため
      setTimeout(() => {
        deleteOrder({ orderNumber });
      }, 50);
    };

    const dialog: IOKCancelDialog = {
      hasOpen: true,
      title: 'Confirm',
      contents: '現在の注文をキャンセルして商品内容確認画面に移動しますか？',
      onOK,
    };
    showOkCancelDialog(dialog);
  };
}

export function generateCancelModifiedOrderBtnFunc(
  history: History,
  serial: string,
  cancelModifiedOrder: typeof CommonPageEvents.cancelModifiedOrder,
  showOkCancelDialog: typeof okCancelDialogActions.show.type,
): () => void {
  return () => {
    const onOK = () => {
      const path = getOrderDetailPath(serial);
      history.push(path);
      cancelModifiedOrder();
    };

    const dialog = {
      hasOpen: true,
      title: '確認',
      contents: '修正をキャンセルして、注文確認画面に戻りますか？',
      onOK,
    };
    showOkCancelDialog(dialog);
  };
}
