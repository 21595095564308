import React from 'react';

import styles from './SpecialAdjustOptionListItem.module.scss';

import Label from '../../atoms/Label/Label';
import SelectBox from '../../atoms/SelectBox/SelectBox';

import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';

import { ILookupItem } from '../../../types/lookup';
import { IItem } from '../../../store/_type/order';
import { IAdjustOptionItem } from '../../../types/new-store/components';

interface IProps {
  item: IItem;
  adjustOption: IAdjustOptionItem;
  onChangeAdjustOption: typeof SizeCorrectionPageEvents.onChangeAdjustOption;
}

const SpecialAdjustOptionListItem: React.FC<IProps> = (props: IProps) => {
  const { adjustOption } = props;
  const {
    optionName,
    optionNumber,
    historyOptionClassName,
    selectableOptionClasses,
    selectedOptionClass,
  } = adjustOption;

  const onChangeSizeAdjust = (v: ILookupItem) => {
    const optionClassNumber = String(v.id);
    props.onChangeAdjustOption({ optionNumber, optionClassNumber, selectedOptionClass });
  };

  return (
    <div id={styles.container}>
      <div className={styles.row}>
        <div className={styles.history}>
          <div className={styles.value}>{historyOptionClassName}</div>
        </div>
        <div className={styles.basegauge}>
          <div className={styles.value}>-</div>
        </div>
        <div className={styles.sizename}>
          <div className={styles.value}>
            <Label text={optionName} translate={false} />
          </div>
        </div>
        <div className={styles.adjustment}>
          <div className={styles.value}>
            <SelectBox
              data={selectableOptionClasses}
              selectedOption={selectedOptionClass}
              canDisplayEmpty={false}
              onValueChanged={onChangeSizeAdjust}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialAdjustOptionListItem;
