import { createSelector } from 'reselect';

import { AppState } from '..';
import { scProjectReducer } from './action-reducer';

type TScProjectState = ReturnType<typeof scProjectReducer>;
const featureSelector = (state: AppState): TScProjectState => state.scProject;

export const scProjectSelector = {
  selectedProject: createSelector(featureSelector, state => state),
};
