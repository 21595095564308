import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import styles from './ItemConfirmationPage.module.scss';

import Footer from '../../organisms/Footer/Footer';
import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { TPageContainerProps } from '../../../containers/pages/ItemConfirmationPage';

import TotalPaymentDetail from '../../molecules/TotalPayementDetails/TotalPayementDetails';
import ItemConfirmationDetail from '../../organisms/ItemConfirmationDetail/ItemConfirmationDetail';
import { langResource } from '../../../i18n/_new/resources';
import { resolvePath } from '../../../helpers/common/path';

type TProps = TPageContainerProps & RouteComponentProps;

class ItemConfirmationPage extends Component<TProps> {
  componentDidMount() {
    this.props.onLoadInitialize();
  }

  render() {
    const { props } = this;
    const { orderConfirmations } = props;

    const onEditOrder = (path: ERouterPath, orderNumber?: string) => {
      if (orderNumber) {
        this.props.onEditOrder({ orderNumber });
      }
      this.props.history.push(resolvePath(path));
    };

    return (
      <div className={styles.orderConfirmation}>
        <div className={styles.left}>
          {orderConfirmations.map((order, index) => {
            return <ItemConfirmationDetail key={index} {...props} order={order} onEditOrder={onEditOrder} />;
          })}
        </div>
        <div className={styles.right}>
          <TotalPaymentDetail {...props} />
        </div>
        {utility(props)}
      </div>
    );
  }
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      isDisabled: props.isEdit,
      textObj: langResource.footer.addOrder,
      path: ERouterPath.home,
    },
    {
      type: 'confirm',
      isDisabled: !props.isValidAllOrders,
      textObj: props.isEdit ? langResource.footer.orderContentsEdit : langResource.footer.settlement,
      path: props.isEdit ? ERouterPath.orderDetail :  ERouterPath.address,
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      {/* // FIXME: あとで直す */}
      {/* <LoadingInfo isLoading={false} /> */}
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(ItemConfirmationPage);
