import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { AppState } from '../../index';

import { navigationReducer, INavigationState } from './reducer';

export { navigationReducer } from './reducer';
export * from './actions';

export interface INavigationFeature {
  navigation: INavigationState;
}

export const reducers = combineReducers({
  navigation: navigationReducer,
});

const featureSelector = (state: AppState): INavigationFeature => state.utils.navigation;

const getNavigationState = createSelector(featureSelector, state => state);

export const get = {
  currentPath: createSelector(getNavigationState, state => state.navigation.currentPath),
};
