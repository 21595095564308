import { TRequestConfig, TRequestInfo, TResponseInfo, TMockIncomingMessage } from '../';
import config from '../../configuration/config';

// TODO: この辺、とりあえず持ってきた
import { getToken } from '../../services/auth';
import { toSnakeCaseObject, toCamelCaseObject, toSnakeCase } from '../../helpers/conv-object/convertions';
import { ApiError } from '../../models/error/api-error';
import { IndexedObject } from '../../types';
import Logger from '../../helpers/common/logger';

interface IResJson {
  statusCode: number;
  json: any;
}

function getResJson(response: Response | TMockIncomingMessage): Promise<IResJson> {
  return new Promise((resolve, reject) => {
    response
      .json()
      .then(res => resolve({ statusCode: response.status, json: res }))
      .catch(ex => {
        reject(new ApiError([{ code: response.status.toString(), message: response.statusText }], response.status));
      });
  });
}

function getSearchParam(queryParams?: IndexedObject, isConvertCamelCase?: boolean): string {
  if (!queryParams) {
    return '';
  }

  const usp = new URLSearchParams();
  Object.keys(queryParams).forEach(param => {
    const value = queryParams[param];
    if (value !== null && value !== '' && value !== undefined) {
      usp.append(isConvertCamelCase ? param : toSnakeCase(param), value);
    }
  });
  return `?${usp.toString()}`;
}

function getEndPoint(base: string, pathParams?: IndexedObject) {
  if (!pathParams) {
    return base;
  }

  return Object.keys({ ...pathParams }).reduce((url, key) => {
    return base.indexOf(key) === -1 ? url : url.replace(`{${key}}`, pathParams[key]);
  }, base);
}

export const fetchApi = <T>(
  ResContainer: new (params: any, req: TRequestInfo, res: TResponseInfo) => T,
  reqConfig: TRequestConfig,
  params?: { [key: string]: any },
): Promise<T> => {
  const token = getToken();

  const reqParams = reqConfig.params2request(params);
  const method = reqConfig.method;
  const _headers: Array<[string, string]> = [
    ['Content-Type', 'application/json'],
    ['token', token],
  ];

  // ステージング環境の場合basic認証を追加
  // if (config.isDev) {
  //   _headers.push(['Authorization', 'Basic MTExMTExMTE6MTEyMjMzNDQ=']);
  // }

  const headers = {
    ..._headers.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {}),
    ...reqParams.headers,
  };
  // isNotConvertがtrueの場合, camelCaseからsnakeCaseへ変換しない
  const body = !reqParams.body
    ? ''
    : reqConfig.isNotConvert
    ? JSON.stringify(reqParams.body)
    : JSON.stringify(toSnakeCaseObject(reqParams.body));
  const endpoint = getEndPoint(reqConfig.endpoint, reqParams.pathParams);
  const query = getSearchParam(reqParams.query, reqConfig.isConvertCamelCase);
  const options: RequestInit = ['POST'].includes(method) ? { method, headers, body } : { method, headers };
  Logger.log('API endpoint: ', `${config.api}${endpoint}${query}`);
  const fetchPromise: Promise<Response | TMockIncomingMessage> =
    reqConfig.useMock !== true || !reqConfig.mockFunc
      ? fetch(`${config.api}${endpoint}${query}`, options)
      : new Promise<TMockIncomingMessage>((resolve, reject) => {
          setTimeout(() => {
            if (typeof reqConfig.mockFunc === 'function') {
              const mockRes = reqConfig.mockFunc(params);

              resolve({
                status: mockRes.status || 200,
                statusText: '',
                ok: typeof mockRes.ok === 'boolean' ? mockRes.ok : true,
                headers: mockRes.headers || {},
                json: () => new Promise((rs, rj) => rs(JSON.parse(mockRes.body))),
              } as TMockIncomingMessage);
            } else {
              reject('mockFunc is not a funciton.');
            }
          }, 50);
        });

  return new Promise((resolve, reject) => {
    const reqres = {
      params,
      req: { endpoint, method, headers, body } as TRequestInfo,
      res: {
        statusCode: 0,
        ok: false,
        headers: {},
        // body: '',
        json: null,
      } as TResponseInfo,
    };

    fetchPromise
      .then((res: Response | TMockIncomingMessage) => {
        reqres.res.statusCode = res.status;
        reqres.res.ok = res.ok;
        reqres.res.headers = res.headers;
        return res;
      })
      .then(getResJson)
      .then(toCamelCaseObject)
      .then((parsedBody: IResJson) => {
        const { json, statusCode } = parsedBody;
        if (Object(json).hasOwnProperty('errors')) {
          reject(new ApiError(json.errors, statusCode));
        } else if (
          Object(json).hasOwnProperty('errorCode') &&
          Object(json).hasOwnProperty('error') &&
          Object(json).hasOwnProperty('errorDescription')
        ) {
          // smaregiApi用エラー処理
          reject(
            new ApiError([{ code: json.errorCode, message: `${json.errorDescription} (${json.errorCode})` }], 400),
          );
        }
        reqres.res.json = json;
        resolve(new ResContainer(reqres.params, reqres.req, reqres.res));
      })
      .catch(ex => {
        if (ex instanceof ApiError) {
          reject(ex);
        } else {
          Logger.log('fetcher catch exception not ApiError', ex);
          reject(new ApiError(ex, 400));
        }
      });
  });
};
