import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../store';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import './App.scss';
import history from '../helpers/common/history';
import AppRouterGuard from './AppRouterGuard';
import MainTemplate from './templates/MainTemplate/MainTemplate';
import ErrorDialog from '../containers/components/dialogs/ErrorDialog/ErrorDialog';
import ScrollToTop from './organisms/ScrollToTop/ScrollToTop';

const store = configureStore({});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <ScrollToTop>
              <AppRouterGuard>
                <MainTemplate />
              </AppRouterGuard>
            </ScrollToTop>
          </Router>
        </ConnectedRouter>
        <ErrorDialog />
      </Provider>
    );
  }
}

export default App;
