import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('- SettlementPage');

export const settlementPageEvents = {
  /**
   * オーダー送信
   */
  onRegistOrders: actionCreator<void>('onRegistOrders'),
  /**
   * オーダー削除
   */
  onDeleteOrders: actionCreator<void>('onDeleteOrders'),
};
