import { fetchApi } from '../../api';
import { ProgressRes, regProgressRequestConfig, loadProgressRequestConfig } from '../../api/progress';
import { AppState } from '../../store/index';

export interface IProgressRequest {
  progressId: string;
  progressName: string;
  progressType: string;
  progressData: string;
}

export async function regProgress(params: IProgressRequest): Promise<ProgressRes> {
  return fetchApi<ProgressRes>(ProgressRes, regProgressRequestConfig, params);
}

export async function loadProgress(progressId: string): Promise<AppState | undefined> {
  const loadRes = await fetchApi<ProgressRes>(ProgressRes, loadProgressRequestConfig, { progressId });
  return loadRes.query('progresses');
}
