import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import MainPage from '../../components/pages/MainPage/MainPage';

// import * as fromNavigation from '../../store/navigation';

class MainPageContainer extends Component {
  componentDidMount() {
    // this.props.getNavBars();
  }
  render() {
    return <MainPage />;
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    // getNavBars: () => dispatch<any>(fromNavigation.HomeRequest()),
  };
}

export default connect(null, mapDispatchToProps)(MainPageContainer);
