import { TRequestConfig, TRequestParams } from '../..';
export { CouponRegistRes } from './CouponRegistRes.class';

export const couponRegistRequestConfig: TRequestConfig = {
  endpoint: '/tempo/api/ambassodor.php',
  method: 'POST',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      body: params ? params.body : {},
    };
  },
};
