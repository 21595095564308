import React from 'react';

import styles from './ConfirmationSubHeader.module.scss';

import Title from '../../atoms/Title/Title';
import Button from '../../atoms/Button/Button';

import { II18nItem } from '../../../i18n/_new/types';
import { langResource } from '../../../i18n/_new/resources';

interface IProps {
  headerObj: II18nItem;
  hasButton?: boolean;
  buttonAction?: () => void;
  isDisabledEdit?: boolean;
}

const ConfirmationSubHeader: React.FC<IProps> = (props: IProps) => {
  const { headerObj, hasButton, buttonAction } = props;
  if (!hasButton) {
    return (
      <div className={styles.header}>
        <Title styles={['gray', 'medium']} textObj={headerObj} />
      </div>
    );
  }
  return (
    <div className={styles.header}>
      <Title styles={['gray', 'medium']} textObj={headerObj} />
      <div className={styles.button}>
        <Button
          onClick={buttonAction}
          styles={['size-s']}
          textObj={langResource.confirmationPage.editBtn}
          disabled={props.isDisabledEdit}
        />
      </div>
    </div>
  );
};

ConfirmationSubHeader.defaultProps = {
  hasButton: false,
};

export default ConfirmationSubHeader;
