export const stepbar = {
  closeSelection: { jp: '生地選択' },
  designSelection: { jp: 'デザイン選択' },
  sizeCorrection: { jp: 'サイズ補正' },
  itemConfirmation: { jp: '商品内容確認' },
  address: { jp: 'お届け先・日時' },
  point: { jp: 'ポイント・値引き金額' },
  order: { jp: '注文内容確認' },
  settlement: { jp: '決済' },
};
