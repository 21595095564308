import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

import cssStyles from './SelectBox.module.scss';

import Label from '../Label/Label';
import { TLookup, ILookupItem } from '../../../types/lookup';
import { Optional } from '../../../types';
import { langResource } from '../../../i18n/_new/resources';

type TEventSelect = React.ChangeEvent<{
  name?: string | undefined;
  value: any;
}>;

interface IProps {
  data: TLookup;
  selectedOption?: Optional<string | number>;
  onValueChanged: (value: ILookupItem) => void;
  disabled?: boolean;
  canDisplayEmpty?: boolean;
  className?: string;
}

const getClassName = (base: string, plainClassName: string): string => {
  const classNames = [base, plainClassName];
  return classNames.filter(v => !!v).join(' ');
};

const SelectBox: React.FC<IProps> = ({
  data,
  selectedOption,
  onValueChanged,
  disabled,
  canDisplayEmpty = true,
  className = '',
}) => {
  return (
    <div>
      <Select
        disabled={disabled || data?.length < 1}
        className={getClassName(cssStyles.selectBox, className)}
        value={selectedOption}
        onChange={onChangeHandler}
        displayEmpty={canDisplayEmpty}
      >
        {showSearchDefaultMessage()}

        {data.map((item: ILookupItem) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.value}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );

  function onChangeHandler(event: TEventSelect) {
    const value = event.target.value;
    const selected = data.find(x => x.id === value) as ILookupItem;
    if (value !== '') {
      onValueChanged(selected);
    }
  }

  function showSearchDefaultMessage() {
    if (selectedOption === '' && disabled !== true) {
      return (
        <MenuItem value="">
          <em>
            <Label textObj={langResource.components.selectBox.defaultMessage} />
          </em>
        </MenuItem>
      );
    }
  }
};

export default SelectBox;
