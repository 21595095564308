import { thunkActionObjectCreatorFactory, actionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { ILookupItem } from '../../../types/lookup';
import { IError } from '../../../types/error';
import { errorDialogActions } from '../dialog/error';
import { handleActionError } from '../../../helpers/error-handler';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { II18nItem } from '../../../i18n/_new/types';

// actions
const actionTitle = 'language';
const ac = actionObjectCreatorFactory(actionTitle);
const actions = {
  changeLangCode: ac<keyof II18nItem>(`[${actionTitle}] changeLangCode`),
};

// reducer
export interface ILanguageState {
  code: keyof II18nItem;
}

export const initialState: ILanguageState = {
  code: 'jp',
};

export const langReducer = reducerWithInitialState(initialState).case(
  actions.changeLangCode._action,
  (state, payload) => ({ ...state, code: payload }),
);

// TODO: helperに移動？？？
const toLangCode = (id: number): keyof II18nItem => {
  switch (id) {
    case 1:
      return 'en';
    case 2:
      return 'zh';
    default:
      return 'jp';
  }
};

// asyncAction
const asyncAc = thunkActionObjectCreatorFactory();
export const langActions = {
  changeLang: asyncAc<ILookupItem, any>(`[${actionTitle}] changeLang`, async (payload, dispatch) => {
    try {
      const langCode = toLangCode(+payload.id);
      actions.changeLangCode.dispatcher(dispatch)(langCode);
    } catch (ex) {
      const next = (errors: IError[]) => dispatch(errorDialogActions.showErrors._action(errors));
      handleActionError(ex, next);
      return [];
    }
  }),
};
