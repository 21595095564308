export const footer = {
  save: {
    jp: '一時保留',
  },
  confirm: {
    jp: '商品内容確認へ',
  },
  home: {
    jp: 'ホームへ',
  },
  next: {
    jp: '次へ',
  },
  back: {
    jp: '前へ',
  },
  backToDigests: {
    jp: '注文検索に戻る',
  },
  cancelEditOrder: {
    jp: '修正キャンセル',
  },
  updateOrder: {
    jp: '更新',
  },
  return: {
    jp: '戻る',
  },
  orderContents: {
    jp: '注文内容確認',
  },
  orderContentsEdit: {
    jp: '注文修正確認',
  },
  addOrder: {
    jp: '商品追加',
  },
  order: {
    jp: '商品決定',
  },
  orderConfirm: {
    jp: 'オーダー確定',
  },
  print: {
    jp: '印刷',
  },
  settlement: {
    jp: '決済',
  },
  complete: {
    jp: '完了',
  },
  undefinded: {
    jp: '未設定ボタン',
  },
};
