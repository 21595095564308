import React from 'react';

import styles from './DesignOptionFreeInput.module.scss';

import InputText from '../../../containers/components/InputText';

import { IOptionPatternWithSelectedInfo } from '../../../store/_type/lookups';
import { langResource } from '../../../i18n/_new/resources';
import { TPageContainerProps } from '../../../containers/pages/DesignSelectionPage';
import { getPartsNumberByPieces } from '../../../helpers/parts';
import { isInputEmbroideryRequired, getOptions, getEmbroideryType } from '../../../helpers/item-thisisforreplaceall';
import { isValidEmbroidery, getInvalidedEmbroideryMessage } from '../../../helpers/common/validate';
import { CSS_CLASSNAME_ERROR_INVALID_DATA } from '../../../lookups/html-css';
import I18Text from '../../../containers/I18Text/I18Text';
import { OPS_BRANDS } from '../../../lookups/item-thisisforreplaceall/cloth';

type TProps = Pick<TPageContainerProps, 'item' | 'onInputFree'> & { optionPattern: IOptionPatternWithSelectedInfo };

const DesignOptionFreeInput: React.FC<TProps> = (props: TProps) => {
  const { item } = props;
  const { selectingClassName } = props.optionPattern;
  const { partsIndex, optionClassNumber } = item.design.selecting;
  const partsNumber = getPartsNumberByPieces(partsIndex, item.pieces);
  const options = getOptions(partsIndex, item.design.designParts);
  const isRequired = isInputEmbroideryRequired(options, partsNumber);
  const embroideryType = getEmbroideryType(options, partsNumber);
  const isValid =
    !selectingClassName || isValidEmbroidery(selectingClassName, embroideryType, partsNumber, item.categoryCode);
  const inputClass = isValid ? undefined : CSS_CLASSNAME_ERROR_INVALID_DATA;
  const errTextObj = isValid ? undefined : getInvalidedEmbroideryMessage(embroideryType, partsNumber);

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionClassName = event.target.value;
    props.onInputFree({ optionClassName, optionClassNumber, item });
  };

  return (
    <div className={styles.content}>
      <div className={styles.item}>
        <div className={styles.card}>
          <div className={styles.input}>
            <InputText
              value={selectingClassName}
              onValueChanged={onChangeText}
              disabled={!isRequired}
              placeholder={langResource.designPage.freeInputPlaceholder}
              className={inputClass}
            />
            {errTextObj ? (
              <div className="error-tooltip">
                <I18Text textObj={errTextObj} />
              </div>
            ) : null}
          </div>
          <div className={styles.text}>
            <p>
              <span>※</span>ローマ字（筆記体）の場合→半角英数「・」のみ。最初の文字とピリオドの後は、大文字になります。
            </p>
            {item.categoryCode === 'CS' && OPS_BRANDS.includes(item.cloth.brandCode) ? (
              <p>
                <span>※</span>
                ドイツ文字・ブロック体の場合→半角英数「・」のみ。最初の文字とピリオドの後は、大文字になります。
              </p>
            ) : null}
             {item.categoryCode === 'DS' ? (
              <p>
                <span>※</span>
                スクリプトの場合、半角英大文字×1文字のみになります。
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignOptionFreeInput;
