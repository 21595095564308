import { TMaster } from '../../types/index';

export const EDeliveryMethod = {
  // delivery: '個宅配',
  storeReceipt: '店舗渡し',
  // warehouseDelivery: '支店配',
  // groupDelivery: '一括配',
  // partnerDelivery: '取引先',
};

// MEMO: 送信するときはコードじゃなくて、名称を送信する
export const MASTER_DELIVERY_METHOD: TMaster[] = [
  // { code: EDeliveryMethod.delivery, value: '個宅配' },
  { code: EDeliveryMethod.storeReceipt, value: '店舗渡し' },
  // { code: EDeliveryMethod.warehouseDelivery, value: '支店配' },
  // { code: EDeliveryMethod.groupDelivery, value: '一括配' },
  // { code: EDeliveryMethod.partnerDelivery, value: '取引先' },
];
