import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { getInformationDialog, infoDialogActions } from '../../../store/utils/dialog/info';
import InformationDialog from '../../../components/organisms/InformationDialog/InformationDialog';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: getInformationDialog.hasOpen(state),
    data: getInformationDialog.data(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClose: infoDialogActions.close.dispatcher(dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type TInformationDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(InformationDialog);
