import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';

// import Label from '../Label/Label';
import I18TextContainer from '../../../containers/I18Text/I18Text';

import cssStyles from './CheckBox.module.scss';
import { II18nItem } from '../../../i18n/_new/types';
interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textObj?: II18nItem;
  checked?: boolean;
  styles?: string[];
  disabled?: boolean;
  value?: string;
  label?: string;
  isTranslate?: boolean;
}

const getClassName = (base: string, styles: string[]): string => {
  const classNames = [base];
  if (styles.find(v => v === 'large')) {
    classNames.push(cssStyles.large);
  }

  return classNames.join(' ');
};

const getSpanClassName = (styles: string[]) => {
  if (styles.find(v => v === 'large')) {
    return cssStyles.largeSpan;
  }
  return;
};

const CheckBox: React.FC<IProps> = ({
  textObj,
  onChange,
  checked,
  styles = [],
  disabled,
  value,
  label,
  isTranslate = true,
}) => {
  return (
    <React.Fragment>
      <div className={getClassName(cssStyles.checkGroup, styles)}>
        <label>
          <input type="checkbox" disabled={disabled} checked={checked} value={value} onChange={onChange} />
          <span className={getSpanClassName(styles)}>{getLabel()}</span>
        </label>
      </div>
    </React.Fragment>
  );

  function getLabel() {
    if (textObj) {
      return <I18TextContainer textObj={textObj} />;
    }

    if (isTranslate) {
      return <I18TextContainer textKey={label} />;
    }
    return label;
  }
};

export default CheckBox;
