import { IItem } from '../../store/_type/order';
import { TCategory } from '../../lookups/master-thisisforreplaceall/category';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall/parts';
import { getDefaultOptionPattern4AddedPart } from '../item-thisisforreplaceall/specific-code-getter';

export const getBaseItemInfo = (item: IItem) => {
  const { categoryCode, subCategoryCode, itemCode, pieces } = item;
  const { seasonCode, brandCode, clothModelCode } = item.cloth;
  const { isDouble, designParts } = item.design;
  return {
    categoryCode,
    subCategoryCode,
    itemCode,
    pieces,
    seasonCode,
    brandCode,
    isDouble,
    clothModelCode,
    designParts,
  };
};

export const getOptionPattern = (
  optionPatterns: string[],
  categoryCode: TCategory,
  partsNumber: TPartsNumber,
  clothModelCode: string,
  brandCode: string,
): string => {
  const patternCount = optionPatterns.length;
  if (patternCount === 0) {
    // デザイン画面でモデル選択できない場合
    return getDefaultOptionPattern4AddedPart(categoryCode, partsNumber, clothModelCode, brandCode) || '';
  } else if (patternCount === 1) {
    // 選択肢が1つしかない場合
    return optionPatterns[0];
  } else {
    // デザイン画面でモデル選択が必要な場合
    return '';
  }
};
