import { IApiError } from '../../types/api';
import { getClientMessage } from '../../helpers/common/message';

// FIXME: 仮です。
export class SystemError extends Error {
  private _errors: IApiError[] = [];

  constructor(code: string, params: string[] = []) {
    super();
    const message = getClientMessage('err', code, [...params]);
    const error = { ...message };
    this._errors.push(error);
  }

  get errors() {
    return this._errors;
  }
}
