import { IOptionType, EOptionType, EEmbroideryType } from '../../types/option';
import { rawStrArr2EnumLikeObj } from '../../lib/utils';
import { EParts } from '../master-thisisforreplaceall/parts';
import { IOption } from '../../store/_type/order';
import {
  TPartsNumber,
  TPartsCount,
  NUDE_SIZE_OPTION_NUMBERS,
  NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT,
  NUDE_SIZE_OPTION_NUMBERS_OF_TOPS,
  NUDE_SIZE_OPTION_NUMBERS_OF_COAT,
  NUDE_SIZE_OPTION_NUMBERS_OF_ONEPIECE,
  NUDE_SIZE_OPTION_NUMBERS_OF_SKIRT,
} from '../master-thisisforreplaceall/';
import { KR_BRANDS } from './cloth';

export const NO_SELECT_OPTION_CLASS_NUMBER = '-1';

export const NO_SELECT_OPTION: IOptionType = {
  optionClassNumber: NO_SELECT_OPTION_CLASS_NUMBER,
  optionClassName: '未設定',
  defaultFlag: '0',
  retailPrice: 0,
  retailPriceTaxin: 0,
  imagePath: '',
};

export const INITIAL_PARTS_COUNT: TPartsCount = {
  [EParts.jaket]: 0,
  [EParts.pants]: 0,
  [EParts.vest]: 0,
  [EParts.skirt]: 0,
  [EParts.shirt]: 0,
  [EParts.onepiece]: 0,
  [EParts.coat]: 0,
  [EParts.tops]: 0,
};

export const EDesignOptionNumber = rawStrArr2EnumLikeObj([
  '1001',
  '1034',
  '1054',
  '1077',
  '1070',
  '1101',
  '1601',
  '1701',
]);

export type TDesignOptionNumber = keyof typeof EDesignOptionNumber;
export const OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE: string = '1001';
/**
 * ダブル判定用のオプションかどうか（ジャケットのときのみ利用される前提）
 */
export const isOptionNumberForJudgeIsDouble = (optionNumber: string): boolean =>
  optionNumber === OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE;
/**
 * シャツの場合、推奨サイズAPI、標準サイズリスト取得APIに設定するmodel_codeの値は
 * optionNumber「1089」で設定されたoptionClassNumberを設定する
 * （OPS_ORDERSYS-588 【XMLデータ】シャツの型番と衿型オプション種別不一致）
 */
export const SHIRT_MODEL_CODE_OPTION_NUMBER = '1089';

// "ダブル" となる optionClassNumber （DESIGN_OPTION_NUMBERS_OF_JACKET の時のみ有効）
export const DOUBLE_DESIGN_OPTION_CLASS_NUMBERS = ['123', '143', '131', '133'];

// オプションタイプ判別用の設定（optionNumberにて判定）
export const optionTypeConfig: Array<[EOptionType, { min?: number; max?: number; list?: string[] }]> = [
  [EOptionType.DESIGN_OPTION, { min: 0, max: 2999 }],
  [EOptionType.DESIGN_OPTION, { min: 4000, max: 6999 }],
  [EOptionType.ADJUST_SIZE_OPTION, { min: 3000, max: 3999 }],
  [
    EOptionType.NUDE_SIZE,
    {
      list: [
        ...NUDE_SIZE_OPTION_NUMBERS,
        ...NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT,
        ...NUDE_SIZE_OPTION_NUMBERS_OF_COAT,
        ...NUDE_SIZE_OPTION_NUMBERS_OF_TOPS,
        ...NUDE_SIZE_OPTION_NUMBERS_OF_ONEPIECE,
        ...NUDE_SIZE_OPTION_NUMBERS_OF_SKIRT,
      ],
    },
  ],
  [EOptionType.FINISHED_SIZE, { min: 7000, max: 7999 }],
  // [EOptionType.MEASURING, { min: 8000, max: 8999 }],
  [EOptionType.FREE_TEXT_OPTION, { min: 9000, max: 9999 }],
];

// 上記からジャケットのoptionNumberを取得する
export const FOLLOW_TO_JACKET_OPTION_CONFIG: Array<{ optionNumber: string; refJacketOptionNumber: string }> = [
  // ステッチ
  { optionNumber: '1091', refJacketOptionNumber: '1008' },
  // ボタン
  { optionNumber: '1058', refJacketOptionNumber: '1009' },
  { optionNumber: '1076', refJacketOptionNumber: '1009' },
  // 裏地
  { optionNumber: '1059', refJacketOptionNumber: '1010' },
];

// ジャケットに準ずるoptionNumber一覧
export const FOLLOW_TO_JACKET_OPTION_NUMBERS = FOLLOW_TO_JACKET_OPTION_CONFIG.map(v => v.optionNumber);

export const SPARE_OPTION_CONFIG: Array<{ mainOption: string; spareOption: string }> = [
  /** 裾口 & 裾口（替） */
  { mainOption: '1036', spareOption: '1037' },
];

/**
 * 刺繍ネームの文字Config
 * <刺繍字体: 1031>
 * なし: 001
 * ローマ字: 002
 * 漢字: 003
 *
 * <字体（シャツ）:1090>
 * なし: 001
 * ローマ字: 007
 * 花文字: 008
 * ブロック: 009
 * スクリプト: 010
 */
export const EMBROIDERY_TYPE_CONFIG: Array<{
  partsNumber: TPartsNumber;
  optionNumber: string;
  optionClassNumber: string;
  EmbroideryType: EEmbroideryType;
}> = [
  { partsNumber: EParts.jaket, optionNumber: '1031', optionClassNumber: '001', EmbroideryType: EEmbroideryType.NONE },
  { partsNumber: EParts.jaket, optionNumber: '1031', optionClassNumber: '002', EmbroideryType: EEmbroideryType.ROMA },
  { partsNumber: EParts.jaket, optionNumber: '1031', optionClassNumber: '003', EmbroideryType: EEmbroideryType.KANJI },
  { partsNumber: EParts.jaket, optionNumber: '1031', optionClassNumber: '004', EmbroideryType: EEmbroideryType.GERMAN },
  { partsNumber: EParts.jaket, optionNumber: '1031', optionClassNumber: '005', EmbroideryType: EEmbroideryType.BLOCK },
  { partsNumber: EParts.shirt, optionNumber: '1090', optionClassNumber: '001', EmbroideryType: EEmbroideryType.NONE },
  { partsNumber: EParts.shirt, optionNumber: '1090', optionClassNumber: '007', EmbroideryType: EEmbroideryType.ROMA },
  { partsNumber: EParts.shirt, optionNumber: '1090', optionClassNumber: '008', EmbroideryType: EEmbroideryType.HANA },
  { partsNumber: EParts.shirt, optionNumber: '1090', optionClassNumber: '009', EmbroideryType: EEmbroideryType.BLOCK },
  { partsNumber: EParts.shirt, optionNumber: '1090', optionClassNumber: '010', EmbroideryType: EEmbroideryType.SCRIPT },
  { partsNumber: EParts.coat, optionNumber: '1631', optionClassNumber: '001', EmbroideryType: EEmbroideryType.NONE },
  { partsNumber: EParts.coat, optionNumber: '1631', optionClassNumber: '002', EmbroideryType: EEmbroideryType.ROMA },
  { partsNumber: EParts.coat, optionNumber: '1631', optionClassNumber: '003', EmbroideryType: EEmbroideryType.KANJI },
];

/**
 * オプションを変更したときに、同一パーツ内で自動変更されるオプションがあれば、その変更後のIOptionを取得するための設定
 * ※ 変更が連鎖するようねケースでは利用不可
 */
export const AFFECTED_OPTION_CLASS_IN_SAME_PARTS_CONFIG: Array<{
  partsNumber: TPartsNumber;
  getNewOptionFuncs: { [optionNumberFrom: string]: (optionClassNumberFrom: string) => IOption | IOption[] | undefined };
}> = [
  {
    partsNumber: EParts.jaket,
    getNewOptionFuncs: {
      // 「刺繍字体」で
      1031: optionClassNumber => {
        // 「なし」を選択した時
        if (optionClassNumber === '001') {
          // 「刺繍ネーム」の入力値を削除
          return {
            optionNumber: '9003',
            optionClassNumber: '',
            optionClassName: '',
          };
        }
        return undefined;
      },
    },
  },
  {
    partsNumber: EParts.coat,
    getNewOptionFuncs: {
      // 「刺繍字体」で
      1631: optionClassNumber => {
        // 「なし」を選択した時
        if (optionClassNumber === '001') {
          // 「刺繍ネーム」の入力値を削除
          return {
            optionNumber: '9003',
            optionClassNumber: '',
            optionClassName: '',
          };
        }
        return undefined;
      },
    },
  },
  {
    partsNumber: EParts.shirt,
    getNewOptionFuncs: {
      // 「刺繍字体」で
      1090: optionClassNumber => {
        // 「なし」を選択した時
        if (optionClassNumber === '001') {
          return [
            // 「刺繍位置」を「無し」に設定
            {
              optionNumber: '1087',
              optionClassNumber: '002',
            },
            // 「刺繍糸色」を「ネイビー」に設定
            {
              optionNumber: '1088',
              optionClassNumber: '001',
            },
            // 「刺繍ネーム」の入力値を削除
            {
              optionNumber: '9003',
              optionClassNumber: '',
              optionClassName: '',
            },
          ];
        }
        return undefined;
      },
      // 「刺繍位置」で
      1087: optionClassNumber => {
        // 「なし」を選択した時
        if (optionClassNumber === '002') {
          return [
            // 「刺繍糸色」を「ネイビー」に設定
            {
              optionNumber: '1088',
              optionClassNumber: '001',
            },
            // 「刺繍字体」を「無し」に設定
            {
              optionNumber: '1090',
              optionClassNumber: '001',
            },
            // 「刺繍ネーム」の入力値を削除
            {
              optionNumber: '9003',
              optionClassNumber: '',
              optionClassName: '',
            },
          ];
        }
        return undefined;
      },
    },
  },
];

/**
 * 刺繍位置のconfig
 */
export const EMBROIDERY_POSITION_CONFIG: Array<{
  partsNumber: TPartsNumber;
  optionNumber: string;
  optionClassNumber: string;
  isNone: boolean;
}> = [{ partsNumber: EParts.shirt, optionNumber: '1087', optionClassNumber: '002', isNone: true }];

/**
 * （ジャケット）刺繍ネームがなしの場合、サイドバーで選択不可となるオプション一覧
 */
export const INFLUENCED_BY_EMBROIDERY_JACKET_OPTION_NUMBERS = ['9003'];

/**
 * （シャツ）刺繍ネームがなしの場合、サイドバーで選択不可となるオプション一覧
 */
export const INFLUENCED_BY_EMBROIDERY_SHIRT_OPTION_NUMBERS = ['1088', '9003'];

/**
 * （コート）刺繍ネームがなしの場合、サイドバーで選択不可となるオプション一覧
 */
export const INFLUENCED_BY_EMBROIDERY_COAT_OPTION_NUMBERS = ['9003'];

/**
 * 注文APIへ送るoptionsから削除する項目に関する設定
 */
export const OPTIONS_FILTER_CONFIG_IN_API_REQUEST_DATA: Array<{
  optionNumber: string;
  optionClassNumber: string | undefined;
  shouldRemove: (option: IOption) => boolean;
}> = [{ optionNumber: '9003', optionClassNumber: '', shouldRemove: v => !v.optionClassName }];

/**
 * 選択したオプションにより同一パーツ内の他のオプションの選択に制限がかかる場合の条件を定義、
 * 特定のIOptionを渡すことでその選択が可能かどうかを返却する関数群を返す。
 */
export const OPTION_COMBINATION_FUNCTIONS_CONFIG: Array<{
  partsNumber: TPartsNumber;
  getCondition: {
    [optionNumberFrom: string]: (optionClassNumberFrom: string) => Array<(v: IOption) => boolean> | undefined;
  };
}> = [
  {
    partsNumber: EParts.shirt,
    getCondition: {
      // 「刺繍位置」で
      1087: optionClassNumber => {
        // 「なし」を選択した時
        if (optionClassNumber === '002') {
          // 「刺繍字体」は「なし」しか選べない
          return [v => v.optionNumber === '1090' && v.optionClassNumber === '001'];
        }
        // 「刺繍字体」は「なし」は選べない
        return [v => v.optionNumber === '1090' && v.optionClassNumber !== '001'];
      },
      // 「刺繍位置」で
      1090: optionClassNumber => {
        // 「なし」を選択した時
        if (optionClassNumber === '001') {
          // 「刺繍位置」は「なし」しか選べない
          return [v => v.optionNumber === '1087' && v.optionClassNumber === '002'];
        }
        // 「刺繍位置」は「なし」は選べない
        return [v => v.optionNumber === '1087' && v.optionClassNumber !== '002'];
      },
    },
  },
];

/**
 * OPTION_COMBINATION_FUNCTIONS_CONFIGの刺繍ルール適用外。
 */
export const OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY: Array<{
  partsNumber: TPartsNumber;
  getCondition: {
    [optionNumberFrom: string]: (optionClassNumberFrom: string) => Array<(v: IOption) => boolean> | undefined;
  };
}> = [];

/**
 * 後ろスリットをありで自動設定するブランドKRのモデル
 */
const AUTO_SETTING_REAR_SLIT_MODELS = ['KS', 'SS'];

/**
 * 自動設定するオプション
 * 全て&条件
 */
export const AUTO_SETTING_OPTIONS: Array<{
  optionNumber: string;
  optionClassNumber: string;
  hasMatchedBrand: (v: string) => boolean;
  hasMatchedModels: (v: string[]) => boolean;
}> = [
  // ブランドFO、モデルFXの場合、後ろスリット => あり
  {
    optionNumber: '1053',
    optionClassNumber: '001',
    hasMatchedBrand: v => v === 'FO',
    hasMatchedModels: v => v.includes('FX'),
  },
  // ブランドKR、モデルKS/SSの場合、後ろスリット => あり
  {
    optionNumber: '1053',
    optionClassNumber: '001',
    hasMatchedBrand: v => KR_BRANDS.includes(v),
    hasMatchedModels: v => v.some(vv => AUTO_SETTING_REAR_SLIT_MODELS.includes(vv)),
  },
  // 後ろスリット => なし
  {
    optionNumber: '1053',
    optionClassNumber: '002',
    hasMatchedBrand: v => true,
    hasMatchedModels: v => true,
  },
];

export const womenBlouseGroups = [
  {
    optionGroupName: 'BL-001 半袖Tブラウス',
    optionGroupId: 'b001',
    optionClassNumbers: [
      '001',
      '002',
      '003',
      '004',
      '005',
      '006',
      '007',
      '008',
      '009',
      '010',
      '011',
      '012',
      '013',
      '014',
      '015',
      '016',
    ],
  },
  {
    optionGroupName: 'BL-002 半袖ボウブラウス',
    optionGroupId: 'b002',
    optionClassNumbers: [
      '017',
      '018',
      '019',
      '020',
      '021',
      '022',
      '023',
      '024',
      '025',
      '026',
      '027',
      '028',
      '029',
      '030',
      '031',
      '032',
    ],
  },
  {
    optionGroupName: 'BL-006 長袖クルーネックブラウス',
    optionGroupId: 'b006',
    optionClassNumbers: ['033', '034', '035', '036'],
  },
  {
    optionGroupName: 'BL-007 長袖ギャザーブラウス',
    optionGroupId: 'b007',
    optionClassNumbers: ['037', '038', '039', '040'],
  },
  {
    optionGroupName: 'BL-008 長袖バンドカラーブラウス',
    optionGroupId: 'b008',
    optionClassNumbers: ['041'],
  },
  {
    optionGroupName: 'BL-009 レギュラーカラーシャツ',
    optionGroupId: 'b009',
    optionClassNumbers: ['042', '043', '044', '045'],
  },
];

export const OPTION_GROUPS = [
  {
    optionNumber: '1951',
    optionGroups: womenBlouseGroups,
  },
  {
    optionNumber: '1952',
    optionGroups: womenBlouseGroups,
  },
];
