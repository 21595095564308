import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../store';

import { getStaff, staffActions } from '../../store/staff';
import { getLanguage, langActions } from '../../store/utils/language';

import LoginPage from '../../components/pages/LoginPage/LoginPage';

function mapStateToProps(state: AppState) {
  return {
    loggingIn: getStaff.isLoggedIn(state),
    selectedLanguage: getLanguage.langCode(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onLogin: staffActions.login.dispatcher(dispatch),
    onChangeCurrentLanguage: langActions.changeLang.dispatcher(dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
