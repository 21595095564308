import { cloneDeep } from 'lodash';
import partial from 'ramda/es/partial';
import partialRight from 'ramda/es/partialRight';
import pipe from 'ramda/es/pipe';

import { IndexedObject, NestedPartial } from '../../../types/';
import {
  TNewOrderRequest,
  initialDataNewOrderRequest,
  initialDataNewOrderRequestItemParts,
  TOTAL_PRICE_ZERO_CONFIG,
  INVOICE_FLAG,
} from '../../../lookups/api/orders/new-order-request';
import { IOrderState } from '../../../store/order';
import { IClothProduct, IAvailableOption, ISizeMeasurement, IPartsAdjustOption } from '../../../store/_type/lookups';
import { initialDataNewOrderRequestItem } from '../../../lookups/api/orders/new-order-request';
import {
  KR_BRAND,
  KR_BRANDS,
  ADDTIONAL_COPY_NUDE_SIZE_CONFIG,
  KR_BRAND_COLOR_CODE,
  overWriteMeasurementItem,
} from '../../../lookups/item-thisisforreplaceall/';
import { getValue, getValueForItemCode } from '../../master-lookup';
import {
  MASTER_CLOTH_COLOR,
  MASTER_CLOTH_PARTITION,
  MASTER_DELIVERY_TIME_ZONE,
  TNudeDementionCode,
  MASTETR_NUDE_DEMENTION,
  TOrderItemCode,
  TCategory,
  MASTER_ITEM_TYPE,
  TPartsNumber,
} from '../../../lookups/master-thisisforreplaceall/';
import {
  TNewOrderRequestItemParts,
  TNewOrderRequestItemPartsOptions,
  initialDataNewOrderRequestItemPartsOptions,
} from '../../../lookups/api/orders/new-order-request';
import {
  availableOptionsSelector,
  editedAvailableOptionsSelector,
  clothProductsSelector,
  sizeMeasurementsViewSelector,
  adjustOptionsViewSelector,
  sizeMeasurementsSelector,
  adjustOptionsSelector,
} from '../../../store/lookups/object-selector';
import { format } from 'date-fns';
import { SystemError } from '../../../models/error/system-error';
import { IStaff } from '../../../store/_type/staff';
import { isShirtOrder } from '../../orders/category';
import { isValidMemberscardNumber } from '../../../store/order/helper/validate';
import { IPayment } from '../../../store/_type/payment';
import { joinGauge } from '../../size-correction';
import { shouldSendForServerNudeSize, shouldSendForServerFreeInput } from '../../item-thisisforreplaceall';
import { piecesSelector, compositionSelector } from '../../../store/order/object-selector';
import { IOption, TProductKind } from '../../../store/_type/order';
import { by } from '../..';
import config from '../../../configuration/config';
import { isSendOkOption } from '../../item-thisisforreplaceall/what-is-the-option-optionClass';
import Logger from '../../common/logger';
import {
  IGNORE_CLIENT_CHANGED_ITEM_PROPERTIES,
  NOT_EDITABLE_ORDER_PROPERTIES,
  NOT_EDITABLE_ITEM_PROPERTIES,
  CLIENT_VALUE_ORDER_PROPERTIES,
} from '../../../lookups/api/orders/edit-order-request';
import { shouldBeTotalPriceZero } from '../../orders';
import { replaceMatchedKeyObjectProps } from '../..';
import { TScProject } from '../../../types/project';
import { getRequestCustomerMailAddress } from '../../orders/customerEmailAdress';

export const getNewOrderRequest = (
  memberscardNumber: string,
  staff: IStaff,
  orderState: IOrderState,
  payment: IPayment,
  scProject: TScProject,
  allProducts: IndexedObject<IClothProduct[]>,
  allAvailableOptions: IndexedObject<IAvailableOption[]>,
  allSizeMeasurements: IndexedObject<ISizeMeasurement[]>,
  allAdjustOptions: IndexedObject<IPartsAdjustOption[]>,
): TNewOrderRequest[] => {
  const orderNumbers = Object.keys(orderState.orders);
  if (orderNumbers.length < 1) {
    throw new SystemError('0020', ['no order']);
  }

  // payment
  const {
    introducerName,
    introducerCode,
    favoriteCustomerName,
    favoriteCustomerCode,
    deliveryRate,
    hasFavoriteCustomer,
    needInvoice,
  } = payment;

  // ordersと並列に保持されているデータ
  const { productKind, isEdit } = orderState;
  const firstOrder = orderState.orders[orderNumbers[0]];
  const { serialNumber, serialYear } = firstOrder;
  const orderRequests = Object.entries(orderState.orders).map(
    ([orderIndex, order]): TNewOrderRequest => {
      const reqData = cloneDeep(initialDataNewOrderRequest);

      // order.item オブジェクト
      const item = order.item;
      const shipping = order.shipping;
      const { itemCode, pieces, cloth, design, size, categoryCode, subCategoryCode } = item;
      const {
        clothCode,
        brandCode,
        clothModelCode,
        personalorderColorCode,
        design: clothDesign,
        compositionFront,
        compositionBack,
        stockPlaceCode,
        requiredScale,
      } = cloth;
      const { designParts, selecting } = design;

      // object-selectors
      const eaos = editedAvailableOptionsSelector(
        availableOptionsSelector(allAvailableOptions[orderIndex]).withSelectedInfo(
          pieces,
          designParts,
          selecting,
          item.categoryCode,
          clothModelCode,
          brandCode,
        ),
      );
      const cps = clothProductsSelector({
        itemCode,
        brandCode,
        products: allProducts[orderIndex],
        subCategoryCode,
      });

      const sizeView = sizeMeasurementsViewSelector(
        sizeMeasurementsSelector(allSizeMeasurements[orderIndex]).toView(item.pieces, size.parts),
      );

      const adjustView = adjustOptionsViewSelector(
        adjustOptionsSelector(allAdjustOptions[orderIndex]).toView(item.pieces, size.parts),
      );

      const productInfo = cps.nearestProduct;

      // ヌード寸法
      const { measurements: nudeMeasurements } = cloneDeep(order.item.size.nude);

      // serial(修正時のみ)
      const serial = {
        serialYear: isEdit ? +serialYear : 0,
        serialNumber: isEdit ? +serialNumber : 0,
      };

      // SC Project
      const scProjectData = hasFavoriteCustomer
        ? {
            scSalesEventId: scProject.salesEventId,
            scSalesEventName: scProject.projectName,
            scCorporationId: scProject.corporationId,
          }
        : {};

      /************************************************************
       * リクエストオブジェクトのroot 直下 （主に orders[n].shipping関連）
       ************************************************************/
      const {
        customerFamilyNameKana,
        customerGivenNameKana,
        customerFamilyNameKanji,
        customerGivenNameKanji,
        customerMailAddress,
        shippingPostalCode,
        shippingPhoneNumber,
        shippingState,
        shippingCity,
        shippingStreet,
        lotNumber,
        cutterNameKana,
        memberscardNumber,
      } = !shipping.isSameOrderOne ? shipping : firstOrder.shipping;
      const { timeZoneCode, deliveryDateGuest, deliveryMethod, shippingCost } = shipping;
      const orderDate = order.orderDate || format(new Date(), 'yyyyMMdd');
      const priceNetTotal = getPriceNetTotal(orderState, allProducts, allAvailableOptions, orderIndex);
      const priceTaxin = +priceNetTotal + +shippingCost;
      const root = {
        orderDate,
        shopName: staff.tempoName,
        shopCode: staff.tempoCode,
        staffName: staff.staffName,
        staffCode: staff.staffCode,
        deliveryDateArrival: deliveryDateGuest,
        deliveryDateGuest,
        timeZoneCode,
        timeZoneName: getDeliveryTimeZoneName(timeZoneCode),
        paymentDate: orderDate,
        clientStaffName: '', // blank
        customerFamilyNameKana,
        customerGivenNameKana,
        customerFamilyNameKanji,
        customerGivenNameKanji,
        memberscardNumber,
        deliveryMethod,
        customerMailAddress: getRequestCustomerMailAddress(customerMailAddress),
        shippingPostalCode,
        shippingPhoneNumber,
        shippingState,
        shippingCity,
        shippingStreet,
        shippingCost: shipping.shippingCost,
        couponUsePoint: 0,
        usePoint: 0,
        // マイナスになるとエラーになるので送信しない OPS_ORDERSYS-747 【本番運用】ポイントのCMシステム連携について
        // priceNetTotal,
        priceTaxin,
        // TODO: OEM: deliveryRateいらない
        // deliveryRate: needInvoice ? deliveryRate : null,
        productKind,
        introducerName,
        introducerCode,
        favoriteCustomerName,
        favoriteCustomerCode,
        invoiceFlag: needInvoice ? INVOICE_FLAG.need : INVOICE_FLAG.noNeed,
        parentSerialYear: serial.serialYear,
        parentSerialNumber: serial.serialNumber,
        // OEM 仮対応 https://oneonward.backlog.com/view/OEM_JYUCHU-68#comment-130084568
        orderPaymentMethod:
          staff.tempoId !== undefined && staff.tempoId !== null && staff.tempoId !== '' ? 'sumareji' : '',
        orderPaymentMethodName:
          staff.tempoId !== undefined && staff.tempoId !== null && staff.tempoId !== '' ? 'スマレジ' : '',
        lotNumber,
        cutterNameKana,
        ...scProjectData,
      };

      /************************************************************
       * items[0] 直下
       ************************************************************/

      // 価格
      const itemOrderPrice = Number(productInfo.retailPrice);
      const itemOrderPriceTaxin = Number(productInfo.retailPriceTaxin);
      const optionPrice = eaos.totalOptionPrice();
      const optionPriceTaxin = eaos.totalOptionPriceTaxin();
      const itemOrderAmount = itemOrderPrice + optionPrice;
      const itemOrderAmountTaxin = itemOrderPriceTaxin + optionPriceTaxin;
      const priceData = {
        itemOrderPrice,
        optionPrice,
        itemOrderAmount,
        itemPriceTax: itemOrderAmountTaxin - itemOrderAmount,
        itemOrderAmountTaxin,
        retailPrice: itemOrderPrice,
      };

      //  OPS_ORDERSYS-369 注文確定API（注文情報の新規登録）に設定する要尺（required_scale）について
      const { partsNumber: initialPartsNumber, index: initialPartsIndex } = pieces[0];
      const { modelPatternCode: initialModelPattern, modelCode: initialModel } = eaos.editiedAvailableOption(
        initialPartsIndex,
      ) || { modelPatternCode: '', modelCode: '' };
      const { useScale } = cps.getModel(initialPartsNumber, initialModelPattern, initialModel) || {
        useScale: 0,
      };

      // item, brand, cloth, pearl_tone
      const brandClothData = {
        item_name: getItemCodeName(itemCode, categoryCode),
        item: itemCode,
        itemType: isShirtOrder(categoryCode) ? getItemType(itemCode) : '',
        brand: brandCode,
        // ブランドKRの場合、モデル（model_pattern）と同じ値をセットする。
        make: KR_BRANDS.includes(brandCode) ? clothModelCode : '',
        clothBrandCode: item.cloth.clothBrandCode,
        clothSeason: item.cloth.clothSeasonCode,
        clothCode,
        // クライアント側ではvendorClothNumber === textileNumberなので、
        // ブランドKRでない場合にはマスタのvendorClothNumberを設定する
        vendorClothNumber: !KR_BRANDS.includes(brandCode)
          ? item.cloth.vendorClothNumber
          : productInfo.vendorClothNumber,
        textileNumber: item.cloth.vendorClothNumber,
        productSeason: item.cloth.productSeasonCode,
        productNumber: clothCode,
        requiredScale: KR_BRANDS.includes(brandCode) ? +requiredScale : useScale, // MEMO: 要尺はKRの時は手入力、それ以外はAPIの値を設定する
        // NOTICE: 大文字の区切りがAPI間で統一されてないですね。。。
        personalOrderColorCode: KR_BRAND.includes(cloth.brandCode) ? KR_BRAND_COLOR_CODE : personalorderColorCode,
        personalOrderColorName: getPersonalorderColorName(personalorderColorCode),
        design: clothDesign,
        compositionFront: compositionSelector(compositionFront).toServerFormat(),
        compositionBack: compositionSelector(compositionBack).toServerFormat(),
        stockPlaceCode,
        stockPlaceName: getStockPlaceName(stockPlaceCode),
        ...eaos.getPearlToneInfo(),
      };
      // modified // NOTICE: 注文修正の場合、注文情報詳細取得APIのレスポンスをそのまま設定する。注文確定は'0'固定
      // hurrying // NOTICE: 「即注文」が実装されたら、即注文時に'1'を設定
      // note // NOTICE: 編集などで本関数を利用する場合は考慮が必要。

      /************************************************************
       * item[0].parts 直下
       ************************************************************/
      const distinctPartsNumbers = piecesSelector(pieces).distinctPartsNumbers();
      const parts = distinctPartsNumbers.map(
        (partsNumber): TNewOrderRequestItemParts => {
          const reqPartsData = cloneDeep(initialDataNewOrderRequestItemParts);
          const editedAvailableOption = eaos.editiedAvailableOptionMergedSpare(partsNumber);
          const sizeMeasurements = sizeView.getFromPartsNumber(partsNumber);
          const adjustOptions = adjustView.getFromPartsNumber(partsNumber);
          if (!editedAvailableOption || !sizeMeasurements || !adjustOptions) {
            return reqPartsData;
          }

          const { optionPatternCode: optionPattern, modelPatternCode: modelPattern, modelCode } = editedAvailableOption;
          const { modelName } = cps.getModel(partsNumber, modelPattern, modelCode) || { modelName: '' };

          const nudeSizes = addAddtionalNudeSize(nudeMeasurements, partsNumber);

          const part = {
            partsNumber: editedAvailableOption.partsNumber,
            partsName: editedAvailableOption.partsName,
            modelCode,
            modelName,
            modelPattern,
            optionPattern,
            sizeCode: joinGauge(sizeMeasurements.gauge, true),
            options: [
              ...editedAvailableOption.optionPatterns
                .filter(v => !v.isFreeInput)
                .filter(v => !!v.selectingClassNumber)
                .map(
                  (v): TNewOrderRequestItemPartsOptions => ({
                    ...initialDataNewOrderRequestItemPartsOptions, // flatなオブジェクトだから、cloneDeepしなくてもOK
                    ...{
                      optionNumber: v.optionNumber,
                      optionName: v.optionName,
                      optionClassNumber: v.selectingClassNumber,
                      optionClassName: v.selectingClassName || '',
                    },
                  }),
                ),
              // 自由入力項目
              ...editedAvailableOption.optionPatterns
                .filter(v => v.isFreeInput)
                .filter(v =>
                  shouldSendForServerFreeInput(
                    v.optionNumber,
                    editedAvailableOption.partsNumber,
                    editedAvailableOption.optionPatterns,
                  ),
                )
                .map(
                  (v): TNewOrderRequestItemPartsOptions => ({
                    ...initialDataNewOrderRequestItemPartsOptions, // flatなオブジェクトだから、cloneDeepしなくてもOK
                    ...{
                      optionNumber: v.optionNumber,
                      optionName: v.optionName,
                      // NOTICE: 刺繍ネームの場合, optionClassNumberは送信不要
                      // OPS_ORDERSYS-689 【本番運用】余計なXML項目が表示されてしまっている。
                      // optionClassNumber: v.selectingClassNumber,
                      optionClassName: v.selectingClassName || '',
                    },
                  }),
                ),
              // 採寸項目
              ...sizeMeasurements.measurementItems
                .map(v => overWriteMeasurementItem(v, modelPattern, categoryCode, brandCode))
                .map(v => ({
                  ...initialDataNewOrderRequestItemPartsOptions, // flatなオブジェクトだから、cloneDeepしなくてもOK
                  ...{
                    optionNumber: v.measurementNumber,
                    optionName: v.measurementName,
                    optionClassName: v.value,
                  },
                })),
              // 特殊補正
              ...adjustOptions.adjustOptions
                .filter(v => !!v.selectedClassNumber)
                .map(v => ({
                  ...initialDataNewOrderRequestItemPartsOptions, // flatなオブジェクトだから、cloneDeepしなくてもOK
                  ...{
                    optionNumber: v.optionNumber,
                    optionName: v.optionName,
                    optionClassNumber: v.selectedClassNumber,
                    optionClassName: v.selectedClassName,
                  },
                })),
              // ヌード寸法
              ...nudeSizes
                .filter(v => shouldSendForServerNudeSize(partsNumber, v.optionNumber))
                .map(v => ({
                  ...initialDataNewOrderRequestItemPartsOptions, // flatなオブジェクトだから、cloneDeepしなくてもOK
                  ...{
                    optionNumber: v.optionNumber,
                    optionName: getNudeMeasurementName(v.optionNumber),
                    optionClassName: v.optionClassName || '',
                  },
                })),
            ]
              .filter(isSendOkOption)
              .sort((a, b) => +a.optionNumber - +b.optionNumber),
          };
          return { ...reqPartsData, ...part };
        },
      );
      const reqDataItem = cloneDeep(initialDataNewOrderRequestItem);
      const itemData = { ...reqDataItem, ...priceData, ...brandClothData, ...serial, parts };
      return { ...reqData, ...root, items: [itemData] };
    },
  );

  const { usePoint, couponUsePoints } = payment;
  const applyPoint = partialRight(applyPointToOrder, [usePoint, false]);
  const applyCoupon = partialRight(applyPointToOrder, [couponUsePoints, true]);
  const applyZeroPrice = partialRight(applyZeroPriceToOrder, [productKind]);
  return pipe(applyPoint, applyCoupon, applyZeroPrice)(orderRequests);
};
const getItemType = (itemCode: string) => getValue(itemCode, MASTER_ITEM_TYPE) || '';
const getItemCodeName = (itemCode: TOrderItemCode, category: TCategory) =>
  getValueForItemCode(itemCode, category) || '';
const getPersonalorderColorName = (personalorderColorCode: string) =>
  getValue(personalorderColorCode, MASTER_CLOTH_COLOR) || '';
const getStockPlaceName = (stockPlaceCode: string) => getValue(stockPlaceCode, MASTER_CLOTH_PARTITION) || '';
const getDeliveryTimeZoneName = (code: string) => getValue(code, MASTER_DELIVERY_TIME_ZONE) || '';
const getNudeMeasurementName = (code: string) => getValue(code as TNudeDementionCode, MASTETR_NUDE_DEMENTION) || '';

const getPriceNetTotal = (
  orderState: IOrderState,
  allProducts: IndexedObject<IClothProduct[]>,
  allAvailableOptions: IndexedObject<IAvailableOption[]>,
  orderNumber: string,
) => {
  const order = orderState.orders[orderNumber];
  const item = order.item;
  const { itemCode, pieces, cloth, design, subCategoryCode } = item;
  const eaos = editedAvailableOptionsSelector(
    availableOptionsSelector(allAvailableOptions[orderNumber]).withSelectedInfo(
      pieces,
      design.designParts,
      design.selecting,
      item.categoryCode,
      cloth.clothModelCode,
      cloth.brandCode,
    ),
  );
  const productInfo = clothProductsSelector({
    itemCode,
    brandCode: cloth.brandCode,
    products: allProducts[orderNumber],
    subCategoryCode,
  }).nearestProduct;

  return +productInfo.retailPriceTaxin + eaos.totalOptionPriceTaxin();
};

const applyPointToOrder = (
  orderRequestsData: TNewOrderRequest[],
  usePoint: number,
  isCoupon: boolean,
): TNewOrderRequest[] => {
  const tmp = orderRequestsData.reduce(
    (acc, curr) => {
      // マイナスになるとエラーになるので送信しない OPS_ORDERSYS-747 【本番運用】ポイントのCMシステム連携について
      // const priceNetTotal = curr.priceNetTotal;
      const priceTaxin = curr.priceTaxin;
      const assignPoint = priceTaxin > acc.unassignedPoint ? acc.unassignedPoint : priceTaxin;
      const restPoint = acc.unassignedPoint - assignPoint;

      const dataPointApplied = {
        ...curr,
        // priceNetTotal: priceNetTotal - assignPoint,
        priceTaxin: priceTaxin - assignPoint,
      };
      if (isCoupon) {
        dataPointApplied.couponUsePoint = assignPoint;
      } else {
        dataPointApplied.usePoint = assignPoint;
      }
      return { data: [...acc.data, dataPointApplied], unassignedPoint: restPoint };
    },
    { data: [] as TNewOrderRequest[], unassignedPoint: usePoint },
  );
  return tmp.data;
};

const applyZeroPriceToOrder = (orderRequestsData: TNewOrderRequest[], productKind: TProductKind): TNewOrderRequest[] =>
  !shouldBeTotalPriceZero(productKind)
    ? orderRequestsData
    : orderRequestsData.map(partial(replaceMatchedKeyObjectProps, [TOTAL_PRICE_ZERO_CONFIG]));

/**
 * 送信時に追加となるヌード寸法をコピーして対象のヌード寸法を追加する
 */
const addAddtionalNudeSize = (nudeSizes: IOption[], partsNumber: TPartsNumber): IOption[] => {
  const addtionalConfigs = ADDTIONAL_COPY_NUDE_SIZE_CONFIG.filter(by('partsNumber')(partsNumber));
  if (addtionalConfigs.length < 1) {
    return [...nudeSizes];
  }
  const addtionalNudesizes = addtionalConfigs.map(v => {
    const from = nudeSizes.find(by('optionNumber')(v.from)) || {
      optionNumber: v.from,
      optionClassNumber: '',
      optionClassName: '0',
    };
    return {
      ...from,
      optionNumber: v.to,
    };
  });
  return [...nudeSizes, ...addtionalNudesizes];
};

const omitProperties = <T>(
  modData: T,
  resData: T,
  ignoreKeys: string[],
  modDataResponceKeys: string[],
  arrayOmitKeys: Array<keyof T> = [],
): NestedPartial<T> => {
  return Object.keys(modData)
    .map(key => key as keyof T)
    .reduce((pre, key) => {
      const modValue = modData[key];
      const resValue = resData[key];
      // 変更を無視してresを設定する
      if (ignoreKeys.includes(key as string)) {
        return { ...pre, [key]: resValue };
      }
      // 修正した値を必ず返却するプロパティの場合
      if (modDataResponceKeys.includes(key as string)) {
        return { ...pre, [key]: modValue };
      }
      // number型のとき（numberは0でもfalseになってしまうので）
      if (typeof resValue === 'number') {
        return { ...pre, [key]: modValue };
      }
      // レスポンスがあるpropertyの場合
      if (resValue) {
        // 処理対象となる配列の場合
        if (Array.isArray(modValue) && Array.isArray(resValue)) {
          if (!arrayOmitKeys.includes(key)) {
            return { ...pre, [key]: modValue };
          }
          // NOTICE: 配列の順番は保証していないのでそのまま返したい時はarrayKeysに設定
          const modArray = modValue.map((v, i) => omitProperties(v, resValue[i], ignoreKeys, modDataResponceKeys));
          return { ...pre, [key]: modArray };
        }
        // objectの場合
        if (typeof modValue === 'object') {
          return { ...pre, [key]: omitProperties(modValue, resValue, ignoreKeys, modDataResponceKeys) };
        }
        if (modValue) {
          return { ...pre, [key]: modValue };
        }
        return { ...pre, [key]: resValue };
      }
      // レスポンスはないけど、修正した項目は追加する
      if (modValue) {
        return { ...pre, [key]: modValue };
      }
      // レスポンスはない、修正にもない場合、取り除く
      return pre;
    }, {} as NestedPartial<T>);
};

export const getEditOrderRequest = (
  orderDetail: TNewOrderRequest,
  memberscardNumber: string,
  staff: IStaff,
  orderState: IOrderState,
  payment: IPayment,
  project: TScProject,
  allProducts: IndexedObject<IClothProduct[]>,
  allAvailableOptions: IndexedObject<IAvailableOption[]>,
  allSizeMeasurements: IndexedObject<ISizeMeasurement[]>,
  allAdjustOptions: IndexedObject<IPartsAdjustOption[]>,
) => {
  const orderRequest = getNewOrderRequest(
    memberscardNumber,
    staff,
    orderState,
    payment,
    project,
    allProducts,
    allAvailableOptions,
    allSizeMeasurements,
    allAdjustOptions,
  )[0];

  const order = omitProperties(
    orderRequest,
    orderDetail,
    [...IGNORE_CLIENT_CHANGED_ITEM_PROPERTIES, ...NOT_EDITABLE_ORDER_PROPERTIES, ...NOT_EDITABLE_ITEM_PROPERTIES],
    [...CLIENT_VALUE_ORDER_PROPERTIES],
    ['items'],
  );
  Logger.log('orderRequest', orderRequest);
  Logger.log('orderDetail', orderDetail);
  Logger.log('order', order);

  return order;
};
