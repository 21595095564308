import { thunkActionErrorHandler } from '../../../helpers/error-handler';
import { thunkActionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { orderActions, IOrderState } from '../../order/';
import { commonPageActions } from '../common';
import { clothProductActions } from '../../lookups/product/action-reducer';
import { availableOptionActions, adjustOptionActions, recommendedGaugeActions } from '../../lookups';
import { sizeMeasurementActions } from '../../lookups/size-measurement/action-reducer';
import { IndexedObject } from '../../../types';
import { IClothProduct, IAvailableOption, ISizeMeasurement, IPartsAdjustOption } from '../../_type/lookups';
import { IStaff } from '../../_type/staff';
import { IPayment } from '../../_type/payment';

type TOrdersRegistParam = {
  memberscardNumber: string;
  staff: IStaff;
  payment: IPayment;
  orderState: IOrderState;
  allProducts: IndexedObject<IClothProduct[]>;
  allAvailableOptions: IndexedObject<IAvailableOption[]>;
  allSizeMeasurements: IndexedObject<ISizeMeasurement[]>;
  allAdjustOptions: IndexedObject<IPartsAdjustOption[]>;
};

// asyncAction
const actionTitle = 'Confirmation Page';
const asyncAc = thunkActionObjectCreatorFactory();
export const confirmationPageActions = {
  editOrder: asyncAc<{ orderNumber: string }, any>(`[${actionTitle}] editOrder`, async (payload, dispatch) => {
    try {
      const { orderNumber } = payload;
      orderActions.updateCurrentOrderNumber.dispatcher(dispatch)(orderNumber);
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
  copyOrder: asyncAc<{ newOrderNumber: string; orderNumber: string }, any>(
    `[${actionTitle}] copyOrder`,
    async (payload, dispatch) => {
      try {
        const { orderNumber } = payload;
        // オーダーのコピー
        orderActions.copyOrder.dispatcher(dispatch)(orderNumber);
        // lookupsのコピー
        clothProductActions.copy.dispatcher(dispatch)(payload);
        availableOptionActions.copy.dispatcher(dispatch)(payload);
        adjustOptionActions.copy.dispatcher(dispatch)(payload);
        recommendedGaugeActions.copy.dispatcher(dispatch)(payload);
        sizeMeasurementActions.copy.dispatcher(dispatch)(payload);
      } catch (ex) {
        return thunkActionErrorHandler(ex, dispatch);
      }
    },
  ),
  deleteOrder: asyncAc<{ orderNumber: string }, any>(`[${actionTitle}] deleteOrder`, async (payload, dispatch) => {
    try {
      const { orderNumber } = payload;
      commonPageActions.deleteOrder.dispatcher(dispatch)({ orderNumber });
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
};
