import React from 'react';

import styles from './DesignOptionBlouseColorSelector.module.scss';

import Logger from '../../../helpers/common/logger';
import { IOptionClass, IOptionPatternWithSelectedInfo } from '../../../store/_type/lookups';
import { TPageContainerProps } from '../../../containers/pages/DesignSelectionPage';
import { isOptionGroupOptionClass } from '../../../helpers/item-thisisforreplaceall';

type TProps = Pick<TPageContainerProps, 'orderNumber' | 'item' | 'products' | 'onSelectOptionClass'> & {
  optionPattern: IOptionPatternWithSelectedInfo;
  selectedOptionGroupId: string;
};

const getColorName = (cls: IOptionClass) => {
  let colorName = '';
  try {
   // cls.optionClassName には、「BL-006(001)S#長袖クルーネックブラウス 白 S」「BL-009(001)L#レギュラーカラーシャツ 白 L」
   // 等が、 入っている前提。 BL-009 レギュラーカラーシャツ のみ、「'シャツ '」に該当する前提。
    colorName = cls.optionClassNumber + ' # ' + cls.optionClassName.split(
      cls.optionClassName.includes('ブラウス') ? 'ブラウス ' : 'シャツ ')[1];
  } catch (e) {
    colorName = '';
  }
  return colorName;
};

const hasSelected = (optionClassNumber: string, selectingClassNumber: string): boolean =>
  selectingClassNumber === '' || selectingClassNumber !== optionClassNumber ? false : true;

const getCardClassName = (v: IOptionClass, selectingClassNumber: string) =>
  hasSelected(v.optionClassNumber, selectingClassNumber) ? styles.cardSelected : styles.card;

const DesignOptionBlouseColorSelector: React.FC<TProps> = (props: TProps) => {
  const { item, orderNumber, products, selectedOptionGroupId } = props;
  const { optionClasses, selectingClassNumber, optionNumber } = props.optionPattern;

  const onSelectColor = (cls: IOptionClass) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { optionClassNumber } = cls;
    if (!selectedOptionGroupId) {
      Logger.log('no selectedOptionGroupId');
    }
    props.onSelectOptionClass({
      orderNumber,
      item,
      products,
      optionClassNumber,
    });
  };

  const renderCard = (cls: IOptionClass, key: number) => (
    <div key={key} className={styles.item}>
      <div className={getCardClassName(cls, selectingClassNumber)} onClick={onSelectColor(cls)}>
        <div className={styles.text}>
          <p>{getColorName(cls)}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.content}>
      {optionClasses.filter(isOptionGroupOptionClass(optionNumber, selectedOptionGroupId)).map(renderCard)}
    </div>
  );
};

export default DesignOptionBlouseColorSelector;
