import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';
import SizeCorrectionPage from '../../../components/pages/SizeCorrectionPage/SizeCorrectionPage';
import { get as getOrder } from '../../../store/order';
import { get as getCustomer } from '../../../store/customer';

import { getSize } from '../../../store/order/selector/size';
import { getCurrentItem } from '../../../store/order/selector/item';
import { getRecommendedGauge } from '../../../store/lookups';
import { customKeyboardHoc } from '../../../hoc/CustomKeyboardHoc';
import { mapCommonPageDispatchToProps, mapCommonPageStateToProps } from '../CommonPage';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';
import { requiredCheckCompleted } from '../../../store/order/selector/cloth';

function mapStateToProps(state: AppState) {
  return {
    orders: getOrder.orders(state),
    // item
    item: getCurrentItem.detail(state),
    // nudeSelection
    nudeSize: getSize.nude(state),
    nudeDate: getSize.nudeDate(state),
    nudeDimensions: getSize.nudeDimensions(state),
    recommendedGauges: getSize.recommendedGauges(state),
    // sizeSelection
    selectingParts: getSize.selectingParts(state),
    partsTabs: getSize.partsTabs(state),
    measurements: getSize.measurements(state),
    adjustOptions: getSize.adjustOptions(state),
    sizeHistoryDate: getSize.sizeHistoryDate(state),
    sizeHistoryGauge: getSize.sizeHistoryGauge(state),
    selectingPartsGauge: getSize.selectingPartsGauge(state),
    selectablePartsGauges: getRecommendedGauge.current.selectablePartsGauges(state),
    hasCompleted: getSize.hasCompleted(state),
    requiredCheckCompleted: requiredCheckCompleted(state),
    memberscardNumber: getCustomer.memberscardNumber(state),
    specialAdjustOptions: getSize.specialAdjustOptions(state),
    ...mapCommonPageStateToProps(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(SizeCorrectionPageEvents, dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(customKeyboardHoc()(SizeCorrectionPage));
