import { IItem, IOrder, TProductKind } from '../../_type/order';
import {
  clothProductsSelector,
  availableOptionsSelector,
  editedAvailableOptionsSelector,
} from '../../lookups/object-selector';
import { IndexedObject } from '../../../types';
import { IClothProduct, IAvailableOption } from '../../_type/lookups';
import { shouldBeTotalPriceZero } from '../../../helpers/orders';

/**
 * FIXME: 各所に広がった関数を統一したい
 * @param item
 */
export const getItemBaseInfo = (item: IItem) => {
  const { pieces, design, itemCode, cloth, categoryCode, subCategoryCode } = item;
  const { brandCode, clothModelCode } = cloth;
  const { parts: partsSize } = item.size;
  return { pieces, design, cloth, itemCode, brandCode, clothModelCode, partsSize, categoryCode, subCategoryCode };
};

export const getProductAndOptionTotal = (
  orders: IndexedObject<IOrder>,
  products: IndexedObject<IClothProduct[]>,
  availableOptions: IndexedObject<IAvailableOption[]>,
  productKind: TProductKind,
) => {
  return Object.keys(orders).map(orderNumber => {
    if (shouldBeTotalPriceZero(productKind)) {
      return {
        orderNumber,
        productPrice: 0,
        optionPrice: 0,
        shippingCost: 0,
      };
    }

    const { item, shipping } = orders[orderNumber];
    const { pieces, design, itemCode, brandCode } = getItemBaseInfo(item);

    // 商品の合計金額
    const _products = products[orderNumber];
    const subCategoryCode = item.subCategoryCode;
    const product = clothProductsSelector({ products: _products, itemCode, brandCode, subCategoryCode }).nearestProduct;
    const productPrice = +product.retailPriceTaxin;

    // オプションの合計金額
    const _options = availableOptions[orderNumber];
    const options = availableOptionsSelector(_options).withSelectedInfo(
      pieces,
      design.designParts,
      design.selecting,
      item.categoryCode,
      item.cloth.clothModelCode,
      item.cloth.brandCode
    );
    const optionPrice = editedAvailableOptionsSelector(options).totalOptionPriceTaxin();

    return {
      orderNumber,
      productPrice,
      optionPrice,
      shippingCost: +shipping.shippingCost,
    };
  });
};
