import { IAdjustOptionView, TOptionClass } from '../../_type/lookups';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { IAdjustOptionItem, IConfirmationMeasurement } from '../../../types/new-store/components';
import { ILookupItem } from '../../../types/lookup';

export const adjustOptionsViewSelector = (state: IAdjustOptionView[]) => new AdjustOptionsViewSelector(state);

class AdjustOptionsViewSelector {
  constructor(private state: IAdjustOptionView[]) {}

  /** 商品確認向けにpartsIndexでも引けるようにしておく */
  public getFromPartsIndex(partsIndex: string) {
    return this.state.find(v => v.partsIndexes.includes(partsIndex));
  }

  public getFromPartsNumber(partsNumber: TPartsNumber) {
    return this.state.find(v => v.partsNumber === partsNumber);
  }

  /** コンポーネント向けに加工したやつ。不要になったら消すこと。 */
  public getAdjustOptionItems(partsNumber: TPartsNumber): IAdjustOptionItem[] {
    const parts = this.getFromPartsNumber(partsNumber);
    return !parts
      ? []
      : parts.adjustOptions.map(option => {
          return {
            ...option,
            isSpecial: option.isSpecialOption,
            optionClassName: option.selectedClassName ? option.selectedClassName : '-',
            historyOptionClassName: option.historyClassName ? option.historyClassName : '-',
            // 000はサーバー側の未選択Number
            selectedOptionClass: option.selectedClassNumber ? option.selectedClassNumber : '000',
            selectableOptionClasses: this.toLookups(option.optionClasses),
          };
        });
  }

  /** コンポーネント向けに加工したやつ。不要になったら消すこと。 */
  public getConfirmations(partsNumber: TPartsNumber): IConfirmationMeasurement[] {
    return this.getAdjustOptionItems(partsNumber)
      .filter(v => v.selectedOptionClass !== '000')
      .map(v => {
        const { optionClassName, optionName, isSpecialOption } = v;
        const data = {
          measurementName: optionName,
          measurementValue: optionClassName,
          isSpecial: isSpecialOption,
        };
        return data;
      });
  }

  private toLookups(classes: TOptionClass[]) {
    return toLookups(classes, 'optionClassNumber', 'optionClassName');
  }
}

// TODO: 違うところに持っていく
function toLookups<T>(objectArray: T[], key1: keyof T, key2: keyof T): ILookupItem[] {
  return objectArray.map(v => {
    return {
      id: String(v[key1]),
      value: String(v[key2]),
    };
  });
}
