import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { get, okCancelDialogActions } from '../../../../store/utils/dialog/ok-cancel';
import OKCancelDialog from '../../../../components/molecules/OKCancelDialog/OKCancelDialog';

function mapStateToProps(state: AppState) {
  return {
    data: get.data(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClose: okCancelDialogActions.close.dispatcher(dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
type TProps = TStateProps & TDispatchProps;

const OkCancelDialogContainer: React.FC<TProps> = (props: TProps) => {
  const { data } = props;
  const onClose = () => {
    props.onClose({});
  };
  return <OKCancelDialog {...data} onClose={onClose} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(OkCancelDialogContainer);
