import { ClothProductRes } from '../../../api/items/cloth-product';
import { IClothProduct, IPartsModelPattern, IModelPattern, IModel } from '../../../store/_type/lookups';
import { IComposition } from '../../../store/_type/order';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';

export function toClothProducts(res: ClothProductRes): IClothProduct[] {
  const data = res.query('products');
  const products = Array.from({ length: data.length }).map((v, i) => toProduct(res, i, 'products'));
  return products;
}

function toProduct(res: ClothProductRes, index: number, parentExpression: string): IClothProduct {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  const patternsData = getValue<any>('partsNumbers');
  const patternsExpression = getExpression('partsNumbers');
  const partsModelPatterns = Array.from({ length: patternsData.length }).map((v, i) =>
    toPartsModelPattern(res, i, patternsExpression),
  );

  const frontMixings = getValue<any>('mixings.front');
  const frontMixingsExpression = getExpression('mixings.front');
  const front = Array.from({ length: frontMixings.length }).map((v, i) => toMixing(res, i, frontMixingsExpression));

  const backMixings = getValue<any>('mixings.back');
  const backMixingsExpression = getExpression('mixings.back');
  const back = Array.from({ length: backMixings.length }).map((v, i) => toMixing(res, i, backMixingsExpression));

  const product: IClothProduct = {
    partsModelPatterns,
    mixings: {
      front,
      back,
    },
    brandCode: getValue('brandCode'),
    itemCode: getValue('itemCode'),
    productSeasonCode: getValue('productSeasonCode'),
    personalorderProductNumber: getValue('personalorderProductNumber'),
    personalorderColorCode: getValue('personalorderColorCode'),
    seasonCode: getValue('seasonCode', false),
    seasonName: getValue('seasonName', false),
    personalorderColorName: getValue('personalorderColorName'),
    retailPrice: getValue('retailPrice'),
    retailPriceTaxin: getValue('retailPriceTaxin'),
    design: getValue('design'),
    designName: getValue('designName'),
    clothName: getValue('clothName'),
    clothSeasonCode: getValue('clothSeasonCode'),
    clothCode: getValue('clothCode'),
    // TODO:API側でカラで返してほしい。。。
    vendorClothNumber: getValue('vendorClothNumber') === '-' ? '' : getValue('vendorClothNumber'),
    isnew: getValue('isnew'),
    issoldout: getValue('issoldout'),
    makerName2: getValue('makerName2', false),
    functionName: getValue('functionName', false),
    clothBrandCode: getValue('clothBrandCode'),
  };

  return product;
}

function toPartsModelPattern(res: ClothProductRes, index: number, parentExpression: string): IPartsModelPattern {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  const modelPatternsData = getValue<any>('modelPatterns');
  const modelPatternsExpression = getExpression('modelPatterns');
  const modelPatterns = Array.from({ length: modelPatternsData.length }).map((v, i) =>
    toModelPattern(res, i, modelPatternsExpression),
  );
  const ret: IPartsModelPattern = {
    partsNumber: getValue<TPartsNumber>('partsNumber'),
    modelPatterns,
  };
  return ret;
}

function toModelPattern(res: ClothProductRes, index: number, parentExpression: string): IModelPattern {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  const modelData = getValue<any>('modelCodes');
  const modelExpression = getExpression('modelCodes');
  const models = Array.from({ length: modelData.length }).map((v, i) => toModel(res, i, modelExpression));
  return {
    modelPattern: getValue('modelPattern'),
    models,
  };
}

function toModel(res: ClothProductRes, index: number, parentExpression: string): IModel {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  return {
    modelCode: getValue('modelCode'),
    modelName: getValue('modelName'),
    useScale: Number(getValue('useScale')),
  };
}

function toMixing(res: ClothProductRes, index: number, parentExpression: string): IComposition {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string, required: boolean = true): T => {
    const expression = getExpression(v);
    return res.query(expression, required) as T;
  };

  const ret: IComposition = {
    mixing: getValue('mixing'),
    mixingRatio: getValue<number>('mixingRatio'),
  };
  return ret;
}
