import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap, filter, debounce } from 'rxjs/operators';
import { actions } from './actions';
import { ApiError } from '../../models/error/api-error';
import { regProgress, loadProgress, IProgressRequest } from '../../services/progress/pregress';
import { createNewKey, progressTypeCd } from './helper';
import { actions as globalActions } from '../actions';
import path from 'ramda/es/path';
import { ILocalState, TProgressList } from './state';
import { timer } from 'rxjs';
import { saveTmpOrderActions } from '../utils/dialog/save-tmpOrder';
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { IStaff } from '../_type/staff';
import { IInformationDialog } from '../../types/dialog';
import { isValidStaff } from '../../helpers/staff/index';
import { orderDeleteActions } from '../order';
import history from '../../helpers/common/history';
import { ERouterPath } from '../../types';
import { resolvePath } from '../../helpers/common/path';
import { actions as ErrorHandlerActions } from '../../store/errorHandling/action';
import { actions as CustomerActions } from '../customer/action-reducer';
import { INITIAL_CUSTOMER } from '../customer/initial-state';
import { ICustomer } from '../_type/customer';
import { jsonDateReplacer, jsonDateReviver } from '../../helpers/common/json';

const getStaffCode = path(['staff', 'staff', 'loggedStaff', 'staffCode']);
const getStaffData = path(['staff', 'staff', 'loggedStaff']);
const getProgress = path(['progress']);

/**
 * 生地選択画面で値の変更が行われた時（単一の値を持つ要素のみ）
 */
const autoUpdate: Epic<AnyAction, Action<string | Parameters<typeof actions.sendRegistApi.started>[0]>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(actions.autoUpdate.started),
    filter(() => !!getStaffCode(state.value) && !!getProgress(state.value)),
    mergeMap(() => {
      const v = getProgress(state.value) as ILocalState;
      const staffCode = getStaffCode(state.value) as string;
      const progressKey = v.progressKey || createNewKey();
      const progressName = v.progressName;
      const progressType = v.progressType;
      const params = {
        progressId: `${staffCode}-${progressKey}`,
        progressName,
        progressType,
        progressData: JSON.stringify(state.value, jsonDateReplacer),
      };
      return progressKey === v.progressKey
        ? [actions.sendRegistApi.started(params)]
        : [actions.updateProgresskey(progressKey), actions.sendRegistApi.started(params)];
    }),
  );

/**
 * オーダー一時保留ダイアログの保存ボタンを押した時
 */
const update: Epic<AnyAction, Action<Parameters<typeof actions.sendRegistApi.started>[0] | void>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(actions.update.started),
    mergeMap(({ payload }) => {
      const v = getProgress(state.value) as ILocalState;
      const staffCode = getStaffCode(state.value) as string;
      const progressKey = v.progressKey;
      const progressName = payload as string;
      const progressType = progressTypeCd.MANUAL;
      const params = {
        progressId: `${staffCode}-${progressKey}`,
        progressName,
        progressType,
        progressData: JSON.stringify(
          { ...state.value, progress: { ...state.value.progress, progressList: [] } },
          jsonDateReplacer,
        ),
      };
      return [actions.sendRegistApi.started(params), saveTmpOrderActions.close._action()];
    }),
  );

const initialProc: Epic<AnyAction, Action<string>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.initialProc),
    map(() => actions.updateProgresskey(createNewKey())),
  );

const loadProgressData: Epic<
  AnyAction,
  Action<AppState | Parameters<typeof actions.loadProgressData.done>[0] | void>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadProgressData.started),
    map(({ payload }) => payload),
    filter(progressId => progressId !== ''),
    mergeMap(progressId => {
      return loadProgress(progressId).catch(err => err);
    }),
    filter(res => res !== undefined && !(res instanceof ApiError)),
    // filter(res => {
    //   const staff = getStaffData(res);
    //   return !staff ? false : isValidStaff(staff as IStaff);
    // }),
    mergeMap(res => {
      const progressList = res as TProgressList;
      const result = {
        progressName: progressList[0].progressName,
        progressKey: progressList[0].progressId.split('-')[1], // staffCodeを除外
        progressType: progressList[0].progressType,
      };
      return [
        globalActions.updateAll(JSON.parse(progressList[0].progressData, jsonDateReviver)),
        actions.loadProgressData.done({ params: '', result }),
      ];
    }),
  );

const loadProgressListData: Epic<
  AnyAction,
  Action<Parameters<typeof actions.loadProgressList.done>[0] | { error: ApiError; options: any } | void>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadProgressList.started),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const staff = appStateObj.staff();
      return { staff };
    }),
    filter(({ staff }) => staff !== undefined),
    mergeMap(obj => {
      const staff = obj.staff as IStaff;
      return loadProgress(staff.staffCode).catch(err => err);
    }),
    filter(res => res !== undefined),
    map(res => {
      if (res instanceof ApiError) {
        // return ErrorHandlerActions.apiError({
        //   error: res,
        //   options: { contents: '一時保留リストの読み込みに失敗しました。' },
        // });
        // 一度、一時保留しないと毎回ダイアログが上がるため。
        return actions.loadProgressList.done({
          result: [],
        });
      }
      const progressList = res as TProgressList;
      return actions.loadProgressList.done({
        result: progressList.filter(list => list.progressType === progressTypeCd.MANUAL),
      });
    }),
  );

const deleteProgressData: Epic<AnyAction, Action<Parameters<typeof actions.sendRegistApi.started>[0]>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(actions.deleteProgressData.started),
    map(({ payload }) => {
      const param: IProgressRequest = {
        progressId: payload.progressId,
        progressName: payload.progressName,
        progressType: progressTypeCd.CANCEL,
        progressData: '{}',
      };
      return actions.sendRegistApi.started(param);
    }),
  );

const sendRegistRequest: Epic<
  AnyAction,
  Action<
    | Parameters<typeof actions.sendRegistApi.done>[0]
    | Parameters<typeof actions.update.done>[0]
    | IInformationDialog
    | { error: ApiError; options: any }
    | void
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.sendRegistApi.started),
    filter(() => !!getProgress(state.value) && state.value.progress.progressKey !== ''),
    filter(() => {
      const staff = getStaffData(state.value);
      return !staff ? false : isValidStaff(staff as IStaff);
    }),
    mergeMap(async ({ payload }) => {
      const result = await regProgress(payload).catch(err => err);
      return { result, params: payload };
    }),
    mergeMap(res => {
      // リクエストが成功していても、getResJson()でエラーが発生するため、statusCodeが200の場合はエラーとして扱わない。
      if (res.result instanceof ApiError && res.result.statusCode !== 200) {
        return [
          ErrorHandlerActions.apiError({
            error: res.result,
            options: { contents: '一時保存データの削除・更新に失敗しました。' },
          }),
          actions.sendRegistApi.failed({ error: res.result, params: res.params }),
        ];
      }
      // オーダー消去時の処理
      if (res.params.progressType === progressTypeCd.CANCEL) {
        return [actions.sendRegistApi.done({ params: res.params }), actions.loadProgressList.started()];
      }
      // オーダー一時保存時の処理 ホーム画面に遷移した後にオーダー情報をstateから消さないとバグが発生する。
      history.push(resolvePath(ERouterPath.home));
      return [
        actions.sendRegistApi.done({ params: res.params }),
        actions.loadProgressList.started(),
        orderDeleteActions.deleteOrders(),
        actions.update.done({ params: res.params.progressName }),
      ];
    }),
  );

const addQueue: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.addQueue),
    debounce(({ payload }) => timer((payload || 5) * 1000)),
    map(() => actions.autoUpdate.started()),
  );

// const doAddQueue: Epic<AnyAction, Action<void | number>, AppState> = (action$, state) =>
//   action$.pipe(
//     filter(action => !/^\[Progress\]/.test(action.type)),
//     map(() => actions.addQueue()),
//   );

// 読み込んだデータが顧客ログイン状態でない時に、一時保留名をcustomer/customerFamilyNameKanjiに設定
const setCustomerName: Epic<AnyAction, Action<ICustomer>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadProgressData.done),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const isCustomerLoaded = appStateObj.isCustomerLoaded();
      return { payload, isCustomerLoaded };
    }),
    filter(({ isCustomerLoaded }) => isCustomerLoaded !== undefined && !isCustomerLoaded),
    map(({ payload, isCustomerLoaded }) => {
      const customerFamilyNameKanji = payload.result.progressName as string;
      return CustomerActions.loadSuccess._action({
        ...INITIAL_CUSTOMER,
        customerFamilyNameKanji,
      });
    }),
  );

export const ProgressEpics = combineEpics(
  autoUpdate,
  update,
  initialProc,
  loadProgressData,
  loadProgressListData,
  deleteProgressData,
  sendRegistRequest,
  addQueue,
  // doAddQueue,
  setCustomerName,
);
