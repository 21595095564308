import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import clone from 'ramda/es/clone';

import { TProjectSearchCondition, TProjectFilter, TScProject } from '../../../../types/project';
import { addMonthsStartOfMonthDate, addMonthsEndOfMonthDate } from '../../../../helpers/common/date';

const ac = actionCreatorFactory('[store/utils/dialog/project-selector]');
const actions = {
  show: ac<void>('show'),
  close: ac<void>('close'),
  select: ac<TScProject>('select'),
  setFilter: ac<TProjectFilter>('setFilter'),
  resetFilter: ac<void>('resetFilter'),
  load: ac.async<{}, TScProject[], {}>('load'),
};
export const projectSelectorDialogActions = actions;

const initialFilter: TProjectSearchCondition = {
  companyName: '',
  contactName: '',
  startDate: addMonthsStartOfMonthDate(new Date(), -1),
  endDate: addMonthsEndOfMonthDate(new Date(), 1),
  hasIncludeUndecided: false,
};

// reducer
export type TProjectDialogState = {
  isLoading: boolean;
  hasOpen: boolean;
  filter: TProjectSearchCondition;
  results: TScProject[];
};

const initialState: TProjectDialogState = {
  isLoading: false,
  hasOpen: false,
  filter: clone(initialFilter),
  results: [],
};

const reducer = reducerWithInitialState(initialState)
  .case(actions.show, (state, payload) => ({
    ...state,
    hasOpen: true,
  }))
  .case(actions.load.started, (state, payload) => ({
    ...state,
    isLoading: true,
  }))
  .case(actions.load.done, (state, payload) => ({
    ...state,
    isLoading: false,
    results: payload.result,
  }))
  .case(actions.load.failed, (state, payload) => ({
    ...state,
    isLoading: false,
    results: [],
  }))
  .case(actions.close, (state, payload) => ({
    ...state,
    hasOpen: false,
  }))
  .case(actions.select, (state, payload) => ({
    ...state,
    searchedCondition: payload,
  }))
  .case(actions.setFilter, (state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      [payload.key]: payload.value,
    },
  }))
  .case(actions.resetFilter, (state, payload) => ({
    ...state,
    filter: {
      ...clone(initialFilter),
    },
  }));
export const projectSelectorDialogReducers = reducer;
