import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { II18nItem } from '../../i18n/_new/types';
import { langResource } from '../../i18n/_new/resources';
import { getLabel } from '../../i18n/_new/helper';

type TLangCode = keyof II18nItem;

export const toOrderTitle = (index: number, orderLength: number, langCode: TLangCode) =>
  getLabel(langCode, langResource.helper.order) + ` ${index + 1}/${orderLength}`;

export const toDeliveryWithLaterDays = (date: string, langCode: TLangCode) => {
  if (date === '') {
    return 'APIより納期を取得できていません!';
  }

  const convedDate = parse(date, 'yyyyMMdd', new Date());
  const formatDate = format(convedDate, 'yyyy年MM月dd日');
  return `${formatDate}`;
};

export const toSameAddressOrderOne = (langCode: TLangCode) => getLabel(langCode, langResource.helper.sameOneOrder);
