import React, { useState } from 'react';

import styles from './DesignSelectionContent.module.scss';

import Button from '../../atoms/Button/Button';
import DesignOptionBlouseSelector from '../../atoms/DesignOptionBlouseSelector/DesignOptionBlouseSelector';
import DesignOptionFreeInput from '../../molecules/DesignOptionFreeInput/DesignOptionFreeInput';
import DesignOptionGridSelector from '../../atoms/DesignOptionGridSelector/DesignOptionGridSelector';
import DesignOptionSpecialSelector from '../../organisms/DesignOptionSpecialSelector/DesignOptionSpecialSelector';

import { TPageContainerProps } from '../../../containers/pages/DesignSelectionPage';
import { getSpecialOptionillust } from '../../../lookups/special-option';
import { langResource } from '../../../i18n/_new/resources';
import { IllustrationDialog } from '../IllustrationDialog/IllustrationDialog';
import { piecesSelector } from '../../../store/order/object-selector';
import { isSelectTBlouse, isSelectBowBlouse } from '../../../helpers/item-thisisforreplaceall';
import { womenBlouseGroups } from '../../../lookups/item-thisisforreplaceall';

type TProps = Pick<
  TPageContainerProps,
  | 'orderNumber'
  | 'item'
  | 'products'
  | 'optionContent'
  | 'selectedOptionGroup'
  | 'selectingOptionPatternCode'
  | 'onSelectOption'
  | 'onSelectOptionClass'
  | 'onInputFree'
  | 'selectGroupId'
  | 'isEdit'
>;

const DesignSelectionContent: React.FC<TProps> = (props: TProps) => {
  const { optionPatterns, isSpecial, title, listItems, isBlouse } = props.optionContent;
  const [hasOpened, setHasOpened] = useState(false);
  const { categoryCode, pieces, cloth, design } = props.item;
  const { clothModelCode } = cloth;
  const partsNumber = piecesSelector(pieces).index2partsNumber(design.selecting.partsIndex);
  const illustImgPath = partsNumber ? getSpecialOptionillust(categoryCode, partsNumber, clothModelCode) : '';

  const renderSelector = () => {
    const pattern = optionPatterns[0];

    if (optionPatterns.length < 1) {
      return null;
    }

    if (isSpecial) {
      return <DesignOptionSpecialSelector {...props} optionPatterns={optionPatterns} optionListItems={listItems} />;
    }

    if (pattern.isFreeInput) {
      return <DesignOptionFreeInput {...props} optionPattern={pattern} />;
    }

    if (isBlouse) {
      return <DesignOptionBlouseSelector {...props} optionPattern={pattern} />;
    }

    return <DesignOptionGridSelector {...props} optionPattern={pattern} />;
  };

  const renderIllustButton = () => {
    return isSpecial && illustImgPath ? (
      <Button onClick={onOpneDialogHandler} textObj={langResource.designPage.illustrationBtn} />
    ) : null;
  };

  const renderSpecialOptionDialog = () => {
    return <IllustrationDialog isOpened={hasOpened} imgPath={illustImgPath} onClose={onCloseDialogHandler} />;
  };

  const onOpneDialogHandler = () => {
    setHasOpened(true);
  };

  const onCloseDialogHandler = () => {
    setHasOpened(false);
  };

  const renderTitle = () => {
    if (isBlouse) {
      const { selectedOptionGroup } = props;
      const selectingOptionGroupId = selectedOptionGroup[optionPatterns[0].optionNumber];
      if (isSelectTBlouse(optionPatterns[0].selectingClassNumber, selectingOptionGroupId)) {
        return womenBlouseGroups[0].optionGroupName;
      }
      if (isSelectBowBlouse(optionPatterns[0].selectingClassNumber, selectingOptionGroupId)) {
        return womenBlouseGroups[1].optionGroupName;
      }
      return title;
    }
    return title;
  };

  return (
    <React.Fragment>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3 className={styles.title}>{renderTitle()}</h3>
          {renderIllustButton()}
        </div>
        <div className={styles.selector}>{renderSelector()}</div>
      </div>
      {renderSpecialOptionDialog()}
    </React.Fragment>
  );
};

export default DesignSelectionContent;
