import { createSelector } from 'reselect';
import { AppState } from '../../index';
import { ILanguageState } from './action-reducer';
import { TPricePrefix } from '../../../types/orders';

export { langReducer, langActions } from './action-reducer';

const featureSelector = (state: AppState): ILanguageState => (state.utils.language || { code: 'jp' }) as ILanguageState;

const prefix = (code: string): TPricePrefix => (code === 'en' ? '$' : '¥');

export const getLanguage = {
  langCode: createSelector(featureSelector, state => state.code),
  pricePrefix: createSelector(featureSelector, state => prefix(state.code)),
};
