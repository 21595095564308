import React, { useState } from 'react';

import styles from './PartsSizeAdjustContent.module.scss';

import Button from '../../atoms/Button/Button';
import Title from '../../atoms/Title/Title';
import MeasurementList from '../../molecules/MeasurementList/MeasurementList';
import I18TextContainer from '../../../containers/I18Text/I18Text';

import { IMeasurementItem, IAdjustOptionItem } from '../../../types/new-store/components';
import { IGauge } from '../../../store/_type/lookups';
import { joinGauge, findGauge } from '../../../helpers/size-correction';
import GaugeSelector from '../../atoms/GaugeSelector/GaugeSelector';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';

import { IItem } from '../../../store/_type/order';
import SpecialAdjustOptionList from '../../molecules/SpecialAdjustOptionList/SpecialAdjustOptionList';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { langResource } from '../../../i18n/_new/resources';
import LinkButton from '../../molecules/LinkButton/LinkButton';
import { ERouterPath } from '../../../types';
import { infoDialogActions } from '../../../store/utils/dialog/info';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';

interface IProps {
  item: IItem;
  sizeHistoryDate: string;
  sizeHistoryGauge: string;
  selectingPartsGauge: IGauge;
  selectablePartsGauges: IGauge[];
  measurements: IMeasurementItem[];
  adjustOptions: IAdjustOptionItem[];
  memberscardNumber: string;
  specialAdjustOptions: IAdjustOptionItem[];
  selectingParts: TPartsNumber;
  onChangeGauge: typeof SizeCorrectionPageEvents.onChangeGauge;
  onChangeSizeMeasurement: typeof SizeCorrectionPageEvents.onChangeSizeMeasurement;
  onChangeAdjustOption: typeof SizeCorrectionPageEvents.onChangeAdjustOption;
  onCopyHistorySize: typeof SizeCorrectionPageEvents.onCopyHistorySize;
  onOpenPurchaseHistoryDialog: typeof SizeCorrectionPageEvents.onOpenPurchaseHistoryDialog;
  showInfoDialog: typeof infoDialogActions.show.type;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const PartsSizeAdjustContent: React.FC<TProps> = (props: TProps) => {
  const {
    sizeHistoryDate,
    sizeHistoryGauge,
    selectingPartsGauge,
    memberscardNumber,
    onChangeGauge,
    selectablePartsGauges,
    specialAdjustOptions,
  } = props;
  const [hasOpenPopup, togle] = useState(false);
  const [hasOpenMemberDialog, togleMemberDialog] = useState(false);
  const [memberscardNumberNew, setMembersCardNumberNew] = useState('');

  const onOpenClose = () => {
    togle(!hasOpenPopup);
  };

  const isDisableCopyBtn = !findGauge(sizeHistoryGauge, selectablePartsGauges);
  const onCopy = () => {
    props.onCopyHistorySize();
  };

  const onOpenEnterMembersCardNumberDialog = () => {
    togleMemberDialog(!hasOpenMemberDialog);
  };
  const setMembersCardNumberState = () => {
    if (memberscardNumberNew === '' || memberscardNumberNew === 'DOESNOTEXIST') {
      return;
    }
    localStorage.setItem('tempMembersCardNumber', memberscardNumberNew);
    setMembersCardNumberNew('DOESNOTEXIST');
    togleMemberDialog(false);
    onOpenPurchaseHistoryDialog();
  };

  const onOpenPurchaseHistoryDialog = () => {
    props.onOpenPurchaseHistoryDialog();
  };

  return (
    <div id={styles.container}>
      {hasOpenMemberDialog ? (
        <section className={styles.modal}>
          <span onClick={onOpenEnterMembersCardNumberDialog} className={styles.close}>
            x
          </span>
          <input
            type="text"
            onChange={e => setMembersCardNumberNew(e.target.value)}
            className={styles.memberInput}
          ></input>
          <button onClick={() => setMembersCardNumberState()} className={styles.memberSubmit}>
            確定
          </button>
        </section>
      ) : null}
      <div className={styles.top}>
        <div className={styles.history}>
          <dl className={styles.date}>
            <dt>
              <I18TextContainer textObj={langResource.sizePage.purchaseDate} />：
            </dt>
            <dd>{sizeHistoryDate}</dd>
          </dl>
          <dl className={styles.gauge}>
            <dt>
              <I18TextContainer textObj={langResource.sizePage.gauge} />：
            </dt>
            <dd>{sizeHistoryGauge}</dd>
          </dl>
          <div className={styles.button}>
            <div className={styles.buttonItem}>{renderPurchageHistoryBtn()}</div>
            <div className={styles.buttonItem}>
              <Button
                styles={['size-xs', 'black', 'block']}
                disabled={isDisableCopyBtn}
                textObj={langResource.sizePage.copyBtn}
                onClick={onCopy}
              />
            </div>
          </div>
        </div>
        <div className={styles.basegauge}>
          <div className={styles.label}>
            <Title styles={['medium', 'center']} textObj={langResource.sizePage.masterSize} />
          </div>
          <div className={styles.gauge}>
            <div className={styles.selector}>
              <div className={styles.value}>{joinGauge(selectingPartsGauge)}</div>
            </div>
            <div className={styles.button}>
              <Button
                styles={['size-xs', 'black', 'block']}
                onClick={onOpenClose}
                textObj={langResource.sizePage.changeBtn}
              />
            </div>
            {renderPopup()}
          </div>
        </div>
        <div className={styles.sizename}>{/* 背景色とサイズを合わせるため */}</div>
        <div className={styles.adjustment}>
          <div className={styles.title}>
            <Title styles={['center']} textObj={langResource.sizePage.sizeMeasurement} />
          </div>
          <div className={styles.diff}>
            <I18TextContainer textObj={langResource.sizePage.diff} />
          </div>
        </div>
      </div>
      <div className={styles.measure}>
        <MeasurementList {...props} />
      </div>
      <div className={styles.special}>
        <SpecialAdjustOptionList {...props} />
      </div>
      <div className={styles.specialButton}>
        <div className={styles.specialButtonHistory}>{/* 背景色とサイズを合わせるため */}</div>
        <div className={styles.specialButtonBasegauge}>{/* 背景色とサイズを合わせるため */}</div>
        <div className={styles.specialButtonSizename}>{/* 背景色とサイズを合わせるため */}</div>
        <div className={styles.specialButtonAdjustment}>
          <div className={styles.specialButtonButton}>
            {specialAdjustOptions?.length > 0 ? (
              <LinkButton
                styles={['size-l', 'center']}
                textObj={langResource.sizePage.specialAdjustBtn}
                to={ERouterPath.specialAdjust}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );

  function renderPurchageHistoryBtn() {
    return (
      <Button
        styles={['size-xs', 'black', 'block']}
        textObj={langResource.sizePage.purchaseHistoryBtn}
        onClick={onOpenEnterMembersCardNumberDialog}
      />
    );
  }

  function renderPopup() {
    if (!hasOpenPopup) {
      return null;
    }

    const onSelected = (gauge: IGauge) => {
      onChangeGauge({ gauge });
      onOpenClose();
    };

    return (
      <GaugeSelector
        selectingGauge={selectingPartsGauge}
        selectableGauges={selectablePartsGauges}
        onSelected={onSelected}
        onClose={onOpenClose}
      />
    );
  }
};

export default PartsSizeAdjustContent;
