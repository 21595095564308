import { ICloth, IOption } from '../../_type/order';
import {
  MASTER_NUDE_DEMENTION_LIMIT,
  NUDE_DEMENTION_LIMIT_DEFAULT_SIZE,
} from '../../../lookups/item-thisisforreplaceall';

/************************************************************
 * 値単体のvalidation
 ************************************************************/

export const isValidSeasonCode = (seasonCode: string): boolean => /^[A-Z]{2}$/.test(seasonCode);
export const isValidClothCode = (clothCode: string): boolean => /^[0-9]{4}$/.test(clothCode);
export const isValidTextileNo = (textileNo: string): boolean => /^[a-zA-Z0-9-]{0,20}$/.test(textileNo);

/************************************************************
 * 複数の値でのvalidation
 ************************************************************/

export const canUpdateClothCode = (newClothCode: string, currClothState: ICloth): boolean =>
  isValidClothCode(newClothCode) &&
  newClothCode !== currClothState.clothCode &&
  isValidSeasonCode(currClothState.seasonCode);

/**
 * すべてのヌード寸の入力値が有効な範囲にある場合
 */
export const isValidNudeMeasurements = (measurements: IOption[]): boolean => {
  return !measurements.some(measurement => {
    const { optionNumber, optionClassName } = measurement;
    const measurementValue = typeof optionClassName !== 'undefined' ? +optionClassName : 0;
    const master = MASTER_NUDE_DEMENTION_LIMIT.find(v => v.code === optionNumber);
    if (!master) {
      return (
        measurementValue < NUDE_DEMENTION_LIMIT_DEFAULT_SIZE.lower ||
        NUDE_DEMENTION_LIMIT_DEFAULT_SIZE.upper < measurementValue
      );
    }
    return measurementValue < master.value.lower || master.value.upper < measurementValue;
  });
};

/**
 * 有効なmemberscardNumberか？
 */
export const isValidMemberscardNumber = (memberscardNumber: string): boolean => {
  return memberscardNumber.length < 31;
};

/**
 * 生地商品一覧の再取得条件
 */
export const isReloadClothProducts = (clothCode: string, preClothCode: string, seasonCode: string): boolean => {
  return clothCode.length === 4 && clothCode !== preClothCode && seasonCode !== '';
};
