import { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { actions } from '../../store/progress/actions';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    initialProc: () => dispatch<any>(actions.initialProc()),
    updateProgress: () => dispatch<any>(actions.autoUpdate.started()),
  };
}

export type TProps = ReturnType<typeof mapDispatchToProps>;

class BackgroundProcess extends Component<TProps> {
  private intervalId: NodeJS.Timeout | undefined = undefined;

  componentDidMount() {
    this.props.initialProc();
    // this.intervalId = setInterval(() => this.props.updateProgress(), 10000);
  }
  componentWillUnmount() {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
    }
  }
  render() {
    return null;
  }
}

export default connect(null, mapDispatchToProps)(BackgroundProcess);
