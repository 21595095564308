import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { debounce } from 'lodash';

import { thunkActionErrorHandler } from '../../../helpers/error-handler';
import { getIntroducer } from '../../../services/operator/introducer';
import { getDestination } from '../../../services/customer/destination';
import { getUseablePointsWithApi } from '../../../services/customer/useablePoints';
import { IntroducerRes } from '../../../api/operator/introducer';
import { DestinationRes } from '../../../api/customer/destination';
import { UseablePointsRes } from '../../../api/customer/useablePoints';
import { thunkActionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { IPayment, TPartialPayment } from '../../_type/payment';
import { KeyExclude } from '../../../types';
import { paymentActions } from '../../payment';
import { isValidMemberscardNumber } from '../../order/helper/validate';
import { isValidIntroducerCode, isValidFavoriteCustomerCode } from '../../../helpers/common/validate';
import { scProjectActions } from '../../sc-project';

type TDebounceName = 'favoriteCustomerName' | 'introducerName';

type TLoadInitializeParam = {
  memberscardNumber: string;
};

type TChangeValueParam = {
  key: keyof KeyExclude<IPayment, TDebounceName>;
  value: string | number | boolean;
};

type TChangeRadioButtonParam = {
  key: keyof Pick<IPayment, 'hasIntroducer' | 'hasFavoriteCustomer' | 'needInvoice'>;
  value: boolean;
};

// ポイント取得
async function getUseablePoints(memberscardNumber: string) {
  if (!isValidMemberscardNumber(memberscardNumber)) {
    return 0;
  }
  const req = {
    pathParams: {
      memberscardNumber,
    },
  };
  const useablePoints = await getUseablePointsWithApi(req).then(toUseablePoints);

  // 0未満は0と同等として扱う
  return useablePoints < 0 ? 0 : useablePoints;
}

function toUseablePoints(res: UseablePointsRes): number {
  const expression = 'currentPoint';
  return res.query(expression);
}

// 紹介者情報取得
async function getIntroducerName(dispatch: ThunkDispatch<any, any, AnyAction>, code: string) {
  try {
    const req = {
      query: {
        introducerCode: code,
      },
    };
    const data = await getIntroducer(req).then(toIntroducer);
    const value = data[0].introducerName;
    const updated: TPartialPayment = {
      introducerName: value,
    };
    paymentActions.update.dispatcher(dispatch)({ payment: updated });
    return;
  } catch (ex) {
    const updated: TPartialPayment = {
      introducerName: '',
    };
    paymentActions.update.dispatcher(dispatch)({ payment: updated });
    return thunkActionErrorHandler(ex, dispatch);
  }
}

function toIntroducer(res: IntroducerRes): [{ introducerCode: string; introducerName: string }] {
  const expression = 'introducers';
  const resData = res.query(expression);
  return resData;
}

// 訪問販売先情報取得
async function getFavoriteCustomerName(dispatch: ThunkDispatch<any, any, AnyAction>, code: string) {
  try {
    const req = {
      query: {
        destinationCode: code,
      },
    };

    const data = await getDestination(req).then(toDestination);
    const { destinationName, deliveryRate, corporationFlag } = data[0];
    const isCorporation = corporationFlag === '1' ? true : false;
    const updated: TPartialPayment = {
      favoriteCustomerName: destinationName,
      deliveryRate,
      isCorporation,
    };
    paymentActions.update.dispatcher(dispatch)({ payment: updated });
    return;
  } catch (ex) {
    const updated: TPartialPayment = {
      favoriteCustomerName: '',
      deliveryRate: 0,
      isCorporation: false,
    };
    paymentActions.update.dispatcher(dispatch)({ payment: updated });
    return thunkActionErrorHandler(ex, dispatch);
  }
}

function toDestination(
  res: DestinationRes,
): [{ destinationCode: string; destinationName: string; deliveryRate: number; corporationFlag: string }] {
  const expression = 'destinations';
  const resData = res.query(expression);
  return resData;
}

const getDebounceNameFunc = (nameKey: TDebounceName) => {
  const debounceAc = nameKey === 'introducerName' ? getIntroducerName : getFavoriteCustomerName;
  return debounce(
    async (dispatch: ThunkDispatch<any, any, AnyAction>, code: string) => await debounceAc(dispatch, code),
    500,
  );
};

// asyncAction
const actionTitle = 'point page';
const asyncAc = thunkActionObjectCreatorFactory();
export const pointPageActions = {
  loadInitialize: asyncAc<TLoadInitializeParam, any>(`[${actionTitle}] initialize`, async (payload, dispatch) => {
    try {
      // MEMO: ポイント読み込み前には次のページに進めないようにする対応
      paymentActions.update.dispatcher(dispatch)({ hasLoadedUseablePoint: false });
      const useablePoints = await getUseablePoints(payload.memberscardNumber);
      const backPoints = 0;
      paymentActions.update.dispatcher(dispatch)({ useablePoints, backPoints, hasLoadedUseablePoint: true });
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
  changeRadioButton: asyncAc<TChangeRadioButtonParam, any>(
    `[${actionTitle}] changeRadioButton`,
    async (payload, dispatch) => {
      const { key, value } = payload;
      const updated: TPartialPayment = {
        [key]: value,
      };

      if (!value) {
        if (key === 'hasIntroducer') {
          updated.introducerCode = '';
          updated.introducerName = '';
        } else {
          updated.favoriteCustomerCode = '';
          updated.favoriteCustomerName = '';
          updated.deliveryRate = 0;
          updated.isCorporation = false;
          updated.needInvoice = false;
          if (key !== 'needInvoice') {
            dispatch(scProjectActions.reset());
          }
        }
      }

      paymentActions.update.dispatcher(dispatch)({ payment: updated });
    },
  ),
  changeValue: asyncAc<TChangeValueParam, any>(`[${actionTitle}] changeValue`, async (payload, dispatch) => {
    try {
      const { key, value } = payload;
      const updated: TPartialPayment = {
        [key]: value,
      };
      paymentActions.update.dispatcher(dispatch)({ payment: updated });

      if (!['introducerCode', 'favoriteCustomerCode'].includes(key)) {
        // 上記でない場合, return
        return;
      }

      // 紹介者コードを変更時には紹介者名を削除,
      // 訪問販売先コードを変更時には訪問販売先名と納入率を削除
      const initial: TPartialPayment = {};
      if (key === 'introducerCode') {
        initial.introducerName = '';
      } else {
        initial.favoriteCustomerName = '';
        initial.deliveryRate = 0;
        initial.isCorporation = false;
      }
      paymentActions.update.dispatcher(dispatch)({ payment: initial });

      // API Call
      const code = String(value);
      const nameKey = key === 'introducerCode' ? 'introducerName' : 'favoriteCustomerName';
      const isValid = key === 'introducerCode' ? isValidIntroducerCode(code) : isValidFavoriteCustomerCode(code);
      const debounceFunc = getDebounceNameFunc(nameKey);
      if (!isValid) {
        debounceFunc.cancel();
        return;
      }
      debounceFunc(dispatch, code);
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
};
