import React, { useState } from 'react';

import styles from './ClothSelectionContent.module.scss';

import Label from '../../atoms/Label/Label';
import Button from '../../atoms/Button/Button';
import InputText from '../../../containers/components/InputText';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import NumberFormat from 'react-number-format';

import { ILookupItem } from '../../../types/lookup';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import {
  isIllustrationButtonOnClothSection,
  isModelSelectBoxOnClothSection,
} from '../../../helpers/item-thisisforreplaceall/';
import { TPageContainerProps } from '../../../containers/pages/ClothSelectionPage';
import { RouteComponentProps } from 'react-router';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import { langResource } from '../../../i18n/_new/resources/index';
import { IOnChangeClothValue } from '../../../store/_type/order';
import { IllustrationDialog } from '../IllustrationDialog/IllustrationDialog';

type TProps = TPageContainerProps & RouteComponentProps & ICustomKeyboardInjectedProps;

const ClothSelectionContent: React.FC<TProps> = props => {
  const { item, brandSelectItem, modelSelectItem, canAddtionalInput, shortestDeliveryDate, isEdit } = props;
  const { cloth, categoryCode, pieces } = item;
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const onChangeValueHandler = (key: IOnChangeClothValue['key']) => (
    event: React.ChangeEvent<HTMLInputElement> | ILookupItem,
  ) => {
    const value = isInputText(event) ? event.target.value : String(event.id);
    props.onChange({ key, value });
  };

  const illustButtonClickHandler = () => {
    setIsOpened(true);
  };

  const onCloseDialogHandler = () => {
    setIsOpened(false);
  };

  function isInputText(value: any): value is React.ChangeEvent<HTMLInputElement> {
    return value.hasOwnProperty('target');
  }

  return (
    <div className={styles.clothSelectionContent}>
      <div className={styles.row}>
        <div className={styles.columnSize1}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.clothCode} styles={getLabelStyles(isEdit)} />
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputItem1}>
              <p className={styles.textItem}>{cloth.clothSeasonCode || cloth.seasonCode}</p>
            </div>
            <div className={styles.inputItem2}>
              <NumberFormat
                readOnly={true}
                value={cloth.clothCode}
                allowNegative={false}
                // decimalScale={0} FIXME: stateが反映されないので一時的にコメントアウト
                onFocus={connectNumericKeyboard}
                data-input-type={EKeyboardInputType.numberText}
                disabled={isEdit}
              />
            </div>
          </div>
        </div>
        <div className={styles.columnSize1}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.textileNo} styles={getLabelStyles(!canAddtionalInput || isEdit)} />
          </div>
          <InputText
            disabled={!canAddtionalInput || isEdit}
            onValueChanged={onChangeValueHandler('vendorClothNumber')}
            value={cloth.vendorClothNumber}
          />
        </div>
        <div className={styles.columnSize2}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.deliveryDate} styles={['large']} />
          </div>
          <p className={styles.textItem}>{shortestDeliveryDate}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.columnSize1}>
          <div className={styles.label}>
            <Label
              textObj={langResource.clothPage.brand}
              styles={getLabelStyles(brandSelectItem.isDisable || isEdit)}
            />
          </div>
          <SelectBox
            canDisplayEmpty={false}
            disabled={brandSelectItem.isDisable || isEdit}
            data={brandSelectItem.data}
            selectedOption={brandSelectItem.selected}
            onValueChanged={onChangeValueHandler('brandCode')}
          />
        </div>
        {renderModelSelectBoxOnClothSection()}
        <div className={styles.columnSize1}>
          <div className={styles.label} />
          {renderIllustButton()}
          {renderClothModelDialog()}
        </div>
      </div>
    </div>
  );

  function getLabelStyles(disabled: boolean): string[] {
    return disabled ? ['large', 'disabled'] : ['large'];
  }

  function connectNumericKeyboard() {
    props.connectNumericKeyboard(cloth.clothCode, value => {
      if (value.length > 4) {
        return value.substring(0, 4);
      }
      props.onChange({ key: 'clothCode', value });
    });
  }

  function renderModelSelectBoxOnClothSection() {
    if (!isModelSelectBoxOnClothSection(categoryCode, pieces, brandSelectItem.selected)) {
      return;
    }
    return (
      <div className={styles.columnSize1}>
        <div className={styles.label}>
          <Label textObj={langResource.clothPage.model} styles={getLabelStyles(modelSelectItem.isDisable || isEdit)} />
        </div>
        <SelectBox
          canDisplayEmpty={false}
          disabled={modelSelectItem.isDisable || isEdit}
          data={modelSelectItem.data}
          selectedOption={modelSelectItem.selected}
          onValueChanged={onChangeValueHandler('clothModelCode')}
        />
      </div>
    );
  }

  function renderIllustButton() {
    return isIllustrationButtonOnClothSection(categoryCode, pieces) ? (
      <Button onClick={illustButtonClickHandler} textObj={langResource.clothPage.illustrationBtn} />
    ) : null;
  }

  function renderClothModelDialog() {
    return (
      <IllustrationDialog
        isOpened={isOpened}
        imgPath="/tempo/_illustration/cloth_model.png"
        onClose={onCloseDialogHandler}
      />
    );
  }
};

export default ClothSelectionContent;
