import React from 'react';
import partial from 'ramda/es/partial';

import styles from './ClothSelectionComposition.module.scss';

import Label from '../../atoms/Label/Label';
import SelectBox from '../../atoms/SelectBox/SelectBox';
// types
import { ILookupItem } from '../../../types/lookup';
import { ICloth } from '../../../store/_type/order';
// lookups
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import NumberFormat from 'react-number-format';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import { langResource } from '../../../i18n/_new/resources/';
import { TPageContainerProps } from '../../../containers/pages/ClothSelectionPage/';
import { RouteComponentProps } from 'react-router-dom';
import { IOnChangeClothValue } from '../../../store/_type/order';
import { INITIAL_COMPOSITION } from '../../../store/order/initial-state';
import { isValidRequiredScale } from '../../../helpers/common/validate';
import { getCssClassErrorInvalidData } from '../../../helpers/html-css';
// import { CSS_CLASSNAME_ERROR_INVALID_DATA } from '../../../lookups/html-css';
import { ClothItem } from '../../../lookups/item-thisisforreplaceall/order-status';
import { canEditItem } from '../../../helpers/orders/digest';

type TProps = TPageContainerProps & RouteComponentProps & ICustomKeyboardInjectedProps;

const ClothSelectionComposition: React.FC<TProps> = props => {
  const {
    canSelectStockPlace,
    canAddtionalInput,
    canAddtionalInputColorDesign,
    colorSelectItem,
    designSelectItem,
    stockSelectItem,
    compositionLookups,
    item,
    isManager,
    orderStatus,
    // isCompositionFrontValueCorrect,
  } = props;
  // const frontCompositionInputClass = isCompositionFrontValueCorrect ? undefined : CSS_CLASSNAME_ERROR_INVALID_DATA;
  // const frontCompositionInputErrorMsg = isCompositionFrontValueCorrect ? '' : '生地組成を正しく入力してください.';

  const labelStyles = !canAddtionalInput ? ['medium', 'disabled'] : ['medium'];
  const canEditClothItem = partial(canEditItem, [orderStatus, isManager]);

  const onChangeValueHandler = (key: IOnChangeClothValue['key']) => (
    event: React.ChangeEvent<HTMLInputElement> | ILookupItem,
  ) => {
    const value = isInputText(event) ? event.target.value : String(event.id);
    props.onChange({ key, value });
  };

  const onChangeCompositionHandler = (
    key: keyof Pick<ICloth, 'compositionFront' | 'compositionBack'>,
    index: number,
  ) => (lookup: ILookupItem) => {
    const value = String(lookup.id);
    props.onChangeComposition({ key, index, subkey: 'mixing', value });
  };

  const connectNumericKeyboard = (
    ratio: string,
    key: keyof Pick<ICloth, 'compositionFront' | 'compositionBack'>,
    index: number,
  ) => (event: React.FocusEvent<HTMLInputElement>) => {
    props.connectNumericKeyboard(ratio, value => {
      if (+value <= 100) {
        props.onChangeComposition({ key, index, subkey: 'mixingRatio', value });
      } else {
        return 100;
      }
    });
  };
  // 要尺用キーボード接続メソッド
  const connectNumericKeyboardForScale = () => {
    props.connectNumericKeyboard(item.cloth.requiredScale, value => {
      props.onChange({ key: 'requiredScale', value });
    });
  };

  return (
    <div className={styles.clothSelectionComposition}>
      <div className={styles.row}>
        <div className={styles.columnSize1}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.clothColor} styles={labelStyles} />
          </div>
          <SelectBox
            canDisplayEmpty={false}
            disabled={!canAddtionalInputColorDesign || !canEditClothItem(ClothItem.color)}
            data={colorSelectItem.data}
            selectedOption={colorSelectItem.selected}
            onValueChanged={onChangeValueHandler('personalorderColorCode')}
          />
        </div>
        <div className={styles.columnSize1}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.clothDesign} styles={labelStyles} />
          </div>
          <SelectBox
            canDisplayEmpty={false}
            disabled={!canAddtionalInputColorDesign || !canEditClothItem(ClothItem.clothDesign)}
            data={designSelectItem.data}
            selectedOption={designSelectItem.selected}
            onValueChanged={onChangeValueHandler('design')}
          />
        </div>
        {renderScaleInput()}
      </div>
      <div className={styles.row}>
        <div className={styles.columnSize2}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.compositionFront} styles={labelStyles} />
          </div>
          <div className={styles.inputWrap}>
            {Array.from({ length: 3 }).map((v, i) => renderComposition('compositionFront', i))}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.columnSize2}>
          <div className={styles.inputWrap}>
            {// MEMO:+3しているのは2段目は４番目から表示するってこと
            Array.from({ length: 3 }).map((v, i) => renderComposition('compositionFront', i + 3))}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.columnSize2}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.compositionBack} styles={labelStyles} />
          </div>
          <div className={styles.inputWrap}>
            {Array.from({ length: 2 }).map((v, i) => renderComposition('compositionBack', i))}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.columnSize1}>
          <div className={styles.label}>
            <Label textObj={langResource.clothPage.stockPlace} styles={labelStyles} />
          </div>
          <SelectBox
            canDisplayEmpty={false}
            disabled={!canSelectStockPlace || !canEditClothItem(ClothItem.stockPlace)}
            data={stockSelectItem.data}
            selectedOption={stockSelectItem.selected}
            onValueChanged={onChangeValueHandler('stockPlaceCode')}
          />
        </div>
      </div>
    </div>
  );

  function isInputText(value: any): value is React.ChangeEvent<HTMLInputElement> {
    return value.hasOwnProperty('target');
  }

  function renderScaleInput() {
    if (!canAddtionalInput) {
      return;
    }

    const isValidRequiredScaleInput = !item.cloth.requiredScale || isValidRequiredScale(item.cloth.requiredScale);
    const requiredScaleInputClass = getCssClassErrorInvalidData(isValidRequiredScaleInput);
    const requiredScaleInputErrorMag = isValidRequiredScaleInput
      ? ''
      : '要尺は整数3桁、小数点以下2桁で入力してください';

    return (
      <div className={styles.columnSize1}>
        <div className={styles.label}>
          <Label textObj={langResource.clothPage.scale} styles={labelStyles} />
        </div>
        <NumberFormat
          readOnly={true}
          value={item.cloth.requiredScale}
          allowNegative={false}
          // decimalScale={0} FIXME: stateが反映されないので一時的にコメントアウト
          onFocus={connectNumericKeyboardForScale}
          data-input-type={EKeyboardInputType.number}
          className={requiredScaleInputClass}
          max={999.99}
          disabled={!canEditClothItem(ClothItem.scale)}
        />
        <div className="error-tooltip">{requiredScaleInputErrorMag}</div>
      </div>
    );
  }

  function renderComposition(key: keyof Pick<ICloth, 'compositionFront' | 'compositionBack'>, index: number) {
    const composition = { ...(props.item.cloth[key][index] ? props.item.cloth[key][index] : INITIAL_COMPOSITION) };
    return (
      <div className={styles.inputSet} key={index}>
        <div className={styles.inputItem1}>
          <SelectBox
            disabled={!canAddtionalInput || !canEditClothItem(ClothItem[key])}
            data={compositionLookups}
            selectedOption={composition.mixing}
            onValueChanged={onChangeCompositionHandler(key, index)}
          />
        </div>
        <div className={styles.inputItem2}>
          <NumberFormat
            readOnly={true}
            value={composition.mixingRatio}
            disabled={!canAddtionalInput || !canEditClothItem(ClothItem[key])}
            allowNegative={false}
            // decimalScale={0} FIXME: stateが反映されないので一時的にコメントアウト
            max={100}
            onFocus={connectNumericKeyboard(String(composition.mixingRatio), key, index)}
            data-input-type={EKeyboardInputType.number}
          />
        </div>
      </div>
    );
  }
};

export default ClothSelectionComposition;
