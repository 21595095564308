import { createSelector } from 'reselect';

import { orderState } from '../../order/selector/order';
import { AppState } from '../..';
import { ILookupFeature } from '../../lookups';

const featureSelector = (state: AppState): ILookupFeature => state.lookups;

const sizeMeasurementState = createSelector(featureSelector, state => state.sizeMeasurement);

export const getSizeMeasurement = {
  all: createSelector(sizeMeasurementState, state => state.sizeMeasurements),
  current: {
    detail: createSelector(
      sizeMeasurementState,
      orderState,
      (state, order) => state.sizeMeasurements[order.currentOrderNumber],
    ),
    // TODO: 下記は全てContainerでやるべき？
    /** 選択中のパーツの仕上がり寸のマスターを返却する */
    partsMeasurement: createSelector(sizeMeasurementState, orderState, (state, order) => {
      const { currentOrderNumber, orders } = order;
      const { partsNumber } = orders[currentOrderNumber].item.size.selecting;
      if (!state.sizeMeasurements[currentOrderNumber]) {
        return [];
      }
      const measurementItems = state.sizeMeasurements[currentOrderNumber].filter(v => v.partsNumber === partsNumber);
      return measurementItems ? measurementItems : [];
    }),
  },
};
