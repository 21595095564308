import { TCategory } from '../../lookups/master-thisisforreplaceall';
import { IndexedObject } from '../../types';

const middlePath = 'img_options';

const getImagePathQuery = (value: IndexedObject): string => {
  const usp = new URLSearchParams();
  Object.keys(value).forEach(param => {
    usp.append(param, value[param]);
  });
  return `?${usp.toString()}`;
};

export function getOptionImagePath(
  optionNumber: string,
  optionClassNumber: string,
  category: TCategory,
  modelPattern: string,
  brand: string,
): string {
  return `http://${window.location.host}/${middlePath}/_options/${optionNumber}/${optionClassNumber}.png`;
}

export function getNotFoundImage(): string {
  return `http://${window.location.host}/${middlePath}/noimage.png`;
}

export function getModelImagePath(category: TCategory, modelPattern: string, brand: string): string {
  return `http://${window.location.host}/${middlePath}/noimage.png`;
}
