import { createSelector } from 'reselect';
//
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { IStaff } from '../_type/staff';
import { getInitialOrderNumber } from '../../helpers/orders';
import { isValidModyfiedOrder } from '../../helpers/edited-order-validate';
import { ICustomer } from '../_type/customer';
import { TProductKind } from '../_type/order';

const orderDetailState = createSelector(appStateSelector, selecter => selecter.orderDetail());

export const getOrderDetail = {
  orderDetail: orderDetailState,
  memberscardNumber: createSelector(orderDetailState, detail =>
    detail && detail.customer ? detail.customer.memberscardNumber : 0,
  ),
  staff: createSelector(orderDetailState, detail => (detail && detail.staff ? detail.staff : ({} as IStaff))),
  hasCurrentOrder: createSelector(appStateSelector, selecter => {
    const orderState = selecter.orderState();
    return orderState ? Object.keys(orderState.orders).length > 0 : false;
  }),
  orderDate: createSelector(orderDetailState, detail => {
    const DEFAULT_DATE = '20000101';
    if (!detail || !detail.orders) {
      return DEFAULT_DATE;
    }
    const order = detail.orders[getInitialOrderNumber(detail.orders)];
    if (!order) {
      return DEFAULT_DATE;
    }
    return order.orderDate || DEFAULT_DATE;
  }),
  isValidModifyOrder: createSelector(appStateSelector, selecter => {
    const currentOrderNumber = selecter.orderNumber();
    const currentOrder = selecter.order();
    const orderDetail = selecter.orderDetail();
    const products = selecter.products();
    const options = selecter.avaliableOptions();
    if (!currentOrder || !orderDetail || !products || !options || !orderDetail.backup.orders[currentOrderNumber]) {
      return false;
    }
    const prevOrder = orderDetail.backup.orders[currentOrderNumber];
    const isValid = isValidModyfiedOrder(currentOrder, prevOrder, products, options);
    return isValid;
  }),
  customer: createSelector(appStateSelector, selecter => {
    const orderDetail = selecter.orderDetail();
    if (!orderDetail || !orderDetail.customer) {
      return {} as ICustomer;
    }
    return orderDetail.customer;
  }),
  serialNumber: createSelector(appStateSelector, selector => {
    const orderDetail = selector.orderDetail();
    return orderDetail ? orderDetail.serialNumber : '';
  }),
  productKind: createSelector(appStateSelector, selecter => {
    const orderDetail = selecter.orderDetail();
    return orderDetail ? orderDetail.productKind : ('' as TProductKind);
  }),
  pending: createSelector(appStateSelector, selecter => {
    const orderDetail = selecter.orderDetail();
    if (orderDetail && orderDetail.pending) {
      return true;
    }
    return false;
  }),
};
