import { TRequestConfig, TRequestParams } from '../..';
import MOCK_UP_JSON from '../../../mockups/jsonServer/mockup.json';
// import config from '../../../configuration/config';
import { toCamelCaseObject } from '../../../helpers/conv-object/convertions';

export { OrderDigestRes } from './OrderDigestRes.class';

export const orderDigestRequestConfig: TRequestConfig = {
  endpoint: '/orders/digest',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: getSearchDigestsSuccessData(),
    };
  },
  params2request: (params): TRequestParams => {
    return {
      query: params ? params.query : null,
    };
  },
};

function getSearchDigestsSuccessData(): string {
  const success = MOCK_UP_JSON['order-digests'];
  return JSON.stringify(toCamelCaseObject(success!));
}
