import { RecommendGaugeRes } from '../../../api/items/recommend-gauge';
import { IRecommendPartsGauge, IGauge } from '../../../store/_type/lookups';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';

export function toRecommendPartsGauges(res: RecommendGaugeRes): IRecommendPartsGauge[] {
  const data = res.query('parts');
  const adjustOptions = Array.from({ length: data.length }).map((v, i) => toRecommendPartsGauge(res, i, 'parts'));
  return adjustOptions;
}

function toRecommendPartsGauge(res: RecommendGaugeRes, index: number, parentExpression: string): IRecommendPartsGauge {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  // FIXME: gaugesじゃない？
  const data = getValue<any>('allGauge');
  const allGaugeExpression = getExpression('allGauge');
  const allGauges = Array.from({ length: data.length }).map((v, i) => toGauge(res, i, allGaugeExpression));

  const recommendedExpression = getExpression('recommendedGauge');
  const ret: IRecommendPartsGauge = {
    partsNumber: getValue<TPartsNumber>('partsNumber'),
    recommendedGauge: toRecommendedGauge(res, recommendedExpression),
    allGauges,
  };

  return ret;
}

function toRecommendedGauge(res: RecommendGaugeRes, parentExpression: string): IGauge {
  const getExpression = (v: string): string => {
    return `${parentExpression}.${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const major = getValue<string>('majorGauge') || '';
  const minor = getValue<string>('minorGauge') || '';
  return { major, minor };
}

function toGauge(res: RecommendGaugeRes, index: number, parentExpression: string): IGauge {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const major = getValue<string>('majorGauge') || '';
  const minor = getValue<string>('minorGauge') || '';
  return { major, minor };
}
