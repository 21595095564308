import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';
import { settlementPageEvents } from '../../../views/events/SettlementPage';
import { mapCommonPageDispatchToProps } from '../CommonPage';

import SettlementPage from '../../../components/pages/SettlementPage/SettlementPage';
import { orderState } from '../../../store/order/selector/order';
import { getSettlement } from '../../../store/settlement';

function mapStateToProps(state: AppState) {
  return {
    orders: orderState(state).orders,
    isRegisting: getSettlement.isLoading(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(settlementPageEvents, dispatch),
    ...mapCommonPageDispatchToProps(dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SettlementPage);
