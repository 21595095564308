import React from 'react';

import styles from './DesignOptionListItem.module.scss';

import DesignOptionListItemPaper from '../../atoms/DesignOptionListItemPaper/DesignOptionListItemPaper';
import DesignOptionSpecialSelectBox from '../../atoms/DesignOptionSpecialSelectBox/DesignOptionSpecialSelectBox';

import { IOptionListItem } from '../../../types/option';
import { IItem } from '../../../store/_type/order';
import { IClothProduct } from '../../../store/_type/lookups';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  partsIndex: string;
  // action
  onSelectOption: typeof DesignSelectionPageEvents.onSelectOption;
  onSelectOptionClass?: typeof DesignSelectionPageEvents.onSelectOptionClass;
}

type TProps = IProps & IOptionListItem;

const DesignOptionListItem: React.FC<TProps> = (props: TProps) => {
  const { orderNumber, partsIndex, optionNumber, item, isSpecial, products } = props;

  const handleClose = () => {
    const hasOpen = false;
    props.onSelectOption({ partsIndex, optionNumber, item, hasOpen });
  };

  const handleSelect = (optionClassNumber: string, isFromSelectBox: boolean) => {
    const hasOpen = isFromSelectBox ? false : isSpecial;
    if (props.onSelectOptionClass && isFromSelectBox) {
      props.onSelectOptionClass({ orderNumber, optionClassNumber, item, hasOpen, products });
    }
    props.onSelectOption({ partsIndex, optionNumber, item, hasOpen });
  };

  const renderItem = () => {
    return (
      <div className={styles.item}>
        <div className={styles.left}>
          <p className={styles.label}>{props.optionName}</p>
        </div>
        <div className={styles.right}>
          <DesignOptionListItemPaper paper={props.paperState} onSelect={handleSelect} />
        </div>
      </div>
    );
  };

  const renderSelector = () => {
    return (
      <div className={styles.selector}>
        <div className={styles.left}>
          <React.Fragment />
        </div>
        <div className={styles.right}>
          <DesignOptionSpecialSelectBox
            selectBoxState={props.selectBoxState}
            onClose={handleClose}
            onSelect={handleSelect}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderItem()}
      {renderSelector()}
    </div>
  );
};

export default DesignOptionListItem;
