import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { get, errorDialogActions } from '../../../../store/utils/dialog/error';
import ErrorDialog from '../../../../components/organisms/ErrorDialog/ErrorDialog';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: get.hasError(state),
    errors: get.errors(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClose: errorDialogActions.clearErrors.dispatcher(dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
type TProps = TStateProps & TDispatchProps;

const ErrorDialogContainer: React.FC<TProps> = (props: TProps) => {
  return <ErrorDialog {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialogContainer);
