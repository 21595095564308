import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';
import { getOrderDetail } from '../orders/detail';
import { toOrder } from '../../helpers/api/converter/order-detail';
import { toDiversionOrder, TDiversionOrder } from '../../helpers/api/orders/conv-state-reverse';
import { ApiError } from '../../models/error/api-error';
import { ILoadOrderDetail } from '../../types/orders/digest';

export const getPartsSizeHistory = async (params: ILoadOrderDetail, partsNumber: TPartsNumber) => {
  const res = await getOrderDetail(params.requestSerial)
    .then(toOrder)
    .then(data => toDiversionOrder(data, params.requestSerial))
    .catch(err => err);

  if (res instanceof ApiError) {
    return res;
  }

  const { parts } = (res as TDiversionOrder).order.item.size;
  if (!parts[partsNumber]) {
    return new ApiError([{ code: 'unknown', message: '' }], 400);
  }
  return parts[partsNumber].history;
};
