import { rawStrArr2EnumLikeObj } from '../../lib/utils';

/**
 * 注文のステータス
 */
export const OrderStatus = rawStrArr2EnumLikeObj(['beforeEvans', 'afterEvans', 'afterShipping']);
export type TOrderStatus = keyof typeof OrderStatus;

// /**
//  * 表の注文内容（いらないと思ったから消した）
//  */
// const OrderItem = rawStrArr2EnumLikeObj([
//   'membersNumber',
//   'productKind',
//   'payment',
//   'address',
//   'delivery',
//   'cloth',
//   'design',
//   'size',
//   'notes',
// ]);
// type TOrderItem = keyof typeof OrderItem;

/**
 * 表の項目
 */
export const PaymentItem = rawStrArr2EnumLikeObj([
  'usePoint',
  'hasIntroducer',
  'introducerCode',
  'hasFavoriteCustomer',
  'visitCode',
  'needInvoice',
]);
export const AddressItem = rawStrArr2EnumLikeObj([
  'postalCode',
  'state',
  'city',
  'street',
  'customerName',
  'phoneNumber',
  'mailAddress',
]);
export const DeliveryItem = rawStrArr2EnumLikeObj(['deliveryMethod', 'deliveryDate', 'deliveryTime']);
export const ClothItem = rawStrArr2EnumLikeObj([
  'code',
  'textileNo',
  'brand',
  'color',
  'scale',
  'clothDesign',
  'compositionFront',
  'compositionBack',
  'stockPlace',
]);
export const DesignItem = rawStrArr2EnumLikeObj(['design']);
export type TItemType =
  | keyof typeof PaymentItem
  | keyof typeof AddressItem
  | keyof typeof DeliveryItem
  | keyof typeof DesignItem
  | keyof typeof ClothItem;

/** 1つ目が連携前、2つ目が連携後、3つ目が出荷後の修正可否を表す */
export type TStatusConfig = [boolean, boolean, boolean];
export enum EStatus {
  beforeEvans,
  afterEvans,
  afterShipping,
}

/** 1つ目が管理者以外、2つ目が管理者 */
export type TEditableConfig = [TStatusConfig, TStatusConfig];
export enum EStaff {
  staff,
  manager,
}

// prettier-ignore
/**
 * いつでも誰でも編集できる項目
 */
// const EDITABLE_ITEM: TEditableConfig = [[true, true, true], [true, true, true]];
/**
 * いつでも誰でも編集できない項目
 */
// prettier-ignore
export const NOT_EDITABLE_ITEM: TEditableConfig = [[false, false, false], [false, false, false]];
// prettier-ignore
/**
 * 管理者以外は連携前なら修正できる。管理者はいつでも修正できる。
 */
const MANAGER_EVERY_TIME_EDITABLE_ITEM: TEditableConfig = [[true, false, false], [true, true, true]];
// prettier-ignore
/**
 * 管理者以外は連携前なら修正できる、管理者は出荷前なら修正できる。
 */
const MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM: TEditableConfig = [[true, false, false], [true, true, false]];
// prettier-ignore
/**
 * 連携前なら修正できる。
 */
const BEFORE_EVANS_EDITABLE_ITEM: TEditableConfig = [[true, false, false], [true, false, false]];

/** 表の編集可否を表す */
const _EDIT_STATUS_CONFIG_ARRAY: Array<[TItemType, TEditableConfig]> = [
  [PaymentItem.hasIntroducer, MANAGER_EVERY_TIME_EDITABLE_ITEM],
  [PaymentItem.introducerCode, MANAGER_EVERY_TIME_EDITABLE_ITEM],
  [PaymentItem.visitCode, MANAGER_EVERY_TIME_EDITABLE_ITEM],
  [PaymentItem.hasFavoriteCustomer, MANAGER_EVERY_TIME_EDITABLE_ITEM],
  [PaymentItem.needInvoice, MANAGER_EVERY_TIME_EDITABLE_ITEM],

  [AddressItem.postalCode, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [AddressItem.state, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [AddressItem.city, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [AddressItem.street, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [AddressItem.customerName, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [AddressItem.phoneNumber, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [AddressItem.mailAddress, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],

  [DeliveryItem.deliveryMethod, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [DeliveryItem.deliveryDate, BEFORE_EVANS_EDITABLE_ITEM],
  [DeliveryItem.deliveryTime, BEFORE_EVANS_EDITABLE_ITEM],

  [ClothItem.code, NOT_EDITABLE_ITEM],
  [ClothItem.textileNo, NOT_EDITABLE_ITEM],
  [ClothItem.brand, NOT_EDITABLE_ITEM],
  [ClothItem.color, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
  [ClothItem.scale, BEFORE_EVANS_EDITABLE_ITEM],
  [ClothItem.clothDesign, BEFORE_EVANS_EDITABLE_ITEM],
  [ClothItem.compositionFront, BEFORE_EVANS_EDITABLE_ITEM],
  [ClothItem.compositionBack, BEFORE_EVANS_EDITABLE_ITEM],
  [ClothItem.stockPlace, BEFORE_EVANS_EDITABLE_ITEM],

  [DesignItem.design, MANAGER_BEFORE_SHIPPING_EDITABLE_ITEM],
];
export const EDIT_STATUS_CONFIG = _EDIT_STATUS_CONFIG_ARRAY.map(v => ({ item: v[0], config: v[1] }));
