import { TOrderItemCode, TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { IndexedObject } from '../../../types';
import { addFloatNumber } from '../../../helpers/common/math';
import { EParts } from '../../../lookups/master-thisisforreplaceall/parts';
import {
  TPartialOrder,
  TPartialItem,
  TPartialCloth,
  TPartialDesign,
  IPartsDesign,
  IPiece,
  TPartialSize,
  IPartsSize,
  INudeSize,
  TPartialShipping,
  IDesignSelectingParam,
  IHistoryMeasurement,
} from '../../_type/order';
import path from 'ramda/es/path';
import { MESUREMENT_PANTS_LENGTH_CONFIG } from '../../../lookups/item-thisisforreplaceall/measurements';
import { cloneDeep } from 'lodash';

const wrapItem = (item: TPartialItem): TPartialOrder => ({ item });
const wrapCloth = (cloth: TPartialCloth): TPartialOrder => ({ item: { cloth } });
const wrapDesign = (design: TPartialDesign): TPartialOrder => ({ item: { design } });
const wrapSize = (size: TPartialSize): TPartialOrder => ({ item: { size } });
const wrapShipping = (shipping: TPartialShipping): TPartialOrder => ({ shipping });

/**
 * TPartialOrderを生成する関数群
 */
export const getPartialOrder = {
  // clothCode 変更時
  fromClothCode: (clothCode: string): TPartialOrder => wrapCloth({ clothCode }),

  fromCloth: (cloth: TPartialCloth): TPartialOrder => wrapCloth(cloth),

  fromShipping: (shipping: TPartialShipping): TPartialOrder => wrapShipping(shipping),

  // designParts 変更時
  fromDesignParts: (designParts: IndexedObject<IPartsDesign>, selecting?: IDesignSelectingParam): TPartialOrder =>
    wrapDesign({ designParts, selecting }),

  // designの option/option_class 選択時
  fromSelectDesignOptionAction: (
    partsIndex: string,
    optionNumber: string,
    optionClassNumber: string,
    hasOpenSelector: boolean,
    targetDesignParts?: IPartsDesign,
  ): TPartialOrder => {
    const obj = {
      designParts: targetDesignParts ? { [partsIndex]: targetDesignParts } : undefined,
      selecting: {
        partsIndex,
        optionNumber,
        optionClassNumber,
        hasOpenSelector,
      },
    };
    if (!targetDesignParts) {
      delete obj.designParts;
    }
    return wrapDesign(obj);
  },
  // オプション選択画面で、デフォルト(おすすめ)を設定した時。
  fromUpdateOptionClassesAction: (partsIndex: string, targetDesignParts: IPartsDesign): TPartialOrder => {
    const obj = {
      designParts: targetDesignParts ? { [partsIndex]: targetDesignParts } : undefined,
    };
    return wrapDesign(obj);
  },
  fromInputFreeOptionAction: (partsIndex: string, targetDesignParts?: IPartsDesign): TPartialOrder => {
    const obj = {
      designParts: targetDesignParts ? { [partsIndex]: targetDesignParts } : undefined,
    };
    return wrapDesign(obj);
  },
  /* TODO: 削除系は、単純にこれをupdateしても消えない気がする。。。 state内の designParts をまず削除してからupdateしなきゃだな。 */
  // designで、 partsの追加・削除 時
  fromChangePieceAction: (
    itemCode?: TOrderItemCode,
    pieces?: IPiece[],
    designParts?: IndexedObject<IPartsDesign>,
    isDouble?: boolean,
    cloth?: TPartialCloth,
  ): TPartialOrder => wrapItem({ itemCode, pieces, design: { designParts, isDouble }, cloth }),

  // size
  fromSizeInitialize: (
    partsNumber?: TPartsNumber,
    parts?: IndexedObject<IPartsSize>,
    nude?: INudeSize,
  ): TPartialOrder => {
    const obj: TPartialSize = {
      nude,
      parts,
      selecting: {
        partsNumber,
      },
    };
    return wrapSize(obj);
  },
  fromSizeChangeNude: (nude: INudeSize): TPartialOrder => {
    const obj: TPartialSize = {
      nude,
    };
    return wrapSize(obj);
  },
  fromPartsSize: (parts: IndexedObject<IPartsSize>): TPartialOrder => {
    if (parts[EParts.pants] && parts[EParts.pants].measurements) {
      const measurements = parts[EParts.pants].measurements;
      const getgetMeasurementValue = (optionNumber: string) =>
        Number(
          path(
            ['optionClassName'],
            measurements.find(v => v.optionNumber === optionNumber),
          ) || 0,
        );
      const ue = getgetMeasurementValue(MESUREMENT_PANTS_LENGTH_CONFIG.rise);
      const shita = Math.max(...MESUREMENT_PANTS_LENGTH_CONFIG.inseams.map(getgetMeasurementValue));
      if (ue && shita) {
        parts[EParts.pants].measurements = [
          ...measurements.filter(v => v.optionNumber !== MESUREMENT_PANTS_LENGTH_CONFIG.pantsLength),
          {
            optionNumber: MESUREMENT_PANTS_LENGTH_CONFIG.pantsLength,
            optionClassNumber: '',
            optionClassName: `${addFloatNumber(ue, shita)}`,
          },
        ];
      }
    }
    const obj: TPartialSize = {
      parts,
    };
    return wrapSize(obj);
  },
  fromPartsHistory: (history: IHistoryMeasurement, partsNumber: TPartsNumber): TPartialOrder => {
    const obj = {
      parts: {
        [partsNumber]: {
          history: cloneDeep(history),
        },
      },
    };
    return wrapSize(obj);
  },
  /** ゲージのバリデーションに引っかかったパーツの、選択されているゲージをStateから削除するために使用 */
  fromValidateGauge: (parts: IndexedObject<IPartsSize>, partsNumbers: TPartsNumber[]): TPartialOrder => {
    const updateParts = partsNumbers.reduce((accParts, curPartsNumber) => {
      const returnParts = {
        ...accParts,
        ...{ [curPartsNumber]: { ...accParts[curPartsNumber], ...{ gauge: { major: '', minor: '' } } } },
      };
      return returnParts;
    }, parts);

    const obj: TPartialSize = {
      parts: updateParts,
    };
    return wrapSize(obj);
  },
};
