import actionCreatorFactory from 'typescript-fsa';
import { combineEpics, Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { Action, AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';

import { AppState } from '../../..';
import { memoDialogActions, TShowMemoDialogParams } from '.';
import { WrapAction } from '../../../_type';

const actionCreaters = actionCreatorFactory('[-- MemoDialog Events]');
export const memoDialogEvents = {
  show: actionCreaters<TShowMemoDialogParams>('show'),
  changeMemo: actionCreaters<string>('changeMemo'),
  update: actionCreaters<void>('update'),
  close: actionCreaters<void>('close'),
};

const show: Epic<AnyAction, Action<TShowMemoDialogParams>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(memoDialogEvents.show),
    map(({ payload }) => memoDialogActions.show(payload)),
  );

const changeMemo: Epic<AnyAction, Action<string>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(memoDialogEvents.changeMemo),
    map(({ payload }) => memoDialogActions.changeMemo(payload)),
  );

const update: Epic<AnyAction, WrapAction<typeof memoDialogActions.update.started>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(memoDialogEvents.update),
    map(({ payload }) => memoDialogActions.update.started({})),
  );

const close: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(memoDialogEvents.close),
    map(({ payload }) => memoDialogActions.close()),
  );

export const listenerEpics = combineEpics(show, changeMemo, update, close);
