import { IApiError } from '../../types/api';

export class ApiError extends Error {
  private _statusCode: number = 0;
  private _errors: IApiError[] = [];

  constructor(errors: IApiError[] | Error, code: number = 0) {
    super();
    this._statusCode = code;
    if (Array.isArray(errors)) {
      this._errors = errors;
    } else {
      this._errors.push({ code: 'unknown', message: String(errors.stack) });
    }
  }

  get errors() {
    return this._errors;
  }

  get statusCode() {
    return this._statusCode;
  }
}
