export const clothPage = {
  deliveryDate: { jp: '納期' },
  /** 生地品番 */
  clothCode: { jp: '生地品番' },
  /** textileNo */
  textileNo: { jp: 'Textile No' },
  /** ブランド */
  brand: { jp: 'ブランド' },
  /** モデル */
  model: { jp: 'モデル' },
  /** 色 */
  clothColor: { jp: '色' },
  /** 柄 */
  clothDesign: { jp: '柄' },
  /** 要尺 */
  scale: { jp: '要尺' },
  /** 生地組成 表地(素材/%) */
  compositionFront: { jp: '生地組成 表地(素材/%)' },
  /** 生地組成 裏地(素材/%) */
  compositionBack: { jp: '生地組成 裏地(素材/%)' },
  /** 着分区分 */
  stockPlace: { jp: '着分区分' },
  /** イラストボタン */
  illustrationBtn: { jp: 'イラスト' },
};
