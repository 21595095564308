import { createSelector } from 'reselect';

import { orderState } from '../../order/selector/order';
import { AppState } from '../..';
import { ILookupFeature } from '../../lookups';
import { IPartsRecommendGauge } from '../../_type/lookups';

export { recommendedGaugeActions, recommendedGaugeReducer } from './action-reducer';

const featureSelector = (state: AppState): ILookupFeature => state.lookups;

const recommendGaugeState = createSelector(featureSelector, state => state.recommendGauge);

export const getRecommendedGauge = {
  all: createSelector(recommendGaugeState, state => state.recommendedGauges),
  current: {
    detail: createSelector(
      recommendGaugeState,
      orderState,
      (state, order) => state.recommendedGauges[order.currentOrderNumber],
    ),
    /** parts毎の推奨ゲージ一覧 */
    recommendGauges: createSelector(recommendGaugeState, orderState, (state, order) => {
      const current = state.recommendedGauges[order.currentOrderNumber];
      if (!current) {
        return [];
      }
      return current.map(v => {
        const { partsNumber, recommendedGauge: gauge } = v;
        const ret: IPartsRecommendGauge = {
          partsNumber,
          gauge,
        };
        return ret;
      });
    }),
    /** 選択中のパーツで選択できるベースゲージのリスト */
    selectablePartsGauges: createSelector(recommendGaugeState, orderState, (state, order) => {
      const { currentOrderNumber } = order;
      const { partsNumber } = order.orders[currentOrderNumber].item.size.selecting;
      if (!state.recommendedGauges[currentOrderNumber]) {
        return [];
      }
      const gauges = state.recommendedGauges[currentOrderNumber].find(v => v.partsNumber === partsNumber);
      return gauges ? gauges.allGauges : [];
    }),
  },
};
