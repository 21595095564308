import { TLookup, ILookupItem } from '../../types/lookup';
import { NO_SELECT_OPTION, INITIAL_PARTS_COUNT, ORDER_CATALOG_ALL } from '../../lookups/item-thisisforreplaceall/';
import { TPartsNumber, TCategory, TSubCategory, TPartsCount } from '../../lookups/master-thisisforreplaceall/';
import { IOptionClass } from '../../store/_type/lookups';
import { getStaff } from '../../store/staff';

// TODO: 使ってるけどこいつは移行する
export function toSpecialOptionLookups(optionTypes: IOptionClass[]): TLookup {
  return [NO_SELECT_OPTION, ...optionTypes].map(type => {
    const item: ILookupItem = {
      id: type.optionClassNumber,
      value: type.optionClassName,
    };
    return item;
  });
}

// new-storeでも使ってる
export function getAddableParts(
  category: TCategory,
  subCategory: TSubCategory,
  partsNumbers: TPartsNumber[],
  brandCode: string,
): TPartsNumber[] {
  return getPartsNumber(category, subCategory, partsNumbers, brandCode);
}

// new-storeでも使ってる
export function getDeletableParts(
  category: TCategory,
  subCategory: TSubCategory,
  partsNumbers: TPartsNumber[],
  brandCode: string,
): TPartsNumber[] {
  return getPartsNumber(category, subCategory, partsNumbers, brandCode, true);
}

const PARTS_NUNBERS = Object.keys(INITIAL_PARTS_COUNT) as TPartsNumber[];

// new-storeでも使ってる
function getPartsNumber(
  category: TCategory,
  subCategory: TSubCategory,
  partsNumbers: TPartsNumber[],
  brandCode: string,
  isDeletable = false,
): TPartsNumber[] {
  const partsCount = calcPartsCount(category, subCategory, partsNumbers, brandCode, isDeletable);
  return toPartsNumbers(partsCount);
}

function calcPartsCount(
  category: TCategory,
  subCategory: TSubCategory,
  partsNumbers: TPartsNumber[],
  brandCode: string,
  isDeletable: boolean,
): TPartsCount {
  const count = toTPartsCount(partsNumbers);
  const masters = getMasterPartsCounts(category, subCategory, brandCode);
  const calcedMasters = masters
    .map(master =>
      // del:現在のパーツ数-マスタのパーツ数、add:マスタのパーツ数-現在のパーツ数
      PARTS_NUNBERS.reduce(
        (pre, cur) => ({ ...pre, [cur]: isDeletable ? count[cur] - master[cur] : master[cur] - count[cur] }),
        { ...INITIAL_PARTS_COUNT },
      ),
    )
    // パーツ数がマイナスになっているのは除く
    .filter(v => PARTS_NUNBERS.every(parts => v[parts] > -1));

  // マスタにある最大のパーツ数を取得し、返却する
  return PARTS_NUNBERS.reduce((pre, cur) => ({ ...pre, [cur]: Math.max(...calcedMasters.map(v => v[cur])) }), {
    ...INITIAL_PARTS_COUNT,
  });
}

function getMasterPartsCounts(category: TCategory, subCategory: TSubCategory, brandCode: string): TPartsCount[] {
  return ORDER_CATALOG_ALL.filter(v => {
    return v.category === category && v.subCategory === subCategory && v.availableBrands.includes(brandCode as string);
  }).map(v => toTPartsCount(v.pieces));
}

function toTPartsCount(partsNumbers: TPartsNumber[]): TPartsCount {
  return PARTS_NUNBERS.reduce((pre, cur) => ({ ...pre, [cur]: partsNumbers.filter(v => v === cur).length }), {
    ...INITIAL_PARTS_COUNT,
  });
}

function toPartsNumbers(partsCount: TPartsCount): TPartsNumber[] {
  return PARTS_NUNBERS.map(parts => Array.from({ length: partsCount[parts] }, () => parts as TPartsNumber)).reduce(
    (pre, cur) => [...pre, ...cur],
    [],
  );
}
