import { createSelector } from 'reselect';
import { AppState } from '../../..';
import { IError } from '../../../../types/error';

export { reducer as errorDialogReducer, actions as errorDialogActions } from './action-reducer';

const featureSelector = (state: AppState): IError[] => state.utils.dialog.error;

export const get = {
  errors: createSelector(featureSelector, state => state),
  hasError: createSelector(featureSelector, state => state.length > 0),
};
