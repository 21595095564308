import { TRequestConfig, TRequestParams } from '../';
// import config from '../../../configuration/config';
export { ProgressRes } from './progress.class';

export const regProgressRequestConfig: TRequestConfig = {
  endpoint: '/progress',
  method: 'POST',
  useMock: false,
  mockFunc: () => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      body: params,
    };
  },
};

export const loadProgressRequestConfig: TRequestConfig = {
  endpoint: '/progress/progresses',
  method: 'GET',
  useMock: false,
  mockFunc: () => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      query: params,
    };
  },
};
