import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../../lib/action-creator-factory';
import { IOKCancelDialog } from '../../../../types/dialog';

// action
const ac = actionObjectCreatorFactory();

const actionTitle = 'OkCancel Dialog';

export const actions = {
  show: ac<IOKCancelDialog>(`[${actionTitle}] show`),
  close: ac(`[${actionTitle}] close`),
};

// reducer
const initialState: IOKCancelDialog = {
  hasOpen: false,
  title: null,
  contents: '',
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.show._action, (state, payload) => ({ ...payload }))
  .case(actions.close._action, state => ({ ...initialState }));
