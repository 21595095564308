import { getProductAndOptionTotal } from './commonly-getter';
import { TPaymentState } from '../../payment/action-reducer';
import { IClothProduct, IAvailableOption } from '../../_type/lookups';
import { IOrder, TProductKind } from '../../_type/order';
import { IndexedObject } from '../../../types';

export const calcTotalOrders = (
  orders: IndexedObject<IOrder>,
  products: IndexedObject<IClothProduct[]>,
  availableOptions: IndexedObject<IAvailableOption[]>,
  productKind: TProductKind,
) => {
  const total = getProductAndOptionTotal(orders, products, availableOptions, productKind);
  return total.reduce((p, c) => p + (c.optionPrice + c.productPrice), 0);
};

/** 決済手数料（とりあえずここに置いておく） */
export const getSettlementCost = (state: TPaymentState): number => 0;
