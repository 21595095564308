import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, map, filter } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';

import { ApiError } from '../../models/error/api-error';
import { AppState } from '..';
import { orderDetailActions, IOrderDetailState } from './action-reducers';
import { actions as ErrorHandlerActions } from '../../store/errorHandling/action';
import * as CanCelOrderApi from '../../services/orders/cancel';
import { getOrderDetail, updateOrderDetail } from '../../services/orders/detail';
import { toOrder } from '../../helpers/api/converter/order-detail';
import Logger from '../../helpers/common/logger';
import { IGetAdjustOptionParams, adjustOptionAsyncActions } from '../lookups/adjust-option/action-reducer';
import { toLoadOrderDetail, TOrderDetail } from '../../helpers/api/orders/conv-state-reverse';
import { clothProductAsyncActions, IGetClothProductsParams } from '../lookups/product/action-reducer';
import { sizeMeasurementAsyncActions, IGetSizeMeasurementParams } from '../lookups/size-measurement/action-reducer';
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { orderActions, IOrderState, orderDeleteActions } from '../order';
import { replace, CallHistoryMethodAction } from 'connected-react-router';
import { resolvePath, parseSearchParams } from '../../helpers/common/path';
import { IndexedObject, ERouterPath } from '../../types';
import { IRecommandedGaugeParams, recommendedGaugeAsyncActions } from '../lookups/recommend-gauge/action-reducer';
import { actions as SizeActions } from '../order/size/actions';
import { piecesSelector } from '../order/object-selector';
import { TLoadStandardSize } from '../pages/size-correction/type';
import { cloneDeep } from 'lodash';
import { getInitialOrderNumber } from '../../helpers/orders';
import { IOrder } from '../_type/order';
import { IClothProduct, IAvailableOption, ISizeMeasurement, IPartsAdjustOption } from '../_type/lookups';
import { ICustomer } from '../_type/customer';
import { IStaff } from '../_type/staff';
import { IPayment } from '../_type/payment';
import { getEditOrderRequest } from '../../helpers/api/orders/conv-state2orderRequest';
import { IInformationDialog } from '../../types/dialog';
import { infoDialogActions } from '../utils/dialog/info';
import { paymentActions } from '../payment';
import { TUpdatePaymentParam } from '../payment/action-reducer';
import config from '../../configuration/config';
import { flattenJson, diffModifyOrderObject } from '../../helpers';
import { TScProject } from '../../types/project';
import { scProjectActions } from '../sc-project';

const editOrder: Epic<
  AnyAction,
  | Action<void>
  | Action<TUpdatePaymentParam>
  | Action<{ error: ApiError; options: any }>
  | Action<IOrderState>
  | Action<TScProject>
  | CallHistoryMethodAction,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.editOrder.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderDetail = appStateObj.orderDetail();
      return { payload, orderDetail };
    }),
    filter(
      ({ orderDetail }) =>
        orderDetail !== undefined &&
        Object.keys(orderDetail.orders).length > 0 &&
        orderDetail.payment !== null &&
        orderDetail.staff !== null,
    ),
    mergeMap(obj => {
      const orderDetail = obj.orderDetail as IOrderDetailState;
      const payment = orderDetail.payment as IPayment;
      const scProject = orderDetail.scProject as TScProject;
      const { orders, staff } = orderDetail;
      Logger.log('orderDetail', { orders, payment, staff, scProject });
      return [
        orderActions.overWriteEditOrder._action({
          orders: cloneDeep(orders),
          currentOrderNumber: getInitialOrderNumber(orders),
          productKind: 'E',
          isEdit: true,
        }),
        paymentActions.update._action({ payment }),
        scProjectActions.set(scProject),
        replace(resolvePath(obj.payload.path)),
      ];
    }),
  );

const cancelOrder: Epic<
  AnyAction,
  | Action<{ error: ApiError; options: any }>
  | Action<Parameters<typeof orderDetailActions.cancelOrder.done>[0]>
  | Action<Parameters<typeof orderDetailActions.pendingOFF>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.cancelOrder.started),
    mergeMap(async ({ payload }) => {
      const { orderNumber } = payload;
      const res = await CanCelOrderApi.cancelOrder(orderNumber).catch(err => err);
      return { res, orderNumber };
    }),
    filter(({ res }) => res instanceof ApiError),
    mergeMap(({ res, orderNumber }) => {
      // statusCodeが204の場合は成功処理
      if (res.statusCode !== 204) {
        return [
          ErrorHandlerActions.apiError({
            error: res as ApiError,
            options: { contents: '注文のキャンセルに失敗しました。' },
          }),
          orderDetailActions.pendingOFF(),
        ];
      }
      return [orderDetailActions.cancelOrder.done({ params: { orderNumber }, result: {} })];
    }),
  );

const loadOrder: Epic<
  AnyAction,
  | Action<{ error: ApiError; options: any }>
  | Action<void>
  | Action<Parameters<typeof orderDetailActions.loadOrderDetail.failed>[0]>
  | Action<Parameters<typeof orderDetailActions.loadOrderDetail.done>[0]>
  | Action<TLoadStandardSize>
  | Action<{ orderDetail: TOrderDetail }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.loadOrderDetail.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const isEdit = appStateObj.isEditOrder();
      return { payload, isEdit };
    }),
    filter(({ isEdit }) => !isEdit),
    mergeMap(async action => {
      const { orderNumber } = action.payload;
      const res = await getOrderDetail(orderNumber)
        .then(toOrder)
        .catch(err => err);
      return { res, orderNumber };
    }),
    mergeMap(({ res, orderNumber }) => {
      if (res instanceof ApiError) {
        Logger.log('orderDetailActions fail', res);
        return [
          ErrorHandlerActions.apiError({ error: res, options: { contents: '注文情報の詳細取得に失敗しました.' } }),
          orderDetailActions.loadOrderDetail.failed({ params: { orderNumber }, error: {} }),
        ];
      }
      const orderDetail = toLoadOrderDetail(res);
      const standardSizeActions = Object.entries(orderDetail.orders)
        .map(([orderIndex, order]) =>
          piecesSelector(order.item.pieces)
            .distinctPartsNumbers()
            .map(partsNumber =>
              SizeActions.loadStandardSize.started({
                orderNumber: orderIndex,
                item: order.item,
                partsNumber,
                isAutoUpdate: false,
              }),
            ),
        )
        .reduce((p, c) => [...p, ...c]);
      return [
        orderDetailActions.loadOrderDetail.done({ params: { orderNumber }, result: { orderDetail } }),
        orderDetailActions.loadLookups({ orderDetail }),
        ...standardSizeActions,
      ];
    }),
  );

const loadEditedOrder: Epic<
  AnyAction,
  Action<void> | Action<{ orders: IndexedObject<IOrder>; payment: IPayment; scProject: TScProject }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.loadOrderDetail.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const isEdit = appStateObj.isEditOrder();
      const orders = appStateObj.orders();
      const payment = appStateObj.paymentState();
      const scProject = appStateObj.selectedScProject();
      return { payload, isEdit, orders, payment, scProject };
    }),
    filter(({ isEdit, orders }) => isEdit && orders !== undefined),
    mergeMap(obj => {
      const orders = obj.orders as IndexedObject<IOrder>;
      const payment = obj.payment as IPayment;
      const scProject = obj.scProject as TScProject;
      return [orderDetailActions.loadEditedOrder({ orders, payment, scProject })];
    }),
  );

const loadlookups: Epic<
  AnyAction,
  | Action<IGetClothProductsParams>
  | Action<IGetAdjustOptionParams & { orderDetail: TOrderDetail }>
  | Action<IGetSizeMeasurementParams>
  | Action<IRecommandedGaugeParams>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.loadLookups),
    map(({ payload }) => {
      const { orderDetail } = payload;
      const { orders, payment, staff, customer } = payload.orderDetail;
      return { orders, payment, staff, customer, orderDetail };
    }),
    filter(v => v.orders !== undefined && Object.keys(v.orders).length > 0),
    mergeMap(({ orders, orderDetail }) => {
      const actions = Object.entries(orders)
        .map(([orderNumber, order]) => {
          const { item } = order;
          return [
            clothProductAsyncActions.loadDetailData.started({ orderNumber, orderDetail }),
            // MEMO: availableOptionはclothProduct完了後に呼び出しているので、ここでは不要
            sizeMeasurementAsyncActions.loadData.started({ orderNumber, item }),
            adjustOptionAsyncActions.loadDetailData.started({ orderNumber, item, orderDetail }),
            recommendedGaugeAsyncActions.loadData.started({ orderNumber, item }),
          ];
        })
        .reduce((p, c) => [...p, ...c]);

      return actions;
    }),
  );

const sendModify: Epic<
  AnyAction,
  | Action<void>
  | Action<{ error: ApiError; options: any }>
  | Action<IInformationDialog>
  | CallHistoryMethodAction
  | Action<boolean>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.sendModifyOrder.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderDetail = appStateObj.orderDetail();
      const isEdit = appStateObj.isEditOrder();
      const allProducts = appStateObj.allProducts();
      const allAvailableOptions = appStateObj.allAvailableOptions();
      const allSizeMeasurements = appStateObj.allSizeMeasurements();
      const allAdjustOptions = appStateObj.allAdjustOptions();
      return {
        payload,
        orderDetail,
        isEdit,
        allProducts,
        allAvailableOptions,
        allSizeMeasurements,
        allAdjustOptions,
      };
    }),
    filter(
      ({ orderDetail, isEdit, allProducts, allAvailableOptions, allSizeMeasurements, allAdjustOptions }) =>
        isEdit &&
        orderDetail !== undefined &&
        allProducts !== undefined &&
        allAvailableOptions !== undefined &&
        allSizeMeasurements !== undefined &&
        allAdjustOptions !== undefined,
    ),
    map(obj => {
      const orderDetail = obj.orderDetail as IOrderDetailState;
      const allProducts = obj.allProducts as IndexedObject<IClothProduct[]>;
      const allAvailableOptions = obj.allAvailableOptions as IndexedObject<IAvailableOption[]>;
      const allSizeMeasurements = obj.allSizeMeasurements as IndexedObject<ISizeMeasurement[]>;
      const allAdjustOptions = obj.allAdjustOptions as IndexedObject<IPartsAdjustOption[]>;
      return { orderDetail, allProducts, allAvailableOptions, allSizeMeasurements, allAdjustOptions };
    }),
    filter(
      ({ orderDetail }) =>
        orderDetail.customer !== null &&
        orderDetail.payment !== null &&
        Object.keys(orderDetail.orders).length > 0 &&
        orderDetail.serialNumber !== '' &&
        orderDetail.staff !== null &&
        orderDetail?.scProject !== undefined,
    ),
    map(obj => {
      const { orders, serialNumber, productKind } = obj.orderDetail;
      const customer = obj.orderDetail.customer as ICustomer;
      const staff = obj.orderDetail.staff as IStaff;
      const scProject = obj.orderDetail.scProject as TScProject;
      const payment = obj.orderDetail.payment as IPayment;
      const prev = obj.orderDetail.backup.orders as IndexedObject<IOrder>;
      const orderNumber = getInitialOrderNumber(orders); // 修正は1件しかできないため
      return { obj, customer, staff, payment, orders, prev, serialNumber, productKind, orderNumber, scProject };
    }),
    mergeMap(async ({ obj, customer, staff, payment, orders, serialNumber, productKind, scProject }) => {
      const orderDetailRes: any = await getOrderDetail(serialNumber).catch(err => err);
      if (orderDetailRes instanceof ApiError) {
        return { res: orderDetailRes, serialNumber };
      }
      const orderDetail = orderDetailRes.res.json;
      const orderState = {
        orders,
        currentOrderNumber: getInitialOrderNumber(orders),
        productKind,
        isEdit: true,
      };
      const { allProducts, allAvailableOptions, allSizeMeasurements, allAdjustOptions } = obj;
      const request = getEditOrderRequest(
        orderDetail,
        customer.memberscardNumber,
        staff,
        orderState,
        payment,
        scProject,
        allProducts,
        allAvailableOptions,
        allSizeMeasurements,
        allAdjustOptions,
      );

      const res = await updateOrderDetail({ serial: serialNumber }, request).catch(err => err);
      return { res, serialNumber, detailRes: orderDetail, request };
    }),
    mergeMap(({ res, serialNumber, detailRes, request }) => {
      if (res instanceof ApiError) {
        const apiRes = flattenJson(detailRes);
        const clientReq = flattenJson(request);
        const extraData = { apiRes, clientReq };
        const { code, message } = res.errors[0];
        const warnMessage = `[${code}] 注文修正エラー メッセージ：${message}`;

        if (!config.isLocal) {
          Sentry.withScope(scope => {
            scope.setExtras(extraData);
            Sentry.captureMessage(warnMessage, Sentry.Severity.Error);
          });
        }

        const diff = diffModifyOrderObject(apiRes, clientReq);
        Logger.error(warnMessage, { ...extraData, diff });

        return [
          ErrorHandlerActions.apiError({ error: res, options: { contents: '注文情報の更新に失敗しました.' } }),
          orderDetailActions.pendingOFF(),
        ];
      }

      const dialog = {
        hasOpen: true,
        title: '情報',
        contents: '注文修正に成功しました.',
      };

      const path = resolvePath(ERouterPath.ordersSearch);

      return [
        orderActions.setIsEdit._action(false),
        orderDeleteActions.deleteEditOrders(),
        replace(path),
        infoDialogActions.show._action(dialog),
      ];
    }),
  );

export const OrderDetailEpics = combineEpics(
  editOrder,
  cancelOrder,
  loadOrder,
  loadlookups,
  loadEditedOrder,
  sendModify,
);
