import { IItem, IOption } from '../../../_type/order';
import { MODEL_SELECTOR_OPTION_NUMBER } from '../../../_type/lookups';
import { isDesignOption } from '../../../../helpers/item-thisisforreplaceall';
import { TCategory } from '../../../../lookups/master-thisisforreplaceall';
import { NO_SELECT_OPTION_CLASS_NUMBER } from '../../../../lookups/item-thisisforreplaceall';

export function getEditPartsDesign(
  partsIndex: string,
  optionNumber: string,
  optionClassNumber: string,
  item: IItem,
  isSetDefault: boolean,
  optionClassName?: string,
) {
  const { designParts } = item.design;
  if (!designParts[partsIndex]) {
    // FIXME: throw error ?
    return { ...designParts };
  }

  const newOption: IOption = {
    optionNumber,
    optionClassNumber,
    optionClassName,
  };

  const isModel = optionNumber === MODEL_SELECTOR_OPTION_NUMBER;
  const targetParts = {
    ...designParts[partsIndex],
    modelCode: getModelCode(designParts[partsIndex].modelCode, [newOption], item.categoryCode, isModel),
  };

  if (isModel) {
    const modelData = {
      ...targetParts,
      modelPattern: optionClassNumber,
      optionPattern: optionClassNumber,
    };
    designParts[partsIndex] = modelData;
    return { ...designParts };
  }

  const oldOption = targetParts.options.find(v => v.optionNumber === optionNumber);

  // 選択をした場合に true （選択解除ではなく）
  const isSelect =
    optionClassNumber !== NO_SELECT_OPTION_CLASS_NUMBER && // 「未選択」以外を選択
    // 元が未選択状態だった or 選択しているのとは別の項目を選択 or 自由入力
    (!oldOption || oldOption.optionClassNumber !== optionClassNumber || optionClassName);

  // 新しいoptions
  const newOptions = targetParts.options.filter(v => v.optionNumber !== optionNumber);
  // デフォルトセット時も選択状態にする。
  if (isSelect || isSetDefault) {
    newOptions.push(newOption);
  }

  designParts[partsIndex] = {
    ...targetParts,
    options: newOptions,
  };

  return { ...designParts };
}

export function getModelCode(
  modelCode: string,
  options: IOption[],
  categoryCode: TCategory,
  isModel: boolean = false,
): string {
  if (isModel) {
    // model選択時
    return options[0].optionClassNumber;
  }

  const matched = options.find(v => isDesignOption(v.optionNumber));
  if (!matched) {
    return modelCode;
  }
  if (categoryCode === 'WM') {
    return modelCode;
  }

  return matched.optionClassNumber;
}
