import React from 'react';

// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell/TableCell';
// import TableBody from '@material-ui/core/TableBody';

import styles from './InventorySearchResults.module.scss';
import { IStocks, getStockSymbol } from '../../../types/inventory-search';
// import Label from '../../atoms/Label/Label';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import { langResource } from '../../../i18n/_new/resources/';

export interface IProps {
  inventorySearchResults: IStocks[];
  updateDate: string;
}

const InventorySearchResults: React.FC<IProps> = ({ inventorySearchResults, updateDate }) => {
  return (
    <div className={styles.inventorySearchResults}>
      <div className={styles.header}>
        <p className={styles.title}>
          <I18TextContainer textObj={langResource.search.result} />：<span>{inventorySearchResults.length}件</span>
        </p>
        <dl className={styles.update}>
          <dt>
            <I18TextContainer textObj={langResource.inventoryPage.updateDate} />
          </dt>
          <dd>{updateDate}</dd>
        </dl>
      </div>

      <div className={styles.content}>
        <div className={styles.thead}>
          <div className={styles.row}>
            <div className={styles.col1}>
              <I18TextContainer textObj={langResource.inventoryPage.brand} />
            </div>
            <div className={styles.col2}>
              <I18TextContainer textObj={langResource.inventoryPage.season} />
            </div>
            <div className={styles.col3}>
              <I18TextContainer textObj={langResource.inventoryPage.productNumber} />
            </div>
            <div className={styles.col4}>
              <I18TextContainer textObj={langResource.inventoryPage.color} />
            </div>
            <div className={styles.col5}>
              <I18TextContainer textObj={langResource.inventoryPage.stock} />
            </div>
          </div>
        </div>
        <div className={styles.tbody}>
          {inventorySearchResults.map((search, i) => (
            <div className={styles.row} key={i}>
              <div className={styles.col1}>{search.brandCode}</div>
              <div className={styles.col2}>{search.seasonCode}</div>
              <div className={styles.col3}>{search.productNumber}</div>
              <div className={styles.col4}>{search.colorCode}</div>
              <div className={styles.col5}>{getStockSymbol(search.stockFlag)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InventorySearchResults;
