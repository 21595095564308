/**
 * 小数点以下の桁数を取得
 * @param num
 * @returns 少数点以下の桁数
 */
export const getDecimalPointLength = (num: number): number => {
  const numbers = String(num).split('.');
  return numbers[1] ? numbers[1].length : 0;
};

/**
 * ２つの数字を足し算した結果を返却（小数点以下の誤差補正処理あり）
 * @param float1
 * @param float2
 * @returns ２つの数を足した結果
 */
export const addFloatNumber = (float1: number, float2: number): number => {
  const decipmalPointLength = Math.max(getDecimalPointLength(float1), getDecimalPointLength(float2));
  const pow = Math.pow(10, decipmalPointLength);
  const res = Math.round(float1 * pow + float2 * pow) / pow;
  return res;
};
