import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../atoms/Button/Button';

import cssStyles from './LinkButton.module.scss';
import { ERouterPath } from '../../../types/index';
import { resolvePath } from '../../../helpers/common/path';
import { TIcon } from '../../../types/icon';
import { II18nItem } from '../../../i18n/_new/types';

interface IProps {
  to: ERouterPath;
  textObj?: II18nItem;
  label?: string;
  styles?: string[];
  cssPrefix?: string;
  disabled?: boolean;
  icon?: TIcon;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LinkButton: React.FC<IProps> = ({ to, textObj, label, styles, cssPrefix, disabled, icon, onClick }) => {
  return (
    <Link className={getClassName(disabled)} to={resolvePath(to)}>
      {renderButton()}
    </Link>
  );

  function getClassName(isdisabled: boolean = false) {
    return isdisabled ? cssStyles.disabled : cssStyles.linkButton;
  }

  // FIXME: textobjに全て移行したら消すこと
  function renderButton() {
    if (textObj) {
      return (
        <Button
          textObj={textObj}
          styles={styles}
          blockClassName={cssPrefix}
          icon={icon}
          disabled={disabled}
          onClick={onClick}
        />
      );
    }

    return (
      <Button styles={styles} blockClassName={cssPrefix} icon={icon} disabled={disabled} onClick={onClick}>
        {String(label)}
      </Button>
    );
  }
};

export default LinkButton;
