import { TLeftAndRightConfig } from '../../lookups/item-thisisforreplaceall';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';
import { IConfirmationMeasurement, IMeasurementItem } from '../../types/new-store/components';
import { getLeftAndRightTogetherConfig } from '../size-correction';

/**
 * @param x: IConfirmationMeasurement か IMeasurementItemが渡って来る想定。
 * measurementNumber は IMeasurementItem にしか存在しないため、あれば、measuments は IMeasurementItem。
 */
const iMeasurement = (x: unknown): x is IMeasurementItem =>
  typeof x === 'object' && x !== null && x.hasOwnProperty('measurementNumber');

/**
 * @param x: IConfirmationMeasurement か IMeasurementItemが渡って来る想定。
 * measurementValue は IConfirmationMeasurement にしか存在しないため、あれば、measuments は IConfirmationMeasurement。
 */
const isConfirmation = (x: unknown): x is IConfirmationMeasurement =>
  typeof x === 'object' && x !== null && x.hasOwnProperty('measurementValue');

/**
 * 左右をまとめて表示しなければならない項目のうち、値が小さい方の項目を取り除く。
 * 大きい方は、共通の名前に上書きする。(例:「袖丈(左)」「袖丈(右)」 => 「袖丈」)
 */
export const getShowMeasurementItems = <T extends IConfirmationMeasurement | IMeasurementItem>(
  measurements: T[],
  brandCode: string,
  partsNumber: TPartsNumber,
): T[] => {
  const matchedConfig = getLeftAndRightTogetherConfig(brandCode, partsNumber);
  if (!matchedConfig) {
    return measurements;
  }
  const leftItem = measurements.find(v => {
    if (isConfirmation(v)) {
      return matchedConfig.leftName === v.measurementName;
    } else if (iMeasurement(v)) {
      return matchedConfig.leftCode === v.measurementNumber;
    } else {
      return false;
    }
  });

  const rightItem = measurements.find(v => {
    if (isConfirmation(v)) {
      return matchedConfig.rightName === v.measurementName;
    } else if (iMeasurement(v)) {
      return matchedConfig.rightCode === v.measurementNumber;
    } else {
      return false;
    }
  });

  const isLeftLarger =
    (isConfirmation(leftItem) &&
      isConfirmation(rightItem) &&
      Number(leftItem.measurementValue) > Number(rightItem.measurementValue)) ||
    (iMeasurement(leftItem) && iMeasurement(rightItem) && Number(leftItem.value) > Number(rightItem.value));

  return getConvertedMeasurements(measurements, matchedConfig, isLeftLarger);
};

const getConvertedMeasurements = <T extends IConfirmationMeasurement | IMeasurementItem>(
  measurements: T[],
  matchedConfig: TLeftAndRightConfig,
  isLeftLarger: boolean,
): T[] =>
  measurements
    .map(v => {
      if (
        (isConfirmation(v) &&
          matchedConfig.leftName !== v.measurementName &&
          matchedConfig.rightName !== v.measurementName) ||
        (iMeasurement(v) &&
          matchedConfig.leftCode !== v.measurementNumber &&
          matchedConfig.rightCode !== v.measurementNumber)
      ) {
        return v;
      }
      return (isConfirmation(v) &&
        ((matchedConfig.leftName === v.measurementName && isLeftLarger) ||
          (matchedConfig.rightName === v.measurementName && !isLeftLarger))) ||
        (iMeasurement(v) &&
          ((matchedConfig.leftCode === v.measurementNumber && isLeftLarger) ||
            (matchedConfig.rightCode === v.measurementNumber && !isLeftLarger)))
        ? { ...v, ...{ measurementName: matchedConfig.alternativeName } }
        : undefined;
    })
    .filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
