import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('- SettlementEnd');

export const SettlementEndPageEvents = {
  /**
   * stateをクリアしてホームに戻る
   */
  clearCurrentProgress: actionCreator<void>('clear current progress'),
};
