import parseISO from 'date-fns/parseISO';

import { IPiece } from '../../store/_type/order';
import { ILookupItem } from '../../types/lookup';
import { pick, toLookup } from '../../helpers/master-lookup';
import {
  MASTER_CLOTH_DESIGN,
  MASTER_CLOTH_COLOR,
  MASTER_COMPOSITION,
  MASTER_CLOTH_PARTITION,
} from '../master-thisisforreplaceall/';
import { ISeasonCodeLookup } from '../../types/season-code';
import { EParts } from '../master-thisisforreplaceall/parts';

export const OPS_BRANDS: string[] = ['01', '03', '04', '08'];
export const KR_BRANDS: string[] = ['KR', '00', '01', '03', '04', '08', '1G'];
export const RT_BRANDS: string[] = ['RT', 'OC'];
export const YC_BRANDS: string[] = ['YC'];
export const JACKET_SIZE_BRANDS: string[] = ['ST', 'OC', 'TD', 'PG', 'KR', '00', 'FO'];

/* 固定色コード用　KR・00のみ適用*/
export const KR_BRAND: string[] = ['KR', '00', '1G'];

export const CLOTH_PARTITION_DEFAULT = '4';
export const CLOTH_PARTITION_ORDERING = '5';

/** ブランドKRの場合、固定で入れる色コード */
export const KR_BRAND_COLOR_CODE = '005';

export const CLOTH_DESIGN_LOOKUPS = toLookup(MASTER_CLOTH_DESIGN);
export const ALL_COMPOSITION_LOOKUPS = toLookup(MASTER_COMPOSITION);
const SELECTABLE_COMPOSITIONS = [
  '001',
  '002',
  '003',
  '004',
  '005',
  '006',
  '007',
  '008',
  '010',
  '011',
  '012',
  '013',
  '023',
  '024',
  '025',
  '026',
  '027',
  '065',
  '089',
  '108',
  '888',
  '999',
];
export const SELECTABLE_COMPOSITION_LOOKUPS = toLookup(pick(SELECTABLE_COMPOSITIONS, MASTER_COMPOSITION));
// export const BACK_COMPOSITION_LOOKUPS = toLookup(pick(['005', '007'], MASTER_COMPOSITION));
export const CLOTH_PARTITION_LOOKUPS = toLookup(MASTER_CLOTH_PARTITION);
export const getClothColorLookups = (pieces: IPiece[]): ILookupItem[] =>
  toLookup(
    pieces.some(v => v.partsNumber === EParts.shirt)
      ? // シャツ
        pick(['001', '002', '012', '030', '072'], MASTER_CLOTH_COLOR)
      : // シャツ以外
        pick(['003', '004', '005', '030', '033', '070', '075'], MASTER_CLOTH_COLOR),
  );

export const SEASON_CODE_LOOKUPS: ISeasonCodeLookup[] = [
  { dateFrom: parseISO('2017-08-01'), dateTo: parseISO('2018-01-31 23:59'), seasonCode: 'HW' },
  { dateFrom: parseISO('2018-02-01'), dateTo: parseISO('2018-07-31 23:59'), seasonCode: 'YM' },
  { dateFrom: parseISO('2018-08-01'), dateTo: parseISO('2019-01-31 23:59'), seasonCode: 'YW' },
  { dateFrom: parseISO('2019-02-01'), dateTo: parseISO('2019-07-31 23:59'), seasonCode: 'KM' },
  { dateFrom: parseISO('2019-08-01'), dateTo: parseISO('2020-01-31 23:59'), seasonCode: 'KW' },
  { dateFrom: parseISO('2020-02-01'), dateTo: parseISO('2020-07-31 23:59'), seasonCode: 'BM' },
  { dateFrom: parseISO('2020-08-01'), dateTo: parseISO('2021-02-25 23:59'), seasonCode: 'BW' },
  { dateFrom: parseISO('2021-02-26'), dateTo: parseISO('2021-08-11 23:59'), seasonCode: 'IM' },
  { dateFrom: parseISO('2021-08-12'), dateTo: parseISO('2022-02-07 23:59'), seasonCode: 'IW' },
  { dateFrom: parseISO('2022-02-08'), dateTo: parseISO('2022-08-09 23:59'), seasonCode: 'NM' },
  { dateFrom: parseISO('2022-08-10'), dateTo: parseISO('2099-08-20 23:59'), seasonCode: 'QS' },
];
