import actionCreatorFactory from 'typescript-fsa';
import { IOnChangeClothValue, IOnChangeClothComposition } from '../../_type/order';

const actionCreator = actionCreatorFactory('[Order/item/cloth]');

export const actions = {
  update: actionCreator.async<IOnChangeClothValue, { previous: IOnChangeClothValue; current: IOnChangeClothValue }, {}>(
    'update',
  ),
  updateComposition: actionCreator.async<
    IOnChangeClothComposition,
    { previous: IOnChangeClothComposition; current: IOnChangeClothComposition },
    {}
  >('updateComposition'),

  /**
   * 自動で選択肢が決まるデータに関して判定し、stateを更新する
   * TODO: デザイン・オプション画面だと、これ系は、stateを更新せず、editedAvarableOpsionsが自動計算した結果を返すことで対応していた。統一感が無いな。。。
   */
  setAutoDetectClothValue: actionCreator<void | { orderNumber?: string }>('setAutoDetectClothValue'),
};
