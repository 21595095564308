import { AppState } from '../../../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CouponInfoDialogEvents } from '../../../../views/events/CouponInfoDialog';
import { CouponInfoDialog } from '../../../../components/organisms/CouponInfoDialog/CouponInfoDialog';
import { getCouponInfoDialogState } from '../../../../store/utils/dialog/coupon-info';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: getCouponInfoDialogState.hasOpen(state),
    results: getCouponInfoDialogState.results(state),
    couponId: getCouponInfoDialogState.results(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(CouponInfoDialogEvents, dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TCouponInfoDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(CouponInfoDialog);
