import { Nullable, IndexedObject } from '../types';
import { ILookupItem } from '../types/lookup';
import Logger from './common/logger';
import partial from 'ramda/es/partial';

export function isInputChecked(selectedOption: Nullable<string>, option: ILookupItem): boolean {
  if (!selectedOption) {
    return false;
  }

  return Number(selectedOption) === option.id;
}

export async function getLazyResponseData<T>(data: T, waitFor: number = 1): Promise<T> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, waitFor);
  });
}

export function getLookupsId(value: string, lookupItems: ILookupItem[]): string | number {
  const matched = lookupItems.find(v => v.value === value);
  return matched ? matched.id : '';
}

export function distinctArray<T>(array: T[]): T[] {
  return [...Array.from(new Set(array))];
}

export const by = <T = any>(key: keyof T) => (searchVal: any) => (targetObj: T) =>
  typeof targetObj === 'object' && targetObj[key] === searchVal;

/** IndexedObjectから条件を満たすkeyを残して返却する */
export const excludeIndexesObject = <T = any>(
  obj: IndexedObject<T>,
  func: (key: string) => boolean,
): IndexedObject<T> => {
  return Object.entries(obj).reduce(
    (pre, [key, value]) => (func(key) ? { ...pre, [key]: value } : { ...pre }),
    {} as IndexedObject<T>,
  );
};

const sortObjectKeys = (data: any): any =>
  Object.keys(data)
    .sort()
    .reduce((obj, key) => ({ ...obj, [key]: data[key] }), {} as any);

export const flattenJson = (data: any): any => {
  try {
    const result: any = {};
    const recurse = (cur: any, property: any) => {
      if (Object(cur) !== cur) {
        result[property] = cur;
      } else if (Array.isArray(cur)) {
        const l = cur.length;
        for (let i = 0; i < l; i++) {
          recurse(cur[i], property ? property + '.' + i : '' + i);
        }
        if (l === 0) {
          result[property] = [];
        }
      } else {
        let isEmpty = true;
        for (const p of Object.keys(cur)) {
          isEmpty = false;
          recurse(cur[p], property ? property + '.' + p : p);
        }
        if (isEmpty) {
          result[property] = {};
        }
      }
    };
    recurse(data, '');
    return sortObjectKeys(result);
  } catch (error) {
    Logger.log('flatten error', error);
    return data;
  }
};

export const diffModifyOrderObject = (data1: any, data2: any): any => {
  try {
    if (!data2) {
      return 'data2 undefined.';
    }
    return distinctArray([...Object.keys(data1), ...Object.keys(data2)])
      .sort()
      .reduce((obj, key) => {
        if (data2[key] === data1[key]) {
          return obj;
        }
        if (/.optionMark$/.test(key)) {
          return obj;
        }
        if (/.optionClassNumber$/.test(key)) {
          if (!data2[key] && !data1[key]) {
            return obj;
          }
        }
        return { ...obj, [key]: { data1: data1[key], data2: data2[key] } };
      }, {} as any);
  } catch (error) {
    return 'diffModifyOrderObject error occurred.';
  }
};

/**
 * 小数点桁数を丸める（degitはdefaultで1なので、小数点2桁目を四捨五入する）
 */
export const roundDegit = (value: number | string, degit: number = 2): number =>
  typeof value === 'string' ? +(+value).toFixed(degit) : +value.toFixed(degit);

/**
 * keyValuePairsのkeyにobjのプロパティがある場合、そのプロパティの値をkeyValuePairsの値で置き換える
 */
export const replaceMatchedKeyObjectProps = <T>(keyValuePairs: Array<[string, any]>, obj: T): T =>
  Object.entries(obj).reduce((pre, [key, value]) => {
    if (Array.isArray(value)) {
      return { ...pre, [key]: value.map(partial(replaceMatchedKeyObjectProps, [keyValuePairs])) };
    }
    if (value !== null && typeof value === 'object') {
      return { ...pre, [key]: replaceMatchedKeyObjectProps(keyValuePairs, value) };
    }
    const matchedPair = keyValuePairs.find(([k, _]) => k === key);
    if (matchedPair) {
      return { ...pre, [key]: matchedPair[1] };
    }
    return { ...pre, [key]: value };
  }, {} as T);

/**
 * objectからnumberを取得する関数を引数として渡すと第1引数をnumber、第2引数をobjectとする関数を返却する
 * その関数を実行すると第2引数に対してobjectからnumberを取得する関数を実行し、その戻り値と第1引数を加算した値を返却する
 */
export const addReducerCreator = <T>(func: (curr: T) => number) => (acc: number, curr: T): number => func(curr) + acc;
