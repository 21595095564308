import { createSelector } from 'reselect';
import { orderState } from './order';
import { getAvailableOption, getProduct, getSizeMeasurement, getAdjustOption } from '../../lookups';
import {
  availableOptionsSelector,
  editedAvailableOptionsSelector,
  adjustOptionsSelector,
  adjustOptionsViewSelector,
  clothProductsSelector,
} from '../../lookups/object-selector';
import { IItem, IShipping, IOrder, TProductKind } from '../../_type/order';
import {
  IOrderTotalPaymentItem,
  ITotalPaymentItem,
  IOrderConfirmation,
  TClothConfirmation,
  IDesignConfirmation,
  IPartsConfirmation,
  ISizeConfirmation,
  IConfirmationOption,
  IAddressConfirmation,
  ICustomerConfirmation,
  IPaymentConfirmation,
} from '../../../types/new-store/components';
import { getLanguage } from '../../utils/language';
import { IAvailableOption, ISizeMeasurement, IPartsAdjustOption, IClothProduct } from '../../_type/lookups';
import {
  TPartsNumber,
  MASTER_CLOTH_DESIGN,
  MASTER_CLOTH_COLOR,
  MASTER_CLOTH_PARTITION,
  MASTER_DELIVERY_METHOD,
  MASTER_DELIVERY_TIME_ZONE,
} from '../../../lookups/master-thisisforreplaceall';
import { joinGauge } from '../../../helpers/size-correction';
import { getNewOrderNumber } from '../helper/_old';
import { getValue } from '../../../helpers/master-lookup';
import { MASTER_PARTS } from '../../../lookups/master-thisisforreplaceall';
import { getProductAndOptionTotal, getItemBaseInfo } from '../helper/commonly-getter';
import { toOrderTitle, toDeliveryWithLaterDays, toSameAddressOrderOne } from '../../../helpers/conv-selector';
import { II18nItem } from '../../../i18n/_new/types';
import { getPaymentState } from '../../payment';
import { calcTotalOrders } from '../helper/calc-total';
import { compositionSelector, piecesSelector } from '../object-selector';
import { IPayment } from '../../_type/payment';
import { sizeMeasurementsSelector } from '../../lookups/object-selector/size-measurement';
import { sizeMeasurementsViewSelector } from '../../lookups/object-selector/size-measurement-view';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { excludeIndexesObject, addReducerCreator } from '../../../helpers';
import {
  isOrderDetailOrderNumber,
  isJacket,
  isInfluencedByEmbroideryOptionNumber,
  isInputEmbroideryRequired,
  toIOptionFromIOptionPatternWithSelectedInfo,
  isDifferentRightAndLeftInseams,
  isClothInputCompleted,
  isShirtItemCode,
  isBrandKR,
} from '../../../helpers/item-thisisforreplaceall';
import { isValidMemberscardNumber } from '../helper/validate';
import { IndexedObject } from '../../../types';
import { getOptionRetailPriceTaxin } from '../../../helpers/option';
import { isDesignPageInputCompleted } from './design';
import { isSizePageInputCompleted } from './size';
import pathOr from 'ramda/es/pathOr';
import { getItemCodeAndName } from '../../../helpers/view';
import clone from 'ramda/es/clone';
import { INITIAL_OBJECT } from '../../../lookups/initial-object';

export const getConfirmation = {
  orderState: createSelector(orderState, state => state),
  newOrderNumber: createSelector(orderState, state => getNewOrderNumber(state.orders)),
  totalPayment: createSelector(
    orderState,
    getProduct.state,
    getAvailableOption.state,
    getLanguage.pricePrefix,
    (state, productState, optionState, pricePrefix) => {
      const orderPrices = getProductAndOptionTotal(
        state.orders,
        productState.products,
        optionState.availableOptions,
        state.productKind,
      );
      const orderItems = Object.keys(state.orders).map(orderNumber => {
        const matchedPrice = orderPrices.find(v => v.orderNumber === orderNumber);
        const item: IOrderTotalPaymentItem = {
          deliveryDate: state.orders[orderNumber].shortestDeliveryDate,
          productPrice: matchedPrice ? matchedPrice.productPrice : 0,
          optionPrice: matchedPrice ? matchedPrice.optionPrice : 0,
          total: matchedPrice ? matchedPrice.productPrice + matchedPrice.optionPrice : 0,
        };
        return item;
      });
      const total = orderItems.reduce((p, c) => p + c.total, 0);
      const data: ITotalPaymentItem = {
        total,
        orderItems,
        pricePrefix,
      };
      return data;
    },
  ),
  confirmationsForItemPage: createSelector(
    orderState,
    getProduct.all,
    getAvailableOption.all,
    getSizeMeasurement.all,
    getAdjustOption.all,
    getLanguage.langCode,
    appStateSelector,
    (state, allProducts, allOptions, allSizeMeasurement, allAdjustOption, langCode, stateSelector) => {
      const { orders } = state;

      return Object.keys(orders).map((orderNumber, index) => {
        const { item, shortestDeliveryDate, serialNumber, orderDate } = orders[orderNumber];
        // 納期はオーダーで異なるので、オーダーごとに取得する
        const deliveryDate = shortestDeliveryDate || '';
        const title = toOrderTitle(index, Object.keys(orders).length, langCode);

        const isValidCloth = isClothInputCompleted(orders[orderNumber]);
        // design
        const isValidDesign = isDesignPageInputCompleted(
          orders[orderNumber],
          stateSelector.avaliableOptions(orderNumber),
        );
        // size
        const isValidSize = isSizePageInputCompleted(orders[orderNumber], stateSelector.sizeMeasurements(orderNumber));

        // 生地・デザイン・サイズ
        const cloth = getCloth(item);
        const partsDesigns = getDesigns(item, allOptions[orderNumber], allProducts[orderNumber]);
        const partsSizes = getSizes(item, allSizeMeasurement[orderNumber], allAdjustOption[orderNumber]);
        const orderItem: IOrderConfirmation = {
          title,
          orderNumber,
          serialNumber,
          orderDate,
          item: {
            item: getItemCodeAndName(item.itemCode, item.categoryCode),
            deliveryDate,
          },
          cloth,
          partsDesigns,
          partsSizes,
          partsDesingAndSize: [],
          isInvalid: [isValidCloth, isValidDesign, isValidSize].some(v => !v),
        };
        return orderItem;
      });
    },
  ),
  paymentConfirmation: createSelector(
    orderState,
    getProduct.state,
    getAvailableOption.state,
    getPaymentState,
    (state, productState, optionState, paymentState) => {
      // MEMO: 同じことを2箇所でやっていたのでまとめた
      return toPaymentConfirmation(
        state.orders,
        paymentState as IPayment,
        productState.products,
        optionState.availableOptions,
        state.productKind,
      );
    },
  ),
  confirmationsForOrderPage: createSelector(
    orderState,
    getProduct.state,
    getAvailableOption.state,
    getSizeMeasurement.all,
    getAdjustOption.all,
    getLanguage.langCode,
    (state, productState, optionState, allSizeMeasurement, allAdjustOption, langCode) => {
      const { orders, productKind } = state;
      const orderPriceList = getProductAndOptionTotal(
        orders,
        productState.products,
        optionState.availableOptions,
        productKind,
      );
      return Object.keys(orders).map((orderNumber, index) => {
        const { item, shipping, serialNumber, orderDate } = orders[orderNumber];
        // 納期はオーダーで異なるので、オーダーごとに取得する
        const deliveryDate = shipping.deliveryDateGuest || '';

        const title = toOrderTitle(index, Object.keys(orders).length, langCode);

        // お客様情報・アイテム
        const customer = getCustomer(shipping, deliveryDate, index, langCode);
        const matchedPrice = orderPriceList.find(v => v.orderNumber === orderNumber);
        const extraInfo = matchedPrice
          ? {
              totalCost: matchedPrice.productPrice + matchedPrice.optionPrice,
              productPrice: matchedPrice.productPrice,
              optionPrice: matchedPrice.optionPrice,
            }
          : matchedPrice;

        // 生地・デザイン・サイズ
        const cloth = getCloth(item);
        const partsDesigns = getDesigns(
          item,
          optionState.availableOptions[orderNumber],
          productState.products[orderNumber],
        );
        const partsSizes = getSizes(item, allSizeMeasurement[orderNumber], allAdjustOption[orderNumber]);

        const orderItem: IOrderConfirmation = {
          title,
          serialNumber,
          orderDate,
          orderNumber,
          customer,
          item: {
            item: getItemCodeAndName(item.itemCode, item.categoryCode),
            deliveryDate,
            extraInfo,
          },
          cloth,
          partsDesigns: [],
          partsSizes: [],
          partsDesingAndSize: toPartsDesignAndSizes(partsDesigns, partsSizes),
        };
        return orderItem;
      });
    },
  ),
  confirmationsForOrderDetailPage: createSelector(appStateSelector, getLanguage.langCode, (stateSelector, langCode) => {
    const orderDetail = stateSelector.orderDetail();
    const productState = stateSelector.allProducts();
    const availableOptionState = stateSelector.allAvailableOptions();
    const sizeMeasurementState = stateSelector.allSizeMeasurements();
    const adjustOptionState = stateSelector.allAdjustOptions();
    if (!orderDetail || !productState || !availableOptionState || !sizeMeasurementState || !adjustOptionState) {
      return [];
    }

    const { orders, productKind } = orderDetail;
    const filteredProducts = excludeIndexesObject(productState, isOrderDetailOrderNumber);
    const filteredAvailableOptions = excludeIndexesObject(availableOptionState, isOrderDetailOrderNumber);
    if (Object.keys(filteredProducts).length < 1 || Object.keys(filteredAvailableOptions).length < 1) {
      return [];
    }

    const orderPriceList = getProductAndOptionTotal(orders, filteredProducts, filteredAvailableOptions, productKind);
    return Object.keys(orders).map((orderNumber, index) => {
      const { item, shipping, serialNumber, orderDate } = orders[orderNumber];
      // 納期はオーダーで異なるので、オーダーごとに取得する
      const deliveryDate = shipping.deliveryDateGuest || '';
      const title = toOrderTitle(index, Object.keys(orders).length, langCode);

      // お客様情報・アイテム
      const customer = getCustomer(shipping, deliveryDate, index, langCode);
      const matchedPrice = orderPriceList.find(v => v.orderNumber === orderNumber);
      const extraInfo = matchedPrice
        ? {
            totalCost: matchedPrice.productPrice + matchedPrice.optionPrice,
            productPrice: matchedPrice.productPrice,
            optionPrice: matchedPrice.optionPrice,
          }
        : matchedPrice;

      // 生地・デザイン・サイズ
      const cloth = getCloth(item);
      const partsDesigns = getDesigns(item, filteredAvailableOptions[orderNumber], filteredProducts[orderNumber]);
      const partsSizes = getSizes(item, sizeMeasurementState[orderNumber], adjustOptionState[orderNumber]);
      const orderItem: IOrderConfirmation = {
        title,
        orderNumber,
        customer,
        serialNumber,
        orderDate,
        item: {
          item: getItemCodeAndName(item.itemCode, item.categoryCode),
          deliveryDate,
          extraInfo,
        },
        cloth,
        partsDesigns: [],
        partsSizes: [],
        partsDesingAndSize: toPartsDesignAndSizes(partsDesigns, partsSizes) || [],
      };
      return orderItem;
    });
  }),
  paymentConfirmationForOrderDetail: createSelector(appStateSelector, stateSelector => {
    const orderDetail = stateSelector.orderDetail();
    const productState = stateSelector.allProducts();
    const availableOptionState = stateSelector.allAvailableOptions();
    if (!orderDetail || !productState || !availableOptionState) {
      return null;
    }

    const { orders, payment, productKind } = orderDetail;
    if (!payment) {
      return null;
    }

    const filteredProducts = excludeIndexesObject(productState, isOrderDetailOrderNumber);
    const filteredAvailableOptions = excludeIndexesObject(availableOptionState, isOrderDetailOrderNumber);
    if (Object.keys(filteredProducts).length < 1 || Object.keys(filteredAvailableOptions).length < 1) {
      return null;
    }

    // MEMO: 同じことを2箇所でやっていたのでまとめた
    return toPaymentConfirmation(orders, payment, filteredProducts, filteredAvailableOptions, productKind);
  }),
  note: createSelector(appStateSelector, stateSelector => {
    const orderDetail = stateSelector.orderDetail();
    if (!orderDetail) {
      return '';
    }
    return orderDetail.note || '';
  }),
  hasShortestDeliveryDate: createSelector(orderState, state => {
    const { orders } = state;
    return Object.keys(orders).every(key => !!orders[key].shortestDeliveryDate);
  }),
  // orderが完璧か？
  isValidAllOrders: createSelector(appStateSelector, stateSelector => {
    const orders = stateSelector.orders();
    return (
      !!orders &&
      Object.entries(orders).every(([orderNumber, order]) => {
        // cloth
        const isValidCloth = isClothInputCompleted(order);
        // design
        const isValidDesign = isDesignPageInputCompleted(order, stateSelector.avaliableOptions(orderNumber));
        // size
        const isValidSize = isSizePageInputCompleted(order, stateSelector.sizeMeasurements(orderNumber));
        return isValidCloth && isValidDesign && isValidSize;
      })
    );
  }),
  isLoggedInCustomer: createSelector(appStateSelector, state => {
    const customer = state.customer();
    if (customer === undefined) {
      return false;
    }

    return isValidMemberscardNumber(customer.memberscardNumber);
  }),
  /**
   * 受注停止期間中でも注文確定できるオーダーかを判定して返す
   * 全てのオーダーがシャツ、またはブランドKRの場合のみtrueを返却する
   */
  canOrdersDuringOrderStopTerm: createSelector(orderState, state =>
    Object.entries(state.orders).every(
      ([_orderNumber, order]) => isShirtItemCode(order.item.itemCode) || isBrandKR(order.item.cloth.brandCode),
    ),
  ),
  scProjectFromOrderDetail: createSelector(appStateSelector, stateSelector => {
    const orderDetail = stateSelector.orderDetail();
    const scProject = orderDetail?.scProject || clone(INITIAL_OBJECT.scProject);
    return scProject;
  }),
};

type TLangCode = keyof II18nItem;

function getCustomer(
  shipping: IShipping,
  deliveryDate: string,
  index: number,
  langCode: TLangCode,
): ICustomerConfirmation {
  return {
    deliveryMethod: getValue(shipping.deliveryMethod, MASTER_DELIVERY_METHOD) || shipping.deliveryMethod || '',
    shippingCost: +shipping.shippingCost,
    shippingPlace: getShippingPlace(shipping, index, langCode),
    deliveryDate: [
      toDeliveryWithLaterDays(deliveryDate, langCode),
      getValue(shipping.timeZoneCode, MASTER_DELIVERY_TIME_ZONE) || '',
    ].join(' '),
  };
}

function getShippingPlace(shipping: IShipping, index: number, langCode: TLangCode): string | IAddressConfirmation {
  if (index !== 0 && shipping.isSameOrderOne) {
    return toSameAddressOrderOne(langCode);
  }

  const postalCode = shipping.shippingPostalCode.slice(0, 3) + '-' + shipping.shippingPostalCode.slice(3);

  return {
    address: ['〒', postalCode, shipping.shippingState, shipping.shippingCity, shipping.shippingStreet].join(' '),
    nameKana: [shipping.customerFamilyNameKana, shipping.customerGivenNameKana].join(' '),
    nameKanji: [shipping.customerFamilyNameKanji, shipping.customerGivenNameKanji].join(' '),
    phoneNumber: shipping.shippingPhoneNumber,
    email: shipping.customerMailAddress,
    cutterNamaKana: shipping.cutterNameKana,
    memberscardNumber: shipping.memberscardNumber,
    lotNumber: shipping.lotNumber,
  };
}

function getCloth(item: IItem): TClothConfirmation {
  const {
    clothCode,
    brandCode,
    clothModelCode,
    personalorderProductNumber,
    design,
    compositionFront,
    compositionBack,
    personalorderColorCode,
    stockPlaceCode,
    vendorClothNumber,
    clothSeasonCode,
    productSeasonCode,
    clothBrandCode,
    hasStock,
    requiredScale,
  } = item.cloth;

  return {
    clothCode: `${clothSeasonCode} ${clothCode}`,
    brandCode,
    clothModelCode,
    personalorderProductNumber,
    design: getValue(design, MASTER_CLOTH_DESIGN) || '',
    compositionFront: compositionSelector(compositionFront).toConfirmationFormat,
    compositionBack: compositionSelector(compositionBack).toConfirmationFormat,
    personalorderColor: getValue(personalorderColorCode, MASTER_CLOTH_COLOR) || '',
    stockPlace: getValue(stockPlaceCode, MASTER_CLOTH_PARTITION) || '',
    vendorClothNumber,
    clothSeasonCode,
    productSeasonCode,
    clothBrandCode,
    hasStock,
    requiredScale,
  };
}

function getDesigns(
  item: IItem,
  _options?: IAvailableOption[],
  _products?: IClothProduct[],
): Array<IPartsConfirmation<IDesignConfirmation>> {
  if (!_options || !_products) {
    return [];
  }

  const { pieces, design, clothModelCode, itemCode, brandCode, categoryCode, subCategoryCode } = getItemBaseInfo(item);
  const models = clothProductsSelector({
    itemCode,
    brandCode,
    products: _products,
    subCategoryCode,
  }).getPartsSelectableDesignModelCodes(categoryCode, pieces);
  const options = availableOptionsSelector(_options).withSelectedInfo(
    pieces,
    design.designParts,
    design.selecting,
    categoryCode,
    clothModelCode,
    brandCode,
  );
  const eops = editedAvailableOptionsSelector(options);
  return piecesSelector(pieces)
    .distinctPieces()
    .map(({ index: partsIndex, partsNumber }) => {
      const optionPatterns = eops.editiedAvailableOptionPatternWithModel(
        partsIndex,
        models,
        design.selecting,
        categoryCode,
        brandCode,
        true, // スペアのオプションはメインに合わせて表示する
      );
      const optionItems = optionPatterns
        // 選択済 or ジャケット以外のmodelか？
        .filter(v => !!v.selectingClassName || !!v.selectingClassNumber || (v.isModel && !isJacket(partsNumber)))
        // 刺繍ネームがなしの場合、刺繍に関する項目を非表示にする
        .filter(
          v =>
            !(
              isInfluencedByEmbroideryOptionNumber(v.optionNumber, partsNumber) &&
              !isInputEmbroideryRequired(toIOptionFromIOptionPatternWithSelectedInfo(optionPatterns), partsNumber)
            ),
        )
        .map(option => {
          const { selectingClassName, selectingClassNumber, optionName, isSpecial, optionClasses } = option;
          const optionItem: IConfirmationOption = {
            optionName,
            optionClassName: selectingClassName,
            priceTaxIn: getOptionRetailPriceTaxin(optionClasses, selectingClassNumber),
            isSpecial,
          };
          return optionItem;
        });

      const confirmation: IPartsConfirmation<IDesignConfirmation> = {
        partsName: getValue(partsNumber, MASTER_PARTS) || '',
        partsIndex,
        partsNumber,
        data: {
          designs: optionItems.filter(v => !v.isSpecial),
          specials: optionItems.filter(v => !!v.isSpecial),
        },
      };
      return confirmation;
    });
}

function getSizes(
  item: IItem,
  sizeMeasurement?: ISizeMeasurement[],
  adjustOption?: IPartsAdjustOption[],
): Array<IPartsConfirmation<ISizeConfirmation>> {
  if (!sizeMeasurement || !adjustOption) {
    return [];
  }

  const { partsSize, pieces } = getItemBaseInfo(item);
  const measurementView = sizeMeasurementsSelector(sizeMeasurement).toView(pieces, partsSize);
  const adjustOptionView = adjustOptionsSelector(adjustOption).toView(pieces, partsSize);
  return Object.keys(partsSize)
    .sort((a, b) => +a - +b)
    .map(v => v as TPartsNumber)
    .map(partsNumber => {
      const partsName = getValue(partsNumber, MASTER_PARTS) || '';
      const gauge = joinGauge(partsSize[partsNumber].gauge);
      const sizes = sizeMeasurementsViewSelector(measurementView).getConfirmations(partsNumber);
      const adjusts = adjustOptionsViewSelector(adjustOptionView).getConfirmations(partsNumber);
      // 左右の股下が異なるか？（ズボン以外の時は常にfalse）
      const isDifferentInseams = isDifferentRightAndLeftInseams(
        partsNumber,
        sizeMeasurementsViewSelector(measurementView).getFromPartsNumber(partsNumber),
      );
      const confirmation: IPartsConfirmation<ISizeConfirmation> = {
        partsName,
        partsNumber,
        gauge,
        isDifferentInseams,
        data: {
          sizes: [...sizes, ...adjusts.filter(v => v.isSpecial === false)],
          adjusts: adjusts.filter(v => v.isSpecial === true),
        },
      };
      return confirmation;
    });
}

function toPartsDesignAndSizes(
  partsDesigns: Array<IPartsConfirmation<IDesignConfirmation>>,
  partsSizes: Array<IPartsConfirmation<ISizeConfirmation>>,
) {
  return partsDesigns.map(design => {
    const { partsNumber, partsName } = design;
    const size = partsSizes.find(v => v.partsNumber === partsNumber) || {
      partsName: '',
      partsNumber: '01',
      gauge: '',
      data: { sizes: [], adjusts: [] },
    };
    return {
      design,
      size,
      partsName,
    };
  });
}

function toPaymentConfirmation(
  orders: IndexedObject<IOrder>,
  payment: IPayment,
  allProducts: IndexedObject<IClothProduct[]>,
  allAvailableOptions: IndexedObject<IAvailableOption[]>,
  productKind: TProductKind,
) {
  const orderPrices = getProductAndOptionTotal(orders, allProducts, allAvailableOptions, productKind);
  const orderTotal = orderPrices.map(({ productPrice, optionPrice, shippingCost }, index) => {
    return {
      title: `${index + 1}/${orderPrices.length}`,
      productPrice,
      optionPrice,
      shippingCost,
    };
  });

  const item: IPaymentConfirmation = {
    orders: orderTotal,
    useablePoints: 0,
    backPoints: 0,
    payment,
    totalList: {
      shippingCost: orderPrices.reduce(addReducerCreator(pathOr(0, ['shippingCost'])), 0),
      orderTotal: calcTotalOrders(orders, allProducts, allAvailableOptions, productKind),
      settlementCost: 0,
      usePoint: payment.usePoint,
      couponUsePoint: payment.couponUsePoints,
      total: 0, // 未使用
    },
  };
  return item;
}
