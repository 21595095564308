import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { IRequiredCheckDialog } from '../types/dialog';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { ItemContentPageEvents } from '../views/events/ItemContentPage';
import { map } from 'rxjs/operators';
import { requiredCheckActions } from '../store/utils/dialog/required-check';

const onShowRequiredCheckDialog: Epic<AnyAction, Action<IRequiredCheckDialog>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ItemContentPageEvents.onShowRequiredCheckDialog),
    map(({ payload }) => requiredCheckActions.show(payload)),
  );

export const ItemContentPageListeners = combineEpics(onShowRequiredCheckDialog);
