import React from 'react';
import { RouteComponentProps } from 'react-router';
import pipe from 'ramda/es/pipe';
import partial from 'ramda/es/partial';

import styles from './OrderDigestsReport.module.scss';

import I18TextContainer from '../../../containers/I18Text/I18Text';
import Button from '../../atoms/Button/Button';

import { isStoppedStartOrderTerm } from '../../../helpers/orders';
import { MANAGER_ONLY_ORDERABLE_TERM_DIALOG } from '../../../lookups/dialog';
import { IOrderDigests } from '../../../types/orders/digest';
import { langResource } from '../../../i18n/_new/resources/';
import { getOrderDetailPath } from '../../../helpers/common/path';
import { canModifyOrder } from '../../../helpers/item-thisisforreplaceall';
import { IOKCancelDialog } from '../../../types/dialog';
import { TPageContainerProps } from '../../../containers/pages/OrderDigestsSearchPage';
import { downloadCsvFile, makeCsvData } from '../../../helpers/common/csv';
import { ORDER_DIGESTS_CSV_CONFIG } from '../../../lookups/csv';
import { getCsvFileName } from '../../../helpers/orders/digest';

type TProps = TPageContainerProps & RouteComponentProps;

const OrderDigestsReport: React.FC<TProps> = props => {
  const outputCsv = pipe(
    partial(makeCsvData, [ORDER_DIGESTS_CSV_CONFIG]),
    partial(downloadCsvFile, [getCsvFileName()]),
  );

  const onOutputCsv = (data: IOrderDigests[]) => () => outputCsv(data);

  return (
    <React.Fragment>
      <div className={styles.header}>
        <p className={styles.title}>
          <I18TextContainer textObj={langResource.search.result} />：<span>{props.ordersDigests.length}件</span>
        </p>
        <div className={styles.btnWrapper}>
          <Button
            styles={['black', 'size-xs']}
            onClick={onOutputCsv(props.ordersDigests)}
            textObj={langResource.orderDigestsSearchPage.csvBtn}
          />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.thead}>
          <div className={styles.row}>
            <div className={styles.col0}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.houjinCd} />
            </div>
            <div className={styles.col1}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.houjinName} />
            </div>
            <div className={styles.col1}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.serialNumber} />
            </div>
            <div className={styles.col2}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.orderDate} />
            </div>
            <div className={styles.col3}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.memberscardNumber} />
            </div>
            <div className={styles.col4}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.nameKana} />
            </div>
            <div className={styles.col5}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.itemCode} />
            </div>
            <div className={styles.col6}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.brandCode} />
            </div>
            <div className={styles.col7}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.seasonCode} />
            </div>
            <div className={styles.col8}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.productNumber} />
            </div>
            <div className={styles.col9}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.tempoName} />
            </div>
            <div className={styles.col10}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.memo} />
            </div>
            <div className={styles.col11}>
              <I18TextContainer textObj={langResource.orderDigestsSearchPage.action} />
            </div>
          </div>
        </div>
        <div className={styles.tbody}>
          {props.ordersDigests.map((order, i) => (
            <div className={[styles.row, order.hasCancel && styles.cancelorder].join(' ')} key={i}>
              <div className={styles.col0}>{order.companyCode}</div>
              <div className={styles.col1}>{order.companyName}</div>
              {/* キャンセルのとき、rowに、class「cancelorder」を追加する */}
              <div className={styles.col1}>
                {/* 以下、キャンセルのとき */}
                {renderCancelIcon(order.hasCancel)}
                {order.serialNumber}
              </div>
              <div className={styles.col2}>{order.orderDate}</div>
              <div className={styles.col3}>{order.memberscardNumber}</div>
              <div className={styles.col4}>{order.nameKana}</div>
              <div className={styles.col5}>{order.item.itemCode}</div>
              <div className={styles.col6}>{order.brandCode}</div>
              <div className={styles.col7}>{order.seasonCode}</div>
              <div className={styles.col8}>{order.productNumber}</div>
              <div className={styles.col9}>{order.tempoName}</div>
              <div className={styles.col10}>{renderMemo(order)}</div>
              <div className={styles.col11}>
                <p>
                  <Button
                    styles={['block', 'black', 'size-xs']}
                    onClick={onCreateRefOrder(order)}
                    textObj={langResource.orderDigestsSearchPage.reference}
                  />
                </p>
                <p>
                  <Button
                    styles={['block', 'white', 'size-xs']}
                    onClick={onClickConfirmationOrModify(order)}
                    textObj={
                      isModifiable(order) && !order.hasCancel
                        ? langResource.orderDigestsSearchPage.confirmationOrModify
                        : langResource.orderDigestsSearchPage.confirmation
                    }
                  />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  function renderCancelIcon(cancelFlag: boolean) {
    return cancelFlag ? (
      <div className={styles.cancelorderIcn}>
        <I18TextContainer textObj={langResource.orderDigestsSearchPage.cancel} />
      </div>
    ) : null;
  }

  function onClickConfirmationOrModify(order: IOrderDigests) {
    return goToOrderDetailPage(order);
  }

  function goToOrderDetailPage(order: IOrderDigests) {
    const path = getOrderDetailPath(order.requestSerial);
    return () => props.history.push(path);
  }

  function renderMemo(order: IOrderDigests) {
    const { serialYear, serialNumber } = order;
    const onOpen = () => {
      props.showMemoDialog({
        memo: order.memo || '',
        serialNumber: `${serialYear}${serialNumber}`,
        canEdit: true,
      });
    };

    return order.memo ? (
      <Button
        styles={['block', 'pink', 'size-xs']}
        textObj={langResource.orderDigestsSearchPage.existingMemo}
        onClick={onOpen}
      />
    ) : (
      <Button
        styles={['block', 'white', 'size-xs']}
        textObj={langResource.orderDigestsSearchPage.nonExistingMemo}
        onClick={onOpen}
      />
    );
  }

  function onCreateRefOrder(order: IOrderDigests) {
    const canOrder = !isStoppedStartOrderTerm(new Date()) || props.isManager;
    if (!canOrder) {
      return () => props.showInfoDialog(MANAGER_ONLY_ORDERABLE_TERM_DIALOG);
    }

    const { requestSerial, memberscardNumber } = order;
    const dialog: IOKCancelDialog = {
      hasOpen: true,
      title: '確認',
      contents: '編集中の情報をすべてクリアし、この注文を元に新規注文データを作成しますか？',
      onOK: () => {
        props.onCreateRefOrder({
          loadOrderDetailParam: { requestSerial },
          loginParam: { memberscardNumber: memberscardNumber.toString() },
        });
      },
    };
    return () => props.onShowOkCancelDialog(dialog);
  }

  function isModifiable(order: IOrderDigests) {
    const loggedTempoCode = props.staff.tempoCode;
    const { tempoCode, orderDate, memberscardNumber } = order;
    const { isManager } = props;
    return canModifyOrder({ tempoCode, loggedTempoCode, orderDate, isManager, memberscardNumber });
  }
};

export default OrderDigestsReport;
