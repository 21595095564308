import { createSelector } from 'reselect';
import { AppState } from '../index';
import { ICustomerState } from './action-reducer';

export { customerReducer } from './action-reducer';

const featureSelector = (state: AppState): ICustomerState => state.customer;

const getCustomerState = createSelector(featureSelector, state => state);

export const get = {
  memberscardNumber: createSelector(getCustomerState, state => state.data.memberscardNumber),
  customer: createSelector(getCustomerState, state => state.data),
  customerName: createSelector(getCustomerState, state =>
    `${state.data.customerFamilyNameKanji} ${state.data.customerGivenNameKanji}`.trim(),
  ),
  isLoad: createSelector(getCustomerState, state => state.isLoaded),
  isLoading: createSelector(getCustomerState, state => state.isLoading),
};
