import { TMaster } from '../../types/index';
import { rawStrArr2EnumLikeObj } from '../../lib/utils';
import { TPartsNumber } from './parts';

const nudeDementionCodes = rawStrArr2EnumLikeObj([
  '7001',
  '7004',
  '7005',
  '7006',
  '7011',
  '7027',
  '7029',
  '7030',
  '7033',
  '7034',
  '7105',
  '7136',
  '7137',
  '7138',
  '7139',
  '7140',
  '7141',
  '7142',
  '7200',
  '7203',
  '7601',
  '7604',
  '7605',
  '7606',
  '7710',
  '7711',
  '7712',
  '7713',
]);

export type TNudeDementionCode = keyof typeof nudeDementionCodes;

// APIに送るヌード寸法↓
export const NUDE_SIZE_OPTION_NUMBERS_OF_JACKET: TNudeDementionCode[] = [];
export const NUDE_SIZE_OPTION_NUMBERS_OF_PANTS: TNudeDementionCode[] = [];
export const NUDE_SIZE_OPTION_NUMBERS_OF_COAT: TNudeDementionCode[] = [];
export const NUDE_SIZE_OPTION_NUMBERS_OF_ONEPIECE: TNudeDementionCode[] = [];
export const NUDE_SIZE_OPTION_NUMBERS_OF_TOPS: TNudeDementionCode[] = [];
export const NUDE_SIZE_OPTION_NUMBERS_OF_SKIRT: TNudeDementionCode[] = [];
// ヌード寸(シャツ)のoptionNumber（=画面で入力できるヌード寸法/APIに送るヌード寸法）
export const NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT: TNudeDementionCode[] = [];
// ヌード寸のoptionNumber（=画面で入力できるヌード寸法）
export const NUDE_SIZE_OPTION_NUMBERS: TNudeDementionCode[] = [
  ...NUDE_SIZE_OPTION_NUMBERS_OF_JACKET,
  ...NUDE_SIZE_OPTION_NUMBERS_OF_PANTS,
];

export const MASTETR_NUDE_DEMENTION: Array<TMaster<TNudeDementionCode, string>> = [
  { code: '7001', value: '総丈' },
  { code: '7004', value: 'OB' },
  { code: '7005', value: '上胴' },
  { code: '7006', value: '中胴' },
  { code: '7203', value: 'ウエスト' },
  { code: '7011', value: '尻囲' },
  { code: '7027', value: 'ネック' },
  { code: '7029', value: 'チェスト' },
  { code: '7033', value: '裄丈' },
  { code: '7034', value: 'カフス長' },
  { code: '7030', value: 'ウエスト' },
  { code: '7136', value: 'ウエスト' }, // スカート
  { code: '7137', value: '尻囲' }, // スカート
  { code: '7105', value: '総丈' }, // ワンピースののときにcopy追加するヌード寸法（送信時に名称を使うので追記）
  { code: '7138', value: 'OB' }, // ワンピース
  { code: '7139', value: '上胴' }, // ワンピース
  { code: '7140', value: '中胴' }, // ワンピース
  { code: '7141', value: 'ウエスト' }, // ワンピース
  { code: '7142', value: '尻囲' }, // ワンピース
  { code: '7200', value: 'ヒップ' },
  { code: '7601', value: '総丈' },
  { code: '7604', value: 'OB' },
  { code: '7605', value: '上胴' },
  { code: '7606', value: '中胴' },
  { code: '7710', value: '総丈' }, // トップス
  { code: '7711', value: 'OB' }, // トップス
  { code: '7712', value: '上胴' }, // トップス
  { code: '7713', value: '中胴' }, // トップス
];

/** 注文確定APIにて送信対象となるパーツごとのヌード寸法の一覧 */
export const NUDE_SIZE_OPTIONS_FOR_SEND_SERVER: Array<TMaster<TPartsNumber, TNudeDementionCode[]>> = [
  { code: '01', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_JACKET] },
  { code: '02', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_PANTS] },
  { code: '04', value: [] },
  { code: '05', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_SKIRT] },
  { code: '06', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_COAT] },
  { code: '09', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT] },
  { code: '10', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_ONEPIECE] },
  { code: '12', value: [...NUDE_SIZE_OPTION_NUMBERS_OF_TOPS] },
];

type TSameNamesNudeSizeMaster = {
  baseCode: TNudeDementionCode;
  tops?: TNudeDementionCode;
  coat?: TNudeDementionCode;
  onepiece?: TNudeDementionCode;
  skirt?: TNudeDementionCode;
};

/** 同一採寸箇所の採寸項目番号一覧 */
export const SAME_NUDE_SIZE_OPTION_NUMBERS: TSameNamesNudeSizeMaster[] = [
  { baseCode: '7001', tops: '7710', coat: '7601', onepiece: '7105' }, // 総丈
  { baseCode: '7004', tops: '7711', coat: '7604', onepiece: '7138' }, // OB
  { baseCode: '7005', tops: '7712', coat: '7605', onepiece: '7139' }, // 上胴
  { baseCode: '7006', tops: '7713', coat: '7606', onepiece: '7140' }, // 中胴
  { baseCode: '7203', onepiece: '7141', skirt: '7136' }, // ウエスト
  { baseCode: '7011', onepiece: '7142', skirt: '7137' }, // 尻囲
];

// OEMにて仮でフロントに置く
export const OEM_ADJUST_DATA = [
  { code: '7002', adjustment_upper_limit: 15, adjustment_lower_limit: -15, measurement_pitch: 0.5 },
  { code: '7003', adjustment_upper_limit: 2, adjustment_lower_limit: -2, measurement_pitch: 0.5 },
  { code: '7007', adjustment_upper_limit: 20, adjustment_lower_limit: -20, measurement_pitch: 0.5 },
  { code: '7008', adjustment_upper_limit: 20, adjustment_lower_limit: -20, measurement_pitch: 0.5 },
  { code: '7009', adjustment_upper_limit: 3, adjustment_lower_limit: -3, measurement_pitch: 0.5 },
  { code: '7010', adjustment_upper_limit: 6, adjustment_lower_limit: -6, measurement_pitch: 1 },
  { code: '7018', adjustment_upper_limit: 0, adjustment_lower_limit: 0, measurement_pitch: 0 },
  { code: '7012', adjustment_upper_limit: 4, adjustment_lower_limit: -2, measurement_pitch: 0.5 },
  { code: '7013', adjustment_upper_limit: 30, adjustment_lower_limit: -30, measurement_pitch: 0.5 },
  { code: '7014', adjustment_upper_limit: 30, adjustment_lower_limit: -30, measurement_pitch: 0.5 },
  { code: '7015', adjustment_upper_limit: 6, adjustment_lower_limit: -6, measurement_pitch: 0.5 },
  { code: '7016', adjustment_upper_limit: 5, adjustment_lower_limit: -5, measurement_pitch: 0.5 },
  { code: '7017', adjustment_upper_limit: 4, adjustment_lower_limit: -4, measurement_pitch: 0.5 },
  { code: '7019', adjustment_upper_limit: 10, adjustment_lower_limit: -10, measurement_pitch: 0.5 },
  { code: '7020', adjustment_upper_limit: 3, adjustment_lower_limit: -3, measurement_pitch: 0.5 },
];
