import { IImageGallery } from '../types/home';
import { ERouterPath } from '../types';
import { langResource } from '../i18n/_new/resources/';
import { ESubcategory, TSubCategory, MASTER_SUBCATEGORY } from './master-thisisforreplaceall/subcategory';
import { getValue } from '../helpers/master-lookup';
import { II18nItem, I18nItemDefault } from '../i18n/_new/types';

const getSubCategoryName = (subcategory: TSubCategory): II18nItem =>
  getValue<TSubCategory, II18nItem>(subcategory, MASTER_SUBCATEGORY) || I18nItemDefault;

export function getGalleryData(categoryInfo: any): IImageGallery[] {
  return [
    {
      url: 'images/home/classic.png',
      title: langResource.homePage.classic,
      to: ERouterPath.clothSelection,
      buttons:
        localStorage.getItem('brandCode') === 'IO'
          ? [
              {
                category: 'CS',
                subCategory: ESubcategory.suit,
                text: 'スーツ',
                to: ERouterPath.clothSelection,
              },
            ]
          : [
              {
                category: 'CS',
                subCategory: ESubcategory.suit,
                text: 'スーツ',
                to: ERouterPath.clothSelection,
              },
              {
                category: 'CS',
                subCategory: ESubcategory.jacket,
                text: 'ジャケット',
                to: ERouterPath.clothSelection,
              },
              {
                category: 'CS',
                subCategory: ESubcategory.pants,
                text: 'パンツ',
                to: ERouterPath.clothSelection,
              },
              {
                category: 'CS',
                subCategory: ESubcategory.vest,
                text: 'ベスト',
                to: ERouterPath.clothSelection,
              },
            ],
    },
  ];
}
