import React from 'react';
import NumberFormat from 'react-number-format';

import styles from './PointUsage.module.scss';

import Title from '../../atoms/Title/Title';
import PriceTextPlane from '../../atoms/PriceTextSpan/PriceTextSpan';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import { langResource } from '../../../i18n/_new/resources';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { pointPageActions } from '../../../store/pages/point';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import { canInputUsePoint } from '../../../helpers/item-thisisforreplaceall';
import { POINT_USAGE_UNIT } from '../../../lookups/point';
import { CSS_CLASSNAME_ERROR_INVALID_DATA } from '../../../lookups/html-css';
import { IPaymentList } from '../../../types/new-store/components/index';

interface IProps {
  usePoint: number;
  useablePoints: number;
  backPoints: number;
  memberscardNumber: string;
  couponUsePoints: number;
  paymentList: IPaymentList;
  isEdit: boolean;
  onChangeValue: typeof pointPageActions.changeValue.tp;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const PointUsage: React.FC<TProps> = (props: TProps) => {
  const { usePoint, useablePoints, memberscardNumber, couponUsePoints, paymentList, isEdit } = props;
  const pointMax = paymentList.orderTotal + paymentList.settlementCost + paymentList.shippingCost - couponUsePoints;
  const canInput = canInputUsePoint(useablePoints, memberscardNumber);
  const connectNumericKeyboard = () => {
    props.connectNumericKeyboard(usePoint, value => {
      if (+value <= useablePoints && +value <= pointMax) {
        props.onChangeValue({ key: 'usePoint', value: +value });
      } else {
        return Math.min(useablePoints, pointMax);
      }
    });
  };

  const isValidPointInput = usePoint % POINT_USAGE_UNIT === 0;
  const pointInputClass = isValidPointInput ? undefined : CSS_CLASSNAME_ERROR_INVALID_DATA;
  const pointInputErrorMsg = isValidPointInput ? '' : '500ポイント単位で指定して下さい。';
  return (
    <div className={styles.points}>
      <div className={styles.title}>
        <Title styles={['lineLightBrown']} textObj={langResource.pointPage.pointUsage} />
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <I18TextContainer textObj={langResource.pointPage.usePoints} />
          </div>
          <div className={styles.columnSize2}>
            <div className={styles.pointSet1}>
              <NumberFormat
                thousandSeparator={true}
                disabled={isEdit || !canInput}
                readOnly={true}
                value={usePoint}
                allowNegative={false}
                // decimalScale={0} FIXME: stateが反映されないので一時的にコメントアウト
                onFocus={connectNumericKeyboard}
                data-input-type={EKeyboardInputType.number}
                className={pointInputClass}
              />
              <div className="error-tooltip">{pointInputErrorMsg}</div>
            </div>
            <div className={styles.pointSet2}>
              <I18TextContainer textObj={langResource.pointPage.useablePoints} />
              <PriceTextPlane price={useablePoints} />
              <I18TextContainer textObj={langResource.pointPage.pointPostFix} />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            {/* <I18TextContainer textObj={langResource.pointPage.backPoints} /> */}
          </div>
          <div className={styles.columnSize3}>
            <div className={styles.pointSet1}>
              {/* <PriceTextPlane price={backPoints} />
              <I18TextContainer textObj={langResource.pointPage.pointPostFix} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointUsage;
