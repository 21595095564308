import { IndexedObject } from '../../types';
import { fetchApi } from '../../api';
import { OrderDigestRes, orderDigestRequestConfig } from '../../api/orders/digest';
import { IStaff } from '../../store/_type/staff';
import { isInternalStaff } from '../../helpers/staff';

export async function getOrderDigests(reqParam: IndexedObject, staff: IStaff): Promise<OrderDigestRes> {
  const isManagerStaff = !staff.managerFlag;
  const param = {
    ...reqParam,
    tempoCode: isManagerStaff ? staff.tempoCode : reqParam.tempoCode || '',
    staffCode: isManagerStaff ? staff.staffCode : reqParam.staffCode || '',
  };
  return fetchApi(OrderDigestRes, orderDigestRequestConfig, { query: param });
}
