import format from 'date-fns/format';
import parse from 'date-fns/parse';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import getHours from 'date-fns/getHours';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import either from 'ramda/es/either';
import pipe from 'ramda/es/pipe';
import partialRight from 'ramda/es/partialRight';

// FIXME: TimeZoneを意識して作り直したほうが良いかも！

const LOCAL_SERVER_FORMAT = 'yyyyMMdd';
// const LOCAL_JP_KANZI_FORMAT = 'yyyy年MM月dd日';
const LOCAL_JP_FORMAT = 'yyyy/MM/dd';

export function toDate(date: string, dateFormat: string = LOCAL_SERVER_FORMAT): Date {
  return parse(date, dateFormat, new Date());
}

export function toDeliveryDate(date: Date, today: Date = new Date(), dateFormat: string = LOCAL_JP_FORMAT): string {
  const days = getDeliveryDays(date, today);
  const targetFormat = toDateFormatString(date, dateFormat);
  return `${targetFormat}${days ? ` (${days}日後)` : ''}`;
}

export function getDeliveryDays(targetDate: Date, today: Date = new Date()): number {
  return differenceInDays(targetDate, today);
}

export function toDateFormatString(value: Date, dateFormat: string = LOCAL_SERVER_FORMAT): string {
  return format(value, dateFormat); // , { awareOfUnicodeTokens: true }); awareOfUnicodeTokensは、既に存在しないオプションです。
}

/**
 * 同じ日付の場合、trueを返却する
 * date1は'yyyy/MM/dd'の文字列
 * date2はDate型。省略した場合は現在日時
 */
export const isSameDate = (date1: string, date2: Date = new Date()) => toDateFormatString(date2) === date1;

export const toDateFormat = (v: string, dateFormat: string = LOCAL_SERVER_FORMAT) => {
  return toDateFormatString(toDate(v), 'yyyy.mm.dd');
};

export function toAddDay(value: string, days: number): string {
  const y = +value.slice(0, 4);
  const m = +value.slice(4, 6) - 1;
  const d = +value.slice(6, 8);
  const date = new Date(y, m, d);
  const newDate = addDays(date, days);
  return toDateFormatString(newDate);
}

export function getConsecutiveDaysFromOneDay(startDate: Date, later: number): Date[] {
  const endDate = addDays(startDate, later);
  return eachDayOfInterval({ start: startDate, end: endDate });
}

export function getDateHours(value: Date): number {
  return getHours(value);
}

export const isEqualOrBefore = either(isEqual, isBefore);
export const isEqualOrAfter = either(isEqual, isAfter);

export const addMonthsStartOfMonthDate = pipe(addMonths, startOfMonth);
export const addMonthsEndOfMonthDate = pipe(addMonths, endOfMonth);
export const isMatchDateTerm = (term: { start: Date; end: Date }, date: Date): boolean =>
  isEqualOrAfter(date, term.start) && isEqualOrBefore(date, term.end);

export const toDateFormatStr = pipe(
  partialRight(parse, [LOCAL_SERVER_FORMAT, new Date(), undefined]),
  partialRight(format, [LOCAL_JP_FORMAT, undefined]),
);
