import { ESubcategory } from '../master-thisisforreplaceall/subcategory';
import { EParts } from '../master-thisisforreplaceall/parts';

export const subCategoriesInitialParts = {
  [ESubcategory.suit]: [EParts.jaket, EParts.pants],
  [ESubcategory.jacket]: [EParts.jaket],
  [ESubcategory.pants]: [EParts.pants],
  [ESubcategory.vest]: [EParts.vest],
  [ESubcategory.pantSuit]: [EParts.jaket, EParts.pants],
  [ESubcategory.skirtSuit]: [EParts.jaket, EParts.skirt],
  [ESubcategory.tuxedo]: [EParts.jaket, EParts.pants],
  [ESubcategory.skirt]: [EParts.skirt],
  [ESubcategory.onepiece]: [EParts.onepiece],
  [ESubcategory.onepieceSuit]: [EParts.jaket, EParts.onepiece],
  [ESubcategory.longSleve]: [EParts.shirt],
  [ESubcategory.shortSleve]: [EParts.shirt],
  [ESubcategory.formalShirt]: [EParts.shirt],
  [ESubcategory.coat]: [EParts.coat],
  [ESubcategory.tops]: [EParts.tops],
  [ESubcategory.womanlongSleeve]: [EParts.shirt],
  [ESubcategory.womanshortSleeve]: [EParts.shirt],
};
