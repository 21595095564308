import { mergeState } from '../helpers/common/merge';
import parseISO from 'date-fns/parseISO';

const config: { [key: string]: any } = {
  all: {
    language: 'en',
    env:
      process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENV_STAGING === '1' ? 'development' : 'production',
    isDev: process.env.NODE_ENV !== 'production' || process.env.REACT_APP_ENV_STAGING === '1',
    isLocal: process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENV_STAGING !== '1',
    isTest: process.env.NODE_ENV === 'test',
    baseUrl: process.env.PUBLIC_URL || '',
  },
  development: {
    api: 'http://owdom.ecbeing.biz/shop/api',
    // dummyMemberscardNumber: 9900480088424,
    dummySeasonCode: 'QS',
    stoppedStartOrderTerm: { start: parseISO('2022-01-19 08:00'), end: parseISO('2022-01-19 12:00') },
    stoppedSettlementOrderTerm: { start: parseISO('2022-01-19 12:00'), end: parseISO('2022-01-19 21:00') },
  },
  test: {
    api: 'http://owdom.ecbeing.biz/shop/api',
    dummySeasonCode: 'QS',
  },
  production: {
    api: 'https://omapi.onward.co.jp/shop/api',
    // dummyMemberscardNumber: 9900094977626,
    stoppedStartOrderTerm: { start: parseISO('2022-02-07 21:00'), end: parseISO('2022-02-09 09:00') },
    stoppedSettlementOrderTerm: { start: parseISO('2022-02-09 09:00'), end: parseISO('2022-02-16 09:00') },
  },
};

export default mergeState(config[config.all.env], config.all);
