import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, map, filter } from 'rxjs/operators';
import { format } from 'date-fns';
import * as Sentry from '@sentry/browser';

//
import { AppState } from '../';
//
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { settlementActions } from './action-reducer';
import { errorDialogActions } from '../utils/dialog/error';

import { IOrderState, orderDeleteActions } from '../order';
import { ICustomer } from '../_type/customer';
import { IStaff } from '../_type/staff';
import { IPayment } from '../_type/payment';
import { IndexedObject } from '../../types';
import { IClothProduct, IAvailableOption, ISizeMeasurement, IPartsAdjustOption } from '../_type/lookups';
import { getNewOrderRequest } from '../../helpers/api/orders/conv-state2orderRequest';
import { postOrderRegist } from '../../services/orders/regist';
import { ApiError } from '../../models/error/api-error';
import { IError } from '../../types/error';
import { getValue } from '../../helpers/master-lookup';
import { IInformationDialog } from '../../types/dialog';
import { infoDialogActions } from '../utils/dialog/info';

import { ILocalState } from '../progress/state';
import { actions } from '../progress/actions';
import { actions as routerActions } from '../router/actions';
import { progressTypeCd } from '../progress/helper';
import { actions as ErrorHandlerActions } from '../../store/errorHandling/action';
import { orderDetailActions } from '../order-detail';
import config from '../../configuration/config';
import {
  ORDER_ERROR_MESSAGE,
  ORDER_ERROR_CODE_UNMATCH_DELIVERY_DATE,
  ORDER_ERROR_CODE_CLOTH_SEASON_UNDEFINED,
} from '../../lookups/master-thisisforreplaceall/order-error-message';
import { getPointPage } from '../payment/selector';
import { flattenJson } from '../../helpers';
import { getConfirmation } from '../order/selector/confirmation';
import { getAddress } from '../order/selector/address';
import { get as getOrder } from '../order';
import { TScProject } from '../../types/project';
import { IRegistCoupon } from '../_type/coupon';
import { postCouponRegist } from '../../services/coupon/regist';

type TRequestOrder = ReturnType<typeof getNewOrderRequest>[0];

/** 仮 */
const preCheck: Epic<
  AnyAction,
  Action<void | IInformationDialog | Parameters<typeof settlementActions.registOrders.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(settlementActions.preCheck.started),
    map(() => {
      return {
        isFilledItem: getOrder.hasFilledItem(state.value),
        isValidAllOrders: getConfirmation.isValidAllOrders(state.value),
        isAddressPageCompleted: getAddress.hasCompleted(state.value),
      };
    }),
    mergeMap(({ isFilledItem, isValidAllOrders, isAddressPageCompleted }) => {
      const isValid = [isFilledItem, isValidAllOrders, isAddressPageCompleted].every(v => !!v);
      if (!isValid) {
        const dialog: IInformationDialog = {
          hasOpen: true,
          title: '警告',
          contents: '不正なオーダーを検知したので、商品内容確認画面に遷移しました',
        };
        return [
          infoDialogActions.show._action(dialog), //
          routerActions.gotoItemConfirmation(), // 商品内容確認画面に遷移
        ];
      }
      return [settlementActions.registOrders.started({})];
    }),
  );

/**
 * 注文ごとの親製造番号を取得する
 * @param resData evansへの注文確定APIのレスポンス一覧
 */
const getSerialNumbers = (resData: any[]): string[] => resData.map(v => `${v.res.res.json.parentSerialNumber}`);

/**
 * アンバサダー連携用の合計金額を計算し、返却する
 * @param resData evansへの注文確定APIのレスポンス一覧
 */
const calcPurchaseAmount = (resData: any[]): number =>
  resData.map(v => Number(v.res.res.json.items[0].itemOrderAmountTaxin) || 0).reduce((acc, cur) => acc + cur, 0);

const registOrders: Epic<
  AnyAction,
  Action<
    | void
    | IError[]
    | IInformationDialog
    | Parameters<typeof settlementActions.registOrders.done>[0]
    | Parameters<typeof settlementActions.registOrders.failed>[0]
    | { error: ApiError; options: any }
    | { orderNumber: string }
    | Parameters<typeof actions.sendRegistApi.started>[0]
    | Parameters<typeof actions.update.done>[0]
    | Parameters<typeof settlementActions.registAmbassadorCoupon>[0]
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(settlementActions.registOrders.started),
    map(() => {
      const selector = appStateSelector(state.value);
      return {
        orderState: selector.orderState(),
        customer: selector.customer(),
        staff: selector.staff(),
        payment: selector.payment(),
        allProducts: selector.allProducts(),
        allAvailableOptions: selector.allAvailableOptions(),
        allSizeMeasurements: selector.allSizeMeasurements(),
        allAdjustOptions: selector.allAdjustOptions(),
        progress: selector.progress(),
        isOnlyDummyData: getPointPage.isOnlyDummyData(state.value),
        project: selector.selectedScProject(),
      };
    }),
    // precheckでチェックしてる
    map(obj => {
      const orderState = obj.orderState as IOrderState;
      const customer = obj.customer as ICustomer;
      const staff = obj.staff as IStaff;
      const payment = obj.payment as IPayment;
      const allProducts = obj.allProducts as IndexedObject<IClothProduct[]>;
      const allAvailableOptions = obj.allAvailableOptions as IndexedObject<IAvailableOption[]>;
      const allSizeMeasurements = obj.allSizeMeasurements as IndexedObject<ISizeMeasurement[]>;
      const allAdjustOptions = obj.allAdjustOptions as IndexedObject<IPartsAdjustOption[]>;
      const progress = obj.progress as ILocalState;
      const isOnlyDummyData = obj.isOnlyDummyData;
      const project = obj.project as TScProject;

      const requests = getNewOrderRequest(
        customer.memberscardNumber,
        staff,
        orderState,
        payment,
        project,
        allProducts,
        allAvailableOptions,
        allSizeMeasurements,
        allAdjustOptions,
      );

      return { requests, staff, payment, progress, isOnlyDummyData, allProducts, customer };
    }),
    mergeMap(async ({ requests, staff, payment, progress, isOnlyDummyData, allProducts, customer }) => {
      const resList: Array<{ orderNumber: string; body: TRequestOrder; res: any }> = [];
      // FIXME: 本当は並列させたかったけど、ローディングで不整合をおこすので。。。
      for (let i = 0; i < requests.length; i++) {
        const orderNumber = String(i + 1);
        const body = requests[i];
        const res = await postOrderRegist(body).catch(err => err);
        resList.push({
          orderNumber,
          body,
          res,
        });
      }
      return { resList, staff, payment, progress, isOnlyDummyData, allProducts, customer };
    }),
    mergeMap(({ resList, staff, payment, progress, isOnlyDummyData, allProducts, customer }) => {
      const apiErrors = resList.filter(v => v.res instanceof ApiError);
      const successResList = resList.filter(v => !(v.res instanceof ApiError));

      if (apiErrors.length > 0) {
        const apiError = apiErrors[0].res as ApiError; // 最初のエラーのみしか見ない(滅多にエラーにならない前提)
        const { orderNumber, body } = apiErrors[0];
        const { code, message } = apiError.errors[0];

        const contents = getValue(code, ORDER_ERROR_MESSAGE);

        // ステージング環境か本番環境かで表示するダイアログを変更する
        const errorDialogAction = config.isDev
          ? errorDialogActions.showErrors._action([
              {
                code: `${orderNumber}番目のオーダーでエラーが発生しました.`,
                message: `メッセージ: ${contents || ''}(${message}), ¥n送信した値: ${JSON.stringify(body)}`,
              },
            ])
          : ErrorHandlerActions.apiError({
              error: apiError,
              options: { title: `注文エラー【コード:${code}】`, contents },
            });

        // 成功した注文をキャンセルするアクション生成
        const cancelOrderActions = successResList.map(list => {
          return orderDetailActions.cancelOrder.started({
            orderNumber: list.res.query('parentSerialYear') + list.res.query('parentSerialNumber'),
          });
        });

        // Sentry
        if (!config.isLocal) {
          Sentry.withScope(scope => {
            const extraData = flattenJson(body);
            scope.setExtras({ request: { extraData } });
            if (code === ORDER_ERROR_CODE_CLOTH_SEASON_UNDEFINED) {
              const productData = flattenJson(allProducts);
              scope.setExtras({ products: { productData } });
            }
            const errorLevel =
              code === ORDER_ERROR_CODE_UNMATCH_DELIVERY_DATE ? Sentry.Severity.Warning : Sentry.Severity.Error;
            Sentry.captureMessage(`[${code}] 注文確定エラー メッセージ：${message}`, errorLevel);
          });
        }

        // 画面の遷移先を指定
        const routerAction =
          code === ORDER_ERROR_CODE_UNMATCH_DELIVERY_DATE
            ? routerActions.gotoItemConfirmation()
            : routerActions.gotoOrderConfirmation();

        return [
          errorDialogAction,
          settlementActions.registOrders.failed({
            params: {},
            error: [{ code: apiErrors[0].res.code, message: apiErrors[0].res.message }],
          }),
          ...cancelOrderActions,
          routerAction,
        ];
      }

      /**
       * アンバサダー連携用のデータを作成する
       */
      const ambassadorCouponData: IRegistCoupon = {
        id: staff.couponId || '',
        purchaseId: customer.memberscardNumber.toString(),
        storeId: staff.tempoCode,
        store: staff.tempoName,
        purchaseAmount: calcPurchaseAmount(resList),
        productId: getSerialNumbers(resList),
        purchaseDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };

      return [
            orderDeleteActions.deleteOrders(),
            routerActions.gotoSettlementEnd(),
            actions.deleteProgressData.started({
              progressId: `${staff.staffCode}-${progress.progressKey}`,
              progressName: progress.progressName,
              progressType: progressTypeCd.CANCEL,
              progressData: '',
            }),
            actions.update.done({ params: progress.progressName }),
            settlementActions.registOrders.done({ params: {}, result: { resList } }),
            settlementActions.registAmbassadorCoupon(ambassadorCouponData),
          ];
    }),
  );

const registAmbassadorCoupon: Epic<
  AnyAction,
  Action<
    void | { error: ApiError; options: any } | Parameters<typeof settlementActions.sendAmbassadorCoupon.started>[0]
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(settlementActions.registAmbassadorCoupon),
    map(({ payload }) => payload),
    filter(payload => !!payload.id),
    // couponIdがあるときのみ、送信を実行する
    map(payload => settlementActions.sendAmbassadorCoupon.started(payload)),
  );

const sendAmbassadorCoupon: Epic<
  AnyAction,
  Action<
    | void
    | { error: ApiError; options: any }
    | Parameters<typeof settlementActions.sendAmbassadorCoupon.done>[0]
    | Parameters<typeof settlementActions.sendAmbassadorCoupon.failed>[0]
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(settlementActions.sendAmbassadorCoupon.started),
    map(({ payload }) => payload),
    mergeMap(async payload => {
      const res = await postCouponRegist(payload).catch(err => err);
      return { res, payload };
    }),
    mergeMap(({ res, payload }) => {
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: 'アンバサダーへの連携に失敗しました。', title: 'アンバサダーエラー' },
          }),
          settlementActions.sendAmbassadorCoupon.failed({ params: payload, error: {} }),
        ];
      }
      return [settlementActions.sendAmbassadorCoupon.done({ params: payload, result: {} })];
    }),
  );

export const SettlementEpics = combineEpics(
  preCheck,
  registOrders,
  registAmbassadorCoupon,
  sendAmbassadorCoupon,
);
