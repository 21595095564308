import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { settlementActions } from '../store/settlement/action-reducer';
import { settlementPageEvents } from '../views/events/SettlementPage';
import { orderDeleteActions } from '../store/order';

const registOrders: Epic<AnyAction, Action<{}>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(settlementPageEvents.onRegistOrders),
    map(action => settlementActions.preCheck.started({})),
  );

const deleteOrders: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(settlementPageEvents.onDeleteOrders),
    map(action => orderDeleteActions.deleteOrders()),
  );

export const SettlementPageListener = combineEpics(registOrders, deleteOrders);
