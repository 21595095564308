import { createSelector } from 'reselect';
import { AppState } from '../../..';
import { ICustomerLoadDialog } from '../../../../types/dialog';

export { reducer as customerDialogReducer, actions as customerLoadDialogActions } from './action-reducer';

const featureSelector = (state: AppState): ICustomerLoadDialog => state.utils.dialog.customerLoad;

export const get = {
  data: createSelector(featureSelector, state => state),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
};
