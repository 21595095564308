/**
 *  specific code getter：特定の条件を満たすコードを返す関数を置く
 */
import {
  FOLLOW_TO_JACKET_OPTION_CONFIG,
  EMBROIDERY_TYPE_CONFIG,
  EMBROIDERY_POSITION_CONFIG,
  AUTO_SETTING_OPTIONS,
} from '../../lookups/item-thisisforreplaceall';
import { isModelSelectBoxOnDesignPartsSection } from './show-or-hide';
import { TCategory, TPartsNumber } from '../../lookups/master-thisisforreplaceall/';
import { by } from '..';
import { EEmbroideryType } from '../../types/option';
import { IOption } from '../../store/_type/order';
import { IOptionClass } from '../../store/_type/lookups';

/**
 * 対応するジャケットのoptionNumberを取得する
 */
export const getFollowToJacketOptionNumber = (optionNumber: string): string | undefined => {
  const match = FOLLOW_TO_JACKET_OPTION_CONFIG.find(v => v.optionNumber === optionNumber);
  return !match ? undefined : match.refJacketOptionNumber;
};

/**
 * パーツを足した場合に設定するデフォルトoptionPatternを取得
 * デフォルトが必要ない場合は undefinedを返却
 */
export const getDefaultOptionPattern4AddedPart = (
  category: TCategory,
  partsNumber: TPartsNumber,
  clothModelCode: string, // 生地選択画面で選択したモデル
  brandCode: string,
): string | undefined =>
  clothModelCode && !isModelSelectBoxOnDesignPartsSection(category, partsNumber, brandCode)
    ? clothModelCode
    : undefined;

/**
 * 刺繍ネームの字体を設定するoptionNumberを取得する
 */
export const getEmbroideryTypeOptionNumber = (partsNumber: TPartsNumber): string => {
  const config = EMBROIDERY_TYPE_CONFIG.find(by('partsNumber')(partsNumber));
  return config ? config.optionNumber : '';
};

/**
 * 刺繍位置を設定するoptionNumberを取得する
 */
export const getEmbroideryPositionOptionNumber = (partsNumber: TPartsNumber): string => {
  const config = EMBROIDERY_POSITION_CONFIG.find(by('partsNumber')(partsNumber));
  return config ? config.optionNumber : '';
};

/**
 * 刺繍字体を取得する
 */
export const getEmbroideryType = (option: IOption[], partsNumber: TPartsNumber): EEmbroideryType => {
  const typeOptionNumber = getEmbroideryTypeOptionNumber(partsNumber);
  const selectedOption = option.find(by('optionNumber')(typeOptionNumber));
  if (!selectedOption) {
    return EEmbroideryType.UNSELECTED;
  }
  const config = EMBROIDERY_TYPE_CONFIG.find(
    v =>
      v.partsNumber === partsNumber &&
      v.optionNumber === typeOptionNumber &&
      v.optionClassNumber === selectedOption.optionClassNumber,
  );
  return config ? config.EmbroideryType : EEmbroideryType.UNSELECTED;
};

/**
 * optionNumber, brand, optionPatternsの組み合わせでオプション自動設定のconfigからoptionClassNumberを取得する
 * 合致するのが複数あった場合、先頭のoptionClassNumberを返却する
 * オプション自動設定のconfigに合致する組み合わせがない場合、undefinedを返却する
 */
export const getAutoSettingOptionClassNumber = (
  settingConfig: typeof AUTO_SETTING_OPTIONS,
  optionNumber: string,
  brandCode: string,
  optionPatterns: string[],
  optionClasses: IOptionClass[],
) => {
  const match = settingConfig.find(
    ({ optionNumber: targetOptionNumber, hasMatchedBrand, hasMatchedModels, optionClassNumber }) =>
      targetOptionNumber === optionNumber &&
      hasMatchedBrand(brandCode) &&
      hasMatchedModels(optionPatterns) &&
      !!optionClasses.find(by('optionClassNumber')(optionClassNumber)),
  );
  return match ? match.optionClassNumber : undefined;
};
