import React from 'react';

// import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './Spinner.module.scss';
import config from '../../../configuration/config';

interface IProps {
  isLoading?: boolean;
}

const Spinner: React.FC<IProps> = ({ isLoading }) => {
  return (
    <div className={isLoading ? styles.isLoading : styles.spinner}>
      {/* <CircularProgress />*/}
      <div className={styles.img}>
        <img src={`${config.baseUrl}/images/common/img-loading.svg`} alt="loading" />
      </div>
    </div>
  );
};

export default Spinner;
