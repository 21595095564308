import React from 'react';
// import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
import Button from '../../atoms/Button/Button';

import styles from './NumberWithButton.module.scss';

import Logger from '../../../helpers/common/logger';
import NumberFormat from 'react-number-format';
import { langResource } from '../../../i18n/_new/resources';
import { roundDegit } from '../../../helpers';
import { IInformationDialog } from '../../../types/dialog';

interface IProps {
  name: string;
  value: number;
  step?: number;
  max?: number;
  min?: number;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string | undefined;
  onFocus: (value: string | number | undefined) => void;
  onValueChanged: (value: number) => void;
  showDialog: (payload: IInformationDialog) => void;
  canInputKeyboard?: boolean;
  contextCannotInputKeyboard?: string;
}

const NumberWithButton: React.FC<IProps> = ({
  name,
  value,
  step,
  max,
  min,
  disabled,
  className,
  onValueChanged,
  onFocus,
  showDialog,
  canInputKeyboard = true,
  contextCannotInputKeyboard,
}) => {
  const allowNegative = false;
  if (!allowNegative && value < 0) {
    changeValue(0);
  }
  return (
    <div className={styles.inputWrapper}>
      <div className={styles.input}>
        <NumberFormat
          readOnly={true}
          value={value}
          allowNegative={allowNegative}
          // decimalScale={1} FIXME: stateが反映されないので一時的にコメントアウト
          onFocus={onInputFocus}
          disabled={disabled}
          className={className}
        />
      </div>
      <div className={styles.button}>
        <Button
          styles={['numeric', 'black']}
          textObj={langResource.components.numberWithButton.minus}
          onClick={onHandleValueChangedMinus}
          disabled={disabled}
        />
      </div>
      <div className={styles.button}>
        <Button
          styles={['numeric', 'black']}
          textObj={langResource.components.numberWithButton.plus}
          onClick={onHandleValueChangedAdd}
          disabled={disabled}
        />
      </div>
    </div>
  );

  function canInput(numberInput: number): boolean {
    Logger.log('canInput', [numberInput, max, min]);
    if (max && numberInput > max) {
      return false;
    }

    if (min && numberInput < min) {
      return false;
    }

    return true;
  }

  function getStep(): number {
    const numberStep = Number(step);
    return !numberStep || numberStep === 0 ? 1 : numberStep;
  }

  function onInputFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (canInputKeyboard) {
      fireOnFocus(value);
      return;
    }

    // キーボード入力不可のとき
    // 先にblurしないとモーダル閉じた後に再度focusが当たってしまう
    e.target.blur();
    onShowDialogCannotInputKeyboard();
  }
  function fireOnFocus(newValue: string | number | undefined = '') {
    onFocus(newValue);
  }

  function onHandleValueChangedAdd() {
    changeValue(value + getStep());
  }

  function onHandleValueChangedMinus() {
    changeValue(value - getStep());
  }

  function changeValue(val: number) {
    const newValue = roundDegit(val);
    if (!canInput(newValue)) {
      return onHandleError(newValue);
    }
    // TODO: fireOnFocusを呼ばなきゃいけないのは、、、、CustomKeyboardHocを修正しよう！
    onValueChanged(newValue);
    if (canInputKeyboard) {
      fireOnFocus(newValue);
    }
  }

  function onHandleError(numberInput: number) {
    showDialog({
      hasOpen: true,
      title: '警告',
      contents: `${numberInput} は ${name} の上限値/下限値を超えています.`,
    });
  }

  function onShowDialogCannotInputKeyboard() {
    showDialog({
      hasOpen: true,
      title: '確認',
      contents: contextCannotInputKeyboard || '+/- ボタンの変更のみ可能です.',
    });
  }
};

export default NumberWithButton;
