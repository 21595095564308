import { addressPage } from './addressPage';
import { pointPage } from './pointPage';
import { clothPage } from './clothPage';
import { sizePage } from './sizePage';

export const confirmationPage = {
  pointPostFix: { jp: 'pt' },
  memberscardNumber: { jp: '会員番号' },
  cutterNameKana: { jp: '担当者番号' },
  lotNumber: { jp: '照合番号' },
  storeName: { jp: '店名' },
  staffName: { jp: '担当' },
  editBtn: { jp: '変更' },
  copyBtn: { jp: '複製' },
  deleteBtn: { jp: '削除' },
  memo: { jp: '備考' },

  // titles
  clothTitle: { jp: '生地' },
  designOptionTitle: { jp: 'デザイン・オプション' },
  sizeTitle: { jp: '仕上がり寸法' },
  designAndSizeTitle: { jp: 'デザイン・オプション / 仕上がり寸法' },

  // subtitles
  designTitle: { jp: 'デザイン' },
  specialOptionTitle: { jp: '特殊オプション' },
  sizeMeasurementTitle: { jp: '仕上がり寸法' },
  specialAdjustTitle: { jp: '特殊補正' },

  // cloth（現在は文言が一致するので、clothPageを参照している）
  /** 生地品番 */
  clothCode: clothPage.clothCode,
  /** textileNo */
  textileNo: clothPage.textileNo,
  /** ブランド */
  brand: clothPage.brand,
  /** モデル */
  model: clothPage.model,
  /** 色 */
  clothColor: clothPage.clothColor,
  /** 柄 */
  clothDesign: clothPage.clothDesign,
  /** 生地組成 表地(素材/%) */
  compositionFront: clothPage.compositionFront,
  /** 生地組成 裏地(素材/%) */
  compositionBack: clothPage.compositionBack,
  /** 着分区分 */
  stockPlace: clothPage.stockPlace,
  /** 要尺 */
  scale: clothPage.scale,

  // size
  /** マスターサイズ */
  masterSize: { jp: 'マスターサイズ' },
  /** 差分 */
  diff: sizePage.diff,

  // お客様情報（現在は文言が一致するので、addressPageを参照している）
  customerTitle: { jp: 'お客様情報' },
  deliveryMethod: addressPage.deliveryMethod,
  deliveryPlace: addressPage.deliveryPlace,
  address: addressPage.address,
  custermerNameKana: addressPage.custermerNameKana,
  custermerName: addressPage.custermerName,
  phoneNumber: addressPage.phoneNumber,
  email: addressPage.email,
  deliveryDate: { jp: '納期' },
  dayTime: addressPage.dayTime,
  deliveryFee: addressPage.deliveryFee,

  // アイテム
  item: { jp: 'アイテム' },
  appointedDay: { jp: '納期' },
  totalCost: { jp: '支払い総額' },
  basePrice: { jp: '本体価格' },
  productPrice: { jp: '商品金額' },
  optionPrice: { jp: 'オプション金額' },
  productTotal: { jp: '商品合計' },

  // お支払
  payment: { jp: 'お支払' },
  pointUsage: pointPage.pointUsage,
  /** 利用ポイント */
  usePoints: pointPage.usePoints,
  useablePoints: pointPage.useablePoints,
  backPoints: pointPage.backPoints,
  campaignCoupon: pointPage.campaignCoupon,
  use: { jp: '利用する' },
  noUse: { jp: '利用しない' },
  order: { jp: 'オーダー' },
  /** 注文内容 */
  orderContent: pointPage.order,
  /** 商品合計 */
  itemTotal: pointPage.item,
  /** 送料 */
  shippingCost: pointPage.shippingCost,
  /** 決済手数料 */
  settlementCost: pointPage.settlementCost,
  other: { jp: 'その他' },
  introducer: pointPage.introducer,
  no: pointPage.no,
  yes: pointPage.yes,
  distribution: pointPage.distribution,
  store: pointPage.store,
  visit: pointPage.visit,
  smartregi: { jp: 'スマレジ連携' },
  useSmartregi: { jp: 'する' },
  nouseSmartregi: { jp: 'しない' },
  serialNo: { jp: '製造No' },
  orderDate: { jp: '受注日' },
  productKind: { jp: 'オーダー区分' },
  invoice: { jp: '請求書' },
  needInvoice: { jp: 'あり' },
  noNeedInvoice: { jp: 'なし' },
  // message
  rightAndLeftInseamDifferent: { jp: '股下が左右違います。' },
};
