import { IApiState } from '../../types/size-correction';
import { EParts } from '../master-thisisforreplaceall/parts';

export * from './nude-size';

export const INITIAL_API_STATE: IApiState = {
  sizeMeasurement: {
    req: {
      path: {
        brandCode: '',
      },
      query: {},
    },
    res: {
      measurement: [],
    },
    updateAt: new Date(),
  },
  standardSize: {
    req: {
      path: {
        brandCode: '',
        partsNumber: EParts.jaket,
        modelCode: '',
        gauge: '',
      },
    },
    res: {
      standardSize: [],
    },
    updateAt: new Date(),
  },
  recommendGauge: {
    req: {
      path: {
        brandCode: '',
      },
      body: {
        parts: [],
        nudeSizes: [],
      },
    },
    res: {
      parts: [],
    },
    updateAt: new Date(),
  },
  sizeAdjustOptions: {
    req: {
      path: {
        brandCode: '',
        itemCode: '',
        seasonCode: '',
        partsNumber: EParts.jaket,
        optionPattern: '',
      },
      query: {},
    },
    res: {
      adjustOptions: [],
    },
    updateAt: new Date(),
  },
  // FIXME: 履歴は一旦置いておく
  nudeDememsionHistory: {
    req: {},
    res: {},
    updateAt: new Date(),
  },
  sizeMeasurementHistory: {
    req: {},
    res: {},
    updateAt: new Date(),
  },
};
