import { ERouterPath } from '../types';
import { IStep } from '../types/stepbar';
import { langResource } from '../i18n/_new/resources/';

export const ITEM_STEPS: IStep[] = [
  {
    order: 0,
    textkey: langResource.stepbar.closeSelection,
    path: ERouterPath.clothSelection,
  },
  {
    order: 1,
    textkey: langResource.stepbar.designSelection,
    path: ERouterPath.designSelection,
  },
  {
    order: 2,
    textkey: langResource.stepbar.sizeCorrection,
    path: ERouterPath.sizeCorrection,
  },
];

export const ORDER_STEPS: IStep[] = [
  {
    order: 0,
    textkey: langResource.stepbar.itemConfirmation,
    path: ERouterPath.itemConfirmation,
  },
  {
    order: 1,
    textkey: langResource.stepbar.address,
    path: ERouterPath.address,
  },
  {
    order: 2,
    textkey: langResource.stepbar.point,
    path: ERouterPath.amount,
  },
];
