import { thunkActionErrorHandler } from '../../../helpers/error-handler';
import { thunkActionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { actions as orderAction } from '../../order/action-reducer';
import { clothProductActions } from '../../lookups/product/action-reducer';
import { availableOptionActions, adjustOptionActions, recommendedGaugeActions } from '../../lookups';
import { sizeMeasurementActions } from '../../lookups/size-measurement/action-reducer';
import { IndexedObject } from '../../../types';
import { IOrder } from '../../_type/order';

// asyncAction
const actionTitle = 'common page';
const asyncAc = thunkActionObjectCreatorFactory();
export const commonPageActions = {
  deleteOrder: asyncAc<{ orderNumber: string }, any>(`[${actionTitle}] deleteOrder`, async (payload, dispatch) => {
    try {
      const { orderNumber } = payload;
      // オーダーの削除
      orderAction.delete.dispatcher(dispatch)(orderNumber);
      // lookupsの削除
      clothProductActions.delete.dispatcher(dispatch)(orderNumber);
      availableOptionActions.delete.dispatcher(dispatch)(orderNumber);
      adjustOptionActions.delete.dispatcher(dispatch)(orderNumber);
      recommendedGaugeActions.delete.dispatcher(dispatch)(orderNumber);
      sizeMeasurementActions.delete.dispatcher(dispatch)(orderNumber);
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
  deleteOrders: asyncAc<{ orders: IndexedObject<IOrder> }, any>(
    `[${actionTitle}] deleteOrders`,
    async (payload, dispatch) => {
      try {
        Object.keys(payload.orders).forEach(orderNumber => {
          // オーダーの削除
          orderAction.delete.dispatcher(dispatch)(orderNumber);
          // lookupsの削除
          clothProductActions.delete.dispatcher(dispatch)(orderNumber);
          availableOptionActions.delete.dispatcher(dispatch)(orderNumber);
          adjustOptionActions.delete.dispatcher(dispatch)(orderNumber);
          recommendedGaugeActions.delete.dispatcher(dispatch)(orderNumber);
          sizeMeasurementActions.delete.dispatcher(dispatch)(orderNumber);
        });
      } catch (ex) {
        return thunkActionErrorHandler(ex, dispatch);
      }
    },
  ),
};
