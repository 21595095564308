import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../lib/action-creator-factory';
import { ICustomer } from '../_type/customer';
import { INITIAL_CUSTOMER } from './initial-state';
import actionCreatorFactory from 'typescript-fsa';

// actions
const actionTitle = 'Customer';
const ac = actionObjectCreatorFactory(actionTitle);
export const actions = {
  loadRequest: ac(`[${actionTitle}] loadRequest`),
  loadSuccess: ac<ICustomer>(`[${actionTitle}] loadSuccess`),
  // MEMO: CustomerLoad時だけ特殊ローディングなので。
  loadFailture: ac(`[${actionTitle}] loadFailture`),
};

export interface ILoadCustomerParam {
  memberscardNumber: string;
  isLoadAddress?: boolean; // trueの場合order/shippingに顧客情報を入れるアクションをdispatch
  onClose?: () => void;
}

const actionCreator = actionCreatorFactory('[Customer]');
// 流用時の顧客ログイン用
export const customerAction = {
  login: actionCreator.async<ILoadCustomerParam, {}, {}>('login'),
};

// reducer
export interface ICustomerState {
  data: ICustomer;
  isLoaded: boolean;
  isLoading: boolean;
}

export const initialState: ICustomerState = {
  data: { ...INITIAL_CUSTOMER },
  isLoaded: false,
  isLoading: false,
};

export const customerReducer = reducerWithInitialState(initialState)
  .case(actions.loadRequest._action, state => ({ ...state, isLoading: true }))
  .case(actions.loadSuccess._action, (state, payload) => ({
    data: { ...payload },
    isLoaded: true,
    isLoading: false,
  }))
  .case(actions.loadFailture._action, state => ({ ...initialState }));
