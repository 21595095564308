import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

const ac = actionCreatorFactory('- optionGroup');

const actions = {
  selectGroupId: ac<{ optionNumber: string; optionGroupId: string }>('selectGroupId'),
  clearGroupId: ac<void>('clearGroupId'),
};
export const optionGroupAction = actions;

const initialState: { [optionNumber: string]: string } = {};

const reducer = reducerWithInitialState(initialState)
  .case(actions.selectGroupId, (state, { optionNumber, optionGroupId }) => ({
    ...state,
    [optionNumber]: optionGroupId,
  }))
  .case(actions.clearGroupId, state => ({}));
export const optionGroupReducer = reducer;
