import { cloneDeep } from 'lodash';

import { IItem } from '../../store/_type/order';
import { IClothProduct, IAvailableOption, IPartsAdjustOption } from '../../store/_type/lookups';
import { INITIAL_PRODUCT_STATE } from '../../store/lookups/product/initial-state';
import { by } from '..';
import { TItem } from '../api/converter/order-detail';
import { getOptionType } from '../item-thisisforreplaceall';
import { EOptionType } from '../../types/option';

export const toClothProduct = (item: IItem, serverItem: TItem, taxRate: string): IClothProduct => {
  const { itemOrderPrice, parts, requiredScale, clothSeason, productSeason } = serverItem;
  const { itemCode, pieces } = item;
  const { brandCode, seasonCode, design, clothCode, vendorClothNumber, compositionFront, compositionBack } = item.cloth;

  const partsModelPatterns = pieces.map(({ partsNumber, index }) => {
    const { modelPattern } = Object.entries(item.design.designParts)
      .map(([partsIndex, partsDesign]) => ({
        partsIndex,
        ...partsDesign,
      }))
      .find(by('partsIndex')(index)) || { modelPattern: '' };

    const { modelCode, modelName } = parts.find(v => v.partsNumber === partsNumber) || { modelName: '', modelCode: '' };

    return {
      partsNumber,
      modelPatterns: [{ modelPattern, models: [{ modelCode, modelName, useScale: +requiredScale }] }],
    };
  });

  const rate = 1 + +taxRate / 100; // 商品の税込額がないので
  const retailPrice = itemOrderPrice;
  const retailPriceTaxin = String(Math.floor(+itemOrderPrice * rate));
  return {
    ...cloneDeep(INITIAL_PRODUCT_STATE),
    partsModelPatterns,
    retailPrice,
    retailPriceTaxin,
    brandCode,
    itemCode,
    seasonCode,
    design,
    clothCode,
    vendorClothNumber,
    clothSeasonCode: clothSeason,
    productSeasonCode: productSeason,
    mixings: {
      front: compositionFront,
      back: compositionBack,
    },
  };
};

// export const INITIAL_PRODUCT_STATE: IClothProduct = {
//   brandCode: '',                   item.cloth.brandCode
//   itemCode: '',                    item.itemCode
//   productSeasonCode: '',           // いらない
//   personalorderProductNumber: '',  // いらない
//   personalorderColorCode: '',      // いらない
//   seasonCode: '',                  item.cloth.seasonCode
//   seasonName: '',                  // いらない
//   personalorderColorName: '',      // いらない
//   retailPrice: '',                 注文詳細で取得した値
//   retailPriceTaxin: '',            注文詳細で取得した値
//   partsModelPatterns: [            やっぱいるのでデザインから作成する
//     {
//       partsNumber: EParts.jaket,
//       modelPatterns: [],
//     },
//   ],
//   design: '',                      item.cloth.design
//   designName: '',                  // いらない
//   clothName: '',                   // いらない
//   clothSeason: '',                 item.cloth.seasonCode
//   clothCode: '',                   item.cloth.clothCode
//   vendorClothNumber: '',           item.cloth.vendorClothNumber
//   mixings: {                       item.cloth.composition~
//     front: [],
//     back: [],
//   },
//   isnew: '',                       // いらない
//   issoldout: '',                   // いらない
//   makerName2: '',                  // いらない
//   functionName: '',                // いらない
//   clothBrandCode: '',              // いらない
// };

export const toAvailableOptions = (serverItem: TItem): IAvailableOption[] => {
  const { parts } = serverItem;
  return parts.map(({ partsNumber, partsName, optionPattern, options }) => {
    const patterns = options
      .map(v => ({ ...v, optionType: getOptionType(v.optionNumber) }))
      .filter(({ optionType }) => [EOptionType.DESIGN_OPTION, EOptionType.FREE_TEXT_OPTION].includes(optionType))
      .map(({ optionNumber, optionName, optionClassNumber, optionClassName }) => ({
        optionPattern,
        optionPatternName: '',
        optionNumber,
        optionName,
        isRequired: true,
        isTakeoverability: false,
        isSpecial: false,
        optionClasses: [
          {
            optionClassNumber,
            optionClassName,
            retailPrice: 99,
            retailPriceTaxin: 99,
            isDefault: true,
            imagePath: '',
          },
        ],
      }));

    return {
      partsNumber,
      partsName,
      optionPatterns: patterns,
    };
  });
};

// export interface IAvailableOption {
//   partsNumber: TPartsNumber;
//   partsName: string;
//   optionPatterns: IOptionPattern[];
// }

// export interface IOptionPattern {
//   optionNumber: string;
//   optionName: string;
//   isSpecial: boolean;
//   isRequired: boolean;
//   optionClasses: IOptionClass[];
//   // ・・・
//   isTakeoverability: boolean;
//   optionPattern: string;
//   optionPatternName: string;
// }

// export interface IOptionClass {
//   optionClassNumber: string;
//   optionClassName: string;
//   isDefault: boolean;
//   retailPrice: number;
//   retailPriceTaxin: number;
//   imagePath: string;
// }

export const toPartsAdjustOption = (serverItem: TItem): IPartsAdjustOption[] | undefined => {
  const { parts } = serverItem;
  const partsOptions = parts
    .map(({ partsNumber, options }) => {
      const adjustOptions = options
        .filter(({ optionNumber }) => getOptionType(optionNumber) === EOptionType.ADJUST_SIZE_OPTION)
        .map(({ optionNumber, optionName, optionClassNumber, optionClassName }) => ({
          optionNumber,
          optionName,
          isSpecialOption: true,
          optionClasses: [
            {
              optionClassName,
              optionClassNumber,
            },
          ],
        }));
      return {
        partsNumber,
        adjustOptions,
      };
    })
    .filter(({ adjustOptions }) => adjustOptions.length > 0);

  return partsOptions.length > 0 ? partsOptions : undefined;
};
