import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { IPartsAdjustOption, TAdjustOptionPathParam } from '../../_type/lookups';
import { cloneDeep } from 'lodash';
import actionCreatorFactory from 'typescript-fsa';
import { IItem } from '../../_type/order';
import { TOrderDetail } from '../../../helpers/api/orders/conv-state-reverse';

// actions
const ac = actionObjectCreatorFactory('Adjust Options');

export interface ISetting {
  orderNumber: string;
  adjustOptions: IPartsAdjustOption[];
  /** MEMO: 複数まとめてリクエストしているので、配列です */
  requestParam: TAdjustOptionPathParam[];
}

export interface IGetAdjustOptionParams {
  orderNumber: string;
  item?: IItem;
}

export const adjustOptionActions = {
  set: ac<ISetting>(`set AdjustOptions`),
  delete: ac<string>(`delete AdjustOptions`),
  copy: ac<{ newOrderNumber: string; orderNumber: string }>(`copy AdjustOptions`),
};

const asyncActionCreator = actionCreatorFactory('Adjust Options');

export const adjustOptionAsyncActions = {
  loadData: asyncActionCreator.async<IGetAdjustOptionParams, {}, {}>('loadData'),
  loadDetailData: asyncActionCreator.async<IGetAdjustOptionParams & { orderDetail: TOrderDetail }, {}, {}>(
    'loadDetailData',
  ),
};

// reducer
export interface IAdjustOptionState {
  adjustOptions: {
    [orderNumber: string]: IPartsAdjustOption[];
  };
  requestParams: {
    [orderNumber: string]: TAdjustOptionPathParam[];
  };
}

const initialState: IAdjustOptionState = {
  adjustOptions: {},
  requestParams: {},
};

export const adjustOptionReducer = reducerWithInitialState(initialState)
  .case(adjustOptionActions.set._action, (state, payload) => {
    const newObj = { ...state.adjustOptions };
    const newParam = { ...state.requestParams };
    newObj[payload.orderNumber] = payload.adjustOptions;
    newParam[payload.orderNumber] = payload.requestParam;
    return {
      ...state,
      adjustOptions: { ...newObj },
      requestParams: { ...newParam },
    };
  })
  .case(adjustOptionActions.delete._action, (state, payload) => {
    const newObj = { ...state.adjustOptions };
    const newParam = { ...state.requestParams };
    delete newObj[payload];
    delete newParam[payload];
    return {
      ...state,
      adjustOptions: newObj,
      requestParams: newParam,
    };
  })
  .case(adjustOptionActions.copy._action, (state, payload) => {
    const { newOrderNumber, orderNumber } = payload;
    const targetObj = state.adjustOptions[orderNumber];
    const targetParam = state.requestParams[orderNumber];
    if (!targetObj || !targetParam) {
      return { ...state };
    }
    const newObj = cloneDeep(targetObj);
    const newParam = cloneDeep(targetParam);
    return {
      ...state,
      adjustOptions: {
        ...state.adjustOptions,
        [newOrderNumber]: newObj,
      },
      requestParams: {
        ...state.requestParams,
        [newOrderNumber]: newParam,
      },
    };
  });
