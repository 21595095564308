import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { CouponInfoDialogEvents } from '../views/events/CouponInfoDialog';
import { couponInfoDialogActions } from '../store/utils/dialog/coupon-info';
import { WrapAction } from '../store/_type';

const show: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(CouponInfoDialogEvents.show),
    map(({ payload }) => couponInfoDialogActions.show()),
  );

const close: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(CouponInfoDialogEvents.close),
    map(({ payload }) => couponInfoDialogActions.close()),
  );

const load: Epic<AnyAction, WrapAction<typeof couponInfoDialogActions.load.started>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(CouponInfoDialogEvents.load),
    map(action => couponInfoDialogActions.load.started({})),
  );

export const CouponInfoDialogListener = combineEpics(show, close, load);
