import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, map, filter } from 'rxjs/operators';

import { AppState } from '../';
import { orderDeleteActions, orderActions } from '.';
import { clothProductActions } from '../lookups/product/action-reducer';
import { availableOptionActions, adjustOptionActions, recommendedGaugeActions } from '../lookups';
import { sizeMeasurementActions } from '../lookups/size-measurement/action-reducer';

import { appStateSelector } from '../../helpers/object-selector/app-state';
import { IndexedObject } from '../../types';
import { IOrder } from '../_type/order';
import { paymentActions } from '../payment';
import { IOrderDetailState, orderDetailActions } from '../order-detail/action-reducers';
import { scProjectActions } from '../sc-project';

const deleteOrder: Epic<AnyAction, Action<void | string>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(orderDeleteActions.deleteOrder),
    mergeMap(({ payload }) => {
      const { orderNumber } = payload;
      return [
        orderActions.delete._action(orderNumber),
        clothProductActions.delete._action(orderNumber),
        availableOptionActions.delete._action(orderNumber),
        adjustOptionActions.delete._action(orderNumber),
        recommendedGaugeActions.delete._action(orderNumber),
        sizeMeasurementActions.delete._action(orderNumber),
        paymentActions.delete._action(),
        scProjectActions.reset(),
      ];
    }),
  );
// TODO: stateをクリアする時にloginActions.clearCurrentProgressを使うとhome画面に戻ってしまうため、戻りたくない場合に現在はこちらを使っている。
const deleteOrders: Epic<AnyAction, Action<{ orderNumber: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(orderDeleteActions.deleteOrders),
    map(({ payload }) => {
      const selector = appStateSelector(state.value);
      return {
        orders: selector.orders(),
      };
    }),
    filter(v => v.orders !== undefined && Object.keys(v.orders).length > 0),
    mergeMap(obj => {
      const orders = obj.orders as IndexedObject<IOrder>;
      return Object.keys(orders).map(orderNumber => orderDeleteActions.deleteOrder({ orderNumber }));
    }),
  );

/** 注文修正で作成したデータを削除する */
const deleteEditOrders: Epic<AnyAction, Action<string | void> | Action<{ orderNumber: string }>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(orderDeleteActions.deleteEditOrders),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderDetail = appStateObj.orderDetail();
      const currentPayment = appStateObj.paymentState();
      const isEdit = appStateObj.isEditOrder();
      return { payload, orderDetail, isEdit, currentPayment };
    }),
    filter(
      ({ orderDetail, isEdit }) => orderDetail !== undefined && Object.keys(orderDetail.orders).length > 0 && !isEdit,
    ),
    mergeMap(obj => {
      const { orders } = obj.orderDetail as IOrderDetailState;
      const actions = Object.keys(orders).map(orderNumber => orderDeleteActions.deleteOrder({ orderNumber }));
      return [...actions, orderDetailActions.deleteDetail()];
    }),
  );

export const OrderEpics = combineEpics(deleteOrder, deleteOrders, deleteEditOrders);
