import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../../lib/action-creator-factory';
import { ICustomerLoadDialog } from '../../../../types/dialog';

// action
const ac = actionObjectCreatorFactory();

const actionTitle = 'Customer Load Dialog';

export const actions = {
  show: ac<ICustomerLoadDialog>(`[${actionTitle}] show`),
  close: ac(`[${actionTitle}] close`),
};

// reducer
export const initialState: ICustomerLoadDialog = {
  hasOpen: false,
  hasError: false,
  isLoading: false,
  errorMessage: '',
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.show._action, (state, payload) => ({ ...payload }))
  .case(actions.close._action, state => ({ ...initialState }));
