import React, { Component } from 'react';

import styles from './GaugeSelector.module.scss';
import SelectBox from '../SelectBox/SelectBox';
import Button from '../Button/Button';
import { ILookupItem } from '../../../types/lookup';
import { IGauge } from '../../../store/_type/order';
import { distinctArray } from '../../../helpers';

interface IProps {
  selectingGauge: IGauge;
  selectableGauges: IGauge[];
  onClose: () => void;
  onSelected: (gauge: IGauge) => void;
}

type TState = Pick<IProps, 'selectingGauge' | 'selectableGauges'>;

const initialLookups: ILookupItem = {
  id: -1,
  value: '',
};

export class GaugeSelector extends Component<IProps, TState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectingGauge: props.selectingGauge,
      selectableGauges: props.selectableGauges,
    };
  }

  render() {
    const { major, minor } = this.state.selectingGauge;
    const majorItems = this.getMajorGauges();
    const minorItems = this.getMinorGauges();

    return (
      <div className={styles.popup}>
        <div className={styles.select}>
          <SelectBox
            data={majorItems}
            disabled={majorItems?.length < 2} // MEMO:選択肢の1つ目に必ずブランクが入るため
            onValueChanged={this.onSelectedMajor}
            selectedOption={this.getLookupId(majorItems, major)}
          />
        </div>
        <div className={styles.select}>
          <SelectBox
            data={minorItems}
            disabled={minorItems?.length < 2} // MEMO:選択肢の1つ目に必ずブランクが入るため
            onValueChanged={this.onSelectedMinor}
            selectedOption={this.getLookupId(minorItems, minor)}
          />
        </div>
        <div className={styles.button}>
          <Button styles={['size-xs', 'black', 'block']} onClick={this.onSelected}>
            決定
          </Button>
        </div>
        <div className={styles.closeButton} onClick={this.props.onClose}>
          ×
        </div>
      </div>
    );
  }

  onSelected = () => {
    this.props.onSelected(this.state.selectingGauge);
  };

  onSelectedMajor = (item: ILookupItem) => {
    this.setState({
      selectingGauge: {
        ...this.state.selectingGauge,
        major: item.value,
      },
    });
  };

  onSelectedMinor = (item: ILookupItem) => {
    this.setState({
      selectingGauge: {
        ...this.state.selectingGauge,
        minor: item.value,
      },
    });
  };

  toTLookup = (id: number, value: string): ILookupItem => {
    return { id, value };
  };

  getMajorGauges = (): ILookupItem[] => {
    const allGauges = this.props.selectableGauges;
    // ゲージのmajorが空の場合は配列から除く(initialLookupsでvalueが空のlookupを入れているため)
    const majorGauges = distinctArray(allGauges.map(v => v.major).filter(v => v !== ''));
    return [initialLookups, ...majorGauges.map((v, i) => this.toTLookup(i, v))];
  };

  getMinorGauges = (): ILookupItem[] => {
    const { major } = this.state.selectingGauge;
    const allGauges = this.props.selectableGauges;
    // ゲージのminorが空の場合は配列から除く(initialLookupsでvalueが空のlookupを入れているため)
    return [
      initialLookups,
      ...allGauges.filter(v => v.major === major && v.minor !== '').map((v, i) => this.toTLookup(i, v.minor)),
    ];
  };

  getLookupId = (items: ILookupItem[], value: string) => {
    const matched = items.find(v => v.value === value);
    if (!matched) {
      return initialLookups.id;
    }
    return matched.id;
  };
}

export default GaugeSelector;
