import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './SizeCorrectionPage.module.scss';

import { TPageContainerProps } from '../../../containers/pages/SizeCorrectionPage';

import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import SizeAdjustSection from '../../organisms/SizeAdjustSection/SizeAdjustSection';
import Footer from '../../organisms/Footer/Footer';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import {
  generateBackHomeBtnFunc,
  generateSaveTempBtnFunc,
  generateCancelModifiedOrderBtnFunc,
} from '../../../helpers/components/footer';
import { langResource } from '../../../i18n/_new/resources';
import { getOrderDetailPath } from '../../../helpers/common/path';

type TProps = TPageContainerProps & RouteComponentProps & ICustomKeyboardInjectedProps;

class SizeCorrectionPage extends Component<TProps> {
  componentDidMount() {
    const { onLoadInitialize } = this.props;
    onLoadInitialize();
  }

  render() {
    const { props } = this;
    return (
      <React.Fragment>
        <div onFocus={props.checkFocusForNumericKeyboard} id={styles.container}>
          <div className={styles.sideAdjust}>
            <SizeAdjustSection {...props} />
          </div>
        </div>
        {this.utility()}
      </React.Fragment>
    );
  }

  utility() {
    // const { props } = this;
    return (
      <React.Fragment>
        {/* <LoadingInfo isLoading={prop.isLoading} displayType="absolute" /> */}
        <Footer buttons={this.getFooterButtons()} />
      </React.Fragment>
    );
  }

  getFooterButtons(): IFooterButton[] {
    const { props } = this;
    return [
      {
        type: 'home',
        func: !props.isEdit
          ? generateBackHomeBtnFunc(props.history, props.orderNumber, props.onDeleteOrder, props.onShowOkCancelDialog)
          : generateCancelModifiedOrderBtnFunc(
              props.history,
              props.serial,
              props.cancelModifiedOrder,
              props.onShowOkCancelDialog,
            ),
        textObj: !props.isEdit ? langResource.footer.home : langResource.footer.cancelEditOrder,
      },
      {
        type: 'save',
        func: generateSaveTempBtnFunc(props.onShowSaveTmpOrderDialog),
        isRender: !props.isEdit,
      },
      {
        type: 'back',
        path: ERouterPath.designSelection,
        isRender: !props.isEdit,
      },
      {
        type: 'next',
        isDisabled: !props.hasCompleted || !props.requiredCheckCompleted,
        path: ERouterPath.itemConfirmation,
        isRender: !props.isEdit,
      },
      {
        type: 'confirm',
        isDisabled: !props.hasConfirmItems || !props.requiredCheckCompleted,
        textObj: !props.isEdit ? langResource.footer.order : langResource.footer.orderContentsEdit,
        path: !props.isEdit ? ERouterPath.itemConfirmation : undefined,
        func: !props.isEdit ? undefined : () => props.history.push(getOrderDetailPath(props.serial)),
      },
    ];
  }
}
export default withRouter(SizeCorrectionPage);
