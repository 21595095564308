export const orderDigestsSearchPage = {
  houjinCd: {
    jp: '法人Cd',
  },
  houjinName: {
    jp: '法人名',
  },
  serialNumber: {
    jp: '製造NO',
  },
  orderDate: {
    jp: '注文日',
  },
  memberscardNumber: {
    jp: '会員番号',
  },
  nameKana: {
    jp: '氏名(カナ)',
  },
  itemCode: {
    jp: 'アイテム',
  },
  brandCode: {
    jp: 'ブランド',
  },
  seasonCode: {
    jp: '期別',
  },
  productNumber: {
    jp: '品番',
  },
  tempoName: {
    jp: '店舗名',
  },
  memo: {
    jp: '備考欄',
  },
  action: {
    jp: 'アクション',
  },
  reference: {
    jp: '参照作成',
  },
  confirmation: {
    jp: '確認',
  },
  confirmationOrModify: {
    jp: '確認/修正',
  },
  existingMemo: {
    jp: '有り',
  },
  nonExistingMemo: {
    jp: 'なし',
  },
  cancel: {
    jp: 'キャンセル済み',
  },
  csvBtn: {
    jp: 'csv出力',
  },
};
