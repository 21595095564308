import { cloneDeep } from 'lodash';
import { IndexedObject } from '../../../types';
import { IOrder } from '../../_type/order';
import { INTIAL_ORDER } from '../initial-state';
import { getSeasonCode } from '../../../helpers/orders/season-code';
import { mergeState } from '../../../helpers/common/merge';

export const getNewOrderNumber = (orders: IndexedObject<IOrder>): string => {
  const keys = Object.keys(orders).map(v => +v);
  const max = keys.length > 0 ? Math.max(...keys) : -1;
  return String(max + 1);
};

export const getInitialOrderData = (now: Date): IOrder =>
  mergeState(cloneDeep(INTIAL_ORDER), { item: { cloth: { seasonCode: getSeasonCode(now) } } });
