export const components = {
  priceText: {
    tax: { jp: '(税込)' },
    point: { jp: 'pt' },
  },
  priceList: {
    total: { jp: '合計' },
  },
  other: {
    home: { jp: 'ホームへ戻る（注文内容が消えます）' },
    notImpliments: { jp: '注文確認画面へ戻る' },
    settlement: { jp: '注文確定' },
  },
  selectBox: {
    defaultMessage: { jp: '選択' },
  },
  numberWithButton: {
    plus: { jp: '+' },
    minus: { jp: '-' },
  },
  errorBoundary: {
    message: { jp: '継続不能なエラーが発生しました。最初からやり直してください。' },
    close: { jp: 'ログイン画面に戻る' },
  },
  inputText: {
    defaultLabel: { jp: '' },
    defaultPlaceHolder: { jp: '' },
    sample: { jp: 'サンプル.' },
  },
  loadingInfo: {
    default: { jp: '' },
  },
};
