export const search = {
  byPeriod: { jp: '期別' },
  itemNumber: { jp: '品番' },
  inventoryStatus: { jp: '在庫状況' },
  enoughStock: { jp: '在庫あり（◯）' },
  littleStock: { jp: '品薄（△）' },
  noStock: { jp: '品切れ（×）' },
  search: { jp: '検索する' },
  reset: { jp: 'RESET' },
  result: { jp: '検索結果' },
  title: { jp: '検索フィルター' },
  category: { jp: 'カテゴリー' },
  tempoCode: { jp: '店舗コード' },
  serialNumber: { jp: '製造NO' },
  phone: { jp: '電話番号' },
  cardNo: { jp: '会員番号' },
  lotNumber: { jp: '照合番号'},
  nameKana: { jp: '氏名(カナ)' },
  nameKanji: { jp: '氏名' },
  paymentDate: { jp: '注文日付' },
  resetButton: { jp: 'リセット' },
  searchButton: { jp: '検索する' },
  selectOptions: { jp: '1つ以上選択してください' },
};
