import { TOrderItemCode, TCategory, TSubCategory, TPartsNumber } from '../../lookups/master-thisisforreplaceall/';
import { ESubcategory } from '../master-thisisforreplaceall/subcategory';
import { EParts } from '../master-thisisforreplaceall/parts';

const M_1_1_0 = [EParts.jaket, EParts.pants];
const M_1_1_1 = [EParts.jaket, EParts.pants, EParts.vest];
const M_1_2_0 = [EParts.jaket, EParts.pants, EParts.pants];
const M_1_2_1 = [EParts.jaket, EParts.pants, EParts.pants, EParts.vest];
const M_1_0_0 = [EParts.jaket];
const M_0_1_0 = [EParts.pants];
const M_0_1_1 = [EParts.pants, EParts.vest];
const M_0_0_1 = [EParts.vest];
const M_0_2_0 = [EParts.pants, EParts.pants];
const M_1_0_1 = [EParts.jaket, EParts.vest];

// アイテムコードの取得は、条件とマッチした1番上のデータのアイテムコードが使われるので、
// 以下のtmp配列では、ダブルは必ずシングルの下に来るようにする。
const tmp: Array<[TOrderItemCode, TCategory, TSubCategory, TPartsNumber[], boolean, string[]]> =
  localStorage.getItem('brandCode') === 'IO'
    ? [
        ['BR', 'CS', ESubcategory.jacket, M_1_0_0, false, ['IO']],
        ['BW', 'CS', ESubcategory.jacket, M_1_0_0, true, ['IO']],
        ['PR', 'CS', ESubcategory.pants, M_0_1_0, false, ['IO']],
        ['PN', 'CS', ESubcategory.pants, M_0_2_0, false, ['IO']],
        ['SR', 'CS', ESubcategory.suit, M_1_1_0, false, ['IO']],
        ['WR', 'CS', ESubcategory.suit, M_1_1_0, true, ['IO']],
        ['SP', 'CS', ESubcategory.suit, M_1_2_0, false, ['IO']],
        ['WX', 'CS', ESubcategory.suit, M_1_2_0, true, ['IO']],
      ]
    : [
        ['BR', 'CS', ESubcategory.jacket, M_1_0_0, false, ['HL', 'IO']],
        ['BW', 'CS', ESubcategory.jacket, M_1_0_0, true, ['HL', 'IO']],
        ['BV', 'CS', ESubcategory.jacket, M_1_0_1, false, ['HL', 'IO']],
        ['PR', 'CS', ESubcategory.pants, M_0_1_0, false, ['HL', 'IO']],
        ['PN', 'CS', ESubcategory.pants, M_0_2_0, false, ['HL', 'IO']],
        ['SR', 'CS', ESubcategory.suit, M_1_1_0, false, ['HL', 'IO']],
        ['WR', 'CS', ESubcategory.suit, M_1_1_0, true, ['HL', 'IO']],
        ['SP', 'CS', ESubcategory.suit, M_1_2_0, false, ['HL', 'IO']],
        ['WX', 'CS', ESubcategory.suit, M_1_2_0, true, ['HL', 'IO']],
        ['SV', 'CS', ESubcategory.suit, M_1_1_1, false, ['HL', 'IO']],
        ['WV', 'CS', ESubcategory.suit, M_1_1_1, true, ['HL', 'IO']],
        ['SX', 'CS', ESubcategory.suit, M_1_2_1, false, ['HL', 'IO']],
        ['WY', 'CS', ESubcategory.suit, M_1_2_1, true, ['HL', 'IO']],
        ['VR', 'CS', ESubcategory.vest, M_0_0_1, false, ['HL', 'IO']],
        ['VS', 'CS', ESubcategory.pants, M_0_1_1, false, ['HL', 'IO']],
      ];

const getItemCodeInfo = (): Array<{
  itemCode: TOrderItemCode;
  category: TCategory;
  subCategory: TSubCategory;
  pieces: TPartsNumber[];
  isDouble: boolean;
  availableBrands: string[];
}> =>
  tmp.map(v => ({
    itemCode: v[0],
    category: v[1],
    subCategory: v[2],
    pieces: v[3],
    isDouble: v[4],
    availableBrands: v[5],
  }));

// export const getOrderCatalog = (master_info: any): Array<{
//   itemCode: TOrderItemCode;
//   category: TCategory;
//   subCategory: TSubCategory;
//   pieces: TPartsNumber[];
//   isDouble: boolean;
//   availableBrands: string[];
// }> => {
//   return getItemCodeInfo();
// };

export const ORDER_CATALOG_ALL = getItemCodeInfo();

export const SHORT_SLEEVE_SHIRT_ITEM_CODES = tmp.filter(v => v[2] === ESubcategory.shortSleve).map(v => v[0]);

export const SHIRT_ITEM_CODES = tmp.filter(v => v[3].some(vv => vv === EParts.shirt)).map(v => v[0]);
