import { AdjustOptionRes } from '../../../api/items/adjust-option';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { IPartsAdjustOption, IAdjustOption, TOptionClass } from '../../../store/_type/lookups';

export function toPartsAdjustOptions(res: AdjustOptionRes, partsNumber: TPartsNumber): IPartsAdjustOption {
  const data = res.query('adjustOptions');
  const adjustOptions = Array.from({ length: data.length }).map((v, i) => toAdjustOption(res, i, 'adjustOptions'));

  const ret: IPartsAdjustOption = {
    partsNumber,
    adjustOptions,
  };
  return ret;
}

function toAdjustOption(res: AdjustOptionRes, index: number, parentExpression: string): IAdjustOption {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const data = getValue<any>('optionClasses');
  const childrenExpression = getExpression('optionClasses');
  const optionClasses = Array.from({ length: data.length }).map((v, i) => toOptionClass(res, i, childrenExpression));

  const adjustOption: IAdjustOption = {
    optionClasses,
    isSpecialOption: getValue<string>('specialOptionFlag') === '1',
    optionNumber: getValue<string>('optionNumber'),
    optionName: getValue<string>('optionName'),
  };
  return adjustOption;
}

function toOptionClass(res: AdjustOptionRes, index: number, parentExpression: string): TOptionClass {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const optionClass: TOptionClass = {
    optionClassNumber: getValue<string>('optionClassNumber'),
    optionClassName: getValue<string>('optionClassName'),
  };

  return optionClass;
}
