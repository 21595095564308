import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import StepbarTemplate from '../../templates/StepbarTemplate/StepbarTemplate';
import OrderSubRouter from '../../../sub-routers/OrderSubRouter';
import { TPageContainerProps } from '../../../containers/pages/OrderContentPage';
import { getOrderStepbar } from '../../../helpers/components/stepbar';

type TProps = TPageContainerProps & RouteComponentProps;

const OrderContentPage: React.FC<TProps> = (props: TProps) => {
  const {
    history,
    isValidAllOrders,
    isLoggedInCustomer,
    hasAddressCompleted,
    onShowCustomerLoadDialog,
    getLocation,
    membersCardNumber,
    isEdit,
  } = props;
  const { pathname } = history.location;
  const initialStepbar = getOrderStepbar(
    pathname,
    isValidAllOrders,
    isLoggedInCustomer,
    hasAddressCompleted,
    onShowCustomerLoadDialog,
    getLocation.pathname,
    membersCardNumber,
    isEdit,
  );
  const [stepbar, setStepbar] = useState(initialStepbar);

  useEffect(() => {
    const edited = getOrderStepbar(
      pathname,
      isValidAllOrders,
      isLoggedInCustomer,
      hasAddressCompleted,
      onShowCustomerLoadDialog,
      getLocation.pathname,
      membersCardNumber,
      isEdit,
    );
    setStepbar(edited);
  }, [
    pathname,
    isValidAllOrders,
    isLoggedInCustomer,
    hasAddressCompleted,
    onShowCustomerLoadDialog,
    getLocation.pathname,
    membersCardNumber,
    isEdit,
  ]);

  return (
    <StepbarTemplate stepbar={stepbar}>
      <OrderSubRouter />
    </StepbarTemplate>
  );
};

export default withRouter(OrderContentPage);
