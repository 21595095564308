import React from 'react';

import styles from './TotalPayementDetails.module.scss';

import Label from '../../atoms/Label/Label';
import Title from '../../atoms/Title/Title';
import { ITotalPaymentItem } from '../../../types/new-store/components';
import PriceTextPlane from '../../atoms/PriceTextSpan/PriceTextSpan';
import { langResource } from '../../../i18n/_new/resources/';

interface IProps {
  totalPayment: ITotalPaymentItem;
}

const TotalPayementDetails: React.FC<IProps> = ({ totalPayment }) => {
  const { total, orderItems, pricePrefix } = totalPayment;
  return (
    <div className={styles.payementDetails}>
      <div className={styles.contentTotal}>
        <div className={styles.row}>
          <Title styles={['medium', 'lineLightGray']} textObj={langResource.confirmationPage.totalCost} />
          <div className={styles.price}>
            <PriceTextPlane price={total} prefix={pricePrefix} />
            <span className={styles.tax}>(税込)</span>
          </div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        {orderItems.map((orderItem, index) => {
          const { total: orderTotal, productPrice, optionPrice, deliveryDate } = orderItem;
          return (
            <div key={index} className={styles.content}>
              <div className={styles.title}>
                <Title styles={['medium']} textObj={langResource.confirmationPage.order} />
                <span>{`${index + 1} / ${orderItems.length}`}</span>
              </div>
              <div className={styles.priceWrap}>
                <div className={styles.row}>
                  <div className={styles.label}>
                    <Label position="TOP" textObj={langResource.confirmationPage.basePrice} />
                  </div>
                  <div className={styles.price}>
                    <PriceTextPlane price={productPrice} prefix={pricePrefix} />
                    <span className={styles.tax}>(税込)</span>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>
                    <Label position="TOP" textObj={langResource.confirmationPage.optionPrice} />
                  </div>
                  <div className={styles.price}>
                    <PriceTextPlane price={optionPrice} prefix={pricePrefix} />
                    <span className={styles.tax}>(税込)</span>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.labelTotal}>
                    <Label position="TOP" textObj={langResource.confirmationPage.productTotal} />
                  </div>
                  <div className={styles.priceTotal}>
                    <PriceTextPlane price={orderTotal} prefix={pricePrefix} />
                    <span className={styles.tax}>(税込)</span>
                  </div>
                </div>
                <div className={styles.rowDate}>
                  <div className={styles.labelDate}>
                    <Label position="TOP" textObj={langResource.confirmationPage.deliveryDate} />
                  </div>
                  <div className={styles.date}>
                    <span>{deliveryDate}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TotalPayementDetails;
