import { createSelector } from 'reselect';

import { AppState } from '../../..';
import { combineEpics } from 'redux-observable';
import { storeEpics } from './epics';
import { listenerEpics } from './event-listeners';

export { memoDialogActions, memoDialogReducer } from './action-reducers';
export { memoDialogEvents } from './event-listeners';

export type TMemoDialog = {
  hasOpen: boolean;
  memo: string;
  previousMemo: string;
  canEdit: boolean;
  /** NOTICE: 年(19)を含むserialNumber */
  serialNumber: string;
};
export type TShowMemoDialogParams = Omit<TMemoDialog, 'previousMemo' | 'hasOpen'>;

const featureSelector = (state: AppState): TMemoDialog => state.utils.dialog.memo;

export const getMemoDialog = {
  state: createSelector(featureSelector, state => state),
};

export const MemoDialogEpics = combineEpics(listenerEpics, storeEpics);
