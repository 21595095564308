import { IItem, TPartialOrder, TPartialCloth, IOnChange } from '../../_type/order';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { getPartialOrder } from '../helper/conv-partial-order';
import { map, mergeMap, filter, debounceTime } from 'rxjs/operators';
import { orderActions } from '../../order';
import { isReloadClothProducts, isValidTextileNo } from '../helper/validate';
import { IClothProduct } from '../../_type/lookups/index';
import { clothProductAsyncActions, IGetClothProductsParams } from '../../lookups/product/action-reducer';
import { actions } from './actions';
import { getExtractedProductPartialCloth } from '../../../helpers/cloth-selection';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { actions as OrderItemActions } from '../item/actions';
import { isOrderDetailOrderNumber } from '../../../helpers/item-thisisforreplaceall';
import { INITIAL_COMPOSITION } from '../initial-state';
import { clothProductsSelector } from '../../lookups/object-selector';
import { getItemCodeForClothSelectPage } from '../../../helpers/orders/order-items';

const getDonePayload = <T extends IOnChange>(startedPayload: T, oldValue: T['value']) => ({
  params: startedPayload,
  result: { previous: { ...startedPayload, value: oldValue } as T, current: startedPayload },
});

/**
 * clothのプロパティ値変更（単一の値を持つ要素のみ）
 */
const update: Epic<AnyAction, Action<TPartialOrder | Parameters<typeof actions.update.done>[0]>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(actions.update.started),
    filter(
      ({ payload }) =>
        payload.key !== 'vendorClothNumber' || (payload.key === 'vendorClothNumber' && isValidTextileNo(payload.value)),
    ),
    mergeMap(({ payload }) => {
      const { key, value, orderNumber } = payload;
      const data = getPartialOrder.fromCloth({ [key]: value });
      return [
        orderActions.updateCurrentOrder._action(data),
        actions.update.done(
          getDonePayload(payload, appStateSelector(state.value).specifiedValueInCloth(key, orderNumber)),
        ),
      ];
    }),
  );

/**
 * clothのプロパティ値変更（compositionBack, compositionFront）
 */
const updateComposition: Epic<
  AnyAction,
  Action<TPartialOrder | Parameters<typeof actions.updateComposition.done>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.updateComposition.started),
    mergeMap(({ payload }) => {
      const { key, subkey, value, index, orderNumber } = payload;
      const compositions = appStateSelector(state.value).specifiedCompositionInCloth(key, orderNumber);
      const composition = compositions[index] || { ...INITIAL_COMPOSITION };
      const oldValue = composition[subkey];

      const newCompositions = [...compositions];
      newCompositions[index] = { ...composition, [subkey]: value };

      const data = getPartialOrder.fromCloth({ [key]: newCompositions });
      return [
        orderActions.updateCurrentOrder._action(data),
        actions.updateComposition.done(getDonePayload(payload, oldValue)),
      ];
    }),
  );

const loadProductLookup: Epic<AnyAction, Action<IGetClothProductsParams>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.update.done),
    filter(({ payload }) => payload.result.previous.value !== payload.result.current.value),
    // filter(({ payload }) => appStateSelector(state.value).cloth(payload.result.current.orderNumber) !== undefined),
    filter(({ payload }) => {
      const { previous, current } = payload.result;
      const key = current.key;
      const cloth = appStateSelector(state.value).cloth(current.orderNumber);
      if (cloth === undefined) {
        return false;
      }
      if (key === 'clothCode') {
        return isReloadClothProducts(current.value, previous.value, cloth.seasonCode);
      }
      return false;
    }),
    // clothCodeの値が正しいフォーマットの場合
    filter(({ payload }) => {
      const cloth = appStateSelector(state.value).cloth();
      const seasonCode = cloth ? cloth.seasonCode : '';
      // TODO: 古いClothCodeと変わっているかどうかのチェックは、はじめのfilterでやっているのでここでは無視します。
      return isReloadClothProducts(payload.result.current.value, '', seasonCode);
    }),
    debounceTime(400),
    // item が undefindeになった場合は処理終了（基本的に無いはずだが、その後でエラーチェックをしなくて済むように）
    filter(clothCode => appStateSelector(state.value).item() !== undefined),
    // playload内のclothCodeと、AppState内のitemを次に渡す
    map(({ payload }) => ({
      clothCode: payload.result.current.value,
      item: appStateSelector(state.value).item() as IItem,
    })),
    map(data => {
      return clothProductAsyncActions.loadData.started({ orderNumber: state.value.order.currentOrderNumber });
    }),
  );

/**
 * 引数として指定したorderNumberと、itemとproductsをcastしたものに加えて、
 * getExtractedProductPartialClothの引数となるitemCode, brandCodeを追加して、返却する
 */
const addPropsItemCodeAndBrandCode = (param: { orderNumber: string; item?: IItem; products?: IClothProduct[] }) => {
  const { orderNumber } = param;
  const item = param.item as IItem;
  const subCategoryCode = item.subCategoryCode;
  const products = param.products as IClothProduct[];
  const { selectableBrandCodes } = clothProductsSelector({ products, itemCode: '', brandCode: '', subCategoryCode });
  const brandCode =
    item.cloth.brandCode && selectableBrandCodes.includes(item.cloth.brandCode)
      ? item.cloth.brandCode // 選択中のブランドコードありかつマスタに選択中のブランドコードがある場合
      : selectableBrandCodes.length === 1 // ブランドコードの選択肢が1つの場合
      ? selectableBrandCodes[0]
      : '';
  const itemCode = getItemCodeForClothSelectPage({
    category: item.categoryCode,
    subCategory: item.subCategoryCode,
    brand: brandCode,
    modelCode: item.cloth.clothModelCode,
  });
  return { orderNumber, item, products, brandCode, itemCode };
};

const autoDetectClothValue: Epic<
  AnyAction,
  Action<TPartialOrder> | Action<void | { orderNumber?: string }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.update.done),
    filter(({ payload }) => payload.result.previous.value !== payload.result.current.value),
    filter(
      ({ payload }) => payload.result.current.key === 'brandCode' || payload.result.current.key === 'clothModelCode',
    ),
    map(({ payload }) => {
      const orderNumber = appStateSelector(state.value).orderNumber(payload.params.orderNumber);
      const item = appStateSelector(state.value).item(orderNumber);
      const products = appStateSelector(state.value).products(orderNumber);
      return { item, products, orderNumber };
    }),
    filter(({ item, products }) => item !== undefined && products !== undefined),
    map(addPropsItemCodeAndBrandCode),
    map(({ item, products, orderNumber, itemCode, brandCode }) => ({
      cloth: getExtractedProductPartialCloth(products, item, itemCode || '', brandCode, false),
      orderNumber,
    })),
    filter(({ cloth }) => cloth !== undefined),
    mergeMap(({ cloth, orderNumber }) => {
      const data = getPartialOrder.fromCloth(cloth as TPartialCloth);
      return [
        orderActions.updateCurrentOrder._action(data),
        OrderItemActions.loadShortestDeliveryDate({ orderNumber }),
        OrderItemActions.loadStockFlag({ orderNumber }),
        OrderItemActions.updateItemCode({ orderNumber }),
      ];
    }),
  );

const reloadStockFlag: Epic<AnyAction, Action<void | { orderNumber?: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.update.done),
    filter(({ payload }) => payload.result.previous.value !== payload.result.current.value),
    filter(({ payload }) => ['personalorderColorCode', 'stockPlaceCode'].includes(payload.result.current.key)),
    map(({ payload }) => {
      const orderNumber = appStateSelector(state.value).orderNumber(payload.params.orderNumber);
      return { orderNumber };
    }),
    mergeMap(({ orderNumber }) => {
      return [OrderItemActions.loadStockFlag({ orderNumber })];
    }),
  );

const reloadDeliveryDate: Epic<AnyAction, Action<void | { orderNumber?: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.update.done),
    filter(({ payload }) => payload.result.previous.value !== payload.result.current.value),
    filter(({ payload }) => ['personalorderColorCode'].includes(payload.result.current.key)),
    map(({ payload }) => {
      const orderNumber = appStateSelector(state.value).orderNumber(payload.params.orderNumber);
      return { orderNumber };
    }),
    mergeMap(({ orderNumber }) => {
      return [OrderItemActions.loadShortestDeliveryDate({ orderNumber })];
    }),
  );

const autoDetectClothValue2: Epic<
  AnyAction,
  Action<TPartialOrder> | Action<void | { orderNumber?: string }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(clothProductAsyncActions.loadData.done),
    map(({ payload }) => {
      const orderNumber = payload.params.orderNumber;
      // apiの取得結果を更新する
      const item = appStateSelector(state.value).item(orderNumber);
      const products = appStateSelector(state.value).products(orderNumber);
      const isOrderDetail = isOrderDetailOrderNumber(orderNumber) && !appStateSelector(state.value).isEditOrder();
      return { payload, item, products, orderNumber, isOrderDetail };
    }),
    filter(({ isOrderDetail }) => !isOrderDetail),
    filter(({ item, products }) => item !== undefined && products !== undefined),
    map(addPropsItemCodeAndBrandCode),
    map(({ item, products, orderNumber, itemCode, brandCode }) => ({
      cloth: getExtractedProductPartialCloth(products, item, itemCode || '', brandCode, true),
      orderNumber,
    })),
    filter(({ cloth }) => cloth !== undefined),
    mergeMap(({ cloth, orderNumber }) => {
      const data = getPartialOrder.fromCloth(cloth as TPartialCloth);
      return [
        orderActions.updateCurrentOrder._action(data),
        OrderItemActions.loadShortestDeliveryDate({ orderNumber }),
        OrderItemActions.loadStockFlag({ orderNumber }),
        OrderItemActions.updateItemCode({ orderNumber }),
      ];
    }),
  );

export const OrderClothEpics = combineEpics(
  update,
  updateComposition,
  loadProductLookup,
  autoDetectClothValue,
  autoDetectClothValue2,
  reloadStockFlag,
  reloadDeliveryDate,
);
