import React from 'react';

import I18TextContainer from '../../../containers/I18Text/I18Text';

import styles from './Logo.module.scss';
import { II18nItem } from '../../../i18n/_new/types';

type TProp = {
  textObj?: II18nItem;
};
const Logo: React.FC<TProp> = ({ textObj }) => {
  return (
    <React.Fragment>
      <h1 className={styles.logo}>
        <I18TextContainer textObj={textObj} />
      </h1>
    </React.Fragment>
  );
};

export default Logo;
