import { cloneDeep } from 'lodash';
import path from 'ramda/es/path';
import {
  IAvailableOption,
  IAvailableOptionWithPartsIndexAndSelectedInfo,
  IOptionPattern,
  IOptionClass,
  TOptionClass,
  IOptionPatternWithSelectedInfo,
} from '../../_type/lookups/index';
import { IDesignSelectingParam, IPiece, IOption, IDesignParts } from '../../_type/order';
import { SystemError } from '../../../models/error/system-error';
import {
  getOptionType,
  isFollowToJacketOptionNumber,
  isJacket,
  getFollowToJacketOptionNumber,
  getOptions,
  isSpareParts,
  isDesignOption,
  isModelSelectBoxOnDesignPartsSection,
  isValidSpareOption,
  isValidMainOption,
} from '../../../helpers/item-thisisforreplaceall';
import { EOptionType } from '../../../types/option';
import { TPartsNumber, TCategory } from '../../../lookups/master-thisisforreplaceall';
import { by } from '../../../helpers';
import { Optional } from '../../../types';
import Logger from '../../../helpers/common/logger';

/**
 * IAvailableOption からデータを取得する関数郡
 */
export const availableOptionsSelector = (state: IAvailableOption[]) => new AvailableOptionsSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class AvailableOptionsSelector {
  constructor(private state: IAvailableOption[]) {}

  public filteredSelectedOptions(partsNumber: TPartsNumber, options: IOption[]): IOption[] {
    const aOptions = (this.state.find(v => v.partsNumber === partsNumber) || { optionPatterns: [] }).optionPatterns;
    return options.filter(v => {
      const matchedOption = aOptions.find(vv => vv.optionNumber === v.optionNumber);
      if (!matchedOption) {
        return false;
      }

      const isFreeInput = getOptionType(v.optionNumber) === EOptionType.FREE_TEXT_OPTION;
      if (!isFreeInput) {
        // 選択肢(optionClasses)が1つしかない場合は選択されたオプションは返却しない。
        if (matchedOption.optionClasses.length < 2) {
          return false;
        }
        // 選択肢(optionClasses)が0個の場合も、マッチしない。
        const matchedOptionClass = matchedOption.optionClasses.find(vv => vv.optionClassNumber === v.optionClassNumber);
        if (!matchedOptionClass) {
          return false;
        }
      }
      return true;
    });
  }

  public withSelectedInfo(
    pieces: IPiece[],
    designParts: IDesignParts,
    selecting: IDesignSelectingParam,
    category: TCategory,
    clothModelCode: string,
    brandCode: string,
  ): IAvailableOptionWithPartsIndexAndSelectedInfo[] {
    return !this.state
      ? []
      : pieces
          .filter(v => this.hasTargetParts(v.partsNumber))
          .map((piece, pieceIndex) => {
            const isSpare = isSpareParts(pieces, piece, pieceIndex);
            const matchedAvailableOption = this.matchAvailableOption(piece.partsNumber);
            const optionPattern = getFilteredOptionPatternCode(
              designParts,
              piece,
              matchedAvailableOption.optionPatterns[0].optionPattern,
              isSpare,
              pieces,
              category,
              clothModelCode,
              brandCode
            );

            const optionsSelectedInfo = {
              ...matchedAvailableOption,
              isSpare,
              partsIndex: piece.index,
              optionPatterns: matchedAvailableOption.optionPatterns
                .filter(v => v.optionPattern === optionPattern)
                .filter(v => (isSpare ? isValidSpareOption(v.optionNumber) : isValidMainOption(v.optionNumber)))
                .map(v => {
                  const isFreeInput = getOptionType(v.optionNumber) === EOptionType.FREE_TEXT_OPTION;
                  const { isFollowToJacketOption, followedJacketClass } = this.getSelectedFollowToJacketOptionClass(
                    pieces,
                    piece.partsNumber,
                    v,
                    designParts,
                  );
                  const spareOptionClass = isSpare
                    ? this.getSparePartsOptionClass(pieces, piece.partsNumber, v, designParts)
                    : undefined;
                  const designPartsOptions = (path([piece.index, 'options'], designParts) as IOption[]) || [];
                  const selectedOptionClass = getSelectedOptionClass(
                    v,
                    designPartsOptions,
                    spareOptionClass,
                    followedJacketClass,
                    isFreeInput,
                  );
                  const selectedOptionClassInfo = v.optionClasses.find(
                    by('optionClassNumber')(selectedOptionClass.optionClassNumber),
                  );
                  return {
                    ...v,
                    isFreeInput,
                    isDesignOption: isDesignOption(v.optionNumber),
                    isFollowToJacketOption,
                    hasSelecting: v.optionNumber === selecting.optionNumber && selecting.partsIndex === piece.index,
                    selectingClassNumber: selectedOptionClass.optionClassNumber,
                    // TODO: 自由入力系の入力値はこの関数で設定すべき。
                    selectingClassName:
                      (!isFreeInput && selectedOptionClassInfo
                        ? selectedOptionClassInfo.optionClassName
                        : selectedOptionClass.optionClassName) || '',
                  };
                }),
            };

            const { modelCode, modelPattern: modelPatternCode, optionPattern: optionPatternCode } = getMainModel(
              pieces,
              piece.partsNumber,
              designParts,
              optionsSelectedInfo.optionPatterns,
              category,
              clothModelCode,
              brandCode,
            );

            return {
              ...optionsSelectedInfo,
              modelCode,
              modelPatternCode,
              optionPatternCode,
            };
          });
  }

  /** 対象のパーツがない場合に落ちるのでfilterをかける（ベスト削除時にエラー発生するため） */
  private hasTargetParts(partsNumber: TPartsNumber): boolean {
    const matched = this.state.find(by('partsNumber')(partsNumber));
    return matched ? true : false;
  }

  private matchAvailableOption(partsNumber: TPartsNumber) {
    const matchedAvailableOption = cloneDeep(this.state.find(by('partsNumber')(partsNumber)));
    if (!matchedAvailableOption) {
      Logger.log('Error: ', { state: this.state, partsNumber });
      throw new SystemError('010', ['partsNumber', partsNumber]);
    }
    return matchedAvailableOption;
  }

  private get jacketAvailableOption(): IAvailableOption | undefined {
    return cloneDeep(this.state.find(v => isJacket(v.partsNumber)));
  }

  private getSelectedFollowToJacketOptionClass(
    pieces: IPiece[],
    partsNumber: TPartsNumber,
    optionPattern: IOptionPattern,
    designParts: IDesignParts,
  ): { isFollowToJacketOption: boolean; followedJacketClass: IOptionClass | undefined } {
    const { optionNumber: followedOptionNumber } = optionPattern;
    const isFollowToJacketOption = isFollowToJacketOptionNumber(pieces, partsNumber, followedOptionNumber);
    const notFoundValue = { isFollowToJacketOption, followedJacketClass: undefined };
    if (!isFollowToJacketOption) {
      return notFoundValue;
    }
    if (!this.jacketAvailableOption) {
      return notFoundValue;
    }
    const jacketOptionNumber = getFollowToJacketOptionNumber(followedOptionNumber);
    if (!jacketOptionNumber) {
      return notFoundValue;
    }

    // isFollowToJacketOptionNumberでジャケットがあるのはわかっているので
    const piece = pieces.find(v => isJacket(v.partsNumber)) as IPiece;
    const selectedJacketOption = getOptions(piece.index, designParts).find(by('optionNumber')(jacketOptionNumber));
    if (!selectedJacketOption) {
      return notFoundValue;
    }

    // jacketと合致するoptionClassを返却する
    const followedJacketClass = optionPattern.optionClasses.find(
      by('optionClassNumber')(selectedJacketOption.optionClassNumber),
    );
    return { isFollowToJacketOption, followedJacketClass };
  }

  private getSparePartsOptionClass(
    pieces: IPiece[],
    partsNumber: TPartsNumber,
    optionPattern: IOptionPattern,
    designParts: IDesignParts,
  ): IOptionClass | undefined {
    const piece = pieces.find(by('partsNumber')(partsNumber)) as IPiece;
    const selectedMainOption = getOptions(piece.index, designParts).find(
      by('optionNumber')(optionPattern.optionNumber),
    );
    if (!selectedMainOption) {
      return undefined;
    }
    return optionPattern.optionClasses.find(by('optionClassNumber')(selectedMainOption.optionClassNumber));
  }
}

function getMainModel(
  pieces: IPiece[],
  partsNumber: TPartsNumber,
  designParts: IDesignParts,
  options: IOptionPatternWithSelectedInfo[],
  category: TCategory,
  clothModelCode: string,
  brandCode: string,
): { modelCode: string; modelPattern: string; optionPattern: string } {
  const piece = getMainPiece(pieces, partsNumber);
  const modelCode = getModelCode(options);
  if (!isModelSelectBoxOnDesignPartsSection(category, partsNumber, brandCode)) {
    // デザイン選択画面でmodel選択がない場合はclothModelCodeを固定で返却する
    return { modelCode, modelPattern: clothModelCode, optionPattern: clothModelCode };
  }
  return !designParts[piece.index]
    ? { modelCode: '', modelPattern: '', optionPattern: '' }
    : { ...designParts[piece.index], modelCode };
}

function getModelCode(options: IOptionPatternWithSelectedInfo[]): string {
  const designOption = options.filter(v => v.selectingClassNumber).find(v => isDesignOption(v.optionNumber));
  return !designOption ? '' : designOption.selectingClassNumber;
}

/**
 * subパーツのPartsNumberからmainパーツのpieceを取得する
 */
function getMainPiece(pieces: IPiece[], subPartsNumber: TPartsNumber): IPiece {
  return pieces.find(by('partsNumber')(subPartsNumber)) as IPiece; // MEMO: undefinedはないのでcast
}

/**
 * 抽出するoptionPatternを取得する
 */
function getFilteredOptionPatternCode(
  designParts: IDesignParts,
  piece: IPiece,
  initial: string,
  isSpare: boolean,
  pieces: IPiece[],
  category: TCategory,
  clothModelCode: string,
  brandCode: string,
): string {
  if (!isModelSelectBoxOnDesignPartsSection(category, piece.partsNumber, brandCode)) {
    // デザイン選択画面でmodel選択がない場合はclothModelCodeを返却する
    return clothModelCode;
  }
  if (isSpare) {
    const mainPiece = getMainPiece(pieces, piece.partsNumber);
    return path([mainPiece.index, 'optionPattern'], designParts) || initial;
  }
  return path([piece.index, 'optionPattern'], designParts) || initial;
}

function getSelectedOptionClass(
  optionPattern: IOptionPattern,
  designPartsOptions: IOption[],
  spareOptionClass: Optional<IOptionClass>,
  followedJacketClass: Optional<IOptionClass>,
  isFreeInput: boolean,
): TOptionClass | IOption {
  // スペアの場合
  if (spareOptionClass) {
    return spareOptionClass;
  }

  // ジャケットに準ずる場合
  if (followedJacketClass) {
    return followedJacketClass;
  }

  const selected = designPartsOptions.find(by('optionNumber')(optionPattern.optionNumber));
  // ユーザが選択したオプションあり
  if (selected) {
    return selected;
  }

  // ユーザが選択したオプションなし
  // ユーザ未選択 && 選択肢１つ && 自由入力ではない → 自動で当該候補を選択状態とする。
  if (optionPattern.optionClasses.length === 1 && !isFreeInput) {
    return {
      optionClassNumber: optionPattern.optionClasses[0].optionClassNumber,
      optionClassName: optionPattern.optionClasses[0].optionClassName,
    };
  }

  // 未選択状態とする。
  return { optionClassNumber: '', optionClassName: '' };
}
