import { ICustomer } from '../../store/_type/customer';
import { TPartialShipping } from '../../store/_type/order';

export function toShipping(customer: ICustomer): TPartialShipping {
  const {
    shippingPostalCode,
    shippingState,
    shippingCity,
    shippingStreet,
    customerFamilyNameKana,
    customerGivenNameKana,
    customerFamilyNameKanji,
    customerGivenNameKanji,
    shippingPhoneNumber,
    customerMailAddress,
  } = customer;

  return {
    shippingPostalCode,
    shippingState,
    shippingCity,
    shippingStreet,
    customerFamilyNameKana,
    customerGivenNameKana,
    customerFamilyNameKanji,
    customerGivenNameKanji,
    shippingPhoneNumber,
    customerMailAddress,
  };
}
