import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './SettlementPage.module.scss';

import Footer from '../../organisms/Footer/Footer';
import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';

import { IFooterButton } from '../../../types/footer';
import { ERouterPath } from '../../../types';
import { langResource } from '../../../i18n/_new/resources';
import { TPageContainerProps } from '../../../containers/pages/SettlementPage';

type TProps = TPageContainerProps & RouteComponentProps;

const SettlementPage: React.FC<TProps> = props => {
  const { onRegistOrders } = props;
  useEffect(() => {
    // ページ表示時にオーダーを送信する
    onRegistOrders();
  }, [onRegistOrders]);

  return (
    <React.Fragment>
      <div className={styles.settlementPage}>{props.isRegisting}</div>
      {utility(props)}
    </React.Fragment>
  );
};

function utility(props: TProps) {
  return (
    <React.Fragment>
      <LoadingInfo isLoading={props.isRegisting} messageKey={langResource.settlementPage.loadingMessage} />
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      path: ERouterPath.home,
    },
    {
      type: 'back',
      path: ERouterPath.orderConfirmation,
    },
    {
      type: 'next',
      isDisabled: true,
      path: ERouterPath.home,
    },
    {
      type: 'confirm',
      isDisabled: true,
      textObj: langResource.footer.home,
    },
  ];
}

// function onMoveToHome(props: TProps): () => void {
//   const dialog: IOKCancelDialog = {
//     hasOpen: true,
//     title: '確認',
//     contents: '現在の注文を全て削除してホーム画面に移動しますか？',
//     onOK: () => {
//       props.history.push(resolvePath(ERouterPath.home));
//       // 下記の処理をすぐに実行するとselectorでerrorが発生するため
//       setTimeout(() => {
//         props.onDeleteOrders();
//       }, 50);
//     },
//   };
//   return () => props.onShowOkCancelDialog(dialog);
// }

export default withRouter(SettlementPage);
