import { IApiError } from '../types/api';
import { ApiError } from '../models/error/api-error';
import { SystemError } from '../models/error/system-error';
import Logger from './common/logger';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getErrorAction } from '../store/utils/helpers';
import { BusinessError } from '../models/error/business-error';
import { removeAllCookies } from '../services/storage/index';

export function handleActionError(
  ex: any,
  systemErrorAction: (errors: IApiError[]) => void,
  notFoundErrorAction?: () => void,
) {
  console.error('handleActionError', ex);
  if (ex instanceof ApiError) {
    const { statusCode, errors } = ex;
    if (statusCode === 404 && notFoundErrorAction) {
      Logger.log('404 Error', errors);
      notFoundErrorAction();
      return;
    }
    // FIXME: とりあえず移動した。
    if (statusCode === 401) {
      removeAllCookies();
    }

    systemErrorAction(errors);
    return;
  }
  if (ex instanceof SystemError) {
    systemErrorAction(ex.errors);
    return;
  }
  // FIXME: 仮
  if (ex instanceof Error) {
    systemErrorAction([{ code: 'unknown', message: `${ex.stack}` }]);
    return;
  }
  systemErrorAction([{ code: 'unknown', message: `${ex}` }]);
}

export function thunkActionErrorHandler(ex: any, dispatch: ThunkDispatch<any, any, AnyAction>, extra?: any) {
  console.error('thunkActionErrorHandler', ex);
  const action = getErrorAction(dispatch, extra);
  if (ex instanceof ApiError) {
    const { statusCode, errors } = ex;
    console.error('api error', `statusCode: ${statusCode}`, ex.errors);
    switch (statusCode) {
      case 404:
        action.notFoundError();
        return;

      case 403:
        action.expiredTokenError();
        return;
    }
    // 上記以外はsystemError扱い
    action.systemError(errors);
    return;
  }

  if (ex instanceof SystemError) {
    console.error('system error', ex.errors);
    action.systemError(ex.errors);
    return;
  }

  if (ex instanceof BusinessError) {
    console.error('business error', ex.errors);
    return;
  }

  if (ex instanceof Error) {
    // FIXME: 仮
    action.systemError([{ code: 'unknown', message: `${ex.stack}` }]);
    return;
  }
  action.systemError([{ code: 'unknown', message: `${ex}` }]);
}
