import { TRequestConfig, TRequestParams } from '../../';
import { getMockRecommendGauge } from '../../../lookups/size-correction/recommended-gauge';
// import config from '../../../configuration/config';
export { RecommendGaugeRes } from './recommend-gauge.class';

export const recommendGaugeRequestConfig: TRequestConfig = {
  endpoint: '/items/sizes/recommend/{brand_code}',
  method: 'POST',
  useMock: false,
  mockFunc: params => {
    const pathParam = params ? params.pathParam : '';
    const bodyParam = params ? params.body : {};

    return {
      ok: true,
      body: JSON.stringify(getMockRecommendGauge(pathParam, bodyParam)),
    };
  },
  params2request: (params): TRequestParams => {
    return {
      body: params ? params.body : {},
      pathParams: {
        brand_code: params ? params.path.brandCode : '',
      },
    };
  },
};
