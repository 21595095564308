import { get } from '../../../../store/utils/dialog/required-check';
import { AppState } from '../../../../store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../../../../store/utils/dialog/required-check/action-reducer';
import RequiredCheckDialog from '../../../../components/organisms/RequiredCheckDialog/RequiredCheckDialog';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: get.hasOpen(state),
    texts: get.texts(state),
    onClick: get.onClick(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClose: () => dispatch(actions.close()),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type TRequiredCheckDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(RequiredCheckDialog);
