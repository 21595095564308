import { TSubCategory } from '.';
import { rawStrArr2EnumLikeObj } from '../../lib/utils';
import { TMaster } from '../../types/index';

const itemCodes = rawStrArr2EnumLikeObj([
  'SR',
  'SV',
  'SP',
  'WR',
  'BR',
  'PR',
  'VR',
  'FB',
  'BF',
  'FI',
  'VF',
  'FX',
  'SK',
  'OP',
  'S5',
  'S6',
  'S7',
  'PN',
  'NS',
  'O2',
  'O7',
  'O6',
  'OR',
  'WV',
  'WX',
  'BW',
  'BV',
  'VS',
  'FR',
  'FV',
  'FP',
  'FJ',
  'XV',
  'XT',
  'WF',
  'WS',
  'WJ',
  'FQ',
  'FW',
  'FY',
  'SX',
  'WY',
  'S1',
  'S2',
  'P1',
  'P2',
  'FZ',
  'HD',
  'HH',
  'HF',
  'PZ',
  'CO',
  'CM',
  'HS',
  'KH',
  'BL',
  'LR',
  'L5',
  'L7',
  'L8',
  'L9',
  'W5',
  'W6',
  'W7',
  'WK',
  'WM',
  'WN',
  'WQ',
  'WU',
  'VZ',
]);

type TNotNullOrderItemCode = keyof typeof itemCodes;
export type TOrderItemCode = '' | TNotNullOrderItemCode;

export const MASTER_ITEM_CODE_MEN: Array<TMaster<TNotNullOrderItemCode>> = [
  { code: 'SR', value: 'S（シングル）スーツ' },
  { code: 'SV', value: 'Sスリーピーススーツ' },
  { code: 'SP', value: 'Sスペア付スーツ' },
  { code: 'SX', value: 'Wスペア付スーツ＆ベスト' },
  { code: 'WR', value: 'W（ダブル）スーツ' },
  { code: 'WV', value: 'Wスリーピーススーツ' },
  { code: 'WX', value: 'Wスペア付スーツ' },
  { code: 'WY', value: 'Wスペア付スーツ＆ベスト' },
  { code: 'BR', value: 'Sジャケット' },
  { code: 'BW', value: 'Wジャケット' },
  { code: 'PR', value: 'パンツ' },
  { code: 'VR', value: 'ベスト' },
  { code: 'PN', value: 'パンツ＆スペア' },
  { code: 'BV', value: 'ジャケット＆ベスト' },
  { code: 'VS', value: 'パンツ＆ベスト' },
  { code: 'FX', value: 'タキシードスーツ' },
  { code: 'FB', value: 'Sフォーマルスーツ' },
  { code: 'FR', value: 'Wフォーマルスーツ' },
  { code: 'FV', value: 'Sフォーマルスリーピーススーツ' },
  { code: 'FP', value: 'Sスペア付フォーマルスーツ' },
  { code: 'FZ', value: 'Sスペア付フォーマルスリーピーススーツ' },
  { code: 'FQ', value: 'Wスペア付フォーマルスーツ' },
  { code: 'FW', value: 'Wフォーマルスリーピーススーツ' },
  { code: 'FY', value: 'Wスペア付フォーマルスリーピーススーツ' },
  { code: 'FJ', value: 'Sフォーマルジャケット' },
  { code: 'BF', value: 'Wフォーマルジャケット' },
  { code: 'FI', value: 'フォーマルスラックス' },
  { code: 'VF', value: 'フォーマルベスト' },
  { code: 'FX', value: 'タキシードスーツ' },
  // { code: 'XJ', value: 'タキシードジャケット' }, // ない。。。
  { code: 'XV', value: 'タキシードスリーピーススーツ' },
  { code: 'XT', value: 'タキシードスラックス' },
  { code: 'WF', value: 'Wタキシードスーツ' },
  { code: 'WS', value: 'Wタキシードスリーピーススーツ' },
  { code: 'WJ', value: 'Wタキシードジャケット' },
  { code: 'CO', value: 'コート' },
];

export const MASTER_ITEM_CODE_WEMEN: Array<TMaster<TNotNullOrderItemCode>> = [
  { code: 'SR', value: 'パンツスーツ' },
  { code: 'S5', value: 'スカートスーツ' },
  { code: 'SP', value: 'ジャケット＋ツーパンツ' },
  { code: 'S6', value: 'ジャケット＋2スカート' },
  { code: 'S7', value: 'ジャケット＋パンツ＋スカート' },
  { code: 'BR', value: 'ジャケット' },
  { code: 'PR', value: 'パンツ' },
  { code: 'SK', value: 'スカート' },
  { code: 'PN', value: 'パンツ×2本' },
  { code: 'NS', value: 'スカート×2本' },
  { code: 'OP', value: 'ワンピース' },
  { code: 'O2', value: 'ジャケット＋ワンピース' },
  { code: 'O7', value: 'ジャケット＋ワンピース＋パンツ' },
  { code: 'O6', value: 'ジャケット＋ワンピース＋スカート' },
  { code: 'OR', value: 'ワンピース＋パンツ' },
  { code: 'PZ', value: 'パンツ+スカート' },
  { code: 'CM', value: 'コート' },
  { code: 'BL', value: 'トップス' },
  { code: 'LR', value: 'トップス+パンツ' },
  { code: 'L5', value: 'トップス+スカート' },
  { code: 'L7', value: 'トップス+パンツ+スカート' },
  { code: 'L8', value: 'ジャケット+トップス+パンツ' },
  { code: 'L9', value: 'ジャケット+トップス+スカート' },
  { code: 'WR', value: 'Wパンツスーツ' },
  { code: 'W5', value: 'Wスカートスーツ' },
  { code: 'WX', value: 'Wジャケット＋ツーパンツ' },
  { code: 'W6', value: 'Wジャケット＋2スカート' },
  { code: 'W7', value: 'Wジャケット＋パンツ＋スカート' },
  { code: 'BW', value: 'Wジャケット' },
  { code: 'WK', value: 'Wジャケット＋ワンピース' },
  { code: 'WM', value: 'Wジャケット＋ワンピース＋パンツ' },
  { code: 'WN', value: 'Wジャケット＋ワンピース＋スカート' },
  { code: 'WQ', value: 'Wジャケット+トップス+パンツ' },
  { code: 'WU', value: 'Wジャケット+トップス+スカート' },
  { code: 'VR', value: 'ベスト' },
  { code: 'VS', value: 'ベスト+パンツ' },
  { code: 'VZ', value: 'ベスト+スカート' },
];

export const MASTER_ITEM_CODE_SHIRT: Array<TMaster<TNotNullOrderItemCode>> = [
  { code: 'HD', value: '長袖ドレスシャツ' },
  { code: 'HS', value: '長袖カジュアルシャツ' },
  { code: 'HH', value: '半袖ドレスシャツ' },
  { code: 'KH', value: '半袖カジュアルシャツ' },
  { code: 'HF', value: '長袖フォーマルシャツ' },
  // ウィメンズ
  { code: 'HD', value: '長袖ウィメンズシャツ' },
  { code: 'HH', value: '半袖ウィメンズシャツ' },
];

export const MASTER_ITEM_TYPE: Array<TMaster<TNotNullOrderItemCode>> = [
  { code: 'HD', value: '長袖' },
  { code: 'HS', value: '長袖' },
  { code: 'HH', value: '半袖' },
  { code: 'KH', value: '半袖' },
  { code: 'HF', value: '長袖' },
];
/**
 * サブカテゴリーに応じたシャツのアイテムコード
 */
export const SHIRT_ITEM_CODE_ACCORDING_TO_SUB_CATEGORY: Partial<Record<TSubCategory, TOrderItemCode[]>> = {
  15: ['HD', 'HS'],
  16: ['HH', 'KH'],
  17: ['HF'],
  18: ['HD'],
  19: ['HH'],
  20: ['HD'],
  21: ['HH'],
};
