import React from 'react';
import NumericKeyboard from '../components/atoms/NumericKeyboard/NumericKeyboard';
import { EKeyboardInputType, TKeyboardInputType } from '../lookups/keyboard';
import Logger from '../helpers/common/logger';

interface IExternalProps {
  style?: React.CSSProperties;
}

export interface ICustomKeyboardInjectedProps {
  checkFocusForNumericKeyboard: (e: React.FocusEvent) => void;
  connectNumericKeyboard: (
    inputState: string | number | undefined,
    updateHandler: (value: string) => void | string | number | undefined,
  ) => void;
  closeNumericKeyboard: () => void;
}

interface IState {
  inputType: TKeyboardInputType;
  numericKeyboardShow: boolean;
  numericKeyboardInitial: string;
  numericKeyboardState: string | number | undefined;
  numericKeyboardUpdateHandler: (val: string) => void;
}

interface IOptions {
  debug?: boolean;
}

export const customKeyboardHoc = ({ debug = false }: IOptions = {}) => <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps & ICustomKeyboardInjectedProps>,
) => {
  type ResultProps = OriginalProps & IExternalProps;

  return class CustomKeyboardHoc extends React.Component<ResultProps, IState> {
    constructor(props: ResultProps) {
      super(props);

      this.state = {
        inputType: EKeyboardInputType.number,
        numericKeyboardShow: false,
        numericKeyboardInitial: '',
        numericKeyboardState: undefined,
        numericKeyboardUpdateHandler: (val: string) => Logger.error('[No update handler]', val),
      };
    }

    public render(): JSX.Element {
      const connectNumericKeyboard = (
        inputState: string | number | undefined,
        updateHandler: (value: string) => void | string | number | undefined,
      ) => {
        this.setState({
          ...this.state,
          numericKeyboardShow: true,
          numericKeyboardInitial: `${inputState}`,
          numericKeyboardState: inputState,
          numericKeyboardUpdateHandler: updateHandler,
        });
      };
      const closeNumericKeyboard = () => {
        this.setState({ ...this.state, numericKeyboardShow: false });
      };
      const getAfterVal = (keyboardValue: string, value: string): string => {
        if (keyboardValue === 'delete') {
          // delete: 最後の１文字を削除
          return value.slice(0, -1);
        } else if (keyboardValue === 'clear') {
          // clear: 全文字削除
          return '';
        } else if (keyboardValue === 'close') {
          closeNumericKeyboard();
          return value;
        } else if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyboardValue)) {
          // 数値入力: inputTypeがnumberであれば頭の0を消す。 それ以外はそのまま連結
          const prev = this.state.inputType === EKeyboardInputType.number && value === '0' ? '' : value;
          return `${prev}${keyboardValue}`;
        } else if (keyboardValue === '.') {
          // .: 小数点
          return value.indexOf('.') !== -1 ? value : value + keyboardValue;
        } else if (keyboardValue === '-') {
          // -: マイナス
          return value === '' || value === '0' ? '-' : value;
        } else {
          Logger.error('unkown keyboard key.');
          return value;
        }
      };
      /**
       * forcusEventを受け取り、NumericKeyboardを消すべきときは消す
       */
      const checkFocusForNumericKeyboard = (e: React.FocusEvent) => {
        // HTML要素で無ければ何もしない。（基本そんなことは無いけど）
        if (!e || !e.target || !(e.target instanceof HTMLElement)) {
          return;
        }

        const numericKeyboardInputTypes = [EKeyboardInputType.number, EKeyboardInputType.numberText];

        if (!(e.target instanceof HTMLInputElement) || !e.target.dataset) {
          closeNumericKeyboard();
          return;
        }
        const inputType = e.target.dataset.inputType || '';
        if (!numericKeyboardInputTypes.some(v => v === inputType)) {
          closeNumericKeyboard();
          return;
        }
        this.setState({ ...this.state, inputType: inputType as TKeyboardInputType });
      };
      const onKeyPress = (keyValue: string) => {
        const oldVal = `${this.state.numericKeyboardState}`;
        const newVal = getAfterVal(keyValue, oldVal);
        if (newVal !== oldVal) {
          this.setState({ ...this.state, numericKeyboardState: newVal });
          const res = this.state.numericKeyboardUpdateHandler(newVal);
          if (typeof res === 'string' || typeof res === 'number') {
            const modifedValue = `${res}`;
            if (newVal !== modifedValue) {
              this.setState({ ...this.state, numericKeyboardState: modifedValue });
              this.state.numericKeyboardUpdateHandler(modifedValue);
            }
          }
        }
      };
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            checkFocusForNumericKeyboard={checkFocusForNumericKeyboard}
            connectNumericKeyboard={connectNumericKeyboard}
            closeNumericKeyboard={closeNumericKeyboard}
          />
          <NumericKeyboard
            display={this.state.numericKeyboardShow}
            onKeyPress={onKeyPress}
            displayValue={`${this.state.numericKeyboardState}`}
          />
        </React.Fragment>
      );
    }
  };
};
