import React from 'react';

import cssStyles from './PriceList.module.scss';

import PriceText from '../../atoms/PriceText/PriceText';
import I18TextContainer from '../../../containers/I18Text/I18Text';

import { IPriceRow, TPricePrefix } from '../../../types/orders';
import { II18nItem } from '../../../i18n/_new/types';
import { langResource } from '../../../i18n/_new/resources';

interface IProps {
  rows: IPriceRow[];
  totalTextObj?: II18nItem;
  isTotalBold?: boolean;
  showTotalTaxIn?: boolean;
  totalPrefix?: TPricePrefix | '';
  unShowShippingCost?: boolean;
}
const PriceList: React.FC<IProps> = (props: IProps) => {
  const { rows, totalTextObj, isTotalBold, showTotalTaxIn, totalPrefix, unShowShippingCost } = props;
  // 注文詳細画面のオーダーのブロックの配送料は表示しないようにする
  const filteredRows = rows.filter(row => {
    const isShippingCostRow = row.textObj.jp === langResource.confirmationPage.deliveryFee.jp;
    return !(isShippingCostRow && unShowShippingCost);
  });
  const totalPrice = getTotalPrice(filteredRows);
  return (
    <div id={cssStyles.container}>
      {filteredRows.map((v, i) => renderRow(v, i))}
      {renderTotal()}
    </div>
  );

  function renderRow(row: IPriceRow, key: number) {
    const { prefix, postfix, textObj, price, styles } = row;

    return (
      <div className={cssStyles.row} key={key}>
        <div className={cssStyles.left}>
          <p className={cssStyles.label}>
            <I18TextContainer textObj={textObj} />
          </p>
        </div>
        <div className={cssStyles.right}>
          <PriceText price={price} prefix={prefix} postfix={postfix} styles={styles} />
        </div>
      </div>
    );
  }

  function renderTotal() {
    const labelStyle = isTotalBold ? [cssStyles.label, cssStyles.bold].join(' ') : cssStyles.label;
    const postfix = showTotalTaxIn ? 'tax' : '';

    return (
      <div className={cssStyles.rowTotal}>
        <div className={cssStyles.left}>
          <p className={labelStyle}>
            <I18TextContainer textObj={totalTextObj} />
          </p>
        </div>
        <div className={cssStyles.right}>
          <PriceText price={totalPrice} postfix={postfix} prefix={totalPrefix} />
        </div>
      </div>
    );
  }

  /**
   * 合計金額を算出する。
   * @param priceRows
   * @returns 合計金額
   */
  function getTotalPrice(priceRows: IPriceRow[]): number {
    return priceRows.reduce((pre, cur) => (willReduceThePrice(cur.textObj.jp) ? pre - cur.price : pre + cur.price), 0);
  }

  /**
   * 支払い総額を計算する時にプラスするものかマイナスするものか判定
   * マイナスするものはtrueを返す
   * @param text row.textObj.jp
   */
  function willReduceThePrice(text: string): boolean {
    const campaignCouponStr = langResource.confirmationPage.campaignCoupon.jp;
    const usePointsStr = langResource.confirmationPage.usePoints.jp;
    return [campaignCouponStr, usePointsStr].includes(text);
  }
};

PriceList.defaultProps = {
  totalTextObj: langResource.components.priceList.total,
  isTotalBold: false,
  showTotalTaxIn: true,
  totalPrefix: '',
};

export default PriceList;
