import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store/index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { CommonPageEvents } from '../views/events/CommonPage';
import { mergeMap } from 'rxjs/operators';
import { orderDeleteActions, orderActions } from '../store/order';

const cancelModifiedOrder: Epic<AnyAction, Action<void | boolean>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(CommonPageEvents.cancelModifiedOrder),
    mergeMap(action => {
      return [orderActions.setIsEdit._action(false), orderDeleteActions.deleteEditOrders()];
    }),
  );

export const CommonPageListeners = combineEpics(cancelModifiedOrder);
