import { fetchApi } from '../../api';
import { OrderDetailRes, orderDetailRequestConfig, updateOrderDetailRequestConfig } from '../../api/orders/detail';
import { IUpdateOrderDetailPathParam } from '../../types/orders/digest';
import { toOrder } from '../../helpers/api/converter/order-detail';
import { toDiversionOrder } from '../../helpers/api/orders/conv-state-reverse';
import { getEditOrderRequest } from '../../helpers/api/orders/conv-state2orderRequest';

export async function getOrderDetail(id: string): Promise<OrderDetailRes> {
  return fetchApi(OrderDetailRes, orderDetailRequestConfig, { id });
}

export async function getDiversionOrderDetail(requestSerial: string) {
  return getOrderDetail(requestSerial)
    .then(toOrder)
    .then(res => toDiversionOrder(res, requestSerial))
    .catch(err => err);
}

export async function updateOrderDetail(
  path: IUpdateOrderDetailPathParam,
  body: ReturnType<typeof getEditOrderRequest>,
): Promise<OrderDetailRes> {
  return fetchApi(OrderDetailRes, updateOrderDetailRequestConfig, { path, body });
}
