import React from 'react';

import styles from './PartsDesignConfirmationList.module.scss';
import { IConfirmationOption } from '../../../types/new-store/components';
import PriceTextPlane from '../../atoms/PriceTextSpan/PriceTextSpan';
import { TPricePrefix } from '../../../types/orders';

interface IProps {
  pricePrefix: TPricePrefix;
  options: IConfirmationOption[];
}

const PartsConfirmationList: React.FC<IProps> = (props: IProps) => {
  const { options, pricePrefix } = props;

  if (options.length < 1) {
    return null;
  }

  return (
    <div id={styles.container}>
      <div className={styles.list}>
        {options.map((v, i) => {
          const { optionName, optionClassName, priceTaxIn } = v;
          return (
            <React.Fragment key={i}>
              <dl>
                <dt className={styles.optionName}>{optionName}</dt>
                <dd className={styles.optionClassName}>{optionClassName}</dd>
                <dd className={styles.price}>
                  <PriceTextPlane price={priceTaxIn} prefix={pricePrefix} />
                  <span className={styles.tax}>{`(税込)`}</span>
                </dd>
              </dl>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PartsConfirmationList;
