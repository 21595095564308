import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { infoDialogActions } from '../dialog/info';
import { errorDialogActions } from '../dialog/error';
import { IError } from '../../../types/error';
import history from '../../../helpers/common/history';
import { resolvePath } from '../../../helpers/common/path';
import { ERouterPath } from '../../../types';
import { removeAllCookies } from '../../../services/storage/index';

// どこに書いていいかわからないので、とりあえずここに書いておく
type actionContents = {
  notFound?: string;
};

export const getErrorAction = (dispatch: ThunkDispatch<any, any, AnyAction>, contents?: actionContents) => {
  const notFound = contents ? contents.notFound : contents;
  return {
    systemError: generateErrorAction(dispatch),
    expiredTokenError: generateExpiredTokenErrorAction(dispatch),
    notFoundError: generateNotFoundErrorAction(dispatch, notFound),
  };
};

export const generateErrorAction = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return (errors: IError[]) => errorDialogActions.showErrors.dispatcher(dispatch)(errors);
};

export const generateNotFoundErrorAction = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  contents?: string,
  title?: string,
) => {
  const data = {
    hasOpen: true,
    title: title ? title : '確認',
    contents: contents ? contents : '条件に該当するデータがありません.',
  };
  return () => infoDialogActions.show.dispatcher(dispatch)(data);
};

export const generateExpiredTokenErrorAction = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  contents?: string,
  title?: string,
) => {
  const onOk = () => {
    removeAllCookies();
    history.push(resolvePath(ERouterPath.login));
  };
  const data = {
    hasOpen: true,
    title: title ? title : '確認',
    contents: contents ? contents : '認証トークンの有効期限が切れました。再度ログインしてください',
    onOk,
  };
  return () => infoDialogActions.show.dispatcher(dispatch)(data);
};
