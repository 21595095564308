import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store/index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { HeaderEvents } from '../views/events/Header';
import { loginActions } from '../store/staff';
import { actions as progressActions } from '../store/progress/actions';
import { IProgressRequest } from '../services/progress/pregress';
import { ILoadCustomerParam, customerAction } from '../store/customer/action-reducer';

/**
 * 顧客ログイン処理
 */
const customerLogin: Epic<AnyAction, Action<ILoadCustomerParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.customerLogin),
    map(({ payload }) => customerAction.login.started(payload)),
  );

/**
 * ログアウト処理
 */
const logout: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.logout),
    map(() => loginActions.logout()),
  );

/**
 * 顧客ログアウト処理
 */
const clearCurrentProgress: Epic<AnyAction, Action<void | { gotoHome: boolean }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.clearCurrentProgress),
    map(() => loginActions.clearCurrentProgress({ gotoHome: true })),
  );

/**
 * 一時保留オーダー読み込み
 */
const loadProgressData: Epic<AnyAction, Action<string>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.loadProgressData),
    map(({ payload }) => progressActions.loadProgressData.started(payload)),
  );

/**
 * 一時保留オーダー一覧読み込み
 */
const loadProgressList: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.loadProgressList),
    map(() => progressActions.loadProgressList.started()),
  );

/**
 * 一時保留オーダー削除
 */
const deleteProgressData: Epic<AnyAction, Action<IProgressRequest>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.deleteProgressData),
    map(({ payload }) => progressActions.deleteProgressData.started(payload)),
  );

/**
 * BSTA連携API (coupon取得)
 */
const loadCouponData: Epic<AnyAction, Action<string>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(HeaderEvents.loadCouponData),
    map(({ payload }) => progressActions.loadProgressData.started(payload)),
  );

export const HeaderListeners = combineEpics(
  customerLogin,
  logout,
  clearCurrentProgress,
  loadProgressData,
  loadProgressList,
  deleteProgressData,
  loadCouponData,
);
