import React from 'react';

import styles from './PointCampaign.module.scss';

import Title from '../../atoms/Title/Title';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import NumberFormat from 'react-number-format';

import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { pointPageActions } from '../../../store/pages/point';
import { langResource } from '../../../i18n/_new/resources';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import { IPaymentList } from '../../../types/new-store/components';

interface IProps {
  usePoint: number;
  couponUsePoints: number;
  isEdit: boolean;
  paymentList: IPaymentList;
  onChangeValue: typeof pointPageActions.changeValue.tp;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const PointCampaign: React.FC<TProps> = (props: TProps) => {
  const { couponUsePoints, isEdit } = props;

  const connectNumericKeyboard = () => {
    const { usePoint, paymentList } = props;
    const couponMax = paymentList.orderTotal + paymentList.settlementCost + paymentList.shippingCost - usePoint;
    props.connectNumericKeyboard(couponUsePoints, value => {
      if (+value <= couponMax) {
        props.onChangeValue({ key: 'couponUsePoints', value: +value });
      } else {
        return couponMax;
      }
    });
  };

  return (
    <div className={styles.pointCampaign}>
      <div className={styles.title}>
        <Title styles={['lineLightBrown']} textObj={langResource.pointPage.campaignCoupon} />
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <I18TextContainer textObj={langResource.pointPage.useCampaignCoupon} />
          </div>
          <div className={styles.columnSize2}>
            <div className={styles.inputSet1}>
              <NumberFormat
                thousandSeparator={true}
                readOnly={true}
                disabled={isEdit}
                value={couponUsePoints}
                allowNegative={false}
                // decimalScale={0} FIXME: stateが反映されないので一時的にコメントアウト
                onFocus={connectNumericKeyboard}
                data-input-type={EKeyboardInputType.number}
              />
            </div>
            <div className={styles.button} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointCampaign;
