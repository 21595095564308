import React, { useEffect, useState } from 'react';
import styles from './CouponInfoDialog.module.scss';
import { Dialog, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { langResource } from '../../../i18n/_new/resources';
import Button from '../../atoms/Button/Button';
import { TCouponInfoDialogProps } from '../../../containers/components/dialogs/CouponInfoDialog';
import { TCouponInfo } from '../../../types/coupon';

export const CouponInfoDialog: React.FC<TCouponInfoDialogProps> = (props: TCouponInfoDialogProps) => {
  const { hasOpen, load, results } = props;
  const [data, setData] = useState<TCouponInfo>();
  useEffect(() => {
    if (hasOpen) {
      load();
    }
  }, [hasOpen, load]);

  useEffect(() => {
    setData(results);
  }, [setData, results]);

  const onClose = () => {
    props.close();
  };

  return (
    <React.Fragment>
      <Dialog open={hasOpen} maxWidth={false} fullWidth={true} className={`dialog ${styles.containers}`}>
        <DialogContent>
          <div className={styles.couponInfoContents}>
            <div className={styles.useCouponMessage}>{data?.ecouponUsedFlg ? 'クーポンが利用されました。' : null} </div>
            <dl className={styles.text}>
              <dt>クーポンID</dt>
              <dd>{data?.id || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>発行者（氏名）</dt>
              <dd>{(data?.lastName || '') + (data?.firstName || '')}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>紹介者ID</dt>
              <dd>{data?.userId || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>発行日</dt>
              <dd>{data?.publishDate || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>利用可能期間</dt>
              <dd>{`${data?.expiration}か月` || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>用途</dt>
              <dd>{data?.purpose || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>着用予定日</dt>
              <dd>{data?.hasExpectedDate === 1 ? 'なし' : 'あり' || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>ご購入者との関係</dt>
              <dd>{data?.buyerType || ''}</dd>
            </dl>
            <dl className={styles.text}>
              <dt>メモ</dt>
              <dd>{data?.memo || ''}</dd>
            </dl>
          </div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button onClick={onClose} textObj={langResource.dialog.couponInfo.close} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
