import { TMaster } from '../../types/index';

export const ORDER_ERROR_MESSAGE: TMaster[] = [
  { code: 'E0101001', value: '正しい店舗コードを入力してください。' },
  { code: 'E0101002', value: '正しい担当者コードを入力してください。' },
  { code: 'E0101003', value: '正しい担当者パスワードを入力してください。' },
  { code: 'E0101006', value: 'デザイン・オプションで未入力項目があります。' },
  { code: 'E0101011', value: 'デザイン・オプションで未入力項目があります。' },
  { code: 'E0101012', value: 'デザイン・オプションで未入力項目があります。' },
  { code: 'E0101013', value: 'デザイン・オプションで未入力項目があります。' },
  { code: 'E0101014', value: '推奨ゲージが特定出来ません。' },
  { code: 'E0101019', value: '生地品番を入力してください。' },
  { code: 'E0105010', value: '正しい店舗コードを入力してください。' },
  { code: 'E0105012', value: '正しい担当者コードを入力してください。' },
  { code: 'E0105064', value: '正しい紹介者コードを入力してください。' },
  { code: 'E0301011', value: '正しい店舗コードを入力してください。' },
  { code: 'E0301017', value: '正しい紹介者コードを入力してください。' },
  {
    code: 'E0502001',
    value: '有効なポイント数の上限を超えています。または、本登録されていない為、ポイント利用出来ません。',
  },
  { code: 'E0303001', value: '生地の残反不足です。' },
  { code: 'E0303002', value: '工場キャパオーバーにより納期情報が更新されました。商品確認画面で確認してください。' },
  {
    code: 'E0303013',
    value:
      '品番に対して誤ったモデル（型番）の組み合わせが選択されています。入力した品番とモデル（型番）を確認してください。',
  },
  {
    code: 'E0501004',
    value: '同時に組み合わせられないオプションが設定されています。選択したオプションを確認してください。',
  },
];

export const ORDER_ERROR_CODE_UNMATCH_DELIVERY_DATE = 'E0303002';

export const ORDER_ERROR_CODE_CLOTH_SEASON_UNDEFINED = 'E0101042';

export const ORDER_ERROR_CODE_DISABLE_EDIT = 'E0506001';
