import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  StoreCreator,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from 'redux';
import { AnyAction } from 'typescript-fsa'; // actionCreatorFactory, { AnyAction, Action, Success }
import thunk from 'redux-thunk';
import { createEpicMiddleware, combineEpics } from 'redux-observable'; // combineEpics, Epic,
import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import history from '../helpers/common/history';

import { actions } from './actions';

import { reducer as orderReducer } from './order';
import * as fromPayment from './payment';
import * as fromLookups from './lookups';
import * as fromCustomer from './customer';
import * as fromStaff from './staff';
import * as fromUtils from './utils';
import * as fromOrderDigest from './order-digest';
import * as fromInventory from './inventory';
import * as fromProgress from './progress/actions';
import * as fromSettlement from './settlement';
import * as fromOrderDetail from './order-detail';
import { scProjectReducer } from './sc-project';

// import { ClothSelectionPageEpics as AAA } from './pages/cloth-selection/action-reducers';
import { EventLisnters } from '../eventListener';
import { OrderClothEpics } from './order/cloth/epics';
import { ProgressEpics } from './progress/epics';
import { OrderItemEpics } from './order/item/epics';
import { OrderDesignEpics } from './order/design/epics';
import { InventoryEpics } from './inventory/epics';
import { OrderDigestsEpics } from './order-digest/epics';
import { SizeCorrectionEpics } from './order/size/epics';
import { RouterEpics } from './router/epics';
import { StaffEpics } from './staff/epics';
import { ProductEpics } from './lookups/product/epics';
import { AdjustOptionEpics } from './lookups/adjust-option/epics';
import { DiversionOrderEpics } from './order/diversion/epics';
import { SettlementEpics } from './settlement/epics';
import { OrderEpics } from './order/epics';
import { ApiErrorEpics } from './errorHandling/epic';
import { OrderDetailEpics } from './order-detail/epics';
import { AvailableOptionEpics } from './lookups/available-option/epics';
import { SizeMeasurementEpics } from './lookups/size-measurement/epics';
import { RecommenedGaugeEpics } from './lookups/recommend-gauge/epics';
import { CustomerEpics } from './customer/epics';
import { PurchaseHistoryEpics } from './utils/dialog/purchase-history/epics';
import { OrderShippingEpics } from './order/address/epics';
import { MemoDialogEpics } from './utils/dialog/memo';
import { ProjectEpics } from './utils/dialog/project-selector';
import { CouponInfoEpics } from './utils/dialog/coupon-info';

const reducers = {
  router: connectRouter(history),
  order: orderReducer,
  payment: fromPayment.paymentReducer,
  lookups: fromLookups.reducers,
  staff: fromStaff.reducers,
  customer: fromCustomer.customerReducer,
  utils: fromUtils.reducers,
  orderDigest: fromOrderDigest.orderDigestReducer,
  orderDetail: fromOrderDetail.orderDetailReducers,
  inventory: fromInventory.reducers,
  progress: fromProgress.reducer,
  settlement: fromSettlement.settlementReducer,
  scProject: scProjectReducer,
};

const appReducer = combineReducers(reducers);
export type AppState = ReturnType<typeof appReducer>;
const rootReducer = (state: any, action: any) => {
  if (action.type === actions.updateAll.type) {
    if (action.hasOwnProperty('payload')) {
      return action.payload;
    }
  }
  if (action.type === actions.clearState.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const rootEpic = combineEpics(
  EventLisnters,
  OrderEpics,
  OrderItemEpics,
  OrderClothEpics,
  OrderDesignEpics,
  OrderDetailEpics,
  ProgressEpics,
  InventoryEpics,
  OrderDigestsEpics,
  SizeCorrectionEpics,
  RouterEpics,
  StaffEpics,
  ProductEpics,
  AdjustOptionEpics,
  DiversionOrderEpics,
  SettlementEpics,
  ApiErrorEpics,
  AvailableOptionEpics,
  SizeMeasurementEpics,
  RecommenedGaugeEpics,
  CustomerEpics,
  PurchaseHistoryEpics,
  OrderShippingEpics,
  MemoDialogEpics,
  ProjectEpics,
  CouponInfoEpics,
);
const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState>();

export const setSentryExtraContextMiddleware: Middleware = (api: MiddlewareAPI<Dispatch>) => (
  next: Dispatch<AnyAction>,
) => async (action: any) => {
  // MEMO: 容量が多すぎるとSentryに飛んでいかないので、間引いた
  const { order, payment } = api.getState() as AppState;
  Sentry.configureScope(scope => {
    scope.setExtras({ order, payment });
  });
  return next(action);
};

const enhancers = compose(
  applyMiddleware(
    routerMiddleware(history),
    thunk,
    epicMiddleware,
    createSentryMiddleware(Sentry),
    setSentryExtraContextMiddleware,
  ),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : (f: StoreCreator) => f,
);

export function configureStore(initialState: any) {
  const store = createStore(rootReducer, initialState, enhancers);
  epicMiddleware.run(rootEpic);
  return store;
}
