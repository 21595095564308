import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IInventorySearchState, IInventory } from '../../types/inventory-search';
import actionCreatorFactory from 'typescript-fsa';
import { cloneDeep } from 'lodash';

// actions

const actionCreator = actionCreatorFactory('[Inventory]');

export const actions = {
  load: actionCreator.async<void, IInventory, {}>('load'),
  clear: actionCreator<void>('clear'),
};

// reducer

const initialState: IInventorySearchState = {
  results: {
    updateDate: '',
    count: 0,
    stocks: [],
  },
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.load.done, (state, payload) => ({ ...state, results: payload.result }))
  .case(actions.clear, state => cloneDeep(initialState));
