import actionCreatorFactory from 'typescript-fsa';
import { ILoadOrderDetail, TSetOrderDigistFilter } from '../../types/orders/digest';
import { ILoadCustomerParam } from '../../store/customer/action-reducer';

const actionCreator = actionCreatorFactory('- OrderDigestsSearchPage');

export const OrderDigestsSearchPageEvents = {
  /**
   * 注文検索画面の検索条件変更時の処理
   */
  setFilter: actionCreator<TSetOrderDigistFilter>('setFilter'),

  /**
   * 注文検索画面の検索ボタン押下時の処理
   */
  load: actionCreator<void>('loadOrderDigests'),

  /**
   * 詳細情報を読み込む
   */
  onCreateRefOrder: actionCreator<{ loadOrderDetailParam: ILoadOrderDetail; loginParam: ILoadCustomerParam }>(
    'onCreateRefOrder',
  ),

  /**
   * 注文検索画面の検索条件リセットの処理
   */
  resetFilter: actionCreator<void>('resetFilter'),
};
