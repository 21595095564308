import { TRequestConfig, TRequestParams } from '../../';
// import config from '../../../configuration/config';

export { StockRes } from './StockRes.class';

export const stockRequestConfig: TRequestConfig = {
  endpoint: '/customizesystemproxy/stock',
  method: 'GET',
  useMock: false,
  mockFunc: () => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return { query: params ? params.query : {} };
  },
};
