import { IClothProduct } from '../store/_type/lookups/index';
import { IItem, TPartialCloth, IComposition } from '../store/_type/order';
import { TOrderItemCode } from '../lookups/master-thisisforreplaceall/item-code';
import { clothProductsSelector } from '../store/lookups/object-selector';
import {
  KR_BRAND,
  YC_BRANDS,
  KR_BRANDS,
  CLOTH_PARTITION_DEFAULT,
  RT_BRANDS,
  CLOTH_PARTITION_ORDERING,
} from '../lookups/item-thisisforreplaceall/cloth';
import { INITIAL_COMPOSITION } from '../store/order/initial-state';
import { isShirtOrder } from './orders/category';

export const fillCompositions = (compositions: IComposition[], length: number): IComposition[] =>
  Array.from({ length }).map((v, i) => ({ ...(compositions[i] ? compositions[i] : INITIAL_COMPOSITION) }));

/**
 * 現在の選択状態から、item.cloth配下の更新べき設定値を取得
 * 生地選択画面で、生地品番を変えた時/モデル選択プルダウンを変えた時/ブランド選択プルダウンを変えた時に呼び出される。
 * @param products 生地商品一覧
 * @param item アイテム
 * @param itemCode アイテムコード
 * @param selectedBrand ブランドコード
 * @param isChangedClothCode 生地品番更新時か？
 */
export function getExtractedProductPartialCloth(
  products: IClothProduct[],
  item: IItem,
  itemCode: TOrderItemCode,
  selectedBrand: string,
  isChangedClothCode: boolean = false, //
): TPartialCloth | undefined {
  const { categoryCode, pieces, subCategoryCode } = item;
  const selector = clothProductsSelector({ products, itemCode, brandCode: selectedBrand, subCategoryCode });
  if (!selector.hasProduct) {
    return;
  }
  const {
    personalorderColorCode,
    design,
    mixings,
    vendorClothNumber,
    clothSeasonCode,
    productSeasonCode,
    clothBrandCode,
    personalorderProductNumber,
  } = selector.nearestProduct;
  const brandCode = selector.selectableBrandCode;
  const clothModelCode = selector.getClothModelCodeForUpdateCloth(
    categoryCode,
    pieces,
    subCategoryCode,
    item.cloth.clothModelCode,
  );
  const cloth: TPartialCloth = {
    brandCode,
    clothModelCode,
    personalorderProductNumber,
    clothSeasonCode,
    productSeasonCode,
    clothBrandCode,
  };

  const isKR = KR_BRANDS.includes(brandCode || '');
  const isKROnly = KR_BRAND.includes(brandCode || '');
  if (!isKR || isChangedClothCode) {
    cloth.design = design;
    cloth.compositionFront = fillCompositions(mixings.front, 6);
    cloth.compositionBack = fillCompositions(mixings.back, 2);
    cloth.vendorClothNumber = vendorClothNumber;
  }
  // KRブランドの場合、色コード005固定
  if (!isKROnly || isChangedClothCode) {
    cloth.personalorderColorCode = personalorderColorCode;
  }
  // brandCodeがKR、YCでない場合、stockPlaceCodeに 4:工場 を入れる
  if (!isKR && !YC_BRANDS.includes(brandCode || '')) {
    cloth.stockPlaceCode = CLOTH_PARTITION_DEFAULT;
  }
  // カテゴリがシャツかつbrandCodeがRT、OCだった場合、stockPlaceCodeに 5:発注 を入れる
  if (isShirtOrder(categoryCode) && RT_BRANDS.includes(brandCode || '')) {
    cloth.stockPlaceCode = CLOTH_PARTITION_ORDERING;
  }
  return cloth;
}
