import actionCreatorFactory from 'typescript-fsa';
import { TDiversionOrder } from '../../../helpers/api/orders/conv-state-reverse';
import { ILoadOrderDetail } from '../../../types/orders/digest';

const actionCreator = actionCreatorFactory('[Order/diversion]');
const actions = {
  loadDiversionOrder: actionCreator.async<ILoadOrderDetail, TDiversionOrder, {}>('loadDiversionOrder'),
  setOrder: actionCreator.async<TDiversionOrder, {}, {}>('setOrder'),
};

export const diversionOrderActions = actions;
