import { AppState } from '../../..';
import { ISaveTmpOrderDialog } from '../../../../types/dialog';
import { createSelector } from 'reselect';

export { reducer as saveTmpOrderReducer, actions as saveTmpOrderActions } from './action-reducer';

const featureSelector = (state: AppState): ISaveTmpOrderDialog => state.utils.dialog.saveTmpOrder;

export const get = {
  data: createSelector(featureSelector, state => state),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
};
