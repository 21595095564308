import { AppState } from '../../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OrderDetailPageEvents } from '../../../views/events/OrderDetailPage';
import { getLanguage } from '../../../store/utils/language';
import { getConfirmation } from '../../../store/order/selector/confirmation';
import { mapCommonPageDispatchToProps, mapCommonPageStateToProps } from '../CommonPage';
import { getStaff } from '../../../store/staff';
import { getOrderDetail } from '../../../store/order-detail';
import { getOrderDigests, getSelectedOrderDigest } from '../../../store/order-digest';

import OrderDetailPage from '../../../components/pages/OrderDetailPage/OrderDetailPage';
import Logger from '../../../helpers/common/logger';
import { getPointPage } from '../../../store/payment/selector';

function mapStateToProps(state: AppState) {
  return {
    orderDate: getOrderDetail.orderDate(state),
    staff: getOrderDetail.staff(state),
    customer: getOrderDetail.customer(state),
    hasCurrentOrder: getOrderDetail.hasCurrentOrder(state),
    ordersDigests: getOrderDigests.digests(state),
    orderStatus: getSelectedOrderDigest.orderStatus(state),
    hasCancel: getSelectedOrderDigest.hasCancel(state),
    pricePrefix: getLanguage.pricePrefix(state),
    orderConfirmations: getConfirmation.confirmationsForOrderDetailPage(state),
    paymentConfirmation: getConfirmation.paymentConfirmationForOrderDetail(state),
    note: getConfirmation.note(state),
    isOnlyDummyData: getPointPage.isOnlyDummyData(state),
    ...mapCommonPageStateToProps(state),
    //
    newOrderNumber: '',
    loggedStaff: getStaff.staff(state),
    isManager: getStaff.isManager(state),
    productKind: getOrderDetail.productKind(state),
    scProject: getConfirmation.scProjectFromOrderDetail(state),
    pending: getOrderDetail.pending(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(OrderDetailPageEvents, dispatch),
    ...mapCommonPageDispatchToProps(dispatch),
    onEditOrder: () => {
      Logger.log('unimplemented');
    },
    onCopyOrder: () => {
      Logger.log('unimplemented');
    },
    onDeleteOrder: () => {
      Logger.log('unimplemented');
    },
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);
