import actionCreatorFactory from 'typescript-fsa';
import clone from 'ramda/es/clone';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { TScProject } from '../../types/project';
import { NestedPartial } from '../../types';
import { INITIAL_OBJECT } from '../../lookups/initial-object';

const ac = actionCreatorFactory('[state/scProject]');
const actions = {
  set: ac<TScProject>('set'),
  update: ac<NestedPartial<TScProject>>('update'),
  reset: ac<void>('reset'),
};
export const scProjectActions = actions;

const initialState = clone(INITIAL_OBJECT.scProject);
const reducer = reducerWithInitialState(initialState)
  .case(actions.set, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .case(actions.update, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .case(actions.reset, (state, payload) => ({
    ...state,
    ...initialState,
  }));
export const scProjectReducer = reducer;
