import React from 'react';

import styles from './Footer.module.scss';

import Button from '../../atoms/Button/Button';
import LinkButton from '../../molecules/LinkButton/LinkButton';
import { ERouterPath } from '../../../types';
import { IFooterButton, TFooterButtonType } from '../../../types/footer';
import { II18nItem } from '../../../i18n/_new/types';
import { langResource } from '../../../i18n/_new/resources';

interface IProps {
  buttons: IFooterButton[];
}

const Footer: React.FC<IProps> = (props: IProps) => {
  const { buttons } = props;

  const renderButton = (type: TFooterButtonType) => {
    const button = buttons.find(btn => btn.type === type);
    if (!button) {
      return null;
    }

    const isRender = typeof button.isRender === 'undefined' ? true : button.isRender;
    if (!isRender) {
      return null;
    }

    if (!button.path) {
      return renderButtonElement(button);
    }
    return renderLinkElement(button, button.path);
  };

  const renderButtonElement = (button: IFooterButton) => {
    const { isDisabled, func, type, classes } = button;
    return (
      <div className={styles.btnSet}>
        <Button
          textObj={getTextKey(button)}
          styles={getClassName(type, classes)}
          disabled={getDisabled(isDisabled)}
          onClick={func}
        />
      </div>
    );
  };

  const renderLinkElement = (button: IFooterButton, path: ERouterPath) => {
    const { isDisabled, type, func, classes } = button;
    return (
      <div className={styles.btnSet}>
        <LinkButton
          textObj={getTextKey(button)}
          styles={getClassName(type, classes)}
          disabled={getDisabled(isDisabled)}
          to={path}
          onClick={func}
        />
      </div>
    );
  };

  const getDisabled = (isDisabled?: boolean): boolean => {
    return typeof isDisabled === 'undefined' ? false : isDisabled;
  };

  const getTextKey = (button: IFooterButton): II18nItem => {
    const { textObj, type } = button;
    if (textObj) {
      return textObj;
    }

    switch (type) {
      case 'home':
        return langResource.footer.home;

      case 'save':
        return langResource.footer.save;

      case 'back':
        return langResource.footer.back;

      case 'next':
        return langResource.footer.next;

      case 'print':
        return langResource.footer.print;

      case 'confirm':
        return langResource.footer.order;

      default:
        throw new Error('フッターのボタンのラベルが未設定です.');
    }
  };

  const getClassName = (type: TFooterButtonType, classes: string[] = []): string[] => {
    const baseClasses = [...classes, 'black'];
    switch (type) {
      case 'home':
        return [...baseClasses, 'home', 'layout-portrait-icon-button'];

      case 'save':
        return [...baseClasses];

      case 'print':
        return [...baseClasses];

      case 'back':
        return [...baseClasses, 'arwLeft', 'layout-portrait-icon-button'];

      case 'next':
        return [...baseClasses, 'arwRight', 'layout-portrait-icon-button'];

      case 'confirm':
        return [...baseClasses, 'red', 'square', 'footerRight'];

      default:
        return baseClasses;
    }
  };

  return (
    <div className={styles.footer}>
      <div className={styles.leftBlock}>
        <div className={styles.leftBtnWrapper}>
          {renderButton('home')}
          {renderButton('save')}
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.centerBtnWrapper}>
          {renderButton('back')}
          {renderButton('next')}
          {renderButton('print')}
        </div>
        <div className={styles.rightBtnWrapper}>{renderButton('confirm')}</div>
      </div>
    </div>
  );
};

export default Footer;
