import { SPARE_OPTION_CONFIG } from '../../lookups/item-thisisforreplaceall';
import { isValidMemberscardNumber } from '../../store/order/helper/validate';
import { isMyTempoOrder, isBeforeEvans } from '.';

/**
 * can-input-select：入力・選択できるかできないか？
 */

/** スペアパーツで選択できるオプションか */
export const canSelectSpareOption = (optionNumber: string): boolean =>
  SPARE_OPTION_CONFIG.some(v => v.spareOption === optionNumber);

/** 入力するポイント欄の制御を行う */
export const canInputUsePoint = (useablePoints: number, memberscardNumber: string): boolean =>
  useablePoints > 0 && isValidMemberscardNumber(memberscardNumber);

// 一旦ここに置いておく
type TModifyCondtion = {
  tempoCode?: string;
  loggedTempoCode: string;
  orderDate: string;
  isManager: boolean;
  memberscardNumber: string | number;
  testDate?: Date;
};

/** 修正可能な注文か */
export const canModifyOrder = (params: TModifyCondtion): boolean => {
  const { orderDate, tempoCode, loggedTempoCode, isManager, testDate } = params;
  if (isManager) {
    return true;
  }
  return isBeforeEvans(orderDate, testDate) && isMyTempoOrder(loggedTempoCode, tempoCode);
};
