import { IStepbar } from '../../types/stepbar';
import { ITEM_STEPS, ORDER_STEPS } from '../../lookups/stepbar';
import { ERouterPath } from '../../types';
import { resolvePath } from '../common/path';
import { ItemContentPageEvents } from '../../views/events/ItemContentPage';
import history from '../common/history';

export function getItemStepbar(
  currentPath: string,
  hasClothCompleted: boolean,
  hasDesignCompleted: boolean,
  isEdit: boolean,
  onShowRequiredCheckDialog: typeof ItemContentPageEvents.onShowRequiredCheckDialog,
  requiredCheckCompleted: boolean,
  errorTextForRequiredCheckDialog: string[],
): IStepbar {
  const canSize = hasClothCompleted && hasDesignCompleted;
  const states = [true, hasClothCompleted, canSize];
  const steps = ITEM_STEPS.map((step, index) => {
    return {
      ...step,
      isDisabled: isEdit || !states[index],
      onClick:
        !requiredCheckCompleted && step.path === ERouterPath.sizeCorrection
          ? () =>
              onShowRequiredCheckDialog({
                hasOpen: true,
                texts: errorTextForRequiredCheckDialog,
                onClick: () => history.push(resolvePath(ERouterPath.clothSelection)),
              })
          : undefined,
    };
  });

  return {
    currentPath,
    steps,
  };
}

export function getOrderStepbar(
  currentPath: string,
  isValidAllOrders: boolean,
  isLoggedInCustomer: boolean,
  hasAddressCompleted: boolean,
  onShowCustomerLoadDialog: any,
  location: string,
  membersCardNumber: string,
  isEdit: boolean,
): IStepbar {
  const states = [isValidAllOrders, isLoggedInCustomer || hasAddressCompleted, hasAddressCompleted];
  const steps = ORDER_STEPS.map((step, index) => {
    return {
      ...step,
      isDisabled: isEdit || !states[index],
      onClick:
        step.path === ERouterPath.address &&
        location === resolvePath(ERouterPath.itemConfirmation) &&
        !isEdit &&
        !membersCardNumber
          ? () => onShowCustomerLoadDialog({ hasOpen: true, hasError: false, isLoading: false })
          : undefined,
    };
  });
  return {
    currentPath,
    steps,
  };
}
