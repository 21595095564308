import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import * as Sentry from '@sentry/browser';

import { AppState } from '../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, tap, debounceTime } from 'rxjs/operators';
import { actions as globalActions } from '../actions';
import { actions as routerActions } from '../router/actions';
import { loginActions } from './action-reducer';
import { removeAllCookies } from '../../services/storage/';
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { IStaff } from '../_type/staff';
import { removeCookie } from '../../services/storage/index';

/**
 * ログアウト
 */
const logout: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(loginActions.logout),
    tap(removeAllCookies),
    tap(() => {
      Sentry.configureScope(scope => {
        scope.clear();
      });
    }),
    debounceTime(400),
    mergeMap(() => [globalActions.clearState(), routerActions.gotoLogin()]),
  );

/**
 * 作業中データのクリア（= 顧客ログアウト）
 */
const clearCurrentProgress: Epic<AnyAction, Action<void | IStaff>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(loginActions.clearCurrentProgress),
    tap(() => removeCookie('progressKey')),
    mergeMap(({ payload }) => {
      const staff = { ...appStateSelector(state.value).staff() } as IStaff;
      const moveHomePageAction = payload && payload.gotoHome ? [routerActions.gotoHome()] : [];
      return [globalActions.clearState(), loginActions.login(staff), ...moveHomePageAction];
    }),
  );

export const StaffEpics = combineEpics(logout, clearCurrentProgress);
