import { createSelector } from 'reselect';
import { orderStateSelector, orderSelector, compositionSelector } from '../object-selector';
import { IOrderState } from '../';
import { ILookupItem } from '../../../types/lookup';
import { orderState } from './order';
import { getProduct } from '../../lookups';
import {
  getClothColorLookups,
  CLOTH_DESIGN_LOOKUPS,
  CLOTH_PARTITION_LOOKUPS,
  SELECTABLE_COMPOSITION_LOOKUPS,
  ALL_COMPOSITION_LOOKUPS,
} from '../../../lookups/item-thisisforreplaceall';
import { ISelectItem } from '../../../types/new-store/components';
import { KR_BRAND, KR_BRANDS, YC_BRANDS } from '../../../lookups/item-thisisforreplaceall';
import {
  isClothInputCompleted,
  isRequiredScaleInputCompleted,
  isCompositionBackInputCompleted,
} from '../../../helpers/item-thisisforreplaceall';
import Logger from '../../../helpers/common/logger';
import { NOSELECT_COMPOSITION_MIXING } from '../initial-state';

const getItem = (state: IOrderState) => orderSelector(orderStateSelector(state).currentOrder()).item();
const canAddtionalInput = (brandCode: string): boolean => {
  return KR_BRANDS.includes(brandCode);
};
const canAddtionalInputColorDesign = (brandCode: string): boolean => {
  return KR_BRAND.includes(brandCode);
};
/** 着分区分の場合、ブランドYCも修正できる */
const canSelectStockPlace = (brandCode: string): boolean =>
  canAddtionalInput(brandCode) || YC_BRANDS.includes(brandCode);

const initialLookupItem: ILookupItem = {
  id: '',
  value: ' ',
};

const getIsCompositionFrontValueCorrect = createSelector(orderState, order => {
  try {
    const { compositionFront, brandCode } = getItem(order).cloth;
    // ブランドK以外の時はチェックしない(必ずtrueを返す)
    if (!KR_BRANDS.includes(brandCode)) {
      return true;
    }
    return compositionSelector(compositionFront).isInputCorrect();
  } catch (error) {
    Logger.log(error);
    return false;
  }
});

export const getClothPage = {
  /** 追加情報が入力できるか(KRの時?) */
  canAddtionalInput: createSelector(orderState, order => {
    const item = getItem(order);
    const { brandCode } = item.cloth;
    return canAddtionalInput(brandCode);
  }),
  canAddtionalInputColorDesign: createSelector(orderState, order => {
    const item = getItem(order);
    const { brandCode } = item.cloth;
    return canAddtionalInputColorDesign(brandCode);
  }),
  canSelectStockPlace: createSelector(orderState, order => {
    const item = getItem(order);
    const { brandCode } = item.cloth;
    return canSelectStockPlace(brandCode);
  }),
  brandSelectItem: createSelector(orderState, getProduct.current.seletableBrands, (order, brandCodes) => {
    const item = getItem(order);
    const { brandCode } = item.cloth;
    return getSelectorItem(brandCode, brandCodes);
  }),
  modelSelectItem: createSelector(orderState, getProduct.current.selectableClothModels, (order, modelCodes) => {
    const item = getItem(order);
    const { clothModelCode } = item.cloth;
    return getSelectorItem(clothModelCode, modelCodes);
  }),
  colorSelectItem: createSelector(orderState, order => {
    const item = getItem(order);
    const { pieces } = item;
    const { brandCode, personalorderColorCode } = item.cloth;
    const isDisable = !canAddtionalInputColorDesign(brandCode);
    const data = getClothColorLookups(pieces);
    return {
      data: [initialLookupItem, ...data],
      isDisable,
      selected: personalorderColorCode,
    };
  }),
  designSelectItem: createSelector(orderState, order => {
    const item = getItem(order);
    const { brandCode, design } = item.cloth;
    const isDisable = !canAddtionalInputColorDesign(brandCode);
    const data = [initialLookupItem, ...CLOTH_DESIGN_LOOKUPS];
    return {
      data,
      isDisable,
      selected: design,
    };
  }),
  stockSelectItem: createSelector(orderState, order => {
    const item = getItem(order);
    const { brandCode, stockPlaceCode } = item.cloth;
    const isDisable = !canAddtionalInput(brandCode);
    const data = [initialLookupItem, ...CLOTH_PARTITION_LOOKUPS];
    return {
      data,
      isDisable,
      selected: stockPlaceCode,
    };
  }),
  compositionLookups: createSelector(orderState, order => {
    const item = getItem(order);
    const { brandCode } = item.cloth;
    const master = canAddtionalInput(brandCode) ? SELECTABLE_COMPOSITION_LOOKUPS : ALL_COMPOSITION_LOOKUPS;
    return [{ id: NOSELECT_COMPOSITION_MIXING, value: ' ' }, ...master];
  }),
  hasCompleted: createSelector(
    orderState,
    getIsCompositionFrontValueCorrect,
    (order, isCompositionFrontValueCorrect) => {
      try {
        const currentOrder = orderStateSelector(order).currentOrder();
        return isClothInputCompleted(currentOrder) && isCompositionFrontValueCorrect;
      } catch (error) {
        Logger.log(error);
        return false;
      }
    },
  ),

  isRequiredScaleInputCompleted: createSelector(orderState, order => {
    try {
      const item = getItem(order);
      return isRequiredScaleInputCompleted(item);
    } catch (error) {
      Logger.log(error);
      return false;
    }
  }),

  isCompositionBackInputCompleted: createSelector(orderState, order => {
    try {
      const item = getItem(order);
      return isCompositionBackInputCompleted(item);
    } catch (error) {
      Logger.log(error);
      return false;
    }
  }),

  isCompositionBackValueCorrect: createSelector(orderState, order => {
    try {
      const { compositionBack, brandCode } = getItem(order).cloth;
      // ブランドK以外の時はチェックしない(必ずtrueを返す)
      if (!KR_BRANDS.includes(brandCode)) {
        return true;
      }
      return compositionSelector(compositionBack).isInputCorrect();
    } catch (error) {
      Logger.log(error);
      return false;
    }
  }),
  isCompositionFrontValueCorrect: createSelector(
    getIsCompositionFrontValueCorrect,
    isCompositionFrontValueCorrect => isCompositionFrontValueCorrect,
  ),
};

// 入力チェックエラーダイアログ用テキスト作成
export const getErrorTextsForRequiredCheckDialog = createSelector(
  getClothPage.isRequiredScaleInputCompleted,
  getClothPage.isCompositionBackInputCompleted,
  getClothPage.isCompositionBackValueCorrect,
  (requiredScaleInputCompleted, compositionBackInputCompleted, compositionBackValueCorrect) => {
    const texts: string[] = [];
    if (!requiredScaleInputCompleted) {
      texts.push('「要尺」を入力してください');
    }
    if (!compositionBackInputCompleted) {
      texts.push('「生地組成（裏地）」を入力してください');
    }

    if (compositionBackInputCompleted && !compositionBackValueCorrect) {
      texts.push('「生地組成（裏地）」を正しく入力してください');
    }
    return texts;
  },
);

export const requiredCheckCompleted = createSelector(
  getClothPage.isRequiredScaleInputCompleted,
  getClothPage.isCompositionBackInputCompleted,
  getClothPage.isCompositionBackValueCorrect,
  (requiredScaleInputCompleted, compositionBackInputCompleted, compositionBackValueCorrect) =>
    requiredScaleInputCompleted && compositionBackInputCompleted && compositionBackValueCorrect,
);

// TODO: これもできれば共通系に持っていきたい
function getSelectorItem(selected: string, codes: string[]): ISelectItem {
  const data = toLookups(codes);
  const isDisable = codes.length <= 1;
  return {
    data,
    selected,
    isDisable,
  };
}

// TODO: これもできればいい感じに直して共通系に持っていきたい
function toLookups(codes: string[]): ILookupItem[] {
  const items = codes.map(v => {
    return {
      id: v,
      value: v,
    };
  });
  return [initialLookupItem, ...items];
}
