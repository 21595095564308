import { AppState } from '../../index';

export enum ActionType {
  SET_CURRENT_PATH = '[Header] SET_CURRENT_PATH',
}

export type SetCurrentPath = {
  type: typeof ActionType.SET_CURRENT_PATH;
  payload: string;
};

export function setCurrentPath(payload: string): SetCurrentPath {
  return {
    type: ActionType.SET_CURRENT_PATH,
    payload,
  };
}

// FIXME: create new navigation store and state has only currentPath.
export function getCurrentPath(state: AppState): string {
  const currentPath = window.location.pathname;
  return currentPath;
}

export type Actions = SetCurrentPath;
