import { IOrderState } from '../';
import { shouldBeTotalPriceZero } from '../../../helpers/orders';

/**
 * IOrderState からデータを取得する関数郡
 */
export const orderStateSelector = (state: IOrderState) => new OrderStateSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class OrderStateSelector {
  constructor(private state: IOrderState) {}
  public currentOrder() {
    return this.state.orders[this.state.currentOrderNumber];
  }

  public hasCurrentOrder(): boolean {
    return this.state.currentOrderNumber !== '' && this.state.orders.hasOwnProperty(this.state.currentOrderNumber);
  }

  get shippingCostTotal(): number {
    const { orders, productKind } = this.state;
    if (shouldBeTotalPriceZero(productKind)) {
      return 0;
    }
    return Object.keys(orders)
      .map(orderNumber => {
        return +orders[orderNumber].shipping.shippingCost;
      })
      .reduce((p, c) => p + c, 0);
  }
}
