/**
 * ルーティング関連：AppState.routerは、connected-react-router が管理しているstateなので直接変更しない
 */
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('[Router]');

export const actions = {
  gotoLogin: actionCreator<void>('gotoLogin'),
  gotoHome: actionCreator<void>('gotoHome'),
  gotoOrderConfirmation: actionCreator<void>('gotoOrderConfirmation'),
  gotoSettlementEnd: actionCreator<void>('gotoSettlementEnd'),
  gotoItemConfirmation: actionCreator<void>('gotoItemConfirmation'),
};
