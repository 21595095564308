import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { AppState } from '../index';
import { reducer as inventoryReducer } from './action-reducer';
import { IInventorySearchState } from '../../types/inventory-search';

export { actions, reducer as inventoryReducer } from './action-reducer';

export interface IInventoryFeature {
  inventory: IInventorySearchState;
}

export const reducers = combineReducers({
  inventory: inventoryReducer,
});

const featureSelector = (state: AppState): IInventoryFeature => state.inventory;

export const get = {
  inventories: createSelector(featureSelector, state => state.inventory.results.stocks),
  updateAt: createSelector(featureSelector, state => state.inventory.results.updateDate),
};
