import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { ConnectedRouter } from 'connected-react-router';
import history from '../helpers/common/history';

import LoginPageContainer from '../containers/pages/LoginPage';
import { getStaff } from '../store/staff';
import { ERouterPath } from '../types/index';
import { resolvePath } from '../helpers/common/path';
import Logger from '../helpers/common/logger';

interface IState {
  loggedIn: boolean;
}

function mapStateToProps(state: AppState) {
  return {
    loggedIn: getStaff.isLoggedIn(state),
  };
}

type TProps = ReturnType<typeof mapStateToProps>;

class AppRouterGuard extends Component<TProps, IState> {
  /** propsのloggedInとstateのloggedInが違う時だけstateを更新する */
  static getDerivedStateFromProps(nextProps: TProps, prevState: IState) {
    if (nextProps.loggedIn === prevState.loggedIn) {
      return null;
    }
    Logger.log('getDerivedStateFromProps changed', nextProps.loggedIn);
    return {
      loggedIn: nextProps.loggedIn,
    };
  }

  constructor(props: TProps) {
    super(props);
    this.state = { loggedIn: props.loggedIn };
  }

  render() {
    if (this.state.loggedIn) {
      return this.props.children;
    }

    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact={true} path={resolvePath(ERouterPath.login)} component={LoginPageContainer} />
          <Redirect to={resolvePath(ERouterPath.login)} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default connect(mapStateToProps)(AppRouterGuard);
