import { CustomerRes } from '../../../api/customer';
import { ICustomer } from '../../../store/_type/customer';

export function toCustomer(res: CustomerRes): ICustomer {
  const street = res.query('customerStreet');
  const residence = res.query('customerResidence');
  return {
    customerCode: res.query('customerCode'),
    customerSex: res.query('customerSex'),
    customerBirthday: res.query('customerBirthday'),
    memberscardNumber: res.query('memberscardNumber') || '',
    shippingPostalCode: res.query('customerPostalCode'),
    shippingState: res.query('customerState'),
    shippingCity: res.query('customerCity'),
    shippingStreet: !residence ? street : `${street} ${residence}`,
    customerFamilyNameKana: res.query('customerFamilyNameKana'),
    customerGivenNameKana: res.query('customerGivenNameKana'),
    customerFamilyNameKanji: res.query('customerFamilyName'),
    customerGivenNameKanji: res.query('customerGivenName'),
    shippingPhoneNumber: res.query('customerPhoneNumber'),
    customerMailAddress: res.query('pcMailAddress'),
  };
}
