import { TRequestConfig, TRequestParams } from '../../_common/types';

export { OrderCancelRes } from './OrderCancelRes.class';

export const orderCancelRequestConfig: TRequestConfig = {
  endpoint: '/orders/cancel/{serial}',
  method: 'POST',
  useMock: false,
  mockFunc: undefined,
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        serial: params ? params.orderNumber : '',
      },
    };
  },
};
