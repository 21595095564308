import { rawStrArr2EnumLikeObj } from '../lib/utils';

// HTML属性 data-input-type の値として設定出来る文字列リスト
const keyboardInputTypes = rawStrArr2EnumLikeObj(['number', 'number-text']);
export type TKeyboardInputType = keyof typeof keyboardInputTypes;

export const EKeyboardInputType = {
  number: keyboardInputTypes.number,
  numberText: keyboardInputTypes['number-text'],
};
