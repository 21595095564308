import { IOrder } from '../../_type/order';
import prop from 'ramda/es/prop';

/**
 * IOrder からデータを取得する関数郡
 */
export const orderSelector = (state: IOrder) => new OrderSeletor(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class OrderSeletor {
  constructor(private state: IOrder) {}
  public item() {
    return prop('item', this.state);
  }
}
