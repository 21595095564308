import { TRequestConfig, TRequestParams } from '../..';
import { joinGauge } from '../../../helpers/size-correction';
// import config from '../../../configuration/config';
export { StandardSizeRes } from './standard-size.class';

export const standardSizeRequestConfig: TRequestConfig = {
  endpoint: '/items/sizes/standards/{brand_code}/{parts_number}/{model_code}/{gauge}/{part_model_code}',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.path : '';
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        brand_code: params ? params.path.brandCode : '',
        parts_number: params ? params.path.partsNumber : '',
        model_code: params ? params.path.modelCode : '',
        gauge: params ? joinGauge(params.path.gauge, true) : '',
        part_model_code: params ? params.path.partModelCode : '',
      },
    };
  },
};
