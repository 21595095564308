import { TRequestConfig, TRequestParams } from '../..';
// import config from '../../../configuration/config';
export { SizeMeasurementRes } from './size-measurement.class';

export const sizeMeasurementRequestConfig: TRequestConfig = {
  endpoint: '/items/sizes/measurements/{brand_code}/{item_code}/{season_code}',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.path : '';
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        brand_code: params ? params.path.brandCode : '',
        item_code: params ? params.path.itemCode : '',
        season_code: params ? params.path.seasonCode : '',
      },
      query: {
        // 配列をカンマ区切りに変換
        option_pattern: params?.path.modelPatterns.join(','),
      },
    };
  },
};
