import { createSelector } from 'reselect';

import { orderState } from './selector/order';
// import { IOrderState } from './action-reducer';
// import { currentItem } from './selector/item';
// import { currentCloth } from './selector/cloth';
// import { currentSize } from './selector/size';
// import { currentDesign } from './selector/design';
import { IOrder, TProductKind } from '../_type/order';
import { IndexedObject } from '../../types/index';
import Logger from '../../helpers/common/logger';

export { reducer, actions as orderActions, orderDeleteActions } from './action-reducer';

export interface IOrderState {
  currentOrderNumber: string;
  orders: IndexedObject<IOrder>;
  productKind: TProductKind;
  isEdit: boolean;
}

export interface IOrderFeature {
  order: IOrderState;
}

export const get = {
  /** オーダー一覧を返す */
  orders: createSelector(orderState, state => state.orders),
  currentOrderNumber: createSelector(orderState, state => state.currentOrderNumber),
  hasCurrentOrder: createSelector(orderState, state => !!state.orders && !!state.orders[state.currentOrderNumber]),
  /** 選択中のオーダーを返す */
  currentOrder: createSelector(orderState, state => state.orders[state.currentOrderNumber]) /** オーダー番号を返す */,
  orderNumber: createSelector(orderState, state => state.currentOrderNumber),
  productKind: createSelector(orderState, state => state.productKind),
  isEdit: createSelector(orderState, state => state.isEdit),
  serial: createSelector(orderState, state => {
    const { isEdit, orders, currentOrderNumber } = state;
    if (!isEdit || Object.keys(orders).length < 1 || !orders[currentOrderNumber]) {
      return '';
    }
    const order = orders[currentOrderNumber];
    if (!order.serialYear || !order.serialNumber) {
      return '';
    }
    return `${order.serialYear}${order.serialNumber}`;
  }),
  hasFilledItem: createSelector(
    orderState,
    state =>
      state.orders &&
      Object.keys(state.orders).length > 0 &&
      Object.entries(state.orders).every(([i, order]) => !!order.item),
  ),
  customerName: createSelector(orderState, state => {
    try {
      if (state.orders[state.currentOrderNumber]) {
        const { customerFamilyNameKanji, customerGivenNameKanji } = state.orders[state.currentOrderNumber].shipping;
        return `${customerFamilyNameKanji} ${customerGivenNameKanji}`.trim();
      }
    } catch (error) {
      Logger.log('customerName', error);
      return '';
    }
  }),
};
