import { TNewOrderRequest, TNewOrderRequestItem } from './new-order-request';

// クライアントの変更を無視して、注文詳細情報取得APIのresをそのまま返すproperty
export const IGNORE_CLIENT_CHANGED_ITEM_PROPERTIES: Array<keyof TNewOrderRequestItem> = [
  'hurrying',
  'modified',
  'note', // 注文修正では備考は編集しないため
];

// 注文詳細情報取得APIのresを無視して、必ずクライアントの値を返却するproperty（本来はそうだよね。。。）
export const CLIENT_VALUE_ORDER_PROPERTIES: Array<keyof TNewOrderRequest> = [
  'introducerName',
  'introducerCode',
  'favoriteCustomerName',
  'favoriteCustomerCode',
  'invoiceFlag',
  'scSalesEventId',
  'scSalesEventId',
  'scSalesEventName',
  'scCorporationId',
];

// こいつらは修正後が0でも返却する
export const NOT_EDITABLE_ORDER_PROPERTIES: Array<keyof TNewOrderRequest> = [
  'shippingCost',
  'couponUsePoint',
  'usePoint',
  'pricePayment',
  // マイナスになるとエラーになるので新規分は送信しないが、既存の修正分も修正できなくなってしまうので、残す
  // OPS_ORDERSYS-747 【本番運用】ポイントのCMシステム連携について
  'priceNetTotal',
  'priceTaxin',
  'taxRate',
];

// こいつらは修正後が0でも返却する
export const NOT_EDITABLE_ITEM_PROPERTIES: Array<keyof TNewOrderRequestItem> = [
  'itemOrderPrice',
  'optionPrice',
  'itemOrderAmount',
  'itemPriceTax',
  'itemOrderAmountTaxin',
  'retailPrice',
];

// ゲストユーザーの場合、編集可
export const GUEST_EDITABLE_ORDER_PROPERTIES: Array<keyof TNewOrderRequest> = [
  'memberscardNumber',
  'customerFamilyNameKanji',
  'customerGivenNameKanji',
  'customerFamilyNameKana',
  'customerGivenNameKana',
  // 'customerBirthday',
  // 'customerPostalCode',
  // 'customerPhoneNumber',
  // 'customerState',
  // 'customerCity',
  // 'customerStreet',
  // 'customerResidence',
];

// 連携後も編集可
export const AFTER_COOPERATION_EDITABLE_ORDER_PROPERTIES: Array<keyof TNewOrderRequest> = [
  'deliveryDateGuest',
  'shippingPostalCode',
  'shippingPhoneNumber',
  'shippingState',
  'shippingCity',
  'shippingStreet',
  // 'shppingResidence',
  'customerMailAddress',
  'memberscardNumber',
  'customerFamilyNameKanji',
  'customerGivenNameKanji',
  'customerFamilyNameKana',
  'customerGivenNameKana',
  // 'customerBirthday',
  // 'customerPostalCode',
  // 'customerPhoneNumber',
  // 'customerState',
  // 'customerCity',
  // 'customerStreet',
  // 'customerResidence',
  // 'transActionHeadId',
  'items',
];

// 連携後も編集可
export const AFTER_COOPERATION_EDITABLE_ITEM_PROPERTIES: Array<keyof TNewOrderRequestItem> = [
  'clothBrandCode',
  'clothSeason',
  'clothCode',
  'vendorClothNumber',
  'personalOrderColorCode',
  'personalOrderColorName',
  'productNumber',
  'textileNumber',
  'parts',
];
