import { AppState } from '../../..';
import { IPurchaseHistoryState } from './action-reducer';
import { createSelector } from 'reselect';

export { reducer as purchaseHistoryReducer, actions as purchaseHistoryActions } from './action-reducer';

const featureSelector = (state: AppState): IPurchaseHistoryState => state.utils.dialog.purchaseHistory;

export const get = {
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
  purchaseHistory: createSelector(featureSelector, state => state.purchaseHistory),
};
