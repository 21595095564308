import { TMaster } from '../../types/index';

export const MASTER_CLOTH_COLOR: TMaster[] = [
  { code: '001', value: 'ホワイト' },
  { code: '002', value: 'グレー系' },
  { code: '003', value: 'ライトグレー' },
  { code: '004', value: 'グレー' },
  { code: '005', value: 'ブラック' },
  { code: '012', value: 'ピンク＆パープル系' },
  { code: '030', value: 'その他' },
  { code: '033', value: 'ベージュ' },
  { code: '070', value: 'ブルー' },
  { code: '072', value: 'ブルー系' },
  { code: '075', value: 'ネイビー' },
];
