import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getLocation } from 'connected-react-router';
import { AppState } from '../../store';
import { getSerialNumber, resolvePath } from '../../helpers/common/path';
import { RouteComponentProps, withRouter } from 'react-router';
import { ERouterPath } from '../../types';

function mapStateToProps(state: AppState) {
  return {
    pathname: getLocation(state).pathname,
  };
}

export type TProps = ReturnType<typeof mapStateToProps> & RouteComponentProps;

const TITLE = 'BSTA';

const BrowserTab: React.FC<TProps> = (props: TProps) => {
  const setTitle = (v: string) => {
    window.document.title = v;
  };

  useEffect(() => {
    if (props.pathname === resolvePath(ERouterPath.orderDetail)) {
      const serialNumber = getSerialNumber(props.location);
      setTitle(`${TITLE} | 製造番号：${serialNumber.substr(2)}`);
    } else {
      setTitle(TITLE);
    }
  }, [props.pathname, props.location]);

  return null;
};

export default withRouter(connect(mapStateToProps, {})(BrowserTab));
