import { TProjectSearchCondition, TScProject } from '../../../types/project';
import { toDateFormatString } from '../../common/date';
import { ProjectRes } from '../../../api/customer/project/projectRes.class';

export function toProjects(res: ProjectRes): TScProject[] {
  const events = res.query('salesEvents') || [];
  return Array.from({ length: events.length }).map((v, i) => toProject(res, i));
}

function toProject(res: ProjectRes, index: number): TScProject {
  const getValue = <T = string>(v: string): T => {
    const expression = `salesEvents[${index}].${v}`;
    return res.query(expression) as T;
  };

  return {
    corporationId: getValue('scCorporationId') || '',
    salesEventId: getValue('scSalesEventId') || '',
    companyName: getValue('scCorporationName') || '',
    projectName: getValue('scSalesEventName') || '',
    contactName: getValue('scStaffName') || '',
    salesRecordingDate: getValue('scSalesRecordedDate') || '19900101',
  };
}

export function toQuery(req: TProjectSearchCondition) {
  return {
    scCorporationName: req.companyName,
    scStaffName: req.contactName,
    scSalesRecordedDateFrom: toDateFormatString(req.startDate),
    scSalesRecordedDateTo: toDateFormatString(req.endDate),
    scSalesEventStatusCode: req.hasIncludeUndecided ? '1' : '0',
  };
}
