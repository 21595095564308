import { createSelector } from 'reselect';

import { AppState } from '../..';
import { ILookupFeature } from '../../lookups';
import { IOrderState } from '../../order/';
import { orderSelector, orderStateSelector } from '../../order/object-selector';
import { orderState } from '../../order/selector/order';
import { IItem } from '../../_type/order';
import { clothProductsSelector } from '../object-selector';

const getItemInfo = (item: IItem) => {
  const { itemCode, pieces, categoryCode, subCategoryCode } = item;
  const { brandCode, clothModelCode } = item.cloth;
  return { itemCode, pieces, brandCode, clothModelCode, categoryCode, subCategoryCode };
};

const getItem = (state: IOrderState) => getItemInfo(orderSelector(orderStateSelector(state).currentOrder()).item());

const featureSelector = (state: AppState): ILookupFeature => state.lookups;

const productState = createSelector(featureSelector, state => state.product);

const getCurrentProducts = createSelector(orderState, productState, (order, product) => {
  const { currentOrderNumber } = order;
  if (!product.products[currentOrderNumber]) {
    return [];
  }
  return product.products[currentOrderNumber];
});

export const getProduct = {
  state: createSelector(productState, product => product),
  all: createSelector(productState, product => product.products),
  current: {
    /** 一覧 */
    products: createSelector(getCurrentProducts, products => products),
    /** 選択できるブランド一覧を返す */
    seletableBrands: createSelector(orderState, getCurrentProducts, (order, products) => {
      const { itemCode, brandCode, subCategoryCode } = getItem(order);
      return clothProductsSelector({ products, itemCode, brandCode, subCategoryCode }).selectableBrandCodes;
    }),
    /** 選択可能なモデル一覧 */
    selectableClothModels: createSelector(orderState, getCurrentProducts, (order, products) => {
      const { itemCode, brandCode, pieces, categoryCode, subCategoryCode } = getItem(order);
      return clothProductsSelector({ products, itemCode, brandCode, subCategoryCode }).getSelectableClothModelCodes(
        categoryCode,
        pieces,
        brandCode,
        subCategoryCode,
      );
    }),
    /** 現在選択中の生地商品情報（最も近しいやつ？） */
    currentProduct: createSelector(orderState, getCurrentProducts, (order, products) => {
      const { itemCode, brandCode, subCategoryCode } = getItem(order);
      return clothProductsSelector({ products, itemCode, brandCode, subCategoryCode }).nearestProduct;
    }),
    /** 商品金額(税込) */
    retailPriceTaxIn: createSelector(orderState, getCurrentProducts, (order, products) => {
      const { itemCode, brandCode, subCategoryCode } = getItem(order);
      return clothProductsSelector({ products, itemCode, brandCode, subCategoryCode }).nearestProduct.retailPriceTaxin;
    }),
  },
};
