import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';
import ClothSelectionPage from '../../../components/pages/ClothSelectionPage/ClothSelectionPage';
import { getCurrentItem } from '../../../store/order/selector/item';
import { getProduct } from '../../../store/lookups';
import { customKeyboardHoc } from '../../../hoc/CustomKeyboardHoc';
import { getClothPage, requiredCheckCompleted } from '../../../store/order/selector/cloth';
import { getSelectedOrderDigest } from '../../../store/order-digest';
import { getStaff } from '../../../store/staff';
import { mapCommonPageDispatchToProps, mapCommonPageStateToProps } from '../CommonPage';
import { ClothSelectionPageEvents } from '../../../views/events/ClothSelectionPage';
import { getShortestDeliveryDate } from '../../../store/order/selector/shortestDeliveryDate';

function mapStateToProps(state: AppState) {
  return {
    shortestDeliveryDate: getShortestDeliveryDate.value(state),
    item: getCurrentItem.detail(state),
    products: getProduct.current.products(state),
    brandSelectItem: getClothPage.brandSelectItem(state),
    modelSelectItem: getClothPage.modelSelectItem(state),
    canAddtionalInput: getClothPage.canAddtionalInput(state),
    canAddtionalInputColorDesign: getClothPage.canAddtionalInputColorDesign(state),
    canSelectStockPlace: getClothPage.canSelectStockPlace(state),
    // composition
    colorSelectItem: getClothPage.colorSelectItem(state),
    designSelectItem: getClothPage.designSelectItem(state),
    stockSelectItem: getClothPage.stockSelectItem(state),
    compositionLookups: getClothPage.compositionLookups(state),
    hasCompleted: getClothPage.hasCompleted(state),
    isCompositionFrontValueCorrect: getClothPage.isCompositionFrontValueCorrect(state),
    requiredCheckCompleted: requiredCheckCompleted(state),
    orderStatus: getSelectedOrderDigest.orderStatus(state),
    isManager: getStaff.isManager(state),
    ...mapCommonPageStateToProps(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(ClothSelectionPageEvents, dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(customKeyboardHoc()(ClothSelectionPage));
