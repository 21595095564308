import { IClothProduct, IModel } from '../../_type/lookups';
import {
  TOrderItemCode,
  TCategory,
  TPartsNumber,
  TSubCategory,
  ESubcategory,
} from '../../../lookups/master-thisisforreplaceall';
import { INITIAL_PRODUCT_STATE } from '../product/initial-state';
import Logger from '../../../helpers/common/logger';
import { distinctArray } from '../../../helpers';
import { IPiece } from '../../_type/order';
import { IPartsModelPattern } from '../../../types/option';
import { isModelSelectBoxOnDesignPartsSection } from '../../../helpers/item-thisisforreplaceall';
import { isModelSelectBoxOnClothSection } from '../../../helpers/item-thisisforreplaceall';
import { EParts } from '../../../lookups/master-thisisforreplaceall/parts';
import { uniq } from 'ramda';
import { getItemCodeDefault, getItemCodeWithBrand } from '../../../helpers/orders/order-items';
import { OPS_BRANDS } from '../../../lookups/item-thisisforreplaceall';

export const clothProductsSelector = (state: IState) => new ClothProductsSelector(state);

interface IState {
  itemCode: TOrderItemCode;
  brandCode: string;
  products: IClothProduct[];
  subCategoryCode: TSubCategory;
}

class ClothProductsSelector {
  private get itemCode(): TOrderItemCode {
    return this.state.itemCode;
  }

  private get brandCode(): string {
    return this.state.brandCode;
  }

  private get products(): IClothProduct[] {
    // MEMO:state不正でも落ちないように修正
    return this.state.products || [];
  }

  get selectableBrandCodes(): string[] {
    const womanShirtSubCategory =
      this.state.subCategoryCode === ESubcategory.womanlongSleeve ||
      this.state.subCategoryCode === ESubcategory.womanshortSleeve;
    const mensShirtSubCategory =
      this.state.subCategoryCode === ESubcategory.longSleve ||
      this.state.subCategoryCode === ESubcategory.shortSleve ||
      this.state.subCategoryCode === ESubcategory.formalShirt;

    if (womanShirtSubCategory) {
      const brandCodes = this.products.filter(v => v.brandCode === 'PU').map(v => v.brandCode);
      return distinctArray(brandCodes);
    } else if (mensShirtSubCategory) {
      const brandCodes = this.products.filter(v => v.brandCode !== 'PU').map(v => v.brandCode);
      return distinctArray(brandCodes);
    } else {
      const brandCodes = this.products.map(v => v.brandCode);
      return distinctArray(brandCodes);
    }
  }

  get filteredProducts(): IClothProduct[] {
    const hasItemCode = this.itemCode !== '';
    const hasBrandCode = this.brandCode !== '';
    if (hasBrandCode && hasItemCode) {
      return this.products.filter(v => v.brandCode === this.brandCode && v.itemCode === this.itemCode);
    }
    if (hasItemCode) {
      return this.products.filter(v => v.itemCode === this.itemCode);
    }
    if (hasBrandCode) {
      return this.products.filter(v => v.brandCode === this.brandCode);
    }
    return this.products;
  }

  get hasProduct(): boolean {
    return this.filteredProducts.length > 0;
  }

  get hasOnlyOneProduct(): boolean {
    return this.filteredProducts.length === 1;
  }

  get nearestProduct(): IClothProduct {
    const products = this.filteredProducts;
    if (products.length < 1) {
      Logger.log('ClothProductsSelector', {
        message: 'not found NearestProduct',
        products: this.products,
        filtered: products,
      });
    }
    if (products.length > 1) {
      Logger.log('ClothProductsSelector', {
        message: 'not found only one NearestProduct',
        products: this.products,
        filtered: products,
      });
    }
    return products.length > 0 ? products[0] : { ...INITIAL_PRODUCT_STATE };
  }

  /**
   * 選択できるBrandCodeが1つの場合はBrandCodeを返却し、それ以外はundefinedを返却する
   */
  get selectableBrandCode(): string | undefined {
    const brandCodes = this.selectableBrandCodes;
    return brandCodes.length === 1 ? brandCodes[0] : undefined;
  }
  constructor(private state: IState) {}

  /**
   * 生地選択画面の時はシングルのitem-codeからProductが1つかどうかを判定。
   */
  hasOnlyOneProductForClothSelectPage(categoryCode: TCategory, subcCategoryCode: TSubCategory): boolean {
    return this.getFilteredProductsForClothSelectPage(categoryCode, subcCategoryCode).length === 1;
  }

  /**
   * @param subcCategoryCode  生地選択画面の時のみ渡ってくる。
   */
  getSelectableClothModelCodes(
    categoryCode: TCategory,
    pieces: IPiece[],
    brandCode: string,
    subcCategoryCode?: TSubCategory,
  ): string[] {
    const canSelect = isModelSelectBoxOnClothSection(categoryCode, pieces, brandCode);
    if (!canSelect) {
      return [];
    }
    return this.getSelectableModelCodes(categoryCode, pieces, subcCategoryCode);
  }

  /**
   * ブランド選択済みまたは選択できるClothModelCodeが1つの場合はmodelCodeを返却し、それ以外はundefinedを返却する
   * 生地選択画面でのみ呼び出される想定。
   */
  getSelectableClothModelCode(
    categoryCode: TCategory,
    pieces: IPiece[],
    brandCode: string,
    subCategoryCode: TSubCategory,
  ): string | undefined {
    const models = this.getSelectableClothModelCodes(categoryCode, pieces, brandCode, subCategoryCode);
    return (this.hasOnlyOneProductForClothSelectPage(categoryCode, subCategoryCode) || this.brandCode !== '') &&
      models.length === 1
      ? models[0]
      : undefined;
  }

  /**
   * 生地選択画面で、Clothをアップデートする際のモデルを取得。
   * 生地選択画面で、生地品番を変えた時/モデル選択プルダウンを変えた時/ブランド選択プルダウンを変えた時に呼び出される。
   */
  getClothModelCodeForUpdateCloth(
    categoryCode: TCategory,
    pieces: IPiece[],
    subCategoryCode: TSubCategory,
    updateClothModelCode: string,
  ): string {
    const selectableClothModelCodes = this.getSelectableClothModelCodes(
      categoryCode,
      pieces,
      this.brandCode,
      subCategoryCode,
    );
    // 生地品番を変えproductsの中身が変わった時に、選ばれているモデルが新しいproductsに含まれていない場合に、
    // ステートのモデルをリセットする必要があるため、↓3行目の最後に「''」を代入する処理を入れている。
    const returnClothModelCode =
      this.getSelectableClothModelCode(categoryCode, pieces, this.brandCode, subCategoryCode) ??
      (selectableClothModelCodes.includes(updateClothModelCode) ? updateClothModelCode : '');

    return returnClothModelCode;
  }

  getSelectableDesignModelCodes(categoryCode: TCategory, partsNumber: TPartsNumber): string[] {
    const canSelect = isModelSelectBoxOnDesignPartsSection(categoryCode, partsNumber, this.brandCode);
    if (!canSelect) {
      return [];
    }
    const product = this.nearestProduct;
    if (!product) {
      return [];
    }
    const pattern = product.partsModelPatterns.find(v => v.partsNumber === partsNumber);
    if (!pattern) {
      return [];
    }
    return distinctArray(pattern.modelPatterns.map(v => v.modelPattern));
  }

  getPartsSelectableDesignModelCodes(categoryCode: TCategory, pieces: IPiece[]): IPartsModelPattern[] {
    return pieces.map(piece => {
      const modelPatterns = this.getSelectableDesignModelCodes(categoryCode, piece.partsNumber);
      return {
        partsIndex: piece.index,
        modelPatterns,
      };
    });
  }

  getForSendToModelPatterns(categoryCode: TCategory, pieces: IPiece[], clothModel: string): string[] {
    const product = this.nearestProduct;
    if (!product) {
      return [];
    }

    const modelPatterns = pieces
      .map(piece => {
        const canSelect = isModelSelectBoxOnDesignPartsSection(categoryCode, piece.partsNumber, this.brandCode);
        if (!canSelect) {
          return clothModel !== '' ? [clothModel] : [];
        }
        const pattern = product.partsModelPatterns.find(v => v.partsNumber === piece.partsNumber);
        if (!pattern) {
          return [];
        }
        return pattern.modelPatterns.map(v => v.modelPattern);
      })
      .reduce((p, c) => [...p, ...c], []);

    return distinctArray(modelPatterns);
  }

  getModel(partsNumber: TPartsNumber, modelPattern: string, modelCode: string): IModel | undefined {
    const product = this.nearestProduct;
    const parts = product.partsModelPatterns.find(v => v.partsNumber === partsNumber);
    if (!parts) {
      return;
    }
    const pattern = parts.modelPatterns.find(v => v.modelPattern === modelPattern);
    if (!pattern) {
      return;
    }
    return pattern.models.find(v => v.modelCode === modelCode);
  }

  /**
   * 生地選択画面でのみ呼び出される想定。(モデル選択プルダウン用)
   */
  private getFilteredProductsForClothSelectPage(
    categoryCode: TCategory,
    subcCategoryCode: TSubCategory,
  ): IClothProduct[] {
    const itemCode = getItemCodeDefault({ category: categoryCode, subCategory: subcCategoryCode });
    const hasBrandCode = this.brandCode !== '';
    const updateItemCode = getItemCodeWithBrand({
      category: categoryCode,
      subCategory: subcCategoryCode,
      brand: this.brandCode,
    });
    if (hasBrandCode && updateItemCode) {
      return this.products.filter(v => v.brandCode === this.brandCode && v.itemCode === updateItemCode);
    }
    if (itemCode) {
      return this.products.filter(v => v.itemCode === itemCode);
    }
    if (hasBrandCode) {
      return this.products.filter(v => v.brandCode === this.brandCode);
    }
    return this.products;
  }

  /**
   * 生地選択画面でのみ呼び出される想定。(モデル選択プルダウン用)
   */
  private getNearestProductForClothSelectPage(categoryCode: TCategory, subcCategoryCode: TSubCategory): IClothProduct {
    const products = this.getFilteredProductsForClothSelectPage(categoryCode, subcCategoryCode);
    return products.length > 0 ? products[0] : { ...INITIAL_PRODUCT_STATE };
  }

  /**
   * 生地選択画面で選択できるModelCodeの一覧を返す
   * @param subCategoryCode  生地選択画面の時のみ渡ってくる。
   */
  private getSelectableModelCodes(
    categoryCode: TCategory,
    pieces: IPiece[],
    subcCategoryCode?: TSubCategory,
  ): string[] {
    const product = subcCategoryCode
      ? this.getNearestProductForClothSelectPage(categoryCode, subcCategoryCode)
      : this.nearestProduct;
    if (!product) {
      return [];
    }

    // レディース、またはEASYの場合
    if (
      categoryCode === 'WM' ||
      categoryCode === 'MT' ||
      (categoryCode === 'FM' && OPS_BRANDS.includes(this.brandCode)) ||
      (categoryCode === 'CS' && OPS_BRANDS.includes(this.brandCode))
    ) {
      const jacketModelPatterns = product.partsModelPatterns.find(v => v.partsNumber === EParts.jaket);
      if (!jacketModelPatterns) {
        Logger.log('ClothProductsSelector', {
          message: 'not found jacketModelPatterns',
          products: product.partsModelPatterns,
        });
        return [];
      }
      return distinctArray(jacketModelPatterns.modelPatterns.map(v => v.modelPattern));
    }

    // シャツの場合
    if (categoryCode === 'DS') {
      // シャツの場合は、this.products には、
      // 各サブカテゴリに応じたアイテムコードのproductのみが格納されている想定。
      const filterProducts =
        categoryCode === 'DS' &&
        (subcCategoryCode === ESubcategory.womanlongSleeve || subcCategoryCode === ESubcategory.womanshortSleeve)
          ? this.products.filter(v => v.brandCode === 'PU')
          : categoryCode === 'DS'
          ? this.products.filter(v => v.brandCode !== 'PU')
          : this.products;
      const products = filterProducts;
      const shirtsModelPatterns = products
        .map(v => v.partsModelPatterns.find(vv => vv.partsNumber === EParts.shirt))
        .filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
      if (shirtsModelPatterns.length < 1) {
        Logger.log('ClothProductsSelector', {
          message: 'not found shirtsPatterns',
          products: product.partsModelPatterns,
        });
        return [];
      }
      return distinctArray(uniq(shirtsModelPatterns.map(v => v.modelPatterns.map(vv => vv.modelPattern)).flat()));
    }

    return this.getAllPiecesModelPatterns(categoryCode, pieces, subcCategoryCode);
  }

  /**
   * @param subcCategoryCode  生地選択画面の時のみ渡ってくる。
   */
  private getAllPiecesModelPatterns(
    categoryCode: TCategory,
    pieces: IPiece[],
    subcCategoryCode?: TSubCategory,
  ): string[] {
    const product = subcCategoryCode
      ? this.getNearestProductForClothSelectPage(categoryCode, subcCategoryCode)
      : this.nearestProduct;
    const modelPatterns = pieces
      .map(piece => {
        const parts = product.partsModelPatterns.find(v => v.partsNumber === piece.partsNumber);
        return parts ? parts.modelPatterns.map(v => v.modelPattern) : [];
      })
      .reduce((p, c) => [...p, ...c], []);
    return distinctArray(modelPatterns);
  }
}
