import React, { useState, useEffect, useCallback } from 'react';

import styles from './DesignOptionBlouseSelector.module.scss';

import Button from '../Button/Button';
import DesignOptionBlouseColorSelector from '../DesignOptionBlouseColorSelector/DesignOptionBlouseColorSelector';

import { IOptionPatternWithSelectedInfo, IOptionBlouseClass } from '../../../store/_type/lookups';
import { getNotFoundImage } from '../../../helpers/option/image-path';
import { womenBlouseGroups } from '../../../lookups/item-thisisforreplaceall';
import {
  getSelectedOptionGroupId,
  getSameColorAndSizeOptionClassByOtherGroup,
} from '../../../helpers/item-thisisforreplaceall';
import { getOptionRetailPriceTaxin } from '../../../helpers/option';
import { TPageContainerProps } from '../../../containers/pages/DesignSelectionPage';
import Logger from '../../../helpers/common/logger';

type TProps = Pick<
  TPageContainerProps,
  'orderNumber' | 'item' | 'products' | 'onSelectOptionClass' | 'selectGroupId' | 'isEdit'
> & {
  optionPattern: IOptionPatternWithSelectedInfo;
};

const getImagePath = (id: string) => `../images/option-group/${id}.png`;

const DesignOptionBlouseSelector: React.FC<TProps> = (props: TProps) => {
  const notFoundImagePath = getNotFoundImage();
  const { item, orderNumber, products, optionPattern, isEdit } = props;
  const [selectedOptionGroupId, SetSelectedOptionGroupId] = useState('');
  const [hasShownColor, setHasShownColor] = useState(false);

  const getOptionClassByOtherGroup = useCallback(
    getSameColorAndSizeOptionClassByOtherGroup(optionPattern.optionNumber, optionPattern.optionClasses),
    [optionPattern.optionNumber, optionPattern.optionClasses],
  );

  useEffect(() => {
    const optionGroupId = getSelectedOptionGroupId(optionPattern.selectingClassNumber);
    SetSelectedOptionGroupId(optionGroupId);
    setHasShownColor(!!optionGroupId);
    return () => {
      SetSelectedOptionGroupId('');
      setHasShownColor(false);
    };
    // NOTICE: オプションが切り替わったときのみ実行する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionPattern.optionNumber]);

  const onGoGroupSelector = () => {
    setHasShownColor(false);
  };

  const onGoColorSelector = () => {
    setHasShownColor(true);
  };

  const onSelectOptionGroup = (v: IOptionBlouseClass) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { selectingClassNumber } = optionPattern;
    if (selectingClassNumber) {
      const anotherGroupOptionClass = getOptionClassByOtherGroup(v.optionGroupId, selectingClassNumber);
      if (!anotherGroupOptionClass) {
        Logger.log('no anotherGroupOptionClass');
      }
      props.onSelectOptionClass({
        orderNumber,
        optionClassNumber: anotherGroupOptionClass?.optionClassNumber || '',
        item,
        products,
      });

      const selectingOptionPrice = getOptionRetailPriceTaxin(optionPattern.optionClasses, selectingClassNumber);
      const anotherGroupOptionPrice = getOptionRetailPriceTaxin(
        optionPattern.optionClasses,
        anotherGroupOptionClass?.optionClassNumber || '',
      );
      const isSameOptionPrice = selectingOptionPrice === anotherGroupOptionPrice;
      const isSameOptionClassNumber = selectingClassNumber === anotherGroupOptionClass?.optionClassNumber;

      if (isEdit && (!isSameOptionClassNumber ? !isSameOptionPrice : selectingOptionPrice !== 0)) {
        // 下記の場合は修正不可なので、componentのStateを更新しない
        // 同一OptionClassを選択した場合、選択中のオプションが外れるのでオプション価格が0円でない
        // 同一OptionClassでない場合、価格が一緒でない
        return;
      }
    } else {
      onGoColorSelector();
    }
    // stateを設定する
    SetSelectedOptionGroupId(v.optionGroupId);
    props.selectGroupId({ optionNumber: optionPattern.optionNumber, optionGroupId: v.optionGroupId });
  };

  const notFoundErrorHandle = (event: any) => {
    event.target.src = notFoundImagePath;
  };

  const renderCard = (cls: IOptionBlouseClass, key: number) => {
    // 各ブラウスに該当するoptionClassNumberがAPIから返却されているか。されていない場合は、そもそも画像を表示しない。
    const hasOptionClass = cls.optionClassNumbers.some(v =>
      optionPattern.optionClasses.some(vv => v === vv.optionClassNumber),
    );
    return !hasOptionClass ? null : (
      <div key={key} className={styles.item}>
        <div className={styles.text} onClick={onSelectOptionGroup(cls)}>
          <p>{cls.optionGroupName}</p>
        </div>
        <div
          className={selectedOptionGroupId === cls.optionGroupId ? styles.cardSelected : styles.card}
          onClick={onSelectOptionGroup(cls)}
        >
          <div className={styles.image} title={cls.optionGroupName}>
            <img src={getImagePath(cls.optionGroupId)} alt={cls.optionGroupName} onError={notFoundErrorHandle} />
          </div>
        </div>
      </div>
    );
  };

  const renderBlouseOptionCard = (pattern: IOptionPatternWithSelectedInfo) => {
    if (hasShownColor && selectedOptionGroupId) {
      return (
        <DesignOptionBlouseColorSelector
          {...props}
          optionPattern={pattern}
          selectedOptionGroupId={selectedOptionGroupId}
        />
      );
    } else {
      return womenBlouseGroups.map((cls, index) => renderCard(cls, index));
    }
  };

  return (
    <React.Fragment>
      {hasShownColor && (
        <div className={styles.buttonWrap}>
          <Button textObj={{ jp: '絵型選択へ' }} onClick={onGoGroupSelector} styles={['size-s']} />
        </div>
      )}
      {!hasShownColor && selectedOptionGroupId && (
        <div className={styles.buttonWrap}>
          <Button textObj={{ jp: '色・サイズ選択へ' }} onClick={onGoColorSelector} styles={['size-s']} />
        </div>
      )}
      <div className={styles.content}>{renderBlouseOptionCard(props.optionPattern)}</div>
    </React.Fragment>
  );
};

export default DesignOptionBlouseSelector;
