import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { Dispatch, bindActionCreators } from 'redux';

import { getStaff } from '../../../store/staff';
import { get as getCustomer } from '../../../store/customer';
import { getProgressState } from '../../../store/progress';
import { customerLoadDialogActions } from '../../../store/utils/dialog/customer-load';
import { HeaderEvents } from '../../../views/events/Header';

import Header from '../../../components/organisms/Header/Header';
import { getLocation } from 'connected-react-router';

function mapStateToProps(state: AppState) {
  return {
    staff: getStaff.staff(state),
    customer: getCustomer.customer(state),
    isLoaded: getCustomer.isLoad(state),
    progressList: getProgressState.progressList(state),
    pathName: getLocation(state).pathname,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(HeaderEvents, dispatch),
    // onLogoutCustomer: customerActions.initializeCustomer.dispatcher(dispatch),
    // onLogoutStaff: staffActions.logout.dispatcher(dispatch),
    onShowCustomerLoadDialog: customerLoadDialogActions.show.dispatcher(dispatch),
    onCloseCustomerLoadDialog: customerLoadDialogActions.close.dispatcher(dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type THeaderProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
