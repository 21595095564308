import { getCanceledOrderStr, getSettledOrderStr, getNumberStr } from '../helpers/orders/digest';
import { getOrderKindStr, getDeliveryTimeZoneStr } from '../helpers/orders/index';

export const ORDER_DIGESTS_CSV_CONFIG = [
  { name: '受注日', path: ['orderDate'] },
  { name: '製造No', path: ['serialNumber'] },
  { name: 'オーダー区分', path: ['orderKind'], convFunc: getOrderKindStr },
  { name: '会員番号', path: ['memberscardNumber'] },
  { name: 'キャンセル', path: ['hasCancel'], convFunc: getCanceledOrderStr },
  { name: 'お客様名', path: ['nameKana'] },
  { name: '店舗名', path: ['tempoName'] },
  { name: '担当者名', path: ['staff', 'name'] },
  { name: 'お渡し方法', path: ['deliveryMethod'] },
  { name: 'お届け予定日', path: ['deliveryDatetime'], convFunc: getDeliveryTimeZoneStr },
  { name: 'アイテム', path: ['item', 'itemCode'] },
  { name: 'アイテム名', path: ['item', 'itemName']},
  { name: 'ブランド', path: ['brandCode'] },
  { name: '期別', path: ['seasonCode'] },
  { name: '生地品番', path: ['productNumber'] },
  { name: '商品金額', path: ['itemOrderPrice'], convFunc: getNumberStr },
  { name: 'オプション金額', path: ['optionPrice'], convFunc: getNumberStr },
  { name: '合計', path: ['totalPrice'], convFunc: getNumberStr },
  { name: '備考', path: ['memo'] },
];
