import { combineReducers } from 'redux';
import { dialogFeatureReducers } from './dialog';
import { langReducer } from './language';
import { reducers as navigationReducer } from './navigation';
import { optionGroupReducer } from './optionGroup';

export const reducers = combineReducers({
  dialog: dialogFeatureReducers,
  language: langReducer,
  navigation: navigationReducer,
  optionGroup: optionGroupReducer,
});
