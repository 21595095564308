import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap } from 'rxjs/operators';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';

import { AppState } from '../store';
import { ItemConfirmationPageEvents } from '../views/events/ItemConfirmationPage';
import { actions as OrderItemActions } from '../store/order/item/actions';
import { recommendedGaugeAsyncActions } from '../store/lookups/recommend-gauge/action-reducer';

const onLoadInitialize: Epic<AnyAction, Action<void | { orderNumber?: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ItemConfirmationPageEvents.onLoadInitialize),
    mergeMap(() => {
      return [
        OrderItemActions.loadShortestDeliveryDateAllOrders(),
        recommendedGaugeAsyncActions.validateSelectedGauge(),
      ];
    }),
  );

export const ItemConfirmationPageListeners = combineEpics(onLoadInitialize);
