import { IndexedObject } from '../types';

export const getSpecialOptionillust = (category: string, partsNumber: string, clothModelCode: string) => {
  const illustList: IndexedObject = {
    'CS/01/KS': '/_illustration/special_options/CS/01_KS.png',
    'CS/01/ST': '/_illustration/special_options/CS/01_ST.png',
    'CS/02/KS': '/_illustration/special_options/CS/02_KS.png',
    'CS/02/ST': '/_illustration/special_options/CS/02_ST.png',
  };

  return illustList[`${category}/${partsNumber}/${clothModelCode}`];
};
