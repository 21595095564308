import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon/Icon';
import Button from '@material-ui/core/Button/Button';

import styles from './NumericKeyboard.module.scss';

interface IProps {
  display?: boolean;
  onLeave?: () => void;
  onKeyPress: (value: string) => void;
  displayValue?: string;
}

const NumericKeyboard: React.FC<IProps> = props => {
  const [moveStartX, setMoveStartX] = useState(0);
  const [moveStartY, setMoveStartY] = useState(0);
  useEffect(() => {
    const dragPoint = document.getElementById('dragPoint');
    if (dragPoint) {
      addTouchMoveListener(dragPoint);
      return () => removeTouchMoveListener(dragPoint);
    }
    // TODO:
    // eslint-disable-next-line
  }, []);
  const keyboardButtons = [
    { row: ['input', '@@', '@@', 'close'] },
    { row: ['7', '8', '9', 'clear'] },
    { row: ['4', '5', '6', 'delete'] },
    { row: ['1', '2', '3', '@@'] },
    { row: ['0', '.', '', '@@'] },
  ];
  const keyboadStyle = props.display === undefined || props.display ? styles.numericKeyboard : styles.hide;

  return (
    <div className={keyboadStyle} id="dragContents">
      <table className={styles.table}>
        <tbody>
          {keyboardButtons.map(item => (
            <tr key={item.row[0]}>{getColumns(item.row)}</tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  function getColumns(columns: string[]) {
    return columns.map(colName => {
      if (colName === '@@') {
        return null;
      }

      switch (colName) {
        case 'input':
          return (
            <td
              key={colName}
              className={styles.item}
              colSpan={3}
              id="dragPoint"
              onTouchStart={touchStartHandler}
              onTouchMove={touchMoveHandler}
            >
              <span />
            </td>
          );
        case 'delete':
          return (
            <td key={colName} className={styles.item} rowSpan={3} onClick={clickHandler}>
              {genericBtn(colName, 'backspace')}
            </td>
          );
        case 'close':
          return (
            <td key={colName} className={styles.item} onClick={clickHandler}>
              {genericBtn(colName, 'close')}
            </td>
          );
        default:
          return (
            <td key={colName} className={styles.item} onClick={clickHandler}>
              {genericBtn(colName)}
            </td>
          );
      }
    });
  }

  function genericBtn(key: string, icon?: string) {
    const buttonText = icon ? <Icon className="icon_small">{icon}</Icon> : key;
    return (
      <Button className={styles.content} value={key}>
        {buttonText === 'clear' ? 'AC' : buttonText}
      </Button>
    );
  }

  function clickHandler(event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) {
    const childrenButton: any = event.currentTarget.firstElementChild;

    if (childrenButton) {
      props.onKeyPress(childrenButton.value);
    }
  }

  function touchStartHandler(event: React.TouchEvent<HTMLTableDataCellElement>) {
    const target = document.getElementById('dragContents');
    if (target) {
      setMoveStartX(event.changedTouches[0].pageX - target.offsetLeft);
      setMoveStartY(event.changedTouches[0].pageY - target.offsetTop);
    }
  }

  function touchMoveHandler(event: React.TouchEvent<HTMLTableDataCellElement>) {
    const left = event.changedTouches[0].pageX - moveStartX;
    const top = event.changedTouches[0].pageY - moveStartY;
    const target = document.getElementById('dragContents');
    if (target) {
      if (left >= 0 && left <= window.innerWidth - 400) {
        // 数字入力ポップアップの横幅(400)
        target.style.left = event.changedTouches[0].pageX - moveStartX + 'px';
      }
      if (top >= 0 && top <= window.innerHeight - 286) {
        // 数字入力ポップアップの縦幅(286)
        target.style.top = event.changedTouches[0].pageY - moveStartY + 'px';
      }
    }
  }

  function addTouchMoveListener(target: HTMLElement) {
    target.addEventListener('touchmove', preventDefault);
  }

  function removeTouchMoveListener(target: HTMLElement) {
    target.removeEventListener('touchmove', preventDefault);
  }

  function preventDefault(e: TouchEvent) {
    e.preventDefault();
  }
};

export default NumericKeyboard;
