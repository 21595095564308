/**
 *
 */
import actionCreatorFactory from 'typescript-fsa';
import {
  IChangeNudeMeasurementParam,
  IChangeGaugeParam,
  IChangeSizeMeasurementParam,
  IChangeAdjustOptionParam,
} from '../../store/pages/size-correction/type';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';

const actionCreator = actionCreatorFactory('- SizeCorrectionPage');

export const SizeCorrectionPageEvents = {
  /**
   *
   */
  onLoadInitialize: actionCreator<void>('onLoadInitialize'),
  onChangeNudeSize: actionCreator<IChangeNudeMeasurementParam>('onChangeNudeSize'),
  onChangeGauge: actionCreator<IChangeGaugeParam>('onChangeGauge'),
  onChangeParts: actionCreator<{ partsNumber: TPartsNumber }>('onChangeParts'),
  onChangeSizeMeasurement: actionCreator<IChangeSizeMeasurementParam>('onChangeSizeMeasurement'),
  onChangeAdjustOption: actionCreator<IChangeAdjustOptionParam>('onChangeAdjustOption'),
  onCopyHistorySize: actionCreator('onCopyHistorySize'),
  onOpenPurchaseHistoryDialog: actionCreator('onOpnePurchaseHistoryDialog'),
};
