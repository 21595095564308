import React, { useEffect } from 'react';

import styles from './OrderDigestsSearchFilter.module.scss';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import InputText from '../../../containers/components/InputText';
import Label from '../../atoms/Label/Label';
import Button from '../../atoms/Button/Button';
import DateRangePicker from '../DateRangePicker/DateRangePicker';

import { ILookupItem } from '../../../types/lookup';
import { IOrderDigestsSearch } from '../../../types/orders/digest';
import { categoryLookups, NO_SELECT_CATEGORY_ID } from '../../../lookups/item-thisisforreplaceall/';
import { langResource } from '../../../i18n/_new/resources/';
import { isInternalStaff } from '../../../helpers/staff';
import { TPageContainerProps } from '../../../containers/pages/OrderDigestsSearchPage';

type TProps = TPageContainerProps;

const OrderDigestsSearchFilter: React.FC<TProps> = ({ staff, filter, load, setFilter, resetFilter }) => {
  const isManagerStaff = !staff.managerFlag;

  useEffect(() => {
    load();
  }, [load]);

  const onChangeTextHandler = (
    key: keyof Pick<
      IOrderDigestsSearch,
      'customerNameKana' | 'memberscardNumber' | 'customerPhoneNumber' | 'tempoCode' | 'serialNumber' | 'lotNumber'
    >,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || '';
    setFilter({ key, value });
  };

  const onChangeSelectHandler = (key: keyof Pick<IOrderDigestsSearch, 'categoryCode'>) => (item: ILookupItem) => {
    const value = item.id !== NO_SELECT_CATEGORY_ID ? String(item.id) : '';
    setFilter({ key, value });
  };

  const onChangeDateHandler = (key: keyof Pick<IOrderDigestsSearch, 'orderDateFrom' | 'orderDateTo'>) => (
    date: Date,
  ) => {
    setFilter({ key, value: date });
  };

  const onSearchHandler = () => {
    load();
  };

  const onResetFilter = () => {
    resetFilter();
  };

  return (
    <div>
      <div className={styles.orderDigestsSearchFilter}>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.search.nameKana} />
            <div className={styles.inputWrap}>
              <InputText onValueChanged={onChangeTextHandler('customerNameKana')} value={filter.customerNameKana} />
            </div>
          </div>
          <div className={styles.columnSize2}>
            <Label textObj={langResource.search.cardNo} />
            <div className={styles.inputWrap}>
              <InputText onValueChanged={onChangeTextHandler('memberscardNumber')} value={filter.memberscardNumber} />
            </div>
          </div>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.search.phone} />
            <div className={styles.inputWrap}>
              <InputText
                onValueChanged={onChangeTextHandler('customerPhoneNumber')}
                value={filter.customerPhoneNumber}
              />
            </div>
          </div>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.search.lotNumber} />
            <div className={styles.inputWrap}>
              <InputText value={filter.lotNumber} onValueChanged={onChangeTextHandler('lotNumber')} />
            </div>
          </div>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.search.tempoCode} />
            <div className={styles.inputWrap}>
              <InputText
                value={isManagerStaff ? staff.tempoCode : filter.tempoCode}
                onValueChanged={onChangeTextHandler('tempoCode')}
                readOnly={isManagerStaff}
                disabled={isManagerStaff}
              />
            </div>
          </div>
        </div>
        <div className={styles.rowBtn}>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.search.serialNumber} />
            <div className={styles.inputWrap}>
              <InputText value={filter.serialNumber} onValueChanged={onChangeTextHandler('serialNumber')} />
            </div>
          </div>
          <div className={styles.columnSize3}>
            <Label textObj={langResource.search.paymentDate} />
            <div className={styles.inputWrap}>
              <DateRangePicker
                dateFrom={filter.orderDateFrom}
                dateTo={filter.orderDateTo}
                onDateFromChange={onChangeDateHandler('orderDateFrom')}
                onDateToChange={onChangeDateHandler('orderDateTo')}
              />
            </div>
          </div>
          <div className={styles.columnSizeReset}>
            <Button onClick={onResetFilter} styles={['size-xs']} textObj={langResource.search.resetButton} />
          </div>
          <div className={styles.column}>
            <Button
              onClick={onSearchHandler}
              styles={['black', 'size-xl', 'block', 'search']}
              textObj={langResource.search.searchButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDigestsSearchFilter;
