import { TSettlement } from './action-reducer';
import { AppState } from '..';
import { createSelector } from 'reselect';
export { settlementReducer } from './action-reducer';

const featureSelector = (state: AppState): TSettlement => state.settlement;

export const getSettlement = {
  isLoading: createSelector(featureSelector, state => state.isLoading),
};
