import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './SpecialAdjustPage.module.scss';

import Title from '../../atoms/Title/Title';
import Footer from '../../organisms/Footer/Footer';
import SelectBox from '../../atoms/SelectBox/SelectBox';

import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { langResource } from '../../../i18n/_new/resources';
import { ILookupItem } from '../../../types/lookup';
import { TPageContainerProps } from '../../../containers/pages/SpecialAdjustPage';
import I18Text from '../../../containers/I18Text/I18Text';
import { getValue } from '../../../helpers/master-lookup';
import { MASTER_PARTS } from '../../../lookups/master-thisisforreplaceall';
// import Button from '../../atoms/Button/Button';
import Logger from '../../../helpers/common/logger';

type TProps = TPageContainerProps & RouteComponentProps;

class SpecialAdjustPage extends Component<TProps> {
  render() {
    const { specialAdjustOptions, selectingParts } = this.props;
    return (
      <React.Fragment>
        <div className={styles.specialAdjustPage}>
          <div className={styles.title}>
            <Title styles={['lineLightBrown']}>
              <I18Text textObj={langResource.specialAdjustPage.title} />
              &nbsp;-{getValue(selectingParts, MASTER_PARTS) || ''}-
            </Title>
          </div>
          <div className={styles.btnWrapper}>
            {/* Ph2 */}
            {/* <Button textObj={langResource.specialAdjustPage.illustrationBtn}
            onClick={this.onShowIllustration} /> */}
          </div>
          <div className={styles.list}>
            {specialAdjustOptions.map((opt, idx) => {
              return (
                <div key={idx} className={styles.item}>
                  <p>{opt.optionName}</p>
                  <SelectBox
                    data={opt.selectableOptionClasses}
                    selectedOption={opt.selectedOptionClass}
                    canDisplayEmpty={false}
                    onValueChanged={this.onChangeSizeAdjust(opt.optionNumber, opt.selectedOptionClass)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {utility(this.props)}
      </React.Fragment>
    );
  }

  onChangeSizeAdjust = (optionNumber: string, selectedOptionClass: string) => (v: ILookupItem) => {
    const optionClassNumber = String(v.id);
    this.props.onChangeAdjustOption({ optionNumber, optionClassNumber, selectedOptionClass });
  };

  onShowIllustration = () => {
    Logger.log('未実装');
  };
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'back',
      textObj: langResource.footer.return,
      path: ERouterPath.sizeCorrection,
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      {/* <LoadingInfo isLoading={prop.isLoading} /> */}
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(SpecialAdjustPage);
