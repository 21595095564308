import actionCreatorFactory from 'typescript-fsa';
import {
  IChangeNudeMeasurementParam,
  IChangeSizeMeasurementParam,
  IChangeGaugeParam,
  IChangeAdjustOptionParam,
  TLoadStandardSize,
} from '../../pages/size-correction/type';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { IOption } from '../../_type/order';
import { ILoadOrderDetail } from '../../../types/orders/digest';

const actionCreator = actionCreatorFactory('[new - order/size]');
export const actions = {
  onLoadInitialize: actionCreator<void>('onLoadInitialize'),
  onChangeNudeSize: actionCreator<IChangeNudeMeasurementParam>('onChangeNudeSize'),
  onChangeGauge: actionCreator<IChangeGaugeParam>('onChangeGauge'),
  onChangeParts: actionCreator<{ partsNumber: TPartsNumber }>('onChangeParts'),
  onChangeSizeMeasurement: actionCreator<IChangeSizeMeasurementParam>('onChangeSizeMeasurement'),
  onChangeAdjustOption: actionCreator<IChangeAdjustOptionParam>('onChangeAdjustOption'),
  onCopyHistorySize: actionCreator('onCopyHistorySize'),
  loadSizeMeasurement: actionCreator<void>('loadSizeMeasurement'),
  loadAdjustOption: actionCreator<void>('loadAdjustOption'),
  loadRecommandedGauge: actionCreator<void>('loadRecommandedGauge'),
  loadPartsSizeHistory: actionCreator<ILoadOrderDetail>('loadPartsSizeHistory'),
  // async action done catched
  loadedSizeMeasurement: actionCreator<void>('loadedSizeMeasurement'), // なくなった採寸項目を外す
  loadedAdjustOption: actionCreator<void>('loadedAdjustOption'), // なくなったオプション項目を外す
  loadedRecommandedGauge: actionCreator<void>('loadedRecommandedGauge'), // 標準サイズ取得を呼び出す
  setStandardSize: actionCreator<{ params: TLoadStandardSize; standardSizes: IOption[] }>('setStandardSize'),
  // async action
  loadNudeSize: actionCreator.async<{}, {}, {}>('loadNudeSize'),
  loadStandardSize: actionCreator.async<TLoadStandardSize, { standardSizes: IOption[] }, {}>('loadStandardSize'),
};
