import React from 'react';

import styles from './MemoDialog.module.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../../atoms/Button/Button';
import TextArea from '../../atoms/textArea/textArea';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import SJISByteCounter from '../../atoms/SJISByteCounter/SJISByteCounter';

import { langResource } from '../../../i18n/_new/resources';
import { TMemoDialogProps } from '../../../containers/components/dialogs/MemoDialog';
import { countSJISBytes } from '../../../helpers/common/count-string';
import { MEMO_MAX_LENGTH } from '../../../lookups/word-count';

type TProps = TMemoDialogProps;

const MemoDialog: React.FC<TProps> = ({ hasOpen, memo, previousMemo, canEdit, changeMemo, update, close }) => {
  const onClose = () => {
    close();
  };

  const onChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value || '';
    if (memo !== value) {
      changeMemo(value);
    }
  };

  const onUpdate = () => {
    update();
  };

  return (
    <React.Fragment>
      <Dialog className="dialog " open={hasOpen} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <I18TextContainer textObj={langResource.dialog.memo.title} />
        </DialogTitle>
        <DialogContent className="dialog__contents">
          <div className="content-wrapper">
            <div className={styles.remarks}>
              <TextArea text={memo} disabled={!canEdit} onValueChanged={onChanged} />
            </div>
            <div className={styles.wordCounter}>
              <SJISByteCounter word={memo} max={MEMO_MAX_LENGTH} />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button onClick={onClose} styles={['white']} textObj={langResource.dialog.memo.close} />
          <Button
            onClick={onUpdate}
            styles={['blown']}
            textObj={langResource.dialog.memo.update}
            disabled={!canEdit || memo === previousMemo || countSJISBytes(memo) > MEMO_MAX_LENGTH}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MemoDialog;
