import { combineEpics, Epic } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, filter, mergeMap } from 'rxjs/operators';
import { actions } from './actions';
import { TPartialOrder, IOrder } from '../../_type/order';
import { getPartialOrder } from '../helper/conv-partial-order';
import { orderActions } from '..';
import { isValidPostalCode } from '../../../helpers/common/validate';
import { getAddress } from '../../../services/address';
import { ApiError } from '../../../models/error/api-error';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IndexedObject } from '../../../types';
import { getInitialOrderNumber } from '../../../helpers/orders';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { toShipping } from '../../../helpers/customer';
import { actions as itemActions } from '../item/actions';

const reloadPostalCodeAddress: Epic<AnyAction, Action<{ orderNumber: string; postalCode: string }>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(actions.reloadPostalCodeAddress),
    map(({ payload }) => {
      const stateSelector = appStateSelector(state.value);
      const orders = stateSelector.orders();
      return { orders };
    }),
    filter(({ orders }) => orders !== undefined && Object.keys(orders).length > 0),
    mergeMap(obj => {
      const orders = obj.orders as IndexedObject<IOrder>;
      return Object.entries(orders)
        .map(([orderNumber, order]) => {
          return {
            orderNumber,
            isSameOrder: order.shipping.isSameOrderOne,
            postalCode: order.shipping.shippingPostalCode,
          };
        })
        .filter(v => v.orderNumber === getInitialOrderNumber(orders) || !v.isSameOrder)
        .map(v => actions.updatePostalCodeAddress(v));
    }),
  );

const errorMessage = '郵便番号からの住所取得に失敗しました.';

const updatePostalCodeAddress: Epic<
  AnyAction,
  Action<{ orderNumber: string; order: TPartialOrder }> | Action<{ error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.updatePostalCodeAddress),
    filter(({ payload }) => isValidPostalCode(payload.postalCode)),
    mergeMap(async ({ payload }) => {
      const { orderNumber, postalCode } = payload;
      const fetchData = await fetch(
        `https://h94r2s53v8.execute-api.ap-northeast-1.amazonaws.com/prod/zipaddress?postcode=${postalCode}`,
      );
      const jsonData = await fetchData.json();
      const res = JSON.parse(JSON.stringify(jsonData));
      return { orderNumber, res };
    }),
    mergeMap(({ orderNumber, res }) => {
      if (res === null) {
        const data = getPartialOrder.fromShipping({});
        return [
          ErrorHandlerActions.apiError({
            error: new ApiError([{ code: '', message: '' }], 400),
            options: { contents: errorMessage },
          }),
          orderActions.updateOrder._action({ orderNumber, order: data }),
        ];
      }
      const street = res.TownName || '';
      const shippingState = res.PrefecturesName;
      const shippingCity = res.CityName + street;
      const data = getPartialOrder.fromShipping({ shippingState, shippingCity });

      if (!shippingState || !shippingCity) {
        return [
          ErrorHandlerActions.apiError({
            error: new ApiError([{ code: '', message: '' }], 400),
            options: { contents: errorMessage },
          }),
          orderActions.updateOrder._action({ orderNumber, order: data }),
        ];
      }
      return [orderActions.updateOrder._action({ orderNumber, order: data })];
    }),
  );

const loadAddress: Epic<AnyAction, Action<any>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadAddress),
    mergeMap(({ payload }) => {
      const { orderNumber, customer } = payload;
      const shipping = toShipping(customer);
      const order = getPartialOrder.fromShipping(shipping);

      const itemAction = [];
      if (isValidPostalCode(shipping.shippingPostalCode || '')) {
        itemAction.push(itemActions.loadShortestDeliveryDate({ orderNumber }));
        itemAction.push(
          actions.updatePostalCodeAddress({ orderNumber, postalCode: String(shipping.shippingPostalCode) }),
        );
      }
      return [orderActions.updateOrder._action({ orderNumber, order }), ...itemAction];
    }),
  );

export const OrderShippingEpics = combineEpics(reloadPostalCodeAddress, updatePostalCodeAddress, loadAddress);
