import { connect } from 'react-redux';
import { AppState } from '../../../store';

import ItemContentPage from '../../../components/pages/ItemContentPage/ItemContentPage';

import { currentDesign } from '../../../store/order/selector/design';
import {
  getClothPage,
  getErrorTextsForRequiredCheckDialog,
  requiredCheckCompleted,
} from '../../../store/order/selector/cloth';
import { get as getOrder } from '../../../store/order';
import { Dispatch, bindActionCreators } from 'redux';
import { ItemContentPageEvents } from '../../../views/events/ItemContentPage';

function mapStateToProps(state: AppState) {
  return {
    hasClothCompleted: getClothPage.hasCompleted(state),
    hasDesignCompleted: currentDesign.hasCompleted(state),
    isEdit: getOrder.isEdit(state),
    requiredCheckCompleted: requiredCheckCompleted(state),
    errorTextForRequiredCheckDialog: getErrorTextsForRequiredCheckDialog(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(ItemContentPageEvents, dispatch),
  };
}
type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TPageContainerProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ItemContentPage);
