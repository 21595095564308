import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from '../../AppRouter';
import BackgroundProcess from '../../../containers/BackgroundProcess/BackgroundProcess';

import './MainTemplate.scss';

import Header from '../../../containers/components/Header/Header';
import ErrorBoundary from '../../organisms/ErrorBoundary/ErrorBoundary';
import SelectionDialog from '../../../containers/components/dialogs/SelectionDialog/SelectionDialog';
import OkCancelDialog from '../../../containers/components/dialogs/OkCancelDialog/OkCancelDialog';
import InformationDialog from '../../../containers/components/dialogs/InformationDialog';
import SaveTmpOrderDialog from '../../../containers/components/dialogs/SaveTmpOrderDialog/SaveTmpOrderDialog';
import RequiredCheckDialog from '../../../containers/components/dialogs/RequiredCheckDialog/RequiredCheckDialog';
import PurchaseHistoryDialog from '../../../containers/components/dialogs/PurchaseHistoryDialog';
import BrowserTab from '../../../containers/BrowserTab/BrowserTab';
import MemoDialog from '../../../containers/components/dialogs/MemoDialog';
import ProjectSelectorDialog from '../../../containers/components/dialogs/ProjectSelectorDialog';
import CouponInfoDialog from '../../../containers/components/dialogs/CouponInfoDialog';
import ErrorRetryActionDialog from '../../../containers/components/dialogs/errorRetryActionDialog';

const MainTemplate: React.FC = () => {
  return (
    <React.Fragment>
      <Router>
        <Header />
        <div className="page-wrapper print-css-enabled">
          <div className="pages-content">
            <ErrorBoundary>
              <AppRouter />
            </ErrorBoundary>
          </div>
        </div>
      </Router>
      {renderDialogs()}
      <BackgroundProcess />
    </React.Fragment>
  );

  function renderDialogs() {
    return (
      <React.Fragment>
        <SelectionDialog />
        <OkCancelDialog />
        <InformationDialog />
        <ErrorRetryActionDialog />
        <SaveTmpOrderDialog />
        <RequiredCheckDialog />
        <PurchaseHistoryDialog />
        <MemoDialog />
        <BrowserTab />
        <ProjectSelectorDialog />
        <CouponInfoDialog />
      </React.Fragment>
    );
  }
};

export default MainTemplate;
