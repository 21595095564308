/**
 * Epics用のhelper関数
 * Epic内では、AppStateに常時アクセスできる点が他と異なるため、AppStateからデータを取得する関数を本ファイルに置く
 */

import { AppState } from '../../store/index';
import path from 'ramda/es/path';
import {
  IItem,
  ICloth,
  IOnChangeClothValue,
  IOnChangeClothComposition,
  IComposition,
  IShipping,
  IDesign,
  ISize,
  IOrder,
  IPiece,
} from '../../store/_type/order';
import {
  IClothProduct,
  IAvailableOption,
  ISizeMeasurement,
  IPartsAdjustOption,
  TAdjustOptionPathParam,
  TSizeMeasurementPathParam,
  IRecommendPartsGauge,
  TRecommendedGaugeParam,
} from '../../store/_type/lookups/index';
import { IndexedObject } from '../../types';
import { ICustomer } from '../../store/_type/customer';
import { IStaff } from '../../store/_type/staff';
import { IOrderState } from '../../store/order';
import { IPayment } from '../../store/_type/payment';
import { IOrderDetailState } from '../../store/order-detail/action-reducers';
import { ILocalState } from '../../store/progress/state';
import { TPaymentState } from '../../store/payment/action-reducer';
import { IOrderDigestsSearch } from '../../types/orders/digest';
import { TMemoDialog } from '../../store/utils/dialog/memo';
import { TScProject, TProjectSearchCondition } from '../../types/project';

/**
 * AppState からデータを取得する関数郡 (主にEpicで利用)
 */
export const appStateSelector = (state: AppState) => new AppStateSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class AppStateSelector {
  constructor(private state: AppState) {}

  orderState(): IOrderState | undefined {
    return path(['order'], this.state);
  }

  orders(): IndexedObject<IOrder> | undefined {
    return path(['order', 'orders'], this.state);
  }

  order(orderNumber?: string | undefined): IOrder | undefined {
    return path(['order', 'orders', this.orderNumber(orderNumber)], this.state);
  }

  isEditOrder(): boolean {
    const order = this.orderState();
    return order ? order.isEdit : false;
  }

  orderNumber(orderNumber?: string | undefined) {
    return orderNumber && /^[0-9]+$/.test(orderNumber) ? orderNumber : this.state.order.currentOrderNumber;
  }

  item(orderNumber?: string): IItem | undefined {
    return path(['order', 'orders', this.orderNumber(orderNumber), 'item'], this.state);
  }

  pieces(orderNumber?: string): IPiece[] | undefined {
    return path(['pieces'], this.item(orderNumber));
  }

  cloth(orderNumber?: string): ICloth | undefined {
    return path(['cloth'], this.item(orderNumber));
  }

  design(orderNumber?: string): IDesign | undefined {
    return path(['design'], this.item(orderNumber));
  }

  size(orderNumber?: string): ISize | undefined {
    return path(['size'], this.item(orderNumber));
  }

  // TODO: 指定したキーのvalueの型になってない。。
  specifiedValueInCloth(key: IOnChangeClothValue['key'], orderNumber?: string): string {
    const cloth = this.cloth(orderNumber);
    return cloth !== undefined ? cloth[key] : '';
  }

  specifiedCompositionInCloth(key: IOnChangeClothComposition['key'], orderNumber?: string): IComposition[] {
    const cloth = this.cloth(orderNumber);
    return cloth !== undefined ? cloth[key] : [];
  }

  shipping(orderNumber?: string): IShipping | undefined {
    return path(['order', 'orders', this.orderNumber(orderNumber), 'shipping'], this.state);
  }

  payment(): IPayment | undefined {
    return path(['payment'], this.state);
  }

  paymentState(): TPaymentState | undefined {
    return path(['payment'], this.state);
  }

  allProducts(): IndexedObject<IClothProduct[]> | undefined {
    return path(['lookups', 'product', 'products'], this.state);
  }

  products(orderNumber?: string): IClothProduct[] | undefined {
    return path(['lookups', 'product', 'products', this.orderNumber(orderNumber)], this.state);
  }

  allAvailableOptions(): IndexedObject<IAvailableOption[]> | undefined {
    return path(['lookups', 'availableOption', 'availableOptions'], this.state);
  }

  avaliableOptions(orderNumber?: string): IAvailableOption[] | undefined {
    return path(['lookups', 'availableOption', 'availableOptions', this.orderNumber(orderNumber)], this.state);
  }

  allSizeMeasurements(): IndexedObject<ISizeMeasurement[]> | undefined {
    return path(['lookups', 'sizeMeasurement', 'sizeMeasurements'], this.state);
  }

  sizeMeasurements(orderNumber?: string): ISizeMeasurement[] | undefined {
    return path(['lookups', 'sizeMeasurement', 'sizeMeasurements', this.orderNumber(orderNumber)], this.state);
  }

  sizeMeasurementsRequestParams(orderNumber?: string): TSizeMeasurementPathParam | undefined {
    return path(['lookups', 'sizeMeasurement', 'requestParams', this.orderNumber(orderNumber)], this.state);
  }

  allAdjustOptions(): IndexedObject<IPartsAdjustOption[]> | undefined {
    return path(['lookups', 'adjustOption', 'adjustOptions'], this.state);
  }

  adjustOptions(orderNumber?: string): IPartsAdjustOption[] | undefined {
    return path(['lookups', 'adjustOption', 'adjustOptions', this.orderNumber(orderNumber)], this.state);
  }

  adjustOptionsRequestParams(orderNumber?: string): TAdjustOptionPathParam[] | undefined {
    return path(['lookups', 'adjustOption', 'requestParams', this.orderNumber(orderNumber)], this.state);
  }

  recommendGauge(orderNumber?: string): IRecommendPartsGauge[] | undefined {
    return path(['lookups', 'recommendGauge', 'recommendedGauges', this.orderNumber(orderNumber)], this.state);
  }

  recommendGaugeRequestParams(orderNumber?: string): TRecommendedGaugeParam | undefined {
    return path(['lookups', 'recommendGauge', 'requestParams', this.orderNumber(orderNumber)], this.state);
  }

  customer(): ICustomer | undefined {
    return path(['customer', 'data'], this.state);
  }

  isCustomerLoaded(): boolean | undefined {
    return path(['customer', 'isLoaded'], this.state);
  }

  staff(): IStaff | undefined {
    return path(['staff', 'staff', 'loggedStaff'], this.state);
  }

  orderDetail(): IOrderDetailState | undefined {
    return path(['orderDetail'], this.state);
  }

  progress(): ILocalState | undefined {
    return path(['progress'], this.state);
  }

  orderDigistFilter(): IOrderDigestsSearch | undefined {
    return path(['orderDigest', 'filterParams'], this.state);
  }

  memoDialog(): TMemoDialog | undefined {
    return path(['utils', 'dialog', 'memo'], this.state);
  }

  projectSearchCondition(): TProjectSearchCondition | undefined {
    return path(['utils', 'dialog', 'projectSelector', 'filter'], this.state);
  }

  selectedScProject(): TScProject | undefined {
    return path(['scProject'], this.state);
  }

  couponId(): string | undefined {
    return path(['utils', 'dialog', 'couponInfo', 'couponId'], this.state);
  }
}
