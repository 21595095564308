import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';
import SettlementEndPage from '../../../components/pages/SettlementEndPage/SettlementEndPage';
import { SettlementEndPageEvents } from '../../../views/events/SettlementEndPage';

function mapStateToProps(state: AppState) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(SettlementEndPageEvents, dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SettlementEndPage);
