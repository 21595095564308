import React from 'react';

import styles from './CustomerLoadInfo.module.scss';

import Button from '../../atoms/Button/Button';
import { langResource } from '../../../i18n/_new/resources/';

interface IProps {
  isLoaded: boolean;
  onOpenDialog: () => void;
}

const CustomerLoginInfo: React.FC<IProps> = (props: IProps) => {
  const { isLoaded } = props;

  const onClick = () => {
    props.onOpenDialog();
  };

  return (
    <React.Fragment>
      <div className={styles.customerWrapper}>
        <div className={styles.button}>
          <Button
            onClick={onClick}
            styles={['size-s']}
            textObj={langResource.loginPage.customerLoadButton}
            disabled={isLoaded}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerLoginInfo;
