import { createSelector } from 'reselect';
import { orderState } from './order';
import { INTIAL_ORDER } from '../initial-state';
import { orderStateSelector, orderSelector } from '../object-selector';

const itemSelector = createSelector(
  orderState,
  state =>
    orderStateSelector(state).hasCurrentOrder()
      ? orderSelector(orderStateSelector(state).currentOrder()).item()
      : { ...INTIAL_ORDER.item }, // TODO: initialize ???
);

export const getCurrentItem = {
  detail: createSelector(itemSelector, state => state),
  itemCode: createSelector(itemSelector, state => state.itemCode),
  parts: createSelector(itemSelector, state => state.pieces),
  categoryCode: createSelector(itemSelector, state => state.categoryCode),
  subCategoryCode: createSelector(itemSelector, state => state.subCategoryCode),
};
