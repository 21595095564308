import { getOrderDetail, updateOrderDetail } from './detail';
import { ApiError } from '../../models/error/api-error';

interface IUpdateOrderDetailMemo {
  serial: string;
  requestSerial: string;
  note: string;
}

/** 備考を更新する（注文詳細を取得し、該当の備考欄のみ書き換えて返却する） */
export const updateMemo = async (params: IUpdateOrderDetailMemo) => {
  try {
    const serial = params.requestSerial;
    const orderDetail: any = await getOrderDetail(serial);
    const path = { serial };
    orderDetail.res.json.items.find((item: any) => item.serialNumber === params.serial).note = params.note;
    const body = {
      ...orderDetail.res.json,
      items: [
        {
          ...orderDetail.res.json.items[0],
          note: params.note,
        },
      ],
    };
    return await updateOrderDetail(path, body);
  } catch (err) {
    return err instanceof ApiError ? err : new ApiError(err);
  }
};
