import actionCreatorFactory, { Action } from 'typescript-fsa';
import { ApiError } from '../../models/error/api-error';

// actions
const actionCreator = actionCreatorFactory('[ErrorHandler]');

type TApiErrorRetryActionParam = { error: ApiError; options: any; action: Action<any> };

export const actions = {
  apiError: actionCreator<{ error: ApiError; options: any }>('api error'),
  // エラーが発生した場合にアクションを再実行する
  apiErrorRetryAction: actionCreator<TApiErrorRetryActionParam>('api error retry action'),
};
