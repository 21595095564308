import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { actionCreatorFactory } from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import Logger from '../../helpers/common/logger';

export const actionObjectCreatorFactory = (prefix: string = '') => {
  const actionCreator = actionCreatorFactory();
  return <T>(description: string) => {
    const _action = actionCreator<T>(description); // typescript-sfa native action
    const type = (payload: T) => _action(payload); // action for Component's prop interface typeof
    const dispatcher = (dispatch: Dispatch) => (payload: T) => dispatch(_action(payload)); //
    return { _action, type, dispatcher };
  };
};

export const thunkActionObjectCreatorFactory = <S>(prefix: string = '') => {
  const thunkActionCreator = asyncFactory<S>(actionCreatorFactory(prefix));
  return <T, U>(description: string, func: (payload: T, dispatch: ThunkDispatch<T, any, AnyAction>) => Promise<U>) => {
    // typescript-sfa native action
    const _action = thunkActionCreator<T, U>(description, async (_payload, _dispatch) => func(_payload, _dispatch));
    const tp = (payload: T): void => {
      Logger.log('決して実行されない');
    }; // action for Component's prop interface typeof
    const dispatcher = (dispatch: ThunkDispatch<S, any, AnyAction>) => (payload: T): void => {
      dispatch(_action.action(payload));
    }; //
    return { _action, tp, dispatcher };
  };
};
