import { TMaster } from '../../types/index';

export const EDeliveryTimeZone = {
  unspecified: '0000',
};

export const MASTER_DELIVERY_TIME_ZONE: TMaster[] = [
  { code: EDeliveryTimeZone.unspecified, value: '指定なし' },
  { code: '0800', value: '午前中' },
  { code: '1200', value: '12:00～14:00' },
  { code: '1400', value: '14:00～16:00' },
  { code: '1600', value: '16:00～18:00' },
  { code: '1800', value: '18:00～20:00' },
  { code: '1900', value: '19:00～21:00' },
];
