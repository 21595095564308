import React from 'react';

import styles from './PointTotalPayment.module.scss';

import Title from '../../atoms/Title/Title';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import PriceTextPlane from '../../atoms/PriceTextSpan/PriceTextSpan';
import { langResource } from '../../../i18n/_new/resources';
import { IPaymentList } from '../../../types/new-store/components';

interface IProps {
  pricePrefix: string;
  paymentList: IPaymentList;
}

const PointTotalPayment: React.FC<IProps> = (props: IProps) => {
  const { pricePrefix } = props;
  const { orderTotal, shippingCost, total, usePoint, couponUsePoint } = props.paymentList;

  return (
    <div className={styles.noteContent}>
      <div className={styles.title}>
        <Title styles={['lineLightBrown']} textObj={langResource.pointPage.order} />
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <I18TextContainer textObj={langResource.pointPage.item} />
          </div>
          <div className={styles.columnSize2}>
            <PriceTextPlane prefix={pricePrefix} price={orderTotal} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <I18TextContainer textObj={langResource.pointPage.shippingCost} />
          </div>
          <div className={styles.columnSize2}>
            <PriceTextPlane prefix={pricePrefix} price={shippingCost} />
          </div>
        </div>
        <div className={styles.rowTotal}>
          <div className={styles.columnSize1}>
            <I18TextContainer textObj={langResource.pointPage.total} />
          </div>
          <div className={styles.columnSize2}>
            <PriceTextPlane prefix={pricePrefix} price={total} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointTotalPayment;
