import { toOrder, TItem } from '../converter/order-detail';
import { ICustomer } from '../../../store/_type/customer';
import {
  IShipping,
  ICloth,
  IPiece,
  IItem,
  IDesign,
  ISize,
  IDesignParts,
  IPartsSize,
  INudeSize,
  IOrder,
  IGauge,
  TProductKind,
  IComposition,
} from '../../../store/_type/order';
import { IPayment } from '../../../store/_type/payment';
import { getSubCategory, getPartsNumbers } from '../../orders/order-items';
import {
  getOptionType,
  hasShirt,
  isJacket,
  isDoubleDesignOptionClass,
  isPants,
  isModelSelectBoxOnClothSection,
  isWemen,
  hasJacket,
  isSpareParts,
  isValidSpareOption,
  isValidMainOption,
} from '../../item-thisisforreplaceall';
import { EOptionType } from '../../../types/option';
import { IndexedObject } from '../../../types';
import { distinctArray, by } from '../..';
import {
  NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT,
  NUDE_SIZE_OPTION_NUMBERS,
  TCategory,
  TPartsNumber,
  MASTER_CLOTH_COLOR,
} from '../../../lookups/master-thisisforreplaceall';
import Logger from '../../common/logger';
import { STOCK_FLAG } from '../../../types/inventory-search';
import { IStaff } from '../../../store/_type/staff';
import path from 'ramda/es/path';
import { fillCompositions } from '../../cloth-selection';
import { KR_BRAND, KR_BRANDS } from '../../../lookups/item-thisisforreplaceall';
import { getCode } from '../../master-lookup';
import { toClothProduct, toAvailableOptions, toPartsAdjustOption } from '../../orders/detail';
import { cloneDeep } from 'lodash';
import { TScProject } from '../../../types/project';

type TOrder = ReturnType<typeof toOrder>;

const toPostalCode = (postalCode: string) => postalCode.replace('-', '');

export const toCustomer = (order: TOrder): ICustomer => {
  const {
    memberscardNumber,
    customerBirthday,
    customerFamilyNameKana,
    customerFamilyNameKanji,
    customerGivenNameKana,
    customerGivenNameKanji,
    customerMailAddress,
    shippingPhoneNumber,
    shippingState,
    shippingCity,
    shippingStreet,
    shippingPostalCode,
  } = order;

  return {
    customerCode: '',
    customerSex: 0,
    customerBirthday,
    memberscardNumber,
    shippingPostalCode: toPostalCode(shippingPostalCode),
    shippingState,
    shippingCity,
    shippingStreet,
    customerFamilyNameKana,
    customerGivenNameKana,
    customerFamilyNameKanji,
    customerGivenNameKanji,
    shippingPhoneNumber,
    customerMailAddress: customerMailAddress || '',
  };
};

const toShipping = (order: TOrder): IShipping => {
  const {
    shippingCity,
    shippingCost,
    shippingPhoneNumber,
    shippingPostalCode,
    shippingState,
    shippingStreet,
    deliveryDateGuest,
    timeZoneCode,
    customerMailAddress,
    customerFamilyNameKana,
    customerGivenNameKana,
    customerFamilyNameKanji,
    customerGivenNameKanji,
    deliveryMethod,
    lotNumber,
    cutterNameKana,
    memberscardNumber,
  } = order;

  return {
    shippingCity,
    shippingCost,
    shippingPhoneNumber,
    shippingPostalCode: toPostalCode(shippingPostalCode),
    shippingState,
    shippingStreet,
    customerMailAddress: customerMailAddress || '',
    customerFamilyNameKana,
    customerGivenNameKana,
    customerFamilyNameKanji,
    customerGivenNameKanji,
    deliveryDateGuest,
    deliveryMethod,
    timeZoneCode,
    isSameOrderOne: true,
    lotNumber: lotNumber || '',
    cutterNameKana: cutterNameKana || '',
    memberscardNumber: memberscardNumber || '',
  };
};

const toPayment = (order: TOrder): IPayment => {
  const {
    usePoint,
    couponUsePoint,
    introducerCode,
    introducerName,
    favoriteCustomerCode,
    favoriteCustomerName,
    deliveryRate,
    corporationId,
    invoiceFlag,
  } = order;

  return {
    usePoint,
    couponUsePoints: couponUsePoint,
    introducerCode,
    introducerName,
    favoriteCustomerCode,
    favoriteCustomerName,
    hasIntroducer: introducerCode ? true : false,
    hasFavoriteCustomer: favoriteCustomerCode || corporationId ? true : false,
    isSettlement: true,
    deliveryRate,
    isCorporation: !!corporationId,
    needInvoice: invoiceFlag,
  };
};

const toScProject = (order: TOrder): TScProject => {
  const { salesEventId, salesRecordingDate, projectName, contactName, corporationId, scCompanyName } = order;
  return {
    salesEventId,
    salesRecordingDate,
    projectName,
    contactName,
    corporationId,
    companyName: scCompanyName,
  };
};

const toClothModelCode = (order: TOrder, itemIndex: number): string => {
  const { category, parts, brand } = order.items[itemIndex];
  const pieces = toPieces(order, itemIndex);
  if (!isModelSelectBoxOnClothSection(category, pieces, brand)) {
    return '';
  }
  // WEMENでジャケットがある場合はジャケットのoptionPatternを利用
  if (isWemen(category) && hasJacket(pieces)) {
    const { modelPattern } = parts.find(v => isJacket(v.partsNumber)) || { modelPattern: '' };
    return modelPattern;
  }
  return parts[0].modelPattern || '';
};

/**
 * #001/毛/50#002/綿/20#008/レーヨン/10#005/ポリエステル/10#007/キュプラ/10 を IComposition[]に変換する
 */
export const toMixing = (compositionStr: string) =>
  compositionStr
    .split('#')
    .filter(v => !!v)
    .map(v => v.split('/'))
    .map(v => ({ mixing: v[0], mixingRatio: +v[2] } as IComposition));

const getMixings = (item: TItem) => {
  const { brand, mixings, compositionFront, compositionBack } = item;
  if (KR_BRANDS.includes(brand)) {
    return {
      front: toMixing(compositionFront),
      back: toMixing(compositionBack),
    };
  }
  return { ...mixings };
};

const toCloth = (order: TOrder, itemIndex: number): ICloth => {
  const {
    brand,
    clothBrandCode,
    clothCode,
    productSeason,
    clothSeason,
    productNumber,
    personalOrderColorCode,
    personalOrderColorName,
    design,
    stockPlaceCode,
    vendorClothNumber,
    requiredScale,
    textileNumber,
  } = order.items[itemIndex];
  const clothModelCode = toClothModelCode(order, itemIndex);
  const { front, back } = getMixings(order.items[itemIndex]);

  return {
    // 生地商品一覧取得APIのパラメタとなる年季なので、製品年季を設定する
    seasonCode: productSeason,
    clothCode,
    brandCode: brand,
    clothModelCode,
    personalorderProductNumber: productNumber,
    personalorderColorCode: KR_BRAND.includes(brand)
      ? getCode(personalOrderColorName, MASTER_CLOTH_COLOR) || ''
      : personalOrderColorCode,
    design,
    compositionFront: fillCompositions(front, 6),
    compositionBack: fillCompositions(back, 2),
    stockPlaceCode,
    vendorClothNumber: KR_BRANDS.includes(brand) ? textileNumber : vendorClothNumber,
    clothSeasonCode: clothSeason,
    productSeasonCode: productSeason,
    clothBrandCode,
    hasStock: STOCK_FLAG.enoughStock,
    requiredScale,
  };
};

const toDesignParts = (order: TOrder, itemIndex: number): IDesignParts => {
  const { parts } = order.items[itemIndex];
  const pieces = toPieces(order, itemIndex);
  return pieces.reduce((pre, cur, index) => {
    const isSpare = isSpareParts(pieces, cur, index);
    const { modelPattern, modelCode, optionPattern, options: allOptions } = parts.find(
      by('partsNumber')(cur.partsNumber),
    ) || { modelPattern: '', modelCode: '', optionPattern: '', options: [] };
    const options = allOptions
      .filter(
        v =>
          getOptionType(v.optionNumber) === EOptionType.DESIGN_OPTION ||
          getOptionType(v.optionNumber) === EOptionType.FREE_TEXT_OPTION,
      )
      .filter(({ optionNumber }) => (isSpare ? isValidSpareOption(optionNumber) : isValidMainOption(optionNumber)));
    return { ...pre, [index]: { modelPattern, modelCode, optionPattern, options } };
  }, {} as IDesignParts);
};

const isDoubleOption = (order: TOrder, itemIndex: number): boolean => {
  const jacketParts = order.items[itemIndex].parts.find(v => isJacket(v.partsNumber));
  if (!jacketParts) {
    return false;
  }
  return jacketParts.options.some(v => isDoubleDesignOptionClass(v.optionNumber, v.optionClassNumber));
};

const toDesign = (order: TOrder, itemIndex: number): IDesign => {
  const designParts = toDesignParts(order, itemIndex);
  const isDouble = isDoubleOption(order, itemIndex);
  return {
    designParts,
    selecting: {
      partsIndex: '',
      optionNumber: '',
      optionClassNumber: '',
      hasOpenSelector: false,
    },
    isDouble,
  };
};

const splitGauge = (category: TCategory, partsNumber: TPartsNumber, mergedSizeCode: string) => {
  if (!mergedSizeCode) {
    return ['', '']; // ECデータにゲージの情報がないので、落ちないように
  }

  if (['CS', 'FM', 'TX'].includes(category)) {
    if (isPants(partsNumber)) {
      return [mergedSizeCode.substring(0, 1), mergedSizeCode.substring(1)];
    } else {
      return [mergedSizeCode.substring(0, 2), mergedSizeCode.substring(2)];
    }
  }
  // FIXME: ウィメンズのサイズ分割処理を変更する(下記のコードはおかしい)
  if (category === 'WM') {
    return mergedSizeCode.length <= 2 && !isNaN(mergedSizeCode as any)
      ? ['', mergedSizeCode]
      : [mergedSizeCode.substring(0, 1), mergedSizeCode.substring(1)];
  }
  return [mergedSizeCode, ''];
};

const toGauge = (category: TCategory, partsNumber: TPartsNumber, mergedSizeCode: string): IGauge => {
  const splitedGauge = splitGauge(category, partsNumber, mergedSizeCode);
  return { major: splitedGauge[0], minor: splitedGauge[1] };
};

const toPartsSize = (order: TOrder, itemIndex: number, isForRefCreation: boolean): IndexedObject<IPartsSize> => {
  const { orderDate } = order;
  const { parts, category } = order.items[itemIndex];
  const pieces = toPieces(order, itemIndex);
  const partsNumbers = distinctArray(pieces.map(v => v.partsNumber));

  return partsNumbers.reduce((pre, cur) => {
    const matched = parts.find(v => v.partsNumber === cur);
    if (!matched) {
      return { ...pre };
    }
    const { options, sizeCode } = matched;
    const gauge = toGauge(category, matched.partsNumber, sizeCode);
    const measurements = options.filter(v => getOptionType(v.optionNumber) === EOptionType.FINISHED_SIZE);
    const adjustOptions = options.filter(v => getOptionType(v.optionNumber) === EOptionType.ADJUST_SIZE_OPTION);
    const data = {
      gauge: isForRefCreation ? { major: '', minor: '' } : gauge,
      measurements: isForRefCreation ? [] : [...measurements],
      // 流用の場合でも返却する
      adjustOptions: cloneDeep(adjustOptions),
      standardSizes: [],
      history: {
        date: orderDate,
        gauge,
        measurements,
        adjustOptions,
      },
      hasChanged: false,
    };
    return { ...pre, [cur]: { ...data } };
  }, {} as IndexedObject<IPartsSize>);
};

const toNudeSize = (order: TOrder, itemIndex: number): INudeSize => {
  const { orderDate } = order;
  const { parts } = order.items[itemIndex];
  const allNudeMeasurements = parts
    .map(v => v.options.filter(vv => getOptionType(vv.optionNumber) === EOptionType.NUDE_SIZE))
    .reduce((p, c) => [...p, ...c]);

  const pieces = toPieces(order, itemIndex);
  const measurementNumbers = hasShirt(pieces) ? NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT : NUDE_SIZE_OPTION_NUMBERS;

  return {
    date: orderDate,
    measurements: measurementNumbers.map(
      v =>
        allNudeMeasurements.find(vv => vv.optionNumber === v) || {
          optionNumber: v,
          optionClassNumber: '',
          optionClassName: '0',
        },
    ),
  };
};

const toSize = (order: TOrder, itemIndex: number, isForRefCreation: boolean): ISize => {
  const pieces = toPieces(order, itemIndex);
  const parts = toPartsSize(order, itemIndex, isForRefCreation);
  const nude = toNudeSize(order, itemIndex);
  return {
    nude,
    parts,
    selecting: {
      partsNumber: pieces[0].partsNumber,
    },
  };
};

const toPieces = (order: TOrder, itemIndex: number): IPiece[] => {
  const { item: itemCode, brand } = order.items[itemIndex];
  const partsNumbers = getPartsNumbers(itemCode, brand);
  return partsNumbers.sort((a, b) => +a - +b).map((v, i) => ({ index: String(i), partsNumber: v }));
};

const toItem = (
  order: TOrder,
  itemIndex: number,
  isForRefCreation: boolean = true,
): { item: IItem; orderDate: string; serialNumber: string; serialYear: string } => {
  const { item: itemCode } = order.items[itemIndex];
  const pieces = toPieces(order, itemIndex);
  const cloth = toCloth(order, itemIndex);
  const design = toDesign(order, itemIndex);
  const size = toSize(order, itemIndex, isForRefCreation);
  //
  const categoryCode = order.items[itemIndex].category;
  const subCategoryCode =
    getSubCategory(
      itemCode,
      categoryCode,
      pieces.map(v => v.partsNumber),
      design.isDouble,
      cloth.brandCode,
    ) || '';
  return {
    item: {
      pieces,
      cloth,
      design,
      size,
      categoryCode,
      subCategoryCode,
      itemCode,
    },
    serialNumber: order.items[itemIndex].serialNumber,
    serialYear: order.items[itemIndex].serialYear,
    orderDate: order.orderDate,
  };
};

const toStaff = (order: TOrder): IStaff => {
  const { shopCode, staffCode, shopName, staffName } = order;

  return {
    tempoCode: shopCode,
    tempoName: shopName,
    staffCode,
    staffName,
    // not used
    tempoId: '',
    managerFlag: false,
    staffType: '',
    couponId: '',
    masterInfo: [],
    categoryInfo: [],
  };
};

/**
 * MEMO: orderがitem単位（https://onward.backlog.jp/view/OPS_ORDERSYS-164#comment-87529572）
 * 注文元がBSTAの場合はitemは必ず1つ、BSTA以外の場合には複数ある場合があるのでserialNumberでfilterをかけて返却
 * @param orderDetail
 * @param requestSerial 「serialYear + serialNumber(子ども)」3桁目以降が、serialNumber
 * @returns
 */
export const toDiversionOrder = (orderDetail: TOrder, requestSerial?: string) => {
  const customer = toCustomer(orderDetail);
  const shipping = toShipping(orderDetail);
  const payment = toPayment(orderDetail);
  const scProject = toScProject(orderDetail);
  const staff = toStaff(orderDetail);
  const items = orderDetail.items.map((v, i) => toItem(orderDetail, i));
  const { item, serialNumber, serialYear, orderDate } = requestSerial
    ? items.find(v => v.serialNumber === requestSerial.substr(2)) || items[0]
    : items[0];
  const order: IOrder = {
    item,
    shipping,
    serialNumber,
    serialYear,
    orderDate,
    shortestDeliveryDate: '',
  };
  const { productKind } = orderDetail;

  Logger.log('conv-state-reverce', { customer, payment, order, productKind, staff, scProject });
  return { customer, payment, order, productKind, staff, scProject };
};

/** 注文詳細からlookupsを作成する */
const toLookups = (orderDetail: TOrder) => {
  const { taxRate } = orderDetail;
  return orderDetail.items.map((serverItem, i) => {
    const orderNumber = `###${i}`;
    const { item } = toItem(orderDetail, i, false);
    const product = toClothProduct(item, serverItem, taxRate);
    const availableOptions = toAvailableOptions(serverItem);
    const partsAdjustOptions = toPartsAdjustOption(serverItem);
    return { orderNumber, products: [product], availableOptions, partsAdjustOptions };
  });
};

/**
 * 1つの注文に複数商品の注文があったとき、1つ目の商品にのみShippingCostを持たせる
 * @param shipping
 * @param itemIndex
 * @returns
 */
const changeShippingCostOfSubsequentOrder2zero = (shipping: IShipping, itemIndex: number): IShipping => {
  // 1つ目の商品の場合はshippingをそのまま返す
  if (itemIndex === 0) {
    return shipping;
  }

  // 2つ目以降の商品はshippingCostを0にして返す
  return {
    ...shipping,
    shippingCost: 0,
  };
};

/** 詳細用の注文を引くときに利用する */
export const toLoadOrderDetail = (orderDetail: TOrder) => {
  const customer = toCustomer(orderDetail);
  const shipping = toShipping(orderDetail);
  const payment = toPayment(orderDetail);
  const scProject = toScProject(orderDetail);
  const staff = toStaff(orderDetail);
  const items = orderDetail.items.map((v, i) => toItem(orderDetail, i, false));
  const productKind = orderDetail.productKind as TProductKind;
  // TODO: 直下にnoteがあるはずだが、items[0]配下に入っていたので、とりあえず無理やり取得している
  const note = (path(['note'], orderDetail) || path(['items', 0, 'note'], orderDetail) || '') as string;
  const orders = items.reduce((pre, cur, i) => {
    const orderNumber = `###${i}`;
    const { item, orderDate, serialNumber, serialYear } = cur;
    return {
      ...pre,
      [orderNumber]: {
        item,
        shipping: changeShippingCostOfSubsequentOrder2zero(shipping, i),
        orderDate,
        serialNumber,
        serialYear,
        shortestDeliveryDate: shipping.deliveryDateGuest || '20991231',
      },
    };
  }, {} as IndexedObject<IOrder>);
  const dummyLookups = toLookups(orderDetail);
  return { orders, payment, staff, customer, productKind, note, scProject, dummyLookups };
};

export type TDiversionOrder = ReturnType<typeof toDiversionOrder>;

export type TOrderDetail = ReturnType<typeof toLoadOrderDetail>;
