import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../../lib/action-creator-factory';
import { IErrorRetryActionDialog } from '../../../../types/dialog';

// action
const ac = actionObjectCreatorFactory();

const actionTitle = 'Error Retry Action Dialog';

export const errorRetryActionDialogActions = {
  show: ac<IErrorRetryActionDialog>(`[${actionTitle}] show`),
  close: ac(`[${actionTitle}] close`),
};

// reducer
const initialState: IErrorRetryActionDialog = {
  hasOpen: false,
  title: null,
  contents: '',
  action: null,
};

export const errorRetryActionDialogReducer = reducerWithInitialState(initialState)
  .case(errorRetryActionDialogActions.show._action, (state, payload) => ({ ...payload }))
  .case(errorRetryActionDialogActions.close._action, state => ({ ...initialState }));
