/**
 * container/page にて設定された各ページ用のイベント（action）を監視し、store側へ転送する
 */

import { combineEpics } from 'redux-observable';

import { ClothPageListeners } from './clothPageListeners';
import { DesignPageListeners } from './designPageListeners';
import { ItemConfirmationPageListeners } from './itemConfirmationPageListeners';
import { InventoryPageListeners } from './inventoryPageListener';
import { OrderDigestsSearchPageListener } from './orderDigestsSearchPageListener';
import { SizeCorrectionPageListeners } from './sizeCorrectionPageListeners';
import { HeaderListeners } from './headerListeners';
import { SettlementPageListener } from './settlementPageListeners';
import { OrderDetailPageListeners } from './orderDetailPageListeners';
import { CommonPageListeners } from './commonPageListeners';
import { SettlementEndPageListener } from './settlementEndPageListeners';
import { ItemContentPageListeners } from './itemContentPageListeners';
import { PurchaseHistoryDialogListener } from './purchaseHistoryDialogListeners';
import { AddressPageListeners } from './addressPageListeners';
import { ProjectSelectorDialogListener } from './projectSelectorDialogListener';
import { CouponInfoDialogListener } from './couponInfoDialogListener';

export const EventLisnters = combineEpics(
  HeaderListeners,
  ClothPageListeners,
  DesignPageListeners,
  ItemConfirmationPageListeners,
  InventoryPageListeners,
  OrderDigestsSearchPageListener,
  SizeCorrectionPageListeners,
  SettlementPageListener,
  SettlementEndPageListener,
  OrderDetailPageListeners,
  CommonPageListeners,
  ItemContentPageListeners,
  PurchaseHistoryDialogListener,
  AddressPageListeners,
  ProjectSelectorDialogListener,
  CouponInfoDialogListener,
);
