import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '../../atoms/Button/Button';
import { Nullable } from '../../../types';
import { langResource } from '../../../i18n/_new/resources/';
import { TErrorRetryActionDialogProps } from '../../../containers/components/dialogs/errorRetryActionDialog';

type TProps = TErrorRetryActionDialogProps;

const errorRetryActionDialog: React.FC<TProps> = (props: TProps) => {
  const { hasOpen, data, onRetryAction, onClose } = props;
  const { title, contents } = props.data;

  const onRetryAndCloseHandler = () => {
    onRetryAction(data);
    onClose({});
  };

  return (
    <Dialog className="dialog" open={hasOpen} aria-labelledby="form-dialog-title">
      {renderTitle(title)}
      <DialogContent className="dialog__contents">{renderContents(contents)}</DialogContent>
      <DialogActions className="dialog__actions">
        <Button onClick={onRetryAndCloseHandler} textObj={langResource.dialog.reTryButonLabel} />
      </DialogActions>
    </Dialog>
  );

  function renderTitle(value: Nullable<string>) {
    return value ? <DialogTitle id="form-dialog-title">{value}</DialogTitle> : null;
  }

  function renderContents(value: string) {
    return (
      <div className="content-wrapper">
        <p>{value}</p>
      </div>
    );
  }
};

export default errorRetryActionDialog;
