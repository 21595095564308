import { ILookupItem } from './lookup';
import { distinctArray } from '../helpers';
import { DEFAULT_SELECT_OPTION } from '../lookups/temporary-lookups';

export interface IInventorySearchState {
  results: IInventory;
}

export interface IInventory {
  updateDate: string;
  count: number;
  stocks: IStocks[];
}

export interface IStocks {
  brandCode: string;
  seasonCode: string;
  productNumber: string;
  colorCode: string;
  stockFlag: string;
}

export interface IInventorySearch {
  brand: ILookupItem;
  season: ILookupItem;
  productNumber: string;
  enoughStock: boolean;
  littleStock: boolean;
  noStock: boolean;
}

export function getInventorySearch(): IInventorySearch {
  return {
    brand: { id: 0, value: 'Select' },
    season: { id: 0, value: 'Select' },
    productNumber: '',
    enoughStock: false,
    littleStock: false,
    noStock: false,
  };
}

export function getBrandLookup(stocks: IStocks[]): ILookupItem[] {
  return [
    DEFAULT_SELECT_OPTION,
    ...distinctArray(stocks.map(stock => stock.brandCode)).map((item, i) => ({ id: i + 1, value: item })),
  ];
}

export function getSeasonLookup(stocks: IStocks[]): ILookupItem[] {
  return [
    DEFAULT_SELECT_OPTION,
    ...distinctArray(stocks.map(stock => stock.seasonCode)).map((item, i) => ({ id: i + 1, value: item })),
  ];
}

export const STOCK_FLAG = {
  noStock: '0',
  littleStock: '1',
  enoughStock: '2',
};

export function getStockSymbol(stockCd: string): string {
  const stockSymbol = {
    [STOCK_FLAG.noStock]: '×',
    [STOCK_FLAG.littleStock]: '△',
    [STOCK_FLAG.enoughStock]: '○',
  };

  return stockSymbol[stockCd];
}
