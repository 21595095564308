import { IStaff } from '../../store/_type/staff';
import { StaffType } from '../../lookups/staff';

export function isValidStaff(staff: IStaff): boolean {
  return !!(staff && staff.tempoCode && staff.staffCode && staff.tempoId);
}

/** OPS社内か */
export function isInternalStaff(staffType: string): boolean {
  return staffType === StaffType.INTERNAL_STAFF;
}
