import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { get as getSaveTmpOrderDialogState, saveTmpOrderActions } from '../../../../store/utils/dialog/save-tmpOrder';
import { actions as progressActions } from '../../../../store/progress/actions';
import SaveTmpOrderDialog from '../../../../components/organisms/SaveTmpOrderDialog/SaveTmpOrderDialog';
import { get as customer } from '../../../../store/customer';
import { get as order } from '../../../../store/order';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: getSaveTmpOrderDialogState.hasOpen(state),
    priorityCustomerName: order.customerName(state),
    customerName: customer.customerName(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onSave: (params: string) => dispatch(progressActions.update.started(params)),
    onClose: saveTmpOrderActions.close.dispatcher(dispatch),
  };
}

export type TSaveTmpOrderDialogProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SaveTmpOrderDialog);
