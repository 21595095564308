export const homePage = {
  classification: {
    jp: 'オーダー区分',
    en: 'Order Class',
  },
  /** 製品 */
  product: {
    jp: '製品',
    en: 'Product',
  },
  /** 作り直し */
  remake: {
    jp: '作り直し',
    en: 'Remake',
  },
  orderSearch: {
    jp: '注文検索',
    en: 'Order Search',
  },
  fabricDelivery: {
    jp: '在庫検索',
    en: 'Stock Search',
  },
  classic: {
    jp: 'クラシック',
  },
  easy: {
    jp: 'EASY',
  },
  formal: {
    jp: 'フォーマル',
  },
  shirt: {
    jp: 'シャツ',
  },
  women: {
    jp: 'ウイメンズ',
  },
  suit: {
    jp: 'スーツ',
  },
  setup: {
    jp: 'セットアップ',
  },
  jacket: {
    jp: 'ジャケット',
  },
  trousers: {
    jp: 'トラウザーズ',
  },
  vest: {
    jp: 'ベスト',
  },
  tuxedo: {
    jp: 'タキシード',
  },
  longSleve: {
    jp: '長袖',
  },
  shortSleve: {
    jp: '半袖',
  },
  womanlongSleeve: {
    jp: 'ウィメンズ長袖',
  },
  womanshortSleeve: {
    jp: 'ウィメンズ半袖',
  },
  pants: {
    jp: 'パンツ',
  },
  skirt: {
    jp: 'スカート',
  },
  onepiece: {
    jp: 'ワンピース',
  },
  pantSuit: {
    jp: 'パンツスーツ',
  },
  skirtSuit: {
    jp: 'スカートスーツ',
  },
  coat: {
    jp: 'コート',
  },
  tops: {
    jp: 'トップス',
  },
  couponInfoDisplay: {
    jp: 'クーポン情報表示',
    en: 'Coupon Info Display',
  },
};
