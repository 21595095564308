import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap, filter, debounceTime } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { actions } from './actions';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IItem, IPartsSize, IOrder, IOption, ISize, IPiece } from '../../_type/order';
import { piecesSelector, designSelector } from '../object-selector';
import { IndexedObject, NestedPartial } from '../../../types';
import { INITIAL_PARTS_SIZE, INITIAL_BASE_GAUGE } from '../initial-state';
import { getPartialOrder } from '../helper/conv-partial-order';
import { orderActions } from '..';
import { sizeMeasurementAsyncActions } from '../../lookups/size-measurement/action-reducer';
import { adjustOptionAsyncActions } from '../../lookups/adjust-option/action-reducer';
import { toStandardSizes } from '../../../helpers/api/converter/standard-size';
import { isShirtOrder } from '../../../helpers/orders/category';
import {
  NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT,
  NUDE_SIZE_OPTION_NUMBERS,
  TPartsNumber,
} from '../../../lookups/master-thisisforreplaceall';
import { ICustomer } from '../../_type/customer';
import { isJacket, hasJacket, isOrderDetailOrderNumber } from '../../../helpers/item-thisisforreplaceall';
import { recommendedGaugeAsyncActions } from '../../lookups/recommend-gauge/action-reducer';
import {
  IRecommendPartsGauge,
  ISizeMeasurement,
  IPartsAdjustOption,
  IGauge,
  IAvailableOption,
} from '../../_type/lookups';
import { by } from '../../../helpers';
import { ApiError } from '../../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { getStandardSizeList } from '../../../services/items';
import {
  getAdjustOptionParams,
  isSameAdjustOptionParams,
  getRecommendedGaugeParam,
  isSameRecommendedGaugeParam,
  getStandardSizeParam,
  hasGauge,
  updateMeasurements,
  editAdjustOptions,
  isSameBrand,
  isSameModel,
  isSameGauge,
  isSameNudeSize,
  findGauge,
  coverHistorySizeToStandardSize,
  getLeftAndRightTogetherConfig,
  isSameJacketGroupBrand,
} from '../../../helpers/size-correction';
import { NudeSizeService } from '../../../services/nude-size';
import { sizeMeasurementsSelector, adjustOptionsSelector } from '../../lookups/object-selector';
import { IOrderDetailState, orderDetailActions } from '../../order-detail/action-reducers';
import { getPartsSizeHistory } from '../../../services/parts-size';
import equals from 'ramda/es/equals';
import prop from 'ramda/es/prop';

// sample
// const sample: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
//   action$.pipe(
//     ofAction(actions.loadNudeSize.started),
//     mergeMap(action => {
//       return [];
//     }),
//   );

const loadInitialize: Epic<
  AnyAction,
  Action<void> | Action<NestedPartial<IOrder>> | Action<Parameters<typeof actions.loadNudeSize.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.onLoadInitialize),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      return { item };
    }),
    filter(params => params.item !== undefined),
    map(obj => {
      const item = obj.item as IItem;
      const { pieces } = item;
      const { parts, selecting } = item.size;
      const partsNumbers = piecesSelector(pieces).distinctPartsNumbers();
      // 既存がある場合は既存のデータをコピー.そうでない場合はinitialStateをコピーする.
      const newParts = partsNumbers.reduce((pre, cur) => {
        const partsSize = parts[cur] ? cloneDeep(parts[cur]) : cloneDeep(INITIAL_PARTS_SIZE);
        return { ...pre, [cur]: partsSize };
      }, {} as IndexedObject<IPartsSize>);
      // 最初に表示するパーツの設定
      const partsNumber = partsNumbers.find(v => v === selecting.partsNumber) || partsNumbers[0];
      return { newParts, partsNumber };
    }),
    mergeMap(({ newParts, partsNumber }) => {
      const data = getPartialOrder.fromSizeInitialize(partsNumber, newParts);
      return [
        // partsSizeの更新、採寸項目取得、調整可能オプション取得、ヌード寸法取得を呼び出す
        orderActions.updateCurrentOrder._action(data),
        actions.loadSizeMeasurement(),
        actions.loadAdjustOption(),
        actions.loadNudeSize.started({}),
      ];
    }),
  );

const loadSizeMeasurement: Epic<
  AnyAction,
  Action<Parameters<typeof sizeMeasurementAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadSizeMeasurement),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      const orderNumber = appStateObj.orderNumber();
      const prevParam = appStateObj.sizeMeasurementsRequestParams();
      return { item, orderNumber, prevParam };
    }),
    filter(params => params.item !== undefined),
    map(params => {
      const item = params.item as IItem;
      const partsModelPatterns = designSelector(item.design).partsModelPatterns(item.pieces);
      const modelPatterns = partsModelPatterns.map(prop('modelPattern'));
      const currentParam = { brandCode: item.cloth.brandCode, modelPatterns };
      return { ...params, currentParam, item };
    }),
    filter(params => params.prevParam === undefined || !equals(params.prevParam, params.currentParam)),
    map(params => {
      return sizeMeasurementAsyncActions.loadData.started({ orderNumber: params.orderNumber, item: params.item });
    }),
  );

const loadedSizeMeasurement: Epic<AnyAction, Action<NestedPartial<IOrder>>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(sizeMeasurementAsyncActions.loadData.done),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const item = appStateObj.item();
      const sizeMeasurements = appStateObj.sizeMeasurements();
      return { payload, orderNumber, sizeMeasurements, item };
    }),
    filter(
      // MEMO: currentOrderNumber === orderNumberは画面に表示しているorderってことで
      ({ payload, orderNumber, sizeMeasurements, item }) =>
        orderNumber === payload.params.orderNumber && sizeMeasurements !== undefined && item !== undefined,
    ),
    map(obj => {
      const item = obj.item as IItem;
      const sizeMeasurements = obj.sizeMeasurements as ISizeMeasurement[];
      const selector = sizeMeasurementsSelector(sizeMeasurements);
      const { parts } = item.size;
      // stateから採寸項目をチェックし、差分の追加削除を行う
      const newParts = Object.entries(parts).reduce((pre, cur) => {
        const [partsNumber, partsSize] = cur;
        const measurements = selector.filteredSelectedMeasurements(partsNumber as TPartsNumber, partsSize.measurements);
        return { ...pre, [partsNumber]: { ...partsSize, measurements } };
      }, {} as IndexedObject<IPartsSize>);
      const data = getPartialOrder.fromPartsSize(newParts);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

const loadAdjustOption: Epic<
  AnyAction,
  Action<Parameters<typeof adjustOptionAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadAdjustOption),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      const orderNumber = appStateObj.orderNumber();
      const prevParam = appStateObj.adjustOptionsRequestParams();
      return { item, orderNumber, prevParam };
    }),
    filter(params => params.item !== undefined),
    map(params => {
      const item = params.item as IItem;
      const currentParam = getAdjustOptionParams(item);
      return { ...params, currentParam };
    }),
    filter(
      params => params.prevParam === undefined || !isSameAdjustOptionParams(params.prevParam, params.currentParam),
    ),
    map(params => {
      return adjustOptionAsyncActions.loadData.started({ orderNumber: params.orderNumber });
    }),
  );

const loadedAdjustOption: Epic<AnyAction, Action<NestedPartial<IOrder>>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(adjustOptionAsyncActions.loadData.done),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const item = appStateObj.item();
      const adjustOptions = appStateObj.adjustOptions();
      return { payload, orderNumber, adjustOptions, item };
    }),
    filter(
      // MEMO: currentOrderNumber === orderNumberは画面に表示しているorderってことで
      ({ payload, orderNumber, adjustOptions, item }) =>
        orderNumber === payload.params.orderNumber && adjustOptions !== undefined && item !== undefined,
    ),
    map(obj => {
      const item = obj.item as IItem;
      const options = obj.adjustOptions as IPartsAdjustOption[];
      const selector = adjustOptionsSelector(options);
      const { parts } = item.size;
      // stateから採寸項目をチェックし、差分の追加削除を行う
      const newParts = Object.entries(parts).reduce((pre, cur) => {
        const [partsNumber, partsSize] = cur;
        const adjustOptions = selector.filteredSelectedOptions(partsNumber as TPartsNumber, partsSize.adjustOptions);
        return { ...pre, [partsNumber]: { ...partsSize, adjustOptions } };
      }, {} as IndexedObject<IPartsSize>);
      const data = getPartialOrder.fromPartsSize(newParts);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

const loadNudeSize: Epic<
  AnyAction,
  Action<void> | Action<NestedPartial<IOrder>> | Action<Parameters<typeof actions.loadNudeSize.done>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadNudeSize.started),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const item = appStateObj.item();
      const orders = appStateObj.orders();
      const customer = appStateObj.customer();
      return { orderNumber, item, orders, customer };
    }),
    filter(params => params.item !== undefined && params.orders !== undefined && params.customer !== undefined),
    mergeMap(obj => {
      const item = obj.item as IItem;
      const orders = obj.orders as IndexedObject<IOrder>;
      const customer = obj.customer as ICustomer;
      return NudeSizeService.loadNudeSize(item, obj.orderNumber, orders, customer);
    }),
    mergeMap(nudeSize => {
      const data = getPartialOrder.fromSizeChangeNude(nudeSize);
      return [orderActions.updateCurrentOrder._action(data), actions.loadNudeSize.done({ params: {}, result: {} })];
    }),
  );

/** ヌード寸法の読み込み後に推奨ゲージを読み込む */
const loadedNudeSize: Epic<
  AnyAction,
  Action<Parameters<typeof recommendedGaugeAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadNudeSize.done),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      const orderNumber = appStateObj.orderNumber();
      const prevParam = appStateObj.recommendGaugeRequestParams(orderNumber);
      const availableOptions = appStateObj.avaliableOptions();
      return { prevParam, item, orderNumber, availableOptions };
    }),
    filter(params => params.item !== undefined && params.availableOptions !== undefined),
    map(obj => {
      const { prevParam, orderNumber } = obj;
      const item = obj.item as IItem;
      const availableOptions = obj.availableOptions as IAvailableOption[];
      const currentParam = getRecommendedGaugeParam(item, availableOptions);
      return { prevParam, currentParam, orderNumber, item };
    }),
    filter(params => !isSameRecommendedGaugeParam(params.currentParam, params.prevParam)),
    map(({ orderNumber, item, prevParam }) => {
      return recommendedGaugeAsyncActions.loadData.started({ orderNumber, item, prevParam });
    }),
  );

/** 推奨ゲージを読み込む（ヌード寸法変更時） */
const loadRecommandedGauge: Epic<
  AnyAction,
  Action<Parameters<typeof recommendedGaugeAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadRecommandedGauge),
    debounceTime(800),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      const orderNumber = appStateObj.orderNumber();
      const prevParam = appStateObj.recommendGaugeRequestParams(orderNumber);
      const availableOptions = appStateObj.avaliableOptions();
      return { item, orderNumber, prevParam, availableOptions };
    }),
    filter(params => params.item !== undefined && params.availableOptions !== undefined),
    map(obj => {
      const { prevParam, orderNumber } = obj;
      const item = obj.item as IItem;
      const availableOptions = obj.availableOptions as IAvailableOption[];
      const currentParam = getRecommendedGaugeParam(item, availableOptions);
      return { prevParam, currentParam, orderNumber, item };
    }),
    filter(params => !isSameRecommendedGaugeParam(params.currentParam, params.prevParam)),
    map(({ orderNumber, item, prevParam }) => {
      return recommendedGaugeAsyncActions.loadData.started({ orderNumber, item, prevParam });
    }),
  );

/** 推奨ゲージを読み込んだ後の処理 */
const loadedRecommandedGauge: Epic<
  AnyAction,
  Action<void> | Action<NestedPartial<IOrder>> | Action<Parameters<typeof actions.loadStandardSize.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(recommendedGaugeAsyncActions.loadData.done),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const item = appStateObj.item();
      const recommendedGauges = appStateObj.recommendGauge();
      const availableOptions = appStateObj.avaliableOptions();
      return { payload, item, orderNumber, recommendedGauges, availableOptions };
    }),
    filter(
      // 画面に表示しているorderNumberのみ反映
      ({ payload, orderNumber, item, recommendedGauges, availableOptions }) =>
        payload.params.orderNumber === orderNumber &&
        item !== undefined &&
        recommendedGauges !== undefined &&
        availableOptions !== undefined,
    ),
    mergeMap(obj => {
      const { orderNumber } = obj;
      const { prevParam } = obj.payload.params;
      const item = obj.item as IItem;
      const availableOptions = obj.availableOptions as IAvailableOption[];
      const currentParam = getRecommendedGaugeParam(item, availableOptions);
      const recommendedGauges = obj.recommendedGauges as IRecommendPartsGauge[];
      const reloadPartsNumber: TPartsNumber[] = [];

      const pieces = Object.keys(item.size.parts).map((partNum, i) => {
        return {
          index: i + '',
          partsNumber: partNum,
        };
      });
      const isHasJacket = hasJacket(pieces as IPiece[]);
      const isChangedModelExceptJacket = pieces.some(piece => {
        return (
          !isJacket(piece.partsNumber as TPartsNumber) &&
          !isSameModel(piece.partsNumber as TPartsNumber, currentParam, prevParam)
        );
      });

      const newParts = Object.entries(item.size.parts).reduce((pre, cur) => {
        const [partsNumber, partsSize] = cur;
        // partsに合致するゲージを取得する
        const { recommendedGauge, allGauges } = recommendedGauges.find(by('partsNumber')(partsNumber)) || {
          recommendedGauge: { major: '', minor: '' },
          allGauges: [] as IGauge[],
        };

        // 仕上がり寸法が変更 かつ 標準サイズに関わる情報が変更されていないとき
        const isSame =
          isSameBrand(currentParam, prevParam) &&
          isSameNudeSize(currentParam, prevParam) &&
          isSameModel(partsNumber as TPartsNumber, currentParam, prevParam);

        // MEMO: このケースはmodel変更時のケースを考慮した
        // 仕上がり寸法が変更 かつ リクエスト条件が一緒 かつ 選択中のゲージが一覧にある場合は推奨ゲージを設定しない
        if (partsSize.hasChanged && isSame && allGauges.find(v => isSameGauge(v, partsSize.gauge))) {
          return { ...pre, [partsNumber]: { ...partsSize } };
        }

        reloadPartsNumber.push(partsNumber as TPartsNumber);
        return { ...pre, [partsNumber]: { ...partsSize, gauge: recommendedGauge } };
      }, {} as IndexedObject<IPartsSize>);
      const data = getPartialOrder.fromPartsSize(newParts);
      // マスターサイズが変更になったパーツの標準サイズ取得を呼び出す
      const loadStandardSizeActions = reloadPartsNumber.map(partsNumber =>
        actions.loadStandardSize.started({ orderNumber, partsNumber, isAutoUpdate: true }),
      );
      return [...loadStandardSizeActions, orderActions.updateCurrentOrder._action(data)];
    }),
  );

/** 標準サイズ取得 */
const loadStandardSize: Epic<
  AnyAction,
  | Action<void>
  | Action<{ error: ApiError; options: any }>
  | Action<Parameters<typeof actions.loadStandardSize.done>[0]>
  | Action<NestedPartial<IOrder>>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadStandardSize.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = payload.item ? payload.item : appStateObj.item(payload.orderNumber);
      const availableOptions = appStateObj.avaliableOptions(payload.orderNumber);
      return { payload, item, availableOptions };
    }),
    filter(({ payload, item }) => item !== undefined && item.size.parts[payload.partsNumber] !== undefined),
    map(obj => {
      const { payload } = obj;
      const item = obj.item as IItem;
      const availableOptions = obj.availableOptions as IAvailableOption[];
      const { parts } = item.size;
      const params = getStandardSizeParam(payload.partsNumber, item, availableOptions);
      return { payload, parts, item, params };
    }),
    // MEMO: 推奨ゲージがない時はhasGaugeがfalseになる
    filter(({ params }) => params.modelCode !== '' && hasGauge(params.gauge)),
    mergeMap(async ({ payload, parts, item, params }) => {
      const res = await getStandardSizeList(params)
        .then(toStandardSizes)
        .catch(err => err);
      return { payload, res, parts, item };
    }),
    mergeMap(({ payload, res, parts, item }) => {
      if (res instanceof ApiError) {
        const { partsNumber } = item.size.selecting;
        // エラーの場合　ゲージを空白にする。
        parts[partsNumber] = {
          ...parts[partsNumber],
          gauge: { major: '', minor: '' },
        };
        const data = getPartialOrder.fromPartsSize(parts);
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: 'マスターサイズに合致する標準サイズを取得できません.' },
          }),
          orderActions.updateCurrentOrder._action(data),
        ];
      }
      // MEMO: ここでは設定せず、done側で設定するstateを振り分ける（詳細にも使い回せる？？）
      const standardSizes = res as IOption[];
      return [actions.loadStandardSize.done({ params: payload, result: { standardSizes } })];
    }),
  );

const setStandardSize: Epic<
  AnyAction,
  Action<void> | Action<NestedPartial<IOrder>> | Action<{ orderNumber: string; data: NestedPartial<IOrder> }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadStandardSize.done),
    map(({ payload }) => {
      const { orderNumber } = payload.params;
      const appStateObj = appStateSelector(state.value);
      const orderDetail = appStateObj.orderDetail();
      const item = appStateObj.item(orderNumber);
      const isOrderDetail = isOrderDetailOrderNumber(orderNumber) && !appStateObj.isEditOrder();
      return { payload, isOrderDetail, orderDetail, item, orderNumber };
    }),
    filter(({ orderNumber, isOrderDetail, orderDetail, item }) => {
      if (isOrderDetail) {
        return orderDetail !== undefined && orderDetail.orders[orderNumber] !== undefined;
      }
      return item !== undefined;
    }),
    map(obj => {
      const { payload, isOrderDetail, orderNumber, orderDetail } = obj;
      const item = isOrderDetail ? (orderDetail as IOrderDetailState).orders[orderNumber].item : (obj.item as IItem);
      const { partsNumber } = payload.params;
      return { payload, isOrderDetail, item, partsNumber, orderNumber };
    }),
    filter(({ partsNumber, item }) => item.size.parts[partsNumber] !== undefined),
    map(({ payload, isOrderDetail, item, orderNumber, partsNumber }) => {
      const { parts } = item.size;
      const { isAutoUpdate, isTriggeredByCopy } = payload.params;
      const { standardSizes } = payload.result;
      const newParts = {
        ...parts,
        [partsNumber]: {
          ...parts[partsNumber],
          measurements: isAutoUpdate
            ? [...standardSizes]
            : !isTriggeredByCopy
            ? [...parts[partsNumber].measurements]
            : [...coverHistorySizeToStandardSize(parts[partsNumber].history.measurements, standardSizes)],
          standardSizes,
          hasChanged: false,
        },
      };

      const data = getPartialOrder.fromPartsSize(newParts);
      return isOrderDetail
        ? orderDetailActions.updatePartialOrder({ orderNumber, data })
        : orderActions.updateCurrentOrder._action(data);
    }),
  );

/** ヌード寸法変更時 */
const changeNudeSize: Epic<AnyAction, Action<void> | Action<NestedPartial<IOrder>>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.onChangeNudeSize),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      return { ...payload, item };
    }),
    filter(params => params.item !== undefined),
    mergeMap(obj => {
      const { optionNumber, value } = obj;
      const item = obj.item as IItem;
      const { nude } = item.size;
      const updated = updateMeasurements(nude.measurements, optionNumber, value);
      // ヌード寸法の順番を並び変えるため
      const nudeOrders = isShirtOrder(item.categoryCode) ? NUDE_SIZE_OPTION_NUMBERS_OF_SHIRT : NUDE_SIZE_OPTION_NUMBERS;
      const newNude = {
        date: '-',
        measurements: nudeOrders.map(
          opNo =>
            updated.find(vv => vv.optionNumber === opNo) || {
              optionNumber: opNo,
              optionClassName: '0',
              optionClassNumber: '',
            },
        ),
      };
      const data = getPartialOrder.fromSizeChangeNude(newNude);
      return [orderActions.updateCurrentOrder._action(data), actions.loadRecommandedGauge()];
    }),
  );

/** partsタブ変更時 */
const changeParts: Epic<AnyAction, Action<NestedPartial<IOrder>>, AppState> = action$ =>
  action$.pipe(
    ofAction(actions.onChangeParts),
    map(({ payload }) => {
      const { partsNumber } = payload;
      const data = getPartialOrder.fromSizeInitialize(partsNumber);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

/** マスターサイズ変更時 */
const changeGauge: Epic<
  AnyAction,
  Action<NestedPartial<IOrder>> | Action<Parameters<typeof actions.loadStandardSize.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.onChangeGauge),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const item = appStateObj.item();
      return { ...payload, item, orderNumber };
    }),
    filter(params => params.item !== undefined),
    mergeMap(obj => {
      const { gauge, orderNumber } = obj;
      const item = obj.item as IItem;
      const { parts } = item.size;
      const { partsNumber } = item.size.selecting;
      // 更新する
      parts[partsNumber] = {
        ...parts[partsNumber],
        gauge,
      };
      const data = getPartialOrder.fromPartsSize(parts);
      return [
        orderActions.updateCurrentOrder._action(data),
        actions.loadStandardSize.started({ orderNumber, partsNumber, isAutoUpdate: true }),
      ];
    }),
  );

/** 仕上がり寸法変更時 */
const changeSizeMeasurement: Epic<AnyAction, Action<NestedPartial<IOrder>>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.onChangeSizeMeasurement),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      return { ...payload, item };
    }),
    filter(params => params.item !== undefined),
    map(obj => {
      const { optionNumber, measurementValue } = obj;
      const item = obj.item as IItem;
      const { brandCode } = item.cloth;
      const { parts } = item.size;
      const { partsNumber } = item.size.selecting;
      const { measurements } = parts[partsNumber];
      // 更新する
      const matchedConfig = getLeftAndRightTogetherConfig(brandCode, partsNumber);
      const updated = updateMeasurements(measurements, optionNumber, measurementValue, matchedConfig);
      parts[partsNumber] = {
        ...parts[partsNumber],
        measurements: updated,
        hasChanged: true,
      };
      const data = getPartialOrder.fromPartsSize(parts);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

/** コピーボタン押下時 */
const copyHistorySize: Epic<
  AnyAction,
  Action<NestedPartial<IOrder>> | Action<Parameters<typeof actions.loadStandardSize.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.onCopyHistorySize),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const recommendGauges = appStateObj.recommendGauge();
      const item = appStateObj.item();
      return { item, orderNumber, recommendGauges };
    }),
    filter(
      ({ item, recommendGauges }) =>
        item !== undefined &&
        item.size.parts[item.size.selecting.partsNumber] !== undefined &&
        item.size.parts[item.size.selecting.partsNumber].history.date !== '' &&
        recommendGauges !== undefined,
    ),
    mergeMap(obj => {
      const item = obj.item as IItem;
      const recommendGauges = obj.recommendGauges as IRecommendPartsGauge[];
      const { orderNumber } = obj;
      const { parts } = item.size;
      const { partsNumber } = item.size.selecting;
      const { measurements, gauge: historyGauge } = parts[partsNumber].history;

      const { allGauges } = recommendGauges.find(by('partsNumber')(partsNumber)) || { allGauges: [] };
      const gauge = findGauge(historyGauge, allGauges) || { ...INITIAL_BASE_GAUGE };

      // 更新
      parts[partsNumber] = {
        ...parts[partsNumber],
        gauge,
        measurements,
        hasChanged: true,
      };
      const data = getPartialOrder.fromPartsSize(parts);
      return [
        orderActions.updateCurrentOrder._action(data),
        actions.loadStandardSize.started({
          orderNumber,
          partsNumber,
          isAutoUpdate: false,
          isTriggeredByCopy: true,
        }),
      ];
    }),
  );

/** 特殊オプション変更時 */
const changeAdjustOption: Epic<AnyAction, Action<NestedPartial<IOrder>>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.onChangeAdjustOption),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      return { ...payload, item };
    }),
    filter(
      ({ item, optionClassNumber, selectedOptionClass }) =>
        item !== undefined && optionClassNumber !== selectedOptionClass,
    ),
    map(obj => {
      const item = obj.item as IItem;
      const { selecting, parts } = item.size;
      return { obj, ...selecting, parts };
    }),
    filter(params => params.parts[params.partsNumber] !== undefined),
    map(({ partsNumber, parts, obj }) => {
      const { adjustOptions } = parts[partsNumber];
      const updated = editAdjustOptions(adjustOptions, obj.optionNumber, obj.optionClassNumber);
      parts[partsNumber] = {
        ...parts[partsNumber],
        adjustOptions: updated,
      };
      const data = getPartialOrder.fromPartsSize(parts);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

const setPartsSizeHistory: Epic<
  AnyAction,
  Action<NestedPartial<IOrder>> | Action<{ error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadPartsSizeHistory),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const size = appStateObj.size() as ISize; // MEMO: 表示できているのでundefindはありえない
      const { partsNumber } = size.selecting;
      return { payload, partsNumber };
    }),
    mergeMap(async ({ payload, partsNumber }) => {
      const res = await getPartsSizeHistory(payload, partsNumber).catch(err => err);
      return { res, partsNumber };
    }),
    map(({ res, partsNumber }) => {
      // error
      if (res instanceof ApiError) {
        return ErrorHandlerActions.apiError({
          error: res,
          options: { contents: '過去の仕上がりサイズの読み込みに失敗しました.' },
        });
      }

      const data = getPartialOrder.fromPartsHistory(res, partsNumber);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

export const SizeCorrectionEpics = combineEpics(
  loadInitialize,
  changeNudeSize,
  loadStandardSize,
  setStandardSize,
  changeGauge,
  changeParts,
  changeSizeMeasurement,
  changeAdjustOption,
  loadSizeMeasurement,
  loadedSizeMeasurement,
  loadAdjustOption,
  loadedAdjustOption,
  loadNudeSize,
  loadedNudeSize,
  copyHistorySize,
  loadRecommandedGauge,
  loadedRecommandedGauge,
  setPartsSizeHistory,
);
