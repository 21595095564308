/**
 * what is this：これが何かを解決する関数を置く
 */
import { SystemError } from '../../models/error/system-error';
import {
  EDesignOptionNumber,
  DOUBLE_DESIGN_OPTION_CLASS_NUMBERS,
  optionTypeConfig,
  FOLLOW_TO_JACKET_OPTION_CONFIG,
  INFLUENCED_BY_EMBROIDERY_SHIRT_OPTION_NUMBERS,
  INFLUENCED_BY_EMBROIDERY_JACKET_OPTION_NUMBERS,
  EMBROIDERY_POSITION_CONFIG,
  MESUREMENT_PANTS_LENGTH_CONFIG,
  SHIRT_ITEM_CODES,
  KR_BRANDS,
  SHORT_SLEEVE_SHIRT_ITEM_CODES,
} from '../../lookups/item-thisisforreplaceall/';
import {
  EParts,
  TPartsNumber,
  NUDE_SIZE_OPTIONS_FOR_SEND_SERVER,
  TNudeDementionCode,
  TCategory,
  TOrderItemCode,
  ECategory,
} from '../../lookups/master-thisisforreplaceall/';
import { EOptionType, EEmbroideryType } from '../../types/option';
import { IPiece, IOption } from '../../store/_type/order';
import { by } from '..';
import { getEmbroideryTypeOptionNumber, getEmbroideryType, getEmbroideryPositionOptionNumber } from '.';
import {
  IOptionPatternWithSelectedInfo,
  ISizeMeasurementView,
  IOptionClass,
  MODEL_SELECTOR_OPTION_NUMBER,
} from '../../store/_type/lookups';
import {
  MODEL_PATTERN_BE_MODEL_CODE_TO_GAUGE,
  MODEL_PATTERN_FOR_OPS_BRAND_GAUGE_STANDARD,
  OPS_BRAND_SPECIAL_TO_STANDARD_SIZE,
} from '../../lookups/item-thisisforreplaceall/size';
import {
  AFFECTED_OPTION_CLASS_IN_SAME_PARTS_CONFIG,
  OPTIONS_FILTER_CONFIG_IN_API_REQUEST_DATA,
  SHIRT_MODEL_CODE_OPTION_NUMBER,
  SPARE_OPTION_CONFIG,
  OPTION_GROUPS,
  womenBlouseGroups,
  INFLUENCED_BY_EMBROIDERY_COAT_OPTION_NUMBERS,
  isOptionNumberForJudgeIsDouble,
  OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
} from '../../lookups/item-thisisforreplaceall/option';
import pipe from 'ramda/es/pipe';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';
import path from 'ramda/es/path';
import nth from 'ramda/es/nth';
import includes from 'ramda/es/includes';
import prop from 'ramda/es/prop';
import partial from 'ramda/es/partial';
import equals from 'ramda/es/equals';
import where from 'ramda/es/where';
import omit from 'ramda/es/omit';
import ifElse from 'ramda/es/ifElse';
import identity from 'ramda/es/identity';

import { isSameDate, getDateHours } from '../common/date';
import { ORDER_SEND_TO_EVANS_HOUR } from '../../lookups/date';
import { match } from '../../lib/utils';

/**
 * デザインオプションかどうか
 * `デザイン` として扱われるOptionNumber (全カテゴリにて成り立つ前提（= 特定のカテゴリでは `デザイン` ではない。ということが無い）)
 */
export const isDesignOption = (optionNumber: string): boolean =>
  Object.keys(EDesignOptionNumber).includes(optionNumber);

/**
 * シャツの場合、推奨サイズAPI、標準サイズリスト取得APIに設定するmodel_codeのoptionNumberか？
 */
export const isShirtModelOption = (optionNumber: string): boolean => optionNumber === SHIRT_MODEL_CODE_OPTION_NUMBER;

/**
 * ダブル（デザイン）かどうか （シングル/ダブルが切り替わるとitemCodeが変わる）
 */
export const isDoubleDesignOptionClass = (optionNumber: string, optionClassNumber: string): boolean =>
  isOptionNumberForJudgeIsDouble(optionNumber) && DOUBLE_DESIGN_OPTION_CLASS_NUMBERS.includes(optionClassNumber);

/**
 * オプション選択画面のデザイン絵型下部の価格表記をフロント側で持つパターン。
 * この画面でモデルを選ぶ項目に関しては、モデルは「optionClassNumber」に入っている。
 */
const RETAIL_PRICE_TAXIN_CONFIG_FOR_OPTION_SELECT_PAGE: Array<{
  category: TCategory;
  brand: string;
  clothModelCode?: string;
  optionNumber: string;
  optionClassNumber: string;
  retailPriceTaxin: number; // 表示にのみ使用される。
}> = [
  {
    category: 'WM',
    brand: 'NX',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '131',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'WD',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '131',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'RX',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '131',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'RC',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '131',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'PA',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '131',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'NX',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '133',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'WD',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '133',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'RX',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '133',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'RC',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '133',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'PA',
    clothModelCode: 'NJ01',
    optionNumber: OPTION_NUMBER_FOR_JUDGE_IS_DOUBLE,
    optionClassNumber: '133',
    retailPriceTaxin: 3300,
  },
  {
    category: 'MT',
    brand: 'KA',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'EP03',
    retailPriceTaxin: 3300,
  },
  {
    category: 'MT',
    brand: 'KA',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'EP04',
    retailPriceTaxin: 3300,
  },
  {
    category: 'WM',
    brand: 'NX',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'DS04',
    retailPriceTaxin: 1100,
  },
  {
    category: 'WM',
    brand: 'WD',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'DS04',
    retailPriceTaxin: 1100,
  },
  {
    category: 'WM',
    brand: 'RX',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'DS04',
    retailPriceTaxin: 1100,
  },
  {
    category: 'WM',
    brand: 'RC',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'DS04',
    retailPriceTaxin: 1100,
  },{
    category: 'WM',
    brand: 'PA',
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionClassNumber: 'DS04',
    retailPriceTaxin: 1100,
  },
];

/**
 * オプション選択画面のデザイン絵型下部の価格表記をフロント側で持つパターンの場合は表示する値段を返す。
 * 該当するパターンがない場合は undifined を返す。
 */
export const getSpecifiedRetailPriceTaxinForOptionSelectPage = (
  category: TCategory,
  brand: string,
  clothModelCode: string,
  optionNumber: string,
  optionClassNumber: string,
): number | undefined => {
  const matchedConfig = RETAIL_PRICE_TAXIN_CONFIG_FOR_OPTION_SELECT_PAGE.find(
    conf =>
      conf.category === category &&
      conf.brand === brand &&
      conf.optionNumber === optionNumber &&
      conf.optionClassNumber === optionClassNumber &&
      (conf.clothModelCode === undefined ? true : conf.clothModelCode === clothModelCode),
  );

  return matchedConfig === undefined ? undefined : matchedConfig.retailPriceTaxin;
};

/**
 * オプションタイプの取得
 * optionTypeConfig配列の先頭から順に条件をチェックし、はじめにマッチしたoptionTypeを返却
 */
export const getOptionType = (optionNumber: string): EOptionType => {
  const matchedOptionType = optionTypeConfig.find(
    ([_, cond]) =>
      (typeof cond.min !== 'number' || Number(optionNumber) >= cond.min) &&
      (typeof cond.max !== 'number' || Number(optionNumber) <= cond.max) &&
      (!Array.isArray(cond.list) || cond.list.includes(optionNumber)),
  );
  if (!matchedOptionType) {
    throw new SystemError('0008', [String(optionNumber)]);
  }
  return matchedOptionType[0];
};

/**
 * piecesにジャケットがあるかつジャケットでないかつジャケットに準ずる場合か
 */
export const isFollowToJacketOptionNumber = (pieces: IPiece[], partsNumber: TPartsNumber, optionNumber: string) =>
  hasJacket(pieces) &&
  !isJacket(partsNumber) &&
  FOLLOW_TO_JACKET_OPTION_CONFIG.some(v => v.optionNumber === optionNumber);

/**
 * ジャケットか
 */
export const isJacket = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.jaket;

/** パンツか */
export const isPants = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.pants;

/** スカートか */
export const isSkirt = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.skirt;

/** シャツか */
export const isShirt = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.shirt;

/** コートか */
export const isCoat = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.coat;

/** ベストか */
export const isVest = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.vest;

/** メンズコートか */
export const isMensCoat = (partsNumber: TPartsNumber, category: TCategory) =>
  isCoat(partsNumber) && category === ECategory.mensCoat;

/** レディースコートか */
export const isWomensCoat = (partsNumber: TPartsNumber, category: TCategory) =>
  isCoat(partsNumber) && category === ECategory.womensCoat;

/** ワンピースか */
export const isOnepiece = (partsNumber: TPartsNumber): boolean => partsNumber === EParts.onepiece;

/**
 * piecesがジャケットを含んでいるかどうか
 */
export const hasJacket = (pieces: IPiece[]): boolean => pieces.some(v => isJacket(v.partsNumber));

/**
 * piecesがシャツを含んでいるかどうか
 */
export const hasShirt = (pieces: IPiece[]): boolean => pieces.some(v => isShirt(v.partsNumber));

/**
 * piecesがコートを含んでいるかどうか
 */
export const hasCoat = (pieces: IPiece[]): boolean => pieces.some(v => isCoat(v.partsNumber));

/**
 * piecesがベストを含んでいるかどうか
 */
 export const hasVest = (pieces: IPiece[]): boolean => pieces.some(v => isVest(v.partsNumber));

/**
 * 複数の同じパーツがあるか？
 */
export const hasSameParts = (pieces: IPiece[], partsNumber: TPartsNumber): boolean =>
  pieces.filter(by('partsNumber')(partsNumber)).length > 1;

/**
 * スペアパーツか?
 */
export const isSpareParts = (pieces: IPiece[], piece: IPiece, pieceIndex: number): boolean =>
  (isPants(piece.partsNumber) || isSkirt(piece.partsNumber)) &&
  hasSameParts(pieces, piece.partsNumber) &&
  pieces.map(v => v.partsNumber).lastIndexOf(piece.partsNumber) === pieceIndex;

/**
 * 刺繍ネームが必須入力か？
 */
export const isInputEmbroideryRequired = (options: IOption[], partsNumber: TPartsNumber): boolean => {
  // 刺繍字体
  const embroideryTypeOptionNumber = getEmbroideryTypeOptionNumber(partsNumber);
  const embroideryTypeOption = options.find(by('optionNumber')(embroideryTypeOptionNumber));
  const embroideryType = embroideryTypeOption ? getEmbroideryType(options, partsNumber) : EEmbroideryType.UNSELECTED;
  const isRequiredType = ![EEmbroideryType.NONE, EEmbroideryType.UNSELECTED].includes(embroideryType);
  if (!isShirt(partsNumber)) {
    return isRequiredType;
  }

  // シャツの場合には刺繍位置も確認する
  const embroideryPositionOptionNumber = getEmbroideryPositionOptionNumber(partsNumber);
  const embroideryPositionOption = options.find(by('optionNumber')(embroideryPositionOptionNumber));
  const isRequiredPosition = embroideryPositionOption
    ? !isEmbroideryPositionNone(partsNumber, embroideryPositionOptionNumber, embroideryPositionOption.optionClassNumber)
    : false;

  return isRequiredType && isRequiredPosition;
};

/** 刺繍ネームに影響を受けるオプションか？ */
export const isInfluencedByEmbroideryOptionNumber = (optionNumber: string, partsNumber: TPartsNumber): boolean => {
  if (!isJacket(partsNumber) && !isShirt(partsNumber) && !isCoat(partsNumber)) {
    return false;
  }
  const influencedOptionNumbers = match(
    [
      [isShirt, INFLUENCED_BY_EMBROIDERY_SHIRT_OPTION_NUMBERS],
      [isJacket, INFLUENCED_BY_EMBROIDERY_JACKET_OPTION_NUMBERS],
      [isCoat, INFLUENCED_BY_EMBROIDERY_COAT_OPTION_NUMBERS],
    ],
    partsNumber,
  ) as string[];
  return influencedOptionNumbers.includes(optionNumber);
};

/** 送信対象のヌード寸か？ */
export const shouldSendForServerNudeSize = (partsNumber: TPartsNumber, optionNumber: string): boolean => {
  const partsNudeSizes = NUDE_SIZE_OPTIONS_FOR_SEND_SERVER.find(v => v.code === partsNumber);
  return !partsNudeSizes ? false : partsNudeSizes.value.includes(optionNumber as TNudeDementionCode);
};

/** 注文履歴のorderNumberか */
export const isOrderDetailOrderNumber = (orderNumber: string) => /^###/.test(orderNumber);

/**
 * 自由入力項目に対するチェック。falseの場合は送信対象から外す。
 * @param optionNumber 対象のオプション
 * @param partsNumber 対象のパーツ
 * @param optionPatterns 選択可能なオプションの一覧
 */
export const shouldSendForServerFreeInput = (
  optionNumber: string,
  partsNumber: TPartsNumber,
  optionPatterns: IOptionPatternWithSelectedInfo[],
): boolean => {
  if (isInfluencedByEmbroideryOptionNumber(optionNumber, partsNumber)) {
    // Notice: INFLUENCED_BY_EMBROIDERY_OPTION_NUMBERSに9000番台が1つしかないので、
    // 上記のチェックで問題ないが、9000番台が増えた場合は要確認！
    return shouldSendForServerEmbroidery(partsNumber, optionPatterns);
  }
  return false;
};

/**
 * TODO: 移動する
 * IOptionPatternWithSelectedInfo[]からIOption[]に変換する
 */
export const toIOptionFromIOptionPatternWithSelectedInfo = (
  optionPatterns: IOptionPatternWithSelectedInfo[],
): IOption[] =>
  optionPatterns
    .filter(v => v.selectingClassName !== '' || v.selectingClassNumber !== '')
    .map(v => ({
      optionNumber: v.optionNumber,
      optionClassNumber: v.selectingClassNumber,
      optionClassName: v.selectingClassName,
    }));

/**
 * 刺繍ネームに影響を受けるオプションを送信するか？
 */
const shouldSendForServerEmbroidery = (partsNumber: TPartsNumber, optionPatterns: IOptionPatternWithSelectedInfo[]) => {
  const embroideryTypeOptionNumber = getEmbroideryTypeOptionNumber(partsNumber);
  const embroideryOption = optionPatterns
    .filter(v => !!v.selectingClassNumber) // 未選択のオプションを省く
    .find(by('optionNumber')(embroideryTypeOptionNumber)); // 刺繍ネームのオプションを取得する
  return embroideryOption
    ? isInputEmbroideryRequired(toIOptionFromIOptionPatternWithSelectedInfo(optionPatterns), partsNumber)
    : false;
};

/**
 * 推奨ゲージ取得APIでリクエストでmodelCodeに設定する値を取得
 * @see https://onward.backlog.jp/view/OPS_ORDERSYS-1109
 */
export const getModelCodeForRecommendGaugeApi = (
  partsNumber: TPartsNumber,
  category: TCategory,
  modelPattern: string,
  modelCode: string,
  brandCode: string,
): string => {
  // TODO OEM CHECK Later
  if (true) {
    return modelPattern;
  }
  // 国内投入用OPSブランド（01,03,04,08）のベスト・ジャケットの場合は「modelPattern」
  if (
    (isVest(partsNumber) || isJacket(partsNumber)) &&
    MODEL_PATTERN_FOR_OPS_BRAND_GAUGE_STANDARD.includes(brandCode)
  ) {
    return modelPattern;
  }
  // 国内投入用OPSブランド（01,03,04）のトラウザーズの場合は、「model_pattern + オプション番号 <設定例：Q001>」
  if (isPants(partsNumber) && OPS_BRAND_SPECIAL_TO_STANDARD_SIZE.includes(brandCode)) {
    return `${modelPattern}${modelCode}`;
  }
  // カテゴリが「MT」または「WM」の場合は「modelPattern」
  if (MODEL_PATTERN_BE_MODEL_CODE_TO_GAUGE.includes(category)) {
    return modelPattern;
  }
  // シャツの場合は、「型番パターン（HD、HD01など）＋シャツモデル（オプション番号：1089）の種別番号 　<設定例：HD01001>」
  if (isShirt(partsNumber)) {
    return `${modelPattern}${modelCode}`;
  }
  // 上記以外は、「modelCode」
  return modelCode;
};

/**
 * 標準サイズ取得APIリクエストでmodelCodeに設定する値を取得
 * @see https://onward.backlog.jp/view/OPS_ORDERSYS-1109
 */
export const getModelCodeForStandardSizeApi = (
  partsNumber: TPartsNumber,
  category: TCategory,
  brandCode: string,
  modelPattern: string,
  modelCode: string,
): string => {
  // 国内投入用OPSブランド（01,03,04）のトラウザーズの場合は、「model_pattern + オプション番号 <設定例：Q001>」
  // if (isPants(partsNumber) && OPS_BRAND_SPECIAL_TO_STANDARD_SIZE.includes(brandCode)) {
  //   return `${modelPattern}${modelCode}`;
  // }
  // // メンズのトラウザーズでブランドがKAでない場合は、「modelCode（ノータック：001など）」
  // if (isPants(partsNumber) && category !== 'WM' && brandCode !== 'KA') {
  //   return modelCode;
  // }
  // シャツの場合は、「型番パターン（HD、HD01など）＋シャツモデル（オプション番号：1089）の種別番号 　<設定例：HD01001>」
  if (isShirt(partsNumber)) {
    return `${modelPattern}${modelCode}`;
  }
  // 上記以外は、「modelPattern」
  return modelPattern;
};

/**
 * 刺繍位置がなしか？
 */
export const isEmbroideryPositionNone = (partsNumber: TPartsNumber, optionNumber: string, optionClassNumber: string) =>
  EMBROIDERY_POSITION_CONFIG.some(
    v =>
      v.partsNumber === partsNumber &&
      v.optionNumber === optionNumber &&
      v.optionClassNumber === optionClassNumber &&
      v.isNone === true,
  );

/**
 * オプションを変更したときに、同一パーツ内で自動変更されるオプションがあれば、その変更後のIOptionを返却
 * ※ 変更が連鎖するようねケースでは利用不可
 *
 * @param partsNumber 対象のパーツ
 * @param optionNumber 今回変更したオプションのoptionNumber
 * @param optionClassNumber  今回変更したオプションのoptionClassNumber
 */
export const getAffectedOptionsInSameParts = (
  partsNumber: TPartsNumber,
  optionNumber: string,
  optionClassNumber: string,
): IOption | IOption[] | undefined => {
  const f = pipe(
    find(propEq('partsNumber', partsNumber)),
    path(['getNewOptionFuncs', optionNumber]),
  )(AFFECTED_OPTION_CLASS_IN_SAME_PARTS_CONFIG);

  return typeof f === 'function' ? f(optionClassNumber) : undefined;
};

/**
 * 注文APIに送出して良いオプションかどうかをチェックする
 */
export const isSendOkOption = (option: IOption) =>
  !OPTIONS_FILTER_CONFIG_IN_API_REQUEST_DATA.some(
    v =>
      v.optionNumber === option.optionNumber &&
      (!v.optionClassNumber || v.optionClassNumber === option.optionClassNumber) &&
      v.shouldRemove(option),
  );

/** WEMENか */
export const isWemen = (category: TCategory): boolean => category === 'WM';

/**
 * ズボンの股下の左右で異なるか？
 * パーツがズボンでない場合はfalseを返却する
 */
export const isDifferentRightAndLeftInseams = (partsNumber: TPartsNumber, measurementView?: ISizeMeasurementView) => {
  if (!isPants(partsNumber) || !measurementView) {
    return false;
  }
  const getMeasumentValue = (measurementNumber: string) =>
    (measurementView.measurementItems.find(by('measurementNumber')(measurementNumber)) || { value: '0' }).value;

  const inseams = MESUREMENT_PANTS_LENGTH_CONFIG.inseams.map(getMeasumentValue);
  return inseams[0] !== inseams[1];
};

/** 自分の店舗のオーダーか？ */
export const isMyTempoOrder = (loggedTempoCode: string, orderedTempoCode?: string): boolean =>
  !!orderedTempoCode && orderedTempoCode === loggedTempoCode;

/**
 * Evans連携前か？
 * （将来的にはステータスコードのチェックになる）
 */
export const isBeforeEvans = (orderDate: string, date: Date = new Date()): boolean =>
  isSameDate(orderDate, date) && getDateHours(date) < ORDER_SEND_TO_EVANS_HOUR;

export const isValidMainOption = (optionNumber: string): boolean =>
  SPARE_OPTION_CONFIG.every(v => v.spareOption !== optionNumber);

export const isValidSpareOption = (optionNumber: string): boolean =>
  SPARE_OPTION_CONFIG.every(v => v.mainOption !== optionNumber);

/** 半袖シャツか？ */
export const isShortSleeveShirt = (itemCode: TOrderItemCode): boolean =>
  SHORT_SLEEVE_SHIRT_ITEM_CODES.includes(itemCode);

/** シャツのアイテムコードか？ */
export const isShirtItemCode = (itemCode: TOrderItemCode): boolean => SHIRT_ITEM_CODES.includes(itemCode);

/** ブランドKRか? */
export const isBrandKR = (brandCode: string): boolean => KR_BRANDS.includes(brandCode);

/**
 * グルーピング情報を取得
 */
export const getOptionGroups = (optionNumber: string) =>
  OPTION_GROUPS.find(v => v.optionNumber === optionNumber)?.optionGroups || [];

/**
 * グルーピングが必要なオプションかどうかを取得
 */
export const shouldGroupBy = (optionNumber: string): boolean => getOptionGroups(optionNumber).length > 0;

export const isSelectTBlouse = (selectingClassNumber: string, selectedOptionGroupId?: string): boolean =>
  selectedOptionGroupId
    ? selectedOptionGroupId === womenBlouseGroups[0].optionGroupId
    : womenBlouseGroups[0].optionClassNumbers.includes(selectingClassNumber);

export const isSelectBowBlouse = (selectingClassNumber: string, selectedOptionGroupId?: string): boolean =>
  selectedOptionGroupId
    ? selectedOptionGroupId === womenBlouseGroups[1].optionGroupId
    : womenBlouseGroups[1].optionClassNumbers.includes(selectingClassNumber);

// export const isCheckBlouseOptionClass = (classNumber: string, className: string, cls: IOptionClass): boolean => {
//   if (getBlouseOptionByName(className) === womenBlouseGroups[0].optionGroupName || isSelectTBlouse(classNumber)) {
//     return womenBlouseGroups[0].optionClassNumbers.includes(cls.optionClassNumber);
//   } else if (
//     getBlouseOptionByName(className) === womenBlouseGroups[1].optionGroupName ||
//     isSelectBowBlouse(classNumber)
//   ) {
//     return womenBlouseGroups[1].optionClassNumbers.includes(cls.optionClassNumber);
//   }
//   return false;
// };

// export const isSelectBlouseByName = (selectingClassName: string): boolean => {
//   const regExp = /ブラウス/;
//   return regExp.test(selectingClassName);
// };

export const getBlouseOptionByName = (selectingClassName: string): string => {
  const regExp = /半袖Tブラウス/;
  return regExp.test(selectingClassName) ? womenBlouseGroups[0].optionGroupName : womenBlouseGroups[1].optionGroupName;
};

/**
 * 選択したoptionClassNumberからoptionGroupIdを取得する
 */
export const getSelectedOptionGroupId = (selectingClassNumber: string): string =>
  isSelectTBlouse(selectingClassNumber)
    ? womenBlouseGroups[0].optionGroupId
    : isSelectBowBlouse(selectingClassNumber)
    ? womenBlouseGroups[1].optionGroupId
    : '';

const optionClass2WithColorSizeGroupId = (
  optionGroups: ReturnType<typeof getOptionGroups>,
  optionClass: IOptionClass,
): IOptionClass & { color: string; size: string; groupId: string } => {
  const classNameArr = (optionClass.optionClassName || '').split(' ');
  const group = optionGroups.find(pipe(prop('optionClassNumbers'), includes(optionClass.optionClassNumber)));
  return {
    ...optionClass,
    color: nth(1, classNameArr) || '',
    size: nth(2, classNameArr) || '',
    groupId: group?.optionGroupId || '',
  };
};

const isSameColorAndSizeAndGroup = (
  otherGroupId: string,
  currentOptionClass?: ReturnType<typeof optionClass2WithColorSizeGroupId>,
) =>
  where({
    color: equals(currentOptionClass?.color || ''),
    size: equals(currentOptionClass?.size || ''),
    groupId: equals(otherGroupId),
  });

/**
 * 別グループから同一サイズ、同一カラーのOptionClassを返却する
 */
export const getSameColorAndSizeOptionClassByOtherGroup = (optionNumber: string, optionClasses: IOptionClass[]) => (
  optionGroupId: string,
  currentOptionClassNumber: string,
): IOptionClass | undefined => {
  const addedColorAndSizes = optionClasses.map(
    partial(optionClass2WithColorSizeGroupId, [getOptionGroups(optionNumber)]),
  );
  const currentOptionClass = addedColorAndSizes.find(propEq('optionClassNumber', currentOptionClassNumber));
  return pipe(
    find(isSameColorAndSizeAndGroup(optionGroupId, currentOptionClass)),
    ifElse(v => typeof v === 'undefined', identity, omit(['color', 'size', 'groupId'])),
  )(addedColorAndSizes);
};

/**
 * グループに含まれるoptionClassか？
 * @param optionNumber
 * @param optionGroupId
 */
export const isOptionGroupOptionClass = (optionNumber: string, optionGroupId: string) => (
  optionClass: IOptionClass,
): boolean =>
  shouldGroupBy(optionNumber) &&
  getOptionGroups(optionNumber)
    .filter(propEq('optionGroupId', optionGroupId))
    .some(pipe(prop('optionClassNumbers'), includes(optionClass.optionClassNumber)));
