import { IError } from '../../../../types/error';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../../lib/action-creator-factory';

// action
const ac = actionObjectCreatorFactory();

export const actions = {
  showErrors: ac<IError[]>('[ERROR DIALOG] SHOW_ERRORS'),
  clearErrors: ac('[ERROR DIALOG] CLEAR_ERRORS'),
};

// reducer
const initialState: IError[] = [];

export const reducer = reducerWithInitialState(initialState)
  .case(actions.showErrors._action, (state, payload) => [...payload, ...state])
  .case(actions.clearErrors._action, state => []);
