import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { TCouponInfo } from '../../../../types/coupon';

const ac = actionCreatorFactory('[store/utils/dialog/coupon-info]');
const actions = {
  show: ac<void>('show'),
  close: ac<void>('close'),
  load: ac.async<{}, TCouponInfo, {}>('load'),
};
export const couponInfoDialogActions = actions;

// reducer
export type TCouponInfoDialogState = {
  isLoading: boolean;
  hasOpen: boolean;
  results: TCouponInfo;
  couponId: string;
};

const initialState: TCouponInfoDialogState = {
  isLoading: false,
  hasOpen: false,
  results: {
    id: '',
    couponName: '',
    discountAmount: '',
    publishDate: '',
    expiration: 0,
    userId: '',
    lastName: '',
    firstName: '',
    purpose: '',
    hasExpectedDate: 0,
    buyerType: '',
    memo: '',
    ecouponUsedFlg: false,
  } as TCouponInfo,
  couponId: '',
};

const reducer = reducerWithInitialState(initialState)
  .case(actions.show, (state, payload) => ({
    ...state,
    hasOpen: true,
  }))
  .case(actions.load.started, (state, payload) => ({
    ...state,
    isLoading: true,
  }))
  .case(actions.load.done, (state, payload) => ({
    ...state,
    isLoading: false,
    results: payload.result,
  }))
  .case(actions.load.failed, (state, payload) => ({
    ...state,
    isLoading: false,
    results: initialState.results,
  }))
  .case(actions.close, (state, payload) => ({
    ...state,
    hasOpen: false,
  }));
export const couponInfoDialogReducers = reducer;
