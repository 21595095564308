import { LoginRes } from '../../../api/operator/login';
import { ILoginReq } from '../../../types/login-api';
import { IStaff } from '../../../store/_type/staff';

const getValue = (v?: any) => (typeof v !== 'undefined' ? String(v) : '');

export function toStaff(req: ILoginReq, res: LoginRes): { staff: IStaff; token: string } {
  const { tempoCode, staffCode, couponId } = req;
  const staff: IStaff = {
    tempoCode,
    staffCode,
    managerFlag: res.query('managerFlag') === '1' ? true : false,
    staffType: getValue(res.query('staffType')),
    tempoName: getValue(res.query('tempoName')),
    staffName: getValue(res.query('staffName')),
    tempoId: getValue(res.query('tempoId')),
    couponId: couponId ? couponId : '',
    masterInfo: res.query('masterInfo'),
    categoryInfo: res.query('categoryInfo'),
  };

  // デフォルト住所をlocalStorageに保存
  localStorage.setItem('shippingPostalCode', res.query('zip') ?? '');
  localStorage.setItem('shippingState', res.query('prefecture') ?? '');
  localStorage.setItem('shippingCity', res.query('address1') ?? '');
  localStorage.setItem('shippingStreet', res.query('address2') ?? '');
  localStorage.setItem('shippingPhoneNumber', res.query('phone') ?? '');

  localStorage.setItem('brandCode', res.query('masterInfo')[0].brandList ?? 'HL');

  return {
    staff,
    token: res.query('certificationToken'),
  };
}
