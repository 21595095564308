import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';

import AddressPage from '../../../components/pages/AddressPage/AddressPage';
import { mapCommonPageDispatchToProps, mapCommonPageStateToProps } from '../CommonPage';
import { getAddress } from '../../../store/order/selector/address';
import { addressPageActions } from '../../../store/pages/address';
import { customKeyboardHoc } from '../../../hoc/CustomKeyboardHoc';
import { get as getCustomer } from '../../../store/customer';
import { getSelectedOrderDigest } from '../../../store/order-digest';
import { getStaff } from '../../../store/staff';
import { AddressPageEvents } from '../../../views/events/AddressPage';

function mapStateToProps(state: AppState) {
  return {
    addressItems: getAddress.addressItems(state),
    isLoadInitialize: getAddress.isLoadInitialize(state),
    orderNumberOrderOne: getAddress.orderNumberOrderOne(state),
    customer: getCustomer.customer(state),
    hasAddressInputCompleted: getAddress.hasCompleted(state),
    orderStatus: getSelectedOrderDigest.orderStatus(state),
    isManager: getStaff.isManager(state),
    ...mapCommonPageStateToProps(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(AddressPageEvents, dispatch),
    onLoadAddress: addressPageActions.loadAddress.dispatcher(dispatch),
    onChangeValue: addressPageActions.changeValue.dispatcher(dispatch),
    onChangeInputTwoValue: addressPageActions.changeInputTwoValue.dispatcher(dispatch),
    onTogleSameOrderOne: addressPageActions.togleSameOrderOne.dispatcher(dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(customKeyboardHoc()(AddressPage));
