export const sizePage = {
  /** 差分 */
  diff: { jp: '差分' },
  /** 購入履歴 */
  purchaseHistoryBtn: { jp: '購入履歴' },
  copyBtn: { jp: 'コピー' },
  changeBtn: { jp: '変更' },
  masterSize: { jp: 'マスターサイズ' },
  sizeMeasurement: { jp: '仕上がり寸法' },
  specialAdjustBtn: { jp: '特殊補正' },
  purchaseDate: { jp: '購入日' },
  gauge: { jp: 'ゲージ' },
  nudeMeasurement: { jp: 'ヌード寸法' },
  measurementDate: { jp: '採寸日' },
  recommenedSize: { jp: '推奨サイズ' },
};
