import { IItem } from '../../_type/order';

/**
 * IItems からデータを取得する関数郡
 */
export const itemSelector = (state: IItem) => new ItemSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class ItemSelector {
  constructor(private state: IItem) {}

  // これはさすがにいらないか、、、
  public pieces() {
    return this.state.pieces;
  }
  // これはさすがにいらないか、、、
  public design() {
    return this.state.design;
  }
}
