import { IOKCancelDialog, ISelectionDialog, ICustomerLoadDialog, IInformationDialog } from '../types/dialog';

export const INITIAL_OK_CANCEL_DIALOG_STATE: IOKCancelDialog = {
  hasOpen: false,
  title: null,
  contents: '',
};

export const INITIAL_SELECTION_DIALOG_STATE: ISelectionDialog = {
  hasOpen: false,
  title: null,
  contents: '',
};

export const INITIAL_CUSTOMER_DIALOG_STATE: ICustomerLoadDialog = {
  hasOpen: false,
  hasError: false,
  isLoading: false,
  errorMessage: '',
};

export const INITIAL_INFORMATION_DIALOG_STATE: IInformationDialog = {
  hasOpen: false,
  title: null,
  contents: '',
};

export const MANAGER_ONLY_ORDERABLE_TERM_DIALOG: IInformationDialog = {
  hasOpen: true,
  title: '確認',
  contents: '現在、管理者のみ注文可能です。',
};
