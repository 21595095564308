import React, { Component } from 'react';

import styles from './LoginPage.module.scss';

import IFrame from '../../atoms/IFrame/IFrame';
import InputText from '../../../containers/components/InputText';
import InputPassword from '../../atoms/InputPassword/InputPassword';
import Title from '../../atoms/Title/Title';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import Button from '../../atoms/Button/Button';
import FlagSelector from '../../atoms/FlagSelector/FlagSelector';
import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';

import ErrorBoundary from '../../../components/organisms/ErrorBoundary/ErrorBoundary';

import { ILoginReq } from '../../../types/login-api';
import { LANGUAGE_LOOKUPS } from '../../../lookups/language';
import { TPageContainerProps } from '../../../containers/pages/LoginPage';
import config from '../../../configuration/config';
import { langResource } from '../../../i18n/_new/resources/index';
import InformationDialog from '../../../containers/components/dialogs/InformationDialog';
import history from '../../../helpers/common/history';

type TState = ILoginReq;

const IFRAME_URL = `${config.baseUrl}/information/index.html`;

class LoginPage extends Component<TPageContainerProps, TState> {
  constructor(props: TPageContainerProps) {
    super(props);
    this.state = {
      tempoCode: '',
      staffCode: '',
      staffPassword: '',
      couponId: new URLSearchParams(history.location.search).get('couponId') || '',
    };
  }

  render() {
    return (
      <React.Fragment>
        <ErrorBoundary>
          {/* Flag */}
          <div className={styles.flag}>
            <FlagSelector
              name="radio buttons"
              data={LANGUAGE_LOOKUPS}
              checkedValue={String(this.props.selectedLanguage)}
              onValueChanged={this.props.onChangeCurrentLanguage}
            />
          </div>

          {/* Page */}
          <div className={styles.page}>
            <div className={styles.informationblock}>
              <div className={styles.logoblock}>
                <p className={styles.message}>WELCOME</p>
              </div>
              <div className={styles.information}>
                <p className={styles.title}>INFORMATION</p>
                <div className={styles.iFrame}>
                  <IFrame title="loginInfo" src={IFRAME_URL} />
                </div>
              </div>
            </div>
            <div className={styles.formblock}>
              <div className={styles.title}>
                <Title styles={['login']} textObj={langResource.header.login} />
              </div>
              <div className={styles.message}>
                <p>
                  <I18TextContainer textObj={langResource.loginPage.description} />
                </p>
              </div>
              <div className={styles.form}>
                <div className={styles.inputGroup}>
                  <InputText
                    label={langResource.loginPage.tempoCodeLabel}
                    value={this.state.tempoCode}
                    onValueChanged={this.onChangeShopCode}
                    labelPosition={'TOP'}
                  />
                  <InputText
                    label={langResource.loginPage.staffCodeLabel}
                    value={this.state.staffCode}
                    onValueChanged={this.onChangeStaffCode}
                    labelPosition={'TOP'}
                  />
                  <InputPassword
                    label={langResource.loginPage.staffPasswordLabel}
                    onValueChanged={this.onChangePassword}
                  />
                </div>
                <Button
                  styles={['black', 'square', 'size-xl', 'center']}
                  onClick={this.onLogin}
                  textObj={langResource.loginPage.loginButtonLabel}
                />
              </div>
            </div>
          </div>
          {this.renderUtility()}
        </ErrorBoundary>
      </React.Fragment>
    );
  }

  renderUtility() {
    return (
      <React.Fragment>
        <LoadingInfo isLoading={this.props.loggingIn} messageKey={langResource.loginPage.loggingInMessage} />
        <InformationDialog />
      </React.Fragment>
    );
  }

  onChangeShopCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ tempoCode: value });
  };

  onChangeStaffCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ staffCode: value });
  };

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ staffPassword: value });
  };

  onLogin = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const params = { ...this.state };
    this.props.onLogin(params);
  };

  // onApplyLatestSource = (event: React.MouseEvent<HTMLButtonElement>): void => {
  //   alert('If you have launched multiple tabs, other tabs will not reflect the update.');
  //   serviceWorker.unregister();
  // };
}

export default LoginPage;
