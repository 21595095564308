import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { InventorySearchPageEvents } from '../views/events/InventorySearchPage';
import { map } from 'rxjs/operators';
import { actions as inventoryActions } from '../store/inventory/action-reducer';

/**
 * 在庫検索画面遷移時のロード処理
 * @see InventorySearchPageEvents.load
 */

const load: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(InventorySearchPageEvents.load),
    map(action => inventoryActions.load.started()),
  );

const clear: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(InventorySearchPageEvents.clear),
    map(() => inventoryActions.clear()),
  );

export const InventoryPageListeners = combineEpics(load, clear);
