import React from 'react';

import styles from './DesignConfirmation.module.scss';

import Title from '../../../atoms/Title/Title';
import ConfirmationSubHeader from '../../../molecules/ConfirmationSubHeader/ConfirmationSubHeader';
import PartsConfirmationList from '../../../molecules/PartsDesignConfirmationList/PartsDesignConfirmationList';

import { IPartsConfirmation, IDesignConfirmation } from '../../../../types/new-store/components';
import { ERouterPath } from '../../../../types';
import { langResource } from '../../../../i18n/_new/resources';
import { TPricePrefix } from '../../../../types/orders';

interface IProps {
  pricePrefix: TPricePrefix;
  orderNumber: string;
  partsDesign: IPartsConfirmation<IDesignConfirmation>;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
}

type TProps = IProps;

const DesignConfirmation: React.FC<TProps> = (props: TProps) => {
  const { orderNumber, pricePrefix, onEditOrder } = props;
  const { partsName } = props.partsDesign;
  const { designs, specials } = props.partsDesign.data;

  return (
    <div id={styles.container}>
      <div>
        <Title styles={['bgLightGray', 'medium']} title={partsName} />
      </div>
      <div className={styles.contentNotFlex}>
        <div className={styles.left}>
          <ConfirmationSubHeader
            headerObj={langResource.confirmationPage.designTitle}
            hasButton={true}
            buttonAction={moveToDesignPage}
          />
          <PartsConfirmationList pricePrefix={pricePrefix} options={designs} />
          <ConfirmationSubHeader headerObj={langResource.confirmationPage.specialOptionTitle} />
          <PartsConfirmationList pricePrefix={pricePrefix} options={specials} />
        </div>
      </div>
    </div>
  );

  function moveToDesignPage() {
    onEditOrder(ERouterPath.designSelection, orderNumber);
  }
};

export default DesignConfirmation;
