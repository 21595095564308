import actionCreatorFactory from 'typescript-fsa';
import { AppState } from './index';

const actionCreator = actionCreatorFactory('#Global');

export const actions = {
  /**
   * 途中保存APIから取得したデータでAppStateを置き換える
   */
  updateAll: actionCreator<AppState>('update all state'),
  /**
   * ログイン画面ロード時にstateの情報をクリアする（スタッフログアウトしてもstateが残っていたため）
   */
  clearState: actionCreator<void>('clear all state'),
};
