import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('- InventorySearchPage ');

export const InventorySearchPageEvents = {
  /**
   * 画面遷移時の在庫一覧ロード処理
   */
  load: actionCreator<void>('loadInventory'),

  /**
   * ページから離れる時、在庫情報をクリア
   */
  clear: actionCreator<void>('clearInventory'),
};
