import { TProductKind } from '../../store/_type/order';
import { TMaster } from '../../types';

export const EProductKind = {
  product: 'E' as TProductKind,
  remake: 'M' as TProductKind,
};

export const MASTER_PRODUCT_KIND: Array<TMaster<TProductKind>> = [
  { code: EProductKind.product, value: '製品' },
  { code: EProductKind.remake, value: '作り直し' },
];
