import { AppState } from '../../../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMemoDialog, memoDialogEvents } from '../../../../store/utils/dialog/memo';

import MemoDialog from '../../../../components/organisms/MemoDialog/MemoDialog';

function mapStateToProps(state: AppState) {
  return {
    ...getMemoDialog.state(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(memoDialogEvents, dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TMemoDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(MemoDialog);
