import { combineEpics, Epic } from 'redux-observable';
import { projectSelectorDialogActions } from './action-reducers';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { actions as ErrorHandlerActions } from '../../../../store/errorHandling/action';
import { Action, AnyAction } from 'typescript-fsa';
import { appStateSelector } from '../../../../helpers/object-selector/app-state';
import { map, mergeMap } from 'rxjs/operators';
import { ApiError } from '../../../../models/error/api-error';
import { TProjectSearchCondition, TScProject } from '../../../../types/project';
import { AppState } from '../../..';
import { toQuery, toProjects } from '../../../../helpers/api/converter/sc-project';
import { getProjects } from '../../../../services/customer/project';
import { WrapAction } from '../../../_type';

const load: Epic<
  AnyAction,
  | Action<{ error: ApiError; options: any }>
  | WrapAction<typeof projectSelectorDialogActions.load.done>
  | WrapAction<typeof projectSelectorDialogActions.load.failed>
  | Action<TScProject>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(projectSelectorDialogActions.load.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const filters = appStateObj.projectSearchCondition() as TProjectSearchCondition;
      return { payload, filters };
    }),
    mergeMap(async ({ payload, filters }) => {
      const param = toQuery(filters);
      const res = await getProjects(param)
        .then(toProjects)
        .catch(err => err);
      return { payload, res };
    }),
    mergeMap(({ payload, res }) => {
      if (res instanceof ApiError) {
        if (res.statusCode === 404) {
          return [
            ErrorHandlerActions.apiError({ error: res, options: { contents: '条件に一致するデータがありません.' } }),
            projectSelectorDialogActions.load.failed({ params: payload, error: {} }),
          ];
        }
        return [
          ErrorHandlerActions.apiError({ error: res, options: { contents: 'データの取得に失敗しました。' } }),
          projectSelectorDialogActions.load.failed({ params: payload, error: {} }),
        ];
      }
      return [projectSelectorDialogActions.load.done({ params: payload, result: res })];
    }),
  );

export const ProjectEpics = combineEpics(load);
