import React, { Component } from 'react';

import styles from './InventorySearchFilter.module.scss';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import InputText from '../../../containers/components/InputText';
import { ILookupItem, TLookup } from '../../../types/lookup';
import CheckBox from '../../atoms/CheckBox/CheckBox';
import Label from '../../atoms/Label/Label';
import { IInventorySearch, getInventorySearch } from '../../../types/inventory-search';
import Button from '../../atoms/Button/Button';
import { langResource } from '../../../i18n/_new/resources/';

interface IProps {
  data: IInventorySearch;
  brandLookup: TLookup;
  periodLookup: TLookup;
  onSearch: (state: IInventorySearch) => void;
}

interface IState {
  brand: ILookupItem;
  season: ILookupItem;
  productNumber: string;
  enoughStock: boolean;
  littleStock: boolean;
  noStock: boolean;
}

class InventorySearchFilter extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = props.data;
  }

  render() {
    return (
      <div className={styles.inventorySearchFilter}>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.inventoryPage.brand} />
            <div className={styles.inputWrap}>
              <SelectBox
                data={this.props.brandLookup}
                selectedOption={this.state.brand.id}
                onValueChanged={this.onBrandChangeHandler}
              />
            </div>
          </div>
          <div className={styles.columnSize2}>
            <Label textObj={langResource.inventoryPage.productNumber} />
            <div className={styles.inputWrap}>
              <InputText onValueChanged={this.onItemNumberChangeHandler} value={this.state.productNumber} />
            </div>
          </div>
        </div>
        <div className={styles.rowBtn}>
          <div className={styles.columnSize3}>
            <Label textObj={langResource.inventoryPage.stock} />
            <div className={styles.inputWrap}>
              <CheckBox onChange={this.onEnoughStockChangeHandler} textObj={langResource.inventoryPage.enoughStock} />
              <CheckBox onChange={this.onLittleStockChangeHandler} textObj={langResource.inventoryPage.littleStock} />
              <CheckBox onChange={this.onNoStockChangeHandler} textObj={langResource.inventoryPage.noStock} />
            </div>
          </div>
          <div className={styles.columnSize4}>
            <Button
              onClick={this.onSearchClickHandler}
              textObj={langResource.inventoryPage.searchBtn}
              styles={['black', 'size-xl', 'block', 'search']}
            />
          </div>
        </div>
      </div>
    );
  }

  //  <Button onClick={this.onResetClickHandler}>{this.translate + 'reset'}</Button>

  onBrandChangeHandler = (value: ILookupItem) => {
    this.setState({ brand: value });
  };

  onModelChangeHandler = (value: ILookupItem) => {
    this.setState({ season: value });
  };

  onEnoughStockChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    this.setState({
      enoughStock: value,
    });
  };

  onLittleStockChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    this.setState({
      littleStock: value,
    });
  };

  onNoStockChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    this.setState({
      noStock: value,
    });
  };

  onItemNumberChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      productNumber: value,
    });
  };

  onSearchClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.onSearch(this.state);
  };

  onResetClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState(getInventorySearch());
  };
}

export default InventorySearchFilter;
