import React from 'react';

import styles from './DesignSelectionSidebar.module.scss';

import Button from '../../atoms/Button/Button';
import DesignOptionPartsPanel from '../../molecules/DesignOptionPartsPanel/DesignOptionPartsPanel';

import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall/';
import { IItem } from '../../../store/_type/order';
import { IAvailableOption, IClothProduct } from '../../../store/_type/lookups';
import { IOptionSidebarItem } from '../../../types/new-store/components';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  sidebarListItem: IOptionSidebarItem;
  isEdit: boolean;

  // どこで使ってる？？？
  availableOptions: IAvailableOption[];

  onAddPiece: typeof DesignSelectionPageEvents.onAddPiece;
  onDeletePiece: typeof DesignSelectionPageEvents.onDeletePiece;
  onSetDefault: typeof DesignSelectionPageEvents.onSetDefault;
  onSelectOption: typeof DesignSelectionPageEvents.onSelectOption;
}

const DesignSelectionSidebar: React.FC<IProps> = (props: IProps) => {
  const { products, isEdit } = props;
  const { partsItems, addablePartsList } = props.sidebarListItem;
  if (partsItems.length < 1) {
    return null;
  }

  const renderDeleteButton = (canDelete: boolean, partsIndex: string, partsName: string) => {
    if (!canDelete) {
      return null;
    }

    const buttonName = `${partsName}を削除`;
    return (
      <div className={styles.button}>
        <Button onClick={onHandleDelete(partsIndex)} styles={['black', 'block', 'size-l']} disabled={isEdit}>
          {buttonName}
        </Button>
      </div>
    );
  };

  const onHandleDelete = (partsIndex: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { orderNumber, item } = props;
    props.onDeletePiece({ partsIndex, item, orderNumber, products });
  };

  const renderAddButtons = () => {
    // サブカテゴリー "ジャケット"においてダブル型選択時はベスト追加できない
    const removeJacketVestDouble = props.item.subCategoryCode === '02' && props.item.design.isDouble === true;

    const buttonName = (name: string) => `${name}を追加`;
    return (
      <div className={styles.button}>
        {addablePartsList.map((addableParts, index) => (
          <Button
            key={index}
            onClick={onHandleAdd(addableParts.partsNumber)}
            styles={['black', 'block', 'size-l']}
            disabled={isEdit || removeJacketVestDouble}
          >
            {buttonName(addableParts.partsName)}
          </Button>
        ))}
      </div>
    );
  };

  const onHandleAdd = (partsNumber: TPartsNumber) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // イオン　（brand: IO）では、ベストを追加できない
    if (props.item.cloth.brandCode === 'IO' && partsNumber === '04') {
      return;
    }
    // OEM: モデル HL02を選択した場合、ベストを追加できない
    if (props.item.cloth.clothModelCode === 'HL02' && partsNumber === '04') {
      return;
    }
    const { orderNumber, item } = props;
    props.onAddPiece({ partsNumber, item, orderNumber, products });
  };

  if (props.item.pieces.find(i => i.partsNumber === '04' && props.item.cloth.clothModelCode === 'HL02')) {
    const { orderNumber, item } = props;
    const partsIndex = partsItems.findIndex(i => i.partsNumber === '04').toString();
    if (partsIndex !== '-1') {
      console.log('DELETE PARTS function started - ', 'deleted index: ', partsIndex, 'target array', partsItems);
      props.onDeletePiece({ partsIndex, item, orderNumber, products });
    }
  }

  return (
    <React.Fragment>
      <div className={styles.sidebar}>
        {partsItems.map((v, key) => {
          const { partsName, partsIndex, canDelete } = v;
          return (
            <React.Fragment key={key}>
              <DesignOptionPartsPanel {...props} {...v} />
              {renderDeleteButton(canDelete, partsIndex, partsName)}
            </React.Fragment>
          );
        })}
        {renderAddButtons()}
      </div>
    </React.Fragment>
  );
};

export default DesignSelectionSidebar;
