import { TRequestConfig, TRequestParams } from '../..';
export { OrderRegistRes } from './OrderRegistRes.class';

export const orderRegistRequestConfig: TRequestConfig = {
  endpoint: '/orders',
  method: 'POST',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      body: params ? params.body : {},
    };
  },
};
