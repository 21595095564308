import { II18nItem } from './types';

/**
 * I18Textで実行するやつ
 * つまり<I18TextConteiner textKey={langResource.home.classification} />となる
 * @param code reduxのutils StoreからCodeを取得する（今まで通り）
 * @param langItem 言語変換を行いたい各コンポーネントのI18Textのpropsに渡す
 */
export const getLabel = (code: keyof II18nItem, langItem: II18nItem) =>
  langItem[code] ? String(langItem[code]) : langItem.jp;
// const classificationLabelJp = getLabel('jp', langResource.home.classification);
// const classificationLabelEn = getLabel('en', langResource.home.classification);
