import React from 'react';

import styles from './SizeConfirmation.module.scss';

import Title from '../../../atoms/Title/Title';
import I18TextContainer from '../../../../containers/I18Text/I18Text';
import PartsSizeConfirmationTable from '../../../molecules/PartsSizeConfirmationTable/PartsSizeConfirmationTable';

import { IPartsConfirmation, ISizeConfirmation } from '../../../../types/new-store/components';
import { ERouterPath } from '../../../../types';
import { langResource } from '../../../../i18n/_new/resources';
import ConfirmationSubHeader from '../../../molecules/ConfirmationSubHeader/ConfirmationSubHeader';

interface IProps {
  orderNumber: string;
  partsSize: IPartsConfirmation<ISizeConfirmation>;
  brandCode: string;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
}

type TProps = IProps;

const SizeConfirmation: React.FC<TProps> = (props: TProps) => {
  const { orderNumber, onEditOrder } = props;
  const { partsName, gauge, partsNumber } = props.partsSize;
  const { sizes, adjusts } = props.partsSize.data;
  const brandCode = props.brandCode;

  return (
    <div id={styles.container}>
      <div>
        <Title styles={['bgLightGray', 'medium']} title={partsName} />
      </div>
      <div className={styles.contentNotFlex}>
        <div className={styles.right}>
          <ConfirmationSubHeader
            headerObj={langResource.confirmationPage.sizeMeasurementTitle}
            hasButton={true}
            buttonAction={moveToSizePage}
          />
          <div className={styles.sizeWrap}>
            <p className={styles.baseGauge}>
              <I18TextContainer textObj={langResource.confirmationPage.masterSize} />
              <span>{gauge}</span>
            </p>
            <PartsSizeConfirmationTable items={sizes} brandCode={brandCode} partsNumber={partsNumber}/>
          </div>
          <ConfirmationSubHeader headerObj={langResource.confirmationPage.specialAdjustTitle} />
          <PartsSizeConfirmationTable items={adjusts} />
        </div>
      </div>
    </div>
  );

  function moveToSizePage() {
    onEditOrder(ERouterPath.sizeCorrection, orderNumber);
  }
};

export default SizeConfirmation;
