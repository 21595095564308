import actionCreatorFactory from 'typescript-fsa';
import { IOnInputFreeParam, IItem, IPiece } from '../../_type/order';
import { IAvailableOption, IClothProduct } from '../../_type/lookups';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall/parts';
import { TOrderItemCode } from '../../../lookups/master-thisisforreplaceall';

export interface IAvailableOptionReq {
  orderNumber: string;
  brandCode: string;
  itemCode: string;
  seasonCode: string;
  optionPatterns: string[];
}

export interface IDefaultOption {
  partsIndex: string;
  item: IItem;
  availableOptions: IAvailableOption[];
  orderNumber: string;
}

export interface IOptionClassSelection {
  orderNumber: string;
  optionClassNumber: string;
  item: IItem;
  products: IClothProduct[];
  hasOpen?: boolean;
  // 自由入力？
  optionClassName?: string;
}

export interface IUpdateOptionClasses {
  partsIndex: string;
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  optionClasses: Array<{
    optionNumber: string;
    optionClassNumber: string;
  }>;
}

export interface ILoadInitializeParam {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
}

export interface IAddPieceParam {
  partsNumber: TPartsNumber;
  item: IItem;
  orderNumber: string;
  products: IClothProduct[];
}

export interface IDeletePieceParam {
  partsIndex: string;
  item: IItem;
  orderNumber: string;
  products: IClothProduct[];
}

export interface IChangeItemCodeParam {
  products: IClothProduct[];
  orderNumber: string;
  item: IItem;
  pieces: IPiece[];
  isDouble: boolean;
  itemCode: TOrderItemCode;
}

const actionCreator = actionCreatorFactory('[Order/item/design]');

export const actions = {
  showNotFoundItemCode: actionCreator<void>('showNotFoundItemCode'),
  loadInitialize: actionCreator<{ params: ILoadInitializeParam; availableOptions: IAvailableOption[] }>(
    'loadInitialize',
  ),
  loadAvailableOptionsForInitialize: actionCreator<ILoadInitializeParam>('loadAvailableOptionsForInitialize'),
  loadAvailableOptionsForChangeItemCode: actionCreator<ILoadInitializeParam>('loadAvailableOptionForChangeItemCode'),
  addPiece: actionCreator<IAddPieceParam>('addPiece'),
  deletePiece: actionCreator<IDeletePieceParam>('deletePiece'),
  setDefault: actionCreator<{
    partsIndex: string;
    item: IItem;
    availableOptions: IAvailableOption[];
    products: IClothProduct[];
    orderNumber: string;
  }>('setDefault'),
  selectOption: actionCreator<{ partsIndex: string; optionNumber: string; item: IItem; hasOpen?: boolean }>(
    'selectOption',
  ),
  updateOptionClasses: actionCreator<IUpdateOptionClasses>('updateOptionClasses'),
  selectOptionClass: actionCreator<IOptionClassSelection>('selectOptionClass'),
  changeItemCode: actionCreator<{ params: IChangeItemCodeParam; availableOptions: IAvailableOption[] }>(
    'changeItemCode',
  ),
  updateFreeInput: actionCreator.async<
    IOnInputFreeParam,
    { previous: IOnInputFreeParam; current: IOnInputFreeParam },
    {}
  >('updateFreeInput'),
};
