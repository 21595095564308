import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { errorRetryActionDialogActions, getErrorRetryActionDialog } from '../../../store/utils/dialog/errorRetryAction';
import errorRetryActionDialog from '../../../components/organisms/errorRetryActionDialog/erroRetryActionDialog';
import { IErrorRetryActionDialog } from '../../../types/dialog';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: getErrorRetryActionDialog.hasOpen(state),
    data: getErrorRetryActionDialog.data(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    // actionを再実行
    onRetryAction: (data: IErrorRetryActionDialog ) => data.action === null ? undefined : dispatch(data.action),
    // stateを初期化
    onClose: errorRetryActionDialogActions.close.dispatcher(dispatch),

  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type TErrorRetryActionDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(errorRetryActionDialog);
