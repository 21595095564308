import { createSelector } from 'reselect';

import { orderState } from '../../order/selector/order';
import { AppState } from '../..';
import { ILookupFeature } from '../../lookups';

export { adjustOptionActions } from './action-reducer';

const featureSelector = (state: AppState): ILookupFeature => state.lookups;

const adjustOptionState = createSelector(featureSelector, state => state.adjustOption);

export const getAdjustOption = {
  all: createSelector(adjustOptionState, state => state.adjustOptions),
  current: createSelector(
    adjustOptionState,
    orderState,
    (state, order) => state.adjustOptions[order.currentOrderNumber],
  ),
};
