import actionCreatorFactory from 'typescript-fsa';
import { IRequiredCheckDialog } from '../../types/dialog';

const actionCreator = actionCreatorFactory('- ItemContentPage');

export const ItemContentPageEvents = {
  /**
   * 必須チェックダイアログ表示処理
   */
  onShowRequiredCheckDialog: actionCreator<IRequiredCheckDialog>('onShowRequiredCheckDialog'),
};
