import React from 'react';

import styles from './ItemConfirmationDetail.module.scss';

import { confirmationPageActions } from '../../../store/pages/confirmation';

import Button from '../../atoms/Button/Button';
import Title from '../../atoms/Title/Title';

import ItemConfirmation from '../confirmations/ItemConfirmation/ItemConfirmation';
import ClothConfirmation from '../confirmations/ClothConfirmation/ClothConfirmation';
import DesignConfirmation from '../confirmations/DesignConfirmation/DesignConfirmation';
import SizeConfirmation from '../confirmations/SizeConfirmation/SizeConfirmation';

import { IOrderConfirmation } from '../../../types/new-store/components';
import { langResource } from '../../../i18n/_new/resources';
import { TPricePrefix } from '../../../types/orders';
import { generateDeleteOrderBtnFunc } from '../../../helpers/components/dialog';
import { selectionDialogActions } from '../../../store/utils/dialog/selection';
import { ERouterPath } from '../../../types';

interface IProps {
  pricePrefix: TPricePrefix;
  order: IOrderConfirmation;
  newOrderNumber: string;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
  onCopyOrder: typeof confirmationPageActions.copyOrder.tp;
  onDeleteOrder: typeof confirmationPageActions.deleteOrder.tp;
  onShowSelectionDialog: typeof selectionDialogActions.show.type;
}

const ItemConfirmationDetail: React.FC<IProps> = (props: IProps) => {
  const { newOrderNumber } = props;
  const { title, orderNumber, cloth, partsDesigns, partsSizes, isInvalid } = props.order;

  const onCopyOrder = () => {
    props.onCopyOrder({ newOrderNumber, orderNumber });
  };

  const onDeleteOrder = generateDeleteOrderBtnFunc(orderNumber, props.onDeleteOrder, props.onShowSelectionDialog);

  return (
    <React.Fragment>
      <div id={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <Title styles={['bgBrightBrown']} title={title} />
          </div>
          <div className={styles.right}>
            <div className={styles.button}>
              <Button
                styles={['black', 'size-s']}
                onClick={onCopyOrder}
                textObj={langResource.confirmationPage.copyBtn}
              />
            </div>
            <div className={styles.button}>
              <Button
                styles={['red', 'size-s']}
                onClick={onDeleteOrder}
                textObj={langResource.confirmationPage.deleteBtn}
              />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {isInvalid ? (
            <div className={styles.invalidMessage}>
              <div className="error-tooltip">不完全なオーダーです.修正するか削除してください.</div>
            </div>
          ) : null}
          <div className={styles.item}>
            <div className={styles.confirmation}>
              <ItemConfirmation {...props} />
            </div>
          </div>
          <div className={styles.cloth}>
            <div className={styles.title}>
              <Title styles={['lineLightBrown']} textObj={langResource.confirmationPage.clothTitle} />
            </div>
            <div className={styles.confirmation}>
              <ClothConfirmation {...props} orderNumber={orderNumber} cloth={cloth} />
            </div>
          </div>
          <div className={styles.parts}>
            <div className={styles.title}>
              <Title styles={['lineLightBrown']} textObj={langResource.confirmationPage.designOptionTitle} />
            </div>
            <div className={styles.confirmation}>
              {partsDesigns.map((design, i) => (
                <DesignConfirmation key={i} {...props} orderNumber={orderNumber} partsDesign={design} />
              ))}
            </div>
          </div>
          <div className={styles.parts}>
            <div className={styles.title}>
              <Title styles={['lineLightBrown']} textObj={langResource.confirmationPage.sizeTitle} />
            </div>
            <div className={styles.confirmation}>
              {partsSizes.map((size, i) => (
                <SizeConfirmation
                  key={i} {...props} orderNumber={orderNumber} partsSize={size} brandCode={cloth.brandCode}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ItemConfirmationDetail;
