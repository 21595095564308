import React from 'react';

import styles from './MeasurementList.module.scss';

import MeasurementListItem from '../MeasurementListItem/MeasurementListItem';
import { IMeasurementItem } from '../../../types/new-store/components';
import { IItem, IGauge } from '../../../store/_type/order';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';
import { infoDialogActions } from '../../../store/utils/dialog/info';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { getShowMeasurementItems } from '../../../helpers/view';

interface IProps {
  item: IItem;
  measurements: IMeasurementItem[];
  selectingPartsGauge: IGauge;
  onChangeSizeMeasurement: typeof SizeCorrectionPageEvents.onChangeSizeMeasurement;
  selectingParts: TPartsNumber;
  showInfoDialog: typeof infoDialogActions.show.type;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const MeasurementList: React.FC<TProps> = (props: TProps) => {
  const { measurements, item } = props;
  const brandCode = item.cloth.brandCode;
  const partsNumber = props.selectingParts;
  const showMeasurements = getShowMeasurementItems(measurements, brandCode, partsNumber);

  return (
    <div id={styles.container}>
      {showMeasurements.map((measurement, i) => {

        return <MeasurementListItem key={i} {...props} measurementItem={measurement} />;
      })}
    </div>
  );
};

export default MeasurementList;
