/**
 *  show or hide：表示非表示を判定する関数を置く
 */
import { IPiece } from '../../store/_type/order';
import { TCategory, TPartsNumber } from '../../lookups/master-thisisforreplaceall/';
import { EParts } from '../../lookups/master-thisisforreplaceall/parts';
import { hasJacket } from '.';
import { OPS_BRANDS } from '../../lookups/item-thisisforreplaceall';

/**
 * 生地選択画面で、モデル選択用のプルダウンを表示するかどうか
 */
export const isModelSelectBoxOnClothSection = (category: TCategory, pieces: IPiece[], brandCode: string): boolean =>
  !(
    (category === 'MT' && !hasJacket(pieces)) ||
    (category === 'WM' && !hasJacket(pieces)) ||
    (category === 'FM' && OPS_BRANDS.includes(brandCode) && !hasJacket(pieces)) ||
    (category === 'CS' && OPS_BRANDS.includes(brandCode) && !hasJacket(pieces))
  );

/**
 * デザイン選択画面サイドバーで、指定のパーツにモデル選択用のプルダウンを表示するかどうか
 */
export const isModelSelectBoxOnDesignPartsSection = (
  category: TCategory,
  partsNumber: TPartsNumber,
  brandCode: string,
): boolean =>
  (category === 'MT' && partsNumber === EParts.pants) ||
  (category === 'WM' &&
    [EParts.pants, EParts.skirt, EParts.onepiece, EParts.tops, EParts.vest].some(v => v === partsNumber)) ||
  (category === 'FM' && OPS_BRANDS.includes(brandCode) && [EParts.pants, EParts.vest].some(v => v === partsNumber)) ||
  (category === 'CS' && OPS_BRANDS.includes(brandCode) && [EParts.pants, EParts.vest].some(v => v === partsNumber));

/**
 * 生地選択画面で、イラストポップアップ用のボタンを表示するかどうか
 */
export const isIllustrationButtonOnClothSection = (category: TCategory, pieces: IPiece[]): boolean =>
  category === 'WM' && hasJacket(pieces);
