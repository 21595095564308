import React from 'react';
import partial from 'ramda/es/partial';

import styles from './DistributionCategory.module.scss';

import Label from '../../atoms/Label/Label';
import Title from '../../atoms/Title/Title';
import InputText from '../../../containers/components/InputText';
import RadioGroup from '../../atoms/RadioGroup/RadioGroup';

import { pointPageActions } from '../../../store/pages/point';
import { langResource } from '../../../i18n/_new/resources';
import { TRadioGroup } from '../../../types/new-store/components';
import { isValidFavoriteCustomerCode } from '../../../helpers/common/validate';
import { getCssClassErrorInvalidData } from '../../../helpers/html-css';
import { TOrderStatus, PaymentItem } from '../../../lookups/item-thisisforreplaceall/order-status';
import { canEditItem } from '../../../helpers/orders/digest';

interface IProps {
  hasFavoriteCustomer: boolean;
  favoriteCustomerCode: string;
  favoriteCustomerName: string;
  onChangeValue: typeof pointPageActions.changeValue.tp;
  onChangeRadioButton: typeof pointPageActions.changeRadioButton.tp;
  orderStatus: TOrderStatus;
  isManager: boolean;
  isEdit: boolean;
  needInvoice: boolean;
}

const radioGroups: TRadioGroup[] = [
  { value: 'false', textObj: langResource.pointPage.store },
  { value: 'true', textObj: langResource.pointPage.visit },
];

const invoiceRadioGroups: TRadioGroup[] = [
  { value: 'false', textObj: langResource.pointPage.noNeedInvoice },
  { value: 'true', textObj: langResource.pointPage.needInvoice },
];

const DistributionCategory: React.FC<IProps> = (props: IProps) => {
  const {
    hasFavoriteCustomer,
    favoriteCustomerCode,
    favoriteCustomerName,
    orderStatus,
    isManager,
    isEdit,
    needInvoice,
  } = props;

  const canEditPaymentItem = partial(canEditItem, [orderStatus, isManager]);

  const onHandleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const upperValue = event.target.value;
    const value = upperValue.length > 6 ? upperValue.substring(0, 6) : upperValue;
    props.onChangeValue({ key: 'favoriteCustomerCode', value });
  };

  const onHandleChangehasFavoriteCustomer = (v: string) => {
    const value = v === 'true';
    props.onChangeRadioButton({ key: 'hasFavoriteCustomer', value });
  };

  const onHandleChangeNeedInvoice = (v: string) => {
    const value = v === 'true';
    props.onChangeRadioButton({ key: 'needInvoice', value });
  };

  const isValidFavoriteCustomerCodeInput = !needInvoice || isValidFavoriteCustomerCode(favoriteCustomerCode);
  const isValidFavoriteCustomerNameInput = !needInvoice || !!favoriteCustomerName;
  const favoriteCustomerCodeInputClass = getCssClassErrorInvalidData(
    isValidFavoriteCustomerCodeInput && isValidFavoriteCustomerNameInput,
  );
  const favoriteCustomerCodeMsg = isValidFavoriteCustomerCodeInput ? '' : '半角英数字6桁で入力してください';

  return (
    <div className={styles.distributionCategory}>
      <div className={styles.title}>
        <Title styles={['medium', 'lineLightGray']} textObj={langResource.pointPage.distribution} />
      </div>
      <div className={styles.set}>
        <RadioGroup
          checkedValue={String(hasFavoriteCustomer)}
          groups={radioGroups}
          name="hasFavoriteCustomer"
          onChanged={onHandleChangehasFavoriteCustomer}
          disabled={isEdit && !canEditPaymentItem(PaymentItem.hasFavoriteCustomer)}
        />
      </div>
      {hasFavoriteCustomer && (
        <div className={styles.set}>
          <RadioGroup
            checkedValue={String(needInvoice)}
            groups={invoiceRadioGroups}
            name="needInvoice"
            onChanged={onHandleChangeNeedInvoice}
            disabled={isEdit && !canEditPaymentItem(PaymentItem.needInvoice)}
          />
        </div>
      )}
      <div className={styles.setCode}>
        <div className={styles.inputSet}>
          <div className={styles.label}>
            <Label textObj={langResource.pointPage.visitCode} />
          </div>
          <div className={styles.inputItem}>
            <InputText
              disabled={!needInvoice || (isEdit && !canEditPaymentItem(PaymentItem.visitCode))}
              onValueChanged={onHandleChangeCode}
              value={favoriteCustomerCode}
              className={favoriteCustomerCodeInputClass}
            />
            <div className="error-tooltip">{favoriteCustomerCodeMsg}</div>
            <div className={styles.text}>
              <InputText disabled={true} value={favoriteCustomerName} styles={['colorBlack']} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionCategory;
