import React from 'react';
import NumberFormat from 'react-number-format';
import partial from 'ramda/es/partial';

import styles from './OrderDeliveryMethod.module.scss';

import Title from '../../atoms/Title/Title';
import Label from '../../atoms/Label/Label';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import { IShipping } from '../../../store/_type/order';
import { addressPageActions } from '../../../store/pages/address';
import { langResource } from '../../../i18n/_new/resources';
import { ILookupItem } from '../../../types/lookup';
import { TAddressItem } from '../../../types/new-store/components';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import { EDeliveryMethod, EDeliveryTimeZone } from '../../../lookups/master-thisisforreplaceall';
import { getCssClassErrorInvalidData } from '../../../helpers/html-css';
import { TOrderStatus, DeliveryItem } from '../../../lookups/item-thisisforreplaceall/order-status';
import { canEditItem } from '../../../helpers/orders/digest';

interface IProps {
  isEdit: boolean;
  addressItem: TAddressItem;
  onChangeValue: typeof addressPageActions.changeValue.tp;
  orderStatus: TOrderStatus;
  isManager: boolean;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const OrderDeliveryMethod: React.FC<TProps> = (props: TProps) => {
  const { onChangeValue, orderStatus, isManager, isEdit } = props;
  const { orderNumber, deliveryDateLookups, methodLookups, timeZoneLookups } = props.addressItem;
  const { shippingCost, deliveryMethod, deliveryDateGuest, timeZoneCode } = props.addressItem.shipping;

  const onHandleChangeSelect = (key: keyof Omit<IShipping, 'customerMailAddress'>) => (item: ILookupItem) => {
    const value = String(item.id);
    onChangeValue({ key, orderNumber, value });
    // 時間指定がdisableになった場合にtimeZoneCodeを0000にする。
    if (key === 'deliveryDateGuest' && deliveryDateLookups.findIndex(lookup => lookup.id === value) < 2) {
      onChangeValue({ key: 'timeZoneCode', orderNumber, value: EDeliveryTimeZone.unspecified });
    }
  };

  const connectNumericKeyboard = () => {
    props.connectNumericKeyboard(shippingCost, value => {
      if (!value) {
        return 0;
      } else {
        onChangeValue({ key: 'shippingCost', orderNumber, value });
      }
    });
  };

  const canEditDeliveryItem = partial(canEditItem, [orderStatus, isManager]);

  const isValidDeliveryMethodSelector = !!deliveryMethod;
  const deliveryMethodSelectorClass = getCssClassErrorInvalidData(isValidDeliveryMethodSelector);

  const isValidDeliveryDateGuestSelector = !!deliveryDateGuest;
  const deliveryDateGuestSelectorClass = getCssClassErrorInvalidData(isValidDeliveryDateGuestSelector);

  const isValidTimeZoneSelector = !!timeZoneCode;
  const timeZoneSelectorClass = getCssClassErrorInvalidData(isValidTimeZoneSelector);

  return (
    <div className={styles.delivery}>
      <div className={styles.title}>
        <Title styles={['lineLightBrown']} textObj={langResource.addressPage.deliveryDate} />
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.addressPage.deliveryMethod} />
            <div className={styles.inputWrap}>
              <div className={styles.inputItem2}>
                <SelectBox
                  data={methodLookups}
                  selectedOption={deliveryMethod}
                  onValueChanged={onHandleChangeSelect('deliveryMethod')}
                  className={deliveryMethodSelectorClass}
                  disabled={isEdit && !canEditDeliveryItem(DeliveryItem.deliveryMethod)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.addressPage.deliveryDate} />
            <div className={styles.inputWrap}>
              <div className={styles.inputItem2}>
                <SelectBox
                  data={deliveryDateLookups}
                  selectedOption={deliveryDateGuest}
                  onValueChanged={onHandleChangeSelect('deliveryDateGuest')}
                  className={deliveryDateGuestSelectorClass}
                  disabled={isEdit && !canEditDeliveryItem(DeliveryItem.deliveryDate)}
                />
              </div>
              <div className={styles.inputItem2}>
                <SelectBox
                  disabled={
                    !canEditDeliveryItem(DeliveryItem.deliveryTime) ||
                    deliveryDateLookups.findIndex(lookup => lookup.id === deliveryDateGuest) < 2 // 2日後から選択可能にする。
                  }
                  data={timeZoneLookups}
                  selectedOption={timeZoneCode}
                  onValueChanged={onHandleChangeSelect('timeZoneCode')}
                  className={timeZoneSelectorClass}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columnSize1}>
            <Label textObj={langResource.addressPage.deliveryFee} />
            <div className={styles.inputWrap}>
              <div className={styles.inputItem1}>
                <NumberFormat
                  prefix="¥"
                  thousandSeparator={true}
                  readOnly={true}
                  value={shippingCost}
                  allowNegative={false}
                  // decimalScale={0} FIXME: stateが反映されないので一時的にコメントアウト
                  onFocus={connectNumericKeyboard}
                  data-input-type={EKeyboardInputType.number}
                  disabled={isEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDeliveryMethod;
