import { IHeaderMapItem } from '../types/header';
import { ERouterPath } from '../types';
import { langResource } from '../i18n/_new/resources/';

export const HEADER_ITEMS: IHeaderMapItem[] = [
  {
    path: ERouterPath.home,
    textKey: langResource.header.home,
  },
  {
    path: ERouterPath.samples,
    textKey: langResource.header.sample,
  },
  {
    path: ERouterPath.ordersSearch,
    textKey: langResource.header.orderSearch,
  },
  {
    path: ERouterPath.orderDetail,
    textKey: langResource.header.orderDetail,
  },
  {
    path: ERouterPath.clothSelection,
    textKey: langResource.header.clothSelection,
  },
  {
    path: ERouterPath.designSelection,
    textKey: langResource.header.designSelection,
  },
  {
    path: ERouterPath.sizeCorrection,
    textKey: langResource.header.sizeCorrection,
  },
  {
    path: ERouterPath.specialAdjust,
    textKey: langResource.header.sizeCorrection,
  },
  {
    path: ERouterPath.address,
    textKey: langResource.header.address,
  },
  {
    path: ERouterPath.amount,
    textKey: langResource.header.amout,
  },
  {
    path: ERouterPath.settlement,
    textKey: langResource.header.settlement,
  },
  {
    path: ERouterPath.inventorySearch,
    textKey: langResource.header.inventorySearch,
  },
  {
    path: ERouterPath.orderConfirmation,
    textKey: langResource.header.orderConfirmation,
  },
  {
    path: ERouterPath.itemConfirmation,
    textKey: langResource.header.itemConfirmation,
  },
];
