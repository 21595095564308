import { ILookupItem } from './../types/lookup';
import { TMaster } from '../types/';
import {
  TOrderItemCode,
  TCategory,
  MASTER_ITEM_CODE_SHIRT,
  MASTER_ITEM_CODE_WEMEN,
  MASTER_ITEM_CODE_MEN,
  SHIRT_ITEM_CODE_ACCORDING_TO_SUB_CATEGORY,
  TSubCategory,
} from '../lookups/master-thisisforreplaceall';

/**
 * コードを名前に変換（TMasterのデータを利用）
 */
export const getValue = <T = string | number, U = string>(code: T, master: Array<TMaster<T, U>>) =>
  (master.find(v => v.code === code) || { value: undefined }).value;

/**
 * 名前をコードに変換（TMasterのデータを利用）
 */
export const getCode = <T = string | number, U = string>(value: U, master: Array<TMaster<T, U>>) =>
  (master.find(v => v.value === value) || { code: undefined }).code;

/** itemCodeを名称に変換する */
export const getValueForItemCode = (itemCode: TOrderItemCode, category: TCategory) =>
  category === 'DS'
    ? getValue(itemCode, MASTER_ITEM_CODE_SHIRT)
    : ['WM', 'WC'].includes(category)
    ? getValue(itemCode, MASTER_ITEM_CODE_WEMEN)
    : getValue(itemCode, MASTER_ITEM_CODE_MEN);

/**
 * TMasterから、指定したcodeの配列のみを持ったTMasterを生成、並び順はcodeの配列の順に並び替わる（ただし、元のTMasterに存在しないコードを指定しても無視される）
 * ※よくわからないが、codesに渡す並び順は重要みたい。。。
 */
export const pick = <T = string | number, U = string>(codes: T[], master: Array<TMaster<T, U>>): Array<TMaster<T, U>> =>
  master.filter(v => codes.includes(v.code)).sort((a, b) => codes.indexOf(a.code) - codes.indexOf(b.code));

/**
 * TmasterをILookup[]に変換する関数
 */
export const toLookup = <T = string | number, U = string>(master: TMaster[]): ILookupItem[] =>
  master.map(v => ({ id: v.code, value: `${v.value}` }));

/**
 * サブカテゴリーに応じたシャツのアイテムコードの配列を取得
 */
export const getShirtItemCodesFromSubCategory = (subcategory: TSubCategory) =>
  SHIRT_ITEM_CODE_ACCORDING_TO_SUB_CATEGORY[subcategory];
