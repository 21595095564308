import * as Sentry from '@sentry/browser';
import isValid from 'date-fns/fp/isValid';
import parse from 'date-fns/fp/parse';
import format from 'date-fns/fp/format';
import pipe from 'ramda/es/pipe';
import config from '../../configuration/config';

const DATE = new Date();

/**
 * 日付のフォーマットと合致する文字列の場合、trueを返却する
 * @param dateFormat 日付のフォーマット
 */
export const isDateFormatStr = (dateFormat: string) => (x: unknown): x is string =>
  typeof x === 'string' && pipe(parse(DATE, dateFormat), isValid)(x);

/**
 * 指定した日付のフォーマットに合致する文字列の場合、Date型に変換して返却
 * 指定した日付のフォーマットに合致しない場合、1970-01-01のDateを返却する
 * @param dateFormat 日付のフォーマット
 */
export const dateStr2Date = (dateFormat: string) => (dateStr: string): Date => {
  if (!isDateFormatStr(dateFormat)(dateStr)) {
    if (!config.isLocal) {
      Sentry.withScope(scope => {
        scope.setExtras({
          dateFormat,
          dateStr,
        });
        Sentry.captureMessage('dateStr2Date invalid format.', Sentry.Severity.Warning);
      });
    }
    return new Date('1970-01-01');
  }
  return parse(DATE, dateFormat, dateStr);
};

/**
 * 日付のフォーマットを指定し、JSON.parseの引数に渡すことで、
 * JSON.parse時に日付のフォーマットと合致する値をDate型に変換する
 * @param dateFormat 日付のフォーマット
 */
export const reviveDate = (dateFormat: string) => (_: string, value: unknown) => {
  if (value == null || typeof value !== 'string' || !isDateFormatStr(dateFormat)(value)) {
    return value;
  }
  return dateStr2Date(dateFormat)(value);
};

/**
 * 日付のフォーマットを指定し、JSON.stringifyの引数に渡すことで
 * JSON.stringify時に日付型の値を指定した日付のフォーマットの文字列に変換する
 * @param dateFormat 日付のフォーマット
 */
export const replaceDate = (dateFormat: string) => (_: string, value: unknown) => {
  if (value == null || typeof value !== 'string' || !isDateFormatStr(dateFormat)(value)) {
    return value;
  }
  const date = dateStr2Date(dateFormat)(value);
  return format(dateFormat, date);
};

const JSON_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
export const jsonDateReviver = reviveDate(JSON_DATE_FORMAT);
export const jsonDateReplacer = replaceDate(JSON_DATE_FORMAT);
