import { ISizeMeasurementView } from '../../_type/lookups';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { IMeasurementItem, IConfirmationMeasurement } from '../../../types/new-store/components';

export const sizeMeasurementsViewSelector = (state: ISizeMeasurementView[]) => new SizeMeasurementsViewSelector(state);

class SizeMeasurementsViewSelector {
  constructor(private state: ISizeMeasurementView[]) {}

  /** 商品確認向けにpartsIndexでも引けるようにしておく */
  public getFromPartsIndex(partsIndex: string) {
    return this.state.find(v => v.partsIndexes.includes(partsIndex));
  }

  public getFromPartsNumber(partsNumber: TPartsNumber) {
    return this.state.find(v => v.partsNumber === partsNumber);
  }

  /** コンポーネント向けに加工したやつ。不要になったら消すこと。 */
  public getMeasurementItems(partsNumber: TPartsNumber) {
    const view = this.getFromPartsNumber(partsNumber);
    return !view ? [] : (view.measurementItems as IMeasurementItem[]);
  }

  /** コンポーネント向けに加工したやつ。不要になったら消すこと。 */
  public getConfirmations(partsNumber: TPartsNumber): IConfirmationMeasurement[] {
    return this.getMeasurementItems(partsNumber).map(measurementItem => {
      const { measurementName, value, diff } = measurementItem;
      const data = {
        measurementName,
        measurementValue: value,
        diff,
      };
      return data;
    });
  }
}
