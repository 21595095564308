import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import styles from './OrderConfirmationPage.module.scss';

import Footer from '../../organisms/Footer/Footer';
import PaymentConfirmation from '../../organisms/confirmations/PaymentConfirmation/PaymentConfirmation';
import OrderConfirmationDetail from '../../organisms/OrderConfirmationDetail/OrderConfirmationDetail';

import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { langResource } from '../../../i18n/_new/resources';
import { TPageContainerProps } from '../../../containers/pages/OrderConfirmationPage';
import { resolvePath } from '../../../helpers/common/path';
import { isStoppedSettleOrderTerm } from '../../../helpers/orders';
import config from '../../../configuration/config';

type TProps = TPageContainerProps & RouteComponentProps;

const OrderConfirmationPage: React.FC<TProps> = props => {
  const {
    orderConfirmations,
    isFilledItem,
    isValidAllOrders,
    isAddressPageCompleted,
    isManager,
    canOrdersDuringOrderStopTerm,
    showInfoDialog,
  } = props;

  useEffect(() => {
    const cases: Array<[boolean, string]> = [
      [isFilledItem, 'オーダーがありません。'],
      [isValidAllOrders, '不正なオーダーを検知しました。商品内容確認画面で確認してください。'],
      [isAddressPageCompleted, 'お届け先画面の項目に不正な入力を検知しました。'],
    ];
    const contents: string[] = cases.filter(([isValid, msg]) => !isValid).map(([isValid, msg]) => msg);
    if (contents.length > 0) {
      showInfoDialog({
        hasOpen: true,
        title: '確認',
        contents: contents.join(' '),
      });

      if (!config.isLocal) {
        Sentry.withScope(scope => {
          scope.setExtras({
            isValid: { isFilledItem, isValidAllOrders, isAddressPageCompleted },
          });
          Sentry.captureMessage('注文内容確認画面で不正なオーダーを検知', Sentry.Severity.Warning);
        });
      }
    }
  }, [showInfoDialog, isFilledItem, isValidAllOrders, isAddressPageCompleted]);

  const onEditOrder = (path: ERouterPath, orderNumber?: string) => {
    if (orderNumber) {
      props.onEditOrder({ orderNumber });
    }
    props.history.push(resolvePath(path));
  };

  // 「決済可能な時間」 or「管理者の場合」or「受注できないアイテムが含まれていない場合」は決済画面へ行ける
  const canSettlement = !isStoppedSettleOrderTerm(new Date()) || isManager || canOrdersDuringOrderStopTerm;

  return (
    <div id={styles.pageContainer}>
      <div className={styles.printPage}>
        <div className={styles.payment}>
          <PaymentConfirmation {...props} onEditOrder={onEditOrder} />
        </div>
      </div>
      <div className={styles.printPage}>
        <div className={styles.order}>
          {orderConfirmations.map((order, key) => (
            <OrderConfirmationDetail key={key} order={order} {...props} onEditOrder={onEditOrder} />
          ))}
        </div>
      </div>
      {utility(props, canSettlement)}
    </div>
  );
};

function getFooterButtons(props: TProps, canSettlement: boolean): IFooterButton[] {
  const { isFilledItem, isValidAllOrders, isAddressPageCompleted } = props;

  const onSaveTmpOrder = () => {
    if (canSettlement) {
      return;
    }
    props.history.push(resolvePath(ERouterPath.orderConfirmation));
    props.showInfoDialog({
      hasOpen: true,
      title: '確認',
      contents:
        '現在受注停止期間のため、シャツとブランドKRの注文以外できません。保存して受注停止期間外に再度お試しください。閉じるボタンを押すと途中保存のモーダルが開きます。',
      onOK: () => {
        props.onShowSaveTmpOrderDialog();
      },
    });
  };

  // 決済ボタン仕様 - 通常：決済画面へ進む ・ 受注停止期間：保存モーダル表示
  return [
    {
      type: 'back',
      path: ERouterPath.address,
    },
    {
      type: 'print',
      func: () => window.print(),
    },
    {
      type: 'confirm',
      isDisabled: [isFilledItem, isValidAllOrders, isAddressPageCompleted].some(v => !v),
      textObj: langResource.footer.orderConfirm,
      path: canSettlement ? ERouterPath.settlement : undefined,
      func: () => onSaveTmpOrder(),
    },
  ];
}

function utility(props: TProps, canSettlement: boolean) {
  return (
    <React.Fragment>
      <Footer buttons={getFooterButtons(props, canSettlement)} />
    </React.Fragment>
  );
}

export default withRouter(OrderConfirmationPage);
