import { actionObjectCreatorFactory } from '../../lib/action-creator-factory';
import { TPartialPayment, IPayment } from '../_type/payment';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeState } from '../../helpers/common/merge';
import { cloneDeep } from 'lodash';

// actions
const ac = actionObjectCreatorFactory();
const actionTitle = 'payment';

export type TUpdatePaymentParam = {
  payment?: TPartialPayment;
  useablePoints?: number;
  backPoints?: number;
  hasLoadedUseablePoint?: boolean;
};

export const paymentActions = {
  update: ac<TUpdatePaymentParam>(`[${actionTitle}] update`),
  delete: ac<void>(`[${actionTitle}] delete`),
};

export type TPaymentState = IPayment & {
  /** （送信しない）使えるポイント */
  useablePoints: number;
  /** （送信しない）還元ポイント */
  backPoints: number;
  /** （送信しない）ポイントを読み込み済みか？ */
  hasLoadedUseablePoint: boolean;
};

const initialState: TPaymentState = {
  usePoint: 0,
  couponUsePoints: 0,
  hasIntroducer: false,
  introducerCode: '',
  introducerName: '',
  hasFavoriteCustomer: false,
  favoriteCustomerCode: '',
  favoriteCustomerName: '',
  isSettlement: true,
  useablePoints: 0,
  backPoints: 0,
  deliveryRate: 0,
  isCorporation: false,
  hasLoadedUseablePoint: false,
  needInvoice: false,
};

export const paymentReducer = reducerWithInitialState(initialState)
  .case(paymentActions.update._action, (state, payload) => {
    const { payment, useablePoints, backPoints, hasLoadedUseablePoint } = payload;
    const newState = mergeState(cloneDeep(state), {
      ...payment,
      useablePoints,
      backPoints,
      hasLoadedUseablePoint,
    }) as TPaymentState;
    return { ...newState };
  })
  .case(paymentActions.delete._action, state => {
    return { ...initialState };
  });
