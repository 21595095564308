import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store/index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { PurchaseHistoryDialogEvents } from '../views/events/PurchaseHistoryDialog';
import { actions as dialogActions } from '../store/utils/dialog/purchase-history/action-reducer';
import { actions as sizeActions } from '../store/order/size/actions';
import { ILoadOrderDetail } from '../types/orders/digest';

const loadPurchaseHistory: Epic<AnyAction, Action<Parameters<typeof dialogActions.load.started>[0]>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(PurchaseHistoryDialogEvents.onLoadPurchaseHistory),
    map(action => {
      return dialogActions.load.started();
    }),
  );

const close: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(PurchaseHistoryDialogEvents.onClose),
    map(action => {
      return dialogActions.close();
    }),
  );

const setPartsSizeHistory: Epic<AnyAction, Action<ILoadOrderDetail>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(PurchaseHistoryDialogEvents.onSetPartsSizeHistory),
    map(({ payload }) => {
      return sizeActions.loadPartsSizeHistory(payload);
    }),
  );

export const PurchaseHistoryDialogListener = combineEpics(loadPurchaseHistory, close, setPartsSizeHistory);
