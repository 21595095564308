import React from 'react';

import styles from './LoadingInfo.module.scss';
import Spinner from '../../../components/atoms/Spinner/Spinner';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import { II18nItem } from '../../../i18n/_new/types';
import { langResource } from '../../../i18n/_new/resources';

type TDisplay = 'block' | 'absolute';

interface IProps {
  isLoading?: boolean;
  messageKey?: II18nItem;
  displayType?: TDisplay;
}

const LoadingInfo: React.FC<IProps> = ({
  isLoading = false,
  messageKey = langResource.components.loadingInfo.default,
  displayType = 'block',
  children,
}) => {
  const message = messageKey ? <I18TextContainer textObj={messageKey} /> : children;

  return (
    <div className={getClassName()}>
      <div>
        {isLoading ? message : null}
        <Spinner isLoading={isLoading} />
      </div>
    </div>
  );

  function getClassName(): string {
    const classes: string[] = [];
    classes.push(styles.info);

    if (isLoading) {
      classes.push(styles.isLoading);
    }

    if (displayType && displayType === 'absolute') {
      classes.push(styles.absolute);
    }

    return classes.join(' ');
  }
};

export default LoadingInfo;
