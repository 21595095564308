import { rawStrArr2EnumLikeObj } from '../../lib/utils';
import { langResource } from '../../i18n/_new/resources/index';
import { TMaster } from '../../types/index';
import { II18nItem } from '../../i18n/_new/types';

const subcategories = rawStrArr2EnumLikeObj([
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
]);

type TNotNullSubCategory = keyof typeof subcategories;
export type TSubCategory = '' | TNotNullSubCategory;

export const ESubcategory = {
  suit: subcategories['01'],
  jacket: subcategories['02'],
  pants: subcategories['03'],
  vest: subcategories['04'],
  pantSuit: subcategories['05'],
  skirtSuit: subcategories['06'],
  tuxedo: subcategories['07'],
  skirt: subcategories['12'],
  onepiece: subcategories['13'],
  onepieceSuit: subcategories['14'],
  longSleve: subcategories['15'],
  shortSleve: subcategories['16'],
  formalShirt: subcategories['17'],
  coat: subcategories['18'],
  tops: subcategories['19'],
  womanlongSleeve: subcategories['20'],
  womanshortSleeve: subcategories['21'],
};

export const MASTER_SUBCATEGORY: Array<TMaster<TNotNullSubCategory, II18nItem>> = [
  { code: ESubcategory.suit, value: langResource.homePage.suit },
  { code: ESubcategory.jacket, value: langResource.homePage.jacket },
  { code: ESubcategory.pants, value: langResource.homePage.pants },
  { code: ESubcategory.vest, value: langResource.homePage.vest },
  { code: ESubcategory.pantSuit, value: langResource.homePage.pantSuit },
  { code: ESubcategory.skirtSuit, value: langResource.homePage.skirtSuit },
  { code: ESubcategory.tuxedo, value: langResource.homePage.tuxedo },
  { code: ESubcategory.skirt, value: langResource.homePage.skirt },
  { code: ESubcategory.onepiece, value: langResource.homePage.onepiece },
  { code: ESubcategory.onepieceSuit, value: langResource.homePage.onepiece },
  { code: ESubcategory.longSleve, value: langResource.homePage.longSleve },
  { code: ESubcategory.shortSleve, value: langResource.homePage.shortSleve },
  { code: ESubcategory.formalShirt, value: langResource.homePage.formal },
  { code: ESubcategory.womanlongSleeve, value: langResource.homePage.womanlongSleeve },
  { code: ESubcategory.womanshortSleeve, value: langResource.homePage.womanshortSleeve },
  { code: ESubcategory.coat, value: langResource.homePage.coat },
  { code: ESubcategory.tops, value: langResource.homePage.tops },
];
