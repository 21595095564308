import React, { useState, useEffect } from 'react';

import styles from './LoginAndSaveDialog.module.scss';
// material
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { TransitionProps } from '@material-ui/core/transitions/transition';

// component
import Button from '../../atoms/Button/Button';
import I18TextContainer from '../../../containers/I18Text/I18Text';

// other
import { langResource } from '../../../i18n/_new/resources';
import { HeaderEvents } from '../../../views/events/Header';
import { TProgressList, IProgressListItem } from '../../../store/progress/state';

const transition = React.forwardRef<unknown, TransitionProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IProps {
  hasOpen: boolean;
  onLoadCustomer: typeof HeaderEvents.customerLogin;
  onLoadProgressData: typeof HeaderEvents.loadProgressData;
  onLoadProgressList: typeof HeaderEvents.loadProgressList;
  onDeleteProgressData: typeof HeaderEvents.deleteProgressData;
  progressList: TProgressList;
  onClose: () => void;
}

const LoginAndSaveDialog: React.FC<IProps> = props => {
  const { hasOpen, onClose, progressList, onLoadProgressList } = props;
  const [hasShowCustomer, togleHasShowCustomer] = useState(true);

  useEffect(() => {
    if (hasOpen) {
      onLoadProgressList();
    }
  }, [hasOpen, onLoadProgressList]);

  const onClickTab = (v: boolean) => (event: any) => togleHasShowCustomer(v);

  const onLoadProgressData = (list: IProgressListItem) => {
    return () => {
      props.onLoadProgressData(list.progressId);
      props.onClose();
    };
  };

  const onDeleteProgressData = (list: IProgressListItem) => {
    return () => props.onDeleteProgressData(list);
  };

  return (
    <React.Fragment>
      <Dialog
        className={`dialog ${styles.containers}`}
        open={hasOpen}
        aria-labelledby="form-dialog-title"
        onClose={onClose}
        TransitionComponent={transition}
      >
        <div>{renderTab()}</div>
        <div className={styles.contents}>{renderTemporarilySaved()}</div>
      </Dialog>
      {/* 以下、途中オーダーの削除 */}
      {/*
      <Dialog
        className={`dialog ${styles.deleatContainers}`}
        open={hasOpen}
        aria-labelledby="form-dialog-title"
        onClose={onClose}
        TransitionComponent={transition}
      >
        <DialogContent>
          <div className={styles.deleatHeader}>途中保存情報を削除しますか？</div>
          <div className={styles.deleatContents}>
            <div className={styles.thead}>
              <div className={styles.row}>
                <div className={styles.col1}>
                  <p>登録日時</p>
                </div>
                <div className={styles.col2}>
                  <p>お名前</p>
                </div>
              </div>
            </div>
            <div className={styles.tbody}>
              <div className={styles.row}>
                <div className={styles.col1}>
                  <p>2019/07/31 13:10:20</p>
                </div>
                <div className={styles.col2}>
                  <p>名前なまえ</p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button onClick={onClose} styles={['white', 'noborder']}>
            キャンセル
          </Button>
          <Button onClick={onClose} styles={['red']}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      */}
    </React.Fragment>
  );

  function renderTab() {
    const getClassNames = (isCustomer: boolean): string => {
      const classes = [styles.tab];
      const isActive = (isCustomer && hasShowCustomer) || (!isCustomer && !hasShowCustomer);
      if (isActive) {
        classes.push(styles.tabActive);
      }
      return classes.join(' ');
    };

    return (
      <React.Fragment>
        <div className={styles.tabWrapper}>
          <div className={getClassNames(false)} onClick={onClickTab(false)}>
            <div className={styles.parts}>
              <I18TextContainer textObj={langResource.dialog.loginAndSave.temporarilySaved.title} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function renderTemporarilySaved() {
    return (
      <React.Fragment>
        {/* 途中オーダー */}
        <div className={styles.savedWrap}>
          <DialogContent>
            <div className={styles.savedContents}>
              <div className={styles.thead}>
                <div className={styles.row}>
                  <div className={styles.col1}>
                    <p>登録日時</p>
                  </div>
                  <div className={styles.col2}>
                    <p>お名前</p>
                  </div>
                  <div className={styles.col3}>
                    <p>アクション</p>
                  </div>
                </div>
              </div>
              <div className={styles.tbody}>
                {progressList.map(list => (
                  <div className={styles.row} key={list.updateDate + list.progressName}>
                    <div className={styles.col1}>
                      <p>{list.createDate}</p>
                    </div>
                    <div className={styles.col2}>
                      <p>{list.progressName}</p>
                    </div>
                    <div className={styles.col3}>
                      <p>
                        <Button
                          onClick={onLoadProgressData(list)}
                          dataId={list.progressId}
                          styles={['black', 'size-xs']}
                        >
                          読み込む
                        </Button>
                      </p>
                      <p>
                        <Button
                          onClick={onDeleteProgressData(list)}
                          dataId={list.progressId}
                          styles={['red', 'size-xs']}
                        >
                          削除
                        </Button>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>
        </div>
      </React.Fragment>
    );
  }
};

export default LoginAndSaveDialog;
