import { TRequestConfig, TRequestParams } from '../../_common/types';
import { toCamelCaseObject } from '../../../helpers/conv-object/convertions';
import MOCK_UP_JSON from '../../../mockups/jsonServer/mockup.json';

export const projectSelectorRequestConfig: TRequestConfig = {
  endpoint: '/customer/sales_events',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: getSearchProjectSuccessData(),
    };
  },
  params2request: (params): TRequestParams => {
    return {
      query: params ? params.query : null,
    };
  },
};

function getSearchProjectSuccessData(): string {
  const success = MOCK_UP_JSON['project-selector'];
  return JSON.stringify(toCamelCaseObject(success!));
}
