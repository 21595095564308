import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './AddressPage.module.scss';

import Title from '../../atoms/Title/Title';
import OrderDestination from '../../molecules/OrderDestination/OrderDestination';
import OrderDeliveryMethod from '../../molecules/OrderDeliveryMethod/OrderDeliveryMethod';
import Footer from '../../organisms/Footer/Footer';
import I18Text from '../../../containers/I18Text/I18Text';

import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { TPageContainerProps } from '../../../containers/pages/AddressPage';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { langResource } from '../../../i18n/_new/resources';
import { generateCancelModifiedOrderBtnFunc } from '../../../helpers/components/footer';
import { getOrderDetailPath } from '../../../helpers/common/path';

type TProps = TPageContainerProps & RouteComponentProps & ICustomKeyboardInjectedProps;

class AddressPage extends Component<TProps> {
  componentDidMount() {
    const { isLoadInitialize, customer, orderNumberOrderOne: orderNumber } = this.props;
    if (isLoadInitialize) {
      this.props.onLoadAddress({ orderNumber, customer });
    } else {
      this.props.reloadPostalCodeAddress();
    }
  }

  render() {
    const { addressItems } = this.props;
    return (
      <React.Fragment>
        <div onFocus={this.props.checkFocusForNumericKeyboard} className={styles.addressPage}>
          <div className={styles.addressPageContent}>
            {addressItems.map((addressItem, index) => {
              const { title, itemCodeAndName, clothSeasonCode, clothCode, brandCode } = addressItem;
              const additionalInformations = [
                { label: langResource.addressPage.item, value: itemCodeAndName },
                { label: langResource.addressPage.clothCode, value: `${clothSeasonCode}${clothCode}` },
                { label: langResource.addressPage.brand, value: brandCode },
              ];
              return (
                <div key={index}>
                  <div className={styles.header}>
                    <Title styles={['bgBrightBrown']}>
                      <div className={styles.title}>
                        <p>{title}</p>
                        {additionalInformations.map(({ label, value }, i) => (
                          <span key={i}>
                            <I18Text textObj={label} /> : {value || ''}
                          </span>
                        ))}
                      </div>
                    </Title>
                  </div>
                  <OrderDestination index={index} {...this.props} {...addressItem} />
                  <OrderDeliveryMethod {...this.props} addressItem={addressItem} />
                </div>
              );
            })}
          </div>
        </div>
        {utility(this.props)}
      </React.Fragment>
    );
  }
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      path: !props.isEdit ? ERouterPath.home : undefined,
      textObj: !props.isEdit ? langResource.footer.addOrder : langResource.footer.cancelEditOrder,
      func: !props.isEdit
        ? undefined
        : generateCancelModifiedOrderBtnFunc(
            props.history,
            props.serial,
            props.cancelModifiedOrder,
            props.onShowOkCancelDialog,
          ),
    },
    {
      type: 'back',
      path: ERouterPath.itemConfirmation,
      isRender: !props.isEdit,
    },
    {
      type: 'next',
      path: ERouterPath.orderConfirmation,
      isDisabled: !props.hasAddressInputCompleted,
      isRender: !props.isEdit,
    },
    {
      type: 'confirm',
      textObj: !props.isEdit ? langResource.footer.orderContents : langResource.footer.orderContentsEdit,
      isDisabled: !props.hasConfirmOrders,
      path: !props.isEdit ? ERouterPath.orderConfirmation : undefined,
      func: !props.isEdit ? undefined : () => props.history.push(getOrderDetailPath(props.serial)),
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      {/* <LoadingInfo isLoading={prop.isLoading} /> */}
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(AddressPage);
