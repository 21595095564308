import React from 'react';
import NumberFormat from 'react-number-format';

import cssStyles from './PriceText.module.scss';

import I18TextContainer from '../../../containers/I18Text/I18Text';

import { TPricePrefix, TPricePostfix } from '../../../types/orders';
import { langResource } from '../../../i18n/_new/resources';

interface IProps {
  price: number;
  prefix?: TPricePrefix | '';
  postfix?: TPricePostfix;
  styles?: string[];
}

const PriceText: React.FC<IProps> = (props: IProps) => {
  const { price, styles, prefix, postfix } = props;

  return (
    <p className={getStyles(styles)}>
      <span className={cssStyles.price}>
        <NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={prefix} />
      </span>
      {getPostFix(postfix)}
    </p>
  );

  function getPostFix(value?: TPricePostfix) {
    if (value === 'tax') {
      return (
        <span className={cssStyles.tax}>
          <I18TextContainer textObj={langResource.components.priceText.tax} />
        </span>
      );
    }
    if (value === 'pt') {
      return (
        <span className={cssStyles.pt}>
          <I18TextContainer textObj={langResource.components.priceText.point} />
        </span>
      );
    }
    return <span className={cssStyles.none} />;
  }

  function getStyles(values: string[] = []): string {
    const classes = [cssStyles.text];
    if (values.find(v => v === 'bold')) {
      classes.push(cssStyles.bold);
    }
    if (values.find(v => v === 'inline')) {
      classes.push(cssStyles.inline);
    }
    return classes.join(' ');
  }
};

PriceText.defaultProps = {
  prefix: '',
};

export default PriceText;
