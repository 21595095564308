export type Nullable<T> = T | null;
export type Optional<T> = T | undefined;
export type Blankable<T> = T | '';
export type IndexedObject<V = any> = { [k: string]: V };
export type TMaster<T1 = string, T2 = string> = {
  code: T1;
  value: T2;
};

export type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R> ? Array<NestedPartial<R>> : NestedPartial<T[K]>;
};

export type KeyExclude<T, U extends keyof T> = { [K in Exclude<keyof T, U>]: T[K] };

// export type UnionOfValue<T> = T[keyof T];
// export type UnionOfKey<T> = keyof T;

export interface IMessage {
  code: string;
  value: string;
}

export interface IAppError {
  code?: string;
  message: string;
}

export enum ERouterPath {
  login = '',
  samples = 'samples',
  home = 'home',
  dialog = 'dialog',
  ordersSearch = 'orders',
  orderDetail = 'orderDetail',
  inventorySearch = 'inventory',

  itemContentPage = 'item',
  clothSelection = 'item/cloth',
  designSelection = 'item/design',
  sizeCorrection = 'item/size',
  specialAdjust = 'item/size/adjust',

  orderContentPage = 'order',
  itemConfirmation = 'order/itemConfirmation',
  address = 'order/shipping',
  amount = 'order/options',
  orderConfirmation = 'order/orderConfirmation',
  settlement = 'order/settlement',
  settlementEnd = 'order/settlementEnd',
}
