import { actionCreatorFactory } from 'typescript-fsa';
import { IOrder, TPartialOrder, TProductKind } from '../_type/order';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { TOrderDetail } from '../../helpers/api/orders/conv-state-reverse';
import { ICustomer } from '../_type/customer';
import { IPayment } from '../_type/payment';
import { IStaff } from '../_type/staff';
import { IndexedObject, Nullable, ERouterPath, Optional } from '../../types';
import { cloneDeep } from 'lodash';
import { mergeState } from '../../helpers/common/merge';
import { TScProject } from '../../types/project';

const acf = actionCreatorFactory('[orderHistory]');
export const orderDetailActions = {
  pendingON: acf<void>('pendingON'),
  pendingOFF: acf<void>('pendingOFF'),
  editOrder: acf.async<{ orderNumber: string; path: ERouterPath }, {}, {}>('editOrder'),
  sendModifyOrder: acf.async<{}, {}, {}>('sendModifyOrder'),
  cancelOrder: acf.async<{ orderNumber: string }, {}, {}>('cancelOrder'),
  loadOrderDetail: acf.async<{ orderNumber: string }, { orderDetail: TOrderDetail }, {}>('loadOrderDetail'),
  loadEditedOrder: acf<{ orders: IndexedObject<IOrder>; payment: IPayment; scProject: TScProject }>('loadEditedOrder'),
  updatePartialOrder: acf<{ orderNumber: string; data: TPartialOrder }>('updateStandardSize'),
  loadLookups: acf<{ orderDetail: TOrderDetail }>('loadLookups'),
  deleteDetail: acf<void>('deleteDetail'),
};

// reducer
export interface IOrderDetailState {
  pending: boolean;
  customer: Nullable<ICustomer>;
  payment: Nullable<IPayment>;
  scProject: Optional<TScProject>;
  staff: Nullable<IStaff>;
  orders: IndexedObject<IOrder>;
  serialNumber: string;
  productKind: TProductKind;
  note?: string;
  backup: {
    customer: Nullable<ICustomer>;
    payment: Nullable<IPayment>;
    scProject: Optional<TScProject>;
    staff: Nullable<IStaff>;
    orders: IndexedObject<IOrder>;
    productKind: TProductKind;
  };
}

const initialData = {
  pending: false,
  customer: null,
  payment: null,
  staff: null,
  scProject: undefined,
  orders: {},
  serialNumber: '',
  productKind: 'K' as TProductKind,
  note: '',
};

const initialState: IOrderDetailState = {
  ...initialData,
  backup: {
    ...initialData,
  },
  serialNumber: '',
};

export const orderDetailReducers = reducerWithInitialState(initialState)
  .case(orderDetailActions.pendingON, state => {
    return {
      ...state,
      pending: true,
    };
  })
  .case(orderDetailActions.pendingOFF, (state, payload) => {
    return {
      ...state,
      pending: false,
    };
  })
  .case(orderDetailActions.loadOrderDetail.started, (state, payload) => {
    return {
      ...state,
      serialNumber: payload.orderNumber || state.serialNumber,
    };
  })
  .case(orderDetailActions.cancelOrder.done, state => {
    return {
      ...state,
      pending: false,
    };
  })
  .case(orderDetailActions.deleteDetail, (state, payload) => {
    return {
      ...initialState,
      pending: false,
      backup: {
        ...initialData,
      },
    };
  })
  .case(orderDetailActions.loadOrderDetail.done, (state, payload) => {
    return {
      ...state,
      ...payload.result.orderDetail,
      backup: {
        ...cloneDeep(payload.result.orderDetail),
      },
    };
  })
  .case(orderDetailActions.loadEditedOrder, (state, payload) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        ...payload.orders,
      },
      payment: {
        ...state.payment,
        ...payload.payment,
      },
      scProject: {
        ...state.scProject,
        ...payload.scProject,
      },
    };
  })
  .case(orderDetailActions.updatePartialOrder, (state, payload) => {
    const { orderNumber, data } = payload;
    if (state.orders[orderNumber] === undefined) {
      return { ...state };
    }
    const orderState = { ...state.orders[orderNumber] };
    const orders = { ...state.orders };
    orders[orderNumber] = mergeState(orderState, data) as IOrder;
    return { ...state, orders };
  })
  .case(orderDetailActions.loadOrderDetail.failed, (state, payload) => {
    return {
      ...initialState,
    };
  });
