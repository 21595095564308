import React from 'react';

import styles from './SpecialAdjustOptionList.module.scss';

import SpecialAdjustOptionListItem from '../SpecialAdjustOptionListItem/SpecialAdjustOptionListItem';

import { IItem } from '../../../store/_type/order';
import { IAdjustOptionItem } from '../../../types/new-store/components';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';

interface IProps {
  item: IItem;
  adjustOptions: IAdjustOptionItem[];
  onChangeAdjustOption: typeof SizeCorrectionPageEvents.onChangeAdjustOption;
}

const SpecialAdjustOptionList: React.FC<IProps> = (props: IProps) => {
  const { adjustOptions } = props;
  return (
    <div id={styles.container}>
      {adjustOptions.map((option, i) => {
        return <SpecialAdjustOptionListItem key={i} {...props} adjustOption={option} />;
      })}
    </div>
  );
};

export default SpecialAdjustOptionList;
