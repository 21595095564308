import partial from 'ramda/es/partial';
import { CANCELED_ORDER_STR, SETTLED_ORDER_STR } from '../../lookups/item-thisisforreplaceall';
import {
  EDIT_STATUS_CONFIG,
  TItemType,
  TStatusConfig,
  TOrderStatus,
  TEditableConfig,
  NOT_EDITABLE_ITEM,
  OrderStatus,
  EStaff,
  EStatus,
} from '../../lookups/item-thisisforreplaceall/order-status';

export const getCsvFileName = (): string => {
  return '注文一覧.csv';
};

/**
 * 引数がtrueの場合、「済」を返却、falseの場合、""を返却
 */
export const getCanceledOrderStr = (hasCanceled: boolean | string): string => {
  const isTrue = typeof hasCanceled === 'string' ? hasCanceled === 'true' : hasCanceled;
  return isTrue ? CANCELED_ORDER_STR : '';
};

export const getSettledOrderStr = (hasSettled: boolean | string): string => {
  const isTrue = typeof hasSettled === 'string' ? hasSettled === 'true' : hasSettled;
  return isTrue ? SETTLED_ORDER_STR : '';
};

export const getNumberStr = (num: number | string): string => {
  const returnVal = typeof num === 'number' ? num.toFixed() : num || '0';
  return returnVal;
};

const _getEditStatusConfig = (statusConfig: typeof EDIT_STATUS_CONFIG, item: TItemType): TEditableConfig => {
  const editStatusConfig = statusConfig.find(status => status.item === item);
  return editStatusConfig ? editStatusConfig.config : NOT_EDITABLE_ITEM;
};

export const getEditStatusConfig = partial(_getEditStatusConfig, [EDIT_STATUS_CONFIG]);

const toStatusConfigObject = (config: TStatusConfig) => ({
  [OrderStatus.beforeEvans]: config[EStatus.beforeEvans],
  [OrderStatus.afterEvans]: config[EStatus.afterEvans],
  [OrderStatus.afterShipping]: config[EStatus.afterShipping],
});

export const canEditItem = (orderStatus: TOrderStatus, isManager: boolean, item: TItemType): boolean => {
  const editableConfig = getEditStatusConfig(item);
  const statusConfig = editableConfig[isManager ? EStaff.manager : EStaff.staff];
  return toStatusConfigObject(statusConfig)[orderStatus];
};
