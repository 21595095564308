import { TRequestConfig, TRequestParams } from '../../';
import MOCK_UP_JSON from '../../../mockups/jsonServer/mockup.json';
// import config from '../../../configuration/config';

export { LoginRes } from './LoginRes.class';

export const loginRequestConfig: TRequestConfig = {
  endpoint: '/operator/login',
  method: 'POST',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: getLoginSuccessData(),
    };
  },
  params2request: (params): TRequestParams => {
    return { body: params };
  },
};

function getLoginSuccessData(): string {
  const success = MOCK_UP_JSON.operator.find(v => v.id === 'login');
  return JSON.stringify(success);
}
