import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { actions } from './action-reducer';
import { AppState } from '..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, map } from 'rxjs/operators';
import { getOrderDigests } from '../../services/orders/digest';
import { ApiError } from '../../models/error/api-error';
import { toOrderDigests, toQuery } from '../../helpers/api/converter/order-digest';
import { actions as ErrorHandlerActions } from '../../store/errorHandling/action';
import { orderDetailActions } from '../order-detail';
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { IStaff } from '../_type/staff';
import { IOrderDigestsSearch } from '../../types/orders/digest';

const load: Epic<
  AnyAction,
  | Action<{ error: ApiError; options: any }>
  | Action<Parameters<typeof actions.load.done>[0]>
  | Action<Parameters<typeof actions.load.failed>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.load.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const staff = appStateObj.staff() as IStaff;
      const filter = appStateObj.orderDigistFilter() as IOrderDigestsSearch;
      return { payload, staff, filter };
    }),
    mergeMap(async ({ payload, staff, filter }) => {
      const param = toQuery(filter);
      const res = await getOrderDigests(param, staff)
        .then(toOrderDigests)
        .catch(err => err);
      return { payload, res };
    }),
    mergeMap(({ payload, res }) => {
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({ error: res, options: {} }),
          actions.load.failed({ params: payload, error: {} }),
        ];
      }
      return [actions.load.done({ params: payload, result: res })];
    }),
  );

// 注文確認/修正画面で注文をキャンセルした際に注文検索画面と状態を合わせるため
const updateCancelFlg: Epic<AnyAction, Action<any>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(orderDetailActions.cancelOrder.done),
    map(({ payload }) => {
      return actions.updateCancelFlag({ orderNumber: payload.params.orderNumber });
    }),
  );

export const OrderDigestsEpics = combineEpics(load, updateCancelFlg);
