import React from 'react';
import { connect } from 'react-redux';

import { II18nItem } from '../../i18n/_new/types';
import { AppState } from '../../store';
import { getLanguage } from '../../store/utils/language';

import InputText from '../../components/atoms/InputText/InputText';

function mapStateToProps(state: AppState) {
  return {
    langCode: getLanguage.langCode(state),
  };
}

interface IProps {
  label?: II18nItem;
  placeholder?: II18nItem;
  onValueChanged?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  readOnly?: boolean;
  disabled?: boolean;
  labelPosition?: 'TOP' | 'LEFT';
  styles?: string[];
  className?: string | undefined;
}

export type TInputTextProps = ReturnType<typeof mapStateToProps> & IProps;

export default connect(mapStateToProps)(InputText);
