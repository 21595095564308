import { TMaster } from '../../types';
import { IOption } from '../../store/_type/order';
import { MASTETR_NUDE_DEMENTION } from '../master-thisisforreplaceall/';
import { pick } from '../../helpers/master-lookup';

export const NUDE_DIMENSIONS_CODES = ['7001', '7004', '7005', '7006', '7203', '7011'];
export const NUDE_DIMENSION_NAMES: TMaster[] = pick(NUDE_DIMENSIONS_CODES, MASTETR_NUDE_DEMENTION);

export const NUDE_DIMENSION_SHIRT_CODES = ['7027', '7029', '7030', '7033', '7034', '7200'];
export const NUDE_DIMENSION_SHIRT_NAMES: TMaster[] = pick(NUDE_DIMENSION_SHIRT_CODES, MASTETR_NUDE_DEMENTION);

export const INITIAL_SHIRT_NUDE_SIZES = NUDE_DIMENSION_SHIRT_NAMES.map(name => {
  const option: IOption = {
    optionNumber: name.code,
    optionClassNumber: '',
    optionClassName: '0',
  };
  return option;
});

export const INITIAL_NUDE_SIZES = NUDE_DIMENSION_NAMES.map(name => {
  const option: IOption = {
    optionNumber: name.code,
    optionClassNumber: '',
    optionClassName: '0',
  };
  return option;
});
