import format from 'date-fns/format';
import { Nullable, Optional } from '../../../types';
import zip from 'ramda/es/zip';
import repeat from 'ramda/es/repeat';

export const initialDataNewOrderRequestItemPartsOptions = {
  optionNumber: '',
  optionName: '',
  optionClassNumber: '',
  optionClassName: '',
  optionMark: '', // blank
};
export type TNewOrderRequestItemPartsOptions = typeof initialDataNewOrderRequestItemPartsOptions;

export const initialDataNewOrderRequestItemParts = {
  partsNumber: '',
  partsName: '',
  modelCode: '',
  modelName: '',
  modelPattern: '',
  optionPattern: '',
  sizeCode: '',
  options: [] as TNewOrderRequestItemPartsOptions[],
};
export type TNewOrderRequestItemParts = typeof initialDataNewOrderRequestItemParts;

export const initialDataNewOrderRequestItem = {
  currentCopyNumber: 1, // fixed value
  serialYear: 0, // blank (set this in server side)
  serialNumber: 0, // blank (set this in server side)
  itemOrderPrice: 0,
  optionPrice: 0,
  itemOrderAmount: 0,
  itemPriceTax: 0,
  itemOrderAmountTaxin: 0,
  retailPrice: 0,
  itemName: '',
  item: '',
  itemType: '',
  brand: '',
  make: '',
  clothBrandCode: '',
  clothSeason: '',
  clothCode: '',
  vendorClothNumber: '',
  textileNumber: '',
  productSeason: '',
  productNumber: '',
  requiredScale: 0,
  personalOrderColorCode: '',
  personalOrderColorName: '',
  design: '',
  deliveryKind: '6', // fixed value
  compositionFront: '',
  compositionBack: '',
  stockPlaceCode: '',
  stockPlaceName: '',
  pearlToneCode: '',
  pearlToneName: '',
  modelPattern: '9999999999', // fixed value
  optionPattern: '9999999999', // fixed value
  departmentProductNumber: '', // blank
  hurrying: '0',
  modified: '0', // fixed value (fixed when new order)
  note: '',
  parts: [] as TNewOrderRequestItemParts[],
};
export type TNewOrderRequestItem = typeof initialDataNewOrderRequestItem;

export const initialDataNewOrderRequest = {
  companyCode: '01', // fixed value
  companyName: 'KASHIYAMA the Smart Tailor', // fixed value
  branchCode: '65', // fixed value
  orderDate: format(new Date(), 'yyyyMMdd'),
  lotNumber: '',
  contactNumber: '', // blank
  shopName: '',
  shopCode: '',
  staffName: '',
  staffCode: '',
  cutterNameKana: '',
  deliveryDateShop: '',
  deliveryDateArrival: '',
  deliveryDateGuest: '',
  timeZoneCode: '',
  timeZoneName: '',
  paymentDate: '',
  section_1: '', // blank
  sectionCode_1: '', // blank
  section_2: '', // blank
  sectionCode_2: '', // blank
  section_3: '', // blank
  sectionCode_3: '', // blank
  clientStaffName: '',
  customerFamilyNameKana: '',
  customerGivenNameKana: '',
  // customerBirthday: '', // blank (資料では入力必須のような記載だが、入れなくて良いことになった)
  customerFamilyNameKanji: '',
  customerGivenNameKanji: '',
  memberscardNumber: '',
  distributionKind: '', // blank
  deliveryMethod: '',
  // customerPostalCode: '', // 利用しない（shippingを利用）
  // customerPhoneNumber: '', // 利用しない（shippingを利用）
  // customerState: '', // 利用しない（shippingを利用）
  // customerCity: '', // 利用しない（shippingを利用）
  // customerStreet: '', // 利用しない（shippingを利用）
  // customerResidence: '', // 利用しない（shippingを利用）
  customerMailAddress: '',
  shippingPostalCode: '',
  shippingPhoneNumber: '',
  shippingState: '',
  shippingCity: '',
  shippingStreet: '',
  priceTagNumber: '', // blank
  note: '', // blank
  parentSerialYear: 0, // blank (set this in server side)
  parentSerialNumber: 0, // blank (set this in server side)
  shippingCost: 0,
  couponUsePoint: 0,
  usePoint: 0,
  pricePayment: 0, // fixed value
  // マイナスになるとエラーになるので送信しない OPS_ORDERSYS-747 【本番運用】ポイントのCMシステム連携について
  priceNetTotal: undefined as Optional<number>,
  priceTaxin: 0,
  taxKind: '1', // fixed value
  taxRate: 10,
  orderPaymentMethod: '',
  orderPaymentMethodName: '',
  paymentStatusCode: '', // blank
  paymentStatusName: '', // blank
  pbKindCode: '', // blank
  pbKindName: '', // blank
  // TODO: OEM: deliveryRateいらない
  // deliveryRate: null as Nullable<number>, // memo: 訪問販売先が訪問の場合、訪問販売先情報取得で取得した値を設定、それ以外はnullを設定する
  cKind: '', // blank
  productKind: '',
  copyNumbers: 1, // fixed value
  relationSerialNumber: '', // blank
  introducerName: '',
  introducerCode: '',
  favoriteCustomerName: '',
  favoriteCustomerCode: '',
  invoiceFlag: '',
  scSalesEventId: '', // sc販売会ID
  scSalesEventName: '', // sc販売会名
  scCorporationId: '', // sc法人ID
  orderCountryCode: '392', // fixed value
  orderCountryName: 'JPN', // fixed value
  shippingCountryCode: '392', // fixed value
  shippingCountryName: 'JPN', // fixed value
  // languageCode: 'ja', // TODO: 設定したら500エラーになった
  items: [] as TNewOrderRequestItem[],
};
export type TNewOrderRequest = typeof initialDataNewOrderRequest;

const ORDER_REQUEST_PRICE_PROPERTIES: Array<keyof TNewOrderRequest> = [
  'shippingCost',
  'couponUsePoint',
  'usePoint',
  'priceTaxin',
  // マイナスになるとエラーになるので送信しない OPS_ORDERSYS-747 【本番運用】ポイントのCMシステム連携について
  // 'priceNetTotal',
  'pricePayment',
];

const ORDER_REQUEST_ITEM_PRICE_PROPERTIES: Array<keyof TNewOrderRequestItem> = [
  'itemOrderPrice',
  'optionPrice',
  'itemOrderAmount',
  'itemPriceTax',
  'itemOrderAmountTaxin',
  'retailPrice',
];

const NEW_ORDER_REQUEST_PRICE_PROPERTIES = [...ORDER_REQUEST_PRICE_PROPERTIES, ...ORDER_REQUEST_ITEM_PRICE_PROPERTIES];

export const TOTAL_PRICE_ZERO_CONFIG = zip(
  NEW_ORDER_REQUEST_PRICE_PROPERTIES,
  repeat(0, NEW_ORDER_REQUEST_PRICE_PROPERTIES.length),
);

export const INVOICE_FLAG = {
  noNeed: '0',
  need: '1',
};
