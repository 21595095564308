/**
 * Cookie, LocalStorage, SessionStorage などの操作をこのファイルに集約する
 */

import Cookies from 'js-cookie';
import { rawStrArr2EnumLikeObj } from '../../lib/utils';

const TOKEN_KEY = 'TOKEN';
const PROGRESS_KEY = 'progressKey';

const tmp = rawStrArr2EnumLikeObj([PROGRESS_KEY, TOKEN_KEY]);
type TCookeyKey = keyof typeof tmp;

const ALL_KEYS: TCookeyKey[] = [PROGRESS_KEY, TOKEN_KEY];

export const setCookie = (key: TCookeyKey, val: string | object) => Cookies.set(key, val, { path: '' });
export const getCookie = (key: TCookeyKey) => Cookies.get(key);
export const removeCookie = (key: TCookeyKey) => Cookies.remove(key, { path: '' });

export const removeAllCookies = () => ALL_KEYS.forEach(removeCookie);
