import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Footer from '../../organisms/Footer/Footer';

import { IFooterButton } from '../../../types/footer';
import { langResource } from '../../../i18n/_new/resources';
import { TPageContainerProps } from '../../../containers/pages/SettlementEndPage';

import styles from './SettlementEndPage.module.scss';
import Button from '../../atoms/Button/Button';

type TProps = TPageContainerProps & RouteComponentProps;

const SettlementEndPage: React.FC<TProps> = props => {
  return <React.Fragment>{utility(props)}</React.Fragment>;
};

function utility(props: TProps) {
  const clearCurrentProgress = () => props.clearCurrentProgress();
  return (
    <React.Fragment>
      <div className={styles.content}>
        <div className={styles.text}>
          ご注文を受け付けました。
        </div>
        <Button styles={['home', 'size-xl']} textObj={langResource.footer.home} onClick={clearCurrentProgress} />
      </div>
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [];
}

export default withRouter(SettlementEndPage);
