import React, { Component } from 'react';

// dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Form
import Button from '../../atoms/Button/Button';
import CheckBox from '../../atoms/CheckBox/CheckBox';

import I18TextContainer from '../../../containers/I18Text/I18Text';
import { IBaseDialogProps } from '../../../types/dialog';
import { ICustomer } from '../../../store/_type/customer';
import { IStaff } from '../../../store/_type/staff';
import { langResource } from '../../../i18n/_new/resources/index';
import { HeaderEvents } from '../../../views/events/Header';

interface ILogoutDialogProps extends IBaseDialogProps {
  customer: ICustomer;
  staff: IStaff;
  onLogoutCustomer: typeof HeaderEvents.clearCurrentProgress;
  onLogoutStaff: typeof HeaderEvents.logout;
}

interface IState {
  isCustomer: boolean;
  isStaff: boolean;
}

class LogoutDialog extends Component<ILogoutDialogProps, IState> {
  constructor(props: ILogoutDialogProps) {
    super(props);
    this.state = {
      isCustomer: false,
      isStaff: false,
    };
  }

  componentDidMount() {
    this.setState({ ...this.state, isCustomer: true, isStaff: true });
  }

  render() {
    return (
      <Dialog
        className="dialog logout-dialog"
        open={this.props.hasOpen}
        onClose={this.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <I18TextContainer textObj={langResource.header.logout} />
        </DialogTitle>
        <DialogContent className="dialog__contents">
          <div className="content-wrapper">
            <DialogContentText className="dialog__contents__text">
              <I18TextContainer textObj={langResource.loginPage.logoutDialogText} />
            </DialogContentText>
            {this.renderFormGroup()}
          </div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button onClick={this.onClose} styles={['white']} textObj={langResource.others.cancel} />
          <Button onClick={this.onLogout} styles={['red']} textObj={langResource.loginPage.logoutButtonLabel} />
        </DialogActions>
      </Dialog>
    );
  }

  renderFormGroup() {
    return (
      <React.Fragment>
        <div className="mt30">
          <CheckBox
            checked={this.state.isCustomer}
            onChange={this.handleChange('isCustomer')}
            value="isCustomer"
            label={this.customerLabel}
            isTranslate={false}
          />
        </div>
        <div className="mt30">
          <CheckBox
            checked={this.state.isStaff}
            onChange={this.handleChange('isStaff')}
            value="isStaff"
            label={this.staffLabel}
            isTranslate={false}
          />
        </div>
      </React.Fragment>
    );
  }

  onClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.props.onHandleClose();
  };

  onLogout = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (this.state.isStaff) {
      this.props.onLogoutStaff();
    } else if (this.state.isCustomer) {
      this.props.onLogoutCustomer();
    }

    this.props.onHandleClose();
  };

  handleChange = (name: string) => (event: { target: { checked: boolean } }) => {
    if (name === 'isStaff') {
      this.setState({ isStaff: event.target.checked });
    } else {
      this.setState({ isCustomer: event.target.checked });
    }
  };

  get customerLabel() {
    const { customerFamilyNameKanji } = this.props.customer;
    const label = 'お客様（読み込み情報）';
    if (customerFamilyNameKanji === '') {
      return label;
    }
    return `${label} ${customerFamilyNameKanji} 様`;
  }

  get staffLabel(): string {
    const staffCode = this.props.staff.staffCode;
    return `担当者アカウント ${staffCode}`;
  }
}

export default LogoutDialog;
