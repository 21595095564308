import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { Dispatch, bindActionCreators } from 'redux';

import { getOrderDigests } from '../../../store/order-digest';
import { get as getOrder } from '../../../store/order';

import OrderDigestsSearchPage from '../../../components/pages/OrderDigestsSearchPage/OrderDigestsSearchPage';
import { OrderDigestsSearchPageEvents } from '../../../views/events/OrderDigestsSearchPage';
import { getStaff } from '../../../store/staff';
import { okCancelDialogActions } from '../../../store/utils/dialog/ok-cancel';
import { mapCommonPageDispatchToProps } from '../CommonPage';

function mapStateToProps(state: AppState) {
  return {
    isEdit: getOrder.isEdit(state),
    ordersDigests: getOrderDigests.digests(state),
    filter: getOrderDigests.filter(state),
    isLoading: getOrderDigests.isLoading(state),
    staff: getStaff.staff(state),
    isManager: getStaff.isManager(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(OrderDigestsSearchPageEvents, dispatch),
    onShowOkCancelDialog: okCancelDialogActions.show.dispatcher(dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type TPageContainerProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderDigestsSearchPage);
