import React from 'react';

import styles from './SJISByteCounter.module.scss';

import { countSJISBytes } from '../../../helpers/common/count-string';

type TProps = {
  word: string;
  max?: number;
  min?: number;
};

const SJISByteCounter: React.FC<TProps> = ({ word, max = 0, min = 0 }) => {
  const count = countSJISBytes(word);
  return (
    <React.Fragment>
      <div id={styles.root}>{`${count} / ${max}`}</div>
    </React.Fragment>
  );
};

export default SJISByteCounter;
