import { thunkActionErrorHandler } from '../../../helpers/error-handler';
import { thunkActionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { IShipping, TPartialShipping } from '../../_type/order';
import { orderActions } from '../../order';
import { getPartialOrder } from '../../order/helper/conv-partial-order';
import { toShipping } from '../../../helpers/customer';
import { ICustomer } from '../../_type/customer';
import { actions as itemActions } from '../../order/item/actions';
import { actions as AddressActions } from '../../order/address/actions';
import { isValidPostalCode } from '../../../helpers/common/validate';

export type TLoadInitializeParam = {
  orderNumber: string;
  customer: ICustomer;
};

type TBaseParam = {
  orderNumber: string;
};

type TChangeValueParam = TBaseParam & {
  key: keyof Omit<IShipping, 'customerMailAddress'>;
  value: string;
};

type TChangeInputTwoValueParam = TBaseParam & {
  key: keyof Pick<IShipping, 'customerMailAddress'>;
  index: number;
  value: string;
  preValue: string;
};

type TTogleSameOrderOneParam = TBaseParam & {
  hasState: boolean;
};
const actionTitle = 'address page';
// asyncAction
const asyncAc = thunkActionObjectCreatorFactory();
export const addressPageActions = {
  loadAddress: asyncAc<TLoadInitializeParam, any>(`[${actionTitle}] loadInitialize`, async (payload, dispatch) => {
    try {
      const { orderNumber, customer } = payload;
      const shipping = toShipping(customer);
      const order = getPartialOrder.fromShipping(shipping);
      orderActions.updateOrder.dispatcher(dispatch)({ orderNumber, order });

      if (isValidPostalCode(shipping.shippingPostalCode || '')) {
        dispatch(
          AddressActions.updatePostalCodeAddress({ orderNumber, postalCode: String(shipping.shippingPostalCode) }),
        );
        dispatch(itemActions.loadShortestDeliveryDate({ orderNumber }));
      }
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
  changeValue: asyncAc<TChangeValueParam, any>(`[${actionTitle}] changeValue`, async (payload, dispatch) => {
    try {
      const { orderNumber, key, value } = payload;
      const updated: TPartialShipping = {
        [key]: value,
      };

      // 以下更新
      const order = getPartialOrder.fromShipping(updated);
      orderActions.updateOrder.dispatcher(dispatch)({ orderNumber, order });

      if (key !== 'shippingPostalCode') {
        // 郵便番号でない場合、以降の処理を行わない
        return;
      }

      // 郵便番号を更新時、都道府県と市区町村を初期化する
      const initial = getPartialOrder.fromShipping({
        shippingState: '',
        shippingCity: '',
      });
      orderActions.updateOrder.dispatcher(dispatch)({ orderNumber, order: initial });

      // 郵便番号が有効な場合 =====
      if (isValidPostalCode(value)) {
        // 郵便番号から住所を取得する
        dispatch(AddressActions.updatePostalCodeAddress({ orderNumber, postalCode: value }));
        // 郵便番号が更新されたので納期を再取得する
        dispatch(itemActions.loadShortestDeliveryDate({ orderNumber }));
      }
    } catch (ex) {
      return thunkActionErrorHandler(ex, dispatch);
    }
  }),
  changeInputTwoValue: asyncAc<TChangeInputTwoValueParam, any>(
    `[${actionTitle}] changeInputTwoValue`,
    async (payload, dispatch) => {
      try {
        const { orderNumber, key, value, preValue, index } = payload;
        const separater = key === 'customerMailAddress' ? '@' : '-';

        const getNewValue = (inputValue: string, idx: number) => {
          // idx は、0 or 1
          const tmp = preValue.split(separater);
          if (tmp.length < 2) {
            tmp.push('');
          }
          tmp[idx] = inputValue.replace('@', ''); // TODO: 何故、@を削除する必要が？？
          const newVal = `${tmp[0]}${separater}${tmp[1]}`;
          return newVal === separater ? '' : newVal;
        };
        const newValue = getNewValue(value, index);

        // 以下更新
        const updated: TPartialShipping = {
          [key]: newValue,
        };

        const order = getPartialOrder.fromShipping(updated);
        orderActions.updateOrder.dispatcher(dispatch)({ orderNumber, order });
      } catch (ex) {
        return thunkActionErrorHandler(ex, dispatch);
      }
    },
  ),
  togleSameOrderOne: asyncAc<TTogleSameOrderOneParam, any>(
    `[${actionTitle}] togleSameOrderOne`,
    async (payload, dispatch) => {
      try {
        const { orderNumber, hasState } = payload;
        const updated: TPartialShipping = {
          isSameOrderOne: hasState,
        };
        const order = getPartialOrder.fromShipping(updated);
        orderActions.updateOrder.dispatcher(dispatch)({ orderNumber, order });
      } catch (ex) {
        return thunkActionErrorHandler(ex, dispatch);
      }
    },
  ),
};
