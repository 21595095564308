import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { OrderDetailPageEvents } from '../views/events/OrderDetailPage';
import { map } from 'rxjs/operators';
import { orderDetailActions } from '../store/order-detail/action-reducers';
import { orderActions, orderDeleteActions } from '../store/order';

const clearDetail: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.clearDetail),
    map(({ payload }) => {
      return orderDeleteActions.deleteEditOrders();
    }),
  );

const editDetail: Epic<AnyAction, Action<{ orderNumber: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.editDetail),
    map(({ payload }) => {
      const { orderNumber, path } = payload;
      return orderDetailActions.editOrder.started({ orderNumber, path });
    }),
  );

const cancelOrder: Epic<AnyAction, Action<{ orderNumber: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.cancelOrder),
    map(({ payload }) => {
      return orderDetailActions.cancelOrder.started({ orderNumber: payload.orderNumber });
    }),
  );

const loadOrderDetail: Epic<AnyAction, Action<{ orderNumber: string }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.loadDetail),
    map(({ payload }) => {
      return orderDetailActions.loadOrderDetail.started({ orderNumber: payload.orderNumber });
    }),
  );

const sendOrder: Epic<AnyAction, Action<Parameters<typeof orderDetailActions.sendModifyOrder.started>[0]>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.sendOrder),
    map(({ payload }) => {
      return orderDetailActions.sendModifyOrder.started({});
    }),
  );

const setIsEdit: Epic<AnyAction, Action<boolean>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.setIsEdit),
    map(({ payload }) => {
      return orderActions.setIsEdit._action(payload.isEdit);
    }),
  );

const setPending: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDetailPageEvents.setPending),
    map(({ payload }) => {
      return orderDetailActions.pendingON();
    }),
  );

export const OrderDetailPageListeners = combineEpics(
  cancelOrder,
  loadOrderDetail,
  editDetail,
  clearDetail,
  sendOrder,
  setIsEdit,
  setPending,
);
