import React from 'react';

import Label from '../Label/Label';
import './InputText.scss';

import { langResource } from '../../../i18n/_new/resources';
import { TInputTextProps } from '../../../containers/components/InputText';
import { getLabel } from '../../../i18n/_new/helper';

const InputText: React.FC<TInputTextProps> = ({
  langCode,
  label = langResource.components.inputText.defaultLabel,
  onValueChanged,
  onClick,
  onBlur,
  readOnly = false,
  disabled = false,
  labelPosition = 'TOP',
  styles = [''],
  placeholder,
  value = '',
  className = '',
}) => {
  return (
    <div className={getLabelInputClassName(labelPosition)}>
      <Label position={labelPosition} textObj={label} />
      {placeholder ? inputText(getLabel(langCode, placeholder)) : inputText()}
    </div>
  );

  function inputText(placeholderValue: string = '') {
    return (
      <input
        disabled={disabled}
        readOnly={readOnly}
        type="text"
        placeholder={placeholderValue}
        onChange={onValueChanged}
        value={value}
        onClick={onClick}
        onBlur={onBlur}
        className={getBtnClassName('input-text__log', styles, className)}
      />
    );
  }

  function getBtnClassName(
    blockClassName = 'input-text__log',
    blockStyles: string[] = [],
    plainClassName: string,
  ): string {
    const classNames = [
      blockClassName,
      ...blockStyles.filter(v => !!v).map(v => `${blockClassName}--${v}`),
      plainClassName,
    ];
    return classNames.filter(v => !!v).join(' ');
  }

  function getLabelInputClassName(style: 'LEFT' | 'TOP' = 'LEFT'): string {
    const left = 'input-text';
    const top = 'input-text__top';
    return style === 'TOP' ? top : left;
  }
};

export default InputText;
