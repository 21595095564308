import { TRequestConfig, TRequestParams } from '../../';
// import config from '../../../configuration/config';

export { DeliveryRes } from './DeliveryRes.class';

export const deliveryRequestConfig: TRequestConfig = {
  endpoint: '/customizesystemproxy/delivery',
  method: 'POST',
  useMock: false,
  mockFunc: () => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return { query: params ? params.query : {}, body: params ? params.body : {} };
  },
};
