import { StandardSizeRes } from '../../../api/items/standard-size';
import { IOption } from '../../../store/_type/order';

export function toStandardSizes(res: StandardSizeRes): IOption[] {
  const data = res.query('standardSizes');
  const standardSizes = Array.from({ length: data.length }).map((v, i) => toOption(res, i, 'standardSizes'));
  return standardSizes;
}

function toOption(res: StandardSizeRes, index: number, parentExpression: string): IOption {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const option: IOption = {
    optionNumber: String(getValue<string>('measurementNumber')),
    optionClassNumber: '',
    optionClassName: String(getValue<string>('measurementValue')),
  };
  return option;
}
