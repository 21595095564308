import React from 'react';

import styles from './DesignOptionSpecialList.module.scss';

import DesignOptionListItem from '../DesignOptionListItem/DesignOptionListItem';
import { IItem } from '../../../store/_type/order';
import { IOptionPattern, IClothProduct } from '../../../store/_type/lookups';
import { IOptionListItem } from '../../../types/option';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  optionListItems: IOptionListItem[];
  optionPatterns: IOptionPattern[];
  onSelectOption: typeof DesignSelectionPageEvents.onSelectOption;
  onSelectOptionClass: typeof DesignSelectionPageEvents.onSelectOptionClass;
}

const DesignOptionSpecialList: React.FC<IProps> = (props: IProps) => {
  const { optionPatterns, item, optionListItems } = props;
  const { partsIndex } = item.design.selecting;

  return (
    <React.Fragment>
      <div className={styles.list}>
        {optionPatterns.map((pattern, index) => {
          const listItem = optionListItems.find(v => v.optionNumber === pattern.optionNumber);
          if (!listItem) {
            return null;
          }

          return (
            <div className={styles.item} key={index}>
              <DesignOptionListItem
                {...props}
                partsIndex={partsIndex}
                optionNumber={pattern.optionNumber}
                optionName={pattern.optionName}
                isSpecial={true}
                // selectbox
                selectBoxState={listItem.selectBoxState}
                // paper
                paperState={listItem.paperState}
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DesignOptionSpecialList;
