import { TOrderItemCode } from '../../lookups/master-thisisforreplaceall/item-code';
import { StockRes, stockRequestConfig } from '../../api/customizesystemproxy/stock';
import { fetchApi } from '../../api/_common/fetcher';

export const getStockFlag = async (params: {
  itemCode: TOrderItemCode;
  brandCode: string;
  clothBrandCode: string;
  productSeason: string;
  colorCode: string;
  productNumber: string;
  stockPlaceCode: string;
}): Promise<string> => {
  const req = {
    query: {
      ...params,
    },
  };
  const res = await fetchApi(StockRes, stockRequestConfig, req);
  return res.query('stockFlag');
};
