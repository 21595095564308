import { Optional } from '../../types';
import { IOrder } from '../../store/_type/order';
import {
  IAvailableOption,
  IClothProduct,
  IAvailableOptionWithPartsIndexAndSelectedInfo,
} from '../../store/_type/lookups';
import {
  editedAvailableOptionsSelector,
  availableOptionsSelector,
  clothProductsSelector,
} from '../../store/lookups/object-selector';

/** 渡したキーの前後比較をして、完全一致するか？ */
const isSameObjValue = <T>(current: T, prev: T, keys: Array<keyof T>): boolean =>
  keys.every(key => current[key] === prev[key]);

/**
 * チェックする項目を返す
 * （最終的にはどの項目が一致しなかったのかをユーザーに表示したい）
 */
const getValidateItems = (order: IOrder, products: IClothProduct[], availableOptions: IAvailableOption[]) => {
  // item
  const { itemCode, cloth, design, pieces, subCategoryCode } = order.item;

  // cloth
  const { brandCode, clothModelCode } = cloth;

  // products
  const productInfo = clothProductsSelector({
    itemCode,
    brandCode,
    products,
    subCategoryCode,
  }).nearestProduct;

  const { retailPriceTaxin } = productInfo;

  // options
  const eaos = editedAvailableOptionsSelector(
    availableOptionsSelector(availableOptions).withSelectedInfo(
      pieces,
      design.designParts,
      design.selecting,
      order.item.categoryCode,
      clothModelCode,
      brandCode,
    ),
  );

  const optionTotal = eaos.totalOptionPriceTaxin();
  const editedAvailableOptions = pieces.map(({ partsNumber }) => eaos.editiedAvailableOptionMergedSpare(partsNumber));
  return { brandCode, itemCode, retailPriceTaxin, optionTotal, clothModelCode, editedAvailableOptions };
};

/**
 * modelとmodelCodeの前後比較を行う
 * （パーツは追加削除不可なので順番は一緒のはず）
 */
const isValidPartsInfo = (
  current: Array<Optional<IAvailableOptionWithPartsIndexAndSelectedInfo>>,
  prev: Array<Optional<IAvailableOptionWithPartsIndexAndSelectedInfo>>,
): boolean => {
  return current.every((cur, i) => {
    const pre = prev[i];
    return !cur || !pre
      ? false // 基本ない
      : isSameObjValue(cur, pre, ['modelCode', 'modelPatternCode', 'optionPatternCode']);
  });
};

/**
 * 修正したオーダーと修正前のオーダーを比較して、修正できない項目が変更されていた場合にfalseを返却する
 */
export const isValidModyfiedOrder = (
  current: IOrder,
  prev: IOrder,
  products: IClothProduct[],
  availableOptions: IAvailableOption[],
): boolean => {
  const currentItem = getValidateItems(current, products, availableOptions);
  const prevItem = getValidateItems(prev, products, availableOptions);
  return (
    isSameObjValue(currentItem, prevItem, [
      'brandCode',
      'clothModelCode',
      'itemCode',
      'optionTotal',
      'retailPriceTaxin',
    ]) && isValidPartsInfo(currentItem.editedAvailableOptions, prevItem.editedAvailableOptions)
  );
};
