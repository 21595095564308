import React from 'react';

import styles from './ClothConfirmation.module.scss';

import Button from '../../../atoms/Button/Button';
import I18TextContainer from '../../../../containers/I18Text/I18Text';

import { ERouterPath } from '../../../../types';
import { langResource } from '../../../../i18n/_new/resources';
import { TClothConfirmation } from '../../../../types/new-store/components';
import { II18nItem } from '../../../../i18n/_new/types';
import { KR_BRANDS } from '../../../../lookups/item-thisisforreplaceall';

interface IProps {
  orderNumber: string;
  cloth: TClothConfirmation;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
  isDisabledEdit?: boolean;
  isFromOrderDetail?: boolean;
}

type TProps = IProps;

const ClothConfirmation: React.FC<TProps> = (props: TProps) => {
  const { orderNumber, onEditOrder, isFromOrderDetail } = props;
  const {
    clothCode,
    brandCode,
    clothModelCode,
    vendorClothNumber,
    personalorderColor,
    design,
    stockPlace,
    compositionFront,
    compositionBack,
    requiredScale,
  } = props.cloth;

  return (
    <div id={styles.container}>
      <div className={styles.row}>
        <div className={styles.left}>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.clothCode} />
            </dt>
            <dd>{clothCode}</dd>
          </dl>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.textileNo} />
            </dt>
            <dd>{vendorClothNumber}</dd>
          </dl>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.clothColor} />
            </dt>
            <dd>{personalorderColor}</dd>
          </dl>
        </div>
        <div className={styles.right}>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.brand} />
            </dt>
            <dd>{brandCode}</dd>
          </dl>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.model} />
            </dt>
            <dd>{clothModelCode}</dd>
          </dl>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.clothDesign} />
            </dt>
            <dd>{design}</dd>
          </dl>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          {renderCompositions(langResource.confirmationPage.compositionFront, compositionFront)}
        </div>
        <div className={styles.right}>
          {renderCompositions(langResource.confirmationPage.compositionBack, compositionBack)}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          <dl>
            <dt>
              <I18TextContainer textObj={langResource.confirmationPage.stockPlace} />
            </dt>
            <dd>{stockPlace}</dd>
          </dl>
        </div>
        {/* MEMO: 要尺はKR以外は送信前にないので表示しない */}
        {!requiredScale ? null : (
          <div className={styles.right}>
            <dl>
              <dt>
                <I18TextContainer textObj={langResource.confirmationPage.scale} />
              </dt>
              <dd>{requiredScale}</dd>
            </dl>
          </div>
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={moveToClothPage}
          styles={['size-s']}
          textObj={langResource.confirmationPage.editBtn}
          disabled={props.isDisabledEdit || (isFromOrderDetail && !KR_BRANDS.includes(brandCode))}
        />
      </div>
    </div>
  );

  function renderCompositions(textObj: II18nItem, compositions: string[]) {
    return (
      <dl>
        <dt>
          <I18TextContainer textObj={textObj} />
        </dt>
        <dd className={styles.group}>
          {compositions.map((v, i) => (
            <p key={i}>{v}</p>
          ))}
        </dd>
      </dl>
    );
  }

  function moveToClothPage() {
    onEditOrder(ERouterPath.clothSelection, orderNumber);
  }
};

export default ClothConfirmation;
