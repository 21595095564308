import { AppState } from '../..';
import { IOrderFeature } from '../../order';
import { createSelector } from 'reselect';

const featureSelector = (state: AppState): IOrderFeature => state;

/**
 * IOrderStateを取得する
 */
export const orderState = createSelector(featureSelector, state => state.order);
