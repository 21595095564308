import { combineEpics, Epic } from 'redux-observable';
import { couponInfoDialogActions } from './action-reducers';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { actions as ErrorHandlerActions } from '../../../../store/errorHandling/action';
import { Action, AnyAction } from 'typescript-fsa';
import { appStateSelector } from '../../../../helpers/object-selector/app-state';
import { map, mergeMap } from 'rxjs/operators';
import { ApiError } from '../../../../models/error/api-error';
import { AppState } from '../../..';
import { toCoupon } from '../../../../helpers/api/converter/coupon';
import { WrapAction } from '../../../_type';
import { TCouponInfo } from '../../../../types/coupon';
import { getCouponDetail } from '../../../../services/coupon/detail';

const load: Epic<
  AnyAction,
  | Action<{ error: ApiError; options: any }>
  | WrapAction<typeof couponInfoDialogActions.load.done>
  | WrapAction<typeof couponInfoDialogActions.load.failed>
  | Action<TCouponInfo>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(couponInfoDialogActions.load.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const couponId = appStateObj.staff()?.couponId;
      return { payload, couponId };
    }),
    mergeMap(async ({ payload, couponId }) => {
      const res = await getCouponDetail(couponId || '')
        .then(toCoupon)
        .catch(err => err);
      return { payload, res };
    }),
    mergeMap(({ payload, res }) => {
      if (res instanceof ApiError) {
        if (res.statusCode === 404) {
          return [
            ErrorHandlerActions.apiError({ error: res, options: { contents: '該当のクーポンがありません。' } }),
            couponInfoDialogActions.load.failed({ params: payload, error: {} }),
          ];
        }
        return [
          ErrorHandlerActions.apiError({ error: res, options: { contents: 'クーポン情報の取得に失敗しました。' } }),
          couponInfoDialogActions.load.failed({ params: payload, error: {} }),
        ];
      }
      return [
        couponInfoDialogActions.load.done({
          params: payload,
          result: res,
        }),
      ];
    }),
  );

export const CouponInfoEpics = combineEpics(load);
