import actionCreatorFactory from 'typescript-fsa';
import { ERouterPath } from '../../types';

const actionCreator = actionCreatorFactory('- OrderDetailPage ');

export const OrderDetailPageEvents = {
  clearDetail: actionCreator<void>('clearDetail'),
  editDetail: actionCreator<{ orderNumber: string; path: ERouterPath }>('editDetail'),
  cancelOrder: actionCreator<{ orderNumber: string }>('cancelOrder'),
  loadDetail: actionCreator<{ orderNumber: string }>('loadDetail'),
  sendOrder: actionCreator<void>('sendOrder'),
  setIsEdit: actionCreator<{ isEdit: boolean }>('setIsEdit'),
  setPending: actionCreator<void>('setPending'),
};
