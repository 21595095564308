import { Actions, ActionType } from './actions';
import { resolvePath } from '../../../helpers/common/path';
import { ERouterPath } from '../../../types';

export interface INavigationState {
  currentPath: string;
}

const initialState: INavigationState = {
  currentPath: resolvePath(ERouterPath.home),
};

export function navigationReducer(state: INavigationState = initialState, action: Actions): INavigationState {
  switch (action.type) {
    case ActionType.SET_CURRENT_PATH:
      return {
        currentPath: action.payload,
      };
  }

  return state;
}
