import { IPiece } from '../../_type/order';
import { TPartsNumber, EParts } from '../../../lookups/master-thisisforreplaceall';
import { distinctArray, by } from '../../../helpers';

/**
 * IPiece[] からデータを取得する関数郡
 */
export const piecesSelector = (state: IPiece[]) => new PiecesSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class PiecesSelector {
  constructor(private state: IPiece[]) {}

  public indexArr() {
    return this.state.map(v => v.index);
  }
  public partsNumberArr() {
    return this.state.map(v => v.partsNumber);
  }
  public pieceByIndex(partsIndex: string) {
    return this.state.find(v => v.index === partsIndex);
  }
  public pieceByPartsNumber(partsNumber: TPartsNumber) {
    return this.state.find(v => v.partsNumber === partsNumber);
  }

  public index2partsNumber(index: string) {
    const piece = this.pieceByIndex(index);
    return !piece ? undefined : piece.partsNumber;
  }
  public partsNumber2index(partsNumber: TPartsNumber) {
    const piece = this.pieceByPartsNumber(partsNumber);
    return !piece ? undefined : piece.index;
  }

  public distinctPartsNumbers() {
    const parts = this.state.map(v => v.partsNumber).sort((a, b) => +a - +b);
    return distinctArray(parts);
  }

  public distinctPieces() {
    const distinctPartsNumbers = this.distinctPartsNumbers();
    return distinctPartsNumbers
      .map(
        partsNumber =>
          this.state.find(by('partsNumber')(partsNumber)) || { index: '00', partsNumber: EParts.jaket as TPartsNumber },
      )
      .filter(v => v.index !== '00');
  }
}
