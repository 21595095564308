export const addressPage = {
  /** お届け先 */
  deliveryPlace: { jp: 'お届け先' },
  /** 〒 */
  zipCode: { jp: '〒' },
  /** ご住所 */
  address: { jp: 'ご住所' },
  /** 都道府県 */
  states: { jp: '都道府県' },
  /** 市区町村 */
  city: { jp: '市区町村' },
  /** 番地 */
  street: { jp: '番地・マンション名' },
  // /** 未使用：マンション名  */
  // residence: { jp: 'マンション名' },
  /** お名前（フリガナ） */
  custermerNameKana: { jp: 'お名前（フリガナ）' },
  /** OEM 会員番号 */
  memberscardNumber: { jp: '会員番号' },
  /** OEM 照合番号 */
  lotNumber: { jp: '照合番号' },
  /** OEM 担当者番号 */
  cutterNameKana: { jp: '担当者番号' },
  /** お名前 */
  custermerName: { jp: 'お名前' },
  /** ご連絡先 */
  contactInfo: { jp: 'ご連絡先' },
  /** 電話番号 */
  phoneNumber: { jp: '電話番号' },
  /** E-mail */
  email: { jp: 'E-mail' },
  /** お届け日時 */
  deliveryDate: { jp: 'お届け日時' },
  /** 日時 */
  dayTime: { jp: '日時' },
  /** お渡し方法 */
  deliveryMethod: { jp: 'お渡し方法' },
  /** 配送料 */
  deliveryFee: { jp: '配送料' },
  /** 個別に指定 */
  orderOtherPlace: { jp: '個別に指定' },
  /** オーダー1と同じ */
  orderOneSamePlace: { jp: 'オーダー1と同じ' },
  /** アイテム */
  item: { jp: 'アイテム' },
  /** 生地品番 */
  clothCode: { jp: '生地品番' },
  /** ブランド */
  brand: { jp: 'ブランド' },
};
