import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { ProjectSelectorDialogEvents } from '../views/events/ProjectSelectorDialog';
import { projectSelectorDialogActions } from '../store/utils/dialog/project-selector';
import { TProjectFilter, TScProject } from '../types/project';
import { WrapAction } from '../store/_type';
import { scProjectActions } from '../store/sc-project';

const setFilter: Epic<AnyAction, Action<TProjectFilter>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ProjectSelectorDialogEvents.setFilter),
    map(({ payload }) => projectSelectorDialogActions.setFilter(payload)),
  );

const select: Epic<AnyAction, Action<TScProject>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ProjectSelectorDialogEvents.select),
    map(({ payload }) => scProjectActions.set(payload)),
  );

const resetFilter: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ProjectSelectorDialogEvents.resetFilter),
    map(({ payload }) => projectSelectorDialogActions.resetFilter()),
  );

const show: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ProjectSelectorDialogEvents.show),
    map(({ payload }) => projectSelectorDialogActions.show()),
  );

const close: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ProjectSelectorDialogEvents.close),
    map(({ payload }) => projectSelectorDialogActions.close()),
  );

const load: Epic<AnyAction, WrapAction<typeof projectSelectorDialogActions.load.started>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(ProjectSelectorDialogEvents.load),
    map(action => projectSelectorDialogActions.load.started({})),
  );

export const ProjectSelectorDialogListener = combineEpics(setFilter, resetFilter, show, close, load, select);
