import { IGauge, IItem, IOption } from '../../store/_type/order';
import { TPartsNumber, TOrderItemCode, SAME_NUDE_SIZE_OPTION_NUMBERS } from '../../lookups/master-thisisforreplaceall';
import {
  TSizeStanderdPathParam,
  TAdjustOptionPathParam,
  TRecommendedGaugeParam,
  IRecommendPartsGauge,
  ISizeMeasurement,
  IAvailableOption,
} from '../../store/_type/lookups';
import { by } from '..';
import { piecesSelector } from '../../store/order/object-selector';
import { INITIAL_BASE_GAUGE } from '../../store/order/initial-state';
import {
  isDesignOption,
  getModelCodeForStandardSizeApi,
  isShirtModelOption,
  isShirt,
  isShortSleeveShirt,
  getModelCodeForRecommendGaugeApi,
} from '../item-thisisforreplaceall';
import {
  SHORT_SLEEVE_SHIRT_AROUND_CUT_MESUREMENT_NUMBER,
  LEFT_AND_RIGHT_TOGETHER_CONFIG,
  TLeftAndRightConfig,
  JACKET_SIZE_BRANDS,
} from '../../lookups/item-thisisforreplaceall';

type TPartsPattern = {
  partsNumber: TPartsNumber;
  optionPattern: string;
  modelCode: string;
  modelPattern: string;
};

/**
 * 送信対象とするparts単位のmodelCodeとmodelPatternとoptionPatternをまとめたもの
 */
export const getPartsPatterns = (item: IItem): TPartsPattern[] => {
  const { designParts } = item.design;
  const distinctPieces = piecesSelector(item.pieces).distinctPieces();
  return distinctPieces.map(({ partsNumber, index }) => {
    const { optionPattern, modelPattern, options } = designParts[index];
    const designOption = options.find(v => isDesignOption(v.optionNumber)) || { optionClassNumber: '' };
    const shirtModelOption = options.find(v => isShirtModelOption(v.optionNumber)) || { optionClassNumber: '' };
    const modelCode = !isShirt(partsNumber) ? designOption.optionClassNumber : shirtModelOption.optionClassNumber;
    return {
      partsNumber,
      optionPattern,
      modelCode,
      modelPattern,
    };
  });
};

export const getBaseItemInfo = (item: IItem) => {
  const { categoryCode, subCategoryCode, itemCode, pieces } = item;
  const { seasonCode, brandCode, clothModelCode } = item.cloth;
  const { designParts } = item.design;
  const { nude, selecting, parts: partsSize } = item.size;
  const partsNumbers = piecesSelector(pieces).distinctPartsNumbers();
  const partsPatterns = getPartsPatterns(item);
  return {
    categoryCode,
    subCategoryCode,
    itemCode,
    pieces,
    seasonCode,
    brandCode,
    designParts,
    partsNumbers,
    clothModelCode,
    nude,
    selecting,
    partsSize,
    partsPatterns,
  };
};

// new-storeで使ってる
type TDiffResult = 'upper' | 'same' | 'lower' | 'NaN' | 'other' | 'undefined';
export function checkDiffValue(value?: string | number): TDiffResult {
  if (typeof value === 'undefined') {
    return 'undefined';
  }

  const v = typeof value === 'string' ? +value : value;
  if (isNaN(v)) {
    return 'NaN';
  }
  if (v === 0) {
    return 'same';
  }
  if (v > 0) {
    return 'upper';
  }
  if (v < 0) {
    return 'lower';
  }
  return 'other';
}

/** gauge結合用 */
export function joinGauge(gauge: IGauge, isServerFormat: boolean = false): string {
  const separater = isServerFormat ? '' : ' ';
  if (!gauge.major && !gauge.minor) {
    return '';
  }
  if (gauge.major && gauge.minor) {
    return `${gauge.major}${separater}${gauge.minor}`;
  }
  return gauge.major ? gauge.major : gauge.minor;
}

export const isSameGauge = (gauge1: IGauge, gauge2: IGauge): boolean =>
  gauge1.major === gauge2.major && gauge1.minor === gauge2.minor;

export const hasGauge = (gauge?: IGauge): boolean => (gauge ? gauge.major !== '' || gauge.minor !== '' : false);

/**
 * シャツのモデルの選択肢が1つの場合には、そのモデルコードを設定した「partsPattern」を返却。
 * @param partsPattern シャツの「partsPattern」のみ渡ってくる想定。
 * @param availableOptions
 * @returns
 */
const fillShirtModelCodeWithDefault = (
  partsPattern: TPartsPattern,
  availableOptions: IAvailableOption[],
): TPartsPattern => {
  if (!Array.isArray(availableOptions)) {
    // 配列処理エラーを避ける目的。(注文した商品を確認する場合等に通過。)
    return partsPattern;
  }

  const shirtAvailableOption = availableOptions.find(v => isShirt(v.partsNumber));
  if (shirtAvailableOption === undefined) {
    // 通らない想定。
    return partsPattern;
  }

  const shirtModelOption = shirtAvailableOption.optionPatterns.find(v => isShirtModelOption(v.optionNumber));
  if (shirtModelOption === undefined) {
    // 通らない想定。
    return partsPattern;
  }

  const defaultShirtModel = shirtModelOption.optionClasses.length === 1 ? shirtModelOption.optionClasses[0] : undefined;
  if (defaultShirtModel === undefined) {
    // モデルの選択肢が1つではない場合は、何も処理をせず返却。
    return partsPattern;
  }

  return { ...partsPattern, ...{ modelCode: defaultShirtModel.optionClassNumber } };
};

export const getRecommendedGaugeParam = (item: IItem, availableOptions: IAvailableOption[]): TRecommendedGaugeParam => {
  const { brandCode, partsPatterns, nude, categoryCode } = getBaseItemInfo(item);
  const path = {
    brandCode,
  };
  // シャツのmodelコードを埋める。
  const partsPatternsWithFillShirtModelCode = partsPatterns.map(v =>
    isShirt(v.partsNumber) ? fillShirtModelCodeWithDefault(v, availableOptions) : v,
  );
  const parts = partsPatternsWithFillShirtModelCode.map(({ partsNumber, modelCode, modelPattern }) => ({
    partsNumber,
    // modelCode: getModelCodeForRecommendGaugeApi(partsNumber, categoryCode, modelPattern, modelCode, brandCode),
    modelCode,
    modelPattern,
  }));

  const nudeSizes = nude.measurements.flatMap(nudeSize => {
    const { optionNumber, optionClassName } = nudeSize;
    const matchedOption = SAME_NUDE_SIZE_OPTION_NUMBERS.find(v => v.baseCode === optionNumber);
    const measurementValue = optionClassName ? +optionClassName : 0;

    const optionNumbers = !matchedOption
      ? [optionNumber]
      : Object.values(matchedOption).filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
    return optionNumbers.map(v => ({
      measurementNumber: +v,
      measurementValue,
    }));
  });

  const body = {
    parts,
    nudeSizes,
  };
  return { path, body };
};

export const isSameJacketGroupBrand = (current: TRecommendedGaugeParam, prev?: TRecommendedGaugeParam): boolean => {
  const prevBrand = prev ? prev.path.brandCode : '';
  if (
    (prevBrand && !JACKET_SIZE_BRANDS.includes(prevBrand)) ||
    !JACKET_SIZE_BRANDS.includes(current.path.brandCode)
  ) {
    return false;
  }
  return true;
};

export const isSameBrand = (current: TRecommendedGaugeParam, prev?: TRecommendedGaugeParam): boolean =>
  prev ? prev.path.brandCode === current.path.brandCode : false;

export const isSameNudeSize = (current: TRecommendedGaugeParam, prev?: TRecommendedGaugeParam): boolean => {
  if (!prev) {
    return false;
  }
  const isSameSize = current.body.nudeSizes.reduce((pre, cur) => {
    if (!pre) {
      return false;
    }
    const matchPrevParts = prev.body.nudeSizes.find(by('measurementNumber')(cur.measurementNumber));
    if (!matchPrevParts) {
      return false;
    }
    return cur.measurementValue === matchPrevParts.measurementValue;
  }, true);
  return isSameSize;
};

export const isSameModel = (
  partsNumber: TPartsNumber,
  current: TRecommendedGaugeParam,
  prev?: TRecommendedGaugeParam,
): boolean => {
  if (!prev) {
    return false;
  }
  const currentParts = current.body.parts.find(by('partsNumber')(partsNumber));
  const prevParts = prev.body.parts.find(by('partsNumber')(partsNumber));
  return currentParts && prevParts ? currentParts.modelCode === prevParts.modelCode : false;
};

export const isSameModelPattern = (
  partsNumber: TPartsNumber,
  current: TRecommendedGaugeParam,
  prev?: TRecommendedGaugeParam,
): boolean => {
  if (!prev) {
    return false;
  }
  const currentParts = current.body.parts.find(by('partsNumber')(partsNumber));
  const prevParts = prev.body.parts.find(by('partsNumber')(partsNumber));
  return currentParts && prevParts ? currentParts.modelPattern === prevParts.modelPattern : false;
};

export const isSameRecommendedGaugeParam = (
  current: TRecommendedGaugeParam,
  prev?: TRecommendedGaugeParam,
): boolean => {
  console.log(
    '[isSameRecommendedGaugeParam Function TEST]',
    'CURRENT: ',
    current,
    'PREV: ',
    prev,
    'isSame TEST: ',
    isSameBrand(current, prev) &&
      isSameNudeSize(current, prev) &&
      current.body.parts.every(v => isSameModel(v.partsNumber, current, prev)) &&
      current.body.parts.every(v => isSameModelPattern(v.partsNumber, current, prev)),
  );
  return (
    isSameBrand(current, prev) &&
    isSameNudeSize(current, prev) &&
    current.body.parts.every(v => isSameModel(v.partsNumber, current, prev)) &&
    current.body.parts.every(v => isSameModelPattern(v.partsNumber, current, prev))
  );
};

export const getStandardSizeParam = (
  partsNumber: TPartsNumber,
  item: IItem,
  availableOptions: IAvailableOption[],
): TSizeStanderdPathParam => {
  const { brandCode, partsPatterns, partsSize } = getBaseItemInfo(item);
  // シャツのmodelコードを埋める。
  const partsPatternsWithFillShirtModelCode = partsPatterns.map(v =>
    isShirt(v.partsNumber) ? fillShirtModelCodeWithDefault(v, availableOptions) : v,
  );
  const target = partsPatternsWithFillShirtModelCode.find(by('partsNumber')(partsNumber));
  const partModelCode = target?.modelCode ?? '';
  const modelCode = target
    ? getModelCodeForStandardSizeApi(partsNumber, item.categoryCode, brandCode, target.modelPattern, target.modelCode)
    : '';
  const { gauge } = partsSize[partsNumber];
  return { partsNumber, brandCode, gauge, modelCode, partModelCode };
};

export const isSameStandardSizeParam = (current: TSizeStanderdPathParam, prev?: TSizeStanderdPathParam) => {
  if (!prev) {
    return false;
  }
  return (
    current.partsNumber === prev.partsNumber &&
    current.brandCode === prev.brandCode &&
    isSameGauge(current.gauge, prev.gauge) &&
    current.modelCode === prev.modelCode
  );
};

export const getAdjustOptionParams = (item: IItem): TAdjustOptionPathParam[] => {
  const { brandCode, partsPatterns, itemCode, seasonCode } = getBaseItemInfo(item);
  return partsPatterns.map(({ partsNumber, optionPattern }) => {
    return {
      brandCode,
      seasonCode,
      itemCode,
      partsNumber,
      optionPattern,
    };
  });
};

export const isSameAdjustOptionParams = (
  prevParams: TAdjustOptionPathParam[],
  curParams: TAdjustOptionPathParam[],
): boolean => {
  if (prevParams.length !== curParams.length) {
    return false;
  }

  // partsは必ず1つなのでsortは不要
  return curParams.every(cur => {
    const prev = prevParams.find(v => v.partsNumber === cur.partsNumber);
    if (!prev) {
      return false;
    }
    // const keys = Object.keys(pre);
    return (
      prev.brandCode === cur.brandCode &&
      prev.itemCode === cur.itemCode &&
      prev.optionPattern === cur.optionPattern &&
      prev.seasonCode === cur.seasonCode
    );
  });
};

// 使ってないけど、使うかもなので一旦退避
export function getInitialSelectingGauge(recommendedGauges: IRecommendPartsGauge[], partsNumber: TPartsNumber): IGauge {
  const partsGauge = recommendedGauges.find(v => v.partsNumber === partsNumber);
  if (!partsGauge) {
    return { ...INITIAL_BASE_GAUGE };
  }
  return partsGauge.recommendedGauge.major || partsGauge.recommendedGauge.minor
    ? partsGauge.recommendedGauge
    : partsGauge.allGauges[0];
}

// 使ってる
export function updateMeasurements(
  measurements: IOption[],
  optionNumber: string,
  value: string,
  config?: TLeftAndRightConfig, // 仕上がり寸法変更時のみ
): IOption[] {
  const combineLROptionNumbers = !!config ? [config.leftCode, config.rightCode] : false;
  return measurements
    .map(v => {
      // 左右同時に寸法を変更するデータの場合
      if (
        !!combineLROptionNumbers &&
        combineLROptionNumbers.includes(optionNumber) &&
        combineLROptionNumbers.includes(v.optionNumber)
      ) {
        return { ...v, ...{ optionClassName: value } };
      } else {
        // 寸法を変更するデータの場合値を設定
        return v.optionNumber === optionNumber ? { ...v, ...{ optionClassName: value } } : v;
      }
    })
    .sort((a, b) => +a.optionNumber - +b.optionNumber);
}

// 使ってる
export function editAdjustOptions(
  adjustOptions: IOption[],
  optionNumber: string,
  optionClassNumber: string,
): IOption[] {
  // 新しいオプション
  const newOption: IOption = {
    optionNumber,
    optionClassNumber,
  };

  // 合致するオプション
  const matchedOption = adjustOptions.find(v => v.optionNumber === optionNumber);
  // 未選択の場合
  if (!matchedOption) {
    // 追加する
    return [...adjustOptions, newOption];
  }

  // 既にある場合、合致するoptionClassを除く
  const excludedOptions = adjustOptions.filter(v => {
    return v.optionNumber !== optionNumber;
  });

  // 空白を選択肢した場合(delete)
  // MEMO: サーバー側で未選択の場合は000を返しているので。
  if (optionClassNumber === '000') {
    return excludedOptions;
  }

  // 同じoptionClassでない場合、追加する(update)
  return [...excludedOptions, newOption];
}

export const findGauge = (gauge: IGauge | string, gauges: IGauge[]) =>
  typeof gauge === 'string'
    ? gauges.find(v => gauge.replace(' ', '') === joinGauge(v, true))
    : gauges.find(v => joinGauge(gauge, true) === joinGauge(v, true));

/**
 * 過去の仕上がり寸法に足りない項目を標準サイズで補ったものを返却する
 */
export const coverHistorySizeToStandardSize = (historySize: IOption[], standardSize: IOption[]): IOption[] => {
  // optionClassNameがないものと、standardSizeが持たない項目は除外
  const historySizeToSet = historySize.filter(
    v => !!v.optionClassName && standardSize.some(by('optionNumber')(v.optionNumber)),
  );
  const standardSizeToSet = standardSize.filter(
    ({ optionNumber }) => !historySizeToSet.some(by('optionNumber')(optionNumber)),
  );
  return [...historySizeToSet, ...standardSizeToSet];
};

// 半袖シャツでない場合、「半袖口廻り(仕上り寸法）:7059」を取り除く処理
export const removeShortSleeveShirtAroundCut = (
  res: ISizeMeasurement[],
  itemCode: TOrderItemCode,
): ISizeMeasurement[] =>
  isShortSleeveShirt(itemCode)
    ? res
    : res.map(v =>
        !isShirt(v.partsNumber)
          ? v
          : {
              ...v,
              measurementItems: v.measurementItems.filter(
                ({ measurementNumber }) => measurementNumber !== SHORT_SLEEVE_SHIRT_AROUND_CUT_MESUREMENT_NUMBER,
              ),
            },
      );

/**
 * 左右をまとめて表示する必要のあるブランドコードとパーツナンバーの組み合わせの場合は、該当するデータを返す。それ以外はundifinedを返す。
 */
export const getLeftAndRightTogetherConfig = (
  brandCode: string,
  partsNumber: TPartsNumber,
): TLeftAndRightConfig | undefined =>
  LEFT_AND_RIGHT_TOGETHER_CONFIG.find(conf => conf.brandCode === brandCode && conf.partsNumber === partsNumber);
