import { TPartsNumber, TNudeDementionCode } from '../master-thisisforreplaceall';

/**
 * ヌード寸法を対象のmesuamentNumberからコピーして、追加する用のconfig
 * from には、ジャケットorパンツの採寸項目番号が入る。
 */
export const ADDTIONAL_COPY_NUDE_SIZE_CONFIG: Array<{
  partsNumber: TPartsNumber;
  from: TNudeDementionCode;
  to: string;
}> = [
  // コート
  { partsNumber: '06', from: '7001', to: '7601' },
  { partsNumber: '06', from: '7004', to: '7604' },
  { partsNumber: '06', from: '7005', to: '7605' },
  { partsNumber: '06', from: '7006', to: '7606' },
  // トップス
  { partsNumber: '12', from: '7001', to: '7710' },
  { partsNumber: '12', from: '7004', to: '7711' },
  { partsNumber: '12', from: '7005', to: '7712' },
  { partsNumber: '12', from: '7006', to: '7713' },
  // ワンピース
  { partsNumber: '10', from: '7001', to: '7105' },
  { partsNumber: '10', from: '7004', to: '7138' },
  { partsNumber: '10', from: '7005', to: '7139' },
  { partsNumber: '10', from: '7006', to: '7140' },
  { partsNumber: '10', from: '7203', to: '7141' },
  { partsNumber: '10', from: '7011', to: '7142' },
  // スカート
  { partsNumber: '05', from: '7203', to: '7136' },
  { partsNumber: '05', from: '7011', to: '7137' },
];
