import { createSelector } from 'reselect';

import { AppState } from '../../..';
import { TCouponInfoDialogState } from './action-reducers';

export * from './action-reducers';
export * from './epics';

const featureSelector = (state: AppState): TCouponInfoDialogState => state.utils.dialog.couponInfo;

const selector = {
  isLoading: createSelector(featureSelector, state => state.isLoading),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
  results: createSelector(featureSelector, state => state.results),
  couponId: createSelector(featureSelector, state => state.couponId),
};
export const getCouponInfoDialogState = selector;
