import { createSelector } from 'reselect';

import { AppState } from '../../..';
import { TProjectDialogState } from './action-reducers';

export * from './action-reducers';
export * from './epics';

const featureSelector = (state: AppState): TProjectDialogState => state.utils.dialog.projectSelector;

const selector = {
  isLoading: createSelector(featureSelector, state => state.isLoading),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
  filterCondition: createSelector(featureSelector, state => state.filter),
  results: createSelector(featureSelector, state => state.results),
};
export const getProjectSelectorDialogState = selector;
