import React from 'react';

import I18TextContainer from '../../../containers/I18Text/I18Text';
import cssStyles from './Title.module.scss';
import { II18nItem } from '../../../i18n/_new/types';
import Logger from '../../../helpers/common/logger';

interface IProps {
  textObj?: II18nItem;
  // textObj: II18nItem | string;
  title?: string;
  styles?: string[];
}

const getClassName = (base: string, styles: string[]): string => {
  const classNames = [base];
  if (styles.find(v => v === 'login')) {
    classNames.push(cssStyles.login);
  }
  if (styles.find(v => v === 'right')) {
    classNames.push(cssStyles.right);
  }
  if (styles.find(v => v === 'center')) {
    classNames.push(cssStyles.center);
  }
  if (styles.find(v => v === 'bold')) {
    classNames.push(cssStyles.bold);
  }
  if (styles.find(v => v === 'blue')) {
    classNames.push(cssStyles.blue);
  }
  if (styles.find(v => v === 'white')) {
    classNames.push(cssStyles.white);
  }
  if (styles.find(v => v === 'red')) {
    classNames.push(cssStyles.red);
  }
  if (styles.find(v => v === 'gray')) {
    classNames.push(cssStyles.gray);
  }
  if (styles.find(v => v === 'bgBrightBrown')) {
    classNames.push(cssStyles.bgBrightBrown);
  }
  if (styles.find(v => v === 'lineLightBrown')) {
    classNames.push(cssStyles.lineLightBrown);
  }
  if (styles.find(v => v === 'lineLightGray')) {
    classNames.push(cssStyles.lineLightGray);
  }
  if (styles.find(v => v === 'bgLightGray')) {
    classNames.push(cssStyles.bgLightGray);
  }
  if (styles.find(v => v === 'bgRed')) {
    classNames.push(cssStyles.bgRed);
  }
  if (styles.find(v => v === 'small')) {
    classNames.push(cssStyles.small);
  }
  if (styles.find(v => v === 'medium')) {
    classNames.push(cssStyles.medium);
  }
  if (styles.find(v => v === 'large')) {
    classNames.push(cssStyles.large);
  }
  if (styles.find(v => v === 'black')) {
    classNames.push(cssStyles.black);
  }

  return classNames.join(' ');
};

const Title: React.FC<IProps> = ({ textObj, title, children, styles = [] }) => {
  if (typeof textObj === 'undefined' && !children) {
    Logger.error('title component', title);
  }
  return (
    <h2 className={getClassName(cssStyles.title, styles)}>
      {children ? children : typeof textObj !== 'undefined' ? <I18TextContainer textObj={textObj} /> : title}
    </h2>
  );
};

export default Title;
