import { TRequestConfig, TRequestParams } from '../..';
// import config from '../../.c./configuration/config';
export { ClothProductRes } from './cloth-product.class';

export const clothProductRequestConfig: TRequestConfig = {
  endpoint: '/items/products/{season_code}/{cloth_code}',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.pathParam : '';
    return {
      ok: true,
      body: JSON.stringify('{}'),
    };
  },
  params2request: (params): TRequestParams => {
    const category = params ? params.query.categoryCode : undefined;
    const brand_code = localStorage.getItem('brandCode') ?? '';
    return {
      query: {
        category,
        brand_code
      },
      pathParams: {
        season_code: params ? params.pathParams.seasonCode : '',
        cloth_code: params ? params.pathParams.clothCode : '',
      },
    };
  },
};
