import React from 'react';

import styles from './StaffLoginInfo.module.scss';

import I18TextContainer from '../../../containers/I18Text/I18Text';
import { IStaff } from '../../../store/_type/staff';
import { langResource } from '../../../i18n/_new/resources/';

interface IProps {
  staff: IStaff;
}

const StaffInfo: React.FC<IProps> = ({ staff }) => {
  const { tempoCode: shopCode, staffCode, tempoName, staffName } = staff;
  return (
    <div className={styles.staffWrapper}>
      <dl>
        <dt>
          <I18TextContainer textObj={langResource.loginPage.tempoIdLabel} />
        </dt>
        <dd>
          {shopCode}
          <p>({tempoName})</p>
        </dd>
      </dl>
      <dl>
        <dt>
          <I18TextContainer textObj={langResource.loginPage.staffCodeLabel} />
        </dt>
        <dd>
          {staffCode}
          <p>({staffName})</p>
        </dd>
      </dl>
    </div>
  );
};

export default StaffInfo;
