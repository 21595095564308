import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';

import styles from './ErrorBoundary.module.scss';

import { IApiError } from '../../../types/api';
import { getClientMessage } from '../../../helpers/common/message';
import config from '../../../configuration/config';
import Button from '../../atoms/Button/Button';
import I18Text from '../../../containers/I18Text/I18Text';
import { langResource } from '../../../i18n/_new/resources';

type TProps = Error | {};

interface IState {
  hasError: boolean;
  errorInfo: string;
  eventId?: string;
}

const initialState: IState = {
  hasError: false,
  errorInfo: '',
};

class ErrorBoundary extends Component<TProps, IState> {
  get error(): IApiError {
    return getClientMessage('err', '0003', [this.state.errorInfo]);
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: TProps) {
    super(props);
    this.state = initialState;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    if (!config.isLocal) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        scope.setLevel(Sentry.Severity.Fatal);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
    this.setState({
      errorInfo: errorInfo.componentStack,
    });
  }

  onClear = (): void => {
    // Sentry.showReportDialog({ eventId: this.state.eventId });
    window.location.reload(true);
  };

  renderErrorMessage() {
    return (
      <div id={styles.container}>
        <div className={styles.wrapper}>
          <p>
            <I18Text textObj={langResource.components.errorBoundary.message} />
          </p>
          {config.isDev && config.isLocal ? (
            <textarea className={styles.textArea} value={this.state.errorInfo} />
          ) : null}
          <Button
            styles={['black', 'size-l']}
            onClick={this.onClear}
            textObj={langResource.components.errorBoundary.close}
          />
        </div>
      </div>
    );
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return this.renderErrorMessage();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
