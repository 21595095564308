import { actionCreatorFactory } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { ISizeMeasurement, TSizeMeasurementPathParam } from '../../_type/lookups';
import { cloneDeep } from 'lodash';
import { IItem } from '../../_type/order';

// actions
const actionName = 'size measurement';
const ac = actionObjectCreatorFactory('size measurement');

export interface ISetting {
  orderNumber: string;
  sizeMeasurements: ISizeMeasurement[];
  requestParam: TSizeMeasurementPathParam;
}

export interface IGetSizeMeasurementParams {
  orderNumber: string;
  item?: IItem;
}

export const sizeMeasurementActions = {
  set: ac<ISetting>(`set sizeMeasurements`),
  delete: ac<string>(`delete sizeMeasurements`),
  copy: ac<{ newOrderNumber: string; orderNumber: string }>(`copy sizeMeasurements`),
};

const asyncAc = actionCreatorFactory(`[${actionName}]`);
export const sizeMeasurementAsyncActions = {
  loadData: asyncAc.async<IGetSizeMeasurementParams, {}, {}>('loadData'),
};

// reducer
export interface ISizeMeasurementState {
  sizeMeasurements: {
    [orderNumber: string]: ISizeMeasurement[];
  };
  requestParams: {
    [orderNumber: string]: TSizeMeasurementPathParam;
  };
}

const initialState: ISizeMeasurementState = {
  sizeMeasurements: {},
  requestParams: {},
};

export const sizeMeasurementReducer = reducerWithInitialState(initialState)
  .case(sizeMeasurementActions.set._action, (state, payload) => {
    const newObj = { ...state.sizeMeasurements };
    const newParam = { ...state.requestParams };
    newObj[payload.orderNumber] = payload.sizeMeasurements;
    newParam[payload.orderNumber] = payload.requestParam;
    return {
      ...state,
      sizeMeasurements: { ...newObj },
      requestParams: { ...newParam },
    };
  })
  .case(sizeMeasurementActions.delete._action, (state, payload) => {
    const newObj = { ...state.sizeMeasurements };
    const newParam = { ...state.requestParams };
    delete newObj[payload];
    delete newParam[payload];
    return {
      ...state,
      sizeMeasurements: newObj,
      requestParams: newParam,
    };
  })
  .case(sizeMeasurementActions.copy._action, (state, payload) => {
    const { newOrderNumber, orderNumber } = payload;
    const targetObj = state.sizeMeasurements[orderNumber];
    const targetParam = state.requestParams[orderNumber];
    if (!targetObj || !targetParam) {
      return { ...state };
    }
    const newObj = cloneDeep(targetObj);
    const newParam = cloneDeep(targetParam);
    return {
      ...state,
      sizeMeasurements: {
        ...state.sizeMeasurements,
        [newOrderNumber]: newObj,
      },
      requestParams: {
        ...state.requestParams,
        [newOrderNumber]: newParam,
      },
    };
  });
