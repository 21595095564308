import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { IRecommendPartsGauge, TRecommendedGaugeParam } from '../../_type/lookups';
import { cloneDeep } from 'lodash';
import { IItem } from '../../_type/order';
import { actionCreatorFactory } from 'typescript-fsa';

// actions
const ac = actionObjectCreatorFactory('recommended gauge');

export interface ISetting {
  orderNumber: string;
  recommendedGauges: IRecommendPartsGauge[];
  requestParam: TRecommendedGaugeParam;
}

export interface IRecommandedGaugeParams {
  orderNumber: string;
  prevParam?: TRecommendedGaugeParam;
  item?: IItem;
}

export const recommendedGaugeActions = {
  set: ac<ISetting>(`set recommendedGauges`),
  delete: ac<string>(`delete recommendedGauges`),
  copy: ac<{ newOrderNumber: string; orderNumber: string }>(`copy recommendedGauges`),
};

const asyncActionCreator = actionCreatorFactory('recommendedGauges');

export const recommendedGaugeAsyncActions = {
  loadData: asyncActionCreator.async<IRecommandedGaugeParams, {}, {}>('loadData'),
  validateSelectedGauge: asyncActionCreator<void>('validateSelectedGauge'),
};

// reducer
export interface IRecommendGaugeState {
  recommendedGauges: {
    [orderNumber: string]: IRecommendPartsGauge[];
  };
  requestParams: {
    [orderNumber: string]: TRecommendedGaugeParam;
  };
}

const initialState: IRecommendGaugeState = {
  recommendedGauges: {},
  requestParams: {},
};

export const recommendedGaugeReducer = reducerWithInitialState(initialState)
  .case(recommendedGaugeActions.set._action, (state, payload) => {
    const newObj = { ...state.recommendedGauges };
    const newParam = { ...state.requestParams };
    newObj[payload.orderNumber] = payload.recommendedGauges;
    newParam[payload.orderNumber] = payload.requestParam;
    return {
      ...state,
      recommendedGauges: { ...newObj },
      requestParams: { ...newParam },
    };
  })
  .case(recommendedGaugeActions.delete._action, (state, payload) => {
    const newObj = { ...state.recommendedGauges };
    const newParam = { ...state.requestParams };
    delete newObj[payload];
    delete newParam[payload];
    return {
      ...state,
      recommendedGauges: newObj,
      requestParams: newParam,
    };
  })
  .case(recommendedGaugeActions.copy._action, (state, payload) => {
    const { newOrderNumber, orderNumber } = payload;
    const targetObj = state.recommendedGauges[orderNumber];
    const targetParam = state.requestParams[orderNumber];
    if (!targetObj || !targetParam) {
      return { ...state };
    }
    const newObj = cloneDeep(targetObj);
    const newParam = cloneDeep(targetParam);
    return {
      ...state,
      recommendedGauges: {
        ...state.recommendedGauges,
        [newOrderNumber]: newObj,
      },
      requestParams: {
        ...state.requestParams,
        [newOrderNumber]: newParam,
      },
    };
  });
