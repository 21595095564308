import { IClothProduct } from '../../_type/lookups';
import { EParts } from '../../../lookups/master-thisisforreplaceall/parts';

// どこに置く???
export const INITIAL_PRODUCT_STATE: IClothProduct = {
  brandCode: '',
  itemCode: '',
  clothSeasonCode: '',
  productSeasonCode: '',
  personalorderProductNumber: '',
  personalorderColorCode: '',
  seasonCode: '',
  seasonName: '',
  personalorderColorName: '',
  retailPrice: '',
  retailPriceTaxin: '',
  partsModelPatterns: [
    {
      partsNumber: EParts.jaket,
      modelPatterns: [],
    },
  ],
  design: '',
  designName: '',
  clothName: '',
  clothCode: '',
  vendorClothNumber: '',
  mixings: {
    front: [],
    back: [],
  },
  isnew: '',
  issoldout: '',
  makerName2: '',
  functionName: '',
  clothBrandCode: '',
};
