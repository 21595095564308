import { combineReducers } from 'redux';

import { errorDialogReducer } from './error';
import { selectionDialogReducer } from './selection';
import { customerDialogReducer } from './customer-load';
import { infoDialogReducer } from './info';
import { okCancelDialogReducer } from './ok-cancel';
import { saveTmpOrderReducer } from './save-tmpOrder';
import { requiredCheckReducer } from './required-check';
import { purchaseHistoryReducer } from './purchase-history';
import { memoDialogReducer } from './memo';
import { projectSelectorDialogReducers } from './project-selector';
import { couponInfoDialogReducers } from './coupon-info';
import { errorRetryActionDialogReducer } from './errorRetryAction';

export const dialogFeatureReducers = combineReducers({
  error: errorDialogReducer,
  errorRetryAction: errorRetryActionDialogReducer,
  info: infoDialogReducer,
  okCancel: okCancelDialogReducer,
  selection: selectionDialogReducer,
  customerLoad: customerDialogReducer,
  saveTmpOrder: saveTmpOrderReducer,
  requiredCheck: requiredCheckReducer,
  purchaseHistory: purchaseHistoryReducer,
  memo: memoDialogReducer,
  projectSelector: projectSelectorDialogReducers,
  couponInfo: couponInfoDialogReducers,
});
