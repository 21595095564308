import { TRequestConfig, TRequestParams } from '../..';
// import config from '../../../configuration/config';

// import MOCK_UP_JSON from '../../../mockups/jsonServer/mockup.json';
// import { toCamelCaseObject } from '../../../helpers/convertions';

export { OrderDetailRes } from './OrderDetailRes.class';

export const orderDetailRequestConfig: TRequestConfig = {
  endpoint: '/orders/detail/{serial}',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        serial: params ? params.id : '',
      },
    };
  },
};

export const updateOrderDetailRequestConfig: TRequestConfig = {
  endpoint: '/orders/{serial}',
  method: 'POST',
  useMock: false,
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        serial: params ? params.path.serial : '',
      },
      body: params ? params.body : {},
    };
  },
};

// function getSearchDigestsSuccessData(): string {
//   const success = MOCK_UP_JSON['order-digests'];
//   return JSON.stringify(toCamelCaseObject(success!));
// }
