import { TCouponInfo } from '../../../types/coupon';
import { CouponDetailRes } from '../../../api/coupon/detail';

export function toCoupon(res: CouponDetailRes): TCouponInfo {
  const expression = `data`;
  const data = res.query(expression);

  return {
    id: data.id || '',
    couponName: data.couponName || '',
    discountAmount: data.discountAmount || '',
    publishDate: data.publishDate || '',
    expiration: data.expiration || 0,
    userId: data.userId || '',
    lastName: data.lastName || '',
    firstName: data.firstName || '',
    purpose: data.purpose || '',
    hasExpectedDate: data.hasExpectedDate || 0,
    buyerType: data.buyerType || '',
    memo: data.memo || '',
    ecouponUsedFlg: data.ecouponUsedFlg || false,
  };
}
