import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import isValid from 'date-fns/isValid';

import styles from './ProjectSelectorDialog.module.scss';
import Label from '../../atoms/Label/Label';
import Button from '../../atoms/Button/Button';
import CheckBox from '../../atoms/CheckBox/CheckBox';
import InputText from '../../../containers/components/InputText';
import DateRangePicker from '../../molecules/DateRangePicker/DateRangePicker';
import I18TextContainer from '../../../containers/I18Text/I18Text';

import { TProjectSelectorDialogProps } from '../../../containers/components/dialogs/ProjectSelectorDialog';
import { langResource } from '../../../i18n/_new/resources';
import { TScProject, TProjectSearchCondition } from '../../../types/project';
import { DUMMY_SC_PROJECT } from '../../../lookups/sc-project';

const ProjectSelectorDialog: React.FC<TProjectSelectorDialogProps> = (props: TProjectSelectorDialogProps) => {
  const { hasOpen, results, load, filterCondition, setFilter } = props;

  useEffect(() => {
    if (hasOpen) {
      load();
    }
  }, [hasOpen, load]);

  const onClose = () => props.close();
  const onReset = () => props.resetFilter();
  const onLoad = () => load();

  const onSelectProject = (project: TScProject) => () => {
    props.select(project);
    props.close();
  };

  const onSelectOther = () => {
    props.select({ ...DUMMY_SC_PROJECT });
    props.close();
  };

  const onChangeTextHandler = (key: keyof Pick<TProjectSearchCondition, 'contactName' | 'companyName'>) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value || '';
    setFilter({ key, value });
  };

  const onChangeDate = (key: keyof Pick<TProjectSearchCondition, 'startDate' | 'endDate'>) => (date: Date) => {
    const selectedDate = isValid(date) ? date : new Date();
    setFilter({ key, value: selectedDate });
  };

  const onChangeCheckBox = (key: keyof Pick<TProjectSearchCondition, 'hasIncludeUndecided'>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.checked || false;
    setFilter({ key, value });
  };

  return (
    <React.Fragment>
      <Dialog open={hasOpen} onClose={onClose} maxWidth={false} className={styles.root}>
        <div>
          <DialogTitle>
            <I18TextContainer textObj={langResource.dialog.projectSelector.title} />
          </DialogTitle>
          <div>
            <DialogContent>
              <div className={styles.filter}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Label styles={['black']} textObj={langResource.dialog.projectSelector.companyName} />
                    <div className={styles.inputWrap}>
                      <InputText
                        onValueChanged={onChangeTextHandler('companyName')}
                        value={filterCondition.companyName}
                      />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <Label styles={['black']} textObj={langResource.dialog.projectSelector.contactName} />
                    <div className={styles.inputWrap}>
                      <InputText
                        onValueChanged={onChangeTextHandler('contactName')}
                        value={filterCondition.contactName}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.colDate}>
                    <Label styles={['black']} textObj={langResource.dialog.projectSelector.salesRecordingDate} />
                    <div className={styles.inputWrap}>
                      <DateRangePicker
                        dateFrom={filterCondition.startDate}
                        dateTo={filterCondition.endDate}
                        onDateFromChange={onChangeDate('startDate')}
                        onDateToChange={onChangeDate('endDate')}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Label styles={['black']} textObj={langResource.dialog.projectSelector.projectCondition} />
                    <div className={styles.inputWrap}>
                      <CheckBox
                        onChange={onChangeCheckBox('hasIncludeUndecided')}
                        checked={filterCondition.hasIncludeUndecided}
                        textObj={langResource.dialog.projectSelector.checkBoxText}
                      />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.actionWrapper}>
                      <div className={styles.resetBtnWrapper}>
                        <Button
                          styles={['block']}
                          textObj={langResource.dialog.projectSelector.resetBtn}
                          onClick={onReset}
                        />
                      </div>
                      <Button
                        styles={['black', 'size-xl', 'search']}
                        textObj={langResource.dialog.projectSelector.loadBtn}
                        onClick={onLoad}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.purchaseHistoryContents}>
                <div className={styles.thead}>
                  <div className={styles.row}>
                    <div className={styles.col1}>
                      <p>
                        <I18TextContainer textObj={langResource.dialog.projectSelector.companyName} />
                      </p>
                    </div>
                    <div className={styles.col2}>
                      <p>
                        <I18TextContainer textObj={langResource.dialog.projectSelector.projectName} />
                      </p>
                    </div>
                    <div className={styles.col3}>
                      <p>
                        <I18TextContainer textObj={langResource.dialog.projectSelector.contactName} />
                      </p>
                    </div>
                    <div className={styles.col4}>
                      <p>
                        <I18TextContainer textObj={langResource.dialog.projectSelector.salesRecordingDate} />
                      </p>
                    </div>
                    <div className={styles.col5}>
                      <p>
                        <I18TextContainer textObj={langResource.dialog.projectSelector.actions} />
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.tbody}>
                  {results.map((project: TScProject, index) => (
                    <div className={styles.row} key={index}>
                      <div className={styles.col1}>
                        <p>{project.companyName}</p>
                      </div>
                      <div className={styles.col2}>
                        <p>{project.projectName}</p>
                      </div>
                      <div className={styles.col3}>
                        <p>{project.contactName}</p>
                      </div>
                      <div className={styles.col4}>
                        <p>{project.salesRecordingDate}</p>
                      </div>
                      <div className={styles.col5}>
                        <Button
                          styles={['block', 'black', 'size-xs']}
                          textObj={langResource.dialog.projectSelector.selectBtn}
                          onClick={onSelectProject(project)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </DialogContent>
            <DialogActions className={['dialog__actions', styles.dialogActions].join(' ')}>
              <div className={styles.actionWrapper}>
                <Button onClick={onSelectOther} textObj={langResource.dialog.projectSelector.otherBtn} />
                <Button onClick={onClose} textObj={langResource.dialog.projectSelector.closeBtn} />
              </div>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default ProjectSelectorDialog;
