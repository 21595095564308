import { IComposition } from '../../_type/order';
import { MASTER_COMPOSITION } from '../../../lookups/master-thisisforreplaceall';
import { getValue } from '../../../helpers/master-lookup';
import { NOSELECT_COMPOSITION_MIXING } from '../initial-state';
import { by } from '../../../helpers';

/**
 * IItems からデータを取得する関数郡
 */
export const compositionSelector = (state: IComposition[]) => new CompositionSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class CompositionSelector {
  private get compositions(): IComposition[] {
    // MEMO:state不正でも落ちないように修正
    return this.state || [];
  }

  constructor(private state: IComposition[]) {}

  /**
   * 組成が選択されているかつ率が0以上のものだけを抽出する
   */
  getValidItems() {
    return this.compositions.filter(v => v.mixing !== NOSELECT_COMPOSITION_MIXING && v.mixingRatio > 0);
  }

  /** #006/ナイロン/89の形に変換 */
  toServerFormat(): string {
    return this.getValidItems()
      .map(c => {
        const { mixing, mixingRatio } = c;
        const name = getValue(mixing, MASTER_COMPOSITION) || '';
        return `#${mixing}/${name}/${mixingRatio}`;
      })
      .join('');
  }

  get toConfirmationFormat(): string[] {
    return this.getValidItems().map(v => {
      const name = getValue(v.mixing, MASTER_COMPOSITION) || '';
      return `${name} / ${v.mixingRatio}%`;
    });
  }

  isInputCorrect(): boolean {
    const validItems = this.getValidItems();
    const total = validItems.reduce((p, c) => p + c.mixingRatio, 0);
    // 組成が重複しているか
    const hasSelectedDoubleItem = validItems.some(v => validItems.filter(by('mixing')(v.mixing)).length !== 1);
    return total === 100 && !hasSelectedDoubleItem;
  }

  isInputCompleted(): boolean {
    return this.getValidItems().length > 0;
  }
}
