import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import {
  sizeMeasurementAsyncActions,
  sizeMeasurementActions,
  ISetting as ISizeMeasurementSetting,
} from './action-reducer';
import { mergeMap } from 'rxjs/operators';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IItem } from '../../_type/order';
import { ApiError } from '../../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { getSizeMeasurements } from '../../../services/items';
import { toSizeMeasurement } from '../../../helpers/api/converter/size-measurement';
import { ISizeMeasurement } from '../../_type/lookups';
import { removeShortSleeveShirtAroundCut } from '../../../helpers/size-correction';
import { designSelector } from '../../order/object-selector';
import prop from 'ramda/es/prop';

const loadData: Epic<
  AnyAction,
  | Action<Parameters<typeof sizeMeasurementAsyncActions.loadData.done>[0]>
  | Action<ISizeMeasurementSetting>
  | Action<{ error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(sizeMeasurementAsyncActions.loadData.started),
    mergeMap(async ({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = payload.item || (appStateObj.item() as IItem);
      const { itemCode } = item;
      const { brandCode, seasonCode } = item.cloth;
      const partsModelPatterns = designSelector(item.design).partsModelPatterns(item.pieces);
      const modelPatterns = partsModelPatterns.map(prop('modelPattern'));
      const res = await getSizeMeasurements({ brandCode, modelPatterns, itemCode, seasonCode })
        .then(toSizeMeasurement)
        .catch(err => err);
      return { payload, res, brandCode, modelPatterns, itemCode };
    }),
    mergeMap(params => {
      const { payload, res, brandCode, modelPatterns, itemCode } = params;
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: '条件に該当するデータがありません.' },
          }),
        ];
      }
      // 長袖シャツの場合には「半袖口廻り」を取り除く処理を入れる。
      const sizeMeasurements = removeShortSleeveShirtAroundCut(res as ISizeMeasurement[], itemCode);
      return [
        sizeMeasurementActions.set._action({
          orderNumber: payload.orderNumber,
          sizeMeasurements,
          requestParam: { brandCode, modelPatterns },
        }),
        sizeMeasurementAsyncActions.loadData.done({ params: payload, result: {} }),
      ];
    }),
  );

export const SizeMeasurementEpics = combineEpics(loadData);
