import { TMaster } from '../../types/index';
import { rawStrArr2EnumLikeObj } from '../../lib/utils';

const categories = rawStrArr2EnumLikeObj(['CS', 'MT', 'FM', 'TX', 'DS', 'WM', 'MC', 'WC']);

type TNotNullCategory = keyof typeof categories;
export type TCategory = '' | TNotNullCategory;

export const ECategory = {
  clasic: categories.CS,
  easy: categories.MT,
  formal: categories.FM,
  tuxedo: categories.TX,
  shirt: categories.DS,
  women: categories.WM,
  mensCoat: categories.MC,
  womensCoat: categories.WC,
};

export const MASTER_CATEGORY: Array<TMaster<TCategory>> = [
  { code: 'CS', value: 'クラシック' },
  // { code: 'MT', value: 'EASY' },
  // { code: 'FM', value: 'フォーマル' },
  // { code: 'TX', value: 'タキシード' },
  // { code: 'DS', value: 'シャツ' },
  // { code: 'WM', value: 'ウィメンズ' },
  // { code: 'MC', value: 'メンズコート' },
  // { code: 'WC', value: 'ウィメンズコート' },
];
