import { ISizeMeasurementItemView } from '../../store/_type/lookups';
import { TMaster } from '../../types';
import { ECategory, EParts, TCategory, TPartsNumber } from '../master-thisisforreplaceall';

interface ISizeLimit {
  upper: number;
  lower: number;
}

export const NUDE_DEMENTION_LIMIT_DEFAULT_SIZE: ISizeLimit = {
  upper: 999,
  lower: 10,
};

// ズボン丈(7018) = 股上(7012) + Math.max(股下（左）(7013), 股下（右）(7014))
export const MESUREMENT_PANTS_LENGTH_CONFIG = {
  rise: '7012',
  inseams: ['7013', '7014'],
  pantsLength: '7018',
};

export const MASTER_NUDE_DEMENTION_LIMIT: Array<TMaster<string, ISizeLimit>> = [
  { code: '7001', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7004', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7005', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7006', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7203', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7011', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7027', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7029', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7033', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7034', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7030', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
  { code: '7200', value: { ...NUDE_DEMENTION_LIMIT_DEFAULT_SIZE } },
];

export const SHORT_SLEEVE_SHIRT_AROUND_CUT_MESUREMENT_NUMBER = '7059';

export const NEED_OVERWRITE_MESUREMENT_VALUE: TOverWriteMeasumentValue[] = [
  // トップス 袖丈(右)
  {
    category: ECategory.women,
    brand: 'NX',
    measurementNumber: '7702',
    model: 'CB02',
    value: 0,
  },
  // トップス 袖丈(左)
  {
    category: ECategory.women,
    brand: 'NX',
    measurementNumber: '7703',
    model: 'CB02',
    value: 0,
  },
];

/**
 * 採寸項目の値を上書きする必要のある項目は上書きする。上書きの必要が無ければそのまま返す。
 */
export const overWriteMeasurementItem = (
  sizeMeasurementItemView: ISizeMeasurementItemView,
  modelPattern: string,
  category: TCategory,
  brand: string,
): ISizeMeasurementItemView => {
  const matchedConfig = NEED_OVERWRITE_MESUREMENT_VALUE.find(
    v =>
      v.category === category &&
      v.brand === brand &&
      v.model === modelPattern &&
      v.measurementNumber === sizeMeasurementItemView.measurementNumber,
  );

  return !matchedConfig
    ? sizeMeasurementItemView
    : { ...sizeMeasurementItemView, ...{ value: `${matchedConfig.value}` } };
};

export type TLeftAndRightConfig = {
  brandCode: string;
  partsNumber: TPartsNumber;
  leftCode: string;
  rightCode: string;
  leftName: string;
  rightName: string;
  alternativeName: string; // 代わりに表示する名前
};

/**
 * 左右をまとめて表示する必要がある採寸項目。
 * 同一ブランド・同一パーツでは一つの項目しか統合出来ない。
 * 現状ブランド「KA」はEASYでしか使われていないようなので「カテゴリMT」という条件は入れていない。
 */
export const LEFT_AND_RIGHT_TOGETHER_CONFIG: TLeftAndRightConfig[] = [
  {
    brandCode: 'KA',
    partsNumber: EParts.jaket,
    leftCode: '7008',
    rightCode: '7007',
    leftName: '袖丈(左)',
    rightName: '袖丈(右)',
    alternativeName: '袖丈',
  },
  {
    brandCode: 'KA',
    partsNumber: EParts.pants,
    leftCode: '7013',
    rightCode: '7014',
    leftName: '股下(左)',
    rightName: '股下(右)',
    alternativeName: '股下',
  },
];
type TOverWriteMeasumentValue = {
  category: TCategory;
  brand: string;
  measurementNumber: string;
  model: string;
  value: number;
};
