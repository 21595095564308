export const header = {
  home: { jp: 'ホーム' },
  sample: { jp: 'サンプル' },
  orderSearch: { jp: '注文検索' },
  orderDetail: { jp: '注文内容' },
  clothSelection: { jp: '生地選択' },
  designSelection: { jp: 'デザイン・オプション選択' },
  sizeCorrection: { jp: 'サイズ補正' },
  address: { jp: 'お届け先・日時' },
  amout: { jp: 'ポイント・値引き金額他' },
  settlement: { jp: '決済' },
  inventorySearch: { jp: '在庫検索' },
  orderConfirmation: { jp: '注文内容確認' },
  itemConfirmation: { jp: '商品内容確認' },
  login: { jp: 'LOGIN' },
  logout: { jp: '終了確認' },
  logoutBtn: { jp: 'ログアウト' },
};
