import { IOnInputFreeParam, IItem } from '../store/_type/order';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { DesignSelectionPageEvents } from '../views/events/DesignSelectionPage';
import { actions as designActions } from '../store/order/design/actions';
import { requiredCheckActions } from '../store/utils/dialog/required-check';
import {
  ILoadInitializeParam,
  IAddPieceParam,
  IDeletePieceParam,
  IOptionClassSelection,
} from '../store/order/design/actions';
import { IAvailableOption } from '../store/_type/lookups';
import { IRequiredCheckDialog } from '../types/dialog';

const onLoadInitialize: Epic<AnyAction, Action<ILoadInitializeParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onLoadInitialize),
    map(action => {
      return designActions.loadAvailableOptionsForInitialize(action.payload);
    }),
  );

const onAddPiece: Epic<AnyAction, Action<IAddPieceParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onAddPiece),
    map(action => {
      return designActions.addPiece(action.payload);
    }),
  );

const onDeletePiece: Epic<AnyAction, Action<IDeletePieceParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onDeletePiece),
    map(action => {
      return designActions.deletePiece(action.payload);
    }),
  );

const onSetDefault: Epic<
  AnyAction,
  Action<{ partsIndex: string; item: IItem; availableOptions: IAvailableOption[] }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onSetDefault),
    map(action => {
      return designActions.setDefault(action.payload);
    }),
  );

const onSelectOption: Epic<
  AnyAction,
  Action<{ partsIndex: string; optionNumber: string; item: IItem; hasOpen?: boolean }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onSelectOption),
    map(action => {
      return designActions.selectOption(action.payload);
    }),
  );

const onSelectOptionClass: Epic<AnyAction, Action<IOptionClassSelection>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onSelectOptionClass),
    map(action => {
      return designActions.selectOptionClass(action.payload);
    }),
  );

const onInputFree: Epic<AnyAction, Action<IOnInputFreeParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onInputFree),
    map(action => {
      return designActions.updateFreeInput.started(action.payload);
    }),
  );

const onShowRequiredCheckDialog: Epic<AnyAction, Action<IRequiredCheckDialog>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(DesignSelectionPageEvents.onShowRequiredCheckDialog),
    map(({ payload }) => {
      return requiredCheckActions.show(payload);
    }),
  );

export const DesignPageListeners = combineEpics(
  onLoadInitialize,
  onAddPiece,
  onDeletePiece,
  onSetDefault,
  onSelectOption,
  onSelectOptionClass,
  onInputFree,
  onShowRequiredCheckDialog,
);
