import {
  IAvailableOptionWithPartsIndexAndSelectedInfo,
  MODEL_SELECTOR_OPTION_NUMBER,
  IOptionPatternWithSelectedInfoAndModel,
  IOptionClass,
} from '../../_type/lookups/index';
import { IDesignSelectingParam, IOption, IDesignParts } from '../../_type/order';
import { IPartsOptionListItem, IOptionListItem, IPartsModelPattern } from '../../../types/option';
import { IOptionContent } from '../../_type/component/design';
import { TPartsNumber, TCategory } from '../../../lookups/master-thisisforreplaceall';
import { IOptionSidebarItem } from '../../../types/new-store/components';
import { getValue } from '../../../helpers/master-lookup';
import { MASTER_PARTS } from '../../../lookups/master-thisisforreplaceall';
import { by } from '../../../helpers';
import {
  isInfluencedByEmbroideryOptionNumber,
  toIOptionFromIOptionPatternWithSelectedInfo,
  isInputEmbroideryRequired,
  isShirtModelOption,
  getEmbroideryType,
  canSelectSpareOption,
  shouldGroupBy,
} from '../../../helpers/item-thisisforreplaceall';
import { getModelImagePath, getOptionImagePath, getOptionClassProp } from '../../../helpers/option';
import { piecesSelector } from '../../order/object-selector';
import { isValidEmbroidery, isValidOptionsCombination } from '../../../helpers/common/validate';
import {
  OPTION_COMBINATION_FUNCTIONS_CONFIG,
  OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY,
} from '../../../lookups/item-thisisforreplaceall';

/**
 * IAvailableOptionWithPartsIndexAndSelectedInfo からデータを取得する関数郡
 */
export const editedAvailableOptionsSelector = (state: IAvailableOptionWithPartsIndexAndSelectedInfo[]) =>
  new EditedAvailableOptionsSelector(state);

/**
 * 決してthis.stateを変更しないこと！！！
 */
class EditedAvailableOptionsSelector {
  /** spareをmainにmergeして返却する */
  get mergeSpareEditedOption() {
    const pieces = this.state.map(v => ({ index: v.partsIndex, partsNumber: v.partsNumber }));
    const distinctPartsNumbers = piecesSelector(pieces).distinctPartsNumbers();
    return distinctPartsNumbers.map(partsNumber => {
      // MEMO: spareじゃないケースは必ずあるので強制的にcast
      const mainParts = this.state.find(
        v => v.partsNumber === partsNumber && !v.isSpare,
      ) as IAvailableOptionWithPartsIndexAndSelectedInfo;
      const spareParts = this.state.find(v => v.partsNumber === partsNumber && v.isSpare);
      if (!spareParts) {
        return mainParts;
      }
      const mainPartsAllOptionNumbers = mainParts.optionPatterns.map(v => v.optionNumber);
      const onlySpareOptionPatterns = spareParts.optionPatterns.filter(
        v => !mainPartsAllOptionNumbers.includes(v.optionNumber),
      );
      return {
        ...mainParts,
        optionPatterns: [...mainParts.optionPatterns, ...onlySpareOptionPatterns],
      };
    });
  }
  constructor(private state: IAvailableOptionWithPartsIndexAndSelectedInfo[]) {}
  /**
   * 画面の初期表示時に表示するオプションを返却する
   */
  public getInitialSelecting(
    partsOptionModels: IPartsModelPattern[],
    category: TCategory,
    brand: string,
    isEdit: boolean,
  ): IDesignSelectingParam {
    const initial = {
      partsIndex: '',
      optionNumber: '',
      optionClassNumber: '',
      hasOpenSelector: false,
    };
    const { partsIndex } = this.state[0];
    const editedOptionPatterns = this.editiedAvailableOptionPatternWithModel(
      partsIndex,
      partsOptionModels,
      initial,
      category,
      brand,
    );
    // 選択可能なオプションの先頭を返却する
    const { optionNumber } =
      editedOptionPatterns.filter(
        v =>
          v.optionClasses.length !== 1 &&
          !(isEdit && (v.isDesignOption || v.isModel || isShirtModelOption(v.optionNumber))),
      )[0] || '';
    return { ...initial, partsIndex, optionNumber };
  }

  public editiedAvailableOption(partsIndex: string) {
    return this.state.find(v => v.partsIndex === partsIndex);
  }

  public optionPattern(partsIndex: string, optionNumber: string) {
    const availableOption = this.editiedAvailableOption(partsIndex);
    return !availableOption ? undefined : availableOption.optionPatterns.find(v => v.optionNumber === optionNumber);
  }

  public optionPatternCode(partsIndex: string, optionNumber: string): string {
    const availableOption = this.editiedAvailableOption(partsIndex);
    return !availableOption
      ? ''
      : (availableOption.optionPatterns.find(v => v.optionNumber === optionNumber) || { optionPattern: '' })
          .optionPattern;
  }

  public editiedAvailableOptionMergedSpare(partsNumber: TPartsNumber) {
    return this.mergeSpareEditedOption.find(v => v.partsNumber === partsNumber);
  }

  public editiedAvailableOptionMergedSpareFromPartsIndex(partsIndex: string) {
    return this.mergeSpareEditedOption.find(v => v.partsIndex === partsIndex);
  }

  // /**
  //  * 指定の optionNumber のデータを保持してるかどうかを返却
  //  */
  // public hasOption(partsIndex: string, optionNumber: string): boolean {
  //   return !!this.optionPattern(partsIndex, optionNumber);
  // }

  public optionClass(partsIndex: string, optionNumber: string, optionClassNumber: string) {
    const optionPattern = this.optionPattern(partsIndex, optionNumber);
    return !optionPattern
      ? undefined
      : optionPattern.optionClasses.find(v => v.optionClassNumber === optionClassNumber);
  }

  // TODO: 「お勧め選択」時にどのような挙動になるのかの仕様、要確認。（現状、「デフォルトがあるものはデフォルトで上書き、それ以外はそのまま」で実装してます）
  public getOptionsFilledByDefault(designParts: IDesignParts, partsIndex: string): IOption[] {
    return this.state
      .filter(v => v.partsIndex === partsIndex)
      .flatMap(v => v.optionPatterns)
      .map(v => ({ optionNumber: v.optionNumber, ...(v.optionClasses.find(vv => vv.isDefault) || {}) }))
      .map(v => {
        if (!v.optionClassNumber) {
          // デフォルトがない場合のみ、選択した値で埋める（ TODO: optionNameも視るべき？？？）
          const selectedOption = designParts[partsIndex].options.find(vv => vv.optionNumber === v.optionNumber);
          if (selectedOption) {
            v.optionClassNumber = selectedOption.optionClassNumber;
            if (selectedOption.optionClassName) {
              v.optionClassName = selectedOption.optionClassName;
            }
          }
        }
        return v;
      })
      .filter(v => v.optionClassNumber || v.optionClassName)
      .map(v => ({
        optionNumber: v.optionNumber,
        optionClassNumber: v.optionClassNumber,
        optionClassName: v.optionClassName,
      }));
  }

  public totalOptionPriceTaxin(): number {
    return this.getTotalPrice('retailPriceTaxin');
  }

  public totalOptionPrice(): number {
    return this.getTotalPrice('retailPrice');
  }

  public getOptionContent(
    selecting: IDesignSelectingParam,
    partsOptionModels: IPartsModelPattern[],
    category: TCategory,
    brand: string,
    isEdit: boolean,
  ): IOptionContent {
    const { partsIndex, optionNumber } = selecting;

    let optionContent: IOptionContent = {
      title: '',
      isSpecial: false,
      optionPatterns: [],
      listItems: [],
      isBlouse: false,
    };

    if (partsIndex && optionNumber) {
      const optionPatterns = this.editiedAvailableOptionPatternWithModel(
        partsIndex,
        partsOptionModels,
        selecting,
        category,
        brand,
      );
      if (optionPatterns.length > 0) {
        const pattern = optionPatterns.find(v => v.optionNumber === optionNumber);
        if (pattern) {
          optionContent = {
            title: !pattern.isSpecial ? pattern.optionName : '特殊オプション',
            isSpecial: pattern.isSpecial,
            optionPatterns: !pattern.isSpecial ? [pattern] : optionPatterns.filter(v => v.isSpecial),
            listItems: [],
            isBlouse: shouldGroupBy(optionNumber),
          };
        }
      }
    }

    if (optionContent.isSpecial) {
      optionContent.listItems = (
        this.getPartsOptionListItems(selecting, partsOptionModels, category, brand, isEdit).find(
          v => v.partsIndex === partsIndex,
        ) || {
          items: [],
        }
      ).items.filter(v => v.isSpecial);
    }
    return optionContent;
  }

  public getSidebarListItem(
    selecting: IDesignSelectingParam,
    addableParts: TPartsNumber[],
    deletableParts: TPartsNumber[],
    partsOptionModels: IPartsModelPattern[],
    category: TCategory,
    brand: string,
    isEdit: boolean,
  ): IOptionSidebarItem {
    const partsOptionListItems = this.getPartsOptionListItems(selecting, partsOptionModels, category, brand, isEdit);
    const addablePartsList = !isEdit
      ? addableParts.sort().map(partsNumber => {
          return {
            partsName: getValue(partsNumber, MASTER_PARTS) || '',
            partsNumber,
          };
        })
      : [];
    const partsItems = partsOptionListItems
      .map(param => {
        const { partsIndex, items, partsNumber, isSpare } = param;
        const partsName = getValue(partsNumber, MASTER_PARTS) || '';
        const isMultipleParts = partsOptionListItems.filter(by('partsNumber')(partsNumber)).length > 1;
        const isDeletableParts = deletableParts.some(v => v === partsNumber);
        // 複数パーツがあるときはスペアのみを削除対象とする
        const canDelete = !isMultipleParts ? isDeletableParts : isDeletableParts && isSpare;
        // listItems
        const normals = items.filter(v => v.isSpecial === false);
        const specials = items.filter(v => v.isSpecial === true);
        const special: IOptionListItem[] =
          specials.length === 0
            ? []
            : [
                {
                  optionNumber: specials[0].optionNumber,
                  optionName: '特殊オプション',
                  isSpecial: true,
                  paperState: {
                    priceTaxIn: specials.reduce((pre, cur) => pre + cur.paperState.priceTaxIn, 0),
                    className: specials.some(v => v.paperState.classNumber !== '') ? '設定あり' : '設定なし',
                    hasSelecting: specials.some(v => v.paperState.hasSelecting),
                    isRequired: specials.some(v => v.paperState.isRequired),
                    // 不要
                    classNumber: '',
                    isDisable: false,
                  },
                  selectBoxState: {
                    hasOpen: false,
                    // 不要
                    selectingClassNumber: '',
                    classes: [],
                  },
                },
              ];

        return {
          partsIndex,
          partsNumber,
          partsName,
          canDelete: canDelete && !isEdit,
          optionListItems: [...normals, ...special],
        };
      })
      .sort((a, b) => +a.partsNumber - +b.partsNumber || +a.partsIndex - +b.partsIndex);

    return {
      partsItems,
      addablePartsList,
    };
  }

  public getPearlToneInfo(): { pearlToneCode: string; pearlToneName: string } {
    const pearlToneName = this.state
      .map(
        v =>
          v.optionPatterns
            .map(vv =>
              vv.optionNumber === '1075' && vv.selectingClassNumber === '001' ? vv.selectingClassName : undefined,
            )
            .filter(vv => vv)[0],
      )
      .filter(v => v)[0];

    return pearlToneName ? { pearlToneCode: '001', pearlToneName } : { pearlToneCode: '', pearlToneName: '' };
  }

  public editiedAvailableOptionPatternWithModel(
    partsIndex: string,
    partsModelPatterns: IPartsModelPattern[],
    selecting: IDesignSelectingParam,
    category: TCategory,
    brand: string,
    isMergedSpare: boolean = false,
  ): IOptionPatternWithSelectedInfoAndModel[] {
    const targetParts = !isMergedSpare
      ? this.editiedAvailableOption(partsIndex)
      : this.editiedAvailableOptionMergedSpareFromPartsIndex(partsIndex);
    if (!targetParts) {
      return [];
    }

    const { modelPatterns } = partsModelPatterns.find(by('partsIndex')(partsIndex)) || { modelPatterns: [] };
    const { optionPatterns } = targetParts;

    // モデル選択済みでない場合は最初のモデルを選択して返す
    const modelPatternCode = targetParts.modelPatternCode || (modelPatterns.length > 1 ? modelPatterns[0] : '');
    const optionPatternsWithIsModels = optionPatterns.map(
      v =>
        ({
          ...v,
          isModel: false,
          optionClasses: v.optionClasses.map(vv => ({
            ...vv,
            imagePath: getOptionImagePath(v.optionNumber, vv.optionClassNumber, category, modelPatternCode, brand),
            retailPrice: targetParts.isSpare ? 0 : vv.retailPrice,
            retailPriceTaxin: targetParts.isSpare ? 0 : vv.retailPriceTaxin,
          })),
        } as IOptionPatternWithSelectedInfoAndModel),
    );

    // modelがない場合はそのまま返却
    if (modelPatterns.length < 1) {
      return optionPatternsWithIsModels;
    }

    const hasSelecting = selecting.partsIndex === partsIndex && selecting.optionNumber === MODEL_SELECTOR_OPTION_NUMBER;
    const modelOptionPattern = getModelOptionPattern(
      modelPatterns,
      targetParts.modelPatternCode,
      category,
      hasSelecting,
      brand,
    );
    return [modelOptionPattern, ...optionPatternsWithIsModels];
  }

  private getTotalPrice(key: keyof Pick<IOptionClass, 'retailPrice' | 'retailPriceTaxin'>) {
    const getPrice = (optionClasses: IOptionClass[], optionClassNumber: string) =>
      (getOptionClassProp(optionClasses, optionClassNumber, key) || 0) as number;

    return this.state.reduce(
      (acc, curr) =>
        acc +
        curr.optionPatterns.reduce(
          (acc2, curr2) => (curr.isSpare ? 0 : acc2 + getPrice(curr2.optionClasses, curr2.selectingClassNumber)),
          0,
        ),
      0,
    );
  }

  // TODO: そもそも、IPartsOptionListItem って要るの？？？
  private getPartsOptionListItems(
    selecting: IDesignSelectingParam,
    partsModelPatterns: IPartsModelPattern[],
    category: TCategory,
    brand: string,
    isEdit: boolean,
  ): IPartsOptionListItem[] {
    return this.state.map(v => ({
      partsIndex: v.partsIndex,
      partsNumber: v.partsNumber,
      isSpare: v.isSpare,
      items: this.editiedAvailableOptionPatternWithModel(
        v.partsIndex,
        partsModelPatterns,
        selecting,
        category,
        brand,
      ).map(vv => {
        const selectedOptionClass = vv.optionClasses.find(by('optionClassNumber')(vv.selectingClassNumber));
        // スペアで選択できないオプション
        const isSpareNoSelectOption = v.isSpare && !canSelectSpareOption(vv.optionNumber);
        const isInfluencedByEmbroidery = isInfluencedByEmbroideryOptionNumber(vv.optionNumber, v.partsNumber);
        const options = toIOptionFromIOptionPatternWithSelectedInfo(v.optionPatterns);
        const canInputEmbroideryOption = isInputEmbroideryRequired(options, v.partsNumber);
        const isValidInputEmbroidery = isValidEmbroidery(
          vv.selectingClassName,
          getEmbroideryType(options, v.partsNumber),
          v.partsNumber,
          category,
        );
        const isShirtModel = isShirtModelOption(vv.optionNumber);
        const isValidCombination = isValidOptionsCombination(
          OPTION_COMBINATION_FUNCTIONS_CONFIG,
          OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY,
          v.partsNumber,
          vv.optionNumber,
          vv.selectingClassNumber,
          options,
        );
        const isDisable = isDisableOption(
          vv.isFollowToJacketOption,
          isSpareNoSelectOption,
          vv.isFreeInput,
          vv.optionClasses,
          isInfluencedByEmbroidery,
          canInputEmbroideryOption,
          isEdit,
          vv.isModel || isShirtModel,
          vv.isDesignOption,
        );
        const isRequired = isRequiredOption(
          vv.isRequired,
          isDisable,
          vv.isFreeInput,
          vv.selectingClassNumber,
          canInputEmbroideryOption,
          isValidInputEmbroidery,
          isValidCombination,
        );
        const className = getOptionClassName(
          vv.selectingClassName,
          vv.isFollowToJacketOption,
          isSpareNoSelectOption,
          isInfluencedByEmbroidery,
          canInputEmbroideryOption,
        );

        return {
          optionNumber: vv.optionNumber,
          optionName: vv.optionName,
          isSpecial: vv.isSpecial,
          paperState: {
            isRequired,
            className,
            isDisable,
            classNumber: vv.selectingClassNumber,
            priceTaxIn: selectedOptionClass ? selectedOptionClass.retailPriceTaxin : 0,
            hasSelecting: vv.hasSelecting,
          },
          selectBoxState: {
            selectingClassNumber: vv.selectingClassNumber,
            hasOpen: vv.isSpecial && vv.hasSelecting && selecting.hasOpenSelector,
            classes: vv.optionClasses,
          },
        };
      }),
    }));
  }
}

/** 編集不可項目か？（背景がグレーか？） */
function isDisableOption(
  isFollowToJacketOption: boolean,
  isSpareNoSelectOption: boolean,
  isFreeInput: boolean,
  selectableOptionClasses: IOptionClass[],
  isInfluencedByEmbroidery: boolean,
  canInputEmbroideryOption: boolean,
  isEdit: boolean,
  isModel: boolean,
  isDesignOption: boolean,
): boolean {
  // 編集時、modelとmodelCodeとなるオプションは変更不可
  if (isEdit && (isDesignOption || isModel)) {
    return true;
  }
  // ジャケットに準ずるか
  if (isFollowToJacketOption) {
    return true;
  }
  // スペアかつ選択できない項目か
  if (isSpareNoSelectOption) {
    return true;
  }
  // 自由入力でないかつ非選択項目か
  if (!isFreeInput && selectableOptionClasses.length === 1) {
    return true;
  }
  // 刺繍ネームに影響を受ける項目かつ入力不可か？
  if (isInfluencedByEmbroidery && !canInputEmbroideryOption) {
    return true;
  }
  return false;
}

/** 必須オプションか？（背景が赤か？） */
function isRequiredOption(
  isRequired: boolean,
  isDisable: boolean,
  isFreeInput: boolean,
  selectedClassNumber: string,
  canInputEmbroideryOption: boolean,
  isValidInputEmbroidery: boolean,
  isValidCombination: boolean,
): boolean {
  // 自由入力（刺繍ネーム）
  if (isFreeInput) {
    // 入力可能かつ正しい入力値でない場合
    return canInputEmbroideryOption && !isValidInputEmbroidery;
  }
  // オプションの整合性がない場合
  if (!isValidCombination) {
    return true;
  }
  // 必須か？
  if (!isRequired) {
    return false;
  }
  // 編集不可項目か？
  if (isDisable) {
    return false;
  }
  // 未選択状態か？（自由入力以外）
  if (selectedClassNumber !== '') {
    return false;
  }
  return true;
}

/** 選択中のoptionClassName（表示するオプション種別名） */
function getOptionClassName(
  selectingClassName: string,
  isFollowToJacketOption: boolean,
  isSpareNoSelectOption: boolean,
  isInfluencedByEmbroidery: boolean,
  canInputEmbroideryOption: boolean,
): string {
  // 刺繍ネームに影響を受ける項目かつ刺繍ネームがなしの場合
  if (isInfluencedByEmbroidery && !canInputEmbroideryOption) {
    return 'なし';
  }
  // 選択済みの場合
  if (selectingClassName) {
    return selectingClassName;
  }
  // ジャケットに準ずるか
  if (isFollowToJacketOption) {
    return 'ジャケットに準ずる';
  }
  // スペアかつ選択できない項目か
  if (isSpareNoSelectOption) {
    return '1本目に準ずる';
  }
  return '未設定';
}

function getModelOptionPattern(
  selectableModelPatterns: string[],
  selectedModelPattern: string,
  category: TCategory,
  hasSelecting: boolean,
  brand: string,
): IOptionPatternWithSelectedInfoAndModel {
  return {
    hasSelecting,
    isModel: true,
    isRequired: true,
    selectingClassNumber: selectedModelPattern,
    selectingClassName: selectedModelPattern,
    optionNumber: MODEL_SELECTOR_OPTION_NUMBER,
    optionName: 'モデル',
    optionClasses: selectableModelPatterns.map(model => {
      return {
        optionClassNumber: model,
        optionClassName: model,
        imagePath: getModelImagePath(category, model, brand),
        isDefault: false,
        retailPrice: 0,
        retailPriceTaxin: 0,
      };
    }), //  以下不要
    isDesignOption: false,
    optionPattern: '',
    optionPatternName: '',
    isFollowToJacketOption: false,
    isFreeInput: false,
    isSpecial: false,
    isTakeoverability: false,
  };
}
