import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';

import ItemConfirmationPage from '../../../components/pages/ItemConfirmationPage/ItemConfirmationPage';

import { ItemConfirmationPageEvents } from '../../../views/events/ItemConfirmationPage';
import { getConfirmation } from '../../../store/order/selector/confirmation';
// import { get as getOrder } from '../../store-new/order';
import { confirmationPageActions } from '../../../store/pages/confirmation';
import { mapCommonPageDispatchToProps } from '../CommonPage';
import { getLanguage } from '../../../store/utils/language';
import { get } from '../../../store/customer';
import { get as getOrder } from '../../../store/order';
import { customerLoadDialogActions } from '../../../store/utils/dialog/customer-load';

function mapStateToProps(state: AppState) {
  return {
    isEdit: getOrder.isEdit(state),
    pricePrefix: getLanguage.pricePrefix(state),
    newOrderNumber: getConfirmation.newOrderNumber(state),
    totalPayment: getConfirmation.totalPayment(state),
    orderConfirmations: getConfirmation.confirmationsForItemPage(state),
    memberscardNumber: get.memberscardNumber(state),
    isValidAllOrders: getConfirmation.isValidAllOrders(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(ItemConfirmationPageEvents, dispatch),
    onEditOrder: confirmationPageActions.editOrder.dispatcher(dispatch),
    onCopyOrder: confirmationPageActions.copyOrder.dispatcher(dispatch),
    onDeleteOrder: confirmationPageActions.deleteOrder.dispatcher(dispatch),
    onShowCustomerLoadDialog: customerLoadDialogActions.show.dispatcher(dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ItemConfirmationPage);
