import { IProgressRequest } from './../../services/progress/pregress';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ApiError } from '../../models/error/api-error';
import { inicialLocalState, ILocalState, TProgressList } from './state';
import { setCookie } from '../../services/storage/index';
import { getDefaultState } from './helper';

const actionCreator = actionCreatorFactory('[Progress]');

export const actions = {
  /**
   * 途中保存の自動実行
   */
  autoUpdate: actionCreator.async<void, void, ApiError>('auto-update'),
  sendRegistApi: actionCreator.async<IProgressRequest, void, ApiError>('send regist API'),
  update: actionCreator.async<string, void, {}>('update'),
  updateProgresskey: actionCreator<string>('updateProgressKey'),
  initialProc: actionCreator<void>('initialProc'),
  loadProgressData: actionCreator.async<string, Partial<ILocalState>, ApiError>('loadProgressData'),
  loadProgressList: actionCreator.async<void, TProgressList, ApiError>('loadProgressList'),
  deleteProgressData: actionCreator.async<IProgressRequest, void, {}>('deleteProgressData'),
  addQueue: actionCreator<number | void>('addQueue'),
  /**
   * ログアウト時、注文完了時など、別データとして途中保存を扱う場合に利用
   */
  // reset: actionCreator<void>('reset'),
};

export const reducer = reducerWithInitialState(inicialLocalState)
  .case(actions.update.done, (state, payload) => ({
    ...getDefaultState(),
    progressList: state.progressList,
  }))
  .case(actions.updateProgresskey, (state, payload) => {
    setCookie('progressKey', payload);
    return { ...state, progressKey: payload };
  })
  .case(actions.loadProgressData.done, (state, payload) => ({
    ...state,
    ...payload.result,
  }))
  .case(actions.loadProgressList.done, (state, payload) => ({
    ...state,
    progressList: payload.result,
  }));
// .case(actions.reset, () => getDefaultState());
