import { connect } from 'react-redux';
import { AppState } from '../../store';

import OrderContentPage from '../../components/pages/OrderContentPage/OrderContentPage';
import { getConfirmation } from '../../store/order/selector/confirmation';
import { getAddress } from '../../store/order/selector/address';
import { customerLoadDialogActions } from '../../store/utils/dialog/customer-load';
import { Dispatch } from 'redux';
import { getLocation } from 'connected-react-router';
import { get } from '../../store/customer';
import { get as getOrder } from '../../store/order';

function mapStateToProps(state: AppState) {
  return {
    isValidAllOrders: getConfirmation.isValidAllOrders(state),
    isLoggedInCustomer: getConfirmation.isLoggedInCustomer(state),
    membersCardNumber: get.memberscardNumber(state),
    hasAddressCompleted: getAddress.hasCompleted(state),
    getLocation: getLocation(state),
    isEdit: getOrder.isEdit(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onShowCustomerLoadDialog: customerLoadDialogActions.show.dispatcher(dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderContentPage);
