import React from 'react';

// import styles from './InformationDialog.module.scss';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '../../atoms/Button/Button';
import { TInformationDialogProps } from '../../../containers/components/dialogs/InformationDialog';
import { Nullable } from '../../../types';
import { langResource } from '../../../i18n/_new/resources/';

type TProps = TInformationDialogProps;

const InformationDialog: React.FC<TProps> = (props: TProps) => {
  const { hasOpen, onClose } = props;
  const { title, contents, onOK } = props.data;

  const onCloseHandler = () => {
    onClose({});
  };

  const onOkHandler = () => {
    if (onOK) {
      onOK();
    }
    onCloseHandler();
  };

  return (
    <Dialog className="dialog" open={hasOpen} onClose={onCloseHandler} aria-labelledby="form-dialog-title">
      {renderTitle(title)}
      <DialogContent className="dialog__contents">{renderContents(contents)}</DialogContent>
      <DialogActions className="dialog__actions">
        <Button onClick={onOkHandler} textObj={langResource.dialog.closeButtonLabel} />
      </DialogActions>
    </Dialog>
  );

  function renderTitle(value: Nullable<string>) {
    return value ? <DialogTitle id="form-dialog-title">{value}</DialogTitle> : null;
  }

  function renderContents(value: string) {
    return (
      <div className="content-wrapper">
        <p>{value}</p>
      </div>
    );
  }
};

export default InformationDialog;
