import React from 'react';

import styles from './ItemConfirmation.module.scss';

import PriceList from '../../../molecules/PriceList/PriceList';
import PriceText from '../../../atoms/PriceText/PriceText';
import I18TextContainer from '../../../../containers/I18Text/I18Text';

import { IOrderConfirmation } from '../../../../types/new-store/components';
import { langResource } from '../../../../i18n/_new/resources';
import { TPricePrefix, IPriceRow } from '../../../../types/orders';

interface IProps {
  pricePrefix: TPricePrefix;
  order: IOrderConfirmation;
}

const ItemConfirmation: React.FC<IProps> = (props: IProps) => {
  const { item } = props.order;
  const { item: itemCode, deliveryDate } = item;

  if (!item.extraInfo) {
    return (
      <div id={styles.itemContainer}>
        <div className={styles.set}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.item} /> ：
          </div>
          <div className={styles.value}>{itemCode}</div>
        </div>
        <div className={styles.set}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.appointedDay} /> ：
          </div>
          <div className={styles.value}>{deliveryDate}</div>
        </div>
      </div>
    );
  }

  const { pricePrefix } = props;
  const { totalCost, productPrice, optionPrice } = item.extraInfo;
  const rows = getPriceRows(pricePrefix, productPrice, optionPrice);

  return (
    <div id={styles.container}>
      <div className={styles.left}>
        <div className={styles.set}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.item} /> ：
          </div>
          <div className={styles.value}>{itemCode}</div>
        </div>
        <div className={styles.set}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.appointedDay} /> ：
          </div>
          <div className={styles.value}>{deliveryDate}</div>
        </div>
        <div className={styles.set}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.totalCost} /> ：
          </div>
          <div className={styles.value}>
            <PriceText prefix={pricePrefix} price={totalCost} postfix="tax" />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <PriceList
          rows={rows}
          totalTextObj={langResource.confirmationPage.totalCost}
          showTotalTaxIn={true}
          isTotalBold={true}
          totalPrefix={pricePrefix}
        />
      </div>
    </div>
  );
};

function getPriceRows(pricePrefix: TPricePrefix, productPrice: number, optionPrice: number): IPriceRow[] {
  return [
    {
      textObj: langResource.confirmationPage.productPrice,
      price: productPrice,
      showTaxIn: false,
      prefix: pricePrefix,
      postfix: '',
    },
    {
      textObj: langResource.confirmationPage.optionPrice,
      price: optionPrice,
      showTaxIn: false,
      prefix: pricePrefix,
      postfix: '',
    },
  ];
}

export default ItemConfirmation;
