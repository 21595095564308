export const loginPage = {
  description: { jp: '発注・決済・注文書発行サービスにログインします。', en: 'laguage test login' },
  tempoCodeLabel: { jp: '店舗コード', en: 'Shop Code' },
  tempoIdLabel: { jp: '店舗ID', en: 'Shop Code' },
  staffCodeLabel: { jp: '担当者コード', en: 'Staff Code' },
  staffPasswordLabel: { jp: '担当者パスワード', en: 'staff password' },
  loginButtonLabel: { jp: 'ログイン', en: 'LOGIN' },
  loggingInMessage: { jp: 'ログイン中。。。', en: 'Logging now...' },
  customerName: { jp: 'お名前' },
  customerId: { jp: 'ID' },
  customerLoadButton: { jp: '読み込み' },
  logoutDialogText: {
    jp:
      'お客様アカウント(読み込み情報)をログアウトして初期状態に戻します。このサービスの利用を終了する場合は、担当者アカウントからもログアウトしてください。',
  },
  logoutButtonLabel: { jp: 'ログアウト' },
};
