import { actions } from './action-reducer';
import { AppState } from '..';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, map } from 'rxjs/operators';
import { getInventory } from '../../services/inventory-search/inventory-search';
import { ApiError } from '../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../store/errorHandling/action';

const load: Epic<
  AnyAction,
  Action<Parameters<typeof actions.load.done>[0] | { error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.load.started),
    mergeMap(action => {
      return getInventory().catch(err => err);
    }),
    map(inventory => {
      if (inventory instanceof ApiError) {
        return ErrorHandlerActions.apiError({
          error: inventory,
          options: { contents: '条件に該当するデータがありません.' },
        });
      } else {
        return actions.load.done({ result: inventory });
      }
    }),
  );

export const InventoryEpics = combineEpics(load);
