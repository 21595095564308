import { TMaster } from '../../types/index';
import { rawStrArr2EnumLikeObj } from '../../lib/utils';

const parts = rawStrArr2EnumLikeObj(['01', '02', '04', '05', '06', '09', '10', '12']);

export type TPartsNumber = keyof typeof parts;

export const EParts = {
  jaket: parts['01'],
  pants: parts['02'],
  vest: parts['04'],
  skirt: parts['05'],
  coat: parts['06'],
  shirt: parts['09'],
  onepiece: parts['10'],
  tops: parts['12'],
};

export const allPartsArr: TPartsNumber[] = Object.entries(EParts).map(([_, v]) => v);

export const MASTER_PARTS: Array<TMaster<TPartsNumber>> = [
  { code: EParts.jaket, value: 'ジャケット' },
  { code: EParts.pants, value: 'パンツ' },
  { code: EParts.vest, value: 'ベスト' },
  { code: EParts.skirt, value: 'スカート' },
  { code: EParts.coat, value: 'コート' },
  { code: EParts.shirt, value: 'シャツ' },
  { code: EParts.onepiece, value: 'ワンピース' },
  { code: EParts.tops, value: 'トップス' },
];

// パーツ別の購入数
export type TPartsCount = { [key in TPartsNumber]: number };
