import actionCreatorFactory from 'typescript-fsa';
import { ILoadOrderDetail } from '../../types/orders/digest';

const actionCreator = actionCreatorFactory('- PurchaseHistoryDialog ');

export const PurchaseHistoryDialogEvents = {
  onLoadPurchaseHistory: actionCreator<void>('onLoadPurchaseHistory'),
  onClose: actionCreator<void>('onClose'),
  onSetPartsSizeHistory: actionCreator<ILoadOrderDetail>('onSetPartsSizeHistory'),
};
