import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory('- CouponInfoDialog');

const events = {
  load: ac<void>('load'),
  show: ac<void>('show'),
  close: ac<void>('close'),
};
export const CouponInfoDialogEvents = events;
