import { TOrderItemCode } from '../../lookups/master-thisisforreplaceall/item-code';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall/parts';
import { deliveryRequestConfig, DeliveryRes } from '../../api/customizesystemproxy/delivery/index';
import { fetchApi } from '../../api/_common/fetcher';
import { POSTALCODE_HARUYAMA } from '../../lookups/postalcode';

export const getShortestDeliveryDate = async (
  params: {
    itemCode: TOrderItemCode;
    brandCode: string;
    clothBrandCode: string;
    productSeason: string; // TODO: ICloth で、 TSeasonCode を利用していないためstringに。
    colorCode: string; // TODO: color用の型は無い？
    productNumber: string;
    postalCode?: string;
  },
  partsInfo: Array<{
    partsNumber: TPartsNumber;
    partsName: string;
    modelCode?: string;
    modelPattern: string;
    sizeCode?: string;
    option?: Array<{
      optionNumber: string;
      optionName: string;
      optionClassNumber: string;
      optionClassName: string;
    }>;
  }>,
): Promise<{ shortestDeliveryDate: string }> => {
  const postalCode = params.postalCode || POSTALCODE_HARUYAMA;

  const requestBody = partsInfo.map(v => ({
    partsNumber: v.partsNumber,
    partsName: v.partsName,
    modelCode: v.modelCode || 'dummy',
    modelPattern: v.modelPattern,
    sizeCode: v.sizeCode || 'dummy',
    option: v.option || [
      {
        optionNumber: '9999',
        optionName: 'dummy',
        optionClassNumber: '999',
        optionClassName: 'dummy',
      },
    ],
  }));

  const reqParam = {
    query: {
      ...params,
      postalCode,
    },
    body: {
      parts: requestBody,
    },
  };

  const res = await fetchApi(DeliveryRes, deliveryRequestConfig, reqParam);
  return {
    shortestDeliveryDate: res.query('shortestDeliveryDate'),
  };
};
