import { IMessage } from '../types/error';

export const CLIENT_ERROR_PREFIX = 'Client';

export const ERROR_MESSAGES: IMessage[] = [
  {
    code: '0001',
    message: 'ItemCode not founded Category: {0}, Sub Category: {1}, Parts: {2}',
  },
  {
    code: '0002',
    message: 'not found seasonCode.\n currentDate: {0}',
  },
  {
    code: '0003',
    message: 'Component clashed because something went wrong.\nError Detail:{0}',
  },
  {
    code: '0004',
    message: 'モデルコードを検出できません。デザイン選択後に再度お試しください。',
  },
  {
    code: '0005',
    message: '選択したバーツのマスターサイズを取得できません。',
  },
  {
    code: '0006',
    message: '該当するItemを検出できませんでした。',
  },
  {
    code: '0007',
    message: '合致するPartsNumberを検出できませんでした。partsIndex: {0}',
  },
  {
    code: '0008',
    message: 'OptionNumberの値が不正です。optionNumber: {0}',
  },
  {
    code: '0009',
    message: 'オプションのデフォルト値（お勧め）を取得できませんでした。optionNumber: {0}',
  },
  {
    code: '0010',
    message: '合致するPartsNameを検出できませんでした。partsIndex: {0}, pieces: {1}',
  },
  {
    code: '0011',
    message: '合致するPartsNumberを検出できませんでした。partsIndex: {0}, pieces: {1}',
  },
  {
    code: '0012',
    message: 'デザイン・オプションと合致するサイズ補正のデータが取得できませんでした。partsNumber: {0}',
  },
  {
    code: '0013',
    message: '条件に該当する{0}のデータがありません.',
  },
];

export const WARM_MESSAGES: IMessage[] = [
  {
    code: '0001',
    message: 'hoge hoge',
  },
  {
    code: '0002',
    message: '有効な郵便番号を入力してください',
  },
  {
    code: '0003',
    message: '有効なEmailアドレスを入力してください',
  },
  {
    code: '0004',
    message: '有効な電話番号を入力してください',
  },
];

export const INFO_MESSAGES: IMessage[] = [
  {
    code: '0001',
    message: 'hoge hoge',
  },
  {
    code: '0002',
    message: 'fuga fuga',
  },
];
