import React from 'react';

import styles from './ProjectRegister.module.scss';
import Label from '../../atoms/Label/Label';
import Title from '../../atoms/Title/Title';
import Button from '../../atoms/Button/Button';
import InputText from '../../../containers/components/InputText';
import { langResource } from '../../../i18n/_new/resources';
import { TPageContainerProps } from '../../../containers/pages/PointPage';
import { canEditItem } from '../../../helpers/orders/digest';
import { PaymentItem } from '../../../lookups/item-thisisforreplaceall/order-status';

type TProps = Pick<
  TPageContainerProps,
  'showProjectSelectionDialog' | 'selectedScProject' | 'payment' | 'isEdit' | 'orderStatus' | 'isManager'
>;
const ProjectRegister: React.FC<TProps> = (props: TProps) => {
  const { showProjectSelectionDialog, selectedScProject, payment, isEdit, isManager, orderStatus } = props;
  const hasSelected = payment.hasFavoriteCustomer && selectedScProject.companyName;
  const canSelect =
    payment.hasFavoriteCustomer && (!isEdit || canEditItem(orderStatus, isManager, PaymentItem.hasFavoriteCustomer));
  const hasShownErrorMessage = payment.hasFavoriteCustomer && !selectedScProject.companyName;

  const onSelectProject = () => {
    showProjectSelectionDialog();
  };

  return (
    <div className={styles.distributionCategory}>
      <div className={styles.title}>
        <Title styles={['medium', 'lineLightGray']} textObj={langResource.pointPage.projectRegist} />
      </div>
      {hasSelected ? (
        <React.Fragment>
          <div className={styles.setCode}>
            <div className={styles.inputSet}>
              <div className={styles.label}>
                <Label textObj={langResource.pointPage.companyName} />
              </div>
              <div className={styles.inputItem}>
                <InputText disabled={true} value={selectedScProject.companyName} styles={['colorBlack']} />
              </div>
            </div>
          </div>
          <div className={styles.setCode}>
            <div className={styles.inputSet}>
              <div className={styles.label}>
                <Label textObj={langResource.pointPage.projectName} />
              </div>
              <div className={styles.inputItem}>
                <InputText disabled={true} value={selectedScProject.projectName} styles={['colorBlack']} />
              </div>
            </div>
          </div>
          <div className={styles.setCode}>
            <div className={styles.inputSet}>
              <div className={styles.label}>
                <Label textObj={langResource.pointPage.contactName} />
              </div>
              <div className={styles.inputItem}>
                <InputText disabled={true} value={selectedScProject.contactName} styles={['colorBlack']} />
              </div>
            </div>
          </div>
          <div className={styles.setCode}>
            <div className={styles.inputSet}>
              <div className={styles.label}>
                <Label textObj={langResource.pointPage.salesRecordingDate} />
              </div>
              <div className={styles.inputItem}>
                <InputText disabled={true} value={selectedScProject.salesRecordingDate} styles={['colorBlack']} />
              </div>
            </div>
          </div>
          <div className={styles.setCode}>
            <div className={styles.btn}>
              <Button
                textObj={langResource.pointPage.btnReselectProject}
                onClick={onSelectProject}
                disabled={!canSelect}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles.errMsgWrapper}>
            {hasShownErrorMessage && <div className="error-tooltip">訪問販売先の場合、案件登録は必須になります.</div>}
          </div>
          <div className={styles.btn}>
            <Button textObj={langResource.pointPage.btnSelectProject} onClick={onSelectProject} disabled={!canSelect} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ProjectRegister;
