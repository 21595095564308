import actionCreatorFactory from 'typescript-fsa';
import { IRequiredCheckDialog } from '../../../../types/dialog';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

const actionCreator = actionCreatorFactory('[- required check]');

export const actions = {
  show: actionCreator<IRequiredCheckDialog>('show'),
  close: actionCreator('close'),
};

const initialState: IRequiredCheckDialog = {
  hasOpen: false,
  texts: [],
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.show, (state, payload) => ({
    ...payload,
  }))
  .case(actions.close, (state, payload) => ({ ...initialState }));
