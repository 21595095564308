import { IItem, ICloth, IShipping, IDesign, ISize, IOrder, TPartialOrder, TPartialShipping } from '../../_type/order';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap, filter } from 'rxjs/operators';
import { orderActions } from '../../order';
import { actions } from './actions';
import { appStateSelector } from '../../../helpers/object-selector/app-state';

import { getShortestDeliveryDate } from '../../../services/orders/shortestDeliveryDate';
import { getStockFlag } from '../../../services/orders/stockFlag';
import { MASTER_PARTS, TPartsNumber, EParts } from '../../../lookups/master-thisisforreplaceall/parts';
import { getValue } from '../../../helpers/master-lookup';
import {
  clothProductsSelector,
  availableOptionsSelector,
  editedAvailableOptionsSelector,
  sizeMeasurementsViewSelector,
  sizeMeasurementsSelector,
  adjustOptionsViewSelector,
  adjustOptionsSelector,
} from '../../lookups/object-selector';
import { ERouterPath, IndexedObject } from '../../../types';
import { joinGauge } from '../../../helpers/size-correction';
import { IClothProduct } from '../../_type/lookups';
import { infoDialogActions } from '../../utils/dialog/info';
import { IInformationDialog } from '../../../types/dialog';
import { STOCK_FLAG } from '../../../types/inventory-search';
import { ApiError } from '../../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../errorHandling/action';
import { piecesSelector } from '../object-selector';
import {
  getItemCodeForChangeModelWithWomenAndEasy,
  getItemCodeForClothSelectPage,
  getItemCodeWithDetailConditions,
} from '../../../helpers/orders/order-items';
import Logger from '../../../helpers/common/logger';
import { getInitialOrderNumber } from '../../../helpers/orders';
import { getPartialOrder } from '../helper/conv-partial-order';
import { isValidPostalCode } from '../../../helpers/common/validate';
import { KR_BRAND, KR_BRAND_COLOR_CODE } from '../../../lookups/item-thisisforreplaceall';
import { WrapAction } from '../../_type';
import { getLocation } from 'connected-react-router';
import { TOrderItemCode } from '../../../lookups/master-thisisforreplaceall';

/** 生地選択画面でモデルを選択していないとき */
const getInitialPartsInfo = (cloth: ICloth, item: IItem, partsNumber: TPartsNumber, products: IClothProduct[]) => {
  const { itemCode, categoryCode, pieces, subCategoryCode } = item;
  const { brandCode, clothModelCode } = cloth;
  const clothSelecter = clothProductsSelector({
    itemCode,
    brandCode,
    products,
    subCategoryCode
  });
  const selectableClothPageModels = clothSelecter.getSelectableClothModelCodes(categoryCode, pieces, brandCode);
  const selectableDesignPageModels = clothSelecter.getSelectableDesignModelCodes(categoryCode, partsNumber);
  const modelPattern =
    selectableDesignPageModels.length > 0
      ? // デザイン選択でモデルを選択できる場合
        selectableDesignPageModels[0]
      : clothModelCode
      ? // 生地選択画面で設定したモデルがある場合
        clothModelCode
      : // ない場合は先頭を返却
        selectableClothPageModels[0] || '';
  return {
    partsNumber,
    partsName: getValue(partsNumber, MASTER_PARTS) || '',
    modelPattern,
  };
};

const loadShortestDeliveryDate: Epic<
  AnyAction,
  | Action<{ shortestDeliveryDate: string; orderNumber: string }>
  | WrapAction<typeof actions.readShortestDeliveryDate.started>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadShortestDeliveryDate),
    map(({ payload }) => {
      const selector = appStateSelector(state.value);
      const { orderNumber } = payload;
      return {
        orderNumber: selector.orderNumber(orderNumber),
        order: selector.order(orderNumber),
        isEdit: selector.isEditOrder(),
      };
    }),
    filter(({ isEdit }) => !isEdit),
    filter(({ order }) => !!order),
    mergeMap(({ orderNumber, order }) => {
      const priviousDate = order ? order.shortestDeliveryDate : '';
      return [
        actions.readShortestDeliveryDate.started({ orderNumber, priviousDate }),
        orderActions.updateShortestDeliveryDate._action({
          shortestDeliveryDate: '', // 初期化する
          orderNumber,
        }),
      ];
    }),
  );

const readShortestDeliveryDate: Epic<
  AnyAction,
  | Action<void>
  | Action<{ error: ApiError; options: any }>
  | WrapAction<typeof actions.readShortestDeliveryDate.done>
  | WrapAction<typeof actions.readShortestDeliveryDate.failed>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.readShortestDeliveryDate.started),
    map(({ payload }) => {
      const selector = appStateSelector(state.value);
      const { orderNumber } = payload;
      return {
        payload,
        orderNumber: selector.orderNumber(orderNumber),
        orders: selector.orders(),
        item: selector.item(orderNumber),
        cloth: selector.cloth(orderNumber),
        design: selector.design(orderNumber),
        size: selector.size(orderNumber),
        shipping: selector.shipping(orderNumber),
        avaliableOptions: selector.avaliableOptions(orderNumber),
        sizeMeasurements: selector.sizeMeasurements(orderNumber),
        adjustOptions: selector.adjustOptions(orderNumber),
        products: selector.products(orderNumber),
      };
    }),
    filter(
      ({ item, cloth, shipping, products }) =>
        item !== undefined &&
        shipping !== undefined &&
        products !== undefined &&
        cloth !== undefined &&
        cloth.brandCode !== '' &&
        cloth.personalorderColorCode !== '',
    ),
    mergeMap(async obj => {
      const { payload } = obj;
      const orders = obj.orders as IndexedObject<IOrder>;
      const item = obj.item as IItem;
      const cloth = obj.cloth as ICloth;
      const design = obj.design as IDesign;
      const size = obj.size as ISize;
      const shipping = obj.shipping as IShipping;
      const products = obj.products as IClothProduct[];
      const initialPiece = item.pieces[0] || { partsNumber: EParts.jaket };
      const initialPartsInfo = getInitialPartsInfo(cloth, item, initialPiece.partsNumber, products);
      const initialOrderNumber = getInitialOrderNumber(orders);
      const postalCode = shipping.isSameOrderOne
        ? orders[initialOrderNumber].shipping.shippingPostalCode
        : shipping.shippingPostalCode;
      // partsの重複を省く
      const distinctPieces = piecesSelector(item.pieces).distinctPieces();

      const eaos =
        Object.keys(design.designParts).length > 0 && typeof obj.avaliableOptions !== 'undefined'
          ? editedAvailableOptionsSelector(
              availableOptionsSelector(obj.avaliableOptions).withSelectedInfo(
                item.pieces,
                design.designParts,
                design.selecting,
                item.categoryCode,
                item.cloth.clothModelCode,
                item.cloth.brandCode
              ),
            )
          : undefined;

      const sizeView =
        Object.keys(size.parts).length > 0 && typeof obj.sizeMeasurements !== 'undefined'
          ? sizeMeasurementsViewSelector(sizeMeasurementsSelector(obj.sizeMeasurements).toView(item.pieces, size.parts))
          : undefined;

      const adjustView =
        Object.keys(size.parts).length > 0 && typeof obj.adjustOptions !== 'undefined'
          ? adjustOptionsViewSelector(adjustOptionsSelector(obj.adjustOptions).toView(item.pieces, size.parts))
          : undefined;

      const partsInfo =
        typeof eaos !== 'undefined' && typeof sizeView !== 'undefined' && typeof adjustView !== 'undefined'
          ? distinctPieces.map(piece => {
              const { index: partsIndex } = piece;
              const editedAvailableOption = eaos.editiedAvailableOption(partsIndex);
              const sizeMeasurements = sizeView.getFromPartsIndex(partsIndex);
              const adjustOptions = adjustView.getFromPartsIndex(partsIndex);
              if (!editedAvailableOption || !sizeMeasurements || !adjustOptions) {
                return getInitialPartsInfo(cloth, item, piece.partsNumber, products);
              }
              return {
                partsNumber: editedAvailableOption.partsNumber,
                partsName: editedAvailableOption.partsName,
                modelCode: editedAvailableOption.modelCode,
                modelPattern: editedAvailableOption.modelPatternCode,
                sizeCode: joinGauge(sizeMeasurements.gauge, true),
                option: [
                  // デザインオプション
                  ...editedAvailableOption.optionPatterns
                    .filter(v => !!v.selectingClassNumber)
                    .map(v => {
                      return {
                        optionNumber: v.optionNumber,
                        optionName: v.optionName,
                        optionClassNumber: v.selectingClassNumber,
                        optionClassName: v.selectingClassName,
                      };
                    }),
                  // 自由入力
                  ...editedAvailableOption.optionPatterns
                    .filter(v => v.isFreeInput && v.isRequired)
                    .map(v => {
                      return {
                        optionNumber: v.optionNumber,
                        optionName: v.optionName,
                        optionClassNumber: v.selectingClassNumber,
                        optionClassName: v.selectingClassName || '',
                      };
                    }),
                  // 採寸項目
                  ...sizeMeasurements.measurementItems.map(v => {
                    return {
                      optionNumber: v.measurementNumber,
                      optionName: v.measurementName,
                      optionClassNumber: '',
                      optionClassName: v.value,
                    };
                  }),
                  // 特殊補正
                  ...adjustOptions.adjustOptions
                    .filter(v => !!v.selectedClassNumber)
                    .map(v => {
                      return {
                        optionNumber: v.optionNumber,
                        optionName: v.optionName,
                        optionClassNumber: v.selectedClassNumber,
                        optionClassName: v.selectedClassName,
                      };
                    }),
                ],
              };
            })
          : // MEMO: なぜかdummyの場合、パーツ１つしか許容しない仕様みたいなので先頭を設定
            [initialPartsInfo];

      // 生地選択画面とそれ以外の画面では、納期情報取得APIにリクエストするitem-codeを変える。
      const { pathname } = getLocation(state.value);
      const isClothSelectionPage = pathname.includes(ERouterPath.clothSelection);
      const itemCode = isClothSelectionPage
        ? (getItemCodeForClothSelectPage({
            category: item.categoryCode,
            subCategory: item.subCategoryCode,
            brand: item.cloth.brandCode,
            modelCode: item.cloth.clothModelCode,
          }) as TOrderItemCode)
        : (getItemCodeWithDetailConditions({
            category: item.categoryCode,
            subCategory: item.subCategoryCode,
            pieces: item.pieces,
            brand: item.cloth.brandCode,
            modelCode: item.cloth.clothModelCode,
            designParts: item.design.designParts,
          }) as TOrderItemCode);

      const res = await getShortestDeliveryDate(
        {
          itemCode,
          brandCode: cloth.brandCode,
          clothBrandCode: cloth.clothBrandCode,
          productSeason: cloth.productSeasonCode,
          colorCode: KR_BRAND.includes(cloth.brandCode) ? KR_BRAND_COLOR_CODE : cloth.personalorderColorCode,
          productNumber: cloth.clothCode,
          postalCode: isValidPostalCode(postalCode) ? postalCode : '',
        },
        partsInfo,
      ).catch(err => err);

      return {
        res,
        payload,
      };
    }),
    mergeMap(({ res, payload }) => {
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: '納期情報の取得に失敗しました。' },
          }),
          actions.readShortestDeliveryDate.failed({ params: payload, error: {} }),
        ];
      }
      const { shortestDeliveryDate } = res as { shortestDeliveryDate: string };
      return [actions.readShortestDeliveryDate.done({ params: payload, result: { shortestDeliveryDate } })];
    }),
  );

const readShortestDeliveryDateDone: Epic<
  AnyAction,
  Action<{ shortestDeliveryDate: string; orderNumber: string }> | Action<{ orderNumber: string; order: TPartialOrder }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.readShortestDeliveryDate.done),
    mergeMap(({ payload }) => {
      const { orderNumber, priviousDate } = payload.params;
      const { shortestDeliveryDate } = payload.result;
      const commonAction = orderActions.updateShortestDeliveryDate._action({
        orderNumber,
        shortestDeliveryDate,
      });
      if (shortestDeliveryDate !== priviousDate) {
        // 前の最短納期と取得した最短納期が違った場合、お届け日時関係のstateを初期化する。
        const update: TPartialShipping = {
          deliveryMethod: '',
          deliveryDateGuest: '',
          timeZoneCode: '',
        };
        const order = getPartialOrder.fromShipping(update);
        return [commonAction, orderActions.updateOrder._action({ orderNumber, order })];
      }
      return [commonAction];
    }),
  );

const loadShortestDeliveryDateAllOrders: Epic<AnyAction, Action<void | { orderNumber?: string }>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(actions.loadShortestDeliveryDateAllOrders),
    map(() => {
      const selector = appStateSelector(state.value);
      return { orders: selector.orders() };
    }),
    filter(({ orders }) => typeof orders !== 'undefined' && Object.keys(orders).length > 0),
    mergeMap(obj => {
      const orders = obj.orders as IndexedObject<IOrder>;
      return Object.keys(orders).map(orderNumber => actions.loadShortestDeliveryDate({ orderNumber }));
    }),
  );

const loadStockFlag: Epic<
  AnyAction,
  Action<void | { stockFlag: string; orderNumber: string } | IInformationDialog | { error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadStockFlag),
    map(({ payload }) => {
      const selector = appStateSelector(state.value);
      const { orderNumber } = payload;
      return { item: selector.item(), cloth: selector.cloth(), orderNumber };
    }),
    filter(
      ({ item, cloth }) =>
        item !== undefined &&
        cloth !== undefined &&
        cloth.brandCode !== '' &&
        cloth.stockPlaceCode !== '' &&
        cloth.personalorderColorCode !== '' &&
        cloth.clothCode !== '',
    ),
    mergeMap(async obj => {
      const item = obj.item as IItem;
      const cloth = obj.cloth as ICloth;
      const itemCode = getItemCodeForClothSelectPage({
        category: item.categoryCode,
        subCategory: item.subCategoryCode,
        brand: item.cloth.brandCode,
        modelCode: item.cloth.clothModelCode,
      }) as TOrderItemCode;
      const stockFlag = await getStockFlag({
        itemCode, // 必須項目
        productNumber: cloth.clothCode, // 必須項目
        clothBrandCode: cloth.clothBrandCode, // 自動設定
        productSeason: cloth.productSeasonCode, // 自動設定
        brandCode: cloth.brandCode, // 任意
        // KRの場合、005固定
        colorCode: KR_BRAND.includes(cloth.brandCode) ? KR_BRAND_COLOR_CODE : cloth.personalorderColorCode,
        stockPlaceCode: cloth.stockPlaceCode, // KRの場合、手動設定
      }).catch(err => err);
      return { stockFlag, orderNumber: obj.orderNumber };
    }),
    mergeMap(obj => {
      const { stockFlag, orderNumber } = obj;
      if (stockFlag instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: stockFlag,
            options: { contents: '在庫情報の取得に失敗しました。' },
          }),
          // 失敗したらなしにする
          // TODO: 納期と平仄を合わせて取得前に初期化する処理を追加する
          orderActions.updateStockFlag._action({ stockFlag: STOCK_FLAG.noStock, orderNumber }),
        ];
      }
      if (stockFlag === STOCK_FLAG.littleStock || stockFlag === STOCK_FLAG.noStock) {
        const data = {
          hasOpen: true,
          title: '在庫確認',
          contents: stockFlag === STOCK_FLAG.littleStock ? '在庫が少なくなっています。' : '在庫がありません。',
        };
        return [
          infoDialogActions.show._action(data),
          orderActions.updateStockFlag._action({ stockFlag: obj.stockFlag, orderNumber }),
        ];
      }
      return [orderActions.updateStockFlag._action({ stockFlag: obj.stockFlag, orderNumber })];
    }),
  );

/**
 * アイテムコードを更新する
 * 生地選択画面で、生地品番を変えた時/モデル選択プルダウンを変えた時/ブランド選択プルダウンを変えた時に呼び出される。
 */
const updateItemCode: Epic<AnyAction, Action<TPartialOrder>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.updateItemCode),
    map(({ payload }) => {
      const { orderNumber } = payload;
      const item = appStateSelector(state.value).item(orderNumber);
      return { item };
    }),
    filter(({ item }) => item !== undefined),
    map(({ item }) => item as IItem),
    // TODO: 21SS仮対応(モデル変更時にWのアイテムコードがシングルに戻らないようにするため)
    filter(item => ['MT', 'WM', 'DS'].includes(item.categoryCode)),
    map(item => {
      const itemCode =
        item.categoryCode === 'DS'
          ? getItemCodeForClothSelectPage({
              category: item.categoryCode,
              subCategory: item.subCategoryCode,
              brand: item.cloth.brandCode,
              modelCode: item.cloth.clothModelCode,
            })
          : getItemCodeForChangeModelWithWomenAndEasy({
              category: item.categoryCode,
              subCategory: item.subCategoryCode,
              brand: item.cloth.brandCode,
              modelCode: item.cloth.clothModelCode,
              pieces: item.pieces,
            });
      Logger.log('---- filter itemCode', { current: itemCode, previous: item.itemCode });
      return {
        previous: item.itemCode,
        current: itemCode,
      };
    }),
    filter(({ current, previous }) => current !== undefined && current !== previous),
    map(({ current }) => {
      Logger.log('---- updated itemCode', current);
      return orderActions.updateCurrentOrder._action({ item: { itemCode: current } });
    }),
  );

export const OrderItemEpics = combineEpics(
  loadShortestDeliveryDate,
  loadShortestDeliveryDateAllOrders,
  loadStockFlag,
  updateItemCode,
  readShortestDeliveryDate,
  readShortestDeliveryDateDone,
);
