import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../../../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import uniq from 'ramda/es/uniq';
import { actions } from './action-reducer';
import { map, filter, mergeMap } from 'rxjs/operators';
import { appStateSelector } from '../../../../helpers/object-selector/app-state';
import { getOrderDigests } from '../../../../services/orders/digest';
import { toOrderDigests } from '../../../../helpers/api/converter/order-digest';
import { IOrder } from '../../../_type/order';
import { ICustomer } from '../../../_type/customer';
import { ApiError } from '../../../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../../../store/errorHandling/action';
import { IStaff } from '../../../_type/staff';
import { getModelPatterns } from '../../../../helpers/item-thisisforreplaceall';

const load: Epic<
  AnyAction,
  Action<Parameters<typeof actions.load.done>[0] | { error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.load.started),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const orderNumber = appStateObj.orderNumber();
      const order = appStateObj.order(orderNumber);
      const customer = appStateObj.customer();
      const staff = appStateObj.staff();
      return { order, customer, staff };
    }),
    filter(({ order, customer, staff }) => order !== undefined && !!customer && !!staff),
    mergeMap(async obj => {
      const order = obj.order as IOrder;
      const customer = obj.customer as ICustomer;
      const staff = obj.staff as IStaff;
      const modelPatterns = uniq(getModelPatterns(order.item.design.designParts));
      const modelPattern = modelPatterns.length > 0 ? modelPatterns.join(',') : undefined;
      const params = {
        memberscardNumber: localStorage.getItem('tempMembersCardNumber'),
        brandCode: order.item.cloth.brandCode,
        modelPattern,
      };
      const res = await getOrderDigests(params, staff)
        .then(toOrderDigests)
        .catch(err => err);
      return { res };
    }),
    map(({ res }) => {
      localStorage.removeItem('tempMembersCardNumber');
      if (res instanceof ApiError) {
        return actions.load.done({ result: [] });
      }
      return actions.load.done({ result: res });
    }),
  );

export const PurchaseHistoryEpics = combineEpics(load);
