export const countSJISBytes = (str: string): number =>
  Array.from(str)
    .map((v, i) => i)
    .reduce((pre, index) => {
      const chr = str.charCodeAt(index);
      if (
        (chr >= 0x00 && chr < 0x81) ||
        chr === 0xf8f0 ||
        (chr >= 0xff61 && chr < 0xffa0) ||
        (chr >= 0xf8f1 && chr < 0xf8f4)
      ) {
        // 半角文字の場合は1を加算
        return pre + 1;
      } else {
        // それ以外の文字の場合は2を加算
        return pre + 2;
      }
    }, 0);
