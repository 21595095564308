import React from 'react';

import styles from './OrderClassification.module.scss';

import RadioGroup from '../../atoms/RadioGroup/RadioGroup';
import LinkButton from '../LinkButton/LinkButton';
import I18TextContainer from '../../../containers/I18Text/I18Text';

import { ERouterPath } from '../../../types';
import { TProductKind } from '../../../store/_type/order';
import { TRadioGroup } from '../../../types/new-store/components';
import { langResource } from '../../../i18n/_new/resources';
import Button from '../../atoms/Button/Button';
import { couponInfoDialogActions } from '../../../store/utils/dialog/coupon-info';

type TProps = {
  productKind: TProductKind;
  onChange: (val: any) => any;
  onShowCouponInfo: typeof couponInfoDialogActions.show;
  couponId: string;
};

const radioGroups: TRadioGroup[] = [
  { value: 'E', textObj: langResource.homePage.product },
  { value: 'M', textObj: langResource.homePage.remake },
];

const OrderClassification: React.FC<TProps> = ({ productKind, onChange, onShowCouponInfo, couponId }) => {
  const togleHasOpenCouponInfo = () => {
    onShowCouponInfo();
  };

  return (
    <React.Fragment>
      <div className={styles.leftBlock}>
        <div className={styles.classification}>
          <p className={styles.title}>
            <I18TextContainer textObj={langResource.homePage.classification} />
          </p>
          <RadioGroup
            name="ordersClassification"
            onChanged={onChange}
            groups={radioGroups}
            checkedValue={productKind}
          />
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.btn}>
          {couponId ? (
            <Button onClick={togleHasOpenCouponInfo} textObj={langResource.homePage.couponInfoDisplay} />
          ) : null}
        </div>
        <div className={styles.btn}>
          <LinkButton styles={['search']} to={ERouterPath.ordersSearch} textObj={langResource.homePage.orderSearch} />
        </div>
        <div className={styles.btn}>
          <LinkButton
            styles={['search']}
            to={ERouterPath.inventorySearch}
            textObj={langResource.homePage.fabricDelivery}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default OrderClassification;
