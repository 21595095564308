import { TRequestConfig, TRequestParams } from '../..';
// import config from '../../../configuration/config';
export { AdjustOptionRes } from './adjust-option.class';

export const adjustOptionRequestConfig: TRequestConfig = {
  endpoint:
    '/items/sizes/adjusts/{brand_code}/{item_code}/{season_code}/{parts_number}/{option_pattern}?language_code=',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.path : '';
    return {
      ok: true,
      body: '{}',
    };
  },
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        brand_code: params ? params.path.brandCode : '',
        item_code: params ? params.path.itemCode : '',
        season_code: params ? params.path.seasonCode : '',
        parts_number: params ? params.path.partsNumber : '',
        option_pattern: params ? params.path.optionPattern : '',
      },
    };
  },
};
