import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getNewOrderRequest } from '../../helpers/api/orders/conv-state2orderRequest';
import { IError } from '../../types/error';
import { IRegistCoupon } from '../_type/coupon';

// actions
const actionCreator = actionCreatorFactory('[async - settlement]');

export const settlementActions = {
  preCheck: actionCreator.async<{}, {}, {}>('preCheck'),
  registOrders: actionCreator.async<{}, { resList: any[] }, IError[]>('registOrders'),
  registOrder: actionCreator.async<
    { orderNumber: string; data: ReturnType<typeof getNewOrderRequest>[0] },
    {},
    { data: string }
  >('registOrder'),
  sendAmbassadorCoupon: actionCreator.async<IRegistCoupon, {}>('sendAmbassadorCoupon'),
  registAmbassadorCoupon: actionCreator<IRegistCoupon>('registAmbassadorCoupon'),
};

export type TSettlement = {
  isLoading: boolean;
  hasCompleted: boolean;
};

const initialState: TSettlement = {
  isLoading: false,
  hasCompleted: false,
};

export const settlementReducer = reducerWithInitialState(initialState)
  .case(settlementActions.registOrders.started, (state, payload) => ({
    ...state,
    isLoading: true,
    hasCompleted: false,
  }))
  .case(settlementActions.registOrders.failed, (state, payload) => ({
    ...state,
    isLoading: false,
    hasCompleted: false,
  }))
  .case(settlementActions.registOrders.done, (state, payload) => ({ ...state, isLoading: false, hasCompleted: true }))
  .case(settlementActions.sendAmbassadorCoupon.started, (state, payload) => ({
    ...state,
    isLoading: true,
    hasCompleted: false,
  }))
  .case(settlementActions.sendAmbassadorCoupon.failed, (state, payload) => ({
    ...state,
    isLoading: false,
    hasCompleted: false,
  }))
  .case(settlementActions.sendAmbassadorCoupon.done, (state, payload) => ({
    ...state,
    isLoading: false,
    hasCompleted: true,
  }));
