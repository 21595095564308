import { createSelector } from 'reselect';
import { getAddableParts, getDeletableParts } from '../../../helpers/option';
import { orderState } from './order';
import { getAvailableOption, getProduct } from '../../lookups';
import { orderStateSelector, orderSelector, itemSelector, piecesSelector } from '../object-selector';
import { IOrderState } from '../';
import {
  availableOptionsSelector,
  editedAvailableOptionsSelector,
  clothProductsSelector,
} from '../../lookups/object-selector';
import { IOptionHeaderItem } from '../../../types/new-store/components';
import { isModelSelectBoxOnClothSection } from '../../../helpers/item-thisisforreplaceall';
import { isDesingInputCompleted } from '../../../helpers/item-thisisforreplaceall/is-input-completed';
import Logger from '../../../helpers/common/logger';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IOrder } from '../../_type/order';
import { IAvailableOption } from '../../_type/lookups';

const getItem = (state: IOrderState) => orderSelector(orderStateSelector(state).currentOrder()).item();
const getDesign = (state: IOrderState) => itemSelector(getItem(state)).design();

export const currentDesign = {
  /** design全体を返却する */
  detail: createSelector(orderState, state => getDesign(state)),
  /** オプションの合計金額を返却する */
  optionTotalTaxIn: createSelector(orderState, getAvailableOption.current, (state, _options) => {
    // FIXME: エラーハンドリングは要確認
    try {
      const { pieces, design, cloth, categoryCode } = getItem(state);
      const options = availableOptionsSelector(_options).withSelectedInfo(
        pieces,
        design.designParts,
        design.selecting,
        categoryCode,
        cloth.clothModelCode,
        cloth.brandCode
      );
      const data = editedAvailableOptionsSelector(options).totalOptionPriceTaxin();
      return data;
    } catch (error) {
      Logger.log('optionTotalTaxIn', error);
      return 0;
    }
  }),
  /** sidebarに表示するデータを返却する */
  sidebarListItem: createSelector(
    orderState,
    getAvailableOption.current,
    getProduct.current.products,
    (state, _options, products) => {
      try {
        const { pieces, design, categoryCode, subCategoryCode, itemCode, cloth } = getItem(state);
        const options = availableOptionsSelector(_options).withSelectedInfo(
          pieces,
          design.designParts,
          design.selecting,
          categoryCode,
          cloth.clothModelCode,
          cloth.brandCode
        );
        const partsDesignModelCodes = clothProductsSelector({
          products,
          itemCode,
          brandCode: cloth.brandCode,
          subCategoryCode
        }).getPartsSelectableDesignModelCodes(categoryCode, pieces);
        const addableParts = getAddableParts(
          categoryCode,
          subCategoryCode,
          piecesSelector(pieces).partsNumberArr(),
          cloth.brandCode,
        );
        const deletableParts = getDeletableParts(
          categoryCode,
          subCategoryCode,
          piecesSelector(pieces).partsNumberArr(),
          cloth.brandCode,
        );

        return editedAvailableOptionsSelector(options).getSidebarListItem(
          design.selecting,
          addableParts,
          deletableParts,
          partsDesignModelCodes,
          categoryCode,
          cloth.brandCode,
          state.isEdit,
        );
      } catch (error) {
        Logger.log('sidebarListItem', error);
        return {
          partsItems: [],
          addablePartsList: [],
        };
      }
    },
  ),
  /** contentに表示するデータを返却する */
  contentItem: createSelector(
    orderState,
    getAvailableOption.current,
    getProduct.current.products,
    (state, _options, products) => {
      const { pieces, design, itemCode, cloth, categoryCode, subCategoryCode } = getItem(state);
      const options = availableOptionsSelector(_options).withSelectedInfo(
        pieces,
        design.designParts,
        design.selecting,
        categoryCode,
        cloth.clothModelCode,
        cloth.brandCode
      );
      const partsDesignModelCodes = clothProductsSelector({
        products,
        itemCode,
        brandCode: cloth.brandCode,
        subCategoryCode
      }).getPartsSelectableDesignModelCodes(categoryCode, pieces);
      return editedAvailableOptionsSelector(options).getOptionContent(
        design.selecting,
        partsDesignModelCodes,
        categoryCode,
        cloth.brandCode,
        state.isEdit,
      );
    },
  ),
  /** contentのheaderに表示するデータを返却する */
  contentHeaderItem: createSelector(
    orderState,
    getAvailableOption.current,
    getProduct.current.currentProduct,
    (state, _options, product) => {
      const { pieces, design, cloth, categoryCode } = getItem(state);
      const options = availableOptionsSelector(_options).withSelectedInfo(
        pieces,
        design.designParts,
        design.selecting,
        categoryCode,
        cloth.clothModelCode,
        cloth.brandCode
      );
      const { brandCode, clothModelCode, clothSeasonCode, clothCode } = cloth;
      const modelCode = isModelSelectBoxOnClothSection(categoryCode, pieces, brandCode) ? clothModelCode : '----';
      const optionTotal = editedAvailableOptionsSelector(options).totalOptionPriceTaxin();
      const productPrice = +product.retailPriceTaxin;
      const totalPrice = optionTotal + productPrice;

      const data: IOptionHeaderItem = {
        cloth: `${clothSeasonCode} ${clothCode}`,
        brandCode,
        modelCode,
        productPrice,
        optionTotal,
        totalPrice,
        pricePrefix: '¥',
      };
      return data;
    },
  ),
  /** 選択中のoptionPatternを返却する */
  selectingOptionPattern: createSelector(orderState, getAvailableOption.current, (state, _options) => {
    if (!state.orders[state.currentOrderNumber]) {
      return '';
    }
    const { pieces, design, cloth, categoryCode } = getItem(state);
    const options = availableOptionsSelector(_options).withSelectedInfo(
      pieces,
      design.designParts,
      design.selecting,
      categoryCode,
      cloth.clothModelCode,
      cloth.brandCode
    );

    const { partsIndex, optionNumber } = design.selecting;

    return editedAvailableOptionsSelector(options).optionPatternCode(partsIndex, optionNumber);
  }),
  hasCompleted: createSelector(appStateSelector, stateSelector => {
    return isDesignPageInputCompleted(stateSelector.order(), stateSelector.avaliableOptions());
  }),
};

// MEMO: 切り離した
export const isDesignPageInputCompleted = (order?: IOrder, availableOptions?: IAvailableOption[]) => {
  if (!order || !availableOptions) {
    return false;
  }
  const { item } = order;
  const eaOptions = availableOptions
    ? availableOptionsSelector(availableOptions).withSelectedInfo(
        item.pieces,
        item.design.designParts,
        item.design.selecting,
        item.categoryCode,
        item.cloth.clothModelCode,
        item.cloth.brandCode
      )
    : [];
  return eaOptions.length > 0 && isDesingInputCompleted(item, eaOptions);
};
