import { TRequestConfig, TRequestParams } from '../..';

export { IntroducerRes } from './introducer.class';

export const introducerResRequestConfig: TRequestConfig = {
  endpoint: '/operator/introducer',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.pathParam : '';
    return {
      ok: true,
      body: JSON.stringify('{}'),
    };
  },
  params2request: (params): TRequestParams => {
    const introducerCode = params ? params.query.introducerCode : undefined;
    return {
      query: {
        introducerCode,
      },
    };
  },
};
