import React from 'react';
import { Route } from 'react-router-dom';

import { ERouterPath } from '../types';
import { resolvePath } from '../helpers/common/path';

import ItemConfirmationPage from '../containers/pages/ItemConfirmationPage';
import AddressPage from '../containers/pages/AddressPage';
import PointPage from '../containers/pages/PointPage';

const OrderSubRouter: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <Route exact={true} path={resolvePath(ERouterPath.itemConfirmation)} component={ItemConfirmationPage} />
      <Route exact={true} path={resolvePath(ERouterPath.address)} component={AddressPage} />
      <Route exact={true} path={resolvePath(ERouterPath.amount)} component={PointPage} />
    </React.Fragment>
  );
};

export default OrderSubRouter;
