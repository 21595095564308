import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IOrderDigests, IOrderDigestsSearch, TSetOrderDigistFilter } from '../../types/orders/digest';
import actionCreatorFactory from 'typescript-fsa';
import { cloneDeep } from 'lodash';

// actions
const actionCreator = actionCreatorFactory('[OrderDigests]');

export const actions = {
  setFilter: actionCreator<TSetOrderDigistFilter>('setFilter'),
  resetFilter: actionCreator<void>('resetFilter'),
  load: actionCreator.async<{}, IOrderDigests[], {}>('load'),
  updateCancelFlag: actionCreator<{ orderNumber: string }>('updateCancelFlag'),
};

// reducer
export interface IOrderDigestsState {
  isLoading: boolean;
  filterParams: IOrderDigestsSearch;
  orderDigest: IOrderDigests[];
}

const initialState: IOrderDigestsState = {
  isLoading: false,
  filterParams: {
    categoryCode: '',
    customerPhoneNumber: '',
    memberscardNumber: '',
    customerNameKana: '',
    tempoCode: '',
    orderDateFrom: new Date(),
    orderDateTo: new Date(),
    serialNumber: '',
    lotNumber: '',
  },
  orderDigest: [],
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.setFilter, (state, payload) => ({
    ...state,
    filterParams: {
      ...state.filterParams,
      [payload.key]: payload.value,
    },
  }))
  .case(actions.resetFilter, (state, payload) => ({
    ...state,
    filterParams: {
      ...initialState.filterParams,
    },
  }))
  .case(actions.load.started, (state, payload) => ({
    ...state,
    isLoading: true,
  }))
  .case(actions.load.failed, (state, payload) => ({
    ...state,
    isLoading: false,
  }))
  .case(actions.load.done, (state, payload) => ({
    ...state,
    orderDigest: payload.result,
    isLoading: false,
  }))
  .case(actions.updateCancelFlag, (state, payload) => {
    const cloneState = cloneDeep(state);
    const canceledOrder = cloneState.orderDigest.find(v => v.serialYear + v.serialNumber === payload.orderNumber);
    if (canceledOrder) {
      canceledOrder.hasCancel = true;
    }
    return {
      ...cloneState,
    };
  });
