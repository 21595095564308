/**
 *  is-input-completed：入力完了済みかをチェックする
 */
import { IItem, IOrder } from '../../store/_type/order';
import { KR_BRANDS } from '../../lookups/item-thisisforreplaceall/cloth';
import { IAvailableOptionWithPartsIndexAndSelectedInfo, ISizeMeasurementView } from '../../store/_type/lookups/index';
import { isModelSelectBoxOnClothSection } from './show-or-hide';
import { STOCK_FLAG } from '../../types/inventory-search';
import { IndexedObject } from '../../types';
import { TPaymentState } from '../../store/payment/action-reducer';
import { POINT_USAGE_UNIT } from '../../lookups/point';
import {
  isKana,
  isValidEmbroidery,
  isValidPostalCode,
  isValidPhoneNumber,
  isValidMailAddress,
  isValidOptionsCombination,
  isValidLengthShippingStreet,
  isValidIntroducerCode,
  isValidFavoriteCustomerCode,
  isValidRequiredScale,
  isValidLengthCustomerNameKanji,
  isValidLengthCustomerNameKana,
  isValidLotNumber,
  isValidCutterNameKana,
  isValidMemberscardNumberInput,
} from '../common/validate';
import {
  isInputEmbroideryRequired,
  toIOptionFromIOptionPatternWithSelectedInfo,
} from './what-is-the-option-optionClass';
import { getOptions, getEmbroideryType } from '.';
import { getInitialOrderNumber } from '../orders';
import {
  OPTION_COMBINATION_FUNCTIONS_CONFIG,
  OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY,
} from '../../lookups/item-thisisforreplaceall';
import { TScProject } from '../../types/project';

const isFilled = (val: any): boolean => !!val;
const isObject = (val: any): boolean => typeof val === 'object';
const hasLength = (val: any): boolean => Array.isArray(val) && val.length > 0;
const isValidLength = (val: any, max: number, min: number): boolean =>
  Object(val).hasOwnProperty('length') && val.length <= max && val.length >= min;
const validSimpleReuired = <T>(
  condition: {
    requiredItems?: Array<keyof T>;
    hasLengthItems?: Array<keyof T>;
    isObjectItems?: Array<keyof T>;
    isValidLengthItems?: Array<{ key: keyof T; max: number; min: number }>;
  },
  target: T,
): boolean => {
  try {
    return !(
      (condition.requiredItems && condition.requiredItems.some(v => !isFilled(target[v]))) ||
      (condition.hasLengthItems && condition.hasLengthItems.some(v => !hasLength(target[v]))) ||
      (condition.isObjectItems && condition.isObjectItems.some(v => !isObject(target[v]))) ||
      (condition.isValidLengthItems &&
        condition.isValidLengthItems.some(v => !isValidLength(target[v.key], v.max, v.min)))
    );
  } catch {
    return false;
  }
};

/**
 * 生地選択画面の必須項目が全て入力されているかどうか
 */
export const isClothInputCompleted = (order: IOrder): boolean => {
  const { item, shortestDeliveryDate } = order;

  // 納期？
  if (!isFilled(shortestDeliveryDate)) {
    return false;
  }
  /************************************************************
   * 常に値が入っている必要があるもの
   ************************************************************/
  // check for item
  if (
    !validSimpleReuired<IItem>(
      {
        requiredItems: ['categoryCode', 'subCategoryCode', 'itemCode'], // deliveryDate???
        hasLengthItems: ['pieces'],
        isObjectItems: ['cloth'],
      },
      item,
    )
  ) {
    return false;
  }
  // check for item.cloth
  if (
    !validSimpleReuired<IItem['cloth']>(
      {
        requiredItems: [
          'seasonCode',
          'clothCode',
          'brandCode',
          'personalorderColorCode',
          'design',
          'stockPlaceCode',
          'vendorClothNumber',
          'clothSeasonCode',
          'productSeasonCode',
          'clothBrandCode',
        ],
        hasLengthItems: ['compositionFront'],
        isValidLengthItems: [{ key: 'clothCode', max: 4, min: 4 }],
      },
      item.cloth,
    )
  ) {
    return false;
  }

  // 在庫があるかどうか
  if (item.cloth.hasStock === STOCK_FLAG.noStock) {
    return false;
  }
  /************************************************************
   * 特定条件で入力が必要な項目に関してチェック [ TODO: 正しい必須条件は要確認 ]
   ************************************************************/
  // KR時に値が入るもの
  if (KR_BRANDS.includes(item.cloth.brandCode)) {
    if (
      !validSimpleReuired<IItem['cloth']>(
        {
          requiredItems: ['vendorClothNumber'],
          // hasLengthItems: ['compositionBack'],
        },
        item.cloth,
      )
    ) {
      return false;
    }
  }
  // モデル選択が表示される場合は、clothModelCodeは必須
  if (isModelSelectBoxOnClothSection(item.categoryCode, item.pieces, item.cloth.brandCode)) {
    if (
      !validSimpleReuired<IItem['cloth']>(
        {
          requiredItems: ['clothModelCode'],
        },
        item.cloth,
      )
    ) {
      return false;
    }
  }

  return true;
};

// ブランドKRの時、記事選択画面の要尺が入力されているかどうか
export const isRequiredScaleInputCompleted = (item: IItem): boolean => {
  if (KR_BRANDS.includes(item.cloth.brandCode)) {
    if (!isValidRequiredScale(item.cloth.requiredScale)) {
      return false;
    }
  }
  return true;
};

// ブランドKRの時、　生地選択画面の裏地が入力されているかどうか
export const isCompositionBackInputCompleted = (item: IItem): boolean => {
  if (KR_BRANDS.includes(item.cloth.brandCode)) {
    if (
      !validSimpleReuired<IItem['cloth']>(
        {
          hasLengthItems: ['compositionBack'],
        },
        item.cloth,
      )
    ) {
      return false;
    }
  }

  return true;
};

/**
 * デザイン・オプション選択画面の必須項目が全て入力されているかどうか
 */
export const isDesingInputCompleted = (
  item: IItem,
  editedAvailableOptions: IAvailableOptionWithPartsIndexAndSelectedInfo[],
): boolean => {
  // 必須オプションチェック
  const hasCompletedOption = editedAvailableOptions.every(parts =>
    parts.optionPatterns.every(pattern => {
      if (pattern.isRequired) {
        return pattern.selectingClassNumber !== '';
      }
      if (pattern.isFreeInput) {
        const options = getOptions(parts.partsIndex, item.design.designParts);
        const isRequired = isInputEmbroideryRequired(options, parts.partsNumber);
        const embroideryType = getEmbroideryType(options, parts.partsNumber);
        return isRequired
          ? isValidEmbroidery(pattern.selectingClassName, embroideryType, parts.partsNumber, item.categoryCode)
          : pattern.selectingClassName === '';
      }
      return true;
    }),
  );

  // 同一パーツ内の選択オプションによって制限がかかるチェック
  const isCombination = editedAvailableOptions.every(({ partsNumber, optionPatterns }) => {
    const options = toIOptionFromIOptionPatternWithSelectedInfo(optionPatterns);
    return optionPatterns.every(({ optionNumber, selectingClassNumber }) =>
      isValidOptionsCombination(
        OPTION_COMBINATION_FUNCTIONS_CONFIG,
        OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY,
        partsNumber,
        optionNumber,
        selectingClassNumber,
        options,
      ),
    );
  });

  // modelチェック
  // modelCodeは型番、modelPattern、optionPatternは生地選択のモデル、またはパーツごとのモデル
  const hasCompletedModelCode = editedAvailableOptions.every(
    parts => parts.modelCode && parts.modelPatternCode && parts.optionPatternCode,
  );

  return hasCompletedOption && isCombination && hasCompletedModelCode;
};
// (![EOptionType.FINISHED_SIZE, EOptionType.NUDE_SIZE].includes(getOptionType(v.optionNumber)) &&
// !v.selectingClassNumber)),

/**
 * サイズ補正画面の必須項目が全て入力されているかどうか
 * TODO: とりあえず、作成したが全然条件が足りていない
 * partsSize.measurements: 入力した値が無いので、 lookups.sizeMeasurement と称号しないと未入力の検知が出来ない。あと、入力値の範囲のチェックが必要
 * partsSize.adjustOptions, partsSize.history, partsSize.standardSizes のチェックは必要？
 * （ってか、そもそも、order.item 配下にあるべきデータなのが謎なものがちらほら、、、）
 */
export const isSizeInputCompleted = (sizeMeasurementsViews: ISizeMeasurementView[], item: IItem) => {
  // majorが空文字またはitem.valueのいずれかの値がmin, maxの範囲内でない時 false
  if (
    sizeMeasurementsViews.some(
      view =>
        (view.gauge.major === '' && view.gauge.minor === '') ||
        view.measurementItems.some(
          mItem =>
            mItem.max !== undefined &&
            mItem.min !== undefined &&
            (Number(mItem.value) < mItem.min || Number(mItem.value) > mItem.max),
        ),
    )
  ) {
    return false;
  }
  /************************************************************
   * 特定条件で入力が必要な項目に関してチェック [ TODO: 正しい必須条件は要確認 ]
   ************************************************************/
  // KR時に値が入るもの
  if (KR_BRANDS.includes(item.cloth.brandCode)) {
    if (
      !validSimpleReuired<IItem['cloth']>(
        {
          requiredItems: ['vendorClothNumber', 'requiredScale'],
          hasLengthItems: ['compositionBack'],
        },
        item.cloth,
      )
    ) {
      return false;
    }
  }

  return true;
};

// 使っていないのでコメントアウト
// /**
//  * 上記３つの関数を一括実行
//  */
// export const isItemInputCompleted = (
//   order: IOrder,
//   editedAvailableOptions: IAvailableOptionWithPartsIndexAndSelectedInfo[],
//   sizeMeasurementsViews: ISizeMeasurementView[],
// ): boolean => {
//   const { item } = order;
//   const completed = {
//     cloth: isClothInputCompleted(order),
//     design: isDesingInputCompleted(item, editedAvailableOptions),
//     size: isSizeInputCompleted(sizeMeasurementsViews, item),
//   };
//   return !Object.entries(completed).some(v => !v[1]);
// };

export const isAddressInputCompleted = (orders: IndexedObject<IOrder>): boolean => {
  const initialOrderNumber = getInitialOrderNumber(orders);
  return Object.keys(orders).every(key => {
    const {
      shippingPostalCode,
      shippingState,
      shippingCity,
      shippingStreet,
      customerFamilyNameKana,
      customerGivenNameKana,
      customerFamilyNameKanji,
      customerGivenNameKanji,
      shippingPhoneNumber,
      customerMailAddress,
      isSameOrderOne,
      deliveryMethod,
      deliveryDateGuest,
      timeZoneCode,
      shippingCost,
      lotNumber,
      memberscardNumber,
      cutterNameKana,
    } = orders[key].shipping;

    const isValidState = !!shippingState;
    const isValidDelivery = deliveryMethod && deliveryDateGuest && timeZoneCode && (shippingCost || shippingCost === 0);
    if (key !== initialOrderNumber && isSameOrderOne) {
      return isValidDelivery;
    }

    return (
      isValidDelivery &&
      isValidPostalCode(shippingPostalCode) &&
      isValidState &&
      shippingCity &&
      shippingStreet &&
      isValidLengthShippingStreet(shippingStreet) &&
      isKana(customerFamilyNameKana, true) &&
      isValidLengthCustomerNameKana(customerFamilyNameKana) &&
      isKana(customerGivenNameKana, true) &&
      isValidLengthCustomerNameKana(customerGivenNameKana) &&
      customerFamilyNameKanji &&
      isValidLengthCustomerNameKanji(customerFamilyNameKanji) &&
      customerGivenNameKanji &&
      isValidLengthCustomerNameKanji(customerGivenNameKanji) &&
      isValidPhoneNumber(shippingPhoneNumber) &&
      (!customerMailAddress || isValidMailAddress(customerMailAddress)) &&
      isValidLotNumber(lotNumber) &&
      isValidCutterNameKana(cutterNameKana) &&
      isValidMemberscardNumberInput(memberscardNumber)
    );
  });
};

export const isPointInputCompleted = (payment: TPaymentState, scProject: TScProject): boolean => {
  // ポイントを読み込んだか？
  if (!payment.hasLoadedUseablePoint) {
    return false;
  }

  // 利用可能ポイントより利用ポイントの値が大きい場合
  if (payment.usePoint > payment.useablePoints || payment.usePoint % POINT_USAGE_UNIT !== 0) {
    return false;
  }

  // 紹介者あり
  if (payment.hasIntroducer) {
    if (!payment.introducerName || !isValidIntroducerCode(payment.introducerCode)) {
      return false;
    }
  }

  // 流通区分：訪問
  if (payment.hasFavoriteCustomer) {
    // 「請求書あり」「請求書なし」のいずれかが選択されているか。
    if (typeof payment?.needInvoice === 'undefined') {
      return false;
    }
    // 請求書ありの場合に、訪問販売先名と訪問販売先コードが正しく入力されているか
    if (
      payment.needInvoice &&
      (!payment.favoriteCustomerName || !isValidFavoriteCustomerCode(payment.favoriteCustomerCode))
    ) {
      return false;
    }

    if (
      !validSimpleReuired(
        {
          requiredItems: [
            'companyName',
            'contactName',
            'corporationId',
            'projectName',
            'salesEventId',
            'salesRecordingDate',
          ],
        },
        scProject,
      )
    ) {
      return false;
    }
  }

  return true;
};
