import {
  TOrderItemCode,
  TPartsNumber,
  TSubCategory,
  TCategory,
  ESubcategory,
} from '../../lookups/master-thisisforreplaceall';
import { ORDER_CATALOG_ALL, subCategoriesInitialParts } from '../../lookups/item-thisisforreplaceall';
import { IDesignParts, IPiece } from '../../store/_type/order';
import Logger from '../common/logger';
import { isDoubleDesignOptionClass } from '../item-thisisforreplaceall';

/**
 * 必ずダブルのitem-codeを設定する、カテゴリ・モデルパターン・ブランドの組み合わせパターン
 * https://onward.backlog.jp/view/OPS_ORDERSYS-1142#comment-131520696
 */
const IS_DOUBLE_CONFIG: Array<{
  category: TCategory;
  modelCode: string;
  brand?: string;
}> = [
  {
    category: 'MT',
    modelCode: 'EJ02',
    brand: 'KA',
  },
  {
    category: 'MT',
    modelCode: 'EJ12',
    brand: 'KA',
  },
  {
    category: 'WM',
    modelCode: 'NJ01',
  },
];

/**
 * 必ずダブルになる、カテゴリ・モデルパターン・ブランドの組み合わせの場合はtrueを返す。
 */
const isDoubleJudgedByModelCode = (category: TCategory, modelCode: string, brand: string): boolean =>
  IS_DOUBLE_CONFIG.some(
    conf =>
      conf.category === category &&
      conf.modelCode === modelCode &&
      (conf.brand === undefined ? true : conf.brand === brand),
  );

/**
 * モデルに応じたシャツのアイテムコード
 */
const SHIRT_ITEM_CODE_ACCORDING_TO_MODELS: Array<{
  subCategory: TSubCategory;
  modelCodes: string[];
  itemCode: TOrderItemCode;
}> = [
  {
    subCategory: ESubcategory.longSleve,
    modelCodes: ['HD'],
    itemCode: 'HD',
  },
  {
    subCategory: ESubcategory.longSleve,
    modelCodes: ['HS01', 'HS02'],
    itemCode: 'HS',
  },
  {
    subCategory: ESubcategory.shortSleve,
    modelCodes: ['HH'],
    itemCode: 'HH',
  },
  {
    subCategory: ESubcategory.shortSleve,
    modelCodes: ['KH01', 'KH02'],
    itemCode: 'KH',
  },
  {
    subCategory: ESubcategory.formalShirt,
    modelCodes: ['HF'],
    itemCode: 'HF',
  },
  {
    subCategory: ESubcategory.womanlongSleeve,
    modelCodes: ['BL001', 'BL002', 'BL003', 'BL004'],
    itemCode: 'HD',
  },
  {
    subCategory: ESubcategory.womanshortSleeve,
    modelCodes: ['BL501', 'BL502', 'BL503', 'BL504'],
    itemCode: 'HH',
  },
];

/**
 * 選択されたモデルに応じたシャツのアイテムコードを返却
 */
const getItemCodeForShirtAccordingToModel = (
  subCategory: TSubCategory,
  modelCode: string,
): TOrderItemCode | undefined =>
  SHIRT_ITEM_CODE_ACCORDING_TO_MODELS.find(
    conf => conf.subCategory === subCategory && conf.modelCodes.includes(modelCode),
  )?.itemCode;

const hasMatchBrand = (brands: string[]) => (brand: string) => (brand !== '' ? brands.includes(brand as string) : true);

/**
 * アイテムコードのマスタから、条件に合致するitemCodeを取得
 * （itemCodeが一意に決まらない場合は、はじめに条件マッチしたitemCodeを利用）
 */
function getItemCode(obj: {
  category: TCategory;
  subCategory: TSubCategory;
  pieces?: IPiece[];
  brand?: string;
  modelCode?: string;
  designParts?: IDesignParts;
}): TOrderItemCode | undefined {
  const { category, subCategory, pieces, brand, modelCode, designParts } = obj;
  // シャツでモデルコードが決まっている場合
  if (category === 'DS' && modelCode !== undefined && modelCode !== '') {
    return getItemCodeForShirtAccordingToModel(subCategory, modelCode);
  }

  // designParts内のoptionsの中身は、オプション選択画面で選択された項目が入っている。
  // ジャケットの型でダブルのoptionClassNumberが選択されている場合のみ、isDoubleJudgedByDesignOptionはtrueになる。
  const isDoubleJudgedByDesignOption =
    designParts === undefined
      ? false
      : Object.values(designParts).some(v =>
          v.options.some(vv => isDoubleDesignOptionClass(vv.optionNumber, vv.optionClassNumber)),
        );
  const isDouble = isDoubleJudgedByDesignOption || isDoubleJudgedByModelCode(category, modelCode ?? '', brand ?? '');
  const parts = pieces?.map(v => v.partsNumber);
  const orderItem = ORDER_CATALOG_ALL.filter(v => v.category === category && v.subCategory === subCategory)
    .filter(v => brand === undefined || v.availableBrands.includes(brand as string))
    .filter(v => parts === undefined || v.pieces.sort().join(',') === parts.sort().join(','))
    .filter(v => v.isDouble === isDouble);

  return orderItem[0]?.itemCode;
}

/**
 *  指定サブカテゴリの代表アイテムコードを返却
 *  (生地選択画面のプルダウンで選択できるモデルコードの一覧を決定時
 *  /サブカテゴリ選択時に呼び出される。)
 */
export function getItemCodeDefault(obj: {
  category: TCategory;
  subCategory: TSubCategory;
}): TOrderItemCode | undefined {
  const { category, subCategory } = obj;
  const pieces = getDefaultPiecesFromSubCategory(subCategory);
  return getItemCode({ category, subCategory, pieces });
}

/**
 *  指定サブカテゴリの代表アイテムコードを返却
 *  (生地選択画面のプルダウンで選択できるモデルコードの一覧をブランド選択時に呼び出す。)
 */
export function getItemCodeWithBrand(obj: {
  category: TCategory;
  subCategory: TSubCategory;
  brand: string;
}): TOrderItemCode | undefined {
  const { category, subCategory, brand } = obj;
  const pieces = getDefaultPiecesFromSubCategory(subCategory);
  return getItemCode({ category, subCategory, brand, pieces });
}

/**
 * 生地選択画面で使用されるアイテムコードを返却
 * (生地選択画面ではオプション選択画面以降で選択される情報は考慮しない)
 */
export function getItemCodeForClothSelectPage(obj: {
  category: TCategory;
  subCategory: TSubCategory;
  brand: string;
  modelCode: string;
}): TOrderItemCode | undefined {
  const { category, subCategory, brand, modelCode } = obj;
  const pieces = getDefaultPiecesFromSubCategory(subCategory);
  return getItemCode({ category, subCategory, brand, modelCode, pieces });
}

/**
 * [仮]/src/store/order/item/epics.ts の　updateItemCode　のための対応。(stateのpiecesを見る)
 * 生地選択画面で、生地品番を変えた時/モデル選択プルダウンを変えた時/ブランド選択プルダウンを変えた時に
 * アイテムコードを更新する際に呼び出される。(EASY/ウィメンズのみ)
 */
export function getItemCodeForChangeModelWithWomenAndEasy(obj: {
  category: TCategory;
  subCategory: TSubCategory;
  brand: string;
  modelCode: string;
  pieces: IPiece[];
}): TOrderItemCode | undefined {
  const { category, subCategory, brand, modelCode, pieces } = obj;
  return getItemCode({ category, subCategory, brand, modelCode, pieces });
}

/**
 * オプション選択画面以降で使用されるアイテムコードを返却。
 * (ジャケットの型で既にダブルが選択されている場合と、
 * カテゴリ・モデル・ブランドの組み合わせによって必ずダブルだと分かる場合はダブルのitem-codeを返す。)
 */
export function getItemCodeWithDetailConditions(obj: {
  category: TCategory;
  subCategory: TSubCategory;
  pieces: IPiece[];
  brand: string;
  modelCode: string;
  designParts: IDesignParts;
}): TOrderItemCode | undefined {
  const { category, subCategory, pieces, brand, modelCode, designParts } = obj;
  return getItemCode({ category, subCategory, pieces, brand, modelCode, designParts });
}

function getSubCategoryParts(subCategory: TSubCategory): TPartsNumber[] {
  return subCategory === '' ? [] : subCategoriesInitialParts[subCategory];
}

/**
 * サブカテゴリから代表的なPiecesを返却。
 */
export function getDefaultPiecesFromSubCategory(subCategory: TSubCategory): IPiece[] {
  const parts = getSubCategoryParts(subCategory);
  const pieces = parts.map((v, i) => {
    const piece: IPiece = {
      index: String(i),
      partsNumber: v,
    };
    return piece;
  });
  return pieces;
}

export function getSubCategory(
  itemCode: TOrderItemCode,
  category: TCategory,
  pieces: TPartsNumber[],
  isDouble: boolean,
  brand: string = '',
): TSubCategory | undefined {
  const orderItemWithDoubleJudgement = ORDER_CATALOG_ALL.find(
    row =>
      row.itemCode === itemCode &&
      row.category === category &&
      row.pieces.sort().join(',') === pieces.sort().join(',') &&
      row.isDouble === isDouble &&
      hasMatchBrand(row.availableBrands)(brand),
  );

  if (!!orderItemWithDoubleJudgement) {
    return orderItemWithDoubleJudgement.subCategory;
  }

  // 「ダブルの商品だが、APIレスポンスのアイテムコードがシングルのコードで返却されている場合」には、ダブルかどうかを判定条件に入れない。
  // https://onward.backlog.jp/view/OPS_ORDERSYS-1156 の対応。
  const orderItemWithNoDoubleJudgement = ORDER_CATALOG_ALL.find(
    row =>
      row.itemCode === itemCode &&
      row.category === category &&
      row.pieces.sort().join(',') === pieces.sort().join(',') &&
      hasMatchBrand(row.availableBrands)(brand),
  );

  return orderItemWithNoDoubleJudgement ? orderItemWithNoDoubleJudgement.subCategory : undefined;
}

export function getPartsNumbers(itemCode: TOrderItemCode, brand: string = ''): TPartsNumber[] {
  const orderItem = ORDER_CATALOG_ALL.find(
    row => row.itemCode === itemCode && hasMatchBrand(row.availableBrands)(brand),
  );
  if (!orderItem) {
    Logger.log('getPartsNumbers undefined', { itemCode, brand });
  }
  return orderItem ? orderItem.pieces : [];
}
