import actionCreatorFactory from 'typescript-fsa';
import { TLoadInitializeParam } from '../../pages/address';

const actionCreator = actionCreatorFactory('[Order/shipping]');

export const actions = {
  reloadPostalCodeAddress: actionCreator<void>('reloadPostalCodeAddress'),
  updatePostalCodeAddress: actionCreator<{ orderNumber: string; postalCode: string }>('updatePostalCodeAddress'),
  loadAddress: actionCreator<TLoadInitializeParam>('loadAddress'),
};
