import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '..';
import { actions } from './action';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { infoDialogActions } from '../utils/dialog/info';
import { IInformationDialog } from '../../types/dialog';
import { match } from '../../lib/utils';
import { ApiError } from '../../models/error/api-error';
import { errorRetryActionDialogActions } from '../utils/dialog/errorRetryAction';
import { ORDER_ERROR_CODE_DISABLE_EDIT } from '../../lookups/master-thisisforreplaceall/order-error-message';

// function getErrorMessage(statusCode: number): string {
//   const errorMessage: IndexedObject = {
//     503: '検索結果が最大件数を超えました。条件を絞り込んでから再検索してください。',
//   };

//   return errorMessage[statusCode] || '条件に該当するデータがありません。';
// }

const getErrorMessage = (payload: { error: ApiError; options: any }): string => {
  const statusCode = Number(payload.error.statusCode);

  const statusMessageConfig: Array<[number | symbol | ((v: number) => boolean), string | ((v: number) => string)]> = [
    [503, '検索結果が最大件数を超えました。条件を絞り込んでから再検索してください。'],
    [(v: number) => v >= 500, (v: number) => `API：システムエラー ${v}`],
    [404, payload.options.contents || '条件に該当するデータがありません。'],
    [403, (v: number) => `API：認証エラー ${v}`],
    [(v: number) => v >= 400, (v: number) => `${payload.options.contents}` || `API：エラー ${v}`],
    [match._, (v: number) => `不明なエラー ${v}`],
  ];

  return match<string, number>(statusMessageConfig, statusCode) || '';
};

const apiError: Epic<AnyAction, Action<IInformationDialog>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.apiError),
    map(({ payload }) => {
      if (payload.error.errors[0].code === ORDER_ERROR_CODE_DISABLE_EDIT) {
        const data = {
          hasOpen: true,
          title: 'E0506001',
          contents: '注文情報の更新に失敗しました.',
        };
        return infoDialogActions.show._action(data);
      }
      const contents = getErrorMessage(payload);
      const data = {
        hasOpen: true,
        title: payload.options.title || '確認',
        contents,
      };
      return infoDialogActions.show._action(data);
    }),
  );

// エラーが発生した場合に、渡されたアクションを再実行する
const apiErrorRetryAction: Epic<AnyAction, Action<IInformationDialog>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.apiErrorRetryAction),
    map(({ payload }) => {
      const contents = getErrorMessage(payload);
      const data = {
        hasOpen: true,
        title: payload.options.title || '確認',
        contents,
        action: payload.action,
      };
      return errorRetryActionDialogActions.show._action(data);
    }),
  );

export const ApiErrorEpics = combineEpics(apiError, apiErrorRetryAction);
