import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store/index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { IOnChangeClothValue, IOnChangeClothComposition } from '../store/_type/order';
import { ClothSelectionPageEvents } from '../views/events/ClothSelectionPage';
import { map } from 'rxjs/operators';
import { actions as clothActions } from '../store/order/cloth/actions';

/**
 * 生地選択画面で値の変更が行われた時（単一の値を持つ要素のみ）
 * @see ClothSelectionPageEvents.onChange
 */
const onChange: Epic<AnyAction, Action<IOnChangeClothValue>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ClothSelectionPageEvents.onChange),
    map(action => {
      return clothActions.update.started(action.payload);
    }),
  );

/**
 * 生地選択画面で生地組成の値の変更が行われた時
 * @see ClothSelectionPageEvents.onChangeComposition
 */
const onChangeComposition: Epic<AnyAction, Action<IOnChangeClothComposition>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(ClothSelectionPageEvents.onChangeComposition),
    map(({ payload }) => {
      const value = payload.subkey === 'mixingRatio' ? Number(payload.value) : payload.value;
      return clothActions.updateComposition.started({ ...payload, value });
    }),
  );

export const ClothPageListeners = combineEpics(onChange, onChangeComposition);
