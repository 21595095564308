import { fetchApi } from '../../api';
import { StandardSizeRes, standardSizeRequestConfig } from '../../api/items/standard-size';
import { AdjustOptionRes, adjustOptionRequestConfig } from '../../api/items/adjust-option';
import { RecommendGaugeRes, recommendGaugeRequestConfig } from '../../api/items/recommend-gauge';
import { SizeMeasurementRes, sizeMeasurementRequestConfig } from '../../api/items/size-measurement';
import {
  TSizeStanderdPathParam,
  TRecommendedGaugePathParam,
  IRecommendedGaugeBodyParam,
  TAdjustOptionPathParam,
  IPartsAdjustOption,
  TGetSizeMeasurementsParam,
} from '../../store/_type/lookups';
import { ApiError } from '../../models/error/api-error';
import { toPartsAdjustOptions } from '../../helpers/api/converter/adjust-option';

export async function getSizeMeasurements(path: TGetSizeMeasurementsParam) {
  return fetchApi(SizeMeasurementRes, sizeMeasurementRequestConfig, { path });
}

export async function getStandardSizeList(path: TSizeStanderdPathParam) {
  return fetchApi(StandardSizeRes, standardSizeRequestConfig, { path });
}

export async function postRecommendSize(
  path: TRecommendedGaugePathParam,
  body: IRecommendedGaugeBodyParam,
): Promise<RecommendGaugeRes> {
  return fetchApi(RecommendGaugeRes, recommendGaugeRequestConfig, { body, path });
}

export async function getSizeAdjustOptionList(path: TAdjustOptionPathParam) {
  return fetchApi(AdjustOptionRes, adjustOptionRequestConfig, { path });
}

export async function getAdjustOptions(requestParam: TAdjustOptionPathParam[]): Promise<IPartsAdjustOption[]> {
  const request = requestParam.map(async path => {
    try {
      const partsOption = await getSizeAdjustOptionList(path).then(res => toPartsAdjustOptions(res, path.partsNumber));
      return partsOption;
    } catch (ex) {
      if (ex instanceof ApiError && ex.statusCode === 404) {
        return {} as IPartsAdjustOption;
      }
      throw ex;
    }
  });
  return await Promise.all(request);
}
