import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { mapCommonPageDispatchToProps, mapCommonPageStateToProps } from './CommonPage';
import { customKeyboardHoc } from '../../hoc/CustomKeyboardHoc';

import PointPage from '../../components/pages/PointPage/PointPage';
import { pointPageActions } from '../../store/pages/point';
import { getPointPage } from '../../store/payment/selector';
import { get as getCustomer } from '../../store/customer';
import { getLanguage } from '../../store/utils/language';
import { getAddress } from '../../store/order/selector/address';
import { get as getOrder } from '../../store/order';
import { getProjectSelectorDialogState } from '../../store/utils/dialog/project-selector';
import { getSelectedOrderDigest } from '../../store/order-digest';
import { getStaff } from '../../store/staff';
import { scProjectSelector } from '../../store/sc-project';
import { ProjectSelectorDialogEvents } from '../../views/events/ProjectSelectorDialog';
import { CouponInfoDialogEvents } from '../../views/events/CouponInfoDialog';
import { getCouponInfoDialogState } from '../../store/utils/dialog/coupon-info';

function mapStateToProps(state: AppState) {
  return {
    isEdit: getOrder.isEdit(state),
    memberscardNumber: getCustomer.memberscardNumber(state),
    pricePrefix: getLanguage.pricePrefix(state),
    payment: getPointPage.payment(state),
    useablePoints: getPointPage.useablePoints(state),
    backPoints: getPointPage.backPoints(state),
    isOnlyDummyData: getPointPage.isOnlyDummyData(state),
    ...mapCommonPageStateToProps(state),
    // 以下合計表示に利用
    paymentList: getPointPage.paymentList(state),
    hasPointInputCompleted: getPointPage.hasCompleted(state),
    hasComfirmOrders: getAddress.hasCompleted(state) && getPointPage.hasCompleted(state),
    selectedScProject: scProjectSelector.selectedProject(state),
    isLoading: getProjectSelectorDialogState.isLoading(state),
    orderStatus: getSelectedOrderDigest.orderStatus(state),
    isManager: getStaff.isManager(state),
    couponDetail: getCouponInfoDialogState.results(state),
    couponId: getStaff.staff(state).couponId,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    onLoadInitialize: pointPageActions.loadInitialize.dispatcher(dispatch),
    onChangeValue: pointPageActions.changeValue.dispatcher(dispatch),
    onChangeRadioButton: pointPageActions.changeRadioButton.dispatcher(dispatch),
    showProjectSelectionDialog: bindActionCreators(ProjectSelectorDialogEvents, dispatch).show,
    onLoadCouponDetail: bindActionCreators(CouponInfoDialogEvents, dispatch).load,
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(customKeyboardHoc()(PointPage));
