import React, { useState, useEffect } from 'react';
import { TSaveTmpOrderDialogProps } from '../../../containers/components/dialogs/SaveTmpOrderDialog/SaveTmpOrderDialog';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import { langResource } from '../../../i18n/_new/resources';
import Button from '../../atoms/Button/Button';
import InputText from '../../../containers/components/InputText';
import Label from '../../atoms/Label/Label';
import styles from './SaveTmpOrderDialog.module.scss';

type TProps = TSaveTmpOrderDialogProps & RouteComponentProps;

const SaveTmpOrderDialog: React.FC<TProps> = (props: TProps) => {
  const { hasOpen, onSave, priorityCustomerName, customerName } = props;
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (hasOpen) {
      setName(priorityCustomerName || customerName);
    }
  }, [hasOpen, priorityCustomerName, customerName]);

  const onClose = () => {
    props.onClose();
    setName('');
  };

  const onClickSaveBtn = () => {
    props.onClose();
    setName('');
    onSave(name);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog open={hasOpen} onClose={onClose} className="dialog">
        <DialogTitle>
          <I18TextContainer textObj={langResource.dialog.saveTmpOrder.title} />
        </DialogTitle>
        <DialogContent>
          <div className={styles.text}>
            <p>一時保留オーダーとして保存します。</p>
            <p>[お名前]を入力してください。</p>
          </div>
          <div className={styles.column}>
            <Label textObj={langResource.dialog.saveTmpOrder.name} />
            <div className={styles.inputWrap}>
              <InputText onValueChanged={onChangeName} value={name} />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button
            textObj={langResource.dialog.saveTmpOrder.cancelBtn}
            onClick={onClose}
            styles={['white', 'noborder']}
          />
          <Button textObj={langResource.dialog.saveTmpOrder.saveBtn} onClick={onClickSaveBtn} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withRouter(SaveTmpOrderDialog);
