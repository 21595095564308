import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';
import { get as getOrder } from '../../../store/order';
import { getSize } from '../../../store/order/selector/size';
import { getCurrentItem } from '../../../store/order/selector/item';
import { mapCommonPageDispatchToProps } from '../CommonPage';

import SpecialAdjustPage from '../../../components/pages/SpecialAdjustPage/SpecialAdjustPage';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';

function mapStateToProps(state: AppState) {
  return {
    orders: getOrder.orders(state),
    orderNumber: getOrder.orderNumber(state),
    // item
    item: getCurrentItem.detail(state),
    // sizeSelection
    selectingParts: getSize.selectingParts(state),
    specialAdjustOptions: getSize.specialAdjustOptions(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(SizeCorrectionPageEvents, dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SpecialAdjustPage);
