import {
  IRecommendGaugeRes,
  IRecommendGaugeBodyReqParams,
  IRecommendGaugePathReqParams,
  IGauge,
} from '../../types/size-correction';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall/';
import { allPartsArr } from '../master-thisisforreplaceall/parts';

const PARTS_NUMBERS: TPartsNumber[] = allPartsArr;

const MAJAR_GAUGES_1 = createMajorGauges(['A', 'B']);
const MAJAR_GAUGES_2 = createMajorGauges(['D', 'E']);
const MAJAR_GAUGES_3 = createMajorGauges(['G', 'H']);
const MAJAR_GAUGES_4 = createMajorGauges(['J', 'K']);
const MAJAR_GAUGES = [MAJAR_GAUGES_1, MAJAR_GAUGES_2, MAJAR_GAUGES_3, MAJAR_GAUGES_4];

const MINOR_GAUGES_1 = createMinorGauges([0, 1]);
const MINOR_GAUGES_2 = createMinorGauges([7, 8]);
const MINOR_GAUGES_3 = createMinorGauges([4, 5]);
const MINOR_GAUGES = [MINOR_GAUGES_1, MINOR_GAUGES_2, MINOR_GAUGES_3];

export function getMockRecommendGauge(
  path: IRecommendGaugePathReqParams,
  body: IRecommendGaugeBodyReqParams,
): IRecommendGaugeRes {
  const size = body.nudeSizes.find(v => v.measurementNumber === +'7001');
  if (!size) {
    return {
      parts: [],
    };
  }

  const partsNumbers = body.parts.map(v => v.partsNumber);

  if (partsNumbers.length < 1) {
    const testParts = PARTS_NUMBERS.map(v => {
      const testRetType = getReturnDataNumber(size.measurementValue);
      const gauges = createAllGauges(testRetType, v);
      return {
        partsNumber: v,
        recommendedGauge: gauges[0],
        allGauge: gauges,
      };
    });
    return { parts: testParts };
  }

  const retType = getReturnDataNumber(size.measurementValue);
  const parts = partsNumbers.map(v => {
    const gauges = createAllGauges(retType, v);
    return {
      partsNumber: v,
      recommendedGauge: gauges[0],
      allGauge: gauges,
    };
  });

  return { parts };
}

function getReturnDataNumber(value: number): number {
  if (value < 100) {
    return 0;
  }
  if (100 <= value && value < 200) {
    return 1;
  }
  if (200 <= value && value < 300) {
    return 2;
  }
  if (400 <= value && value < 500) {
    return 3;
  }
  return 4;
}

function createAllGauges(value: number, partsNumber: TPartsNumber): IGauge[] {
  const majors = MAJAR_GAUGES[(value + +partsNumber) % 4];
  return majors
    .map((v, i) => {
      const minors = MINOR_GAUGES[i % 3];
      // const index = (value * i * +partsNumber) % 8;
      return minors.map(minor => {
        return {
          majorGauge: v,
          minorGauge: minor,
        };
      });
    })
    .reduce((pre, cur) => [...pre, ...cur], []);
}

function createMajorGauges(chars: string[]): string[] {
  return chars
    .map(gauge => {
      return Array.from({ length: 2 }, (v, i) => `${gauge}${i + 1}`);
    })
    .reduce((pre, cur) => [...pre, ...cur]);
}

function createMinorGauges(numbers: number[]): string[] {
  return numbers
    .map(num => {
      return Array.from({ length: 3 }, (v, i) => `${num}${i + 1}`);
    })
    .reduce((pre, cur) => [...pre, ...cur]);
}
