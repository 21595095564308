import { TRequestConfig, TRequestParams } from '../..';

export { UseablePointsRes } from './useablePoints.class';

export const useablePointsResRequestConfig: TRequestConfig = {
  endpoint: '/customer/{memberscard_number}/point',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.pathParam : '';
    return {
      ok: true,
      body: JSON.stringify('{}'),
    };
  },
  params2request: (params): TRequestParams => {
    return {
      pathParams: {
        memberscard_number: params ? params.pathParams.memberscardNumber : '',
      },
    };
  },
};
