import partial from 'ramda/es/partial';

import config from '../../configuration/config';
import { SEASON_CODE_LOOKUPS } from '../../lookups/item-thisisforreplaceall';
import { TSeasonCode } from '../../lookups/master-thisisforreplaceall';
import { SystemError } from '../../models/error/system-error';
import { isEqualOrBefore, isEqualOrAfter } from '../common/date';

/**
 * クライアントのマスタの年季を取得する
 * （生地商品一覧取得APIへのリクエストになる年季）
 * 本番環境でない場合、configのdummySeasonCodeを返却する
 */
export const getSeasonCodeFromEnv = (isDev: boolean, currentDate: Date): TSeasonCode => {
  if (isDev) {
    return config.dummySeasonCode;
  }

  const currentSeason = SEASON_CODE_LOOKUPS.find(
    // from <= current <= to
    ({ dateFrom, dateTo }) => isEqualOrAfter(currentDate, dateFrom) && isEqualOrBefore(currentDate, dateTo),
  );

  if (!currentSeason) {
    throw new SystemError('0002', [`${currentDate}`]);
  }

  return currentSeason.seasonCode;
};

export const getSeasonCode = partial(getSeasonCodeFromEnv, [config.isDev]);
