import { getCookie, setCookie } from '../storage/index';

export function getToken(): string {
  return getCookie('TOKEN') || '';
}

export function setToken(token: string): void {
  setCookie('TOKEN', token);
}

export function hasToken(): boolean {
  return !!getCookie('TOKEN');
}
