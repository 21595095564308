// メールアドレス未入力の場合にダミーとして送るメールアドレス。
// 「OPS_ORDERSYS-841 BSTAでメールアドレスを未入力にしたにも関わらず、商品出荷メールが飛んでしまっている。」の対応
const API_CUSTOMER_MAIL_ADDRESS_WHEN_NO_INPUT = 'onwardguest@presc.jp';

/**
 * APIリクエスト「customerMailAddress」にセットするメールアドレスを返却。(注文確定API、注文修正API)
 * @param inputMailAddress Mail入力フォームに入力されたメールアドレス
 * @returns Mail入力フォームに何も入力されていない場合は、'onwardguest@presc.jp'を返却。
 */
export const getRequestCustomerMailAddress = (inputMailAddress: string) =>
  !!inputMailAddress ? inputMailAddress : API_CUSTOMER_MAIL_ADDRESS_WHEN_NO_INPUT;

/**
 * APIから返却された「customerMailAddress」をステートに保存するメールアドレスに変換。(注文詳細API)
 * @param resMailAddress APIから取得したメールアドレス
 * @returns APIから返却されたメールアドレスが、'onwardguest@presc.jp'の場合は、空を返す。
 */
export const getStateMailAddressFromAPIRes = (resMailAddress: string) =>
  resMailAddress === API_CUSTOMER_MAIL_ADDRESS_WHEN_NO_INPUT ? '' : resMailAddress;
