import { IPartsAdjustOption, IAdjustOptionView, TOptionClass, IAdjustOption } from '../../_type/lookups';
import { IPiece, IPartsSize, IOption } from '../../_type/order';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { cloneDeep } from 'lodash';
import { by } from '../../../helpers';

export const adjustOptionsSelector = (state: IPartsAdjustOption[]) => new AdjustOptionsSelector(state);

class AdjustOptionsSelector {
  constructor(private state: IPartsAdjustOption[]) {}

  public filteredSelectedOptions(partsNumber: TPartsNumber, options: IOption[]): IOption[] {
    const { adjustOptions } = this.state.find(v => v.partsNumber === partsNumber) || {
      adjustOptions: [] as IAdjustOption[],
    };
    return options.filter(v => {
      const matchedOption = adjustOptions.find(by('optionNumber')(v.optionNumber));
      if (!matchedOption) {
        return false;
      }
      const matchedClass = matchedOption.optionClasses.find(by('optionClassNumber')(v.optionClassNumber));
      return !matchedClass ? false : true;
    });
  }

  public toView(
    pieces: IPiece[],
    partsSizes: {
      [partsNumber: string]: IPartsSize;
    },
  ): IAdjustOptionView[] {
    return Object.entries(partsSizes)
      .map(obj => {
        const partsNumber = obj[0] as TPartsNumber;
        const partsSize = obj[1];
        return { partsNumber, partsSize };
      })
      .map(obj => {
        const { partsNumber, partsSize } = obj;
        const { adjustOptions: selectedOptions } = partsSize;
        const { adjustOptions: historyOptions, date: historyDate } = partsSize.history;
        const partsIndexes = pieces.filter(v => v.partsNumber === partsNumber).map(v => v.index);
        const matchedOptions = this.matchAdjustOptions(partsNumber);
        const adjustOptions = matchedOptions.map(adjustOption => {
          const { optionNumber } = adjustOption;
          const selectedOption = this.matchOption(selectedOptions, optionNumber, adjustOption.optionClasses);
          const historyOption = this.matchOption(historyOptions, optionNumber, adjustOption.optionClasses);

          return {
            ...adjustOption,
            selectedClassNumber: selectedOption ? selectedOption.optionClassNumber : '',
            selectedClassName: selectedOption && selectedOption.optionClassName ? selectedOption.optionClassName : '',
            historyClassNumber: historyOption ? historyOption.optionClassNumber : '',
            historyClassName: historyOption && historyOption.optionClassName ? historyOption.optionClassName : '',
          };
        });

        return {
          partsNumber,
          partsIndexes,
          historyDate,
          adjustOptions,
        };
      });
  }

  private matchAdjustOptions(partsNumber: TPartsNumber) {
    if (!Array.isArray(this.state)) {
      return [];
    }

    const matched = this.state.find(v => v.partsNumber === partsNumber);
    if (!matched) {
      return [];
    }
    return cloneDeep(matched).adjustOptions;
  }

  private matchOption(options: IOption[], optionNumber: string, master: TOptionClass[]) {
    const matched = options.find(v => v.optionNumber === optionNumber);
    return matched ? master.find(v => v.optionClassNumber === matched.optionClassNumber) : matched;
  }
}
