import { AppState } from '../../..';
import { IRequiredCheckDialog } from '../../../../types/dialog';
import { createSelector } from 'reselect';

export { reducer as requiredCheckReducer, actions as requiredCheckActions } from './action-reducer';

const featureSelector = (state: AppState): IRequiredCheckDialog => state.utils.dialog.requiredCheck;

export const get = {
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
  texts: createSelector(featureSelector, state => state.texts),
  onClick: createSelector(featureSelector, state => state.onClick),
};
