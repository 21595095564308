import React from 'react';

import styles from './DesignAndSizeConfirmation.module.scss';

import Title from '../../../atoms/Title/Title';
import I18TextContainer from '../../../../containers/I18Text/I18Text';
import PartsConfirmationList from '../../../molecules/PartsDesignConfirmationList/PartsDesignConfirmationList';
import PartsSizeConfirmationTable from '../../../molecules/PartsSizeConfirmationTable/PartsSizeConfirmationTable';
import ConfirmationSubHeader from '../../../molecules/ConfirmationSubHeader/ConfirmationSubHeader';

import { ERouterPath } from '../../../../types';
import { langResource } from '../../../../i18n/_new/resources';
import { IPartsConfirmation, IDesignConfirmation, ISizeConfirmation} from '../../../../types/new-store/components';
import { TPricePrefix } from '../../../../types/orders';
import I18Text from '../../../../containers/I18Text/I18Text';
import { DesignItem, TOrderStatus } from '../../../../lookups/item-thisisforreplaceall/order-status';
import { canEditItem } from '../../../../helpers/orders/digest';

interface IProps {
  pricePrefix: TPricePrefix;
  orderNumber: string;
  orderStatus?: TOrderStatus;
  partsName: string;
  design: IPartsConfirmation<IDesignConfirmation>;
  size: IPartsConfirmation<ISizeConfirmation>;
  brandCode: string;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
  isDisabledEdit?: boolean;
  isFromOrderDetail?: boolean;
  isManager?: boolean;
}

type TProps = IProps;

const DesignAndSizeConfirmation: React.FC<TProps> = (props: TProps) => {
  const { partsName, orderNumber, pricePrefix, onEditOrder, isFromOrderDetail, orderStatus, isManager } = props;
  const { designs, specials } = props.design.data;
  const { gauge, isDifferentInseams } = props.size;
  const { sizes, adjusts } = props.size.data;
  const brandCode = props.brandCode;
  const {partsNumber} = props.design;
  const canEdit = isFromOrderDetail
    ? canEditItem(orderStatus || 'beforeEvans', isManager || false, DesignItem.design)
    : true;

  return (
    <div id={styles.container}>
      <div>
        <Title styles={['bgLightGray', 'medium']} title={partsName} />
      </div>
      <div className={styles.contentFlex}>
        <div className={styles.left}>
          <ConfirmationSubHeader
            headerObj={langResource.confirmationPage.designTitle}
            hasButton={true}
            buttonAction={moveToDesignPage}
            isDisabledEdit={props.isDisabledEdit || !canEdit}
          />
          <PartsConfirmationList pricePrefix={pricePrefix} options={designs} />
          <ConfirmationSubHeader
            headerObj={langResource.confirmationPage.specialOptionTitle}
            isDisabledEdit={props.isDisabledEdit || !canEdit}
          />
          <PartsConfirmationList pricePrefix={pricePrefix} options={specials} />
        </div>
        <div className={styles.right}>
          <ConfirmationSubHeader
            headerObj={langResource.confirmationPage.sizeMeasurementTitle}
            hasButton={true}
            buttonAction={moveToSizePage}
            isDisabledEdit={props.isDisabledEdit || !canEdit}
          />
          <div className={styles.sizeWrap}>
            <p className={styles.baseGauge}>
              <I18TextContainer textObj={langResource.confirmationPage.masterSize} />
              <span>{gauge}</span>
            </p>
            <PartsSizeConfirmationTable items={sizes} brandCode={brandCode} partsNumber={partsNumber}/>
            {isDifferentInseams ? (
              // 左右の股下が異なるときのみメッセージを表示する
              <div className={styles.massageWrap}>
                <div className="error-tooltip">
                  <I18Text textObj={langResource.confirmationPage.rightAndLeftInseamDifferent} />
                </div>
              </div>
            ) : null}
          </div>
          <ConfirmationSubHeader
            headerObj={langResource.confirmationPage.specialAdjustTitle}
            isDisabledEdit={props.isDisabledEdit || !canEdit}
          />
          <PartsSizeConfirmationTable items={adjusts} />
        </div>
      </div>
    </div>
  );

  function moveToDesignPage() {
    onEditOrder(ERouterPath.designSelection, orderNumber);
  }

  function moveToSizePage() {
    onEditOrder(ERouterPath.sizeCorrection, orderNumber);
  }
};

export default DesignAndSizeConfirmation;
