import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { SizeCorrectionPageEvents } from '../views/events/SizeCorrectionPage';
import { map } from 'rxjs/operators';
import { actions as SizeCorrectionActions } from '../store/order/size/actions';
import { actions as PurchaseHistoryDialogActions } from '../store/utils/dialog/purchase-history/action-reducer';
import {
  IChangeAdjustOptionParam,
  IChangeSizeMeasurementParam,
  IChangeGaugeParam,
  IChangeNudeMeasurementParam,
} from '../store/pages/size-correction/type';
import { TPartsNumber } from '../lookups/master-thisisforreplaceall';

const onLoadInitialize: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onLoadInitialize),
    map(action => SizeCorrectionActions.onLoadInitialize()),
  );

const onChangeNudeSize: Epic<AnyAction, Action<IChangeNudeMeasurementParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onChangeNudeSize),
    map(({ payload }) => SizeCorrectionActions.onChangeNudeSize(payload)),
  );

const onChangeGauge: Epic<AnyAction, Action<IChangeGaugeParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onChangeGauge),
    map(({ payload }) => SizeCorrectionActions.onChangeGauge(payload)),
  );

const onChangeParts: Epic<AnyAction, Action<{ partsNumber: TPartsNumber }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onChangeParts),
    map(({ payload }) => SizeCorrectionActions.onChangeParts(payload)),
  );

const onChangeSizeMeasurement: Epic<AnyAction, Action<IChangeSizeMeasurementParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onChangeSizeMeasurement),
    map(({ payload }) => SizeCorrectionActions.onChangeSizeMeasurement(payload)),
  );

const onChangeAdjustOption: Epic<AnyAction, Action<IChangeAdjustOptionParam>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onChangeAdjustOption),
    map(({ payload }) => SizeCorrectionActions.onChangeAdjustOption(payload)),
  );

const onCopyHistorySize: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onCopyHistorySize),
    map(({ payload }) => SizeCorrectionActions.onCopyHistorySize()),
  );

const onOpenPurchaseHistoryDialog: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SizeCorrectionPageEvents.onOpenPurchaseHistoryDialog),
    map(() => PurchaseHistoryDialogActions.open()),
  );

export const SizeCorrectionPageListeners = combineEpics(
  onLoadInitialize,
  onChangeNudeSize,
  onChangeGauge,
  onChangeParts,
  onChangeSizeMeasurement,
  onChangeAdjustOption,
  onCopyHistorySize,
  onOpenPurchaseHistoryDialog,
);
