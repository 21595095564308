import { createSelector } from 'reselect';
import { AppState } from '../../..';
import { ISelectionDialog } from '../../../../types/dialog';

export { reducer as selectionDialogReducer, actions as selectionDialogActions } from './action-reducer';

const featureSelector = (state: AppState): ISelectionDialog => state.utils.dialog.selection;

export const get = {
  data: createSelector(featureSelector, state => state),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
};
