import React from 'react';

import styles from './DesignOptionList.module.scss';

import DesignOptionListItem from '../DesignOptionListItem/DesignOptionListItem';
import { IOptionListItem } from '../../../types/option';
import { IItem } from '../../../store/_type/order';
import { IClothProduct } from '../../../store/_type/lookups';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  optionListItems: IOptionListItem[];
  partsIndex: string;
  onSelectOption: typeof DesignSelectionPageEvents.onSelectOption;
}

const DesignOptionList: React.FC<IProps> = (props: IProps) => {
  const { optionListItems } = props;
  return (
    <React.Fragment>
      <div className={styles.list}>
        {optionListItems.map((item, index) => {
          return (
            <div className={styles.item} key={index}>
              <DesignOptionListItem {...props} {...item} isSpecial={false} />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DesignOptionList;
