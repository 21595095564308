import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import InventorySearchPage from '../../../components/pages/InventorySearchPage/InventorySearchPage';
import { InventorySearchPageEvents } from '../../../views/events/InventorySearchPage';
import { AppState } from '../../../store';
import { get } from '../../../store/inventory';

function mapStateToProps(state: AppState) {
  return {
    inventories: get.inventories(state),
    updateAt: get.updateAt(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(InventorySearchPageEvents, dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(InventorySearchPage);
