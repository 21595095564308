import React from 'react';

import styles from './CustomerConfirmation.module.scss';

import Button from '../../../atoms/Button/Button';
import PriceText from '../../../atoms/PriceText/PriceText';
import I18TextContainer from '../../../../containers/I18Text/I18Text';

import { ERouterPath } from '../../../../types';
import { langResource } from '../../../../i18n/_new/resources';
import { IOrderConfirmation } from '../../../../types/new-store/components';
import { TPricePrefix } from '../../../../types/orders';

interface IProps {
  pricePrefix: TPricePrefix;
  memberscardNumber: string;
  order: IOrderConfirmation;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
  orderNumber?: string;
  isDisabledEdit?: boolean;
}

const CustomerConfirmation: React.FC<IProps> = (props: IProps) => {
  if (!props.order.customer) {
    return null;
  }

  const { pricePrefix } = props;
  const { deliveryDate, deliveryMethod, shippingPlace, shippingCost } = props.order.customer;

  const onClick = () => props.onEditOrder(ERouterPath.address, props.orderNumber);
  return (
    <div id={styles.container}>
      <div className={styles.row}>
        <div className={styles.title}>
          <I18TextContainer textObj={langResource.confirmationPage.deliveryMethod} />
        </div>
        <div className={styles.contents}>
          <div className={styles.row}>
            <p>{deliveryMethod}</p>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.title}>
          <I18TextContainer textObj={langResource.confirmationPage.deliveryPlace} />
        </div>
        <div className={styles.contents}>{renderPlace()}</div>
      </div>
      <div className={styles.rowNoBorder}>
        <div className={styles.title}>
          <I18TextContainer textObj={langResource.confirmationPage.deliveryDate} />
        </div>
        <div className={styles.contents}>
          <div className={styles.row}>
            <div className={styles.label}>
              <I18TextContainer textObj={langResource.confirmationPage.dayTime} />
            </div>
            <div className={styles.value}>{deliveryDate}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              <I18TextContainer textObj={langResource.confirmationPage.deliveryFee} />
            </div>
            <div className={styles.value}>
              <PriceText prefix={pricePrefix} price={shippingCost} styles={['inline']} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.button}>
          <Button
            onClick={onClick}
            styles={['size-s']}
            textObj={langResource.confirmationPage.editBtn}
            disabled={props.isDisabledEdit}
          />
        </div>
      </div>
    </div>
  );

  function renderPlace() {
    if (typeof shippingPlace === 'string') {
      return (
        <div className={styles.row}>
          <p>{shippingPlace}</p>
        </div>
      );
    }

    const {
      address,
      nameKana,
      nameKanji,
      phoneNumber,
      email,
      cutterNamaKana,
      memberscardNumber,
      lotNumber,
    } = shippingPlace;
    return (
      <React.Fragment>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.address} />
          </div>
          <div className={styles.value}>{address}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.custermerNameKana} />
          </div>
          <div className={styles.value}>{nameKana}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.custermerName} />
          </div>
          <div className={styles.value}>{nameKanji}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.phoneNumber} />
          </div>
          <div className={styles.value}>{phoneNumber}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.email} />
          </div>
          <div className={styles.value}>{email}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.cutterNameKana} />
          </div>
          <div className={styles.value}>{cutterNamaKana}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.lotNumber} />
          </div>
          <div className={styles.value}>{lotNumber}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.memberscardNumber} />
          </div>
          <div className={styles.value}>{memberscardNumber}</div>
        </div>
      </React.Fragment>
    );
  }
};

export default CustomerConfirmation;
