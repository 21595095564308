/**
 * ヘッダ部で発火するイベント（action）一覧
 */

import actionCreatorFactory from 'typescript-fsa';
import { IProgressRequest } from '../../services/progress/pregress';
import { ILoadCustomerParam } from '../../store/customer/action-reducer';

const actionCreator = actionCreatorFactory('- Header ');

export const HeaderEvents = {
  /**
   * 顧客ログイン
   */
  customerLogin: actionCreator<ILoadCustomerParam>('customerLogin'),
  /**
   * ログアウト
   */
  logout: actionCreator<void>('logout'),

  /**
   * stateをクリアしてホームに戻る
   */
  clearCurrentProgress: actionCreator<void>('clear current progress'),

  /**
   * 一時保留オーダー読み込み
   */
  loadProgressData: actionCreator<string>('load Progress Data'),

  /**
   * 一時保留オーダー一覧読み込み
   */
  loadProgressList: actionCreator<void>('load Progress List'),

  /**
   * 一時保留オーダー削除
   */
  deleteProgressData: actionCreator<IProgressRequest>('delete Progress Data'),

  /**
   * BSTA連携API (coupon取得)
   */
  loadCouponData: actionCreator<string>('load Coupon Data'),
};
