import { TMaster } from '../../types/index';

export const MASTER_COMPOSITION: TMaster[] = [
  { code: '001', value: '毛' },
  { code: '002', value: '綿' },
  { code: '003', value: '絹' },
  { code: '004', value: '麻' },
  { code: '005', value: 'ポリエステル' },
  { code: '006', value: 'ナイロン' },
  { code: '007', value: 'キュプラ' },
  { code: '008', value: 'レーヨン' },
  { code: '009', value: 'ポリノジック' },
  { code: '010', value: 'アセテート' },
  { code: '011', value: 'トリアセテート' },
  { code: '012', value: 'アクリル' },
  { code: '013', value: 'ポリウレタン' },
  { code: '014', value: 'ポリエチレン' },
  { code: '015', value: 'ポリクラール' },
  { code: '016', value: 'ポリプロピレン' },
  { code: '017', value: 'プロミックス' },
  { code: '018', value: 'ビニロン' },
  { code: '019', value: 'ビニリデン' },
  { code: '020', value: 'ポリ塩化ビニル' },
  { code: '021', value: 'ベニゾエート' },
  { code: '022', value: 'ガラス繊維' },
  { code: '023', value: 'カシミヤ' },
  { code: '024', value: 'アンゴラ' },
  { code: '025', value: 'アルパカ' },
  { code: '026', value: 'キャメル' },
  { code: '027', value: 'モヘヤ' },
  { code: '028', value: 'セーブル' },
  { code: '029', value: 'フォックス' },
  { code: '042', value: '亜麻' },
  { code: '043', value: '苧麻' },
  { code: '044', value: 'リネン' },
  { code: '057', value: 'ビキューナ' },
  { code: '060', value: 'ウール' },
  { code: '061', value: 'コットン' },
  { code: '063', value: 'アラミド' },
  { code: '064', value: 'らくだ' },
  { code: '065', value: '羊毛' },
  { code: '066', value: '牛革' },
  { code: '067', value: 'ミンク' },
  { code: '068', value: '豚革' },
  { code: '069', value: 'ヌートリア' },
  { code: '077', value: '鹿革' },
  { code: '080', value: '指定外繊維テンセル' },
  { code: '081', value: '指定外繊維リヨセル' },
  { code: '089', value: '指定外繊維' },
  { code: '090', value: '人工皮革' },
  { code: '091', value: '合成皮革' },
  { code: '092', value: '羊革' },
  { code: '093', value: 'ダウン' },
  { code: '094', value: 'フェザー' },
  { code: '095', value: 'その他の羽毛' },
  { code: '108', value: 'モダール' },
  { code: '230', value: '指定外繊維ダウＸＬＡ' },
  { code: '888', value: '不明' },
  { code: '999', value: 'その他' },
];

// MEMO: 下記はマスタになかったのですが、追加しました。
// { code: '060', value: 'ウール' },
// { code: '061', value: 'コットン' },
