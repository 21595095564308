import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { AppState } from '../index';
import { reducer as staffReducer, IStaffState } from './action-reducer';

export { staffActions, loginActions, reducer as staffReducer } from './action-reducer';

export interface IStaffFeature {
  staff: IStaffState;
}

export const reducers = combineReducers({
  staff: staffReducer,
});

const featureSelector = (state: AppState): IStaffFeature => state.staff;

const getStaffState = createSelector(featureSelector, state => state.staff);

export const getStaff = {
  staff: createSelector(getStaffState, state => state.loggedStaff),
  isLoggedIn: createSelector(getStaffState, state => state.loggedIn),
  isManager: createSelector(getStaffState, state => state.loggedStaff.managerFlag),
  masterInfo: createSelector(getStaffState, state => state.loggedStaff.masterInfo),
  categoryInfo: createSelector(getStaffState, state => state.loggedStaff.categoryInfo)
};
