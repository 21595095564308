import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { clothProductActions, ISetting, clothProductAsyncActions } from './action-reducer';
import { mergeMap } from 'rxjs/operators';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IItem, TPartialOrder } from '../../_type/order';
import { getClothProducts } from '../../../services/cloth-selection/cloth-selection';
import { ApiError } from '../../../models/error/api-error';
import { ECategory, TOrderItemCode } from '../../../lookups/master-thisisforreplaceall';
import { IClothProduct } from '../../_type/lookups';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { orderActions } from '../../order';
import { getPartialOrder } from '../../order/helper/conv-partial-order';
import { cloneDeep } from 'lodash';
import { INITIAL_CLOTH } from '../../order/initial-state';
import { WrapAction } from '../../_type';
import { by } from '../../../helpers';
import { getShirtItemCodesFromSubCategory } from '../../../helpers/master-lookup';

const loadData: Epic<
  AnyAction,
  Action<
    | TPartialOrder
    | Parameters<typeof clothProductAsyncActions.loadData.done>[0]
    | ISetting
    | { error: ApiError; options: any }
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(clothProductAsyncActions.loadData.started),
    mergeMap(async ({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = payload.item ? payload.item : (appStateObj.item() as IItem);
      const { categoryCode } = item;
      const { clothCode, seasonCode } = item.cloth;
      const products = await getClothProducts(clothCode, seasonCode, categoryCode).catch(err => err);
      return { payload, products, item, clothCode, seasonCode };
    }),
    mergeMap(params => {
      const { item, clothCode, seasonCode } = params;
      let { products } = params;
      if (products instanceof ApiError) {
        // 失敗した場合、IClothを初期のstateと既存のclothCode、seasonCodeで置き換える
        const initialCloth = cloneDeep(INITIAL_CLOTH);
        const data = getPartialOrder.fromCloth({ ...initialCloth, clothCode, seasonCode });
        return [
          ErrorHandlerActions.apiError({ error: products, options: { contents: '条件に該当するデータがありません.' } }),
          orderActions.updateCurrentOrder._action(data),
        ];
      } else {
        // シャツの場合、選んだサブカテゴリに対応するproductsのみ返却
        if (item.categoryCode === ECategory.shirt) {
          products = (products as IClothProduct[]).filter(v =>
            (getShirtItemCodesFromSubCategory(item.subCategoryCode) ?? []).includes(v.itemCode as TOrderItemCode),
          );
        }
      }

      return [
        clothProductActions.set._action({
          orderNumber: params.payload.orderNumber,
          products,
        }),
        clothProductAsyncActions.loadData.done({ params: params.payload, result: {} }),
      ];
    }),
  );

const loadDetailData: Epic<
  AnyAction,
  | Action<void>
  | WrapAction<typeof clothProductAsyncActions.loadDetailData.done>
  | Action<ISetting>
  | Action<{ error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(clothProductAsyncActions.loadDetailData.started),
    mergeMap(({ payload }) => {
      const { orderNumber, orderDetail } = payload;
      const match = orderDetail.dummyLookups.find(by('orderNumber')(orderNumber));
      if (!match) {
        return [
          ErrorHandlerActions.apiError({
            error: new ApiError([{ code: '500', message: '' }], 404),
            options: { contents: '注文情報から生地商品一覧のデータ復元に失敗しました.' },
          }),
        ];
      }
      return [
        clothProductActions.set._action({
          orderNumber,
          products: match.products,
        }),
        clothProductAsyncActions.loadDetailData.done({ params: payload, result: {} }),
      ];
    }),
  );

export const ProductEpics = combineEpics(loadData, loadDetailData);
