import path from 'ramda/es/path';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import join from 'ramda/es/join';
import partial from 'ramda/es/partial';
import toString from 'ramda/es/toString';

import replace from 'ramda/es/replace';
import { isIE, isEdge } from './user-agent';

const getCellData = (
  obj: any,
  setting: { name: string; path: string | string[]; convFunc?: (v: string) => string },
): string => {
  const data = path(Array.isArray(setting.path) ? setting.path : [setting.path], obj) || '';
  const strData = typeof data === 'string' ? data : toString(data);
  return setting.convFunc ? setting.convFunc(strData) : strData;
};

export const makeCsvData = (
  setting: Array<{ name: string; path: string | string[]; convFunc?: (v: string) => string }>,
  data: any[],
): any[][] => {
  const headers = setting.map(({ name }) => name);
  const rows = data.map(obj => setting.map(partial(getCellData, [obj])));
  return [headers, ...rows];
};

const wrapDoubleQuotation = (v: string) => `"${v}"`;
const escapeLineStr = replace(/\r\n|\r|\n/g, ' ');
const escapeSpecialChar = replace('"', '""');

export const escapeCellData = pipe(escapeLineStr, escapeSpecialChar, wrapDoubleQuotation);

export const addEndNewLine = (v: string): string => v + '\n';

export const downloadCsvFile = (fileName: string, rows: any[][]): void => {
  const makeRow = pipe(map(escapeCellData), join(','), addEndNewLine);
  const data = rows.map(makeRow);
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, ...data], { type: 'data:text/csv;charset=utf-8;' });

  if (isIE || isEdge) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    return;
  }

  // Creating a download link to click
  const a = window.document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.style.visibility = 'hidden';
  a.href = url;
  a.download = fileName;

  // Appending because FF does not like clicks on noneexisting links
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
