import { Dispatch, bindActionCreators } from 'redux';
import { commonPageActions } from '../../../store/pages/common';
import { selectionDialogActions } from '../../../store/utils/dialog/selection';
import { okCancelDialogActions } from '../../../store/utils/dialog/ok-cancel';
import { saveTmpOrderActions } from '../../../store/utils/dialog/save-tmpOrder';
import { CommonPageEvents } from '../../../views/events/CommonPage';
//
import { AppState } from '../../../store';
import { get as getOrder } from '../../../store/order';
import { getSize } from '../../../store/order/selector/size';
import { currentDesign } from '../../../store/order/selector/design';
import { getClothPage } from '../../../store/order/selector/cloth';
import { getOrderDetail } from '../../../store/order-detail';
import { get as getCustomer } from '../../../store/customer';
import { getAddress } from '../../../store/order/selector/address';
import { getPointPage } from '../../../store/payment/selector';
import { orderSelector } from '../../../store/order/object-selector/order';
import { orderStateSelector } from '../../../store/order/object-selector/orderState';
import { IOrderState } from '../../../store/order/index';
import { memoDialogEvents } from '../../../store/utils/dialog/memo';
import { infoDialogActions } from '../../../store/utils/dialog/info';

export function mapCommonPageStateToProps(state: AppState) {
  const hasItemState = !!orderSelector(orderStateSelector(state.order as IOrderState).currentOrder()).item();

  const isEdit = getOrder.isEdit(state);
  const isValidModifyOrder = getOrderDetail.isValidModifyOrder(state);
  const hasCompletedItems =
    hasItemState &&
    getClothPage.hasCompleted(state) &&
    currentDesign.hasCompleted(state) &&
    getSize.hasCompleted(state);
  const hasConfirmItems = !isEdit ? hasCompletedItems : hasCompletedItems && isValidModifyOrder;
  const hasConfirmOrders = !isEdit
    ? getAddress.hasCompleted(state)
    : getAddress.hasCompleted(state) && isValidModifyOrder;

  return {
    isEdit,
    hasConfirmItems,
    hasConfirmOrders,
    isValidModifyOrder,
    serial: getOrder.serial(state),
    orderNumber: getOrder.orderNumber(state),
    memberscardNumber: getCustomer.memberscardNumber(state),
  };
}

export function mapCommonPageDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(CommonPageEvents, dispatch),
    showMemoDialog: bindActionCreators(memoDialogEvents, dispatch).show,
    onDeleteOrder: commonPageActions.deleteOrder.dispatcher(dispatch),
    onDeleteAllOrders: commonPageActions.deleteOrders.dispatcher(dispatch),
    onShowOkCancelDialog: okCancelDialogActions.show.dispatcher(dispatch),
    onShowSelectionDialog: selectionDialogActions.show.dispatcher(dispatch),
    onShowSaveTmpOrderDialog: saveTmpOrderActions.show.dispatcher(dispatch),
    showInfoDialog: infoDialogActions.show.dispatcher(dispatch),
  };
}
