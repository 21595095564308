export const inventoryPage = {
  brand: {
    jp: 'ブランド',
  },
  season: {
    jp: '期別',
  },
  productNumber: {
    jp: '品番',
  },
  color: {
    jp: '色',
  },
  stock: {
    jp: '在庫状況',
  },
  enoughStock: {
    jp: '在庫あり(○)',
  },
  littleStock: {
    jp: '品薄(△)',
  },
  noStock: {
    jp: '品切れ(×)',
  },
  updateDate: {
    jp: '更新日時',
  },
  searchBtn: {
    jp: '検索する',
  },
  // TODO: 検索結果: 〇〇「件」← 件の英語版を設定
};
