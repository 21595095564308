// TODO: 仮置き場。あとで適切な位置に移動すること
// storeのdirectory下に一緒に置くのが適当かな。。。
import {
  IOrder,
  ICloth,
  IPartsSize,
  IGauge,
  IHistoryMeasurement,
  IShipping,
  IDesign,
  IPartsDesign,
  IComposition,
} from '../_type/order';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';
import { EParts } from '../../lookups/master-thisisforreplaceall/parts';
import { STOCK_FLAG } from '../../types/inventory-search';

export const INITIAL_CLOTH: ICloth = {
  seasonCode: '',
  clothCode: '',
  brandCode: '',
  clothModelCode: '',
  personalorderProductNumber: '',
  personalorderColorCode: '',
  design: '',
  compositionFront: [],
  compositionBack: [],
  stockPlaceCode: '',
  vendorClothNumber: '',
  productSeasonCode: '',
  clothSeasonCode: '',
  clothBrandCode: '',
  hasStock: STOCK_FLAG.noStock,
  requiredScale: '',
};

export const INITIAL_DESING_PARTS_DESIGN: IPartsDesign = {
  modelPattern: '',
  modelCode: '',
  optionPattern: '',
  options: [],
};

export const INITIAL_DESIGN: IDesign = {
  designParts: {},
  selecting: {
    partsIndex: '',
    optionNumber: '',
    optionClassNumber: '',
    hasOpenSelector: false,
  },
  isDouble: false,
};

export const INITIAL_BASE_GAUGE: IGauge = {
  major: '',
  minor: '',
};

export const INITIAL_HISTORY_MEASUREMENT: IHistoryMeasurement = {
  date: '',
  gauge: INITIAL_BASE_GAUGE,
  measurements: [],
  adjustOptions: [],
};

export const INITIAL_HISTORY_MEASUAMENT: IHistoryMeasurement = {
  date: '',
  gauge: INITIAL_BASE_GAUGE,
  measurements: [],
  adjustOptions: [],
};

export const INITIAL_PARTS_SIZE: IPartsSize = {
  gauge: INITIAL_BASE_GAUGE,
  measurements: [],
  history: INITIAL_HISTORY_MEASUAMENT,
  adjustOptions: [],
  standardSizes: [],
  hasChanged: false,
};

export const INITIAL_SIZE = {
  nude: {
    date: '',
    measurements: [],
  },
  parts: {},
  selecting: {
    partsNumber: EParts.jaket as TPartsNumber,
  },
};

export const INITIAL_SHIPPING: IShipping = {
  shippingPostalCode: '',
  shippingState: '',
  shippingCity: '',
  shippingStreet: '',
  customerFamilyNameKana: '',
  customerGivenNameKana: '',
  customerFamilyNameKanji: '',
  customerGivenNameKanji: '',
  shippingPhoneNumber: '',
  customerMailAddress: '',
  isSameOrderOne: true,
  deliveryMethod: '',
  shippingCost: 0,
  deliveryDateGuest: '',
  timeZoneCode: '',
  lotNumber: '',
  cutterNameKana: '',
  memberscardNumber: '',
};

export const INTIAL_ORDER: IOrder = {
  item: {
    categoryCode: '',
    subCategoryCode: '',
    itemCode: '',
    pieces: [],
    cloth: INITIAL_CLOTH,
    design: INITIAL_DESIGN,
    size: INITIAL_SIZE,
  },
  shipping: INITIAL_SHIPPING,
  shortestDeliveryDate: '',
  serialNumber: '',
  serialYear: '',
  orderDate: '',
};

export const NOSELECT_COMPOSITION_MIXING = '000';
export const INITIAL_COMPOSITION: IComposition = {
  mixing: NOSELECT_COMPOSITION_MIXING,
  mixingRatio: 0,
};
