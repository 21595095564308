import { createSelector } from 'reselect';
import { AppState } from '../../..';
import { IErrorRetryActionDialog } from '../../../../types/dialog';

export { errorRetryActionDialogReducer, errorRetryActionDialogActions } from './action-reducer';

const featureSelector = (state: AppState): IErrorRetryActionDialog => state.utils.dialog.errorRetryAction;

export const getErrorRetryActionDialog = {
  data: createSelector(featureSelector, state => state),
  hasOpen: createSelector(featureSelector, state => state.hasOpen),
};
