import { rawStrArr2EnumLikeObj } from '../../lib/utils';

const seasonCodes = rawStrArr2EnumLikeObj([
  'HW',
  'YM',
  'YW',
  'KM',
  'KW',
  'BM',
  'BW',
  'IM',
  'IW',
  'NM',
  'NW',
  'SM',
  'SW',
  'CM',
  'CW',
  'GM',
  'GW',
  'LM',
  'LW',
  'HM',
  'HW',
  'QS',
]);

export type TSeasonCode = keyof typeof seasonCodes;
