import { AppState } from '../../../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { get as getPurchaseHistory } from '../../../../store/utils/dialog/purchase-history';
import { PurchaseHistoryDialog } from '../../../../components/organisms/PurchaseHistoryDialog/PurchaseHistoryDialog';
import { PurchaseHistoryDialogEvents } from '../../../../views/events/PurchaseHistoryDialog';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: getPurchaseHistory.hasOpen(state),
    purchaseHistory: getPurchaseHistory.purchaseHistory(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(PurchaseHistoryDialogEvents, dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TPurchaseHistoryDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistoryDialog);
