import partial from 'ramda/es/partial';

import { EEmbroideryType } from '../../types/option';
import { TCategory, TPartsNumber } from '../../lookups/master-thisisforreplaceall';
import { isJacket, isMensCoat, isShirt, isWomensCoat } from '../item-thisisforreplaceall';
import { II18nItem } from '../../i18n/_new/types';
import {
  EMBROIDERY_VALIDATION_MESSAGES,
  CUSTOMER_NAME_KANJI_MAX_LENGTH,
  CUSTOMER_NAME_KANA_MAX_LENGTH,
} from '../../lookups/validation';
import { IOption } from '../../store/_type/order';
import {
  OPTION_COMBINATION_FUNCTIONS_CONFIG,
  OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY,
} from '../../lookups/item-thisisforreplaceall';
import { by } from '..';
import { countSJISBytes } from './count-string';

const lteLength = (length: number, str: string) => countSJISBytes(str) <= length;

/**
 * 氏名カナのカタカナ判定
 * @param str
 */
export const isKana = (str: string, required: boolean = false): boolean =>
  (!required || str !== '') && /^[ァ-ヴ\u30fc]*$/.test(str);

/** 日本語か？ */
export const isJapanese = (text: string): boolean =>
  /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/.test(text);

/** 英数字以外は2文字としてカウントする */
function calcTextLength(text: string): number {
  return text.split('').reduce((cur, moji) => {
    if (isJapanese(moji)) {
      return cur + 2;
    }
    return ++cur;
  }, 0);
}

// 半角文字を含んでいる
const isIncludeHankaku = (str: string) => /[\x20-\x7e]+/.test(str);

const isStrLenWithinRange = (range: { min: number; max: number }, str: string): boolean => {
  const strLen = calcTextLength(str);
  return strLen >= range.min && strLen <= range.max;
};

/**
 * 刺繍ネーム用：ローマ字 入力チェッカー。
 * 半角英数と「.」のみで構成。最初の文字と「.」の後は、大文字になる。
 * @param str - チェック対象文字列
 * @param maxLength - 最大文字数
 * @returns OKの場合 true
 */
const isValidEmbroideryRoma = (str: string, maxLength: number) =>
  isStrLenWithinRange({ min: 1, max: maxLength }, str) &&
  /^[a-zA-Z0-9.]+$/.test(str) &&
  !/^[a-z0-9]+/.test(str) &&
  !/\.[a-z0-9]+/.test(str);

/**
 * 刺繍ネーム用：漢字 入力チェッカー。
 * 全角文字のみで構成。
 * @param str - チェック対象文字列
 * @param maxLength - 最大文字数
 * @returns OKの場合 true
 */
const isValidEmbroideryKanji = (str: string, maxLength: number) =>
  isStrLenWithinRange({ min: 1, max: maxLength }, str) && !isIncludeHankaku(str);

const isValidEmbroideryScript = (str: string, maxLength: number) =>
  isStrLenWithinRange({ min: 1, max: maxLength }, str) && /^[A-Z]+$/.test(str);

const isValidEmbroideryForJacketAndMensCoat = (text: string, embroideryType: EEmbroideryType): boolean => {
  if (
    ![EEmbroideryType.ROMA, EEmbroideryType.GERMAN, EEmbroideryType.BLOCK, EEmbroideryType.KANJI].includes(
      embroideryType,
    )
  ) {
    return true;
  }
  return (
    (embroideryType === EEmbroideryType.ROMA && isValidEmbroideryRoma(text, 16)) ||
    (embroideryType === EEmbroideryType.GERMAN && isValidEmbroideryRoma(text, 16)) ||
    (embroideryType === EEmbroideryType.BLOCK && isValidEmbroideryRoma(text, 16)) ||
    (embroideryType === EEmbroideryType.KANJI && isValidEmbroideryKanji(text, 16))
  );
};

const isValidEmbroideryForWomensCoat = (text: string, embroideryType: EEmbroideryType): boolean => {
  if (![EEmbroideryType.ROMA, EEmbroideryType.KANJI].includes(embroideryType)) {
    return true;
  }
  return (
    (embroideryType === EEmbroideryType.ROMA && isValidEmbroideryRoma(text, 14)) ||
    (embroideryType === EEmbroideryType.KANJI && isValidEmbroideryKanji(text, 16))
  );
};

const isValidEmbroideryForShirt = (text: string, embroideryType: EEmbroideryType): boolean => {
  if (
    ![EEmbroideryType.HANA, EEmbroideryType.BLOCK, EEmbroideryType.ROMA, EEmbroideryType.SCRIPT].includes(
      embroideryType,
    )
  ) {
    return true;
  }
  // 花文字かブロック文字の場合
  if (embroideryType === EEmbroideryType.HANA || embroideryType === EEmbroideryType.BLOCK) {
    if (!/^[A-Z]{1}\.[A-Z]{1}$/.test(text)) {
      return false;
    }
  }
  // ローマ字の場合
  if (embroideryType === EEmbroideryType.ROMA) {
    if (!isValidEmbroideryRoma(text, 14)) {
      return false;
    }
  }
  // スクリプトの場合
  if (embroideryType === EEmbroideryType.SCRIPT) {
    if (!isValidEmbroideryScript(text, 1)) {
      return false;
    }
  }
  return true;
};

/**
 * 妥当な刺繍ネームか？
 */
export const isValidEmbroidery = (
  text: string,
  embroideryType: EEmbroideryType,
  partsNumber: TPartsNumber,
  category: TCategory,
): boolean => {
  if (embroideryType === EEmbroideryType.UNSELECTED) {
    return false;
  }
  if (embroideryType === EEmbroideryType.NONE) {
    return !text;
  }
  if (isJacket(partsNumber) || isMensCoat(partsNumber, category)) {
    return isValidEmbroideryForJacketAndMensCoat(text, embroideryType);
  }
  if (isWomensCoat(partsNumber, category)) {
    return isValidEmbroideryForWomensCoat(text, embroideryType);
  }
  if (isShirt(partsNumber)) {
    return isValidEmbroideryForShirt(text, embroideryType);
  }
  return true;
};

/**
 * 刺繍ネームのバリデーションメッセージ（これはここか？）
 */
export const getInvalidedEmbroideryMessage = (
  EmbroideryType: EEmbroideryType,
  partsNumber: TPartsNumber,
): II18nItem | undefined => {
  const match = EMBROIDERY_VALIDATION_MESSAGES.find(
    v => v.partsNumber === partsNumber && v.EmbroideryType === EmbroideryType,
  );
  return match ? match.textObj : undefined;
};

export const isValidPostalCode = (postalCode: string) => /^\d{7}$/.test(postalCode);
export const isValidFormatPhoneNumber = (phoneNumber: string) => /^[0-9]+$/.test(phoneNumber);
export const isValidLengthPhoneNumber = (phoneNumber: string) => phoneNumber.length < 13;
export const isValidPhoneNumber = (phoneNumber: string) =>
  isValidFormatPhoneNumber(phoneNumber) && isValidLengthPhoneNumber(phoneNumber);
export const isValidMailAddress = (mailAddress: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mailAddress);

export const isValidLengthShippingStreet = (shippingStreet: string) => countSJISBytes(shippingStreet) <= 50;

export const isValidLengthCustomerNameKanji = partial(lteLength, [CUSTOMER_NAME_KANJI_MAX_LENGTH]);
export const isValidLengthCustomerNameKana = partial(lteLength, [CUSTOMER_NAME_KANA_MAX_LENGTH]);

export const isValidIntroducerCode = (introducerCode: string) => /^[0-9A-Z]{6}$/.test(introducerCode);
export const isValidLotNumber = (lotNumber: string) => lotNumber.length < 31;
export const isValidMemberscardNumberInput = (memberscardNumber: string) => memberscardNumber.length < 31;
export const isValidCutterNameKana = (cutterNamaKana: string) => cutterNamaKana.length < 31;
export const isValidFavoriteCustomerCode = (favoriteCustomerCode: string) => /^[0-9A-Z]{6}$/.test(favoriteCustomerCode);
export const isValidRequiredScale = (requiredScale: string) =>
  /^([1-9][0-9]{0,2}|0)(\.[0-9]{1,2})?$/.test(requiredScale);

/** 同一パーツ内の選択オプションによって制限がかかる場合のチェックを行う */
export const isValidOptionsCombination = (
  configParam: typeof OPTION_COMBINATION_FUNCTIONS_CONFIG,
  configParamNoEmbroidery: typeof OPTION_COMBINATION_FUNCTIONS_CONFIG_NO_EMBROIDERY,
  partsNumber: TPartsNumber,
  optionNumber: string,
  selectingClassNumber: string,
  options: IOption[],
): boolean => {
  // 刺繍位置(1087)選択時、刺繍字体(1090)がない場合の特殊処理
  const optionConfig =
    optionNumber === '1087' &&
    options.some(v => v.optionNumber === '1087') &&
    options.some(v => v.optionNumber !== '1090')
      ? configParamNoEmbroidery
      : configParam;

  const config = optionConfig.find(by('partsNumber')(partsNumber));
  if (!config || !config.getCondition[optionNumber]) {
    return true;
  }
  const functions = config.getCondition[optionNumber](selectingClassNumber);
  return !functions || functions.every(v => options.filter(v).length === 1);
};
