import React from 'react';
import { History } from 'history';

import styles from './HomeImageGallery.module.scss';

import * as fromHome from '../../../store/pages/home';

import Button from '../../atoms/Button/Button';
import I18TextContainer from '../../../containers/I18Text/I18Text';

import { resolvePath } from '../../../helpers/common/path';
import { IImageGallery, IImageGalleryButton } from '../../../types/home';
import { generateSelectionDialogData } from '../../../helpers/components/dialog';
import { selectionDialogActions } from '../../../store/utils/dialog/selection';
import { ESubcategory } from '../../../lookups/master-thisisforreplaceall/subcategory';
import { infoDialogActions } from '../../../store/utils/dialog/info';
import { isStoppedStartOrderTerm } from '../../../helpers/orders';
import { MANAGER_ONLY_ORDERABLE_TERM_DIALOG } from '../../../lookups/dialog';

interface IProps {
  history: History;
  galleries: IImageGallery[];
  isManager: boolean;
  onCleate: typeof fromHome.createOrder;
  showSelectionDialog: typeof selectionDialogActions.show.type;
  showInfoDialog: typeof infoDialogActions.show.type;
  categoryInfo: any;
}

const HomeImageGallery: React.FC<IProps> = props => {
  const { history, galleries, onCleate, showSelectionDialog, isManager, showInfoDialog} = props;

  // TODO:  <img src={gallery.url} alt={gallery.title} /> の altに i18nItem は渡せないので一旦削除
  return props.categoryInfo[0].categoryName && (
    <div className={styles.homeImageGallery}>
      {galleries.map((gallery, idx) => {
        return (
          <div key={idx} className={styles.item}>
            <div className={styles.header}>
              <div className={styles.title}>
                <I18TextContainer textObj={gallery.title} />
              </div>
              <div className={styles.image}>
                <img src={gallery.url} alt="category" />
              </div>
            </div>
            <div className={styles.buttonBlock}>
              {gallery.buttons.map((button, idx2) => renderButton(button, idx2))}
            </div>
          </div>
        );
      })}
    </div>
  );

  function getClickAction(button: IImageGalleryButton) {
    const { category, subCategory, to } = button;
    if (subCategory !== ESubcategory.onepiece) {
      return () => {
        onCleate(category, subCategory);
        history.push(resolvePath(to));
      };
    }

    return () => {
      const contents = 'ジャケットをセットに含めますか？';
      const title = '確認';
      const onYes = () => {
        onCleate(category, ESubcategory.onepieceSuit);
        history.push(resolvePath(to));
      };
      const onNo = () => {
        onCleate(category, ESubcategory.onepiece);
        history.push(resolvePath(to));
      };
      const data = generateSelectionDialogData(contents, title, false, onYes, onNo);
      showSelectionDialog(data);
    };
  }

  function showOrderOnlyManagerMessage() {
    return () => {
      showInfoDialog(MANAGER_ONLY_ORDERABLE_TERM_DIALOG);
    };
  }

  function renderButton(button: IImageGalleryButton, idx: number) {
    const canOrder = !isStoppedStartOrderTerm(new Date()) || isManager;
    const onClick = canOrder ? getClickAction(button) : showOrderOnlyManagerMessage();
    return (
      <div key={idx} className={styles.button}>
        <Button styles={['black', 'size-l', 'block']} text={button.text} onClick={onClick} />
      </div>
    );
  }
};

export default HomeImageGallery;
