import { IndexedObject } from '../../../types/index';
import { IDesign, IPartsDesign, IPiece } from '../../_type/order';
import { piecesSelector } from './pieces';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { TPartsModelPattern } from '../../_type/lookups';

/**
 * IDesign からデータを取得する関数郡
 */
export const designSelector = (state: IDesign) => new DesignSelector(state);

const initialPartsDesign: IPartsDesign = {
  modelPattern: '',
  modelCode: '',
  optionPattern: '',
  options: [],
};

const addInitialParts2obj = (indexes: string[], obj: IndexedObject<IPartsDesign>): IndexedObject<IPartsDesign> =>
  indexes.reduce((acc, curr) => ({ ...acc, [curr]: { ...initialPartsDesign } }), obj);
const removeIndexesFromObj = (indexes: string[], obj: IndexedObject<IPartsDesign>): IndexedObject<IPartsDesign> => {
  const newObj = { ...obj };
  indexes.forEach(v => delete newObj[v]);
  return newObj;
};
export const adjustPartsDesign2pieces = (
  pieces: IPiece[],
  designParts: IndexedObject<IPartsDesign> = {},
): IndexedObject<IPartsDesign> => {
  const nextIndexes = pieces.map(v => v.index);
  const prevIndexes = Object.keys(designParts);
  const indexesForAdd = nextIndexes.filter(v => !prevIndexes.includes(v));
  const indexesForRemove = prevIndexes.filter(v => !nextIndexes.includes(v));
  return removeIndexesFromObj(indexesForRemove, addInitialParts2obj(indexesForAdd, designParts));
};

/**
 * 決してthis.stateを変更しないこと！！！
 */
class DesignSelector {
  constructor(private state: IDesign) {}
  public designPart(partsIndex: string) {
    return this.state.designParts[partsIndex];
  }
  public selectedOption(partsIndex: string, optionNumber: string) {
    return this.state.designParts[partsIndex].options.find(v => v.optionNumber === optionNumber);
  }
  public selectedOptionClassNumber(partsIndex: string, optionNumber: string) {
    const selectedOption = this.selectedOption(partsIndex, optionNumber);
    return selectedOption ? selectedOption.optionClassNumber : undefined;
  }
  public selecting() {
    return this.state.selecting;
  }
  /**
   * 新しいpiecesに合わせて、partsDesignの情報を変更
   * - 新規：initialPartsDesignで埋める
   * - 追加：追加分のみinitialPartsDesignで埋める
   * - 削除：減ったpartsの情報を削除
   */
  public adjustPartsDesign2pieces(pieces: IPiece[]): IndexedObject<IPartsDesign> {
    return adjustPartsDesign2pieces(pieces, this.state.designParts);
  }

  /**
   * partsNumberとmodelPatternの組み合わせを返却する
   * @param pieces
   */
  public partsModelPatterns(pieces: IPiece[]): TPartsModelPattern[] {
    return Object.entries(this.state.designParts).map(([partsIndex, { modelPattern }]) => ({
      partsNumber: piecesSelector(pieces).index2partsNumber(partsIndex) as TPartsNumber,
      modelPattern,
    }));
  }
}
