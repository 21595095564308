// utils
import { footer } from './groups/footer';
import { helper } from './groups/helper';
import { components } from './groups/components';

// page
import { loginPage } from './groups/loginPage';
import { homePage } from './groups/homePage';
import { clothPage } from './groups/clothPage';
import { designPage } from './groups/designPage';
import { sizePage } from './groups/sizePage';
import { specialAdjustPage } from './groups/specialAdjustPage';
import { addressPage } from './groups/addressPage';
import { pointPage } from './groups/pointPage';
import { confirmationPage } from './groups/confirmationPage';
import { inventoryPage } from './groups/inventoryPage';
import { orderDigestsSearchPage } from './groups/orderDigestsSearchPage';
import { settlementPage } from './groups/settlementPage';
import { orderDetailPage } from './groups/orderDetailPage';
import { header } from './groups/header';
import { search } from './groups/search';
import { stepbar } from './groups/stepbar';
import { dialog } from './groups/dialog';
import { others } from './groups/others';
import { validation } from './groups/validation';

export const langResource = {
  // TODO: こいつらの作成単位を考えてルール化する必要がありそう。。。
  footer,
  helper,
  components,
  // pages
  loginPage,
  homePage,
  clothPage,
  designPage,
  sizePage,
  specialAdjustPage,
  addressPage,
  pointPage,
  confirmationPage,
  inventoryPage,
  orderDigestsSearchPage,
  settlementPage,
  orderDetailPage,
  header,
  search,
  stepbar,
  dialog,
  others,
  validation,
};
