import { HEADER_ITEMS } from '../../lookups/header';
import Logger from '../common/logger';
import { resolvePath } from '../common/path';
import { II18nItem } from '../../i18n/_new/types';
import { langResource } from '../../i18n/_new/resources';

export function resolveHeaderTextKey(pathname?: string): II18nItem {
  const path = pathname ? pathname : window.location.pathname;
  const item = HEADER_ITEMS.find(v => resolvePath(v.path) === path);
  if (!item) {
    Logger.error(`[Error]: resolveHeaderTextKey not found ${path}`);
    return langResource.header.home;
  }
  return item.textKey;
}
