import React from 'react';

import styles from './DesignOptionGridSelector.module.scss';

import PriceTextPlane from '../PriceTextSpan/PriceTextSpan';
import I18Text from '../../../containers/I18Text/I18Text';

import { IItem } from '../../../store/_type/order';
import { IOptionClass, IClothProduct, IOptionPatternWithSelectedInfo } from '../../../store/_type/lookups';
import { getNotFoundImage } from '../../../helpers/option/image-path';
import { langResource } from '../../../i18n/_new/resources';
import {
  getSpecifiedRetailPriceTaxinForOptionSelectPage,
  isDoubleDesignOptionClass,
} from '../../../helpers/item-thisisforreplaceall';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  optionPattern: IOptionPatternWithSelectedInfo;
  onSelectOptionClass: typeof DesignSelectionPageEvents.onSelectOptionClass;
}

const DesignOptionGridSelector: React.FC<IProps> = (props: IProps) => {
  const notFoundImagePath = getNotFoundImage();
  const { item, orderNumber, products } = props;
  const { optionClasses } = props.optionPattern;

  const onSelect = (v: IOptionClass) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // OEM: ベスト追加済みの場合、ダブルの型番を選択できない
    if (
      props.item.pieces.find(i => i.partsNumber === '04') &&
      v.optionClassNumber === '143' &&
      v.imagePath.includes('1001')
    ) {
      return;
    }
    const { optionClassNumber } = v;
    props.onSelectOptionClass({ orderNumber, optionClassNumber, item, products });
  };

  const hasSelected = (optionClassNumber: string, selectingClassNumber: string): boolean => {
    return selectingClassNumber === '' || selectingClassNumber !== optionClassNumber ? false : true;
  };

  const getCardClassName = (v: IOptionClass) => {
    if (hasSelected(v.optionClassNumber, props.optionPattern.selectingClassNumber)) {
      return styles.cardSelected;
    }
    return styles.card;
  };

  const notFoundErrorHandle = (event: any) => {
    event.target.src = notFoundImagePath;
  };

  const renderPrice = (optionNumber: string, cls: IOptionClass) => {
    const specifiedPriceInfo = getSpecifiedRetailPriceTaxinForOptionSelectPage(
      item.categoryCode,
      item.cloth.brandCode,
      item.cloth.clothModelCode,
      optionNumber,
      cls.optionClassNumber,
    );
    // フロント側で、表示価格をもつ場合
    if (specifiedPriceInfo !== undefined) {
      return (
        <React.Fragment>
          <PriceTextPlane price={specifiedPriceInfo} prefix="¥" />
          <span className={styles.tax}>
            <I18Text textObj={langResource.components.priceText.tax} />
          </span>
        </React.Fragment>
      );
    }

    const isDouble = isDoubleDesignOptionClass(optionNumber, cls.optionClassNumber);
    // ダブルジャケットの場合
    if (isDouble) {
      return (
        <span>
          <I18Text textObj={langResource.designPage.doubleOptionPrice} />
        </span>
      );
    }
    return (
      <React.Fragment>
        <PriceTextPlane price={cls.retailPriceTaxin} prefix="¥" />
        <span className={styles.tax}>
          <I18Text textObj={langResource.components.priceText.tax} />
        </span>
      </React.Fragment>
    );
  };

  const renderCard = (cls: IOptionClass, key: number) => {
    return (
      <div key={key} className={styles.item}>
        <div className={getCardClassName(cls)} onClick={onSelect(cls)}>
          <div className={styles.image} title={cls.optionClassName}>
            <img src={cls.imagePath} alt={cls.optionClassName} onError={notFoundErrorHandle} />
          </div>
          <div className={styles.text}>
            <p>{cls.optionClassName}</p>
            <p className={styles.price}>{renderPrice(item.design.selecting.optionNumber, cls)}</p>
          </div>
        </div>
      </div>
    );
  };

  return <div className={styles.content}>{optionClasses.map((cls, index) => renderCard(cls, index))}</div>;
};

export default DesignOptionGridSelector;
