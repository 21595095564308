import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import styles from './DesignSelectionPage.module.scss';

// component
import DesignSelectionContent from '../../organisms/DesignSelectionContent/DesignSelectionContent';
import DesignSelectionSidebar from '../../organisms/DesignSelectionSidebar/DesignSelectionSidebar';
import DesignSelectionHeader from '../../molecules/DesignSelectionHeader/DesignSelectionHeader';
// import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';

import Footer from '../../organisms/Footer/Footer';
import { IFooterButton } from '../../../types/footer';
import { ERouterPath } from '../../../types';
import { TPageContainerProps } from '../../../containers/pages/DesignSelectionPage';
import {
  generateBackHomeBtnFunc,
  generateSaveTempBtnFunc,
  generateCancelModifiedOrderBtnFunc,
} from '../../../helpers/components/footer';
import { langResource } from '../../../i18n/_new/resources';
import history from '../../../helpers/common/history';
import { resolvePath, getOrderDetailPath } from '../../../helpers/common/path';

type TProps = TPageContainerProps & RouteComponentProps;

class DesignSelectionPage extends Component<TProps> {
  componentDidMount() {
    const { props } = this;
    const { orderNumber, item, products } = props;
    props.onLoadInitialize({
      orderNumber,
      item,
      products,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.page}>
          <div className={styles.content}>
            <div className={styles.sidebar}>
              <DesignSelectionSidebar {...this.props} />
            </div>
            <div className={styles.sideContent}>
              <div className={styles.header}>
                <DesignSelectionHeader {...this.props} />
              </div>
              <div className={styles.selector}>
                <DesignSelectionContent {...this.props} />
              </div>
            </div>
          </div>
        </div>
        {utility(this.props)}
      </React.Fragment>
    );
  }
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      func: !props.isEdit
        ? generateBackHomeBtnFunc(props.history, props.orderNumber, props.onDeleteOrder, props.onShowOkCancelDialog)
        : generateCancelModifiedOrderBtnFunc(
            props.history,
            props.serial,
            props.cancelModifiedOrder,
            props.onShowOkCancelDialog,
          ),
      textObj: !props.isEdit ? langResource.footer.home : langResource.footer.cancelEditOrder,
    },
    {
      type: 'save',
      func: generateSaveTempBtnFunc(props.onShowSaveTmpOrderDialog),
      isRender: !props.isEdit,
    },
    {
      type: 'back',
      path: ERouterPath.clothSelection,
      isRender: !props.isEdit,
    },
    {
      type: 'next',
      isDisabled: !props.hasCompleted,
      func: props.requiredCheckCompleted
        ? undefined
        : () =>
            props.onShowRequiredCheckDialog({
              hasOpen: true,
              texts: props.errorTextForRequiredCheckDialog,
              onClick: () => history.push(resolvePath(ERouterPath.clothSelection)),
            }),
      path: props.requiredCheckCompleted ? ERouterPath.sizeCorrection : undefined,
      isRender: !props.isEdit,
    },
    {
      type: 'confirm',
      isDisabled: !props.hasConfirmItems || !props.requiredCheckCompleted,
      textObj: !props.isEdit ? langResource.footer.order : langResource.footer.orderContentsEdit,
      path: !props.isEdit ? ERouterPath.itemConfirmation : undefined,
      func: !props.isEdit ? undefined : () => props.history.push(getOrderDetailPath(props.serial)),
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      {/* // FIXME: 最後にまとめて実装する */}
      {/* <LoadingInfo isLoading={props.isLoading} /> */}
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(DesignSelectionPage);
