import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ILoadOrderDetail, TSetOrderDigistFilter } from '../types/orders/digest';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { OrderDigestsSearchPageEvents } from '../views/events/OrderDigestsSearchPage';
import { map, mergeMap } from 'rxjs/operators';
import { actions as orderDigestsSearchActions } from '../store/order-digest/action-reducer';
import { diversionOrderActions } from '../store/order/diversion/actions';
import { ILoadCustomerParam } from '../store/customer/action-reducer';

/**
 * 注文検索画面の検索条件変更時の処理
 * @see OrderDigestsSearchPageEvents.setFilter
 */
const setFilter: Epic<AnyAction, Action<TSetOrderDigistFilter>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDigestsSearchPageEvents.setFilter),
    map(({ payload }) => orderDigestsSearchActions.setFilter(payload)),
  );

/**
 * 注文検索画面の検索条件初期化処理
 * @see OrderDigestsSearchPageEvents.resetFilter
 */
const resetFilter: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDigestsSearchPageEvents.resetFilter),
    map(({ payload }) => orderDigestsSearchActions.resetFilter()),
  );

/**
 * 注文検索画面の検索ボタン押下時の処理
 * @see OrderDigestsSearchPageEvents.load
 */
const load: Epic<AnyAction, Action<Parameters<typeof orderDigestsSearchActions.load.started>[0]>, AppState> = (
  action$,
  state,
) =>
  action$.pipe(
    ofAction(OrderDigestsSearchPageEvents.load),
    map(action => orderDigestsSearchActions.load.started({})),
  );

const createRefOrder: Epic<
  AnyAction,
  Action<ILoadOrderDetail | ILoadCustomerParam | { gotoHome: boolean } | void>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(OrderDigestsSearchPageEvents.onCreateRefOrder),
    mergeMap(action => {
      return [diversionOrderActions.loadDiversionOrder.started(action.payload.loadOrderDetailParam)];
    }),
  );

export const OrderDigestsSearchPageListener = combineEpics(setFilter, resetFilter, load, createRefOrder);
