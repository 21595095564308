import { combineEpics, Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { Action, AnyAction } from 'typescript-fsa';
import { map, filter, mergeMap, tap } from 'rxjs/operators';
import { getLocation } from 'connected-react-router';

import { memoDialogActions, TMemoDialog } from '.';
import { AppState } from '../../..';
import { WrapAction } from '../../../_type';
import { ApiError } from '../../../../models/error/api-error';
import { appStateSelector } from '../../../../helpers/object-selector/app-state';
import { updateMemo } from '../../../../services/orders/memo';
import { actions as ErrorHandlerActions } from '../../../errorHandling/action';

import { isOrderDetailPath } from '../../../../helpers/common/path';
import { orderDetailActions } from '../../../order-detail/index';
import { actions as orderDigistActions } from '../../../order-digest/action-reducer';
import { IOrderDigestsSearch } from '../../../../types/orders/digest';

import Logger from '../../../../helpers/common/logger';

const updateStarted: Epic<
  AnyAction,
  | WrapAction<typeof memoDialogActions.update.done>
  | WrapAction<typeof memoDialogActions.update.failed>
  | Action<{ error: ApiError; options: any }>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(memoDialogActions.update.started),
    map(({ payload }) => {
      const memoDialogState = appStateSelector(state.value).memoDialog();
      return { payload, memoDialogState };
    }),
    filter(({ memoDialogState }) => memoDialogState !== undefined),
    mergeMap(async ({ payload, memoDialogState }) => {
      const { memo, serialNumber } = memoDialogState as TMemoDialog;
      const res = await updateMemo({
        note: memo,
        requestSerial: serialNumber,
        serial: serialNumber.substr(2),
      }).catch(err => err);
      return { payload, res };
    }),
    mergeMap(({ payload, res }) => {
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({ error: res, options: { contents: '備考の更新に失敗しました.' } }),
          memoDialogActions.update.failed({ params: payload, error: {} }),
        ];
      }
      return [memoDialogActions.update.done({ params: payload, result: {} })];
    }),
  );

const updateDone: Epic<
  AnyAction,
  | Action<void>
  | WrapAction<typeof orderDetailActions.loadOrderDetail.started>
  | WrapAction<typeof orderDigistActions.load.started>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(memoDialogActions.update.done),
    map(({ payload }) => {
      const { pathname } = getLocation(state.value);
      const stateSelector = appStateSelector(state.value);
      const { serialNumber } = stateSelector.memoDialog() as TMemoDialog;
      const filterParams = stateSelector.orderDigistFilter() as IOrderDigestsSearch;
      return { pathname, filterParams, serialNumber };
    }),
    tap(({ pathname }) => Logger.log('pathname', pathname)),
    mergeMap(({ pathname, filterParams, serialNumber }) => {
      // MEMO: ページ元に合わせてリロードするActionを変える
      const reloadAction = isOrderDetailPath(pathname)
        ? orderDetailActions.loadOrderDetail.started({ orderNumber: serialNumber })
        : orderDigistActions.load.started(filterParams);
      return [reloadAction, memoDialogActions.close()];
    }),
  );

export const storeEpics = combineEpics(updateStarted, updateDone);
