import { TMaster } from '../../types/index';

export const MASTER_CLOTH_DESIGN: TMaster[] = [
  { code: '001', value: '無地' },
  { code: '002', value: '縞（小）' },
  { code: '003', value: '縞（大）' },
  { code: '004', value: '格子' },
  { code: '005', value: '千鳥' },
  { code: '006', value: '織柄' },
  { code: '007', value: 'その他' },
];
