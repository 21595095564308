import React from 'react';

import styles from './DesignOptionSpecialSelector.module.scss';

import DesignOptionSpecialList from '../../molecules/DesignOptionSpecialList/DesignOptionSpecialList';
import { IItem } from '../../../store/_type/order';
import { IClothProduct, IOptionPatternWithSelectedInfo } from '../../../store/_type/lookups';
import { IOptionListItem } from '../../../types/option';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  optionListItems: IOptionListItem[];
  optionPatterns: IOptionPatternWithSelectedInfo[];
  onSelectOption: typeof DesignSelectionPageEvents.onSelectOption;
  onSelectOptionClass: typeof DesignSelectionPageEvents.onSelectOptionClass;
}

const DesignOptionSpecialSelector: React.FC<IProps> = (props: IProps) => {
  return (
    <React.Fragment>
      <div className={styles.container}>
        {/* 仮 */}
        <DesignOptionSpecialList {...props} />
      </div>
    </React.Fragment>
  );
};

export default DesignOptionSpecialSelector;
