import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './OrderDetailPage.module.scss';

import Button from '../../atoms/Button/Button';
import Footer from '../../organisms/Footer/Footer';
import I18TextContainer from '../../../containers/I18Text/I18Text';
import OrderConfirmationDetail from '../../organisms/OrderConfirmationDetail/OrderConfirmationDetail';

import { TPageContainerProps } from '../../../containers/pages/OrderDetailPage';
import { IFooterButton } from '../../../types/footer';
import { ERouterPath } from '../../../types';
import { IOKCancelDialog } from '../../../types/dialog';
import { langResource } from '../../../i18n/_new/resources';
import PaymentConfirmation from '../../organisms/confirmations/PaymentConfirmation/PaymentConfirmation';
import { canModifyOrder, isMyTempoOrder } from '../../../helpers/item-thisisforreplaceall';
import { resolvePath, getSerialNumber } from '../../../helpers/common/path';
import { generateCancelModifiedOrderBtnFunc } from '../../../helpers/components/footer';
import Logger from '../../../helpers/common/logger';
import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';

type TProps = TPageContainerProps & RouteComponentProps;

const canEditOrder = (props: TProps): boolean => {
  const { staff, loggedStaff, isManager, orderDate, customer, hasCancel } = props;
  const { memberscardNumber } = customer;
  const { tempoCode: loggedTempoCode } = loggedStaff;
  const { tempoCode } = staff;
  const isCanEditOrder =
    canModifyOrder({ loggedTempoCode, tempoCode, isManager, orderDate, memberscardNumber }) && !hasCancel;
  return isCanEditOrder;
};

const getEditOrder = (props: TProps) => (path: ERouterPath, orderNumber?: string) => {
  const editOrder = () => {
    if (orderNumber) {
      props.editDetail({ orderNumber, path });
    }
  };
  if (!props.isEdit && props.hasCurrentOrder) {
    // 修正中でない、または現在のオーダーがある場合ダイアログを表示する
    const dialog: IOKCancelDialog = {
      hasOpen: true,
      title: '注文修正',
      contents: '注文を修正しますか？注文中のオーダーがあった場合には削除されます.',
      onOK: editOrder,
    };
    props.onShowOkCancelDialog(dialog);
  } else {
    editOrder();
  }
};

const getDeleteOrder = (props: TProps, orderNumber?: string) => () => {
  const onOK = () => {
    if (orderNumber) {
      props.setPending();
      props.cancelOrder({ orderNumber });
    }
  };
  const dialog: IOKCancelDialog = {
    hasOpen: true,
    title: '注文削除',
    contents: '注文を削除しますか？',
    onOK,
  };
  props.onShowOkCancelDialog(dialog);
};

const OrderDetailPage: React.FC<TProps> = props => {
  const { loadDetail, orderConfirmations, clearDetail, isEdit, isManager, loggedStaff, staff } = props;
  const pathParam = getSerialNumber(props.location);
  /** TODO: serialNoに変更する */
  const orderNumber = pathParam ? pathParam : undefined;

  useEffect(() => {
    Logger.log('useEffect', [orderNumber, loadDetail, clearDetail, isEdit]);
    if (!isEdit) {
      loadDetail({ orderNumber: orderNumber || '' });
    } else {
      loadDetail({ orderNumber: '' });
    }
  }, [orderNumber, loadDetail, clearDetail, isEdit]);

  const onDeleteOrder = getDeleteOrder(props, orderNumber);
  const onEditOrder = getEditOrder(props);
  const isDisableCancel = props.isEdit || !canEditOrder(props);

  const onOpenMemoDialog = () => {
    const serialNumber = String(orderNumber);
    props.showMemoDialog({
      serialNumber,
      memo: props.note,
      canEdit: true,
    });
  };

  return (
    <div id={styles.pageContainer}>
      <div className={styles.printPage}>
        <div className={styles.header}>
          <div className={styles.membersId}>
            <Button
              styles={['red', 'size-s']}
              onClick={onDeleteOrder}
              disabled={isDisableCancel}
              textObj={langResource.orderDetailPage.orderCancel}
            />
            <I18TextContainer textObj={langResource.confirmationPage.memberscardNumber} />：
            <span>{props.customer.memberscardNumber}</span>
          </div>
        </div>
        <div className={styles.payment}>
          {props.paymentConfirmation ? (
            <PaymentConfirmation
              {...props}
              paymentConfirmation={props.paymentConfirmation}
              onEditOrder={onEditOrder}
              orderNumber={orderNumber}
              // MEMO: 紹介者と流通区分は管理者でない場合、自店舗の注文のみ修正できる.
              isDisabledEdit={!isManager && !isMyTempoOrder(loggedStaff.tempoCode, staff.tempoCode)}
              isFromOrderDetail={true}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.printPage}>
        <div className={styles.order}>
          {orderConfirmations.map((order, key) => (
            <OrderConfirmationDetail
              key={key}
              order={order}
              {...props}
              isFromOrderDetail={true}
              onEditOrder={onEditOrder}
              isDisabledEdit={!canEditOrder(props)}
              memberscardNumber={props.customer.memberscardNumber}
            />
          ))}
        </div>
      </div>
      <div className={styles.printPage}>
        <div className={styles.header}>
          <div className={styles.note}>
            <p>
              <I18TextContainer textObj={langResource.confirmationPage.memo} />： <span>{props.note}</span>
            </p>
            <Button
              styles={['blown', 'size-s']}
              onClick={onOpenMemoDialog}
              disabled={props.isEdit}
              textObj={langResource.orderDetailPage.change}
            />
          </div>
        </div>
      </div>
      {utility(props)}
    </div>
  );
};

function utility(props: TProps) {
  return (
    <React.Fragment>
      <LoadingInfo isLoading={props.pending} />
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

function getFooterButtons(props: TProps): IFooterButton[] {
  const onCommitEditOrder = () => {
    props.setPending();
    props.sendOrder();
  };

  return [
    {
      type: 'save',
      textObj: !props.isEdit ? langResource.footer.backToDigests : langResource.footer.cancelEditOrder,
      func: !props.isEdit
        ? () => {
            props.setIsEdit({ isEdit: false });
            props.clearDetail();
            props.history.push(resolvePath(ERouterPath.ordersSearch));
          }
        : generateCancelModifiedOrderBtnFunc(
            props.history,
            props.serial,
            props.cancelModifiedOrder,
            props.onShowOkCancelDialog,
          ),
    },
    {
      type: 'print',
      func: () => window.print(),
    },
    {
      type: 'confirm',
      textObj: langResource.footer.updateOrder,
      isDisabled: !props.isEdit || !props.isValidModifyOrder,
      func: onCommitEditOrder,
    },
  ];
}

export default withRouter(OrderDetailPage);
