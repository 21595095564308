import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { TMemoDialog, TShowMemoDialogParams } from '.';

const actionCreator = actionCreatorFactory('[Memo Dialog]');
const actions = {
  show: actionCreator<TShowMemoDialogParams>('show'),
  changeMemo: actionCreator<string>('changeMemo'),
  update: actionCreator.async<{}, {}, {}>('update'),
  close: actionCreator<void>('close'),
};
export const memoDialogActions = actions;

const initialState: TMemoDialog = {
  hasOpen: false,
  memo: '',
  previousMemo: '',
  canEdit: false,
  serialNumber: '',
};

export const memoDialogReducer = reducerWithInitialState(initialState)
  .case(actions.show, (state, payload) => ({ ...state, ...payload, hasOpen: true, previousMemo: payload.memo }))
  .case(actions.changeMemo, (state, payload) => ({ ...state, memo: payload }))
  .case(actions.close, (state, payload) => ({ ...state, ...initialState }));
