import actionCreatorFactory from 'typescript-fsa';
import { IOrderDigests } from '../../../../types/orders/digest';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

const actionCreator = actionCreatorFactory('[- purhcase hisotry]');

export const actions = {
  open: actionCreator('open'),
  close: actionCreator('close'),
  load: actionCreator.async<void, IOrderDigests[], {}>('load'),
};

export interface IPurchaseHistoryState {
  hasOpen: boolean;
  purchaseHistory: IOrderDigests[];
}

export const initialState: IPurchaseHistoryState = {
  hasOpen: false,
  purchaseHistory: [],
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.open, state => ({ ...state, hasOpen: true }))
  .case(actions.close, state => ({ ...state, hasOpen: false }))
  .case(actions.load.done, (state, payload) => ({ ...state, purchaseHistory: payload.result }));
