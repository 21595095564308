export const dialog = {
  title: { jp: '情報' },
  closeButtonLabel: {
    jp: '閉じる',
  },
  reTryButonLabel: {
    jp: '再実行',
  },
  information: {
    title: { jp: '情報' },
  },
  error: {
    title: { jp: '予期せぬエラーが発生しました' },
    closeBtn: { jp: '閉じる' },
  },
  loginAndSave: {
    temporarilySaved: {
      title: { jp: '一時保留オーダー' },
      staffCode: { jp: '担当者コード' },
    },
  },
  saveTmpOrder: {
    title: { jp: '保存確認' },
    name: { jp: 'お名前' },
    saveBtn: { jp: '保存' },
    cancelBtn: { jp: 'キャンセル' },
  },
  requiredCheck: {
    title: { jp: '入力チェックエラー' },
    okBtn: { jp: 'OK' },
  },
  purchaseHistory: {
    title: { jp: '購入履歴' },
    loadBtn: { jp: '読込' },
  },
  memo: {
    title: { jp: '備考欄' },
    close: { jp: '閉じる' },
    update: { jp: '更新' },
  },
  projectSelector: {
    title: { jp: '案件選択' },
    // filter & column
    projectCondition: { jp: '案件状況' },
    checkBoxText: { jp: '決定でないものを含める' },
    companyName: { jp: '法人名' },
    projectName: { jp: '案件名' },
    contactName: { jp: '担当者名' },
    salesRecordingDate: { jp: '売上計上日' },
    actions: { jp: '選択' },
    // button
    loadBtn: { jp: '検索する' },
    resetBtn: { jp: '検索条件をリセット' },
    selectBtn: { jp: '選択' },
    otherBtn: { jp: 'その他を選択' },
    closeBtn: { jp: '閉じる' },
  },
  couponInfo: {
    close: { jp: '閉じる' },
  },
};
