import { TPartialOrder, IOrder, IDesignParts, IPartsDesign, IItem } from '../../_type/order';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { getPartialOrder } from '../helper/conv-partial-order';
import { map, filter, mergeMap } from 'rxjs/operators';
import { actions, IUpdateOptionClasses } from './actions';
import { orderActions } from '..';
import { getEditPartsDesign } from '../../pages/design-selection/helper';
import { getBaseItemInfo, getOptionPattern } from '../../../helpers/available-option';
import { NestedPartial, IndexedObject } from '../../../types';
import { availableOptionsSelector } from '../../lookups/object-selector/availableOptions';
import { editedAvailableOptionsSelector } from '../../lookups/object-selector/editedAvailableOptionsSelector';
import { designSelector, adjustPartsDesign2pieces } from '../object-selector/design';
import {
  isDoubleDesignOptionClass,
  isDesignOption,
  getAffectedOptionsInSameParts,
} from '../../../helpers/item-thisisforreplaceall/what-is-the-option-optionClass';
import { isOptionNumberForJudgeIsDouble } from '../../../lookups/item-thisisforreplaceall/option';
import { availableOptionAsyncActions } from '../../lookups/available-option/action-reducer';
import pick from 'ramda/es/pick';
import { clothProductsSelector } from '../../lookups/object-selector/cloth-product';
import { isModelSelectBoxOnDesignPartsSection } from '../../../helpers/item-thisisforreplaceall/show-or-hide';
import {
  getSelectedAndSelectableOptions,
  getSelectedOption,
} from '../../../helpers/item-thisisforreplaceall/selected-value-getter';
import { piecesSelector } from '../object-selector/pieces';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall/parts';
import { by } from '../../../helpers';
import { IInformationDialog } from '../../../types/dialog';
import { infoDialogActions } from '../../utils/dialog/info';
import { isSameOptionPriceTaxIn, getOptionClassFromAvailableOptions } from '../../../helpers/option';
import { WrapAction } from '../../_type';
import { optionGroupAction } from '../../utils/optionGroup';
import { IAvailableOption } from '../../_type/lookups';

const loadAvailableOptionsForInitialize: Epic<
  AnyAction,
  Action<Parameters<typeof availableOptionAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadAvailableOptionsForInitialize),
    map(({ payload }) => {
      const { orderNumber, item, products } = payload;
      return availableOptionAsyncActions.loadData.started({ orderNumber, item, products, forWhat: 'initialize' });
    }),
  );

const loadInitialize: Epic<AnyAction, Action<any>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.loadInitialize),
    map(({ payload }) => {
      const isEdit = appStateSelector(state.value).isEditOrder();
      return { payload, isEdit };
    }),
    map(({ payload, isEdit }) => {
      const { item, products } = payload.params;
      const { categoryCode, itemCode, brandCode, clothModelCode, pieces, subCategoryCode } = getBaseItemInfo(item);
      const clothSelector = clothProductsSelector({
        itemCode,
        brandCode,
        products,
        subCategoryCode
      });
      const { availableOptions } = payload;
      // パーツ別に確定済みのoptionPatternを取得 + 選択肢が一つしか無いデザインオプションをmodelCodeとして取得
      const partsOptionsPatterns = item.pieces.map(({ index: partsIndex, partsNumber }) => {
        const designPart = item.design.designParts[partsIndex];
        const { optionPattern: selectedOptionPattern, modelCode: selectedModelCode } = designPart
          ? { ...pick(['optionPattern', 'modelCode'], designPart) }
          : { optionPattern: '', modelCode: '' };

        const selectableOptionPatterns = clothSelector.getSelectableDesignModelCodes(item.categoryCode, partsNumber);
        const optionPattern = selectableOptionPatterns.includes(selectedOptionPattern)
          ? // 選択したのがあればそのまま設定
            selectedOptionPattern
          : getOptionPattern(selectableOptionPatterns, categoryCode, partsNumber, clothModelCode, brandCode);

        const modelCode = isModelSelectBoxOnDesignPartsSection(categoryCode, partsNumber, brandCode)
          ? // デザイン画面でモデルを選択するパーツの場合、modelCodeにoptionPatternを設定
            optionPattern
          : // レディースの場合、modelCodeには生地選択画面で選択したモデルを設定する
          categoryCode === 'WM'
          ? clothModelCode
          : // 上記以外
            (() => {
              const matchedOptionPattern = (
                availableOptions.find(v => v.partsNumber === partsNumber) || { optionPatterns: [] }
              ).optionPatterns.find(v => isDesignOption(v.optionNumber));

              // 選択済みかつ選択肢ある場合
              if (
                selectedModelCode !== '' &&
                matchedOptionPattern &&
                matchedOptionPattern.optionClasses.find(by('optionClassNumber')(selectedModelCode))
              ) {
                return selectedModelCode;
              }

              // 選択肢が１つしか無いデザインオプションであれば、デフォルト値として当該optionClassNumberを設定
              if (matchedOptionPattern && matchedOptionPattern.optionClasses.length === 1) {
                return matchedOptionPattern.optionClasses[0].optionClassNumber;
              }

              return '';
            })();

        return { partsIndex, optionPattern, modelCode };
      });

      // FIXME: この処理は当オーダーで当画面を初めて開いた時のみ実施する 、 本来 NestedPartial<IDesignParts> な気がする
      const initialDesignParts: IDesignParts = Object.entries(adjustPartsDesign2pieces(item.pieces)).reduce(
        (acc, [partsIndex, partsDesing]) => {
          const partsOptionPattern = partsOptionsPatterns.find(v => v.partsIndex === partsIndex);
          const optionPattern = partsOptionPattern ? partsOptionPattern.optionPattern : '';
          const modelCode = partsOptionPattern ? partsOptionPattern.modelCode : '';
          return { ...acc, [partsIndex]: { ...partsDesing, modelPattern: optionPattern, optionPattern, modelCode } };
        },
        {},
      );
      const aoSelector = availableOptionsSelector(availableOptions);

      // 選択済みのoptionsが新しいalairableOptionsに存在すれば、値を保持
      const newDesignAddSelectedOptions = getSelectedAndSelectableOptions(
        availableOptions,
        pieces,
        item.design.designParts,
        initialDesignParts,
      );

      // 初期設定
      const partsModelPatterns = clothSelector.getPartsSelectableDesignModelCodes(categoryCode, pieces);
      const initialSelecting = editedAvailableOptionsSelector(
        aoSelector.withSelectedInfo(
          item.pieces,
          newDesignAddSelectedOptions,
          item.design.selecting,
          item.categoryCode,
          clothModelCode,
          brandCode,
        ),
      ).getInitialSelecting(partsModelPatterns, categoryCode, brandCode, isEdit);

      const data: TPartialOrder = getPartialOrder.fromDesignParts(newDesignAddSelectedOptions, initialSelecting);
      return orderActions.updateCurrentOrder._action({ ...data, ...{ item: { design: data.item?.design, itemCode } } });
    }),
  );

const changeItemCode: Epic<AnyAction, Action<any> | Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.changeItemCode),
    map(({ payload }) => {
      const { availableOptions } = payload;
      const { products, item, pieces, isDouble, itemCode } = payload.params;
      const { categoryCode, brandCode, clothModelCode, subCategoryCode } = getBaseItemInfo(item);

      if (!itemCode) {
        return actions.showNotFoundItemCode();
      }

      const clothSelector = clothProductsSelector({ itemCode, brandCode, products, subCategoryCode });
      // デザイン更新
      const design = designSelector(item.design).adjustPartsDesign2pieces(pieces);

      // パーツを追加した場合、optionPatternが設定されていないので、設定する
      const newDesign = Object.keys(design).reduce((pre, partsIndex) => {
        const piece = piecesSelector(pieces).pieceByIndex(partsIndex);
        if (!piece) {
          return pre;
        }
        const optionPatterns = clothSelector.getSelectableDesignModelCodes(item.categoryCode, piece.partsNumber);
        const optionPattern = getOptionPattern(
          optionPatterns,
          categoryCode,
          piece.partsNumber,
          clothModelCode,
          brandCode,
        );
        return {
          ...pre,
          [partsIndex]: {
            ...design[partsIndex],
            optionPattern: design[partsIndex].optionPattern !== '' ? design[partsIndex].optionPattern : optionPattern,
            modelPattern: design[partsIndex].optionPattern !== '' ? design[partsIndex].optionPattern : optionPattern,
          },
        };
      }, {} as IndexedObject<IPartsDesign>);

      // 選択済みのoptionsが新しいalairableOptionsに存在すれば、値を保持
      const newDesignAddSelectedOptions = getSelectedAndSelectableOptions(
        availableOptions,
        pieces,
        item.design.designParts,
        newDesign,
      );

      // orderの更新
      const data = getPartialOrder.fromChangePieceAction(itemCode, pieces, newDesignAddSelectedOptions, isDouble);

      // パーツを削除した場合は別アクションを呼び出す。
      const oldPieces = appStateSelector(state.value).pieces() || [];
      if (pieces.length < oldPieces.length) {
        return orderActions.updateCurrentOrderDeleteParts._action(data);
      }
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

const addPiece: Epic<
  AnyAction,
  Action<Parameters<typeof availableOptionAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.addPiece),
    map(({ payload }) => {
      const { orderNumber, item, partsNumber, products } = payload;
      const { pieces, isDouble } = getBaseItemInfo(item);

      // pieceの更新
      const partsIndex = String(Math.max(...pieces.map(v => +v.index)) + 1);
      const newPieces = [...pieces, { index: partsIndex, partsNumber }];
      return availableOptionAsyncActions.loadData.started({
        products,
        orderNumber,
        item,
        pieces: newPieces,
        isDouble,
        forWhat: 'changeItemCode',
      });
    }),
  );

const deletePiece: Epic<
  AnyAction,
  Action<Parameters<typeof availableOptionAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.deletePiece),
    map(({ payload }) => {
      const { orderNumber, item, partsIndex, products } = payload;
      const { pieces, isDouble } = getBaseItemInfo(item);

      // pieceの更新
      const newPieces = pieces.filter(v => v.index !== partsIndex);
      return availableOptionAsyncActions.loadData.started({
        products,
        orderNumber,
        item,
        pieces: newPieces,
        isDouble,
        forWhat: 'changeItemCode',
      });
    }),
  );

const setDefault: Epic<AnyAction, Action<IUpdateOptionClasses>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.setDefault),
    map(({ payload }) => {
      const { partsIndex, item, availableOptions, orderNumber, products } = payload;
      const { pieces } = item;
      const { designParts, selecting } = item.design;
      // 未選択かつdefaultがあるオプションを取得する
      const editiedOptions = availableOptionsSelector(availableOptions).withSelectedInfo(
        pieces,
        designParts,
        selecting,
        item.categoryCode,
        item.cloth.clothModelCode,
        item.cloth.brandCode,
      );
      const newOptions = editedAvailableOptionsSelector(editiedOptions).getOptionsFilledByDefault(
        designParts,
        partsIndex,
      );

      const defaultOptionClasses = newOptions.map(v => {
        return {
          optionClassNumber: v.optionClassNumber,
          optionNumber: v.optionNumber,
        };
      });

      return actions.updateOptionClasses({
        partsIndex,
        orderNumber,
        products,
        item,
        optionClasses: defaultOptionClasses,
      });
    }),
  );

const selectOption: Epic<
  AnyAction,
  Action<NestedPartial<IOrder>> | WrapAction<typeof optionGroupAction.clearGroupId>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.selectOption),
    mergeMap(({ payload }) => {
      const { partsIndex, optionNumber, item, hasOpen } = payload;
      // MEMO: 選択中のoptionClassNumberを取得する
      const optionClassNumber = designSelector(item.design).selectedOptionClassNumber(partsIndex, optionNumber) || '';
      const data: TPartialOrder = getPartialOrder.fromSelectDesignOptionAction(
        partsIndex,
        optionNumber,
        optionClassNumber,
        !!hasOpen,
      );
      return [orderActions.updateCurrentOrder._action(data), optionGroupAction.clearGroupId()];
    }),
  );

// TODO: 手で直接選んだ時のみ実行されるようになっているが、本来別のフックの方が良いよね。。。
const setAffectedOptionValueInSameParts = (
  partsNumber: TPartsNumber,
  optionNumber: string,
  optionClassNumber: string,
  partsDesign: IPartsDesign,
): IPartsDesign => {
  // 該当のオプションが無い場合、今回は選択ではなくキャンセルであったとみなし、何もしない
  if (!partsDesign.options.find(v => v.optionNumber === optionNumber && v.optionClassNumber === optionClassNumber)) {
    return partsDesign;
  }

  const newOptions = getAffectedOptionsInSameParts(partsNumber, optionNumber, optionClassNumber);
  if (!newOptions) {
    return partsDesign;
  }
  partsDesign.options = (Array.isArray(newOptions) ? newOptions : [newOptions]).reduce(
    (acc, curr) => [...acc.filter(v => v.optionNumber !== curr.optionNumber), curr],
    partsDesign.options,
  );
  return partsDesign;
};

const judgeCanChangeOption = (
  item: IItem,
  partsIndex: string,
  availableOptions: IAvailableOption[],
  partsNumber: TPartsNumber,
  isEdit: boolean,
  optionNumber: string,
  optionClassNumber: string,
): boolean => {
  const prevOptionClassNumber =
    getSelectedOption(item.design.designParts, partsIndex, optionNumber)?.optionClassNumber || '';
  const prevOptionClass = getOptionClassFromAvailableOptions(
    availableOptions,
    partsNumber,
    optionNumber,
    prevOptionClassNumber,
  );
  const currentOptionClassNumber = optionClassNumber;
  const isSameOptionPrice = isSameOptionPriceTaxIn(
    { availableOptions, partsNumber, optionNumber },
    prevOptionClassNumber,
    currentOptionClassNumber,
  );
  const isSameOptionClass = prevOptionClassNumber === currentOptionClassNumber;
  const isPrevOptionRetailPriceTaxinZero = (prevOptionClass?.retailPriceTaxin || 0) === 0;
  // 同一OptionClassを選択した場合、選択中のオプションが外れるので、選択中のオプション価格が0円である場合、修正可
  // 同一OptionClassでない場合、価格が一緒であれば修正可
  const canChangeOption = !isEdit || (!isSameOptionClass ? isSameOptionPrice : isPrevOptionRetailPriceTaxinZero);
  return canChangeOption;
};

const selectOptionClass: Epic<
  AnyAction,
  | Action<NestedPartial<IOrder> | Parameters<typeof availableOptionAsyncActions.loadData.started>[0]>
  | Action<IInformationDialog>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.selectOptionClass),
    map(({ payload }) => {
      const isEdit = appStateSelector(state.value).isEditOrder();
      const availableOptions = appStateSelector(state.value).avaliableOptions() || [];
      return { payload, isEdit, availableOptions };
    }),
    map(({ payload, isEdit, availableOptions }) => {
      const { orderNumber, optionClassNumber, item, optionClassName, hasOpen, products } = payload;
      const { partsIndex, optionNumber } = item.design.selecting;
      const partsNumber = piecesSelector(item.pieces).index2partsNumber(partsIndex);

      const canChangeOption = judgeCanChangeOption(
        item,
        partsIndex,
        availableOptions,
        partsNumber ?? '01',
        isEdit,
        optionNumber,
        optionClassNumber,
      );
      if (!canChangeOption) {
        const data = {
          hasOpen: true,
          title: '確認',
          contents: '注文修正の場合、オプション価格が変わる修正はできません.',
        };
        return infoDialogActions.show._action(data);
      }

      const newDesignParts = getEditPartsDesign(
        partsIndex,
        optionNumber,
        optionClassNumber,
        item,
        false,
        optionClassName,
      );
      const isDouble = isDoubleDesignOptionClass(optionNumber, optionClassNumber);

      if (!isOptionNumberForJudgeIsDouble(optionNumber) || item.design.isDouble === isDouble) {
        const newPartsDesign =
          partsNumber !== undefined
            ? setAffectedOptionValueInSameParts(
                partsNumber,
                optionNumber,
                optionClassNumber,
                newDesignParts[partsIndex],
              )
            : newDesignParts[partsIndex];
        // itemCodeの変更がない場合
        const orderData: TPartialOrder = getPartialOrder.fromSelectDesignOptionAction(
          partsIndex,
          optionNumber,
          optionClassNumber,
          !!hasOpen,
          newPartsDesign,
        );
        return orderActions.updateCurrentOrder._action(orderData);
      }

      // itemCodeの変更がある場合
      return availableOptionAsyncActions.loadData.started({
        products,
        orderNumber,
        item,
        pieces: item.pieces,
        isDouble,
        forWhat: 'changeItemCode',
      });
    }),
  );

// 2021年3月現在はデフォルトセット時しか呼び出されない。
const UpdateOptionClasses: Epic<
  AnyAction,
  | Action<NestedPartial<IOrder> | Parameters<typeof availableOptionAsyncActions.loadData.started>[0]>
  | Action<IInformationDialog>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.updateOptionClasses),
    map(({ payload }) => {
      const isEdit = appStateSelector(state.value).isEditOrder();
      const availableOptions = appStateSelector(state.value).avaliableOptions() || [];
      return { payload, isEdit, availableOptions };
    }),
    mergeMap(({ payload, isEdit, availableOptions }) => {
      const { orderNumber, item, products, partsIndex, optionClasses } = payload;
      const partsNumber = piecesSelector(item.pieces).index2partsNumber(partsIndex);

      const hasCannotChangeOption = optionClasses.some(v => {
        const canChangeOption = judgeCanChangeOption(
          item,
          partsIndex,
          availableOptions,
          partsNumber ?? '01',
          isEdit,
          v.optionNumber,
          v.optionClassNumber,
        );
        return !canChangeOption;
      });

      if (hasCannotChangeOption) {
        const data = {
          hasOpen: true,
          title: '確認',
          contents: '注文修正の場合、オプション価格が変わる修正はできません.',
        };
        return [infoDialogActions.show._action(data)];
      }

      const ItemWithNewPartsDesign = optionClasses.reduce((acc, cur) => {
        const newDesignParts = getEditPartsDesign(partsIndex, cur.optionNumber, cur.optionClassNumber, acc, true);
        const newPartsDesign =
          partsNumber !== undefined
            ? setAffectedOptionValueInSameParts(
                partsNumber,
                cur.optionNumber,
                cur.optionClassNumber,
                newDesignParts[partsIndex],
              )
            : newDesignParts[partsIndex];
        const newItem = {
          ...item,
          ...{ design: { ...item.design, ...{ designParts: { [partsIndex]: newPartsDesign } } } },
        };
        return newItem;
      }, item);

      const updateOrderData: TPartialOrder = getPartialOrder.fromUpdateOptionClassesAction(
        partsIndex,
        ItemWithNewPartsDesign.design.designParts[partsIndex],
      );

      // itemCodeに変更が必要でない場合は「undifined」になる。必要な場合は「isDouble」の値を、
      // availableOptionAsyncActions.loadData.started の「isDouble」にセット。
      const judgedNeedChangeItemCode = optionClasses
        .map(v => {
          const isDouble = isDoubleDesignOptionClass(v.optionNumber, v.optionClassNumber);
          return {
            needChangeItemCode: isOptionNumberForJudgeIsDouble(v.optionNumber) && item.design.isDouble !== isDouble,
            isDouble,
          };
        })
        .find(v => v.needChangeItemCode);

      // itemCodeに変更が必要な場合は、2つのアクションを呼び出す。
      if (judgedNeedChangeItemCode !== undefined) {
        return [
          orderActions.updateCurrentOrder._action(updateOrderData),
          availableOptionAsyncActions.loadData.started({
            products,
            orderNumber,
            item,
            pieces: item.pieces,
            isDouble: judgedNeedChangeItemCode.isDouble,
            forWhat: 'changeItemCode',
          }),
        ];
      }

      // itemCodeに変更が必要でない場合は、Stateだけアップデート。
      return [orderActions.updateCurrentOrder._action(updateOrderData)];
    }),
  );

/**
 * clothのプロパティ値変更（単一の値を持つ要素のみ）
 */
const updateFreeInput: Epic<
  AnyAction,
  Action<TPartialOrder | Parameters<typeof actions.updateFreeInput.done>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(actions.updateFreeInput.started),
    map(({ payload }) => {
      const appSelector = appStateSelector(state.value);
      const item = appSelector.item();
      return { payload, item };
    }),
    filter(({ item }) => item !== undefined && item.design.designParts[item.design.selecting.partsIndex] !== undefined),
    map(obj => {
      const item = obj.item as IItem;
      const { optionClassName, optionClassNumber } = obj.payload;
      const { partsIndex, optionNumber } = item.design.selecting;
      const newDesignParts = getEditPartsDesign(
        partsIndex,
        optionNumber,
        optionClassNumber,
        item,
        false,
        optionClassName,
      );
      const data = getPartialOrder.fromInputFreeOptionAction(partsIndex, newDesignParts[partsIndex]);
      return orderActions.updateCurrentOrder._action(data);
    }),
  );

const showNotFoundItemCode: Epic<AnyAction, Action<IInformationDialog>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.showNotFoundItemCode),
    map(({ payload }) => {
      const data = {
        hasOpen: true,
        title: '確認',
        contents:
          'パーツの組み合わせが不正です。カテゴリ、ブランド、パーツ、シングル/ダブル の組み合わせをご確認ください。',
      };
      return infoDialogActions.show._action(data);
    }),
  );

export const OrderDesignEpics = combineEpics(
  loadAvailableOptionsForInitialize,
  loadInitialize,
  changeItemCode,
  addPiece,
  deletePiece,
  setDefault,
  UpdateOptionClasses,
  selectOption,
  selectOptionClass,
  updateFreeInput,
  showNotFoundItemCode,
);
