import { ICustomer } from '../_type/customer';

export const INITIAL_CUSTOMER: ICustomer = {
  customerCode: '',
  customerSex: 0,
  customerBirthday: '',
  memberscardNumber: '',
  shippingPostalCode: localStorage.getItem('shippingPostalCode') ?? '',
  shippingState: localStorage.getItem('shippingState') ?? '',
  shippingCity: localStorage.getItem('shippingCity') ?? '',
  shippingStreet: localStorage.getItem('shippingStreet') ?? '',
  customerFamilyNameKana: '',
  customerGivenNameKana: '',
  customerFamilyNameKanji: '',
  customerGivenNameKanji: '',
  shippingPhoneNumber: localStorage.getItem('shippingPhoneNumber') ?? '',
  customerMailAddress: '',
};
