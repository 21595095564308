import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Sentry from '@sentry/browser';
import { actionObjectCreatorFactory, thunkActionObjectCreatorFactory } from '../../lib/action-creator-factory';

import { login as loginStaff } from '../../services/staff/staff';
import { setToken } from '../../services/auth';

import history from '../../helpers/common/history';
import { resolvePath } from '../../helpers/common/path';
import { ERouterPath } from '../../types';
import { ILoginReq } from '../../types/login-api';
import { toStaff } from '../../helpers/api/converter/staff';
import { IStaff } from '../_type/staff';
import { actions as errorHandlerAction } from '../errorHandling/action';
import { ApiError } from '../../models/error/api-error';

const actionCreator = actionCreatorFactory('[Staff]');
// TODO: actions, staffActions と被るので、この名前しか付けられなかった。。。。
export const loginActions = {
  login: actionCreator<IStaff>('login'), // こっちのloginは、顧客ログアウト後にstaff情報を詰め直すためだけに使ってる。。。
  logout: actionCreator<void>('logout'),
  clearCurrentProgress: actionCreator<{ gotoHome: boolean } | void>('clearCurrentProgress'),
};

// actions
const ac = actionObjectCreatorFactory();
const actionTitle = 'Staff';
const actions = {
  login: ac<IStaff>(`[${actionTitle}] login`),
  initializeStaff: ac(`[${actionTitle}] initializeStaff`),
};

// asyncAction
const asyncAc = thunkActionObjectCreatorFactory();
export const staffActions = {
  login: asyncAc<ILoginReq, any>(`[${actionTitle}] login`, async (payload, dispatch) => {
    try {
      // api call
      const param = {
        tempoCode: payload.tempoCode,
        staffCode: payload.staffCode,
        staffPassword: payload.staffPassword,
      };
      const { staff, token }: { staff: IStaff; token: string } = await loginStaff(param).then(res =>
        toStaff(payload, res),
      );

      actions.login.dispatcher(dispatch)(staff);
      setToken(token);

      Sentry.configureScope(scope => {
        const { staffCode, staffType, tempoCode } = staff;
        scope.setUser({
          staffCode,
          staffType,
          tempoCode,
          loggedInAt: new Date(),
        });
      });

      // ページ遷移
      history.push(resolvePath(ERouterPath.home));
    } catch (ex) {
      if (ex instanceof ApiError) {
        return dispatch(errorHandlerAction.apiError({ error: ex, options: { contents: 'ログインに失敗しました.' } }));
      }
    }
  }),
};

// reducer
export interface IStaffState {
  loggedStaff: IStaff;
  loggedIn: boolean;
}

const clearedState: IStaffState = {
  loggedStaff: {
    staffCode: '',
    staffName: '',
    staffType: '',
    managerFlag: false,
    tempoCode: '',
    tempoName: '',
    tempoId: '',
    couponId: '',
    masterInfo: [],
    categoryInfo: [],
  },
  loggedIn: false,
};

const initialState: IStaffState = {
  loggedStaff: {
    tempoCode: '',
    staffCode: '',
    managerFlag: false,
    tempoName: '',
    staffName: '',
    staffType: '',
    tempoId: '',
    couponId: '',
    masterInfo: [],
    categoryInfo: [],
  },
  loggedIn: false,
};

export const reducer = reducerWithInitialState(initialState)
  .case(loginActions.login, (state, payload) => ({ ...state, loggedStaff: payload, loggedIn: true }))
  .case(actions.login._action, (state, payload) => ({ ...state, loggedStaff: payload, loggedIn: true }))
  .case(actions.initializeStaff._action, state => ({ ...clearedState }));
