import React from 'react';

import styles from './SizeAdjustSection.module.scss';

import PartsTab from '../../atoms/PartsTab/PartsTab';
import PartsSizeAdjustContent from '../PartsSizeAdjustContent/PartsSizeAdjustContent';

import { IPartsTabItem, IMeasurementItem, IAdjustOptionItem } from '../../../types/new-store/components';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall/';

import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';

import { IGauge } from '../../../store/_type/lookups';
import { IItem } from '../../../store/_type/order';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { infoDialogActions } from '../../../store/utils/dialog/info';

interface IProps {
  item: IItem;
  // partsTab
  selectingParts: TPartsNumber;
  partsTabs: IPartsTabItem[];
  onChangeParts: typeof SizeCorrectionPageEvents.onChangeParts;
  // PartsSizeAdjustContent
  sizeHistoryDate: string;
  sizeHistoryGauge: string;
  selectingPartsGauge: IGauge;
  selectablePartsGauges: IGauge[];
  measurements: IMeasurementItem[];
  adjustOptions: IAdjustOptionItem[];
  specialAdjustOptions: IAdjustOptionItem[];
  memberscardNumber: string;
  onChangeGauge: typeof SizeCorrectionPageEvents.onChangeGauge;
  onChangeSizeMeasurement: typeof SizeCorrectionPageEvents.onChangeSizeMeasurement;
  onChangeAdjustOption: typeof SizeCorrectionPageEvents.onChangeAdjustOption;
  onCopyHistorySize: typeof SizeCorrectionPageEvents.onCopyHistorySize;
  onOpenPurchaseHistoryDialog: typeof SizeCorrectionPageEvents.onOpenPurchaseHistoryDialog;
  showInfoDialog: typeof infoDialogActions.show.type;
}

type TProps = IProps & ICustomKeyboardInjectedProps;

const SizeAdjustSection: React.FC<TProps> = (props: TProps) => {
  return (
    <div id={styles.container}>
      <div className={styles.top}>
        <div className={styles.tab}>
          <PartsTab {...props} />
        </div>
        <div className={styles.button}>{/* <Button styles={['newwindow']}>サイズチャート</Button> */}</div>
      </div>
      <div className={styles.container}>
        <PartsSizeAdjustContent {...props} />
      </div>
    </div>
  );
};

export default SizeAdjustSection;
