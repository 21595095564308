import { TRequestConfig, TRequestParams } from '../';
import MOCK_UP_JSON from '../../mockups/jsonServer/mockup.json';
// import config from '../../configuration/config';

export { CustomerRes } from './customer.class';

export const customerRequestConfig: TRequestConfig = {
  endpoint: '/customer/{memberscard_number}',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    return {
      ok: true,
      body: getCustomer(),
    };
  },
  params2request: (params): TRequestParams => {
    return {
      body: '',
      pathParams: {
        memberscard_number: params ? params.memberscardNumber : '',
      },
    };
  },
};

function getCustomer(): string {
  return JSON.stringify(MOCK_UP_JSON.customer);
}
