import { createSelector } from 'reselect';

import { orderState } from '../../order/selector/order';
import { AppState } from '../..';
import { ILookupFeature } from '../../lookups';

export { availableOptionActions } from './action-reducer';

const featureSelector = (state: AppState): ILookupFeature => state.lookups;

const availableOptionState = createSelector(featureSelector, state => state.availableOption);

export const getAvailableOption = {
  state: createSelector(availableOptionState, state => state),
  all: createSelector(availableOptionState, state => state.availableOptions),
  current: createSelector(
    availableOptionState,
    orderState,
    (state, order) => state.availableOptions[order.currentOrderNumber],
  ),
};
