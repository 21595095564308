import { TPartsNumber, TOrderItemCode } from '../../../lookups/master-thisisforreplaceall';
import { IComposition } from '../order';

/**
 * 生地商品一覧取得のマスタ
 * APIで受け取ったデータを格納して、マスタとして利用する
 * 現在はとりあえずAPIのintefaceをコピーしただけ
 */
export interface IClothProduct {
  brandCode: string;
  itemCode: string;
  clothSeasonCode: string;
  productSeasonCode: string;
  personalorderProductNumber: string;
  personalorderColorCode: string;
  seasonCode: string;
  seasonName: string;
  personalorderColorName: string;
  retailPrice: string;
  retailPriceTaxin: string;
  partsModelPatterns: IPartsModelPattern[];
  design: string;
  designName: string;
  clothName: string;
  clothCode: string;
  vendorClothNumber: string;
  mixings: {
    front: IComposition[];
    back: IComposition[];
  };
  isnew: string;
  issoldout: string;
  makerName2: string;
  functionName: string;
  clothBrandCode: string;
}

export interface IPartsModelPattern {
  partsNumber: TPartsNumber;
  modelPatterns: IModelPattern[];
}

export interface IModelPattern {
  modelPattern: string;
  models: IModel[];
}

export interface IModel {
  modelCode: string;
  modelName: string;
  useScale: number;
}

/**
 * 利用可能オプション情報一覧のマスタ
 * APIで受け取ったデータを格納して、マスタとして利用する
 * 現在はとりあえずAPIのintefaceをコピーしただけ
 */
// export interface IAvailableOption {
//   partsNumber: TPartsNumber;
//   partsName: string;
//   optionNumber: string;
//   optionName: string;
//   specialOptionFlag: string;
//   required: string;
//   takeoverability: boolean;
//   optionPattern: string;
//   optionPatternName: string;
//   optionClassNumber: string;
//   optionClassName: string;
//   defaultFlag: string;
//   retailPrice: number;
//   retailPriceTaxin: number;
//   imagePath: string;
// }

//
export interface IAvailableOption {
  partsNumber: TPartsNumber;
  partsName: string;
  optionPatterns: IOptionPattern[];
}

export interface IOptionPattern {
  optionNumber: string;
  optionName: string;
  isSpecial: boolean;
  isRequired: boolean;
  optionClasses: IOptionClass[];
  // ・・・
  isTakeoverability: boolean;
  optionPattern: string;
  optionPatternName: string;
  autoSettingClassNumber?: string;
}

export type TOptionType = 'normal' | 'adjust' | 'nude' | 'measureLing' | 'free';

export interface IOptionClass {
  optionClassNumber: string;
  optionClassName: string;
  isDefault: boolean;
  retailPrice: number;
  retailPriceTaxin: number;
  imagePath: string;
}

export interface IAvailableOptionWithPartsIndexAndSelectedInfo extends IAvailableOption {
  partsIndex: string;
  optionPatterns: IOptionPatternWithSelectedInfo[];
  isSpare: boolean;
  modelCode: string;
  optionPatternCode: string;
  modelPatternCode: string;
}

export interface IOptionPatternWithSelectedInfo extends IOptionPattern {
  hasSelecting: boolean;
  selectingClassNumber: string;
  selectingClassName: string;
  isFreeInput: boolean;
  isFollowToJacketOption: boolean;
  isDesignOption: boolean;
}

export interface IOptionPatternWithSelectedInfoAndModel extends IOptionPatternWithSelectedInfo {
  isModel: boolean;
}

export const MODEL_SELECTOR_OPTION_NUMBER = 'MODEL_SELECTOR_OPTION_NUMBER';

/**
 * 採寸項目リスト取得のマスタ
 * APIで受け取ったデータを格納して、マスタとして利用する
 * 現在はとりあえずAPIのintefaceをコピーしただけ
 */
export interface ISizeMeasurement {
  partsNumber: TPartsNumber;
  measurementItems: IMeasurementItem[];
}

export interface IMeasurementItem {
  measurementNumber: string;
  measurementName: string;
  adjustmentUpperLimit: number;
  adjustmentLowerLimit: number;
  measurementPitch: number;
}

/** 採寸項目リスト取得のマスタ用のView */
export interface ISizeMeasurementView extends ISizeMeasurement {
  partsIndexes: string[];
  gauge: IGauge;
  historyDate: string;
  historyGauge: IGauge;
  measurementItems: ISizeMeasurementItemView[];
}

/** 採寸項目リスト取得のマスタ用のViewの中身 */
export interface ISizeMeasurementItemView extends IMeasurementItem {
  value: string;
  historyValue: string;
  standardValue: string;
  // 以下3つコンポーネントに渡すときに計算できるから不要かも。。。
  max?: number;
  min?: number;
  diff: string;
}

/**
 * 推奨ゲージ取得のマスタ
 * APIで受け取ったデータを格納して、マスタとして利用する
 * 現在はとりあえずAPIのintefaceをコピーしただけ
 */
export interface IRecommendPartsGauge {
  partsNumber: TPartsNumber;
  recommendedGauge: IGauge;
  allGauges: IGauge[];
}

export interface IGauge {
  major: string;
  minor: string;
}

export interface IPartsRecommendGauge {
  partsNumber: TPartsNumber;
  gauge: IGauge;
}

/**
 * 設定可能補正サイズリスト情報取得のマスタ
 * APIで受け取ったデータを格納して、マスタとして利用する
 * 現在はとりあえずAPIのintefaceをコピーしただけ
 */
export interface IPartsAdjustOption {
  partsNumber: TPartsNumber;
  adjustOptions: IAdjustOption[];
}

export interface IAdjustOption {
  optionNumber: string;
  optionName: string;
  isSpecialOption: boolean;
  optionClasses: TOptionClass[];
}

export type TOptionClass = Pick<IOptionClass, 'optionClassName' | 'optionClassNumber'>;

/** 設定可能補正サイズリスト情報取得のマスタ用のView */
export interface IAdjustOptionView extends IPartsAdjustOption {
  partsIndexes: string[];
  historyDate: string;
  adjustOptions: IAdjustOptionItemView[];
}

/** 設定可能補正サイズリスト情報取得のマスタ用のViewの中身 */
export interface IAdjustOptionItemView extends IAdjustOption {
  // 選択用
  selectedClassNumber: string;
  selectedClassName: string;
  // 履歴表示用
  historyClassNumber: string;
  historyClassName: string;
}

interface ISizeBaseParam {
  brandCode: string;
}

export type TSizeMeasurementPathParam = ISizeBaseParam & {
  modelPatterns: string[];
};

export type TGetSizeMeasurementsParam = ISizeBaseParam & {
  modelPatterns: string[];
  itemCode: TOrderItemCode;
  seasonCode: string;
};

export type TAdjustOptionPathParam = ISizeBaseParam & {
  itemCode: TOrderItemCode;
  seasonCode: string;
  partsNumber: TPartsNumber;
  optionPattern: string;
};

export interface IRecommendedGaugeBodyParam {
  parts: Array<{ partsNumber: TPartsNumber; modelCode: string; modelPattern: string }>;
  nudeSizes: Array<{ measurementNumber: number; measurementValue: number }>;
}

export type TRecommendedGaugePathParam = ISizeBaseParam;

export type TRecommendedGaugeParam = {
  path: TRecommendedGaugePathParam;
  body: IRecommendedGaugeBodyParam;
};

export type TSizeStanderdPathParam = ISizeBaseParam & {
  partsNumber: TPartsNumber;
  modelCode: string;
  gauge: IGauge;
  partModelCode: string;
};

export interface IOptionBlouseClass {
  optionGroupName: string;
  optionGroupId: string;
  optionClassNumbers: string[];
}

/**
 * partsNumberとmodelPatternがペアとなったobject
 */
export type TPartsModelPattern = {
  partsNumber: TPartsNumber;
  modelPattern: string;
};
