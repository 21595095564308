import jsonata from 'jsonata';
import { TRequestInfo, TResponseInfo } from '../';
import Logger from '../../helpers/common/logger';

export class ApiResContainer {
  constructor(protected params: any, protected req: TRequestInfo, protected res: TResponseInfo) {}

  public queryParams(): any {
    const res = this.params;
    return res;
  }

  public queryAll(expression: string): any {
    const params = this.params;
    const req = this.req;
    const res = jsonata(expression).evaluate(this.res.json);
    const response = { params, req, res };
    return response;
  }

  public query(expression: string, isRequired: boolean = false): any {
    const res = jsonata(expression).evaluate(this.res.json);
    if (res === undefined && isRequired) {
      Logger.log(expression, [this.res.json, this.req, this.res]);
    }
    return res;
  }
}
