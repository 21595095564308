import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('[Order/item]');

export type TReadDeliveryDateParam = {
  orderNumber: string;
  priviousDate: string;
};

export const actions = {
  loadShortestDeliveryDate: actionCreator<{ orderNumber?: string }>('loadShortestDeliveryDate'),
  loadShortestDeliveryDateAllOrders: actionCreator<void>('loadShortestDeliveryDateAllOrders'),
  loadStockFlag: actionCreator<{ orderNumber: string }>('loadStockFlag'),
  updateItemCode: actionCreator<{ orderNumber: string; brandCode?: string }>('updateItemCode'),
  readShortestDeliveryDate: actionCreator.async<TReadDeliveryDateParam, { shortestDeliveryDate: string }, {}>(
    'loadShortestDeliveryDate',
  ),
  // TODO: あとで対応
  readStockFlag: actionCreator.async<{ orderNumber: string }, { stockFlag: string }, {}>('loadStockFlag'),
};
