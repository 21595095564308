import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, filter, map } from 'rxjs/operators';
//
import { AppState } from '../..';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { ApiError } from '../../../models/error/api-error';
//
import { availableOptionAsyncActions, ISetting as IOptionSetting, availableOptionActions } from './action-reducer';
import { IClothProduct, IAvailableOption } from '../../_type/lookups';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { clothProductsSelector } from '../object-selector';
import { toRequestParam, toAviliableOptions } from '../../pages/design-selection/helper';
import { getAvailableOption } from '../../../services/items/available-option';
import { clothProductAsyncActions } from '../product/action-reducer';
import { IndexedObject } from '../../../types';
import { isOrderDetailOrderNumber } from '../../../helpers/item-thisisforreplaceall';

import { actions } from '../../order/design/actions';
import { IItem, IPiece } from '../../_type/order';
import { getItemCodeWithDetailConditions } from '../../../helpers/orders/order-items';
import { SystemError } from '../../../models/error/system-error';
import { TOrderItemCode } from '../../../lookups/master-thisisforreplaceall';
import { getBaseItemInfo } from '../../../helpers/available-option';
import { IOrderDetailState } from '../../order-detail/action-reducers';
import { by } from '../../../helpers';
import { IInformationDialog } from '../../../types/dialog';
import { infoDialogActions } from '../../utils/dialog/info';
import partial from 'ramda/es/partial';

const loadDataForOrderDetail: Epic<
  AnyAction,
  | Action<Parameters<typeof availableOptionAsyncActions.loadData.done>[0]>
  | Action<{ error: ApiError; options: any }>
  | Action<IInformationDialog>
  | Action<IOptionSetting>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(clothProductAsyncActions.loadDetailData.done),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const orderDetail = appStateObj.orderDetail();
      const products = appStateObj.allProducts();
      const isOrderDetail = isOrderDetailOrderNumber(payload.params.orderNumber) && !appStateObj.isEditOrder();
      return { payload, orderDetail, products, isOrderDetail };
    }),
    filter(({ isOrderDetail }) => isOrderDetail),
    filter(
      ({ payload, orderDetail, products }) =>
        orderDetail !== undefined &&
        orderDetail.orders[payload.params.orderNumber] !== undefined &&
        products !== undefined &&
        products[payload.params.orderNumber] !== undefined,
    ),
    mergeMap(async obj => {
      const { orderNumber } = obj.payload.params;
      const { params } = obj.payload;
      const orderDetail = obj.orderDetail as IOrderDetailState;
      const { item } = orderDetail.orders[orderNumber];
      const products = (obj.products as IndexedObject<IClothProduct[]>)[orderNumber];

      const { itemCode, categoryCode, pieces, subCategoryCode } = item;
      const { brandCode, clothModelCode, seasonCode } = item.cloth;

      const cs = clothProductsSelector({ itemCode, brandCode, products, subCategoryCode });
      const optionPatterns = cs.getForSendToModelPatterns(categoryCode, pieces, clothModelCode);
      const reqParam = toRequestParam({ orderNumber, brandCode, itemCode, seasonCode, optionPatterns });
      const res = await getAvailableOption(reqParam)
        .then(partial(toAviliableOptions, [brandCode, optionPatterns]))
        .catch(err => err);
      return { orderNumber, res, item, products, params };
    }),
    mergeMap(({ orderNumber, res, item, products, params }) => {
      if (res instanceof ApiError) {
        const match = params.orderDetail.dummyLookups.find(by('orderNumber')(orderNumber));
        // memo: APIエラーかつ注文詳細から復元できないとき
        if (!match) {
          return [
            ErrorHandlerActions.apiError({
              error: res,
              options: { contents: '利用可能オプション情報一覧取得に失敗しました。' },
            }),
          ];
        }
        const data = {
          hasOpen: true,
          title: '注意',
          contents: '注文情報詳細取得よりデータを復元しました.',
        };
        return [
          infoDialogActions.show._action(data),
          availableOptionActions.set._action({ orderNumber, availableOptions: match.availableOptions }),
          availableOptionAsyncActions.loadData.done({ params: { orderNumber, item, products }, result: {} }),
        ];
      }
      const availableOptions = res as IAvailableOption[];
      return [
        availableOptionActions.set._action({ orderNumber, availableOptions }),
        availableOptionAsyncActions.loadData.done({ params: { orderNumber, item, products }, result: {} }),
      ];
    }),
  );

const loadData: Epic<AnyAction, Action<any>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(availableOptionAsyncActions.loadData.started),
    map(({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const { orderNumber, pieces, forWhat } = payload;
      const item = payload.item ? payload.item : appStateObj.item();
      const products = payload.products ? payload.products : appStateObj.products();
      const stateItemCode = appStateObj.item()?.itemCode;
      return { payload, orderNumber, item, products, pieces, forWhat, stateItemCode };
    }),
    filter(obj => obj.item !== undefined && obj.products !== undefined),
    mergeMap(async ({ payload, orderNumber, item, products, pieces, forWhat, stateItemCode }) => {
      const { categoryCode, subCategoryCode, seasonCode, brandCode, clothModelCode, designParts } = getBaseItemInfo(
        item as IItem,
      );

      const itemCode =
        forWhat === 'initialize'
          ? stateItemCode
          : getItemCodeWithDetailConditions({
              category: categoryCode,
              subCategory: subCategoryCode,
              pieces: pieces as IPiece[],
              brand: brandCode,
              modelCode: clothModelCode,
              designParts,
            });

      if (!itemCode) {
        // TODO: ダイアログが正しい気がする。。。
        throw new SystemError('0006');
      }

      const clothSelector = clothProductsSelector({
        itemCode: itemCode as TOrderItemCode,
        brandCode,
        products: products as IClothProduct[],
        subCategoryCode,
      });
      const optionPatterns = clothSelector.getForSendToModelPatterns(
        categoryCode,
        pieces || (item as IItem).pieces,
        clothModelCode,
      );
      const reqParam = toRequestParam({ orderNumber, brandCode, itemCode, seasonCode, optionPatterns });
      const res = await getAvailableOption(reqParam)
        .then(partial(toAviliableOptions, [brandCode, optionPatterns]))
        .catch(err => err);
      return { payload, res, itemCode };
    }),
    mergeMap(({ payload, res, itemCode }) => {
      const { orderNumber, item, products, pieces, isDouble, forWhat } = payload;
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiErrorRetryAction({
            error: res,
            options: { contents: '利用可能オプション情報一覧取得に失敗しました。' },
            action: availableOptionAsyncActions.loadData.started(payload),
          }),
        ];
      }

      const returnActions: Array<Action<any>> = [
        availableOptionActions.set._action({
          orderNumber: payload.orderNumber,
          availableOptions: res as IAvailableOption[],
        }),
      ];

      // forWhatによってdispatchするactionを変更する。
      if (forWhat === 'initialize') {
        returnActions.push(
          actions.loadInitialize({
            params: {
              orderNumber,
              item: { ...(item as IItem), ...{ itemCode } },
              products: products as IClothProduct[],
            },
            availableOptions: res as IAvailableOption[],
          }),
        );
      } else if (forWhat === 'changeItemCode') {
        returnActions.push(
          actions.changeItemCode({
            params: {
              orderNumber,
              item: item as IItem,
              products: products as IClothProduct[],
              pieces: pieces || (item as IItem).pieces,
              isDouble: isDouble as boolean,
              itemCode,
            },
            availableOptions: res as IAvailableOption[],
          }),
        );
      }

      return returnActions;
    }),
  );

export const AvailableOptionEpics = combineEpics(loadDataForOrderDetail, loadData);
