import { TPartsNumber, EParts } from './master-thisisforreplaceall';
import { EEmbroideryType } from '../types/option';
import { II18nItem } from '../i18n/_new/types';
import { langResource } from '../i18n/_new/resources';

// 正しいメッセージはそのうち。。。
export const EMBROIDERY_VALIDATION_MESSAGES: Array<{
  partsNumber: TPartsNumber;
  EmbroideryType: EEmbroideryType;
  textObj: II18nItem;
}> = [
  { partsNumber: EParts.jaket, EmbroideryType: EEmbroideryType.NONE, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.jaket, EmbroideryType: EEmbroideryType.ROMA, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.jaket, EmbroideryType: EEmbroideryType.KANJI, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.jaket, EmbroideryType: EEmbroideryType.GERMAN, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.jaket, EmbroideryType: EEmbroideryType.BLOCK, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.shirt, EmbroideryType: EEmbroideryType.NONE, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.shirt, EmbroideryType: EEmbroideryType.ROMA, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.shirt, EmbroideryType: EEmbroideryType.HANA, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.shirt, EmbroideryType: EEmbroideryType.BLOCK, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.shirt, EmbroideryType: EEmbroideryType.SCRIPT, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.coat, EmbroideryType: EEmbroideryType.NONE, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.coat, EmbroideryType: EEmbroideryType.ROMA, textObj: langResource.validation.invalidation },
  { partsNumber: EParts.coat, EmbroideryType: EEmbroideryType.KANJI, textObj: langResource.validation.invalidation },
];

export const CUSTOMER_NAME_KANJI_MAX_LENGTH = 20;
export const CUSTOMER_NAME_KANA_MAX_LENGTH = 20;
