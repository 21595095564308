import React from 'react';

import styles from './DesignOptionListItemPaper.module.scss';

import I18Text from '../../../containers/I18Text/I18Text';
import PriceTextPlane from '../PriceTextSpan/PriceTextSpan';

import { IOptionPaper } from '../../../types/option';
import { langResource } from '../../../i18n/_new/resources';

interface IProps {
  paper: IOptionPaper;
  onSelect: (optionClassNumber: string, isFromSelectBox: boolean) => void;
}

const DesignOptionListItemPaper: React.FC<IProps> = (props: IProps) => {
  const { classNumber, className, priceTaxIn, hasSelecting, isDisable, isRequired } = props.paper;

  const onHandleSelect = () => {
    if (isDisable) {
      return;
    }
    props.onSelect(classNumber, false);
  };

  const getPaperClassName = () => {
    const base = styles.paper;
    const classes = [base];
    if (isDisable) {
      classes.push(styles.paperDisable);
    }
    if (hasSelecting) {
      classes.push(styles.paperSelected);
    }
    if (isRequired) {
      classes.push(styles.paperRequierd);
    }
    return classes.join(' ');
  };

  return (
    <React.Fragment>
      <dl className={getPaperClassName()} onClick={onHandleSelect}>
        <dt>{className}</dt>
        <dd>
          <PriceTextPlane price={priceTaxIn} prefix="¥" />
          <span className={styles.tax}>
            <I18Text textObj={langResource.components.priceText.tax} />
          </span>
        </dd>
      </dl>
    </React.Fragment>
  );
};

export default DesignOptionListItemPaper;
