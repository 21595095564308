import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../store';

import OrderConfirmationPage from '../../components/pages/OrderConfirmationPage/OrderConfirmationPage';

import { get as getCustomer } from '../../store/customer';
import { getLanguage } from '../../store/utils/language';
import { getStaff } from '../../store/staff';
import { mapCommonPageDispatchToProps } from './CommonPage';
import { confirmationPageActions } from '../../store/pages/confirmation';
import { getConfirmation } from '../../store/order/selector/confirmation';
import { get as getOrder } from '../../store/order';
import { getPointPage } from '../../store/payment/selector';
import { getAddress } from '../../store/order/selector/address';
import { scProjectSelector } from '../../store/sc-project';

function mapStateToProps(state: AppState) {
  return {
    staff: getStaff.staff(state),
    productKind: getOrder.productKind(state),
    pricePrefix: getLanguage.pricePrefix(state),
    memberscardNumber: getCustomer.memberscardNumber(state),
    newOrderNumber: getConfirmation.newOrderNumber(state),
    orderConfirmations: getConfirmation.confirmationsForOrderPage(state),
    paymentConfirmation: getConfirmation.paymentConfirmation(state),
    isOnlyDummyData: getPointPage.isOnlyDummyData(state),
    isFilledItem: getOrder.hasFilledItem(state),
    isValidAllOrders: getConfirmation.isValidAllOrders(state),
    isAddressPageCompleted: getAddress.hasCompleted(state),
    scProject: scProjectSelector.selectedProject(state),
    isManager: getStaff.isManager(state),
    canOrdersDuringOrderStopTerm: getConfirmation.canOrdersDuringOrderStopTerm(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    onEditOrder: confirmationPageActions.editOrder.dispatcher(dispatch),
    onCopyOrder: confirmationPageActions.copyOrder.dispatcher(dispatch),
    onDeleteOrder: confirmationPageActions.deleteOrder.dispatcher(dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationPage);
