import { ISizeMeasurementItemView, IOptionClass, IOptionPattern, IAvailableOption } from '../store/_type/lookups';
import { IOption, IPartsDesign } from '../store/_type/order';
import { TScProject } from '../types/project';

const sizeMeasurementItemView: ISizeMeasurementItemView = {
  value: '',
  standardValue: '',
  measurementNumber: '',
  measurementName: '',
  adjustmentUpperLimit: 0,
  adjustmentLowerLimit: 0,
  measurementPitch: 0,
  historyValue: '',
  diff: '',
};

const option: IOption = {
  optionNumber: '',
  optionClassNumber: '',
};

const availableOption: IAvailableOption = {
  partsNumber: '01',
  partsName: '',
  optionPatterns: [],
};

const optionPattern: IOptionPattern = {
  optionName: '',
  optionNumber: '',
  optionClasses: [],
  isSpecial: false,
  isRequired: false,
  isTakeoverability: false,
  optionPattern: '',
  optionPatternName: '',
};

const optionClass: IOptionClass = {
  optionClassNumber: '',
  optionClassName: '',
  isDefault: false,
  retailPrice: 0,
  retailPriceTaxin: 0,
  imagePath: '',
};

const partsDesign: IPartsDesign = {
  modelPattern: '',
  modelCode: '',
  optionPattern: '',
  options: [],
};

const scProject: TScProject = {
  corporationId: '',
  salesEventId: '',
  companyName: '',
  projectName: '',
  contactName: '',
  salesRecordingDate: '',
};

export const INITIAL_OBJECT = {
  sizeMeasurementItemView,
  option,
  partsDesign,
  availableOption,
  optionPattern,
  optionClass,
  scProject,
};
