import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { IClothProduct } from '../../_type/lookups';
import { cloneDeep } from 'lodash';
import { TCategory } from '../../../lookups/master-thisisforreplaceall';
import actionCreatorFactory from 'typescript-fsa';
import { IItem } from '../../_type/order';
import { TOrderDetail } from '../../../helpers/api/orders/conv-state-reverse';

// actions
const ac = actionObjectCreatorFactory();
const actionTitle = 'Cloth Product';

export interface ISetting {
  orderNumber: string;
  products: IClothProduct[];
}

export interface IGetClothProductsParams {
  orderNumber: string;
  codes?: {
    clothCode: string;
    seasonCode: string;
    categoryCode: TCategory;
  };
  item?: IItem;
}

export const clothProductActions = {
  set: ac<ISetting>(`[${actionTitle}] load`),
  delete: ac<string>(`[${actionTitle}] delete`),
  copy: ac<{ newOrderNumber: string; orderNumber: string }>(`[${actionTitle}] copy`),
};

const asyncActionCreator = actionCreatorFactory(`[${actionTitle}]`);

export const clothProductAsyncActions = {
  loadData: asyncActionCreator.async<IGetClothProductsParams, {}, {}>('loadData'),
  loadDetailData: asyncActionCreator.async<{ orderNumber: string; orderDetail: TOrderDetail }, {}, {}>(
    'loadDetailData',
  ),
};

// reducer
export interface IProductState {
  products: {
    [orderNumber: string]: IClothProduct[];
  };
}

const initialState: IProductState = {
  products: {},
};

export const reducer = reducerWithInitialState(initialState)
  .case(clothProductActions.set._action, (state, payload) => {
    const newState = { ...state.products };
    newState[payload.orderNumber] = payload.products;
    return {
      ...state,
      products: { ...newState },
    };
  })
  .case(clothProductActions.delete._action, (state, payload) => {
    const copied = { ...state.products };
    delete copied[payload];
    return {
      ...state,
      products: copied,
    };
  })
  .case(clothProductActions.copy._action, (state, payload) => {
    const { newOrderNumber, orderNumber } = payload;
    const target = state.products[orderNumber];
    if (!target) {
      return { ...state };
    }

    const copied = cloneDeep(target);
    return {
      ...state,
      products: {
        ...state.products,
        [newOrderNumber]: copied,
      },
    };
  });
