/**
 * 生地選択画面で発火するイベント（action）一覧
 */

import actionCreatorFactory from 'typescript-fsa';
import { IOnChangeClothValue, IOnChangeClothComposition } from '../../store/_type/order';

const actionCreator = actionCreatorFactory('- ClothSelectionPage ');

export const ClothSelectionPageEvents = {
  /**
   * 生地選択画面で値の変更が行われた時（単一の値を持つ要素）
   */
  onChange: actionCreator<IOnChangeClothValue>('onChangeOtherItem'),

  /**
   * Compositionが変更された
   */
  onChangeComposition: actionCreator<IOnChangeClothComposition>('onChangeComposition'),
};
