import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { actions, customerAction } from './action-reducer';
import { mergeMap } from 'rxjs/operators';
import { getCustomer } from '../../services/customer/customer';
import { toCustomer } from '../../helpers/api/converter/customer';
import { ApiError } from '../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../store/errorHandling/action';
import { ICustomer } from '../_type/customer';
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { TLoadInitializeParam } from '../pages/address';
import { getInitialOrderNumber } from '../../helpers/orders';
import { actions as AddressActions } from '../order/address/actions';

const customerLogin: Epic<
  AnyAction,
  Action<{ error: ApiError; options: any } | ICustomer | TLoadInitializeParam>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(customerAction.login.started),
    mergeMap(async ({ payload }) => {
      const { memberscardNumber, isLoadAddress, onClose } = payload;
      const appStateObj = appStateSelector(state.value);
      const orders = appStateObj.orders();

      const res = await getCustomer({ memberscardNumber })
        .then(toCustomer)
        .catch(err => err);
      return { res, isLoadAddress, onClose, orders };
    }),
    mergeMap(({ res, isLoadAddress, onClose, orders }) => {
      // ログイン処理がダイアログから始まる場合にそのダイアログを閉じるため
      if (onClose) {
        onClose();
      }
      if (res instanceof ApiError) {
        return [ErrorHandlerActions.apiError({ error: res, options: { contents: 'ログインに失敗しました' } })];
      }

      const loadAddressAction = [];
      if (isLoadAddress && orders) {
        // 先頭のオーダーに設定する
        const orderNumber = getInitialOrderNumber(orders);
        loadAddressAction.push(AddressActions.loadAddress({ orderNumber, customer: res as ICustomer }));
      }
      return [actions.loadSuccess._action(res as ICustomer), ...loadAddressAction];
    }),
  );

export const CustomerEpics = combineEpics(customerLogin);
