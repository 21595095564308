import { createSelector } from 'reselect';

import { AppState } from '../index';
import { IOrderDigestsState } from './action-reducer';
import { getOrderDetail } from '../order-detail';
import { IOrderDigests } from '../../types/orders/digest';

export { actions as orderDigestActions, reducer as orderDigestReducer } from './action-reducer';

const featureSelector = (state: AppState): IOrderDigestsState => state.orderDigest;

export const getOrderDigests = {
  filter: createSelector(featureSelector, state => state.filterParams),
  digests: createSelector(featureSelector, state => (Array.isArray(state.orderDigest) ? state.orderDigest : [])),
  isLoading: createSelector(featureSelector, state => state.isLoading),
  selectedOrderDigest: createSelector(featureSelector, getOrderDetail.serialNumber, (state, serialNumber) => {
    const digests = Array.isArray(state.orderDigest) ? state.orderDigest : [];
    const selectedDigests = digests.find(digest => digest.serialYear + digest.serialNumber === serialNumber);
    return selectedDigests ? selectedDigests : ({ orderStatus: 'beforeEvans', hasCancel: false } as IOrderDigests);
  }),
};

export const getSelectedOrderDigest = {
  orderStatus: createSelector(getOrderDigests.selectedOrderDigest, state => state.orderStatus),
  hasCancel: createSelector(getOrderDigests.selectedOrderDigest, state => state.hasCancel),
};
