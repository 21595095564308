import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../../store';

import DesignSelectionPage from '../../../components/pages/DesignSelectionPage/DesignSelectionPage';

// store
import { getCurrentItem } from '../../../store/order/selector/item';
import { currentDesign } from '../../../store/order/selector/design';
import { getProduct, getAvailableOption } from '../../../store/lookups';
import { mapCommonPageDispatchToProps, mapCommonPageStateToProps } from '../CommonPage';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';
import { getErrorTextsForRequiredCheckDialog, requiredCheckCompleted } from '../../../store/order/selector/cloth';
import { optionGroupSelector, optionGroupAction } from '../../../store/utils/optionGroup';

function mapStateToProps(state: AppState) {
  return {
    // item
    item: getCurrentItem.detail(state),
    // cloth
    products: getProduct.current.products(state),
    // design
    availableOptions: getAvailableOption.current(state),
    // contents表示用
    sidebarListItem: currentDesign.sidebarListItem(state),
    optionContent: currentDesign.contentItem(state),
    optionContentHeader: currentDesign.contentHeaderItem(state),
    selectingOptionPatternCode: currentDesign.selectingOptionPattern(state),
    hasCompleted: currentDesign.hasCompleted(state),
    errorTextForRequiredCheckDialog: getErrorTextsForRequiredCheckDialog(state),
    // 裏地・要尺　必須チェック用
    requiredCheckCompleted: requiredCheckCompleted(state),
    selectedOptionGroup: optionGroupSelector.selectedOptionGroup(state),
    ...mapCommonPageStateToProps(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
    ...bindActionCreators(DesignSelectionPageEvents, dispatch),
    ...bindActionCreators(optionGroupAction, dispatch),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DesignSelectionPage);
