import { SizeMeasurementRes } from '../../../api/items/size-measurement';
import { ISizeMeasurement, IMeasurementItem } from '../../../store/_type/lookups';
import { OEM_ADJUST_DATA, TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { OPS_BRANDS } from '../../../lookups/item-thisisforreplaceall';

/**
 * 採寸項目リスト取得APIからのレスポンス値を返却する
 */
export const toSizeMeasurement = (res: SizeMeasurementRes): ISizeMeasurement[] => {
  const key = 'measurement';
  const measurements = res.query(key);
  const params = res.queryParams();

  // OPS_BRANDSの場合、optionPatternをpartsNumber間で共有する場合があるため、取り除く処理を入れる
  if (OPS_BRANDS.includes(params.path.brandCode)) {
    const optionPatternArray = params.path.modelPatterns ?? [];

    const newMeasurements = res.queryAll(key);
    for (let i = 0; i < measurements.length - 1; i++) {
      if (
        measurements[i].optionPattern === optionPatternArray[i] &&
        measurements[i].partsNumber === measurements[i + 1].partsNumber
      ) {
        newMeasurements.res.splice(i + 1, 1);
      }
      if (measurements[i].optionPattern !== optionPatternArray[i]) {
        newMeasurements.res.splice(i, 1);
        i--;
      }
    }
  }
  const data = Array.from({ length: measurements.length }).map((v, i) => toMesurement(res, i, key));
  return mergeMeasurementItems(data);
};

function mergeMeasurementItem(returnItems: ISizeMeasurement[], measurement: ISizeMeasurement): ISizeMeasurement[] {
  return returnItems.map(item => {
    if (item.partsNumber === measurement.partsNumber) {
      return {
        partsNumber: item.partsNumber,
        measurementItems: [...item.measurementItems, ...measurement.measurementItems],
      };
    }
    return item;
  });
}
/**
 * 同じpartsNumberを持つmeasurementItemsを1つにまとめる関数
 * @param measurements
 */
function mergeMeasurementItems(measurements: ISizeMeasurement[]): ISizeMeasurement[] {
  return measurements.reduce((returnItems, measurement) => {
    if (returnItems.some(item => item.partsNumber === measurement.partsNumber)) {
      // returnItemsに同じpartsNumberを持つオブジェクトがあった場合、mergeMeasurementItemを実行
      return mergeMeasurementItem(returnItems, measurement);
    }
    return [...returnItems, measurement];
  }, [] as ISizeMeasurement[]);
}

function toMesurement(res: SizeMeasurementRes, index: number, parentExpression: string): ISizeMeasurement {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const items = getValue<any>('measurementItems');
  const childrenExpression = getExpression('measurementItems');
  const measurementItems = Array.from({ length: items.length }).map((v, i) =>
    toMesurementItem(res, i, childrenExpression),
  );
  const measurement: ISizeMeasurement = {
    partsNumber: getValue<TPartsNumber>('partsNumber'),
    measurementItems,
  };
  return measurement;
}

function toMesurementItem(res: SizeMeasurementRes, index: number, parentExpression: string): IMeasurementItem {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const item: IMeasurementItem = {
    measurementNumber: String(getValue<string>('measurementNumber')),
    measurementName: getValue<string>('measurementName'),
    adjustmentUpperLimit:
      OEM_ADJUST_DATA.find(x => x.code === String(getValue<string>('measurementNumber')))?.adjustment_upper_limit ?? 0,
    adjustmentLowerLimit:
      OEM_ADJUST_DATA.find(x => x.code === String(getValue<string>('measurementNumber')))?.adjustment_lower_limit ?? 0,
    measurementPitch:
      OEM_ADJUST_DATA.find(x => x.code === String(getValue<string>('measurementNumber')))?.measurement_pitch ?? 0,
  };
  return item;
}
