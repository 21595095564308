import { TRequestConfig, TRequestParams } from '../..';

export { DestinationRes } from './destination.class';

export const destinationResRequestConfig: TRequestConfig = {
  endpoint: '/customer/destination',
  method: 'GET',
  useMock: false,
  mockFunc: params => {
    // const pathParam = params ? params.pathParam : '';
    return {
      ok: true,
      body: JSON.stringify('{}'),
    };
  },
  params2request: (params): TRequestParams => {
    const destinationCode = params ? params.query.destinationCode : undefined;
    return {
      query: {
        destinationCode,
      },
    };
  },
};
