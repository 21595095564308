import { createSelector } from 'reselect';
import { orderState } from './order';
import { get as getCustomer } from '../../customer';
import { getLanguage } from '../../utils/language';
import { TAddressItem } from '../../../types/new-store/components';
import { toOrderTitle } from '../../../helpers/conv-selector';
import { DELIVERY_METHOD_LOOKUPS, DELIVERY_TIMEZONE_LOOKUPS } from '../../../lookups/shipping';
import { isValidMemberscardNumber } from '../helper/validate';
import { getConsecutiveDaysFromOneDay, toDate, toDateFormatString, toDeliveryDate } from '../../../helpers/common/date';
import { toLookup } from '../../../helpers/master-lookup';
import { isAddressInputCompleted } from '../../../helpers/item-thisisforreplaceall';
import { getItemCodeAndName } from '../../../helpers/view';
import Logger from '../../../helpers/common/logger';

export const getAddress = {
  orderNumberOrderOne: createSelector(orderState, state => {
    const { orders } = state;
    const numbers = Object.keys(orders).map(orderNumber => orderNumber);
    return numbers.length > 0 ? numbers[0] : '';
  }),
  isLoadInitialize: createSelector(orderState, getCustomer.customer, (state, customer) => {
    if (!isValidMemberscardNumber(customer.memberscardNumber)) {
      return false;
    }

    const { orders } = state;
    // FIXME: 仮の実装です。
    const results = Object.keys(orders).map((orderNumber, index) => {
      const { shipping } = orders[orderNumber];
      const { shippingPostalCode, isSameOrderOne } = shipping;
      if (index === 0) {
        return shippingPostalCode.length > 1;
      }
      return isSameOrderOne ? true : shippingPostalCode.length > 1;
    });

    if (results.length < 1) {
      return false;
    }
    // 郵便番号が埋まってない場合
    return results.some(v => v === false);
  }),
  addressItems: createSelector(orderState, getLanguage.langCode, (state, langCode) => {
    const { orders } = state;
    const data: TAddressItem[] = Object.keys(orders).map((orderNumber, index) => {
      const { shipping, shortestDeliveryDate, item } = orders[orderNumber];
      const deliveryDate = shortestDeliveryDate;
      const deliveryDateMaster = deliveryDate
        ? getConsecutiveDaysFromOneDay(toDate(deliveryDate), 0).map(date => ({
            code: toDateFormatString(date),
            value: toDeliveryDate(date, toDate(deliveryDate)),
          }))
        : []; // MEMO: 郵便番号変更時に一時的にblankになるので
      const deliveryDateLookups = toLookup(deliveryDateMaster);

      const { itemCode, categoryCode } = item;
      const { clothCode, brandCode, clothSeasonCode } = item.cloth;
      return {
        title: toOrderTitle(index, Object.keys(orders).length, langCode),
        postalCodes: shipping.shippingPostalCode.split('-'),
        mailAddresses: shipping.customerMailAddress.split('@'),
        methodLookups: [...DELIVERY_METHOD_LOOKUPS],
        deliveryDateLookups,
        timeZoneLookups: [...DELIVERY_TIMEZONE_LOOKUPS],
        orderNumber,
        shipping,
        clothSeasonCode,
        clothCode,
        brandCode,
        itemCodeAndName: getItemCodeAndName(itemCode, categoryCode),
      };
    });
    return data;
  }),
  hasCompleted: createSelector(orderState, state => {
    const { orders } = state;
    try {
      return isAddressInputCompleted(orders);
    } catch (error) {
      Logger.log('isAddressInputCompleted', error);
      return false;
    }
  }),
};
