import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './HomePage.module.scss';

import HomeImageGallery from '../../organisms/HomeImageGallery/HomeImageGallery';
import OrderClassification from '../../molecules/OrderClassification/OrderClassification';
import Footer from '../../organisms/Footer/Footer';

import { getGalleryData } from '../../../lookups/home-gallery';
import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { TPageContainerProps } from '../../../containers/pages/HomePage';
import { langResource } from '../../../i18n/_new/resources';

type TProps = TPageContainerProps & RouteComponentProps;

export class HomePage extends Component<TProps> {
  render() {
    const { props } = this;
    const { showSelectionDialog, onCleate, couponId } = props;
    return (
      props.categoryInfo[0].categoryName && (
        <React.Fragment>
          <div className={styles.header}>
            <OrderClassification
              productKind={props.productKind}
              onChange={props.onChangeProductKind}
              onShowCouponInfo={props.showCouponInfoDialog}
              couponId={couponId}
            />
          </div>

          <div className={styles.gallery}>
            <HomeImageGallery
              history={props.history}
              showSelectionDialog={showSelectionDialog}
              onCleate={onCleate}
              galleries={getGalleryData(props.categoryInfo)}
              isManager={props.isManager}
              showInfoDialog={props.showInfoDialog}
              categoryInfo={props.categoryInfo}
            />
          </div>
          {this.utility(props)}
        </React.Fragment>
      )
    );
  }

  getFooterButtons(props: TProps): IFooterButton[] {
    return [
      {
        type: 'confirm',
        textObj: langResource.footer.confirm,
        isDisabled: !props.hasConfirmItems,
        path: ERouterPath.itemConfirmation,
      },
    ];
  }

  utility = (props: TProps) => {
    return (
      <React.Fragment>
        <Footer buttons={this.getFooterButtons(props)} />
      </React.Fragment>
    );
  };
}

export default withRouter(HomePage);
