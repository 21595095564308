/**
 * selected-value-getter：選択したものを取得する共通関数を置く
 */
import curry from 'ramda/es/curry';
import Logger from '../common/logger';
import { IDesignParts, IOption, IPiece } from '../../store/_type/order';
import { IAvailableOption, IOptionPattern } from '../../store/_type/lookups';
import { availableOptionsSelector } from '../../store/lookups/object-selector';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';
import { by } from '..';

import partial from 'ramda/es/partial';
import pipe from 'ramda/es/pipe';
import path from 'ramda/es/path';

export const getOptions = (partsIndex: string, designParts: IDesignParts): IOption[] => {
  const partsDesing = designParts[partsIndex];
  return partsDesing ? partsDesing.options : [];
};

export const getSelectedOption = (
  designParts: IDesignParts,
  partsIndex: string,
  optionNumber: string,
): IOption | undefined => getOptions(partsIndex, designParts).find(by('optionNumber')(optionNumber));

/**
 * 全パーツからmodelPatternを抽出する
 */
export const getModelPatterns = (designParts: IDesignParts): string[] => {
  return Object.entries(designParts).reduce((pre, [_partsIndex, partsDesign]) => {
    const modelPattern: string = path(['modelPattern'], partsDesign) || '';
    return modelPattern ? [...pre, modelPattern] : pre;
  }, [] as string[]);
};

/**
 * 選択済みのoptionsが新しいalairableOptionsに存在すれば、値を設定して返却
 */
export const getSelectedAndSelectableOptions = (
  availableOptions: IAvailableOption[],
  pieces: IPiece[],
  designParts: IDesignParts,
  newDesignParts: IDesignParts,
): IDesignParts => {
  const aoSelector = availableOptionsSelector(availableOptions);
  return pieces.reduce((pre, { partsNumber, index: partsIndex }) => {
    try {
      const selectedOptions = getOptions(partsIndex, designParts);
      const targetDesignParts = newDesignParts[partsIndex];
      if (targetDesignParts) {
        const filteredSelectedOptions = aoSelector.filteredSelectedOptions(partsNumber, selectedOptions) || [];
        const setAutoOptions = partial(setAutoSettingOptionToIOptions, [
          getOptionPatterns(availableOptions, partsNumber),
        ]);
        const convertOptions = pipe(setAutoOptions);
        targetDesignParts.options = convertOptions(filteredSelectedOptions);
      }
      return { ...pre, [partsIndex]: targetDesignParts };
    } catch {
      Logger.log('No matchedParts.');
      return { ...pre };
    }
  }, {} as IDesignParts);
};

/**
 * IAvailableOption[]からTPartsNumberで抽出したIOptionPattern[]を返却する
 */
export const getOptionPatterns = (availableOptions: IAvailableOption[], partsNumber: TPartsNumber): IOptionPattern[] =>
  (availableOptions.find(by('partsNumber')(partsNumber)) || { optionPatterns: [] }).optionPatterns;

/**
 * IOptionPattern.autoSettingClassNumberがundefinedでない、かつ、selectedOptionsの中にIOptionPattern.optionNumberが含まれないとき、Trueを返却する
 */
export const hasAutoOptionNoIncludedSelectedOptions = (
  selectedOptions: IOption[],
  optionPattern: IOptionPattern,
): boolean =>
  !!optionPattern.autoSettingClassNumber &&
  !selectedOptions.some(({ optionNumber }) => optionPattern.optionNumber === optionNumber);

/**
 * 引数のIOption[]に対して、自動設定するIOption[]を追加して返却する
 */
export const setAutoSettingOptionToIOptions = (
  optionPatterns: IOptionPattern[],
  selectedOptions: IOption[],
): IOption[] => {
  const isAutoSettingOptionPattern = curry(hasAutoOptionNoIncludedSelectedOptions)(selectedOptions);
  const autoSettingOptions = optionPatterns
    .filter(isAutoSettingOptionPattern)
    .map(({ optionNumber, autoSettingClassNumber }) => ({
      optionNumber,
      optionClassNumber: autoSettingClassNumber || '',
    }));
  return [...selectedOptions, ...autoSettingOptions];
};
