import { ISizeMeasurement, ISizeMeasurementView, IMeasurementItem } from '../../_type/lookups';
import { IPiece, IPartsSize, IOption } from '../../_type/order';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { cloneDeep } from 'lodash';
import { roundDegit } from '../../../helpers';

export const sizeMeasurementsSelector = (state: ISizeMeasurement[]) => new SizeMeasurementsSelector(state);

class SizeMeasurementsSelector {
  constructor(private state: ISizeMeasurement[]) {}

  public filteredSelectedMeasurements(partsNumber: TPartsNumber, measurements: IOption[]): IOption[] {
    const { measurementItems } = this.state.find(v => v.partsNumber === partsNumber) || {
      measurementItems: [] as IMeasurementItem[],
    };
    return measurements.filter(v => {
      const matchedOption = measurementItems.find(vv => vv.measurementNumber === v.optionNumber);
      return !matchedOption ? false : true;
    });
  }

  public toView(
    pieces: IPiece[],
    partsSizes: {
      [partsNumber: string]: IPartsSize;
    },
  ): ISizeMeasurementView[] {
    return Object.entries(partsSizes)
      .map(obj => {
        const partsNumber = obj[0] as TPartsNumber;
        const partsSize = obj[1];
        return { partsNumber, partsSize };
      })
      .map(obj => {
        const { partsNumber, partsSize } = obj;
        const { measurements, gauge, standardSizes } = partsSize;
        const { gauge: historyGauge, measurements: historyMeasurements, date: historyDate } = partsSize.history;
        const partsIndexes = pieces.filter(v => v.partsNumber === partsNumber).map(v => v.index);
        const matched = this.matchedSizeMeasurement(partsNumber);
        // itemViewの作成
        const measurementItems = matched.map(item => {
          const { measurementNumber } = item;
          const value = this.getValue(measurements, measurementNumber, '0');
          const historyValue = this.getValue(historyMeasurements, measurementNumber);
          const standardValue = this.getValue(standardSizes, measurementNumber);
          return {
            ...item,
            value,
            historyValue,
            standardValue,
            diff: this.diffStandard(value, standardValue),
            max: this.getMaxMin(standardValue, item.adjustmentUpperLimit),
            min: this.getMaxMin(standardValue, item.adjustmentLowerLimit),
            pitch: item.measurementPitch !== 0 ? item.measurementPitch : undefined,
          };
        });
        return {
          partsNumber,
          partsIndexes,
          gauge,
          historyDate,
          historyGauge,
          measurementItems,
        };
      });
  }

  private matchedSizeMeasurement(partsNumber: TPartsNumber) {
    if (!Array.isArray(this.state)) {
      return [];
    }
    const matched = this.state.find(v => v.partsNumber === partsNumber);
    if (!matched) {
      return [];
    }
    return cloneDeep(matched).measurementItems;
  }

  private getValue(measurements: IOption[], measurementNumber: string, defaultValue: string = '-'): string {
    const matcted = measurements.find(v => v.optionNumber === measurementNumber);
    return matcted ? (matcted.optionClassName ? matcted.optionClassName : defaultValue) : defaultValue;
  }

  private getMaxMin(standardValue: string, limit: number) {
    if (standardValue === '-') {
      return undefined;
    }
    return roundDegit(+standardValue + limit);
  }

  private diffStandard(value: string, standardValue: string): string {
    // 小数点3桁目を丸めて1桁にする
    return standardValue === '-' ? '-' : String(roundDegit(+value - +standardValue));
  }
}
