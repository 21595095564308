import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';

import { mergeMap, map, filter } from 'rxjs/operators';
import {
  recommendedGaugeAsyncActions,
  ISetting as IRecommendedGaugeSetting,
  recommendedGaugeActions,
} from './action-reducer';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IItem, IOrder } from '../../_type/order';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { ApiError } from '../../../models/error/api-error';
import { IAvailableOption, IGauge, IRecommendPartsGauge } from '../../_type/lookups';
import { postRecommendSize } from '../../../services/items';
import { toRecommendPartsGauges } from '../../../helpers/api/converter/recommended-gauge';
import { getRecommendedGaugeParam, isSameGauge } from '../../../helpers/size-correction';
import { by } from '../../../helpers';
import { IInformationDialog } from '../../../types/dialog';
import { infoDialogActions } from '../../utils/dialog/info';
import { orderActions } from '../../order';
import { NestedPartial } from '../../../types';
import { getPartialOrder } from '../../order/helper/conv-partial-order';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';

const loadData: Epic<
  AnyAction,
  | Action<void>
  | Action<{ error: ApiError; options: any }>
  | Action<IRecommendedGaugeSetting>
  | Action<Parameters<typeof recommendedGaugeAsyncActions.loadData.done>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(recommendedGaugeAsyncActions.loadData.started),
    mergeMap(async ({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = payload.item ? payload.item : (appStateObj.item() as IItem);
      const availableOptions = appStateObj.avaliableOptions(payload.orderNumber) as IAvailableOption[];
      const params = getRecommendedGaugeParam(item, availableOptions);
      const res = await postRecommendSize(params.path, params.body)
        .then(toRecommendPartsGauges)
        .catch(err => err);
      return { payload, res, params };
    }),
    mergeMap(({ payload, res, params }) => {
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: '推奨ゲージの取得に失敗しました' },
          }),
        ];
      }
      const recommendedGauges = res as IRecommendPartsGauge[];
      return [
        recommendedGaugeActions.set._action({
          recommendedGauges,
          orderNumber: payload.orderNumber,
          requestParam: params,
        }),
        recommendedGaugeAsyncActions.loadData.done({ params: payload, result: {} }),
      ];
    }),
  );

// 商品内容確認画面に遷移をしたタイミングで正しいゲージが選択されているかチェックする。
const validateSelectedGauge: Epic<
  AnyAction,
  Action<void> | Action<{ error: ApiError; options: any }> | Action<IInformationDialog> | Action<NestedPartial<IOrder>>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(recommendedGaugeAsyncActions.validateSelectedGauge),
    map(() => {
      const appStateObj = appStateSelector(state.value);
      const item = appStateObj.item();
      const availableOptions = appStateObj.avaliableOptions();
      return { item, availableOptions };
    }),
    filter(v => v.item !== undefined && v.availableOptions !== undefined),
    mergeMap(async v => {
      const item = v.item as IItem;
      const availableOptions = v.availableOptions as IAvailableOption[];
      const params = getRecommendedGaugeParam(item, availableOptions);
      const res = await postRecommendSize(params.path, params.body)
        .then(toRecommendPartsGauges)
        .catch(err => err);
      return { res, item };
    }),
    mergeMap(({ res, item }) => {
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: '推奨ゲージの取得に失敗しました' },
          }),
        ];
      }

      const recommendedGauges = res as IRecommendPartsGauge[];

      const invalidGaugePartsNumbers = Object.entries(item.size.parts)
        .filter(v => {
          const [partsNumber, partsSize] = v;
          // partsに合致するゲージを取得する
          const { allGauges } = recommendedGauges.find(by('partsNumber')(partsNumber)) || {
            allGauges: [] as IGauge[],
          };
          // APIレスポンスのゲージと選択されているゲージが1つも一致しない場合はtrueを返す。
          return !allGauges.some(gauge => isSameGauge(gauge, partsSize.gauge));
        })
        .map(v => v[0]);

      // マスターサイズを選び直すよう促すダイアログを出し、次のページに進めないようにする
      // (正しくないゲージを選択をしているパーツのゲージの情報を削除）
      if (invalidGaugePartsNumbers.length >= 1) {
        const dialogContents = {
          hasOpen: true,
          title: '入力チェックエラー',
          contents: 'マスターサイズを選び直してください.',
        };

        const updateData = getPartialOrder.fromValidateGauge(
          item.size.parts,
          invalidGaugePartsNumbers as TPartsNumber[],
        );

        return [infoDialogActions.show._action(dialogContents), orderActions.updateCurrentOrder._action(updateData)];
      }
      return [];
    }),
  );

export const RecommenedGaugeEpics = combineEpics(loadData, validateSelectedGauge);
