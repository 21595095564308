import { createSelector } from 'reselect';
import { orderState } from './order';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { INudeDimensionItem, IRecommendedGaugeItem, IPartsTabItem } from '../../../types/new-store/components';
import { joinGauge } from '../../../helpers/size-correction';
import { getRecommendedGauge, getSizeMeasurement, getAdjustOption } from '../../lookups';
import { INITIAL_BASE_GAUGE } from '../initial-state';
import { IOrderState } from '../';
import { orderSelector, orderStateSelector } from '../object-selector';
import { getValue } from '../../../helpers/master-lookup';
import { MASTETR_NUDE_DEMENTION, MASTER_PARTS } from '../../../lookups/master-thisisforreplaceall';
import { sizeMeasurementsSelector } from '../../lookups/object-selector/size-measurement';
import { sizeMeasurementsViewSelector } from '../../lookups/object-selector/size-measurement-view';
import { adjustOptionsSelector, adjustOptionsViewSelector } from '../../lookups/object-selector';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { ISizeMeasurement, ISizeMeasurementView } from '../../_type/lookups';
import { IOrder } from '../../_type/order';
import { isSizeInputCompleted, isClothInputCompleted } from '../../../helpers/item-thisisforreplaceall';
import { isDesignPageInputCompleted } from './design';

const getItem = (state: IOrderState) => orderSelector(orderStateSelector(state).currentOrder()).item();

export const getSize = {
  detail: createSelector(orderState, state => {
    const { size } = getItem(state);
    return size;
  }),
  nude: createSelector(orderState, state => {
    const { nude } = getItem(state).size;
    return nude;
  }),
  nudeDate: createSelector(orderState, state => {
    const { nude } = getItem(state).size;
    return nude.date;
  }),
  nudeDimensions: createSelector(orderState, state => {
    const { measurements } = getItem(state).size.nude;
    return measurements.map(measurement => {
      const { optionNumber } = measurement;
      const item: INudeDimensionItem = {
        measurement,
        name: getValue(optionNumber, MASTETR_NUDE_DEMENTION) || '',
      };
      return item;
    });
  }),
  recommendedGauges: createSelector(
    orderState,
    getRecommendedGauge.current.recommendGauges,
    (state, recommendGauges) => {
      const { parts } = getItem(state).size;
      const partsNumbers = Object.keys(parts);
      const gauges = partsNumbers.map(partsNumber => {
        const partsName = getValue(partsNumber as TPartsNumber, MASTER_PARTS) || '';
        const { gauge } = parts[partsNumber];
        const matched = recommendGauges.find(v => v.partsNumber === partsNumber);
        const recommendedGauge = matched ? joinGauge(matched.gauge) : '-';
        const item: IRecommendedGaugeItem = {
          partsName,
          recommendedGauge,
          selectingGauge: joinGauge(gauge),
        };
        return item;
      });
      return gauges;
    },
  ),
  selectingParts: createSelector(orderState, state => {
    const { partsNumber } = getItem(state).size.selecting;
    return partsNumber;
  }),
  partsTabs: createSelector(orderState, state => {
    const { parts } = getItem(state).size;
    return Object.keys(parts).map(key => {
      const partsNumber = key as TPartsNumber;
      const item: IPartsTabItem = {
        partsNumber,
        partsName: getValue(partsNumber, MASTER_PARTS) || '',
      };
      return item;
    });
  }),
  measurements: createSelector(orderState, getSizeMeasurement.current.detail, (state, measurements) => {
    const { size, pieces } = getItem(state);
    const { parts } = size;
    const { partsNumber } = size.selecting;
    if (!parts[partsNumber]) {
      return [];
    }
    const view = sizeMeasurementsSelector(measurements).toView(pieces, parts);
    return sizeMeasurementsViewSelector(view).getMeasurementItems(partsNumber);
  }),
  sizeHistoryDate: createSelector(orderState, state => {
    const { parts, selecting } = getItem(state).size;
    const { partsNumber } = selecting;
    if (!parts[partsNumber]) {
      return '';
    }
    return parts[partsNumber].history.date;
  }),
  sizeHistoryGauge: createSelector(orderState, state => {
    const { parts, selecting } = getItem(state).size;
    const { partsNumber } = selecting;
    if (!parts[partsNumber]) {
      return '';
    }
    return joinGauge(parts[partsNumber].history.gauge);
  }),
  selectingPartsGauge: createSelector(orderState, state => {
    const { parts, selecting } = getItem(state).size;
    const { partsNumber } = selecting;
    if (!parts[partsNumber]) {
      return { ...INITIAL_BASE_GAUGE };
    }
    const { gauge } = parts[partsNumber];
    return gauge;
  }),
  adjustOptions: createSelector(orderState, getAdjustOption.current, (order, partsAdjustOptions) => {
    const { size, pieces } = getItem(order);
    const { parts } = size;
    const { partsNumber } = size.selecting;
    if (!parts[partsNumber]) {
      return [];
    }
    const view = adjustOptionsSelector(partsAdjustOptions).toView(pieces, parts);
    return adjustOptionsViewSelector(view)
      .getAdjustOptionItems(partsNumber)
      .filter(v => !v.isSpecialOption);
  }),
  specialAdjustOptions: createSelector(orderState, getAdjustOption.current, (order, partsAdjustOptions) => {
    const { size, pieces } = getItem(order);
    const { parts } = size;
    const { partsNumber } = size.selecting;
    if (!parts[partsNumber]) {
      return [];
    }
    const view = adjustOptionsSelector(partsAdjustOptions).toView(pieces, parts);
    return adjustOptionsViewSelector(view)
      .getAdjustOptionItems(partsNumber)
      .filter(v => v.isSpecialOption);
  }),
  hasCompleted: createSelector(appStateSelector, state => {
    const order = state.order();
    return (
      !!order &&
      isClothInputCompleted(order) &&
      isDesignPageInputCompleted(order, state.avaliableOptions()) &&
      isSizePageInputCompleted(order, state.sizeMeasurements())
    );
  }),
};

// MEMO: 切り離した
export const isSizePageInputCompleted = (order?: IOrder, sizeMeasurements?: ISizeMeasurement[]) => {
  if (!order || !sizeMeasurements) {
    return false;
  }
  const sizeView = sizeMeasurementsViewSelector(
    sizeMeasurementsSelector(sizeMeasurements).toView(order.item.pieces, order.item.size.parts),
  );
  // 重複を削除したpartsNumberを取得
  const partsNumbers = order.item.pieces.map(piece => piece.partsNumber).filter((x, i, self) => self.indexOf(x) === i);
  const sizeMeasurementsViews = partsNumbers.map(partsNumber =>
    sizeView.getFromPartsNumber(partsNumber as TPartsNumber),
  ) as ISizeMeasurementView[];

  if (sizeMeasurementsViews.some(view => view === undefined)) {
    return false;
  }
  return isSizeInputCompleted(sizeMeasurementsViews, order.item);
};
