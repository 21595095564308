import React from 'react';
import { Route } from 'react-router-dom';

import { ERouterPath } from '../types';
import { resolvePath } from '../helpers/common/path';

import ClothSelectionPageContainer from '../containers/pages/ClothSelectionPage';
import DesignSelectionPageContainer from '../containers/pages/DesignSelectionPage';
import SizeCorrectionPageContainer from '../containers/pages/SizeCorrectionPage';

const ItemSubRouter: React.FC = () => {
  return (
    <React.Fragment>
      <Route path={resolvePath(ERouterPath.clothSelection)} component={ClothSelectionPageContainer} />
      <Route path={resolvePath(ERouterPath.designSelection)} component={DesignSelectionPageContainer} />
      <Route path={resolvePath(ERouterPath.sizeCorrection)} component={SizeCorrectionPageContainer} />
    </React.Fragment>
  );
};

export default ItemSubRouter;
