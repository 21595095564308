import { Location } from 'history';
import config from '../../configuration/config';
import { ERouterPath, IndexedObject } from '../../types/index';

export function resolvePath(path: ERouterPath) {
  if (config.baseUrl === '') {
    return `/${path}`;
  }

  if (path === ERouterPath.login) {
    return `${config.baseUrl}`;
  }

  return `${config.baseUrl}/${path}`;
}

export const resolvePaths = (paths: ERouterPath[]) => paths.map(resolvePath);

export function parseSearchParams(obj: IndexedObject): string {
  const usp = new URLSearchParams();
  Object.keys(obj).forEach(key => {
    usp.append(key, obj[key]);
  });
  return `?${usp.toString()}`;
}

export const getSerialNumber = (location: Location<any>): string =>
  new URLSearchParams(location.search).get('orderNumber') || '';

export const getOrderDetailPath = (serial: string) => {
  // TODO: queryの文字列はserialに変更したい
  const params = parseSearchParams({ orderNumber: serial });
  return resolvePath(ERouterPath.orderDetail) + params;
};

export const isOrderDetailPath = (pathname: string): boolean => resolvePath(ERouterPath.orderDetail) === pathname;
