// import { Dispatch, Action } from 'redux';
// import { ThunkAction } from 'redux-thunk';
// import { AppState } from '../index';
import { cloneDeep } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../lib/action-creator-factory';
//
import { IOrder, TPartialOrder, TProductKind, IDesignParts, IPiece } from '../_type/order';
import { getNewOrderNumber, getInitialOrderData } from './helper/_old';
import { mergeState } from '../../helpers/common/merge';
import { actionCreatorFactory } from 'typescript-fsa';
import { IOrderState } from './index';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';

// actions
const ac = actionObjectCreatorFactory();
const actionTitle = 'Order';

export const actions = {
  create: ac<void>(`[${actionTitle}] create`),
  updateOrder: ac<{ orderNumber: string; order: TPartialOrder }>(`[${actionTitle}] updateOrder`),
  updateCurrentOrder: ac<TPartialOrder>(`[${actionTitle}] updateCurrentOrder`),
  updateCurrentOrderDeleteParts: ac<TPartialOrder>(`[${actionTitle}] updateCurrentOrderDeleteParts`),
  delete: ac<string>(`[${actionTitle}] delete`),
  deleteCurrentOrder: ac<void>(`[${actionTitle}] deleteCurrentOrder`),
  copyOrder: ac<string>(`[${actionTitle}] copy`),
  updateCurrentOrderNumber: ac<string>(`[${actionTitle}] updateCurrentOrderNumber`),
  updateProductKind: ac<TProductKind>(`[${actionTitle}] updateProductKind`),
  updateShortestDeliveryDate: ac<{ shortestDeliveryDate: string; orderNumber: string }>(
    `[${actionTitle}] updateShortestDeliveryDate`,
  ),
  updateStockFlag: ac<{ orderNumber: string; stockFlag: string }>(`[${actionTitle}] updateStockFlag`),
  overWriteEditOrder: ac<IOrderState>('overWriteEditOrder'),
  setIsEdit: ac<boolean>('setIsEdit'),
};

// TODO: 名前はかり
const acf = actionCreatorFactory('[order]');
export const orderDeleteActions = {
  deleteOrder: acf<{ orderNumber: string }>('deleteOrder'),
  deleteOrders: acf('deleteOrders'),
  deleteEditOrders: acf('deleteEditOrders'),
};

// reducer
export const initialState: IOrderState = {
  currentOrderNumber: '',
  orders: {},
  productKind: 'E',
  isEdit: false,
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.create._action, state => {
    const now = new Date();
    const currentOrderNumber = getNewOrderNumber(state.orders);

    return {
      ...state,
      currentOrderNumber,
      isEdit: false,
      orders: {
        ...state.orders,
        [currentOrderNumber]: getInitialOrderData(now),
      },
    };
  })
  .case(actions.updateOrder._action, (state, payload) => {
    const currentItemState = { ...state.orders[payload.orderNumber] };
    const orders = { ...state.orders };
    orders[payload.orderNumber] = mergeState(currentItemState, payload.order) as IOrder;
    return {
      ...state,
      orders,
    };
  })
  .case(actions.updateCurrentOrder._action, (state, payload) => {
    const currentItemState = { ...state.orders[state.currentOrderNumber] };
    const orders = { ...state.orders };
    orders[state.currentOrderNumber] = mergeState(currentItemState, payload) as IOrder;
    return {
      ...state,
      orders,
    };
  })
  .case(actions.updateCurrentOrderDeleteParts._action, (state, payload) => {
    const currentItemState = { ...state.orders[state.currentOrderNumber] };
    const orders = { ...state.orders };
    orders[state.currentOrderNumber] = mergeState(currentItemState, payload) as IOrder;

    // payloadで削除したパーツもmergeされてしまうので、payloadで上書きする
    if (payload.item && payload.item.pieces && payload.item.design && payload.item.design.designParts) {
      orders[state.currentOrderNumber].item.design.designParts = payload.item.design.designParts as IDesignParts;
    }

    // sizeの中にpiecesにないパーツがあれば削除する
    if (payload.item && payload.item.pieces) {
      const partsNumbers = Object.keys(orders[state.currentOrderNumber].item.size.parts) as TPartsNumber[];
      const partsList = (payload.item.pieces as IPiece[]).map(v => v.partsNumber);
      partsNumbers.forEach(parts => {
        if (!partsList.includes(parts)) {
          delete orders[state.currentOrderNumber].item.size.parts[parts];
        }
      });
    }

    return {
      ...state,
      orders,
    };
  })
  .case(actions.delete._action, (state, payload) => {
    if (!state.orders[payload]) {
      return { ...state };
    }
    const copied = { ...state.orders };
    // これでイケるかな。。。
    delete copied[payload];

    const orderNumbers = Object.keys(copied).map(v => +v);
    return {
      ...state,
      isEdit: false,
      orders: copied,
      currentOrderNumber: orderNumbers.length > 0 ? String(orderNumbers[0]) : '',
    };
  })
  .case(actions.deleteCurrentOrder._action, (state, payload) => {
    const copied = { ...state.orders };
    // これでイケるかな。。。
    delete copied[state.currentOrderNumber];
    return {
      ...state,
      orders: copied,
    };
  })
  .case(actions.copyOrder._action, (state, payload) => {
    if (!state.orders[payload]) {
      return { ...state };
    }
    const currentOrderNumber = getNewOrderNumber(state.orders);
    const copied = cloneDeep(state.orders[payload]);
    return {
      ...state,
      currentOrderNumber,
      orders: {
        ...state.orders,
        [currentOrderNumber]: copied,
      },
    };
  })
  .case(actions.updateCurrentOrderNumber._action, (state, payload) => {
    return {
      ...state,
      currentOrderNumber: payload,
    };
  })
  .case(actions.updateProductKind._action, (state, payload) => {
    return {
      ...state,
      productKind: payload,
    };
  })
  .case(actions.updateShortestDeliveryDate._action, (state, payload) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        [payload.orderNumber]: {
          ...state.orders[payload.orderNumber],
          shortestDeliveryDate: payload.shortestDeliveryDate,
        },
      },
    };
  })
  .case(actions.overWriteEditOrder._action, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
  .case(actions.setIsEdit._action, (state, payload) => {
    return {
      ...state,
      isEdit: payload,
    };
  })
  .case(actions.updateStockFlag._action, (state, payload) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        [payload.orderNumber]: {
          ...state.orders[payload.orderNumber],
          item: {
            ...state.orders[payload.orderNumber].item,
            cloth: {
              ...state.orders[payload.orderNumber].item.cloth,
              hasStock: payload.stockFlag,
            },
          },
        },
      },
    };
  });
