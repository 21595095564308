import { connect } from 'react-redux';
import { AppState } from '../../store';

import I18Text from '../../components/atoms/I18Text/I18Text';
import { II18nItem } from '../../i18n/_new/types';
import { getLanguage } from '../../store/utils/language';

function mapStateToProps(state: AppState) {
  return {
    langCode: getLanguage.langCode(state),
  };
}

interface IProps {
  textKey?: string;
  textObj?: II18nItem;
  translateChildren?: II18nItem;
}

export type TI18nTextProps = ReturnType<typeof mapStateToProps> & IProps;

export default connect(mapStateToProps)(I18Text);
