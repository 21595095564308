import { ILocalState } from './state';
export const createNewKey = () => new Date().getTime().toString(36);

export const getDefaultState = (): ILocalState => ({
  progressKey: createNewKey(),
  progressName: '#EMPTY#',
  progressType: '01',
  progressList: [],
});

export const progressTypeCd = {
  AUTO: '01',
  MANUAL: '02',
  CANCEL: '99',
};

// export const getCurrentPath = (): string => window.location.pathname;
