import React from 'react';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Button from '../../atoms/Button/Button';
import { langResource } from '../../../i18n/_new/resources';
import styles from './IllustrationDialog.module.scss';

interface IProps {
  isOpened: boolean;
  imgPath: string;
  onClose: () => void;
}
export const IllustrationDialog: React.FC<IProps> = (props: IProps) => {
  const { isOpened, imgPath, onClose } = props;

  return (
    <Dialog open={isOpened} onClose={onClose} maxWidth={false}>
      <DialogContent>
        <img src={imgPath} className={styles.image} alt="" />
      </DialogContent>
      <DialogActions>
        <Button textObj={langResource.dialog.closeButtonLabel} onClick={onClose} styles={['white']} />
      </DialogActions>
    </Dialog>
  );
};
