import { OrderDigestRes } from '../../../api/orders/digest';
import { IOrderDigests, IOrderDigestsSearch } from '../../../types/orders/digest';
import { toDateFormatString } from '../../common/date';
import { TOrderStatus, OrderStatus } from '../../../lookups/item-thisisforreplaceall/order-status';

export function toOrderDigests(res: OrderDigestRes): IOrderDigests[] {
  const all = res.query('orderDigests');
  if (all.length < 1) {
    return [];
  }
  const data = Array.from({ length: all.length }).map((v, i) => toOrderDigest(res, i));
  return data;
}

function toOrderDigest(res: OrderDigestRes, index: number): IOrderDigests {
  const getValue = <T = string>(v: string): T => {
    const expression = `orderDigests[${index}].${v}`;
    return res.query(expression) as T;
  };

  return {
    brandCode: getValue('brandCode'),
    hasCancel: getValue('cancelFlag') === '1',
    category: getValue('category'),
    customerId: getValue('customer').replace('/customer/', ''),
    companyCode: getValue('companyCode'),
    companyName: getValue('companyName'),
    item: {
      itemCode: getValue('item.itemCode'),
      itemName: getValue('item.itemName'),
    },
    memberscardNumber: getValue<string>('memberscardNumber'),
    name: getValue('name'),
    nameKana: getValue('nameKana'),
    noSettlementOrderFlag: getValue('noSettlementOrderFlag'),
    orderId: getValue('order').replace('/order/', '').replace('/orders/', ''),
    orderDate: getValue('orderDate'),
    productNumber: getValue('productNumber'),
    seasonCode: getValue('seasonCode'),
    serialNumber: getValue('serialNumber'),
    serialYear: getValue('serialYear'),
    settledFlag: getValue('settledFlag'),
    tempoCode: getValue('tempoCode'),
    tempoName: getValue('tempoName'),
    textile: getValue('textile'),
    memo: getValue('memo'),
    updateDate: getValue('updateDate'),
    staff: {
      code: getValue('staffCode'),
      name: getValue('staffName'),
    },
    orderKind: getValue('orderKind'),
    introducerName: getValue('introducerName'),
    distributionKind: getValue('distributionKind'),
    deliveryMethod: getValue('deliveryMethod'),
    deliveryDatetime: getValue('deliveryDatetime'),
    itemOrderPrice: getValue<number>('itemOrderPrice'),
    optionPrice: getValue<number>('optionPrice'),
    totalPrice: getValue<number>('totalPrice'),
    hasSettledSmartregi: getValue('settledFlag') === '1',
    orderStatus: toClientOrderStatus(getValue('orderStatus')),
    requestSerial: `${getValue('serialYear')}${getValue('serialNumber')}`,
  };
}

/** 検索条件をAPI側フォーマットに合わせる */
export function toQuery(req: IOrderDigestsSearch) {
  return {
    ...req,
    serial: req.serialNumber,
    orderDateFrom: toDateFormatString(req.orderDateFrom),
    orderDateTo: toDateFormatString(req.orderDateTo),
  };
}

export const toClientOrderStatus = (status: string = '00'): TOrderStatus => {
  if (+status === 15) {
    return OrderStatus.afterEvans;
  }

  if (+status >= 40) {
    return OrderStatus.afterShipping;
  }

  return OrderStatus.beforeEvans;
};
