import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { mergeMap, map, delay, filter } from 'rxjs/operators';
//
import { AppState } from '../../';
//
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { orderActions } from '..';
import { diversionOrderActions } from './actions';
import { clothProductAsyncActions } from '../../lookups/product/action-reducer';
//
import { NestedPartial, ERouterPath } from '../../../types';
import { IOrder, TPartialOrder } from '../../_type/order';
import { getDiversionOrderDetail } from '../../../services/orders/detail';
import { ApiError } from '../../../models/error/api-error';
import { TDiversionOrder } from '../../../helpers/api/orders/conv-state-reverse';
import history from '../../../helpers/common/history';
import { resolvePath } from '../../../helpers/common/path';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { ILoadCustomerParam, customerAction } from '../../customer/action-reducer';
import { loginActions } from '../../staff';

const loadDiversionOrder: Epic<
  AnyAction,
  Action<
    | { error: ApiError; options: any }
    | { gotoHome: boolean }
    | ILoadCustomerParam
    | void
    | Parameters<typeof diversionOrderActions.setOrder.started>[0]
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(diversionOrderActions.loadDiversionOrder.started),
    mergeMap(async action => {
      const { requestSerial } = action.payload;
      const res = await getDiversionOrderDetail(requestSerial).catch(err => err);
      return { res };
    }),
    mergeMap(({ res }) => {
      // error
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({ error: res, options: { contents: '条件に該当するデータがありません.' } }),
        ];
      }
      const diversionOrder: TDiversionOrder = res;
      // diversionOrder.customer.memberscardNumberが number型で定義されているが、入っているのはstring型になっている。
      // TODO (OEM): ログインAPIの会社アドレスを使用する
      const loginAction = true
        ? []
        : [
            customerAction.login.started({
              memberscardNumber: diversionOrder.customer.memberscardNumber.toString(),
              isLoadAddress: true,
            }),
          ];
      return [
        loginActions.clearCurrentProgress(),
        orderActions.create._action(),
        diversionOrderActions.setOrder.started(diversionOrder),
        ...loginAction,
      ];
    }),
  );

const setOrder: Epic<
  AnyAction,
  Action<NestedPartial<IOrder> | Parameters<typeof clothProductAsyncActions.loadData.started>[0]>,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(diversionOrderActions.setOrder.started),
    delay(400),
    map(({ payload }) => {
      const selector = appStateSelector(state.value);
      return {
        orderNumber: selector.orderNumber(),
        order: selector.order(),
        diversionOrder: payload,
      };
    }),
    filter(v => v.order !== undefined && v.orderNumber !== undefined),
    mergeMap(obj => {
      const { orderNumber } = obj;
      const order = obj.order as IOrder;
      // MEMO: 年季はそのまま使う
      const { seasonCode } = order.item.cloth;
      const { item, shipping } = obj.diversionOrder.order;
      const { categoryCode, subCategoryCode, itemCode, pieces, cloth } = item;
      const { clothCode } = cloth;
      const partialOrder: TPartialOrder = {
        item: {
          categoryCode,
          subCategoryCode,
          itemCode,
          pieces,
          cloth: {
            clothCode,
          },
          design: item.design,
          size: item.size,
        },
        shipping,
      };

      // 生地選択画面に遷移する
      history.push(resolvePath(ERouterPath.clothSelection));

      const params = { orderNumber, codes: { clothCode, seasonCode, categoryCode } };
      return [orderActions.updateCurrentOrder._action(partialOrder), clothProductAsyncActions.loadData.started(params)];
    }),
  );

export const DiversionOrderEpics = combineEpics(loadDiversionOrder, setOrder);
