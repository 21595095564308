import { OrderDetailRes } from '../../../api/orders/detail';
import { TPartsNumber, TOrderItemCode, TCategory } from '../../../lookups/master-thisisforreplaceall';
import { INVOICE_FLAG } from '../../../lookups/api/orders/new-order-request';
import { getStateMailAddressFromAPIRes } from '../../orders/customerEmailAdress';
import { toMixing as toMixingApi } from '../../../helpers/api/orders/conv-state-reverse';

import { by } from '../..';
import { ADDTIONAL_COPY_NUDE_SIZE_CONFIG, ORDER_CATALOG_ALL } from '../../../lookups/item-thisisforreplaceall';

const toOption = (res: OrderDetailRes, index: number, parentExpression: string) => {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  return {
    optionNumber: getValue('optionNumber') || '',
    optionName: getValue('optionName') || '',
    optionClassNumber: getValue('optionClassNumber'),
    optionClassName: getValue('optionClassName') || '',
    // optionMark: getValue('optionMark'),
  };
};

type TOption = ReturnType<typeof toOption>;

/**
 * ジャケットorパンツにもあるヌード採寸項目の場合は、採寸項目番号をジャケットorパンツのものに変換する。
 */
const convertNudeSizeOptionNumber = (option: TOption, partsNumber: TPartsNumber): TOption => {
  const convertedNudeSizeOptionNumberFrom = ADDTIONAL_COPY_NUDE_SIZE_CONFIG.filter(by('partsNumber')(partsNumber)).find(
    v => v.to === option.optionNumber,
  )?.from;
  return !convertedNudeSizeOptionNumberFrom
    ? option
    : {
        optionNumber: convertedNudeSizeOptionNumberFrom,
        optionName: option.optionName,
        optionClassNumber: option.optionClassNumber,
        optionClassName: option.optionClassName,
      };
};

const toParts = (res: OrderDetailRes, index: number, parentExpression: string) => {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const optionData = getValue<any>('options');
  const optionExp = getExpression('options');
  const partsNumber = getValue<TPartsNumber>('partsNumber');
  const options = Array.from({ length: optionData.length })
    .map((v, i) => toOption(res, i, optionExp))
    .map(v => convertNudeSizeOptionNumber(v, partsNumber));

  return {
    options,
    partsNumber,
    partsName: getValue('partsName'),
    modelCode: getValue('modelCode'),
    modelName: getValue('modelName'),
    modelPattern: getValue('modelPattern'),
    optionPattern: getValue('optionPattern'),
    sizeCode: getValue('sizeCode'),
  };
};

const toMixing = (res: OrderDetailRes, index: number, parentExpression: string) => {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  return {
    mixing: getValue('mixing'),
    mixingRatio: getValue<number>('mixingRatio'),
  };
};

const toMixings = (res: OrderDetailRes, parentExpression: string) => {
  const getExpression = (v: string): string => {
    return `${parentExpression}.${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const frontData = getValue<any>('front');
  const frontExp = getExpression('front');
  const front = Array.from({ length: frontData.length }).map((v, i) => toMixing(res, i, frontExp));

  const backData = getValue<any>('back');
  const backExp = getExpression('back');
  const back = Array.from({ length: backData.length }).map((v, i) => toMixing(res, i, backExp));

  return {
    front,
    back,
  };
};

const toItem = (res: OrderDetailRes, index: number, parentExpression: string) => {
  const getExpression = (v: string): string => {
    return `${parentExpression}[${index}].${v}`;
  };

  const getValue = <T = string>(v: string): T => {
    const expression = getExpression(v);
    return res.query(expression) as T;
  };

  const partsData = getValue<any>('parts');
  const partsExp = getExpression('parts');
  const parts = Array.from({ length: partsData.length }).map((v, i) => toParts(res, i, partsExp));

  // OEM では mixingsが返却されていない
  const frontMixing = toMixingApi(getValue<any>('compositionFront') ?? '');
  const backMixing = toMixingApi(getValue<any>('compositonBack') ?? '');
  const mixings = { front: frontMixing, back: backMixing };

  // OEM ではカテゴリーが返却されていない
  const searchCategory =
    ORDER_CATALOG_ALL.find(row => row.itemCode === getValue<TOrderItemCode>('item'))?.category ?? 'CS';

  return {
    parts,
    mixings,
    //
    currentCopyNumber: getValue('currentCopyNumber'),
    serialYear: getValue('serialYear'),
    serialNumber: getValue('serialNumber'),
    itemOrderPrice: getValue('itemOrderPrice'),
    optionPrice: getValue('optionPrice'),
    itemOrderAmount: getValue('itemOrderAmount'),
    itemPriceTax: getValue('itemPriceTax'),
    itemOrderAmountTaxin: getValue('itemOrderAmountTaxin'),
    retailPrice: getValue('retailPrice'),
    itemName: getValue('itemName'),
    item: getValue<TOrderItemCode>('item'),
    itemType: getValue('itemType'),
    brand: getValue('brand'),
    make: getValue('make'),
    clothBrandCode: getValue('clothBrandCode'),
    clothSeason: getValue('clothSeason'),
    clothCode: getValue('clothCode'),
    vendorClothNumber: getValue('vendorClothNumber'),
    productSeason: getValue('productSeason'),
    productNumber: getValue('productNumber'),
    requiredScale: getValue('requiredScale'),
    personalOrderColorCode: getValue('personalOrderColorCode'),
    personalOrderColorName: getValue('personalOrderColorName'),
    design: getValue('design'),
    deliveryKind: getValue('deliveryKind'),
    compositionFront: getValue('compositionFront') || '',
    compositionBack: getValue('compositionBack') || '',
    stockPlaceCode: getValue('stockPlaceCode'),
    stockPlaceName: getValue('stockPlaceName'),
    //
    pearlToneCode: getValue('pearlToneCode'),
    pearlToneName: getValue('pearlToneName'),
    modelPattern: getValue('modelPattern'),
    optionPattern: getValue('optionPattern'),
    departmentProductNumber: getValue('departmentProductNumber'),
    hurrying: getValue('hurrying'),
    modified: getValue('modified'),
    note: getValue('note'),
    category: searchCategory,
    textileNumber: getValue('textileNumber'),
  };
};

export const toOrder = (res: OrderDetailRes) => {
  const getValue = <T = string>(expression: string, isRequired: boolean = true): T => {
    return res.query(expression, isRequired) as T;
  };

  const itemData = getValue<any>('items');
  const items = Array.from({ length: itemData.length }).map((v, i) => toItem(res, i, 'items'));
  return {
    items,
    //
    companyCode: getValue('companyCode'),
    companyName: getValue('companyName'),
    branchCode: getValue('branchCode'),
    orderDate: getValue('orderDate'),
    lotNumber: getValue('lotNumber', false),
    contactNumber: getValue('contactNumber', false),
    shopName: getValue('shopName'),
    shopCode: getValue('shopCode'),
    staffName: getValue('staffName'),
    staffCode: getValue('staffCode'),
    cutterNameKana: getValue('cutterNameKana', false),
    deliveryDateShop: getValue('deliveryDateShop', false),
    deliveryDateArrival: getValue('deliveryDateArrival') || '',
    deliveryDateGuest: getValue('deliveryDateGuest') || '',
    timeZoneCode: getValue('timeZoneCode'),
    timeZoneName: getValue('timeZoneName'),
    paymentDate: getValue('paymentDate'),
    section1: getValue('section1', false),
    sectionCode1: getValue('sectionCode1', false),
    section2: getValue('section2', false),
    sectionCode2: getValue('sectionCode2', false),
    section3: getValue('section3', false),
    sectionCode3: getValue('sectionCode3', false),
    clientStaffName: getValue('clientStaffName', false),
    customerFamilyNameKana: getValue('customerFamilyNameKana'),
    customerGivenNameKana: getValue('customerGivenNameKana'),
    customerBirthday: getValue('customerBirthday', false) || '',
    customerFamilyNameKanji: getValue('customerFamilyNameKanji'),
    customerGivenNameKanji: getValue('customerGivenNameKanji'),
    memberscardNumber: getValue<string>('memberscardNumber'),
    distributionKind: getValue('distributionKind', false),
    deliveryMethod: getValue('deliveryMethod'),
    customerPostalCode: getValue('customerPostalCode', false),
    customerPhoneNumber: getValue('customerPhoneNumber', false),
    customerState: getValue('customerState', false),
    customerCity: getValue('customerCity', false),
    customerStreet: getValue('customerStreet', false),
    customerResidence: getValue('customerResidence', false),
    customerMailAddress: getStateMailAddressFromAPIRes(getValue('customerMailAddress')),
    shippingPostalCode: getValue('shippingPostalCode'),
    shippingPhoneNumber: getValue('shippingPhoneNumber'),
    shippingState: getValue('shippingState'),
    shippingCity: getValue('shippingCity'),
    shippingStreet: getValue('shippingStreet'),
    priceTagNumber: getValue('priceTagNumber', false),
    note: getValue('note', false),
    parentSerialYear: getValue('parentSerialYear'),
    parentSerialNumber: getValue('parentSerialNumber'),
    shippingCost: getValue<number>('shippingCost'),
    couponUsePoint: getValue<number>('couponUsePoint'),
    usePoint: getValue<number>('usePoint'),
    orderAddPoint: getValue('orderAddPoint', false),
    pricePayment: getValue('pricePayment'),
    priceNetTotal: getValue('priceNetTotal', false),
    priceTaxin: getValue('priceTaxin'),
    taxKind: getValue('taxKind'),
    taxRate: getValue('taxRate'),
    orderPaymentMethod: getValue('orderPaymentMethod', false),
    orderPaymentMethodName: getValue('orderPaymentMethodName', false),
    paymentStatusCode: getValue('paymentStatusCode', false),
    paymentStatusName: getValue('paymentStatusName', false),
    pbKindCode: getValue('pbKindCode', false),
    pbKindName: getValue('pbKindName', false),
    deliveryRate: getValue<number>('deliveryRate', false),
    cKind: getValue('cKind', false),
    productKind: getValue('productKind'),
    copyNumbers: getValue('copyNumbers'),
    relationSerialNumber: getValue('relationSerialNumber', false),
    introducerName: getValue('introducerName', false) || '',
    introducerCode: getValue('introducerCode', false) || '',
    favoriteCustomerName: getValue('favoriteCustomerName', false) || '',
    favoriteCustomerCode: getValue('favoriteCustomerCode', false) || '',
    invoiceFlag: getValue('invoiceFlag', false) === INVOICE_FLAG.need,
    orderCountryCode: getValue('orderCountryCode'),
    orderCountryName: getValue('orderCountryName'),
    shippingCountryCode: getValue('shippingCountryCode'),
    shippingCountryName: getValue('shippingCountryName'),
    // sc
    corporationId: getValue('scCorporationId', false) || '',
    scCompanyName: getValue('scCorporationName', false) || '',
    salesEventId: getValue('scSalesEventId', false) || '',
    projectName: getValue('scSalesEventName', false) || '',
    contactName: getValue('scStaffName', false) || '',
    salesRecordingDate: getValue('scSalesRecordedDate', false) || '',
  };
};

export type TItem = ReturnType<typeof toItem>;
