import actionCreatorFactory from 'typescript-fsa';
import { TScProject, TProjectFilter } from '../../types/project';

const ac = actionCreatorFactory('- ProjectSelectorDialog');

const events = {
  load: ac<void>('load'),
  show: ac<void>('show'),
  close: ac<void>('close'),
  select: ac<TScProject>('select'),
  setFilter: ac<TProjectFilter>('setFilter'),
  resetFilter: ac<void>('resetFilter'),
};
export const ProjectSelectorDialogEvents = events;
