export const pointPage = {
  pointPostFix: { jp: 'pt' },
  /** ポイント利用 */
  pointUsage: { jp: 'ポイント利用' },
  /** 利用ポイント */
  usePoints: { jp: '利用ポイント' },
  /** 利用可能ポイント */
  useablePoints: { jp: '利用可能ポイント' },
  /** 今回の還元ポイント */
  backPoints: { jp: '今回の還元ポイント' },
  /** 適用する */
  apply: { jp: '適用する' },
  /** 変更する */
  change: { jp: '変更する' },
  /** キャンペーン・クーポン */
  campaignCoupon: { jp: '値引き金額' },
  /** 利用キャンペーン・クーポン */
  useCampaignCoupon: { jp: '値引き金額' },
  /** 注文内容 */
  order: { jp: '注文内容' },
  /** 商品合計 */
  item: { jp: '商品合計' },
  /** 送料 */
  shippingCost: { jp: '送料' },
  /** 決済手数料 */
  settlementCost: { jp: '決済手数料' },
  /** 合計 */
  total: { jp: '合計' },
  /** 紹介者 */
  introducer: { jp: '紹介者' },
  /** なし */
  no: { jp: 'なし' },
  /** あり */
  yes: { jp: 'あり' },
  /** 紹介者コード */
  introducerCode: { jp: '紹介者コード' },
  /** 流通区分 */
  distribution: { jp: '流通区分' },
  /** 店舗 */
  store: { jp: '店舗' },
  /** 訪問 */
  visit: { jp: '訪問' },
  /** 請求書あり */
  needInvoice: { jp: '請求書あり' },
  /** 請求書なし */
  noNeedInvoice: { jp: '請求書なし' },
  /** 訪問販売先コード */
  visitCode: { jp: '訪問販売先コード' },
  /** スマレジ連携しない */
  noUseSmartage: { jp: 'スマレジ連携しない' },
  /** 案件登録(Salesforce) */
  projectRegist: { jp: '案件登録 (Salesforce)' },
  /** 法人名 */
  companyName: { jp: '法人名' },
  /** 案件名 */
  projectName: { jp: 'SC案件名' },
  /** 担当者名 */
  contactName: { jp: '担当者名' },
  /** 売上計上日 */
  salesRecordingDate: { jp: '売上計上日' },
  /** 案件を選択する */
  btnSelectProject: { jp: '案件を選択する' },
  /** 案件を選択しなおす */
  btnReselectProject: { jp: '案件を選択しなおす' },
};
