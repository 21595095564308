import React from 'react';

import styles from './MeasurementListItem.module.scss';

import Label from '../../atoms/Label/Label';
import NumberWithButton from '../NumberWithButton/NumberWithButton';

import { IMeasurementItem } from '../../../types/new-store/components';
import { IItem, IGauge } from '../../../store/_type/order';
import { checkDiffValue } from '../../../helpers/size-correction';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { EKeyboardInputType } from '../../../lookups/keyboard';
import { SizeCorrectionPageEvents } from '../../../views/events/SizeCorrectionPage';
import { CSS_CLASSNAME_ERROR_INVALID_DATA } from '../../../lookups/html-css';
import { roundDegit } from '../../../helpers';
import { infoDialogActions } from '../../../store/utils/dialog/info';
import { IInformationDialog } from '../../../types/dialog';
import { isShirt } from '../../../helpers/item-thisisforreplaceall';

interface IProps {
  item: IItem;
  measurementItem: IMeasurementItem;
  selectingPartsGauge: IGauge;
  onChangeSizeMeasurement: typeof SizeCorrectionPageEvents.onChangeSizeMeasurement;
  showInfoDialog: typeof infoDialogActions.show.type;
}
type TProps = IProps & ICustomKeyboardInjectedProps;

const MeasurementListItem: React.FC<TProps> = (props: TProps) => {
  const { measurementItem, selectingPartsGauge } = props;
  const {
    measurementNumber,
    measurementName,
    historyValue,
    standardValue,
    value,
    max,
    min,
    measurementPitch: pitch,
    diff,
  } = measurementItem;
  const diffStyle = getDiffClass(diff);
  const isNumberWithButtonDisabled =
    (!selectingPartsGauge.major && !selectingPartsGauge.minor) ||
    (((!!max && !!min) || (max === 0 && min === 0)) && max === min);

  const onChangeMeasurement = (v: number) => {
    const measurementValue = String(v);
    const optionNumber = measurementNumber;
    props.onChangeSizeMeasurement({ optionNumber, measurementValue });
  };

  const onFocus = (inputValue: string | number | undefined) => {
    if (!isNumberWithButtonDisabled) {
      props.connectNumericKeyboard(inputValue, newValue => {
        onChangeMeasurement(roundDegit(newValue));
      });
    }
  };

  const onShowDialog = (payload: IInformationDialog) => {
    props.showInfoDialog(payload);
  };

  const isValidValue = !((max && +value > max) || (min && +value < min));
  const inputClass = isNumberWithButtonDisabled || isValidValue ? undefined : CSS_CLASSNAME_ERROR_INVALID_DATA;
  return (
    <div id={styles.container}>
      <div className={styles.row}>
        <div className={styles.history}>
          <div className={styles.value}>{historyValue}</div>
        </div>
        <div className={styles.basegauge}>
          <div className={styles.value}>{standardValue}</div>
        </div>
        <div className={styles.sizename}>
          <div className={styles.value}>{measurementName}</div>
        </div>
        <div className={styles.adjustment}>
          <div className={styles.value}>
            <NumberWithButton
              name={measurementName}
              value={+value}
              step={pitch}
              max={max}
              min={min}
              onFocus={onFocus}
              onValueChanged={onChangeMeasurement}
              data-input-type={EKeyboardInputType.number}
              disabled={isNumberWithButtonDisabled}
              className={inputClass}
              showDialog={onShowDialog}
              canInputKeyboard={!isShirt(props.item.size.selecting.partsNumber)}
              contextCannotInputKeyboard="シャツは +/- ボタンでの調整のみ可能です."
            />
          </div>
          <div className={styles.diff}>
            <Label text={diff} translate={false} styles={[diffStyle]} />
          </div>
        </div>
      </div>
    </div>
  );

  function getDiffClass(v: string): string {
    const result = checkDiffValue(v);
    switch (result) {
      case 'upper':
        return 'plus';
      case 'lower':
        return 'minus';
      default:
        return 'zero';
    }
  }
};

export default MeasurementListItem;
