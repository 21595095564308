import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getLocation } from 'connected-react-router';

import { AppState } from '../../store';
import { get as getOrder } from '../../store/order';
import { mapCommonPageDispatchToProps } from '../pages/CommonPage';
import { resolvePath, resolvePaths } from '../../helpers/common/path';
import { ERouterPath } from '../../types';
import Logger from '../../helpers/common/logger';
import config from '../../configuration/config';

function mapStateToProps(state: AppState) {
  return {
    pathname: getLocation(state).pathname,
    hasCurrentOrder: getOrder.hasCurrentOrder(state),
    currentOrderNumber: getOrder.currentOrderNumber(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...mapCommonPageDispatchToProps(dispatch),
  };
}

type TContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;

const EXCLUDED_PATHS: ERouterPath[] = [
  ERouterPath.login,
  ERouterPath.home,
  ERouterPath.ordersSearch,
  ERouterPath.orderDetail,
  ERouterPath.inventorySearch,
  ERouterPath.itemConfirmation,
  ERouterPath.orderConfirmation,
  ERouterPath.settlement,
  ERouterPath.settlementEnd,
];

const NoOrderGuard: React.FC<TContainerProps> = props => {
  const { showInfoDialog, hasCurrentOrder, currentOrderNumber, history, pathname } = props;

  const isNoOrder = !hasCurrentOrder || !currentOrderNumber;
  // 検証・本番環境では/{basePath}/のケースがあるため、`${config.baseUrl || ''}/`を追加
  const isExcludedPath = [`${config.baseUrl || ''}/`, ...resolvePaths(EXCLUDED_PATHS)].includes(pathname);
  // オーダーがない、かつ、除外するページに含まれないとき
  const isDisable = isNoOrder && !isExcludedPath;

  useEffect(() => {
    if (isDisable) {
      // ダイアログでメッセージを表示
      showInfoDialog({
        hasOpen: true,
        title: '確認',
        contents: '注文がありません.ホーム画面で追加してください.',
        onOK: () => {
          history.push(resolvePath(ERouterPath.home));
        },
      });
    }

    return () => {
      Logger.log('NoOrderGuard unmount.', pathname);
    };
  }, [showInfoDialog, history, isDisable, pathname]);

  return props.children && !isDisable ? <React.Fragment>{props.children}</React.Fragment> : null;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoOrderGuard));
