import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import * as fromHome from '../../store/pages/home';

import HomePage from '../../components/pages/HomePage/HomePage';
import { AppState } from '../../store';
import { selectionDialogActions } from '../../store/utils/dialog/selection';
import { TCategory, TSubCategory } from '../../lookups/master-thisisforreplaceall/';
import { get as getOrder, orderActions } from '../../store/order';
import { getConfirmation } from '../../store/order/selector/confirmation';
import { infoDialogActions } from '../../store/utils/dialog/info';
import { getStaff } from '../../store/staff';
import { CouponInfoDialogEvents } from '../../views/events/CouponInfoDialog';

function mapStateToProps(state: AppState) {
  return {
    productKind: getOrder.productKind(state),
    hasConfirmItems: getOrder.hasFilledItem(state),
    isValidAllOrders: getConfirmation.isValidAllOrders(state),
    isManager: getStaff.isManager(state),
    couponId: getStaff.staff(state).couponId,
    categoryInfo: getStaff.categoryInfo(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onCleate: (category: TCategory, subCategory: TSubCategory) =>
      dispatch<any>(fromHome.createOrder(category, subCategory)),
    showSelectionDialog: selectionDialogActions.show.dispatcher(dispatch),
    onChangeProductKind: orderActions.updateProductKind.dispatcher(dispatch),
    showInfoDialog: infoDialogActions.show.dispatcher(dispatch),
    showCouponInfoDialog: bindActionCreators(CouponInfoDialogEvents, dispatch).show,
    // homeNavBars: () => dispatch<any>(fromNavigation.homeNavBars()),
    // setHomeParameters: (param: IHomeSelectedItem) => dispatch<any>(fromHome.loadHomeParameters(param)),
    // loadTemporaryOrderItem: () => dispatch<any>(fromOrderConfirmation.editOrderConfirmationItem()),
  };
}

export type TPageContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
