import partial from 'ramda/es/partial';
import config from '../../configuration/config';
import { IndexedObject } from '../../types';
import { IOrder, TProductKind } from '../../store/_type/order';
import {
  EProductKind,
  MASTER_PRODUCT_KIND,
  MASTER_DELIVERY_TIME_ZONE,
  DELIVERY_DATE_UNSPECIFIED,
  DELIVERY_DATE_UNSPECIFIED_STRING,
} from '../../lookups/master-thisisforreplaceall';
import { isMatchDateTerm, toDateFormatStr } from '../common/date';
import { getValue } from '../../helpers/master-lookup';

export const getInitialOrderNumber = (orders: IndexedObject<IOrder>) => Object.keys(orders)[0];

export const shouldBeTotalPriceZero = (productKind: TProductKind) =>
  [EProductKind.remake].includes(productKind);

/**
 * 「管理者以外生地選択画面へ進めない期間」であれば true、そうでなければ false を返却
 * @param {Date} date 期間をチェックする日付
 * @return {boolean} 該当期間であれば true、そうでなければ false
 */
export const isStoppedStartOrderTerm = partial(isMatchDateTerm, [config.stoppedStartOrderTerm]);

export const getDeliveryTimeZoneStr = (deliveryDatetime: string): string => {
  const [deliveryDate, deliveryTimeZoneCode] = deliveryDatetime.split(',');
  const deliveryTimeZoneName = getValue(deliveryTimeZoneCode, MASTER_DELIVERY_TIME_ZONE) || '';
  return `${getDateStr(deliveryDate)} ${deliveryTimeZoneName}`;
};

export const getOrderKindStr = (orderKind: string): string => {
  return getValue(orderKind, MASTER_PRODUCT_KIND) || '';
};

export const getDateStr = (date: string): string =>
  date === DELIVERY_DATE_UNSPECIFIED ? DELIVERY_DATE_UNSPECIFIED_STRING : toDateFormatStr(date);

/**
 * 「注文確定できない期間」であれば true、そうでなければ false を返却
 * @param {Date} date 期間をチェックする日付
 * @return {boolean} 該当期間であれば true、そうでなければ false
 */
export const isStoppedSettleOrderTerm = partial(isMatchDateTerm, [config.stoppedSettlementOrderTerm]);
