import history from '../../helpers/common/history';
import { actions } from './actions';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../index';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { tap, ignoreElements } from 'rxjs/operators';
import { resolvePath } from '../../helpers/common/path';
import { ERouterPath } from '../../types/index';
import Logger from '../../helpers/common/logger';

// TODO: history.push(path) を利用するとログアウト後の遷移が上手くいかなかっためとりえあず。（通常の画面遷移は history.pushを利用すべきだとおもわれ）
const gotoPath = (path: ERouterPath) => setTimeout(() => history.push(resolvePath(path)), 200);
const doLogout = (path: ERouterPath) => setTimeout(() => (window.location.href = resolvePath(path)), 200);

const gotoLogin: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.gotoLogin),
    tap(() => {
      Logger.log('GOTO:', resolvePath(ERouterPath.login));
      doLogout(ERouterPath.login);
    }),
    ignoreElements(),
  );

const gotoHome: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.gotoHome),
    tap(() => gotoPath(ERouterPath.home)),
    ignoreElements(),
  );

const gotoOrderConfirmation: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.gotoOrderConfirmation),
    tap(() => gotoPath(ERouterPath.orderConfirmation)),
    ignoreElements(),
  );

const gotoSettlementEnd: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.gotoSettlementEnd),
    tap(() => gotoPath(ERouterPath.settlementEnd)),
    ignoreElements(),
  );

const gotoItemConfirmation: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(actions.gotoItemConfirmation),
    tap(() => gotoPath(ERouterPath.itemConfirmation)),
    ignoreElements(),
  );

export const RouterEpics = combineEpics(
  gotoLogin,
  gotoHome,
  gotoOrderConfirmation,
  gotoSettlementEnd,
  gotoItemConfirmation,
);
