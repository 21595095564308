import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';

import { AppState } from '../store';
import { AddressPageEvents } from '../views/events/AddressPage';
import { actions as OrderShippingActions } from '../store/order/address/actions';

/**
 * 郵便番号で住所を再読み込みする
 */
const onLoadPostalCodeAddress: Epic<AnyAction, Action<void>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(AddressPageEvents.reloadPostalCodeAddress),
    map(({ payload }) => OrderShippingActions.reloadPostalCodeAddress()),
  );

export const AddressPageListeners = combineEpics(onLoadPostalCodeAddress);
