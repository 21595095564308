import { Dispatch } from 'redux';

import { actions } from '../../utils/dialog/error/action-reducer';
import { handleActionError } from '../../../helpers/error-handler';
import { IError } from '../../../types/error';
import { TCategory, TSubCategory } from '../../../lookups/master-thisisforreplaceall';
import { getDefaultPiecesFromSubCategory, getItemCodeDefault } from '../../../helpers/orders/order-items';
import { TPartialOrder } from '../../_type/order';
import { SystemError } from '../../../models/error/system-error';
import { orderActions } from '../../order';

/**
 * ホームのサブカテゴリボタン選択時
 * @param categoryCode
 * @param subCategoryCode
 */
export function createOrder(categoryCode: TCategory, subCategoryCode: TSubCategory) {
  return (dispatch: Dispatch) => {
    dispatch(orderActions.create._action());

    try {
      // ここで他のstoreのdispatchを呼び出す
      const pieces = getDefaultPiecesFromSubCategory(subCategoryCode);
      const itemCode = getItemCodeDefault({ category: categoryCode, subCategory: subCategoryCode });

      if (!itemCode) {
        throw new SystemError('0006');
      }

      const orderData: TPartialOrder = {
        item: {
          categoryCode,
          subCategoryCode,
          itemCode,
          pieces,
        },
      };

      dispatch(orderActions.updateCurrentOrder._action(orderData));
    } catch (ex) {
      const next = (errors: IError[]) => dispatch(actions.showErrors._action(errors));
      handleActionError(ex, next);
    }
  };
}
