import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import styles from './ClothSelectionPage.module.scss';

import ClothSelectionComposition from '../../organisms/ClothSelectionComposition/ClothSelectionComposition';
import ClothSelectionContent from '../../organisms/ClothSelectionContent/ClothSelectionContent';
import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';
import Footer from '../../organisms/Footer/Footer';

import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { TPageContainerProps } from '../../../containers/pages/ClothSelectionPage';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import {
  generateBackHomeBtnFunc,
  generateSaveTempBtnFunc,
  generateCancelModifiedOrderBtnFunc,
} from '../../../helpers/components/footer';
import { langResource } from '../../../i18n/_new/resources';
import { getOrderDetailPath } from '../../../helpers/common/path';

type TProps = TPageContainerProps & RouteComponentProps & ICustomKeyboardInjectedProps;

const ClothSelectionPage: React.FC<TProps> = props => {
  return (
    <div onFocus={props.checkFocusForNumericKeyboard} className={styles.clothSelectionPage}>
      {/* 上半分 */}
      <ClothSelectionContent {...props} />
      {/* 下半分 （KRのみ）*/}
      <ClothSelectionComposition {...props} />
      {utility(props)}
    </div>
  );
};

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      func: !props.isEdit
        ? generateBackHomeBtnFunc(props.history, props.orderNumber, props.onDeleteOrder, props.onShowOkCancelDialog)
        : generateCancelModifiedOrderBtnFunc(
            props.history,
            props.serial,
            props.cancelModifiedOrder,
            props.onShowOkCancelDialog,
          ),
      textObj: !props.isEdit ? langResource.footer.home : langResource.footer.cancelEditOrder,
    },
    {
      type: 'save',
      func: generateSaveTempBtnFunc(props.onShowSaveTmpOrderDialog),
      isRender: !props.isEdit,
    },
    {
      type: 'next',
      isDisabled: !props.hasCompleted,
      path: ERouterPath.designSelection,
      isRender: !props.isEdit,
    },
    {
      type: 'confirm',
      isDisabled: !props.hasConfirmItems || !props.requiredCheckCompleted,
      textObj: !props.isEdit ? langResource.footer.order : langResource.footer.orderContentsEdit,
      path: !props.isEdit ? ERouterPath.itemConfirmation : undefined,
      func: !props.isEdit ? undefined : () => props.history.push(getOrderDetailPath(props.serial)),
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      <LoadingInfo isLoading={false} />
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(ClothSelectionPage);
