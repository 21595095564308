import React from 'react';
import Button from '../../atoms/Button/Button';

import styles from './DesignOptionPartsPanel.module.scss';

import DesignOptionList from '../../molecules/DesignOptionList/DesignOptionList';
import { IOptionListItem } from '../../../types/option';
import { IItem } from '../../../store/_type/order';
import { IAvailableOption, IClothProduct } from '../../../store/_type/lookups';
import { langResource } from '../../../i18n/_new/resources';
import { DesignSelectionPageEvents } from '../../../views/events/DesignSelectionPage';

interface IProps {
  orderNumber: string;
  item: IItem;
  products: IClothProduct[];
  optionListItems: IOptionListItem[];
  partsName: string;
  partsIndex: string;

  // action
  onSelectOption: typeof DesignSelectionPageEvents.onSelectOption;

  availableOptions: IAvailableOption[]; // setDefaultで使う
  onSetDefault: typeof DesignSelectionPageEvents.onSetDefault;
}

const DesignOptionPartsPanel: React.FC<IProps> = (props: IProps) => {
  const onRecommended = () => {
    const { partsIndex, item, availableOptions, orderNumber, products } = props;
    props.onSetDefault({ partsIndex, item, availableOptions, orderNumber, products });
  };

  return (
    <React.Fragment>
      <div className={styles.panel}>
        <div className={styles.title}>
          <h2>{props.partsName}</h2>
        </div>
        <div className={styles.detail}>
          <DesignOptionList {...props} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesignOptionPartsPanel;
