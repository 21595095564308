import actionCreatorFactory from 'typescript-fsa';
import { IOnInputFreeParam, IItem } from '../../store/_type/order';
import {
  ILoadInitializeParam,
  IAddPieceParam,
  IDeletePieceParam,
  IOptionClassSelection,
} from '../../store/order/design/actions';
import { IAvailableOption, IClothProduct } from '../../store/_type/lookups';
import { IRequiredCheckDialog } from '../../types/dialog';

const actionCreator = actionCreatorFactory('- DesignSelectionPage ');

export const DesignSelectionPageEvents = {
  /**
   * デザイン・オプション画面で初期化処理
   */
  onLoadInitialize: actionCreator<ILoadInitializeParam>('onLoadInitialize'),
  /**
   * パーツ追加処理
   */
  onAddPiece: actionCreator<IAddPieceParam>('onAddPiece'),
  /**
   * パーツ削除処理
   */
  onDeletePiece: actionCreator<IDeletePieceParam>('onDeletePiece'),
  /**
   * デフォルト処理
   */
  onSetDefault: actionCreator<{
    partsIndex: string;
    item: IItem;
    availableOptions: IAvailableOption[];
    products: IClothProduct[];
    orderNumber: string;
  }>('onSetDefault'),
  /**
   * オプション選択処理
   */
  onSelectOption: actionCreator<{ partsIndex: string; optionNumber: string; item: IItem; hasOpen?: boolean }>(
    'onSelectOption',
  ),
  /**
   * オプション選択処理
   */
  onSelectOptionClass: actionCreator<IOptionClassSelection>('onSelectOptionClass'),

  /**
   * デザイン・オプション画面で値の変更が行われた時（単一の値を持つ要素）
   */
  onInputFree: actionCreator<IOnInputFreeParam>('onInputFree'),

  /**
   * 必須チェックダイアログ表示処理
   */
  onShowRequiredCheckDialog: actionCreator<IRequiredCheckDialog>('onShowRequiredCheckDialog'),
};
