import React from 'react';

import { getLabel } from '../../../i18n/_new/helper';
import { TI18nTextProps } from '../../../containers/I18Text/I18Text';
import Logger from '../../../helpers/common/logger';

const I18Text: React.FC<TI18nTextProps> = ({ textKey, textObj, langCode }) => {
  if (textKey) {
    Logger.error('# still use old i18n textKey:', textKey);
  }
  return (
    <React.Fragment>
      {textKey ? textKey : null}
      {textObj ? getLabel(langCode, textObj) : null}
    </React.Fragment>
  );
};

export default I18Text;
