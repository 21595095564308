import React, { useState, useEffect } from 'react';

import StepbarTemplate from '../../templates/StepbarTemplate/StepbarTemplate';
import ItemSubRouter from '../../../sub-routers/ItemSubRouter';
import { RouteComponentProps } from 'react-router';
import { getItemStepbar } from '../../../helpers/components/stepbar';
import { TPageContainerProps } from '../../../containers/pages/ItemContentPage';

type TProps = TPageContainerProps & RouteComponentProps;

const ItemContentPage: React.FC<TProps> = (props: TProps) => {
  const {
    history,
    hasClothCompleted,
    hasDesignCompleted,
    isEdit,
    onShowRequiredCheckDialog,
    requiredCheckCompleted,
    errorTextForRequiredCheckDialog,
  } = props;
  const { pathname } = history.location;
  const initialStepbar = getItemStepbar(
    pathname,
    hasClothCompleted,
    hasDesignCompleted,
    isEdit,
    onShowRequiredCheckDialog,
    requiredCheckCompleted,
    errorTextForRequiredCheckDialog,
  );
  const [stepbar, setStepbar] = useState(initialStepbar);

  useEffect(() => {
    const edited = getItemStepbar(
      pathname,
      hasClothCompleted,
      hasDesignCompleted,
      isEdit,
      onShowRequiredCheckDialog,
      requiredCheckCompleted,
      errorTextForRequiredCheckDialog,
    );
    setStepbar(edited);
  }, [
    pathname,
    hasClothCompleted,
    hasDesignCompleted,
    isEdit,
    onShowRequiredCheckDialog,
    requiredCheckCompleted,
    errorTextForRequiredCheckDialog,
  ]);

  return (
    <StepbarTemplate stepbar={stepbar}>
      <ItemSubRouter />
    </StepbarTemplate>
  );
};

export default ItemContentPage;
