import { AppState } from '..';
import { createSelector } from 'reselect';
import { TPaymentState } from './action-reducer';
import { IPayment } from '../_type/payment';
import { orderState } from '../order/selector/order';
import { orderStateSelector } from '../order/object-selector';
import { getProduct, getAvailableOption } from '../lookups';
import { calcTotalOrders, getSettlementCost } from '../order/helper/calc-total';
import { IPaymentList } from '../../types/new-store/components';
import { isPointInputCompleted } from '../../helpers/item-thisisforreplaceall';
import { appStateSelector } from '../../helpers/object-selector/app-state';
import { scProjectSelector } from '../sc-project';

const featureSelector = (state: AppState): TPaymentState => state.payment;

export const getPaymentState = createSelector(featureSelector, state => state);

export const getPointPage = {
  payment: createSelector(getPaymentState, state => state as IPayment),
  isOnlyDummyData: createSelector(appStateSelector, state => {
    const orders = state.orders();
    return !orders ? false : Object.entries(orders).every(([i, order]) => order.item.cloth.clothCode === '9999');
  }),
  useablePoints: createSelector(getPaymentState, state => state.useablePoints),
  backPoints: createSelector(getPaymentState, state => state.backPoints),
  paymentList: createSelector(
    getPaymentState,
    orderState,
    getProduct.state,
    getAvailableOption.state,
    (state, order, product, option) => {
      const orderTotal = calcTotalOrders(order.orders, product.products, option.availableOptions, order.productKind);
      const shippingCost = orderStateSelector(order).shippingCostTotal;
      const settlementCost = getSettlementCost(state);
      const { usePoint, couponUsePoints } = state;
      const total = [orderTotal, shippingCost, settlementCost].reduce((p, c) => p + c, 0) - usePoint - couponUsePoints;
      const list: IPaymentList = {
        orderTotal,
        shippingCost,
        settlementCost,
        usePoint,
        couponUsePoint: couponUsePoints,
        total,
      };
      return list;
    },
  ),
  hasCompleted: createSelector(getPaymentState, scProjectSelector.selectedProject, (paymentState, selectedProject) => {
    return isPointInputCompleted(paymentState, selectedProject);
  }),
};
