import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './PointPage.module.scss';

import CheckBox from '../../atoms/CheckBox/CheckBox';
import PointUsage from '../../molecules/PointUsage/PointUsage';
import PointCampaign from '../../molecules/PointCampaign/PointCamaign';
import PointTotalPayment from '../../molecules/PointTotalPayment/PointTotalPayment';
import DistributionCategory from '../../molecules/DistributionCategory/DistributionCategory';
import BalanceIntroducer from '../../molecules/BalanceIntroducer/BalanceIntroducer';
import ProjectRegister from '../../molecules/ProjectRegister/ProjectRegister';
import Footer from '../../organisms/Footer/Footer';

import { ERouterPath } from '../../../types';
import { IFooterButton } from '../../../types/footer';
import { ICustomKeyboardInjectedProps } from '../../../hoc/CustomKeyboardHoc';
import { langResource } from '../../../i18n/_new/resources';
import { TPageContainerProps } from '../../../containers/pages/PointPage';
import { generateCancelModifiedOrderBtnFunc } from '../../../helpers/components/footer';
import { getOrderDetailPath } from '../../../helpers/common/path';
import { TProjectSelectorDialogProps } from '../../../containers/components/dialogs/ProjectSelectorDialog';
import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';

type TProps = TPageContainerProps & RouteComponentProps & ICustomKeyboardInjectedProps & TProjectSelectorDialogProps;

class AmountPage extends Component<TProps> {
  componentDidMount() {
    const { onLoadInitialize, memberscardNumber } = this.props;
    onLoadInitialize({ memberscardNumber });
  }

  onHandleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = !event.target.checked;
    this.props.onChangeValue({ key: 'isSettlement', value });
  };

  render() {
    return (
      <React.Fragment>
        <div className={styles.amountPage}>
          <div className={styles.left}>
            <PointUsage {...this.props} {...this.props.payment} />
            <PointCampaign {...this.props} {...this.props.payment} />
            <PointTotalPayment {...this.props} />
          </div>
          <div className={styles.right}>
            <BalanceIntroducer {...this.props} {...this.props.payment} />
            <DistributionCategory {...this.props} {...this.props.payment} />
            <ProjectRegister {...this.props} />
            <div className={styles.orderWithoutPayment}>
              {!this.props.isEdit ? (
                <CheckBox
                  textObj={langResource.pointPage.noUseSmartage}
                  styles={['large']}
                  // MEMO: スマレジ連携する場合、isSettlementはtrue
                  checked={this.props.isOnlyDummyData || !this.props.payment.isSettlement}
                  disabled={this.props.isEdit || this.props.isOnlyDummyData}
                  onChange={this.onHandleCheck}
                />
              ) : null}
            </div>
          </div>
        </div>
        {utility(this.props)}
      </React.Fragment>
    );
  }
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      textObj: !props.isEdit ? langResource.footer.addOrder : langResource.footer.cancelEditOrder,
      path: !props.isEdit ? ERouterPath.home : undefined,
      func: !props.isEdit
        ? undefined
        : generateCancelModifiedOrderBtnFunc(
            props.history,
            props.serial,
            props.cancelModifiedOrder,
            props.onShowOkCancelDialog,
          ),
    },
    {
      type: 'back',
      path: ERouterPath.address,
      isRender: !props.isEdit,
    },
    {
      type: 'next',
      path: ERouterPath.orderConfirmation,
      isDisabled: !props.hasPointInputCompleted,
      isRender: !props.isEdit,
    },
    {
      type: 'confirm',
      textObj: !props.isEdit ? langResource.footer.orderContents : langResource.footer.orderContentsEdit,
      path: !props.isEdit ? ERouterPath.orderConfirmation : undefined,
      isDisabled: !props.hasComfirmOrders,
      func: !props.isEdit ? undefined : () => props.history.push(getOrderDetailPath(props.serial)),
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      <LoadingInfo isLoading={props.isLoading} displayType="absolute" />
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(AmountPage);
