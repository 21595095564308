import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../..';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { adjustOptionAsyncActions, adjustOptionActions } from './action-reducer';
import { mergeMap } from 'rxjs/operators';
import { appStateSelector } from '../../../helpers/object-selector/app-state';
import { IItem } from '../../_type/order';
import { ISetting as IAdjustOptionSetting } from '../../lookups/adjust-option/action-reducer';
import { ApiError } from '../../../models/error/api-error';
import { actions as ErrorHandlerActions } from '../../../store/errorHandling/action';
import { getAdjustOptionParams } from '../../../helpers/size-correction';
import { getAdjustOptions } from '../../../services/items';
import { IPartsAdjustOption } from '../../_type/lookups';
import { by } from '../../../helpers';
import { infoDialogActions } from '../../utils/dialog/info';
import { IInformationDialog } from '../../../types/dialog';

const loadData: Epic<
  AnyAction,
  Action<
    | IAdjustOptionSetting
    | { error: ApiError; options: any }
    | Parameters<typeof adjustOptionAsyncActions.loadData.done>[0]
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(adjustOptionAsyncActions.loadData.started),
    mergeMap(async ({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = payload.item || (appStateObj.item() as IItem);
      const requestParam = getAdjustOptionParams(item);
      const res = await getAdjustOptions(requestParam).catch(err => err);
      return { payload, res, requestParam };
    }),
    mergeMap(({ payload, res, requestParam }) => {
      // 404の場合、getAdjustOptionsの中で握りつぶすようにして、他と平仄を合わせた
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: '設定可能補正サイズリスト情報の取得に失敗しました.' },
          }),
        ];
      } else {
        const adjustOptions = res as IPartsAdjustOption[];
        return [
          adjustOptionActions.set._action({ orderNumber: payload.orderNumber, adjustOptions, requestParam }),
          adjustOptionAsyncActions.loadData.done({ params: payload, result: {} }),
        ];
      }
    }),
  );

const loadDetailData: Epic<
  AnyAction,
  Action<
    | IAdjustOptionSetting
    | { error: ApiError; options: any }
    | IInformationDialog
    | Parameters<typeof adjustOptionAsyncActions.loadDetailData.done>[0]
  >,
  AppState
> = (action$, state) =>
  action$.pipe(
    ofAction(adjustOptionAsyncActions.loadDetailData.started),
    mergeMap(async ({ payload }) => {
      const appStateObj = appStateSelector(state.value);
      const item = payload.item || (appStateObj.item() as IItem);
      const requestParam = getAdjustOptionParams(item);
      const res = await getAdjustOptions(requestParam).catch(err => err);
      return { payload, res, requestParam };
    }),
    mergeMap(({ payload, res, requestParam }) => {
      // 404の場合、getAdjustOptionsの中で握りつぶすようにして、他と平仄を合わせた
      if (res instanceof ApiError) {
        return [
          ErrorHandlerActions.apiError({
            error: res,
            options: { contents: '設定可能補正サイズリスト情報の取得に失敗しました.' },
          }),
        ];
      } else {
        const isNotFound = Array.isArray(res) && res.some(v => Object.keys(v).length === 0);
        const { orderDetail, orderNumber } = payload;
        const match = orderDetail.dummyLookups.find(by('orderNumber')(orderNumber));
        if (isNotFound && match && match.partsAdjustOptions) {
          const data = {
            hasOpen: true,
            title: '注意',
            contents: '注文情報詳細取得よりデータを復元しました.',
          };
          return [
            infoDialogActions.show._action(data),
            adjustOptionActions.set._action({
              orderNumber: payload.orderNumber,
              adjustOptions: match.partsAdjustOptions,
              requestParam,
            }),
            adjustOptionAsyncActions.loadDetailData.done({ params: payload, result: {} }),
          ];
        }
        const adjustOptions = res as IPartsAdjustOption[];
        return [
          adjustOptionActions.set._action({ orderNumber: payload.orderNumber, adjustOptions, requestParam }),
          adjustOptionAsyncActions.loadDetailData.done({ params: payload, result: {} }),
        ];
      }
    }),
  );

export const AdjustOptionEpics = combineEpics(loadData, loadDetailData);
