import { ClothProductRes, clothProductRequestConfig } from '../../api/items/cloth-product';
import { fetchApi } from '../../api/_common/fetcher';
import { TCategory } from '../../lookups/master-thisisforreplaceall/';
import { IClothProduct } from '../../store/_type/lookups/index';
import { toClothProducts } from '../../helpers/api/converter/cloth-product';

const getClothProductsReqParam = (clothCode: string, seasonCode: string, categoryCode: TCategory) => ({
  pathParams: {
    seasonCode,
    clothCode,
  },
  query: {
    categoryCode,
  },
});

const requestProduct = async (req: {
  pathParams: { seasonCode: string; clothCode: string };
  query: { categoryCode: TCategory };
}): Promise<ClothProductRes> => {
  return fetchApi(ClothProductRes, clothProductRequestConfig, req);
};

export const getClothProducts = async (
  clothCode: string,
  seasonCode: string,
  categoryCode: TCategory,
): Promise<IClothProduct[]> => {
  const param = getClothProductsReqParam(clothCode, seasonCode, categoryCode);
  return await requestProduct(param).then(toClothProducts);
};
