import React from 'react';

import cssStyles from './RadioGroup.module.scss';

import I18TextContainer from '../../../containers/I18Text/I18Text';
import { TRadioGroup } from '../../../types/new-store/components';
import InputText from '../../../containers/components/InputText';

type TProps = {
  checkedValue: string;
  groups: TRadioGroup[];
  name: string;
  onChanged: (v: string) => void;
  onValueChanged?: (v: string) => void;
  styles?: string[];
  disabled?: boolean;
};

const getClassName = (styles: string[] = []): string => {
  const classNames = [cssStyles.radioGroup];
  if (styles.find(v => v === 'vertical')) {
    classNames.push(cssStyles.vertical);
  }
  if (styles.find(v => v === 'block')) {
    classNames.push(cssStyles.block);
  }
  return classNames.join(' ');
};

const RadioGroup: React.FC<TProps> = (props: TProps) => {
  const { groups, checkedValue, name, styles, disabled } = props;

  const onChanged = (v: string) => (event: any) => {
    props.onChanged(v);
  };

  const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onValueChanged) {
      props.onValueChanged(event.currentTarget.value);
    }
  };

  return (
    <React.Fragment>
      {groups.map((group, index) => {
        return (
          <div key={index} className={getClassName(styles)}>
            <label>
              <input
                type="radio"
                checked={checkedValue === group.value}
                onChange={onChanged(group.value)}
                value={group.value}
                name={name}
                disabled={disabled}
              />
              <span>
                {group.isTextBox ? (
                  <InputText
                    placeholder={group.inputPlaceHolder}
                    onValueChanged={onValueChanged}
                    value={group.inputValue}
                    styles={['inRadioGroup']}
                  />
                ) : (
                  <I18TextContainer textObj={group.textObj} />
                )}
              </span>
            </label>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default RadioGroup;
