import { TLookup, ILookupItem } from '../types/lookup';

export const DEFAULT_SELECT_OPTION: ILookupItem = {
  id: 0,
  value: 'Select',
};

export const MOCKUP_CATEGORIES: TLookup = [
  DEFAULT_SELECT_OPTION,
  { id: '1', value: 'Category.cat1' },
  { id: '2', value: 'Category.cat2' },
  { id: '3', value: 'Category.cat3' },
];
