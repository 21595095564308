import { INudeSize, IItem, IOrder } from '../../store/_type/order';
import { IndexedObject, Nullable } from '../../types';
import { ICustomer } from '../../store/_type/customer';
import { cloneDeep } from 'lodash';
import { hasShirt } from '../../helpers/item-thisisforreplaceall';
import { toDateFormatString } from '../../helpers/common/date';
import { INITIAL_SHIRT_NUDE_SIZES, INITIAL_NUDE_SIZES } from '../../lookups/size-correction';

const getNudeSizeOrders = (
  orders: IndexedObject<IOrder>,
  currentOrderNumber: string,
  isShirt: boolean,
): Nullable<INudeSize> => {
  const objArray = Object.entries(orders)
    .map(([orderNumber, order]) => ({ orderNumber, nude: order.item.size.nude, isShirt: hasShirt(order.item.pieces) }))
    .filter(v => v.isShirt === isShirt && v.orderNumber !== currentOrderNumber);

  if (objArray.length < 1) {
    return null;
  }

  const nude = objArray[objArray.length - 1].nude;
  // MEMO: 1つ前のオーダーのヌード寸法が空の場合
  if (nude.measurements.length < 1) {
    return null;
  }
  return cloneDeep(nude);
};

const getNudeHistory = async (item: IItem, customer: ICustomer): Promise<Nullable<INudeSize>> => {
  return null; // FIXME: 未実装
};

const loadNudeSize = async (
  item: IItem,
  orderNumber: string,
  orders: IndexedObject<IOrder>,
  customer: ICustomer,
): Promise<INudeSize> => {
  const { size, pieces } = item;
  const { nude } = size;

  // 現在のstateにヌード寸法が存在するとき
  if (nude.measurements.length > 0) {
    return { ...nude, date: toDateFormatString(new Date()) };
  }

  // オーダーの中にヌード寸法があるとき
  const isShirt = hasShirt(pieces);
  const nudeFromOrders = getNudeSizeOrders(orders, orderNumber, isShirt);
  if (nudeFromOrders) {
    return { ...nudeFromOrders, date: toDateFormatString(new Date()) };
  }

  // どっちにもないときはAPIから引いてみる
  const nudeFromApi = await getNudeHistory(item, customer);
  if (nudeFromApi) {
    return nudeFromApi;
  }

  // APIにもないとき
  const measurements = isShirt ? INITIAL_SHIRT_NUDE_SIZES : INITIAL_NUDE_SIZES;

  return { date: '-', measurements: [...measurements] };
};

export const NudeSizeService = {
  loadNudeSize,
};
