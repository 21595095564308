import { IOptionClass, IAvailableOption } from '../../store/_type/lookups';
import { by } from '..';
import { TPartsNumber } from '../../lookups/master-thisisforreplaceall';

import partial from 'ramda/es/partial';

export const getOptionClassProp = (
  optionClasses: IOptionClass[],
  optionClassNumber: string,
  propKey?: keyof IOptionClass,
) => {
  const optionClass = optionClasses.find(by('optionClassNumber')(optionClassNumber));
  if (propKey === undefined) {
    return optionClass;
  }
  return optionClass ? optionClass[propKey] : undefined;
};

export const getOptionClass = (optionClasses: IOptionClass[], optionClassNumber: string) =>
  getOptionClassProp(optionClasses, optionClassNumber) as IOptionClass | undefined;

export const getOptionClassName = (optionClasses: IOptionClass[], optionClassNumber: string) =>
  (getOptionClassProp(optionClasses, optionClassNumber, 'optionClassName') || '') as string;

export const getOptionRetailPrice = (optionClasses: IOptionClass[], optionClassNumber: string) =>
  (getOptionClassProp(optionClasses, optionClassNumber, 'retailPrice') || 0) as number;

export const getOptionRetailPriceTaxin = (optionClasses: IOptionClass[], optionClassNumber: string) =>
  (getOptionClassProp(optionClasses, optionClassNumber, 'retailPriceTaxin') || 0) as number;

export const getOptionClassesFromAvailableOptions = (
  availableOptions: IAvailableOption[],
  partsNumber: TPartsNumber,
  optionNumber: string,
): IOptionClass[] =>
  (availableOptions.find(by('partsNumber')(partsNumber))?.optionPatterns || []).find(by('optionNumber')(optionNumber))
    ?.optionClasses || [];

export const getOptionClassFromAvailableOptions = (
  availableOptions: IAvailableOption[],
  partsNumber: TPartsNumber,
  optionNumber: string,
  optionClassNumber: string,
) =>
  getOptionClass(getOptionClassesFromAvailableOptions(availableOptions, partsNumber, optionNumber), optionClassNumber);

export const isSameOptionPriceTaxIn = (
  availableOptionParams: {
    availableOptions: IAvailableOption[];
    partsNumber: TPartsNumber;
    optionNumber: string;
  },
  previousOptionClassNumber: string,
  currentOptionClassNumber: string,
): boolean => {
  const { availableOptions, partsNumber, optionNumber } = availableOptionParams;
  const optionClasses = getOptionClassesFromAvailableOptions(availableOptions, partsNumber, optionNumber);
  const getProps = partial(getOptionRetailPriceTaxin, [optionClasses]);
  return getProps(previousOptionClassNumber) === getProps(currentOptionClassNumber);
};
