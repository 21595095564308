import { Epic, combineEpics } from 'redux-observable';
import { AnyAction, Action } from 'typescript-fsa';
import { AppState } from '../store';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { SettlementEndPageEvents } from '../views/events/SettlementEndPage';
import { loginActions } from '../store/staff';
import { map } from 'rxjs/operators';

/**
 * 顧客ログアウト処理
 */
const clearCurrentProgress: Epic<AnyAction, Action<void | { gotoHome: boolean }>, AppState> = (action$, state) =>
  action$.pipe(
    ofAction(SettlementEndPageEvents.clearCurrentProgress),
    map(() => loginActions.clearCurrentProgress({ gotoHome: true })),
  );

export const SettlementEndPageListener = combineEpics(clearCurrentProgress);
