import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../../lib/action-creator-factory';
import { IInformationDialog } from '../../../../types/dialog';

// action
const ac = actionObjectCreatorFactory();

const actionTitle = 'Information Dialog';

export const infoDialogActions = {
  show: ac<IInformationDialog>(`[${actionTitle}] show`),
  close: ac(`[${actionTitle}] close`),
};

// reducer
const initialState: IInformationDialog = {
  hasOpen: false,
  title: null,
  contents: '',
};

export const infoDialogReducer = reducerWithInitialState(initialState)
  .case(infoDialogActions.show._action, (state, payload) => ({ ...payload }))
  .case(infoDialogActions.close._action, state => ({ ...initialState }));
