import React from 'react';

import styles from './OrderConfirmationDetail.module.scss';

import Title from '../../atoms/Title/Title';
import Button from '../../atoms/Button/Button';
import ItemConfirmation from '../confirmations/ItemConfirmation/ItemConfirmation';
import CustomerConfirmation from '../confirmations/CustomerConfirmation/CustomerConfirmation';
import ClothConfirmation from '../confirmations/ClothConfirmation/ClothConfirmation';
import DesignAndSizeConfirmation from '../confirmations/DesignAndSizeConfirmation/DesignAndSizeConfirmation';

import { confirmationPageActions } from '../../../store/pages/confirmation';
import { IOrderConfirmation } from '../../../types/new-store/components';
import { langResource } from '../../../i18n/_new/resources';
import { TPricePrefix } from '../../../types/orders';
import { selectionDialogActions } from '../../../store/utils/dialog/selection';
import { generateDeleteOrderBtnFunc } from '../../../helpers/components/dialog';
import { IStaff } from '../../../store/_type/staff';
import I18Text from '../../../containers/I18Text/I18Text';
import { ERouterPath } from '../../../types';
import { TOrderStatus } from '../../../lookups/item-thisisforreplaceall/order-status';
import { TProductKind } from '../../../store/_type/order';
import { getValue } from '../../../helpers/master-lookup';
import { MASTER_PRODUCT_KIND } from '../../../lookups/master-thisisforreplaceall';

interface IProps {
  pricePrefix: TPricePrefix;
  memberscardNumber: string;
  newOrderNumber: string;
  order: IOrderConfirmation;
  staff: IStaff;
  productKind: TProductKind;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
  onCopyOrder: typeof confirmationPageActions.copyOrder.tp;
  onDeleteOrder: typeof confirmationPageActions.deleteOrder.tp;
  onShowSelectionDialog: typeof selectionDialogActions.show.type;
  isFromOrderDetail?: boolean;
  isDisabledEdit?: boolean;
  isManager?: boolean;
  orderStatus?: TOrderStatus;
}

const renderHeaderInfo = (staff: IStaff, order: IOrderConfirmation, productKind: TProductKind) => {
  const { serialNumber, orderDate } = order;
  const { tempoName, staffName } = staff;
  return [
    { label: langResource.confirmationPage.serialNo, data: serialNumber, isSerial: true },
    { label: langResource.confirmationPage.orderDate, data: orderDate },
    { label: langResource.confirmationPage.storeName, data: tempoName },
    { label: langResource.confirmationPage.staffName, data: staffName },
    { label: langResource.confirmationPage.productKind, data: getValue(productKind, MASTER_PRODUCT_KIND) || '' },
  ].map((v, i) =>
    v.data !== '' ? (
      <p key={i}>
        <I18Text textObj={v.label} /> : {v.isSerial ? <span className="font-ocrb">{v.data}</span> : v.data}
      </p>
    ) : null,
  );
};

const OrderConfirmationDetail: React.FC<IProps> = (props: IProps) => {
  const { newOrderNumber, isFromOrderDetail, staff } = props;
  const { title, orderNumber, cloth } = props.order;

  const onDeleteOrder = generateDeleteOrderBtnFunc(orderNumber, props.onDeleteOrder, props.onShowSelectionDialog);
  const onCopyOrder = () => {
    props.onCopyOrder({ newOrderNumber, orderNumber });
  };

  return (
    <div id={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <Title styles={['bgBrightBrown']} title={title} />
        </div>
        <div className={styles.center}>{renderHeaderInfo(staff, props.order, props.productKind)}</div>
        <div className={styles.right}>
          {!isFromOrderDetail ? (
            <React.Fragment>
              <div className={styles.button}>
                <Button
                  styles={['black', 'size-s']}
                  onClick={onCopyOrder}
                  textObj={langResource.confirmationPage.copyBtn}
                />
              </div>
              <div className={styles.button}>
                <Button
                  styles={['red', 'size-s']}
                  onClick={onDeleteOrder}
                  textObj={langResource.confirmationPage.deleteBtn}
                />
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.customer}>
          <div className={styles.title}>
            <Title styles={['lineLightBrown']} textObj={langResource.confirmationPage.customerTitle} />
          </div>
          <div className={styles.confirmation}>
            <CustomerConfirmation {...props} orderNumber={orderNumber} />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.confirmation}>
            <ItemConfirmation {...props} />
          </div>
        </div>
        <div className={styles.cloth}>
          <div className={styles.title}>
            <Title styles={['lineLightBrown']} textObj={langResource.confirmationPage.clothTitle} />
          </div>
          <div className={styles.confirmation}>
            <ClothConfirmation {...props} orderNumber={orderNumber} cloth={cloth} />
          </div>
        </div>
        <div className={styles.parts}>
          <div className={styles.title}>
            <Title styles={['lineLightBrown']} textObj={langResource.confirmationPage.designAndSizeTitle} />
          </div>
          <div className={styles.confirmation}>
            {props.order.partsDesingAndSize.map((v, key) => (
              <DesignAndSizeConfirmation
                key={key} {...props} {...v} orderNumber={orderNumber} brandCode={cloth.brandCode}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationDetail;
