import { AppState } from '../../../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProjectSelectorDialog from '../../../../components/organisms/ProjectSelectorDialog/ProjectSelectorDialog';

import { getProjectSelectorDialogState } from '../../../../store/utils/dialog/project-selector';
import { ProjectSelectorDialogEvents } from '../../../../views/events/ProjectSelectorDialog';

function mapStateToProps(state: AppState) {
  return {
    hasOpen: getProjectSelectorDialogState.hasOpen(state),
    filterCondition: getProjectSelectorDialogState.filterCondition(state),
    results: getProjectSelectorDialogState.results(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(ProjectSelectorDialogEvents, dispatch),
  };
}

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TProjectSelectorDialogProps = TStateProps & TDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelectorDialog);
