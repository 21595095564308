import React from 'react';
import { History } from 'history';

import styles from './PaymentConfirmation.module.scss';

import Title from '../../../atoms/Title/Title';
import Button from '../../../atoms/Button/Button';
// import PriceText from '../../../atoms/PriceText/PriceText';
import PriceList from '../../../molecules/PriceList/PriceList';
import I18TextContainer from '../../../../containers/I18Text/I18Text';

import { ERouterPath } from '../../../../types';
import { IPaymentConfirmation, IPaymentList, IOrderPayment } from '../../../../types/new-store/components';
import { langResource } from '../../../../i18n/_new/resources';
import { TPricePrefix, IPriceRow } from '../../../../types/orders';
import { TScProject } from '../../../../types/project';

interface IProps {
  history: History;
  pricePrefix: TPricePrefix;
  paymentConfirmation: IPaymentConfirmation;
  isOnlyDummyData: boolean;
  onEditOrder: (path: ERouterPath, orderNumber?: string) => void;
  orderNumber?: string;
  isFromOrderDetail?: boolean;
  isDisabledEdit?: boolean;
  scProject: TScProject;
}

const PaymentConfirmation: React.FC<IProps> = (props: IProps) => {
  const { pricePrefix, onEditOrder, orderNumber, isOnlyDummyData, scProject, isFromOrderDetail } = props;
  const { payment, totalList, orders } = props.paymentConfirmation;
  const { projectName, salesEventId, companyName, contactName } = scProject;
  const { isSettlement } = payment;

  const onClick = () => onEditOrder(ERouterPath.amount, orderNumber);

  // label
  // const couponTextObj = couponUsePoints !== 0 ?
  // langResource.confirmationPage.use : langResource.confirmationPage.noUse;
  const smartageObj =
    !isOnlyDummyData && isSettlement
      ? langResource.confirmationPage.useSmartregi
      : langResource.confirmationPage.nouseSmartregi;

  return (
    <div id={styles.container}>
      <div className={styles.header}>
        <Title styles={['bgRed', 'white']} textObj={langResource.confirmationPage.payment} />
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.title}>
            <I18TextContainer textObj={langResource.confirmationPage.orderContent} />
          </div>
          <div className={styles.contentsOrder}>
            <div className={styles.row}>
              <div className={styles.label}>
                <I18TextContainer textObj={langResource.confirmationPage.totalCost} />
              </div>
              <div className={styles.value}>
                <PriceList
                  rows={getTotalListRows(pricePrefix, totalList)}
                  isTotalBold={true}
                  showTotalTaxIn={true}
                  totalPrefix={pricePrefix}
                />
              </div>
            </div>
            {renderOrders()}
          </div>
        </div>
      </div>
    </div>
  );

  function renderOrders() {
    return orders.map((order, key) => {
      const { title } = order;
      return (
        <div className={styles.row} key={key}>
          <div className={styles.label}>
            <I18TextContainer textObj={langResource.confirmationPage.order} />
            {title}
          </div>
          <div className={styles.value}>
            <PriceList
              rows={getOrderPaymentRows(pricePrefix, order)}
              isTotalBold={true}
              showTotalTaxIn={true}
              totalPrefix={pricePrefix}
              unShowShippingCost={isFromOrderDetail}
            />
          </div>
        </div>
      );
    });
  }
};

function getTotalListRows(pricePrefix: TPricePrefix, totalList: IPaymentList): IPriceRow[] {
  return [
    {
      textObj: langResource.confirmationPage.itemTotal,
      price: totalList.orderTotal,
      showTaxIn: true,
      prefix: pricePrefix,
      postfix: 'tax',
    },
    {
      textObj: langResource.confirmationPage.shippingCost,
      price: totalList.shippingCost,
      showTaxIn: false,
      prefix: pricePrefix,
      postfix: '',
    },
  ];
}

function getOrderPaymentRows(pricePrefix: TPricePrefix, order: IOrderPayment): IPriceRow[] {
  return [
    {
      textObj: langResource.confirmationPage.productPrice,
      price: order.productPrice,
      showTaxIn: true,
      prefix: pricePrefix,
      postfix: 'tax',
    },
    {
      textObj: langResource.confirmationPage.optionPrice,
      price: order.optionPrice,
      showTaxIn: false,
      prefix: pricePrefix,
      postfix: '',
    },
    {
      textObj: langResource.confirmationPage.deliveryFee,
      price: order.shippingCost,
      showTaxIn: false,
      prefix: pricePrefix,
      postfix: '',
    },
  ];
}

export default PaymentConfirmation;
