import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';

import styles from './Header.module.scss';

import config from '../../../configuration/config';
import Logo from '../../atoms/Logo/Logo';
import LogoutDialog from '../../organisms/LogoutDialog/LogoutDialog';
import StaffLoginInfo from '../../molecules/StaffLoginInfo/StaffLoginInfo';
import CustomerLoadInfo from '../CustomerLoadInfo/CustomerLoadInfo';
import LoginAndSaveDialog from '../LoginAndSaveDialog/LoginAndSaveDialog';

import { resolveHeaderTextKey } from '../../../helpers/components/header';
import { RouteComponentProps, withRouter } from 'react-router';
import { THeaderProps } from '../../../containers/components/Header/Header';
import { langResource } from '../../../i18n/_new/resources';

type TProps = THeaderProps & RouteComponentProps;

const Header: React.FC<TProps> = (props: TProps) => {
  const { isLoaded, staff, customer, pathName, clearCurrentProgress, logout } = props;
  const [hasOpen, setHasOpen] = useState(false);
  const [hasOpenCustomerLogin, setHasOpenCustomerLogin] = useState(false);

  const initialHeaderText = resolveHeaderTextKey();
  const [headerText, setHeaderText] = useState(initialHeaderText);

  useEffect(() => {
    const text = resolveHeaderTextKey();
    setHeaderText(text);
  }, [headerText, pathName]);

  const handleLogoutOpen = () => {
    setHasOpen(true);
  };

  const handleLogoutClose = () => {
    setHasOpen(false);
  };

  const togleHasOpenCustomerLogin = () => {
    setHasOpenCustomerLogin(!hasOpenCustomerLogin);
  };

  return (
    <React.Fragment>
      <header className={`print-css-enabled ${config.isDev ? styles.headerDev : styles.header}`}>
        <div className={styles.leftBlock}>
          <Logo textObj={headerText} />
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.staff}>
            <StaffLoginInfo staff={staff} />
          </div>
          <div className={styles.customer}>
            <CustomerLoadInfo isLoaded={isLoaded} onOpenDialog={togleHasOpenCustomerLogin} />
          </div>
          <div className={styles.logout}>
            <Button styles={['black', 'logout']} onClick={handleLogoutOpen} textObj={langResource.header.logoutBtn} />
          </div>
        </div>
      </header>

      {/* LOGOUT DIALOG */}
      <LogoutDialog
        hasOpen={hasOpen}
        customer={customer}
        staff={staff}
        onHandleClose={handleLogoutClose}
        onLogoutCustomer={clearCurrentProgress}
        onLogoutStaff={logout}
      />
      {/* お客様ログイン&途中保存 */}
      <LoginAndSaveDialog
        hasOpen={hasOpenCustomerLogin}
        onClose={togleHasOpenCustomerLogin}
        onLoadCustomer={props.customerLogin}
        onLoadProgressData={props.loadProgressData}
        onLoadProgressList={props.loadProgressList}
        onDeleteProgressData={props.deleteProgressData}
        progressList={props.progressList}
      />
    </React.Fragment>
  );
};

export default withRouter(Header);
