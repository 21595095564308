import { Nullable } from './index';
import { TPartsNumber, TCategory, TSubCategory } from '../lookups/master-thisisforreplaceall/';
import { IOptionClass } from '../store/_type/lookups';

export interface IGetAvailableOptionsReqPathParam {
  brandCode: string;
  itemCode: string;
  seasonCode: string;
}

export interface IGetAvailableOptionsReqQueryParam {
  optionPattern: string;
  optionNumber?: string;
  languageCode?: string;
}

export interface IGetAvailableOptionsRes {
  availableOptions: IPart[];
}

// =================================================
// 削除予定
export interface IPart {
  partsNumber: TPartsNumber;
  partsName: string;
  optionPatterns: IOptionPattern[];
}

// 削除予定
export interface IOptionPattern {
  optionNumber: string;
  optionName: string;
  specialOptionFlag: string;
  required: string;
  optionTypes: IOptionType[];
  // ・・・
  optionPattern?: string;
  optionPatternName?: string;
}

// 削除予定
export interface IOptionType {
  optionClassNumber: string;
  optionClassName: string;
  defaultFlag: string;
  retailPrice: number;
  retailPriceTaxin: number;
  imagePath: string;
}

// =================================================

export interface IRequestParameter {
  path: IGetAvailableOptionsReqPathParam;
  query: IGetAvailableOptionsReqQueryParam;
  other: IOtherCondition;
}

// 削除予定
export interface IOtherCondition {
  category: TCategory;
  subCategory: TSubCategory;
  initialPieces: TPartsNumber[];
}

// 削除予定
interface IBaseOptionSelection {
  partsIndex: number;
  partsNumber: TPartsNumber;
  optionNumber: string;
}

// 削除予定
export interface IOptionSelectedParam extends IBaseOptionSelection {
  optionClassNumber: string;
}

// 削除予定
export interface IOptionSelectingParam extends IBaseOptionSelection {
  optionClassNumber: Nullable<string>;
  hasOpenPopup: boolean;
}

// 削除予定
export interface IDesignSelection {
  availableOptions: IPart[];
  selectingOption: IOptionSelectingParam;
  selectedOptions: IOptionSelectedParam[];
  sidebarParts: ISidebarPart[];
  addableParts: TPartsNumber[];
  deletableParts: TPartsNumber[];
}

// 削除予定
export interface ISidebarPart {
  number: TPartsNumber;
  index: number;
}

// new-storeでも使ってる
export interface IPartsModelPattern {
  partsIndex: string;
  modelPatterns: string[];
}

// new-storeでも使ってる
export interface IOptionListItem {
  optionNumber: string;
  optionName: string;
  isSpecial: boolean;
  paperState: IOptionPaper;
  selectBoxState: IOptionSelectBox;
}

// new-storeでも使ってる
export interface IPartsOptionListItem {
  partsIndex: string;
  items: IOptionListItem[];
  partsNumber: TPartsNumber;
  isSpare: boolean;
}

// new-storeでも使ってる
export interface IOptionPaper {
  classNumber: string;
  className: string;
  priceTaxIn: number;
  hasSelecting: boolean;
  isRequired: boolean;
  isDisable: boolean;
  // onSelect?: (param: IOptionSelectingParam, isSelectType: boolean) => void;
}

// new-storeでも使ってる
export interface IOptionSelectBox {
  hasOpen: boolean;
  selectingClassNumber: string;
  // FIXME: lookupItemsに変更
  classes: IOptionClass[];
}

/**
 * オプションタイプのenum
 */
export enum EOptionType {
  DESIGN_OPTION = 'DESIGN_OPTION',
  FREE_TEXT_OPTION = 'FREE_TEXT_OPTION',
  NUDE_SIZE = 'NUDE_SIZE',
  FINISHED_SIZE = 'FINISHED_SIZE',
  ADJUST_SIZE_OPTION = 'ADJUST_SIZE_OPTION',
  // MEASURING = 'MEASURING', // メジャーリング（PM用の差寸リスト式） ...用途不明のためコメントアウト
}

/**
 * 刺繍ネームの種類
 */
export enum EEmbroideryType {
  NONE = 'NONE',
  ROMA = 'ROMA',
  KANJI = 'KANJI',
  HANA = 'HANA',
  GERMAN = 'GERMAN',
  BLOCK = 'BLOCK',
  SCRIPT = 'SCRIPT',
  UNSELECTED = 'UNSELECTED',
}
