import { TRequestConfig, TRequestParams } from '../..';
export { CouponDetailRes } from './CouponDetailRes.class';

export const couponDetailRequestConfig: TRequestConfig = {
  endpoint: '/tempo/api/ambassodor.php',
  method: 'GET',
  useMock: false,
  params2request: (params): TRequestParams => {
    return {
      query: {
        couponId: params ? params.couponId : '',
      },
    };
  },
  isConvertCamelCase: true,
};
