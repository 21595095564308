import { createSelector } from 'reselect';
import { optionGroupAction, optionGroupReducer } from './action-reducer';
import { AppState } from '../..';

export { optionGroupAction, optionGroupReducer };

const featureSelector = (state: AppState): ReturnType<typeof optionGroupReducer> => state.utils.optionGroup;
export const optionGroupSelector = {
  selectedOptionGroup: createSelector(featureSelector, state => state),
};
