import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import InventorySearchFilter from '../../molecules/InventorySearchFilter/InventorySearchFilter';
import {
  getInventorySearch,
  IInventorySearch,
  getBrandLookup,
  getSeasonLookup,
  IStocks,
  STOCK_FLAG,
} from '../../../types/inventory-search';
import InventorySearchResults from '../../molecules/InventorySearchResults/InventorySearchResults';
import { IFooterButton } from '../../../types/footer';
import styles from './InventorySearchPage.module.scss';
import { ERouterPath } from '../../../types';
import Footer from '../../organisms/Footer/Footer';
import { TPageContainerProps } from '../../../containers/pages/InventorySearchPage';

type TProps = RouteComponentProps & TPageContainerProps;

const InventorySearchPage: React.FC<TProps> = props => {
  const [inventorySearch, setInventorySearch] = useState(getInventorySearch());

  useEffect(() => {
    props.load();
    return () => {
      props.clear();
    };
    // FIXME: パフォーマンスに問題がある場合は直す
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className={styles.inventorySearchPage}>
        <InventorySearchFilter
          data={inventorySearch}
          brandLookup={getBrandLookup(props.inventories)}
          periodLookup={getSeasonLookup(props.inventories)}
          onSearch={onSearchHandler}
        />
        <InventorySearchResults
          inventorySearchResults={filterInventories(props.inventories, inventorySearch)}
          updateDate={props.updateAt}
        />
      </div>
      {utility(props)}
    </React.Fragment>
  );
  function onSearchHandler(state: IInventorySearch): void {
    setInventorySearch(state);
  }
};

function filterInventories(stocks: IStocks[], filter: IInventorySearch): IStocks[] {
  const { brand, season, productNumber, enoughStock, littleStock, noStock } = filter;
  const stockFlag: string[] = [];
  if (enoughStock) {
    stockFlag.push(STOCK_FLAG.enoughStock);
  }
  if (littleStock) {
    stockFlag.push(STOCK_FLAG.littleStock);
  }
  if (noStock) {
    stockFlag.push(STOCK_FLAG.noStock);
  }
  return stocks
    .filter(stock => stock.brandCode === brand.value || brand.value === 'Select')
    .filter(stock => stock.seasonCode === season.value || season.value === 'Select')
    .filter(stock => stock.productNumber === productNumber || productNumber === '')
    .filter(stock => stockFlag.some(flag => flag === stock.stockFlag) || stockFlag.length === 0);
}

function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      path: ERouterPath.home,
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      {/* <LoadingInfo isLoading={props.isLoading} displayType="absolute" /> */}
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(InventorySearchPage);
