import { TPartsNumber } from '../../lookups/master-thisisforreplaceall/';
import { IPiece } from '../../store/_type/order';
import { SystemError } from '../../models/error/system-error';
import { getValue } from '../master-lookup';
import { MASTER_PARTS } from '../../lookups/master-thisisforreplaceall/';

export function getPartsNameByPieces(partsIndex: string, pieces: IPiece[]): string {
  const matched = pieces.find(v => v.index === partsIndex);
  if (!matched) {
    throw new SystemError('0011', [partsIndex, pieces.join(',')]);
  }
  return getValue(matched.partsNumber, MASTER_PARTS) || '';
}

export function getPartsNumberByPieces(partsIndex: string, pieces: IPiece[]): TPartsNumber {
  const matched = pieces.find(v => v.index === partsIndex);
  if (!matched) {
    throw new SystemError('0010', [partsIndex, pieces.join(',')]);
  }
  return matched.partsNumber;
}

export function getPartsNumberAndPartsName(
  partsIndex: string,
  pieces: IPiece[],
): { partsName: string; partsNumber: TPartsNumber } {
  return {
    partsName: getPartsNameByPieces(partsIndex, pieces),
    partsNumber: getPartsNumberByPieces(partsIndex, pieces),
  };
}

export function sortPieces(pieces: IPiece[]): IPiece[] {
  return pieces.sort((a, b) => +a.partsNumber - +b.partsNumber || +a.index - +b.index);
}
