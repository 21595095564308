import { createSelector } from 'reselect';
import { orderState } from './order';
import { orderStateSelector } from '../object-selector';

const shortestDeliveryDateSelector = createSelector(orderState, state =>
  // TODO: initialize ???
  orderStateSelector(state).hasCurrentOrder() ? orderStateSelector(state).currentOrder().shortestDeliveryDate : '',
);

export const getShortestDeliveryDate = {
  value: createSelector(shortestDeliveryDateSelector, state => state),
};
