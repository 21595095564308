import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionObjectCreatorFactory } from '../../../lib/action-creator-factory';
import { IAvailableOption, IClothProduct } from '../../_type/lookups';
import { cloneDeep } from 'lodash';
import { actionCreatorFactory } from 'typescript-fsa';
import { IItem, IPiece } from '../../_type/order';

// actions
const actionName = 'Available Options';
const ac = actionObjectCreatorFactory('Available Options');

export interface ISetting {
  orderNumber: string;
  availableOptions: IAvailableOption[];
}

export interface IGetAvailableOptionsParams {
  orderNumber: string;
  item?: IItem;
  products?: IClothProduct[];
  pieces?: IPiece[];
  isDouble?: boolean;
  forWhat?: forWhat;
}

export type forWhat = 'initialize' | 'changeItemCode' | 'noReason';

export const availableOptionActions = {
  set: ac<ISetting>(`set AvailableOptions`),
  delete: ac<string>(`delete AvailableOptions`),
  copy: ac<{ newOrderNumber: string; orderNumber: string }>(`copy AvailableOptions`),
};

const asyncAc = actionCreatorFactory(`[${actionName}]`);
export const availableOptionAsyncActions = {
  loadData: asyncAc.async<IGetAvailableOptionsParams, {}, {}>('loadData'),
};

// reducer
export interface IAvailableOptionState {
  availableOptions: {
    [orderNumber: string]: IAvailableOption[];
  };
}

const initialState: IAvailableOptionState = {
  availableOptions: {},
};

export const availableOptionReducer = reducerWithInitialState(initialState)
  .case(availableOptionActions.set._action, (state, payload) => {
    const newState = { ...state.availableOptions };
    newState[payload.orderNumber] = payload.availableOptions;
    return {
      ...state,
      availableOptions: { ...newState },
    };
  })
  .case(availableOptionActions.delete._action, (state, payload) => {
    const copied = { ...state.availableOptions };
    delete copied[payload];
    return {
      ...state,
      availableOptions: copied,
    };
  })
  .case(availableOptionActions.copy._action, (state, payload) => {
    const { newOrderNumber, orderNumber } = payload;
    const target = state.availableOptions[orderNumber];
    if (!target) {
      return { ...state };
    }

    const copied = cloneDeep(target);
    return {
      ...state,
      availableOptions: {
        ...state.availableOptions,
        [newOrderNumber]: copied,
      },
    };
  });
