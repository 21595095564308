import React from 'react';

import styles from './PartsSizeConfirmationTable.module.scss';

import I18TextContainer from '../../../containers/I18Text/I18Text';

import { IConfirmationMeasurement } from '../../../types/new-store/components';
import { checkDiffValue } from '../../../helpers/size-correction';
import { langResource } from '../../../i18n/_new/resources';
import { TPartsNumber } from '../../../lookups/master-thisisforreplaceall';
import { getShowMeasurementItems } from '../../../helpers/view';

interface IProps {
  items: IConfirmationMeasurement[];
  brandCode?: string;
  partsNumber?: TPartsNumber;
}

const PartsSizeConfirmationTable: React.FC<IProps> = (props: IProps) => {
  const { items, brandCode, partsNumber } = props;
  const hasDiff = items.some(v => typeof v.diff !== 'undefined');
  const showItems = !brandCode || !partsNumber
    ? items : getShowMeasurementItems(items, brandCode, partsNumber);

  if (showItems.length < 1) {
    return null;
  }

  return (
    <div id={styles.container}>
      <div className={styles.table}>
        <table>
          <colgroup>
            <col />
            <col className={styles.size1} />
            {hasDiff ? <col className={styles.size2} /> : null}
          </colgroup>
          <thead>
            <tr>
              <th className={styles.noBorder}>&nbsp;</th>
              <th className={styles.noBorder}>&nbsp;</th>
              {hasDiff ? (
                <th>
                  <I18TextContainer textObj={langResource.confirmationPage.diff} />
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>{showItems.map((v, i) => renderRow(v, i))}</tbody>
        </table>
      </div>
    </div>
  );

  function renderRow(item: IConfirmationMeasurement, key: number) {
    const { measurementName, measurementValue, diff } = item;
    if (!hasDiff) {
      return (
        <tr key={key} className={styles.row}>
          <th>{measurementName}</th>
          <td>{measurementValue}</td>
        </tr>
      );
    }

    if (diff) {
      return (
        <tr key={key} className={styles.row}>
          <th>{measurementName}</th>
          <td>{measurementValue}</td>
          <td className={getDiffStyle(diff)}>{diff}</td>
        </tr>
      );
    }
    return (
      <tr key={key} className={styles.row}>
        <th>{measurementName}</th>
        <td colSpan={2}>{measurementValue}</td>
      </tr>
    );
  }

  function getDiffStyle(diff: string): string {
    const result = checkDiffValue(diff);
    switch (result) {
      case 'upper':
        return styles.diffPlus;
      case 'lower':
        return styles.diffMinus;
      default:
        return styles.diffZero;
    }
  }
};

export default PartsSizeConfirmationTable;
