import React, { useEffect } from 'react';
import partial from 'ramda/es/partial';

import styles from './BalanceIntroducer.module.scss';

import Title from '../../atoms/Title/Title';
import Label from '../../atoms/Label/Label';
import InputText from '../../../containers/components/InputText';
import RadioGroup from '../../atoms/RadioGroup/RadioGroup';

import { pointPageActions } from '../../../store/pages/point';
import { langResource } from '../../../i18n/_new/resources';
import { TRadioGroup } from '../../../types/new-store/components';
import { getCssClassErrorInvalidData } from '../../../helpers/html-css';
import { isValidIntroducerCode } from '../../../helpers/common/validate';
import { TOrderStatus, PaymentItem } from '../../../lookups/item-thisisforreplaceall/order-status';
import { canEditItem } from '../../../helpers/orders/digest';
import { TPageContainerProps } from '../../../containers/pages/PointPage';

interface IProps {
  hasIntroducer: boolean;
  introducerCode: string;
  introducerName: string;
  onChangeValue: typeof pointPageActions.changeValue.tp;
  onChangeRadioButton: typeof pointPageActions.changeRadioButton.tp;
  orderStatus: TOrderStatus;
  isManager: boolean;
  isEdit: boolean;
  couponId: string;
}

type TCouponProps = Pick<TPageContainerProps, 'onLoadCouponDetail' | 'couponDetail'>;

type TProps = IProps & TCouponProps;

const radioGroups: TRadioGroup[] = [
  { value: 'false', textObj: langResource.pointPage.no },
  { value: 'true', textObj: langResource.pointPage.yes },
];

const BalanceIntroducer: React.FC<TProps> = (props: TProps) => {
  const {
    hasIntroducer,
    introducerCode,
    introducerName,
    orderStatus,
    isManager,
    isEdit,
    onLoadCouponDetail,
    couponDetail,
    couponId,
  } = props;

  useEffect(() => {
    if (couponId) {
      onLoadCouponDetail();
    }
  }, [onLoadCouponDetail, couponId]);

  useEffect(() => {
    const userId = couponDetail.userId;
    if (userId) {
      const value = userId.length > 6 ? userId.substring(0, 6) : userId;
      props.onChangeValue({ key: 'hasIntroducer', value: true });
      props.onChangeValue({ key: 'introducerCode', value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponDetail]);

  const canEditPaymentItem = partial(canEditItem, [orderStatus, isManager]);

  const onHandleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const upperValue = event.target.value;
    const value = upperValue.length > 6 ? upperValue.substring(0, 6) : upperValue;
    props.onChangeValue({ key: 'introducerCode', value });
  };

  const onHandleChangeHasIntroducer = (v: string) => {
    const value = v === 'true';
    props.onChangeRadioButton({ key: 'hasIntroducer', value });
  };

  const isValidIntroducerCodeInput = !hasIntroducer || isValidIntroducerCode(introducerCode);
  const isValidIntroducerNameInput = !hasIntroducer || !!introducerName;
  const introducerCodeInputClass = getCssClassErrorInvalidData(
    isValidIntroducerCodeInput && isValidIntroducerNameInput,
  );
  const introducerCodeMsg = isValidIntroducerCodeInput ? '' : '半角英数字6桁で入力してください';

  return (
    <div className={styles.balanceIntroducer}>
      <div className={styles.title}>
        <Title styles={['medium', 'lineLightGray']} textObj={langResource.pointPage.introducer} />
      </div>
      <div className={styles.set}>
        <RadioGroup
          checkedValue={String(hasIntroducer)}
          groups={radioGroups}
          name="hasIntroducer"
          onChanged={onHandleChangeHasIntroducer}
          disabled={isEdit && !canEditPaymentItem(PaymentItem.hasIntroducer)}
        />
      </div>
      <div className={styles.setCode}>
        <div className={styles.inputSet}>
          <div className={styles.label}>
            <Label textObj={langResource.pointPage.introducer} />
          </div>
          <div className={styles.inputItem}>
            <InputText
              disabled={!hasIntroducer || (isEdit && !canEditPaymentItem(PaymentItem.introducerCode))}
              onValueChanged={onHandleChangeCode}
              value={introducerCode}
              className={introducerCodeInputClass}
            />
            <div className="error-tooltip">{introducerCodeMsg}</div>
            <div className={styles.text}>
              <InputText disabled={true} value={introducerName} styles={['colorBlack']} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceIntroducer;
