import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './OrderDigestsSearchPage.module.scss';

import OrderDigestsSearchFilter from '../../molecules/OrderDigestsSearchFilter/OrderDigestsSearchFilter';
import OrderDigestsReport from '../../molecules/OrderDigestsReport/OrderDigestsReport';
import Footer from '../../organisms/Footer/Footer';
import LoadingInfo from '../../molecules/LoadingInfo/LoadingInfo';

import { IFooterButton } from '../../../types/footer';
import { ERouterPath } from '../../../types';
import { TPageContainerProps } from '../../../containers/pages/OrderDigestsSearchPage';

type TProps = TPageContainerProps & RouteComponentProps;

const OrderDigestsSearchPage: React.FC<TProps> = props => {
  return (
    <React.Fragment>
      <div className={styles.orderDigestsSearchPage}>
        <OrderDigestsSearchFilter {...props} />
        <OrderDigestsReport {...props} />
      </div>
      {utility(props)}
    </React.Fragment>
  );
};
function getFooterButtons(props: TProps): IFooterButton[] {
  return [
    {
      type: 'home',
      isDisabled: props.isEdit,
      path: ERouterPath.home,
    },
  ];
}

function utility(props: TProps) {
  return (
    <React.Fragment>
      {/* 検索結果を取得するのが遅いのでloadingを実装 */}
      <LoadingInfo isLoading={props.isLoading} />
      <Footer buttons={getFooterButtons(props)} />
    </React.Fragment>
  );
}

export default withRouter(OrderDigestsSearchPage);
